import React from "react";
import BaseList from "../../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../../utils/converter";
import {
    COMPANY, PRODUCT_LOG
} from "../../../../../api/endpoints";
import {useSelector} from "react-redux";
import CardDetail from "../../../../../components/common/list/card-detail";
import TableDetail from "../../../../../components/common/list/table-detail";
import {useParams} from "react-router-dom";
import BasePermissionWrapper from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../../constants/permissions";
import UserCell from "../../../../../components/common/table/user-cell";
import {getCustomFormat} from "../../../../../utils/timeUtils";

const ProductLog = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT, permissionSpecific.PRODUCT_LOG)]} renderBaseOnFail={true}>
            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.products.user_log"),
                            t("app.products.type"),
                            t("app.products.date_log"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, PRODUCT_LOG])}
                        extraParams={{group_name: 'product', group_id: id}}
                        baseResult={{total_rows: 0, product_logs: []}}
                        resultLabel={'product_logs'}
                        title={t("app.catalog.supplier")}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        editForm={EditForm}
                        editTitle={t("app.catalog.details")}
                        isActiveEdit={true}
                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <UserCell user={element} />

                    <div onClick={() => {
                        extras.onEdit(true, element);
                    }} className=" cursor-pointer text-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {t("dashboard.view_log")}
                    </div>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.products.type")}>
                        {t("app.products."+element.entity_name)} ({t("app.products."+element.action)})
                    </CardDetail>

                    <CardDetail title={t("app.products.date_log")}>
                        {getCustomFormat(element.date, 'DD/MM/YYYY HH:mm')}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();



    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        <UserCell user={element} />
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900 capitalize">
                    {t("app.products."+element.entity_name)}
                </div>
                <div className="text-xs text-gray-500 capitalize">
                    {t("app.products."+element.action)}
                </div>
            </TableDetail>

            <TableDetail>
                {getCustomFormat(element.date, 'DD/MM/YYYY HH:mm')}
            </TableDetail>

            <TableDetail>
                <div onClick={() => {
                    extras.onEdit(true, element);
                }} className=" cursor-pointer hidden text-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                    {t("dashboard.view_log")}
                </div>
            </TableDetail>
        </tr>
    );
};

const EditForm = ({ data }) =>  {

    return (
        <>
            <div>
                <div className="space-y-4">

                    <dl className="grid grid-cols-1">
                        {Object.entries(data.changed_column).map(([key, values], index) => (
                            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0" key={index}>
                                <dt className="text-sm font-semibold leading-6 text-gray-900 capitalize">{key.replace("_"," ")}</dt>
                                {
                                    values[0] !== '' ? (
                                        <dd className=" text-xs leading-6 text-gray-900 text">
                                            <i className={'line-through'} dangerouslySetInnerHTML={{__html: values[0]}} /> -> <b dangerouslySetInnerHTML={{__html: values[1]}} />
                                        </dd>
                                    ) : (
                                        <dd className=" text-xs leading-6 text-gray-900 text">
                                            <b dangerouslySetInnerHTML={{__html: values[1]}} />
                                        </dd>
                                    )
                                }
                            </div>
                        ))}
                    </dl>

                </div>
            </div>

        </>
    );
}

export default ProductLog;
