import React, {useState} from 'react';
import CustomerCell from "../common/table/customer-cell";
import {getFormattedDate} from "../../utils/timeUtils";
import moment from "moment";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import {useTranslation} from "react-i18next";
import {CheckIcon, ExclamationCircleIcon, ExclamationIcon, EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {hasPermission} from "../partials/restricted/permission-wrapper";
import {pg, pt} from "../../constants/permissions";
import {useSelector} from "react-redux";
import {getWebPathParam} from "../../utils/converter";
import {LOGISTICS, SHIPPING, SHIPPING_DETAIL} from "../../routes/endpoints";
import AnimatedPing from "../common/progress/animated-ping";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



function ShippingTrackingLog({data, height}) {

  const { t } = useTranslation();


  return (
    <>
        {
            data.logs.length === 0 ? (
                <div className="text-center p-10">
                    <ShippingIcon
                        className="h-24 w-24 text-indigo-600 m-auto"
                        aria-hidden="true"
                    />
                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                        {t("dashboard.unconfirmed_shipping.not_found_title")}
                    </p>
                    <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                        {t("dashboard.unconfirmed_shipping.not_found_description")}
                    </p>

                </div>
            ) : (
                <nav style={{height: height+'px'}} className="overflow-y-scroll" aria-label="Directory">
                    <ul className="relative z-0 divide-y divide-gray-200">
                        {data.logs.map((item) => (
                            <li key={item.id}>
                                <Row item={item} />
                            </li>
                        ))}
                    </ul>
                </nav>

            )
        }

    </>
  );
}

const Row = ({ item }) => {

    const { permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((s) => s.user);
    const { t } = useTranslation();
    let depDate = moment(item.shipping.departure_date, "YYYY-M-D HH:mm:ss");
    let arrivalDate = moment(item.shipping.arrival_date, "YYYY-M-D HH:mm:ss");
    const [isActive, setActive] = useState(false);

    let url = '#0';

    const isLinked = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.EDIT,
        item.shipping.customer.id,
        companyType,
        true
    );
    if(isLinked){
        url = getWebPathParam([LOGISTICS,SHIPPING, item.shipping.id, SHIPPING_DETAIL]);
    }


    return (
        <>
            <div className="flex items-center justify-between py-3 px-4">
                {
                    item.shipping.notification_code && (
                        <AnimatedPing color={item.shipping.notification_code} extraClass={' -ml-2 z-10'} />
                    )
                }
                <a rel="noreferrer" href={url} target="_blank" >
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        <CustomerCell customer={item.shipping.customer} secondExtra={item.shipping.tracking} extra={item.shipping?.shipping_note ?? false}  />
                    </h2>
                </a>

                <div className="text-right">
                    {
                        item.logs.length > 0 && (
                            <div className="text-xs text-indigo-900 underline  cursor-pointer items-center text-right" onClick={() => {setActive(!isActive)}}>
                                {isActive ?  <div className="flex justify-end items-center"> <EyeOffIcon className="h-4 w-4 mr-2"/>  {t("dashboard.hide_log")}</div> :
                                    <div className="flex items-center justify-end"> <EyeIcon className="h-4 w-4 mr-2"/>  {t("dashboard.view_log")}</div>}
                            </div>
                        )
                    }
                </div>
            </div>

            {
                isActive && (
                    <>

                        <div className={'bg-gray-50 py-3 px-4 flex justify-between text-sm font-medium  '}>

                            <div className="flex items-center  ">

                                <div className={"flex items-center "}>

                                    <div className="mr-2">
                                        <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                                    </div>
                                    <div>
                                        <div className="text-xs text-blue-1000">
                                            {t("dashboard.shipping_tracking_log.title")}
                                        </div>
                                        <div className="text-sm whitespace-pre-line text-gray-900">
                                            {arrivalDate.diff(depDate, 'days')} {t("app.plan.days")}
                                        </div>
                                        <div className="text-xs whitespace-pre-line text-gray-500 capitalize">
                                            {item.shipping.vessel_name}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="flex items-center">

                                <div >
                                    <div className="text-left">
                                        <div className="text-xs text-blue-1000">
                                            {t("dashboard.active_shipping.departure")}
                                        </div>
                                        <div className="text-sm whitespace-pre-line text-gray-900">
                                            {getFormattedDate(item.shipping.departure_date)}
                                        </div>
                                        <div className="text-xs whitespace-pre-line text-gray-500 capitalize">
                                            {item.shipping.departure_port}
                                        </div>
                                    </div>
                                </div>
                                <div className="m-5 text-sm text-blue-1000">
                                    {'->'}
                                </div>
                                <div >
                                    <div className="text-left">
                                        <div className="text-xs text-blue-1000">
                                            {t("dashboard.active_shipping.arrival")}
                                        </div>
                                        <div className="text-sm whitespace-pre-line text-gray-900">
                                            {getFormattedDate(item.shipping.arrival_date)}
                                        </div>
                                        <div className="text-xs whitespace-pre-line text-gray-500 capitalize">
                                            {item.shipping.arrival_port}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className={'bg-gray-100 py-3 px-4 flex justify-between text-sm font-medium border-t border-gray-200  '}>
                            <div className="text-left">
                                <div className="text-xs text-blue-1000 mb-3">
                                    {t("app.shipping_tracking.log_description")} & {t("app.shipping_tracking.log_date")}
                                </div>


                                <nav aria-label="Progress">

                                    <ol  className="overflow-hidden">

                                        {
                                            item.logs.map((step, stepIdx) => (
                                                <li key={step.name} className={classNames(stepIdx !== item.logs.length - 1 ? 'pb-10' : '', 'relative')}>

                                                    {stepIdx < item.logs.length - 1 && item.logs.length > 1 ? (
                                                        <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                                    ) : null}


                                                    <a href={step.href} className="group relative flex items-start">
                                          <span className="flex h-9 items-center">

                                              {
                                                  step.notification_code.substring(0,1) === 'U' && (
                                                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-red-600">
                                                            <ExclamationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                      </span>
                                                  )
                                              }

                                              {
                                                  step.notification_code.substring(0, 1) === 'W' && (
                                                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-yellow-600">
                                                            <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                      </span>
                                                  )
                                              }

                                              {
                                                  step.notification_code.substring(0, 1) === 'A' && (
                                                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-green-600">
                                                        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                    </span>
                                                  )
                                              }

                                          </span>
                                                        <span className="ml-4 flex min-w-0 flex-col">
                                            <span className="text-sm font-medium text-blue-1000">  {t("tracking_notification."+step.notification_code+".title")}</span>
                                            <span className="text-xs whitespace-pre-line text-gray-700">{t("tracking_notification."+step.notification_code+".description")}</span>
                                              <span className="text-xs whitespace-pre-line text-gray-500 capitalize mt-3"> {getFormattedDate(step.date)} </span>
                                          </span>
                                                    </a>
                                                </li>
                                            ))}
                                    </ol>
                                </nav>


                            </div>
                        </div>

                    </>
                )
            }



        </>
    )
}


export default ShippingTrackingLog;
