import { useTranslation } from "react-i18next";
import React from "react";

export default function InputTextBtn({
                                    type,
                                    label,
                                    id,
                                    errors,
                                    warning,
                                    warningRegex,
                                    input,
                                    defaultValue,
                                    isDisabled = false,
                                    placeholder = '',
                                    btnLabel,
                                    icon,
                                    isLoading,
                                    onClick,
                                    isActive,
                                    hideBtn = false,
                                    btnClassName = 'bg-green-500 hover:bg-green-700'
                                  }) {
  const { t } = useTranslation();

  const Icon = icon;

  return (
      <>
          <input
              type={type}
              id={id}
              defaultValue={defaultValue}
              disabled={isDisabled}
              className={`form-input w-full   z-0  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${
                  errors
                      ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      : ""
              }`}
              placeholder={label ? t("app.common.placeholder", {
                  field: label.toLowerCase(),
              }) : t("app.common.placeholder", {
                  field: placeholder.toLowerCase(),
              })}
              {...input}
          />

          {
              !hideBtn && (
                  <div className="absolute top-1.5 right-2">

                      {
                          isLoading ? (
                              <button
                                  className={"py-1 px-4 text-white rounded disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"}
                                  disabled
                              >
                                  <svg
                                      className="animate-spin w-4 h-4 fill-current shrink-0"
                                      viewBox="0 0 16 16"
                                  >
                                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                              </button>
                          ) : (
                              <button disabled={!isActive} onClick={onClick}
                                      className={"py-1 px-4 text-white rounded  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed " + btnClassName}>
                                  <Icon className="h-5 w-5 transform rotate-90" /> {btnLabel}
                              </button>
                          )
                      }
                  </div>
              )
          }




            {errors && errors.type === "required" && (
                <p className="mt-2 text-sm text-red-600">
                  {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "validate" && (
                <p className="mt-2 text-sm text-red-600">
                  {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "pattern" && (
                <p className="mt-2 text-sm text-red-600">
                  {warningRegex
                      ? warningRegex
                      : t("app.warning.description_regex", { field: label })}
                </p>
            )}
      </>
  );
}
