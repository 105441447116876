import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import InputCheckbox from "../../../../components/common/form/input-checkbox";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {cleanData, getSelectParam} from "../../../../utils/converter";
import {useParams} from "react-router-dom";
import Loader from "../../../../components/common/loader/loader";
import InputSubmit from "../../../../components/common/form/input-submit";
import InputRemove from "../../../../components/common/form/input-remove";
import {Warning} from "../../../../components/common/alert/banner";
import {deleteEvent, deleteEventImporter, getEvent, putEvent, putEventImporter} from "../../../../api/event";
import InputDate from "../../../../components/common/form/input-date";
import {
    permissionGroup,
    permissionSpecific,
    permissionType
} from "../../../../constants/permissions";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";

const EventDetail = ({isOpen, setIsOpen, detail, onNeedRefresh}) => {

    const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm();
    const [isLoading, setLoading] = useState(true);
    const { isExporter } = useSelector((state) => state.user);

    // eslint-disable-next-line
    const [event, setEvent] = useState(null);
    let {id}  = useParams();
    const { t } = useTranslation();
    const {  permissions } = useSelector((s) => s.userCompany);

    const canEdit =  hasPermissionFor(permissions, permissionGroup.EVENT, permissionType.EDIT);
    const canDelete =  hasPermissionFor(permissions, permissionGroup.EVENT, permissionType.DELETE);
    const canConfirmPermissions =  hasPermissionFor(permissions, permissionGroup.EVENT, permissionSpecific.EVENT_CONFIRM);


    // put container
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);

    const updateDocument = async (data) => {
        setUpdateLoading(true);
        (isExporter ?
            putEvent({shipping_id: id, event_id: detail.id, data}) :
                putEventImporter({shipping_id: id, event_id: detail.id, data})
        ).then(response => {
                setEvent(response);
                for (const k in response) {
                    if(typeof response[k] === "object"){
                        setValue(k, getSelectParam(response[k], 'name'));
                    }else{
                        setValue(k, response[k]);
                    }
                }
                setUpdateLoading(false);
                toast.success(t("app.event.update_success"));
                onNeedRefresh(true);
                setUpdateException(false);
            }).catch(e => {
            setUpdateException(e.message);
            setUpdateLoading(false);
        });
    };

    // put container
    const [isRemoveLoading, setRemoveLoading] = useState(false);

    const removeDocument = async () => {
        setRemoveLoading(true);
        (isExporter ? deleteEvent({shipping_id: id, event_id: detail.id}) :
            deleteEventImporter({shipping_id: id, event_id: detail.id}))
            .then(response => {
                setRemoveLoading(false);
                setIsOpen(false);
                onNeedRefresh(true);
            }).catch(e => {
            setUpdateException(e.message);
            setRemoveLoading(false);
        });
    };

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchDetail(){
            if(detail){
                setLoading(true);
                reset();
                getEvent({shipping_id: id, event_id: detail.id, signal})
                    .then(response => {
                        setEvent(response);
                        for (const k in response) {
                            if(typeof response[k] === "object"){
                                setValue(k, getSelectParam(response[k], 'name'));
                            }else{
                                setValue(k, response[k]);
                            }
                        }
                        setLoading(false);
                    }).catch(e => {
                    setLoading(false);
                });
            }
        }
        fetchDetail();
        return () => {controller.abort();};

        // eslint-disable-next-line
    }, [id, setValue, detail, reset]);

    const onSubmit = async (data) => {
        cleanData(data);
        await updateDocument(data);
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={setIsOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />

                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-4 px-4 bg-blue-1000 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">{detail?.event_type?.name}</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-blue-1000 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() => setIsOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">

                                            {
                                                isLoading ? (
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pt-6 pb-5">
                                                            <Loader />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                        <div className="space-y-6 pt-6 pb-5">

                                                            <InputText
                                                                id={'event_note'}
                                                                type={'text'}
                                                                isDisabled={!canEdit}
                                                                errors={errors.event_note}
                                                                input={{...register("event_note", { required: false })}}
                                                                label={t("app.event.event_note")}
                                                            />

                                                            <Controller
                                                                name="event_date"
                                                                rules={{ required: true }}
                                                                control={control}
                                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                    <InputDate
                                                                        label={t("app.event.event_date")}
                                                                        format={'YYYY-MM-D HH:mm:ss'}
                                                                        placeholder={'Es. 01/12/2021'}
                                                                        activeMinDate={false}
                                                                        startDate={value}
                                                                        disabled={!canEdit}
                                                                        isShowTime={true}
                                                                        errors={error}
                                                                        name={name}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                        }}
                                                                    />
                                                                )}
                                                            />


                                                            {
                                                                (canConfirmPermissions && canEdit) && (
                                                                    <InputCheckbox
                                                                        label={t("app.event.event_confirm_title")}
                                                                        description={t("app.event.event_confirm_description")}
                                                                        id={'confirmed'}
                                                                        errors={errors.confirmed}
                                                                        input={{...register("confirmed", { required: false })}}
                                                                    />
                                                                )
                                                            }




                                                            {/* Warning */}
                                                            {updateException && (
                                                                <Warning
                                                                    message={updateException}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>

                                    {
                                        !isLoading && (
                                            <div className="flex-shrink-0 px-4 py-4 flex justify-between">

                                                {
                                                    canDelete ? (
                                                        <InputRemove
                                                            isLoading={isRemoveLoading}
                                                            label={t("app.common.remove")}
                                                            onClick={async () => {
                                                                await removeDocument();
                                                            }}
                                                        />
                                                    ) : (
                                                        <div />
                                                    )

                                                }

                                                {
                                                    canEdit ? (
                                                        <InputSubmit
                                                            isLoading={isUpdateLoading}
                                                            label={t("app.form.save")}
                                                        />
                                                    ) : (
                                                        <div />
                                                    )

                                                }

                                            </div>
                                        )
                                    }


                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default EventDetail;
