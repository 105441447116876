//import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
//import loadingAnim from "../../resources/animations/logo.json";
import loadingAnim from "../../resources/images/logo-animated.gif";

const Loading = () => (
  <div className="min-h-screen bg-white  grid place-content-center">

     <img src={loadingAnim} alt={'logo'} className={' m-auto w-1/4'}/>
         {/*
             <Player style={{ width: '50%' }} autoplay={true} loop={true} src={loadingAnim} />

         */}
  </div>
);

export default Loading;
