import React from 'react'
import SlidePanel from "../../components/layout/slide-panel";
import {CheckIcon} from "@heroicons/react/solid";
import {getCustomFormat} from "../../utils/timeUtils";
import {useTranslation} from "react-i18next";
import MapMarker from "../../components/partials/map/map-marker";
import {LocationMarkerIcon} from "@heroicons/react/outline";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ShippingTimeline = ({isOpen, setIsOpen, events, isSeaType, tracking}) => {
    const { t } = useTranslation();

    return (

        <SlidePanel title={'Timeline & '+t("app.shipping_tracking.title")}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    bg={'bg-white'}
                    setClean={() => {

                    }}
        >
            <div className="space-y-6 pt-6 pb-5">
                <div className="bg-white ">


                    {
                        isSeaType && (
                            <>
                                <h2 className="text-lg leading-6 font-medium text-blue-1000 mb-2">
                                    {t("app.shipping_tracking.title")}
                                </h2>

                                {
                                    !tracking ? (
                                        <div className="border bg-solitude-400 shadow-md rounded-lg p-4 h-32 w-full mx-auto  flex before:flex-1 after:flex-1 flex-col items-center">
                                            <div className="animate-pulse border border-gray-500 rounded-full p-4">
                                                <LocationMarkerIcon className="text-gray-500 h-6 w-6 stroke-current stroke-1" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="block w-full shadow-md aspect-w-10 aspect-h-10 rounded-lg overflow-hidden">
                                            {
                                                (tracking.location) && (
                                                    <MapMarker
                                                        location={tracking.location ??  false}
                                                        zoom={5}
                                                        height={'200px'}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </>
                        )
                   }



                    <h2 className="text-lg leading-6 font-medium text-blue-1000 mt-3">
                        Timeline
                    </h2>


                    {/* Activity Feed */}
                    <div className="mt-6 flow-root">
                        <ul>
                            {(events ?? []).map((event, itemIdx) => (
                                <li key={event.id}>
                                    <div className="relative pb-8">
                                        {itemIdx !== events.length - 1 ? (
                                            <span
                                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        <div className="relative flex space-x-3">
                                            <div>
                                                                  <span
                                                                      className={classNames(
                                                                          event.confirmed ? 'bg-green-500' : 'bg-gray-400',
                                                                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                                                      )}
                                                                  ><CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                                  </span>
                                            </div>
                                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                <div>
                                                    <p className="text-sm text-blue-1000">
                                                        {event.event_type.name}
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        {event.event_note}
                                                    </p>
                                                </div>
                                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                    {
                                                        event.confirmed && (
                                                            <time dateTime={event.event_date}>{getCustomFormat(event.event_date, 'DD/MM')}</time>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>


        </SlidePanel>
    );
};

export default ShippingTimeline;
