import React from 'react';

function ContainerSearchTracking({height}) {

  return (
    <>
        <div className="tracking-block">
            <iframe title="container-tracking" src="https://sirius.searates.com/tracking/multitracking" width="100%" height={height+"px"}
                    frameBorder="0" align="middle" scrolling="No" allowFullScreen="Yes" />
        </div>
    </>
  );
}



export default ContainerSearchTracking;
