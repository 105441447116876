import React, {useEffect, useState} from "react";
import BaseList from "../../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam, makeid} from "../../../../../utils/converter";
import {
    PRICE_HISTORIES,
    PRODUCT,
    PRICE_HISTORY, DATA_PRICE_HISTORIES
} from "../../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../../components/common/table/actions-menu";
import CardDetail from "../../../../../components/common/list/card-detail";
import TableDetail from "../../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../../components/common/form/input-text";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {getCustomFormat, getFormattedDate} from "../../../../../utils/timeUtils";
import NumberFormat from "react-number-format";
import InputFormattedNumber from "../../../../../components/common/form/input-formatted-number";
import DiscountEditor from "../../../../../components/common/card/discount-editor";
import {PresentationChartLineIcon} from "@heroicons/react/outline";
import PriceHistoryChart from "../../../../../components/dashboard/price-history-chart";
import {getElement} from "../../../../../api/config";
import Loader from "../../../../../components/common/loader/loader";
import InputDate from "../../../../../components/common/form/input-date";
import UnderlineTab from "../../../../../components/partials/Tabs/underline-tab";
import ListSection from "../../../../../components/layout/list-section";
import DynamicFilterMenu from "../../../../../components/common/table/dynamic-filter-menu";
import {getProductsSellingFilter} from "../../../../../api/catalog";
import {generateParamsFormLocation} from "../../../../../constants/dynamic-filter";
import CustomerCell from "../../../../../components/common/table/customer-cell";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType
} from "../../../../../constants/permissions";
import InputDiscountNumber from "../../../../../components/common/form/input-discount-number";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../../components/partials/restricted/base-permission-wrapper";

const PriceHistory = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();
    const location = useLocation();

    const [isLoadingData, setLoadingData] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [chartData, setChartData] = useState([]);

    const { setNeedRefresh } = useOutletContext();


    const { permissions } = useSelector((s) => s.userCompany);
    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.VIEW);


    const canViewRateSelling = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionSpecific.PRODUCT_RATE_SELLING);


    //sell
    const [isFetchProductsLoading, setFetchProductsLoading] = useState(true);
    const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
    const [dynamicFilters, setDynamicFilters] = useState(false);
    const [sellingHistory, setSellingHistory] = useState([]);
    const [chartSellingHistory, setChartSellingHistory] = useState([]);

    const columns = [
        t("app.customers.customer"),
        t("app.quotations.date"),
        t("app.quotations.base_price"),
        t("app.quotations.type"), // reference_label
        "",
    ];

    const tabs = [
        { name: t("app.products.pricing_buy"), group: "buy" },
        canViewRateSelling && { name: t("app.products.pricing_sell"), group: "sell" }
    ].filter(Boolean);


    const [activeGroup, setActiveGroup] = useState("buy");

    const fetchData = async (signal = null) => {
        if(refresh){
            setLoadingData(true);
        }
        try {
            const res = await getElement(getPathParam([PRODUCT, id, DATA_PRICE_HISTORIES]), signal, {});
            setChartData(res);
            setRefresh(false);
        } catch (e) {
            setChartData([]);
        } finally {
            setLoadingData(false);
            setRefresh(false);
        }
    }



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if(activeGroup === 'buy'){
            fetchData(signal);
        }

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, [id, activeGroup]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchProducts() {
            if (loggedCompany && activeGroup === 'sell') {
                setFetchProductsLoading(true);
                getProductsSellingFilter({
                    product_id: id,
                    params: {},
                    data: activeDynamicFilter,
                    signal,
                })
                    .then((response) => {
                        // Aggiungi il tipo a ciascun elemento dell'array
                        // let quotationWithTypes = response.quotation.map(item => ({...item, type: "quotations"}));
                        let orderWithTypes = response.order.map(item => ({...item, type: "orders"}));
                        let containerProductsWithTypes = response.container_products.map(item => ({...item, type: "container_products"}));
                        let quotationWithTypes = response.quotation.map(item => ({...item, type: "quotation"}));

                        // Unisci gli array
                        let combinedArray = [...orderWithTypes, ...containerProductsWithTypes, ...quotationWithTypes];
                        // Funzione di ordinamento
                        combinedArray.sort((a, b) => {
                            let dateA = new Date(a.date);
                            let dateB = new Date(b.date);

                            // Se entrambe le date sono valide, confrontale
                            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                                return dateB - dateA; // Ordina dal più recente al più vecchio
                            } else if (!isNaN(dateA.getTime())) {
                                // Se solo dateA è valida, mettila prima
                                return -1;
                            } else if (!isNaN(dateB.getTime())) {
                                // Se solo dateB è valida, mettila prima
                                return 1;
                            } else {
                                // Se nessuna delle date è valida, considerale uguali
                                return 0;
                            }
                        });
                        setSellingHistory(combinedArray);

                        let purchaseArray = combinedArray
                            .filter(item => !isNaN(Date.parse(item.date))) // Filtra solo elementi con data valida
                            .map(item => ({
                                purchase_price: item.price,
                                purchase_date: item.date
                            }));

                        setChartSellingHistory(purchaseArray);

                        setFetchProductsLoading(false);
                        if(response.filters){
                            setDynamicFilters(response.filters);
                        }
                    })
                    .catch((e) => {
                        if(signal.aborted){
                            setFetchProductsLoading(true);
                        }else{
                            setSellingHistory([]);
                            setFetchProductsLoading(false);
                        }
                    });
            }
        }

        fetchProducts();
        return () => {
            controller.abort();
        };
    }, [loggedCompany, activeGroup, activeDynamicFilter, id]);



    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_RATE, permissionType.LIST)]} renderBaseOnFail={true}>
            <>




                {
                    loggedCompany && (

                        <>

                            <UnderlineTab
                                marginTop={''}
                                tabs={tabs}
                                activeGroup={activeGroup}
                                setActiveGroup={setActiveGroup}
                            />


                            {
                                activeGroup === 'buy' && (
                                    <div className={"h-full bg-white shadow-sm rounded-sm mb-5"}>
                                        {
                                            isLoadingData ? (
                                                <div className="animate-pulse px-5 py-3"  >
                                                    <div className="w-full flex justify-center items-center" style={{height: '200px'}} >
                                                        <Loader />
                                                    </div>
                                                </div>
                                            ) : (
                                                <PriceHistoryChart
                                                    data={chartData?.chart_date ?? []}
                                                    height={200}
                                                    width={'100%'}/>
                                            )
                                        }
                                    </div>

                                )
                            }

                            {
                                (activeGroup === 'sell' && canViewRateSelling) && (
                                    <div className={"h-full bg-white shadow-sm rounded-sm mb-5"}>
                                        {
                                            isFetchProductsLoading ? (
                                                <div className="animate-pulse px-5 py-3" >
                                                    <div className="w-full flex justify-center items-center" style={{height: '200px'}} >
                                                        <Loader />
                                                    </div>
                                                </div>
                                            ) : (
                                                <PriceHistoryChart
                                                    data={chartSellingHistory}
                                                    height={200}
                                                    width={'100%'}/>
                                            )
                                        }
                                    </div>

                                )
                            }


                            {
                                activeGroup === 'buy' && (
                                    <BaseList
                                        columns={[
                                            t("app.quotations.date"),
                                            t("app.products.list_price"),
                                            t("app.quotations.purchase_price"),
                                            t("app.products.price_note"),
                                            "",
                                        ]}
                                        endpoint={getPathParam([PRODUCT, id, PRICE_HISTORIES])}
                                        storeEndpoint={getPathParam([PRODUCT, id, PRICE_HISTORY])}
                                        updateEndpoint={getPathParam([PRODUCT, id, PRICE_HISTORY, ':id'])}
                                        baseResult={{total_rows: 0, pricing: []}}
                                        resultLabel={'pricing'}
                                        title={t("app.products.pricing_title")}
                                        showHeader={true}
                                        mobRow={MobRow}
                                        deskRow={DeskRow}
                                        addForm={AddForm}
                                        icon={PresentationChartLineIcon}
                                        addFormLabel={t("app.catalog.add", {type: t("app.products.pricing_title")})}
                                        btnAddLabel={canCreate ? t("app.catalog.add", {type: t("app.products.pricing_title")}) : false}
                                        editForm={EditForm}
                                        editTitle={t("app.catalog.details")}
                                        isActiveEdit={canView || canEdit}
                                        addPermission={[getPermission(permissionGroup.PRODUCT_RATE, permissionType.CREATE)]}
                                        onUpdateParent={() => {
                                            setRefresh(true);
                                            setNeedRefresh(true);
                                            fetchData();
                                        }}
                                    />
                                )
                            }

                            {
                                activeGroup === 'sell' && canViewRateSelling && (
                                    <ListSection
                                        title={t("app.products.products")}
                                        columns={columns}
                                        totalRows={sellingHistory.length}
                                        list={sellingHistory}
                                        isLoading={isFetchProductsLoading}
                                        isDynamicPagination={false}
                                        showSearch={false}
                                        mobileRow={SellMobRow}
                                        desktopRow={SellDeskRow}
                                        activeDynamicFilter={true}
                                    >
                                        <DynamicFilterMenu
                                            filters={dynamicFilters}
                                            isLoading={isFetchProductsLoading}
                                            onChange={(r) => {
                                                setDynamicDateFilter(r);
                                            }}
                                        />


                                    </ListSection>
                                )
                            }
                        </>



                    )
                }

            </>
        </BasePermissionWrapper>




    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const { permissions } = useSelector((s) => s.userCompany);

    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.VIEW);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.DELETE);

    const removeDetail = {
        title: t("app.products.remove_history_title"),
        message: t("app.products.remove_history_description"),
        endpoint: getPathParam([PRODUCT, id, PRICE_HISTORY, element.id])
    };


    const getDiscountLabel = (discounts) => {
        let discount_string = '';
        // eslint-disable-next-line
        (discounts ?? []).map((discount) => {
            if(discount){
                discount_string = discount_string + discount + '% + ';
            }
        })
        return discount_string.slice(0,-3);
    }

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {getFormattedDate(element.date)}

                    {
                        (canView || canEdit || canDelete) && (
                            <ActionsMenu>
                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onEdit(true, element);
                                        }}
                                        >
                                            {t("app.common.edit")}
                                        </button>
                                    )
                                }

                                {
                                    canDelete && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>
                                    )
                                }
                            </ActionsMenu>
                        )
                    }
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.products.list_price")}>
                        <NumberFormat
                            value={element.list_price}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={4}
                            suffix={' €'}
                        />
                    </CardDetail>

                    {
                        (element.discount && element.discount.length > 0) && (
                            <CardDetail title={t("app.quotations.discounts")}>
                                {getDiscountLabel(element.discount)}
                            </CardDetail>
                        )
                    }


                    <CardDetail title={t("app.quotations.purchase_price")}>
                        <NumberFormat
                            value={element.purchase_price}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={4}
                            suffix={' €'}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.products.price_note")}>
                        {element.price_note}
                    </CardDetail>

                    <CardDetail title={t("app.quotations.created_by")}>
                       {element.user.firstname} {element.user.lastname}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const { permissions } = useSelector((s) => s.userCompany);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.VIEW);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.DELETE);

    const removeDetail = {
        title: t("app.products.remove_history_title"),
        message: t("app.products.remove_history_description"),
        endpoint: getPathParam([PRODUCT, id, PRICE_HISTORY, element.id])
    };

    const getDiscountLabel = (discounts) => {
        let discount_string = '';
        // eslint-disable-next-line
        (discounts ?? []).map((discount) => {
            if(discount){
                discount_string = discount_string + discount + '% + ';
            }
        })
        return discount_string.slice(0,-3);
    }
    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {getFormattedDate(element.date)}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                <NumberFormat
                    value={element.list_price}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={4}
                    suffix={' €'}
                />
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900 ">
                    <NumberFormat
                        value={element.purchase_price}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalScale={4}
                        decimalSeparator={","}
                        suffix={' €'}
                    />
                </div>
                <div className="text-xs text-gray-500">
                    {
                        (element.discount && element.discount.length > 0) && (
                            <>
                                {t("app.quotations.discount")} {getDiscountLabel(element.discount)}
                            </>
                        )

                    }

                </div>
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900 ">
                    {element.price_note}
                </div>
                <div className="text-xs text-gray-500">
                    {t("app.quotations.created_by")} {element.user.firstname} {element.user.lastname}
                </div>
            </TableDetail>



            <TableDetail>

                {
                    (canView || canEdit || canDelete) && (
                        <ActionsMenu>
                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )
                            }

                            {
                                canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )
                            }
                        </ActionsMenu>
                    )
                }
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue
    } = useForm();

    const { t } = useTranslation();
    const onSubmit = async (data) => {

        let discount_string = '';
        if(watch('list_price') > 0){
            // eslint-disable-next-line
            (discounts ?? []).map((discount) => {
                discount_string = discount_string + discount.price + '|';
            })
        }else{
            data['list_price'] = null;
        }
        data['discount'] = discount_string.slice(0, -1);
        onSubmitData(data);
    };


    const fixDiscount = (discount_data) => {

        let discount_list = [];
        // eslint-disable-next-line
        (discount_data ?? []).map((discount, index) => {
            discount_list.push({id: makeid(20), price: discount})
        })
        return discount_list;
    }

    const [discountLabel, setDiscountLabel] = useState(t("app.quotations.no_discount"));
    const [discounts, setDiscounts] = useState(fixDiscount([]));


    useEffect(() => {

        if(discounts.length > 0){

            let discount_string = '';
            // eslint-disable-next-line
            (discounts ?? []).map((discount) => {
                if(discount.price){
                    discount_string = discount_string + discount.price + '% + ';
                }
            })
            // eslint-disable-next-line
            setDiscountLabel(discount_string.slice(0,-3));
        }else{
            setDiscountLabel(t("app.quotations.no_discount"));
        }

        // eslint-disable-next-line
    }, [discounts]);


    useEffect(() => {

        let final_price = watch('list_price') ?? 0;

        if(final_price > 0){
            // eslint-disable-next-line
            (discounts ?? []).map((discount) => {
                final_price = final_price - (final_price * (discount.price / 100));
            });
        }

        if(watch('list_price')){
            setValue('purchase_price', final_price);
        }else{
            setValue('purchase_price', undefined);
        }

        // eslint-disable-next-line
    }, [watch('list_price'), setValue, discounts]);


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">


                    <Controller
                        name="list_price"
                        rules={{ required: false }}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputFormattedNumber
                                label={t("app.products.list_price")}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                suffix={" €"}
                                errors={error}
                            />
                        )}
                    />

                    <div>
                        <label  className="block text-sm font-medium mb-1 text-gray-700">
                            {t("app.quotations.discounts")}
                        </label>
                        <DiscountEditor
                            btnClass={'w-full justify-start'}
                            boxClass={''}
                            label={discountLabel}
                            discounts={discounts}
                            element={{id: 'new'}}
                            onUpdateDiscount={(id, value, index) => {
                                let filtered = discounts;
                                filtered[index]['price'] = value !== false ? value : null;
                                filtered = filtered.filter(r => r.price !== null);
                                setDiscounts(filtered);
                            }}
                            onAddDiscount={(id) => {
                                let filtered = discounts;
                                filtered = [...discounts, {id: makeid(20), price: null}];
                                setDiscounts(filtered)

                            }}
                        />
                    </div>


                    <Controller
                        name="purchase_price"
                        rules={{ required: (watch('purchase_price') >= 0)}}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputFormattedNumber
                                label={t("app.quotations.purchase_price")}
                                name={name}
                                value={value}
                                decimalScale={4}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isDisabled={watch('list_price') !== undefined}
                                suffix={" €"}
                                errors={error}
                            />
                        )}
                    />

                    <Controller
                        name="purchase_date"
                        rules={{ required: true }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputDate
                                label={t("app.quotations.date")}
                                format={"YYYY-MM-D HH:mm:ss"}
                                placeholder={"Es. 01/12/2021"}
                                activeMinDate={false}
                                startDate={value}
                                disabled={false}
                                isShowTime={false}
                                errors={error}
                                name={name}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />


                    <InputText
                        id={"price_note"}
                        type={"text"}
                        errors={errors.price_note}
                        input={{
                            ...register("price_note", { required: true }),
                        }}
                        label={t("app.products.price_note")}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.EDIT);


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue
    } = useForm();

    const { t } = useTranslation();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object") {
                    setValue(k, getSelectParam(data[k], "name"));
                }else{
                    setValue(k, data[k]);
                }
            }
        }

        if(data.list_price){
            setValue('list_price', data.list_price);
        }else{
            if (data.purchase_price) {
                setValue('list_price', data.purchase_price);
            }
        }


    }, [setValue, data]);

    const onSubmit = async (data) => {
           onSubmitData(data);
    };



    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 pt-4 pb-5">


                    <Controller
                        name="list_price"
                        rules={{ required: false }}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputDiscountNumber
                                label={t("app.quotations.purchase_price")}
                                name={name}
                                value={value}
                                decimalScale={4}
                                onChangeValues={(e) => {
                                    setValue('discount_string',e.discount_string);
                                    setValue('discount',e.discount_string);
                                    setValue('purchase_price',e.final_price);
                                }}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isDisabled={!canEdit}
                                suffix={" €"}
                                errors={error}
                                isShowFinalPrice={true}
                                discountList={data?.discount ?? []}
                                symbol={'€'}
                                purchasePrice={watch('purchase_price')}
                            />
                        )}
                    />

                    <Controller
                        name="purchase_date"
                        rules={{ required: true }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputDate
                                label={t("app.quotations.date")}
                                format={"YYYY-MM-D HH:mm:ss"}
                                placeholder={"Es. 01/12/2021"}
                                activeMinDate={false}
                                startDate={value}
                                disabled={!canEdit}
                                isShowTime={false}
                                errors={error}
                                name={name}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />

                    <InputText
                        id={"price_note"}
                        type={"text"}
                        errors={errors.price_note}
                        input={{
                            ...register("price_note", { required: true }),
                        }}
                        label={t("app.products.price_note")}
                        isDisabled={!canEdit}
                    />

                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    {
                        canEdit && (
                            <InputSubmit
                                isLoading={isEditLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        )
                    }

                </div>
            </form>

        </>
    );
}


const SellMobRow = ({ element }) => {
    const { t } = useTranslation();

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">

                    <CustomerCell
                        customer={{color: element.color, business_name: element.business_name}}
                    />

                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.quotations.date")}>
                        {getCustomFormat(element.date, 'DD/MM/YYYY')}

                    </CardDetail>


                    <CardDetail title={t("app.quotations.base_price")}>
                        <NumberFormat
                            value={element?.price ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={4}
                            suffix={" €"}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.quotations.type")}>
                        {t("app.quotations.history_"+element.type)}
                    </CardDetail>

                    <CardDetail title={t("app.shippings.reference_label", {label: ''})}>
                        {element.reference}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const SellDeskRow = ({ element }) => {

    const { t } = useTranslation();



    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <CustomerCell
                    customer={{color: element.color, business_name: element.business_name}}
                />
            </TableDetail>

            <TableDetail>
                {getCustomFormat(element.date, 'DD/MM/YYYY')}
            </TableDetail>

            <TableDetail>
                <NumberFormat
                    value={element?.price ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={4}
                    suffix={" €"}
                />
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900 ">
                    {t("app.quotations.history_"+element.type)}
                </div>
                <div className="text-xs text-gray-500">
                    {t("app.shippings.reference_label", {label: element.reference } )}
                </div>
            </TableDetail>

        </tr>
    );
};




export default PriceHistory;
