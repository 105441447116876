import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, { ActionButton, WhiteButton } from "../../../components/layout/page-header";
import { useDispatch, useSelector } from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import {FULFILLMENT, FULFILLMENTS, LOGISTICS, SHIPPING, SHIPPING_DETAIL, SHIPPING_OVERVIEW, SHIPPINGS} from "../../endpoints";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import { getCustomFormat, getFormattedDate } from "../../../utils/timeUtils";
import {getPathParam, getWebPathParam} from "../../../utils/converter";
import CustomerCell from "../../../components/common/table/customer-cell";
import {getPermission, permissionGroup, permissionType, sp} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";
import ListSection from "../../../components/layout/list-section";
import OptionalLink from "../../../components/common/table/optional-link";
import TableDetail from "../../../components/common/list/table-detail";
import {COMPANY, SHIPPING_IMPORTER} from "../../../api/endpoints";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {AIR, DELIVERY, LAND, PICKUP, SEA} from "../../../constants/config";
import {hasSubscriptionPermission} from "../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import {PlusIcon, TruckIcon} from "@heroicons/react/outline";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../../constants/dynamic-filter";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {StatusBarIcon} from "../../../components/common/alert/status-bar-icon";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import AddFulfillment from "./add-fulfillment";
import { getElementBody } from "../../../api/config";
import AirIcon from "../../../resources/icons/AirIcon";
import ManageFulfillment from "./manage-fulfillment";
import { RadioGroup } from '@headlessui/react';
import { ScaleIcon } from "@heroicons/react/solid";
import FulfillmentNegotiation from "./fulfillment-negotiation";
import UsersToolbar from "../../../components/common/card/users-toolbar";
import UserInitial from "../../../components/common/table/user-initial";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}





const Fulfillment = () => {
  const { loggedCompany, companyType } = useSelector((state) => state.user);
  const { permissions, subscriptions } = useSelector((s) => s.userCompany);
  const { t } = useTranslation();
  const location = useLocation();

  const tabs = [
    { name: t("app.fulfillment.pickup"), group: PICKUP },
    { name: t("app.fulfillment.delivery"), group: DELIVERY },
  ];
  const [activeGroup, setActiveGroup] = useState(localStorage.getItem('FulfillmentType') ? localStorage.getItem('FulfillmentType') : PICKUP)


  const columns = [
    {
      header: t("app.common.customer") + "/" + t("app.common.supplier"),
      id: 'business_name',
      accessor: r => r.customer.business_name,
    },
    {
      header: activeGroup === DELIVERY ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup"),
      id: 'destination',
      accessor: r => r?.destination?.name ?? '-',
    },
    {
      header: t("app.fulfillment.reference"),
      id: 'reference',
      accessor: "reference"
    },
    {
      header: activeGroup === DELIVERY ? t("app.fulfillment.delivery_date") : t("app.fulfillment.pickup_date"),
      id: 'expected_date',
      accessor: r => new Date(r.expected_date).getTime()
    },
    {
      header: t("app.shippings.status"),
      id: 'status',
      accessor: "status"
    },
    {
      header: t("app.common.user"),
      id: 'user',
      accessor: r => r?.user?.fullname ?? '-',
    },
    {
      header: "",
      accessor: null
    }
  ];


  const shippingTypes = [
    { type: 'land', name: '', icon: TruckIcon },
    { type: 'sea', name: '', icon: ShippingIcon },
    { type: 'air', name: '', icon: AirIcon },
  ];
  const [shippingType, setShippingType] = useState(localStorage.getItem('FulfillmentShippingType') ? shippingTypes.find(r => r.type === localStorage.getItem('FulfillmentShippingType')) : shippingTypes[0])



  const [isFetchLoading, setFetchLoading] = useState(true);
  const [list, setList] = useState([]);
  const [Rows, setRows] = useState(0);

  const [limit] = useState(30);


  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManage, setIsOpenManage] = useState(false);
  const [isOpenNegotiation, setIsOpenNegotiation] = useState(false);
  const [manageDetails, setManageDetails] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});
  const [needRefresh, setNeedRefresh] = useState(false);
  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});

  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));

  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);


  const dispatch = useDispatch();



  useEffect(() => {
    if(companyType !== lastCompanyType){
      setDynamicDateFilter([]);
      setDefaultParams(false);
      setDefaultParamsUsed(false);
    }
    // eslint-disable-next-line
  }, [companyType]);

  useEffect(() => {
    localStorage.setItem('FulfillmentShippingType', shippingType.type)
    setIsCheck([]);
    // eslint-disable-next-line
  }, [shippingType]);

  useEffect(() => {
    localStorage.setItem('FulfillmentType', activeGroup)
    setIsCheck([]);
    // eslint-disable-next-line
  }, [activeGroup]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    async function fetchShippings() {
      if (loggedCompany) {
        setFetchLoading(true);
        try {

          const r = await getElementBody(getPathParam([COMPANY, loggedCompany.id, FULFILLMENTS]), signal, 
            {
              limit: limit,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              fulfillment_type: activeGroup,
              shipping_type: shippingType.type,
              remove_default: !defaultParamsUsed,
              company_type: companyType
            },
            activeDynamicFilter
        );

          if(query.length > 0 || offset === 0){
            setList(r.fulfillments);
            setRows(r.total_rows);
          }else{
            if(r.total_rows > 0 ){
              setList(r.fulfillments);
              setRows(r.total_rows);
            }else{
              setList([])
            }
          }
          if(r.filters){
            setDynamicFilters(r.filters);
          }

          if(r.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
            setDefaultParams('?'+r.default_filter);
          }
          setLastCompanyType(companyType);
          setNeedRefresh(false);
        } catch (e) {
          setList([]);
          setRows(0);
          if(signal.aborted){
            setFetchLoading(true);
          }
        } finally {
          if(signal.aborted){
            setFetchLoading(true);
          }else{
            setFetchLoading(false);
          }
        }
      }
    }

    fetchShippings();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, offset, query, loggedCompany, companyType, needRefresh, limit, activeDynamicFilter, activeGroup, shippingType]);

    const handleSelectAll = () => {
      if (list.filter(r => !r.has_booking) > 0) {
            setSelectAll(!selectAll);
             setIsCheck(list.filter(r => !r.has_booking));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };


    const handleClick = e => {
      const { id, checked } = e.target;
      setSelectAll(false);
      let fList = list.find(r => r.id === id);
      setIsCheck([...isCheck, fList]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.fulfillment.page_title")}>
            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.CREATE)]}>
              <ActionButton
                icon={
                  <PlusIcon
                    className="w-4 h-4 "
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                }}
                text={t("app.fulfillment.add_new")}
              />
            </BasePermissionWrapper>
          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

          <UnderlineTab
            tabs={tabs}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />

          <ListSection
            pageLimit={limit}
            title={t("app.common.customer") + "/" + t("app.common.supplier")}
            columns={columns}
            totalRows={Rows}
            list={list}
            onSearch={setQuery}
            isLoading={isFetchLoading}
            paginationOnChange={setOffset}
            selectAll={selectAll}
            isActiveSelectAll={false}
            handleSelectAll={handleSelectAll}
            isDynamicPagination={true}
            hasCheckbox={true}
            mobileRow={FulfillmentMobRow}
            desktopRow={FulfillmentDeskRow}
            isTableSort={true}
            sortBy={sortBy}
            onSortBy={(n) => {
              setSortBy(n);
            }}
            activeDynamicFilter={true}
            extras={{
              onRemove: (r, detail) => {
                setOpenRemove(r);
                setRemoveDetail(detail);
              },
              onManage: (r, element) => {
                setManageDetails(element);
                setIsOpenManage(r);
              },
              isCustomConfig: false,
              isCheck,
              handleClick,
              isSubscriptionActive: hasSubscriptionPermission(subscriptions, sp.FULFILLMENT_INTERNAL_PACKAGE),
              isLinked: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.EDIT),
              canView: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.VIEW),
              canDelete: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.DELETE),
            }}
          >

            <fieldset className="min-w-36">
              <RadioGroup value={shippingType} onChange={setShippingType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                {shippingTypes.map((option) => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active, checked }) =>
                      `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                       ${(shippingType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                    }
                  >
                    <option.icon className="h-5 w-5" />
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </fieldset>

            {
              (isCheck.length > 0) && (
                <div className="inline-flex items-center justify-center">
                  <div className={"ml-3"}>
                    <WhiteButton
                      onClick={() => {
                        setIsOpenNegotiation(true)
                      }}
                      icon={
                        <ScaleIcon
                          className="w-4 h-4"
                          aria-hidden="true"
                        />
                      }
                      text={t('app.fulfillment.create_booking', { values: isCheck.length })}
                    />
                  </div>

                </div>
              )
            }



            <DynamicFilterMenu
                filters={dynamicFilters}
                defaultParams={defaultParams}
                lastCompanyType={lastCompanyType}
                isLoading={isFetchLoading && offset === 0}
                filterType={'fulfillment'}
                onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);

                }}
            />
          </ListSection>
        </div>

        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.DELETE)]}>
          {isOpenRemove && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  onRemoveConfirmed={() => {
                    setNeedRefresh(!needRefresh);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                  }}
              />
          )}
        </BasePermissionWrapper>


        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.CREATE)]}>
          {isOpen && 
            <AddFulfillment isOpen={isOpen} setIsOpen={setIsOpen} 
              onOpenManage={(r) => {
                setActiveGroup(localStorage.getItem('FulfillmentType'));
                setShippingType(shippingTypes.find(r => r.type === localStorage.getItem('FulfillmentShippingType')));
                setManageDetails(r);
                setIsOpenManage(true);
                setNeedRefresh(!needRefresh);
              }} 
            />
          }
        </BasePermissionWrapper>


        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.VIEW)]}>
          <ManageFulfillment isOpen={isOpenManage} setIsOpen={setIsOpenManage} manageDetails={manageDetails} onRefresh={ () => {setNeedRefresh(!needRefresh)}} />
        </BasePermissionWrapper>
        
        
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.VIEW)]}>
          <FulfillmentNegotiation isOpen={isOpenNegotiation} setIsOpen={setIsOpenNegotiation} shippingType={shippingType} fulfillmentType={activeGroup} fulfillments={isCheck}  />
        </BasePermissionWrapper>





      </div>
    </BasePermissionWrapper>
  );
};

export const FulfillmentMobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const {  loggedCompany, isExporter } = useSelector((s) => s.user);

  const removeDetail = {
    title: t("app.shippings.remove_title"),
    message: t("app.shippings.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, isExporter ? SHIPPING : SHIPPING_IMPORTER, element.id]),
    redirect: getWebPathParam([LOGISTICS, SHIPPINGS]),
  };

  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={false}
        to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">

          <div className={"flex items-center"}>

            <div className="mr-4">
              {
                (element.shipping_type && (element.shipping_type.code === LAND)) ? (
                    <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                ) : (
                    <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                )
              }
            </div>

            <CustomerCell customer={element.connection} extra={''} />
          </div>
          <ActionsMenu>
            { extras.isSubscriptionActive && extras.isLinked && (
              <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_DETAIL])}>
                  {t("app.common.edit")}
                </Link>
            )}
            {extras.canView && (
              <Link
                to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_OVERVIEW])}
              >
                {t("app.common.overview")}
              </Link>
            )}
            {(extras.isSubscriptionActive && extras.canDelete) && (
                <button className={'text-left'} onClick={() => {
                  extras.onRemove(true, removeDetail);
                }}
                >
                  {t("app.common.remove")}
                </button>
            )}
          </ActionsMenu>
        </div>

        <div className="flex flex-col">

         


        </div>
      </OptionalLink>
    </li>
  );
};

export const FulfillmentDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);
  const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;

  const removeDetail = {
    title: t("app.common.remove_generic_title"),
    message: t("app.common.remove_generic_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, FULFILLMENT, element.id])
  };


  const canCreate = () => {
      let disabled = false;

    if (!extras.isCustomConfig){
      if (element.has_booking) {
        disabled = true;
      }

      if (element.combination_count === 0) {
        disabled = true;
      }



      if ((element.shipping_type && (element.shipping_type.code === SEA))) {
        if (!element.departure_port || !element.destination_port) {
          disabled = true;
        }
      } else {
        if (!element.connection_address) {
          disabled = true;
        }
      }
    }
  
    return disabled;

  }



  return (
    <tr key={element.id} className={(isCheck) ? 'bg-solitude-100' : ''}>
      <TableDetail extraClass={classNames(
          isCheck ? "relative  bg-solitude-important" : "relative",
          "max-w-lg"
      )}>

        <div className="flex items-center">

          {
            (isCheck) && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
            )
          }
          <input type="checkbox"
            id={element.id}
            disabled={canCreate()}
            onChange={extras.handleClick} checked={isCheck}
            className=" mr-3 left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed" />


          <div className="flex items-center space-x-3 lg:pl-2">
            <div
              onClick={() => {
                extras.onManage(true, element);
              }}
              className="truncate hover:text-gray-600 cursor-pointer"
            >
              <CustomerCell customer={element.connection} secondExtra={element.internal_reference} extra={''} />
            </div>
          </div>
        </div>

       
      </TableDetail>

      <TableDetail>

        <div className={"flex items-center"}>
          <div className="mr-4">
            {
              (element.shipping_type && (element.shipping_type.code === LAND)) && (
                <TruckIcon className={'w-5 h-5 text-blue-1000'} />
              )
            }

            {
              (element.shipping_type && (element.shipping_type.code === SEA)) && (
                <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
              )
            }
            {
              (element.shipping_type && (element.shipping_type.code === AIR)) && (
                <AirIcon className={'w-5 h-5 text-blue-1000'} />
              )
            }
          </div>
          <div>
            <div className="text-sm text-gray-900 capitalize">
              {element?.destination?.name ?? t("app.container.container_not_defined")}
            </div>
            {
              element.destination && (
                <div className="text-xs text-gray-500 capitalize">
                  {element?.destination?.country ?? '-'}
                </div>
              )
            }
            
          </div>
        </div>
      </TableDetail>

      <TableDetail>
        {element.reference ? element.reference : t("app.container.container_not_defined")}
      </TableDetail>

      <TableDetail>
        {element.expected_date ? getFormattedDate(element.expected_date) : t("app.container.container_not_defined")}
      </TableDetail>

      <TableDetail>
        {
          element.status && (
            <StatusBarIcon status={(element.fulfillment_type === DELIVERY ? 'd_' : 'p_')+element.status} />
          )
        }
      </TableDetail>

      <TableDetail>
        {
          element.user && (
            <UsersToolbar users={[element.user]} isActive={([element.user]).length > 1} extraData={getCustomFormat(element.created_date, 'DD/MM/YYYY')} className="relative">
              <div className="flex items-center space-x-2">
                <div className={"flex items-center gap-1 text-sm"}>
                  <UserInitial user={element.user} ringClass={" ring-yellow-400 "} />
                  <div className="text-left ml-1">
                    <div className="truncate text-sm text-gray-900">
                      {element?.user?.fullname ?? ''}
                    </div>
                    <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{ fontSize: '11px' }}>
                      {t("app.quotations.created_on", { date: getCustomFormat(element.created_date, 'DD/MM/YYYY') })}
                    </div>
                  </div>
                </div>
              </div>
            </UsersToolbar>
          )
        }
      </TableDetail>

      {
        !extras.isCustomConfig && (
          <TableDetail>
            <ActionsMenu>
              {extras.isSubscriptionActive && extras.isLinked && (
                <button className={'text-left'} onClick={() => {
                  extras.onManage(true, element);
                }}
                >
                  {t("app.common.edit")}
                </button>
              )}
              {(extras.isSubscriptionActive && extras.canDelete) && (
                <button className={'text-left'} onClick={() => {
                  extras.onRemove(true, removeDetail);
                }}
                >
                  {t("app.common.remove")}
                </button>
              )}
            </ActionsMenu>
          </TableDetail>
        )
      }
     
    </tr>
  );
};

export default Fulfillment;
