import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid/esm";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { hasPermissionFor } from "../restricted/base-permission-wrapper";

function SidebarLink({ item, currentPath, open, onOpenMenu }) {
  const { permissions, subscriptions, user_filters, menuCounter } = useSelector((s) => s.userCompany);
  const { companyType, isExporter } = useSelector((s) => s.user);
  const { t } = useTranslation();

  const [counters, setCounters] = useState(menuCounter);

  const active =
      currentPath === item.indexLocation ||
      currentPath.includes(item.locationGroup);


  useEffect(() => {
    if(currentPath === item.indexLocation ||
        currentPath.includes(item.locationGroup)){
      onOpenMenu(item.name);
    }
    // eslint-disable-next-line
  }, [currentPath]);


    useEffect(() => {
        setCounters(menuCounter);
        // eslint-disable-next-line
    }, [menuCounter]);



    const getMenuCounter = (label) => {
        return counters?.find(r => r.label === label)?.value ?? 0
    };


  const handleClick = (e) => {
    onOpenMenu(e);
  };


  const checkMenu = (item) => {
      if(item?.submenu ?? false){
          let list = Object.entries(item.submenu).filter(([k, _]) => k in (permissions?.navigation ?? {}));
          if(list.length > 0){

              let show = false;
              // eslint-disable-next-line
              list.map(([_, subItem]) => {
                  if((((!isExporter && subItem.hideForImporter === false) || isExporter) && match(subItem))){
                      show = true;
                  }
              })

              return show;
          }
      }
      return true;
  }

  const match = (item) => {
      if(item.subscriptionGroup.length === 0){
          return true;
      }

      return (subscriptions ?? []).filter(subscription => {
          return item.subscriptionGroup.find((group) => {
              return subscription === group
          });
      }).length > 0
  }

  return (


      match(item) && checkMenu(item) && (
          <li
              className={`group pl-2 ${
                  active
                      ? "text-white bg-white/[0.06] border-r-4 border-sky-400"
                      : "text-white hover:bg-white/[0.06] hover:border-r-4 hover:border-sky-400"
              }`}
          >
            <NavLink
                key={item.name}
                to={item.location + (user_filters?.find(r => r.filter_type === item.name && r.company_type === companyType)?.filter ?? '')}
                onClick={() => {
                  item.submenu && handleClick(open === item.name ? false : item.name);
                }}
                className={`block`}
            >
              <div className="flex items-center justify-between px-2 py-2 ">
                <div
                    className={`flex items-center text-sm font-normal ${
                        active ? "text-white" : "text-white/[0.4] group-hover:text-white"
                    }`}
                >
                  <item.icon
                      className={`mr-3 flex-shrink-0 h-5 w-5 ${
                          active ? "text-sky-400" : "group-hover:text-sky-400"
                      }`}
                      aria-hidden="true"
                  />
                  {t(`app.menu.${item.name}`)}
                </div>

                {item.submenu && (
                    <div className="flex shrink-0 ml-2">
                      <ChevronDownIcon
                          className={`w-5 h-5 shrink-0 ml-1 fill-current text-gray-400 ${
                              open === item.name && "transform rotate-180"
                          }`}
                      />
                    </div>
                )}
              </div>
            </NavLink>
            {item.submenu && (
                <div className="lg:sidebar-expanded:block 2xl:block">
                  <ul className={`pl-9 mt-1 ${open === item.name ? "block" : "hidden"}`}>
                    {Object.entries(item.submenu)
                        .filter(([k, _]) => k in (permissions?.navigation ?? {}))
                        .map(([_, subItem]) => (
                            (((!isExporter && subItem.hideForImporter === false) || isExporter) && match(subItem)) && (
                                <li className="mb-1 last:mb-0" key={subItem.name}>

                                    {
                                        subItem.countAlertLabel && menuCounter.length > 0 ? (
                                            <div className="flex justify-between items-center">
                                                <NavLink
                                                    to={subItem.location + (user_filters?.find(r => r.filter_type === subItem.name && r.company_type === companyType)?.filter ?? '')}
                                                    className={(res) =>
                                                        "group flex items-center px-2 py-2 text-sm font-normal " +
                                                        (res.isActive || currentPath.includes(subItem.name)
                                                            ? " text-white"
                                                            : "  hover:text-white text-white/[0.4]")
                                                    }
                                                >
                                                    {t(`app.menu.${subItem.name}`)}
                                                </NavLink>
                                                
                                                
                                                {
                                                    getMenuCounter(subItem.countAlertLabel) > 0 && (subItem.countAlertPermissionSpecific && subItem.countAlertPermissionGroup && hasPermissionFor(permissions, subItem.countAlertPermissionGroup, subItem.countAlertPermissionSpecific)) && (
                                                        <span className="inline-flex mr-3 items-center  justify-center  text-xs font-extrabold h-6 w-6 leading-none text-white bg-red-500 rounded-full">
                                                            {getMenuCounter(subItem.countAlertLabel) > 9 ? "+9" : getMenuCounter(subItem.countAlertLabel)}
                                                        </span>
                                                    )
                                                }

                                            </div>
                                        ) : (
                                                <NavLink
                                                    to={subItem.location + (user_filters?.find(r => r.filter_type === subItem.name && r.company_type === companyType)?.filter ?? '')}
                                                    className={(res) =>
                                                        "group flex items-center px-2 py-2 text-sm font-normal " +
                                                        (res.isActive || currentPath.includes(subItem.name)
                                                            ? " text-white"
                                                            : "  hover:text-white text-white/[0.4]")
                                                    }
                                                >
                                                    {t(`app.menu.${subItem.name}`)}
                                                </NavLink>
                                        )
                                    }


                                  
                                </li>
                            )

                        ))}
                  </ul>
                </div>
            )}
          </li>
      )



  );
}

export default SidebarLink;
