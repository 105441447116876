import React, {useState} from 'react'
import noImage from "../../../resources/images/placeholder.jpeg";
import {useTranslation} from "react-i18next";
import {WhiteButton} from "../../layout/page-header";
import GenerateSignature from "./generate-signature";

export default function SignatureCard({label, picture = false, onSave, onRemove, showRemove = true, isUpdateLoading = false}) {

    const {t} = useTranslation();

    const [isOpenGenerate, setOpenGenerate] = useState(false);
    const [fileUrl, setFileUrl] = useState(picture ? picture : noImage);
    const [removeBtn, setRemoveBtn] = useState(false);

    return (
        <div>
            <label htmlFor="photo" className="block text-sm font-medium mb-1 text-gray-700">{label}</label>
            <div className="flex items-center">
                <img className="inline-block w-24"
                     src={fileUrl}
                     alt="" />

                <div className="ml-4 flex">

                    {
                        isUpdateLoading ? (
                            <button className={"btn bg-indigo-500 hover:bg-indigo-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"} disabled>
                                <svg className="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                                <span className="ml-2">{t("app.common.loading")}</span>
                            </button>
                        ) : (
                            <WhiteButton
                                text={t("app.company.change")}
                                onClick={() => {
                                    setOpenGenerate(true);
                                }}
                            />

                        )
                    }





                    { showRemove && (
                        picture && removeBtn === false) && (
                        <button type="button"
                                onClick={() => {
                                    setFileUrl(noImage);
                                    setRemoveBtn(true);
                                    onRemove(true);
                                }}
                                className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">{t("app.common.remove")}</button>

                        )
                    }

                </div>


            </div>


            {
                isOpenGenerate && (
                    <GenerateSignature
                        isOpen={isOpenGenerate}
                        setIsOpen={ () => {
                            setOpenGenerate(false);
                        }}
                        onSave={(r) => {
                            setFileUrl(r);
                            onSave(r);
                        }}
                    />

                )
            }


        </div>
    )
}
