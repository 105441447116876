export default function CustomerIcon(props) {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="transparent" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11.8386 14.8255C15.3802 14.8255 18.2513 11.9544 18.2513 8.41274C18.2513 4.87108 15.3802 2 11.8386 2C8.29693 2 5.42585 4.87108 5.42585 8.41274C5.42585 11.9544 8.29693 14.8255 11.8386 14.8255Z" strokeWidth="2.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.66652 23.744C1.66498 23.2933 1.76595 22.8481 1.96179 22.4421C2.57515 21.2141 4.30785 20.5631 5.74528 20.2679C6.78176 20.0464 7.83258 19.8984 8.88991 19.8249C10.8479 19.6528 12.8172 19.6528 14.7752 19.8249C15.8315 19.9001 16.8824 20.0477 17.9198 20.2679C19.3559 20.5631 21.0886 21.1523 21.7047 22.4421C21.8991 22.851 22 23.298 22 23.7507C22 24.2034 21.8991 24.6505 21.7047 25.0593C21.0886 26.3477 19.3559 26.9383 17.9198 27.2201C16.8837 27.451 15.8328 27.604 14.7752 27.6765C13.1807 27.8107 11.5809 27.8348 9.98376 27.7489C9.61467 27.7489 9.259 27.7489 8.88991 27.6751C7.83568 27.6041 6.78843 27.4524 5.75736 27.2215C4.30785 26.9396 2.58857 26.3491 1.96179 25.0593C1.76663 24.6478 1.66575 24.1994 1.66652 23.744Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>



    );
}
