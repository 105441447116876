import React, {Fragment} from "react";
import {CloudUploadIcon, PencilIcon} from "@heroicons/react/outline";
import {Menu, Switch, Transition} from '@headlessui/react'
import ImportProgress from "../partials/upload/import-progress";
import CircleBtn from "../common/button/circle-btn";
import {joinClassNames} from "../../utils/converter";
import {useTranslation} from "react-i18next";
import BasePermissionWrapper from "../partials/restricted/base-permission-wrapper";
import SystemTasks from "../partials/header/system-task";
import CompanyTypeSwitch from "../partials/header/company-type-switch";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PageHeader({ title, canSwitch = false, customTitle = false, taskContext = false, taskContextId = false, children, subtitle = false, isActiveEdit = false, onClickEdit =null,  progressData = null, onSubtitleClick, lastImport = false, importType = false, importGroup = false, onNeedRefresh, importEntityId = false }) {
  return (
      <div className="flex justify-between items-center flex-wrap gap-4 mb-6 ">
      {/* Left: Title */}


          <div className="flex  items-center justify-between md:justify-start w-full md:w-auto">
              <div>
                  <div className='flex items-center'>


                      {
                          taskContext && (
                              <div className='mr-2'>
                                  <SystemTasks taskContext={taskContext} taskContextId={taskContextId} />
                              </div>
                          )
                      }



                      {
                          isActiveEdit ? (
                              <div className="flex justify-between items-center ">
                                  <CircleBtn
                                      icon={PencilIcon}
                                      onClick={onClickEdit}
                                      extraClass={"bg-white text-gray-800 border border-gray-200 shadow-md  hover:bg-gray-200"}
                                  />
                                  {
                                      title && (
                                          <h1 className="ml-2 text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                                              {title}
                                          </h1>
                                      )
                                  }

                                  {
                                      customTitle && (
                                          <>{customTitle}</>
                                      )
                                  }

                              </div>
                          ) : (

                              <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                                  {title}
                              </h1>

                          )
                      }


                  </div>

                  {
                      importGroup && (
                          <BasePermissionWrapper
                              requiredPermissions={importGroup}
                              requireExporter={true}
                          >
                              {subtitle && (
                                  <p className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                      <span onClick={() => { onSubtitleClick() }}>{subtitle}</span>
                                  </p>
                              )}
                          </BasePermissionWrapper>
                      )
                  }
              </div>
              {
                  canSwitch && (
                      <CompanyTypeSwitch />
                  )
              }
          </div>

        {
            importGroup && (
                <BasePermissionWrapper
                    requiredPermissions={importGroup}
                    requireExporter={true}
                >
                    <ImportProgress
                        onNeedRefresh={onNeedRefresh}
                        lastImport={lastImport}
                        importType={importType}
                        importEntityId={importEntityId}
                    />
                </BasePermissionWrapper>
            )
        }


      {/* Right: Actions */}
      <div className="md:flex md:items-center md:justify-end gap-2 w-full md:w-auto">
        {children}
      </div>
    </div>
  );
}

export const ImportButton = ({ text, icon = null, onClick, disabled = false, extraClass = '' }) => (
  <button
      disabled={disabled}
        className={"btn bg-green-600 hover:bg-green-700 text-white rounded-full w-full md:w-auto disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none " + extraClass}
    onClick={onClick}
  >
      {icon ? (icon) : (<CloudUploadIcon className="w-4 h-4" aria-hidden="true" />)}
      {text && (<span className="ml-2">{text}</span>)}
  </button>
);



export const ImportExportButton = ({ buttonExtraClass = '', extraClass  = ' ', classExtra = '', onImport, onExport, canImport = false, canExport = false }) => {

    const { t } = useTranslation();

    return (
        <Menu as="div" className="inline-block w-full md:w-auto">
            <Menu.Button
                className={"btn bg-green-600 hover:bg-green-700 text-white rounded-full w-full md:w-auto disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none " + buttonExtraClass}
            >
                <CloudUploadIcon className="w-4 h-4" aria-hidden="true" />
                <span className="ml-2"> {t("app.products.ie_products")}</span>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={(extraClass) +  "origin-top-right z-10  absolute text-left mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "+(classExtra)}>
                    <div className="py-1">

                        {
                            canImport && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={joinClassNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "px-4 py-2 text-sm flex flex-col"
                                            )}
                                        >
                                            <button className={'text-left'} onClick={() => {
                                                onImport();
                                            }}>
                                                {t("app.products.import_products")}
                                            </button>
                                        </div>
                                    )}
                                </Menu.Item>
                            )
                        }




                        {
                            canExport && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={joinClassNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900"
                                                    : "text-gray-700",
                                                "px-4 py-2 text-sm flex flex-col"
                                            )}
                                        >
                                            <button className={'text-left'} onClick={() => {
                                                onExport();
                                            }}>
                                                {t("app.products.export_products")}
                                            </button>
                                        </div>
                                    )}
                                </Menu.Item>
                            )
                        }





                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )


};


export const SendButton = ({ text, icon = null, onClick }) => (
    <button
        className="btn bg-green-600 hover:bg-green-700 text-white"
        onClick={onClick}
    >
        {icon ? (icon) : (<CloudUploadIcon className="w-4 h-4" aria-hidden="true" />)}
        {text && (<span className="ml-2">{text}</span>)}
    </button>
);

export const ActionButton = ({ text, icon = '', onClick, disabled = false, extraClass = '' }) => (
  <button
    disabled={disabled}
        className={"btn bg-blue-1000 hover:bg-blue-900 rounded-full text-white w-full md:w-auto mx-2s md:ml-2 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none " + extraClass}
    onClick={onClick}
  >
    {icon}
    <span className={icon !== '' ? "ml-2" : ''}>{text}</span>
  </button>
);

export const FilterButton = ({ isRightIcon = false, text, disabled = false, icon, onClick }) => (
    <button
        className="btn border-blue-1000 text-blue-1000 hover:bg-blue-900 hover:text-white ml-2 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
        onClick={onClick}
        disabled={disabled}
    >
        {!isRightIcon && (<>{icon}</>)}
        {text && (<span className="ml-2">{text}</span>)}
        {isRightIcon && (<>{icon}</>)}
    </button>
);

export const InfoButton = ({ text, icon, onClick }) => (
    <button
        className="btn p-2 shadow-none text-blue-1000  hover:bg-blue-1000 hover:text-white"
        onClick={onClick}
    >
        {icon}
        {text && (<span className="ml-2">{text}</span>)}
    </button>
);


export const TrashButton = ({ text, icon, onClick, isFull = false }) => (
    <button
        className={isFull ? "btn text-sm bg-red-500 hover:bg-red-700 text-white w-full" : "btn text-sm bg-red-500 hover:bg-red-700 text-white"}
        onClick={onClick}
    >
        {icon}
        {text && (<span className="ml-2">{text}</span>)}
    </button>
);

export const WhiteButton = ({ text, icon, onClick, extraClassName }) => (
    <div
        className={"btn text-sm bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 text-gray-900 cursor-pointer "+extraClassName}
        onClick={onClick}
    >
        {icon ? (icon) : ''}
        {text && (<span className={icon ? "ml-2" : "" }>{text}</span>)}
    </div>
);

export const ViewButton = ({ text, icon, onClick }) => (
    <button
        className="btn border-blue-1000 text-blue-1000 hover:bg-blue-900 hover:text-white mx-2"
        onClick={onClick}
    >
        {icon}
        <span className="ml-2">{text}</span>
    </button>
);

export const ActionToggle = ({title, isEnabled, onChange}) => (
    <Switch.Group as="div" className="flex items-center">


        <Switch.Label as="span" className="mr-3">
            <span className="text-sm font-medium text-gray-900">{title}</span>
        </Switch.Label>

        <Switch
            checked={isEnabled}
            onChange={() => {
                onChange(!isEnabled);
            }}
            className={classNames(
                isEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
        >
        <span
            aria-hidden="true"
            className={classNames(
                isEnabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
        />
        </Switch>

    </Switch.Group>
);
