import React, {Fragment, useState} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import {useTranslation} from "react-i18next";




export default function InputShippingLineSelect({
                                          isLoadingData,
                                          data,
                                          label = '',
                                          placeholder,
                                          disabled = false,
                                          isRequired = false,
                                          selectedItem = null,
                                          onSelect
}) {


    const [query, setQuery] = useState('');

    const { t } = useTranslation();



    const filteredData =
        query === ''
            ? data
            : data.filter((item) =>
                item.label
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )



    return (
        <div className="w-full">
            <Combobox disabled={disabled} value={selectedItem ? data.find(elem => elem.id === selectedItem.id) : null} onChange={(r) => {
                onSelect(r);
            }}>

                <div className="relative ">


                    <label className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm ">
                        <Combobox.Input
                            id={'test'}
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                            placeholder={placeholder}
                            displayValue={(item) => item?.label ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        {
                            !disabled && (
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Combobox.Button>
                            )
                        }

                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoadingData ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {filteredData.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredData.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (

                                                        <>
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                                                        <span className="font-medium leading-none text-white">
                                                                            <ShippingIcon className={'h-5 w-5 text-blue-100'} />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{item.data?.name ?? ''}</b></p>

                                                                    {
                                                                        (item.data && item.data.traceable) ? (
                                                                            <p className="text-xs font-semibold text-green-900">
                                                                                {t("app.shipping_tracking.traceable")}
                                                                            </p>
                                                                        ) : (
                                                                            <p className="text-xs font-semibold text-red-900">
                                                                                {t("app.shipping_tracking.not_traceable")}
                                                                            </p>
                                                                        )
                                                                    }


                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
