import React, {Fragment, useEffect, useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import InputText from "../../common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputDate from "../../common/form/input-date";
import InputSubmit from "../../common/form/input-submit";
import {Warning} from "../../common/alert/banner";
import {
    getCompanyReportTypes,
    getDynamicReport,
    getReport,
    putDynamic,
    putImport
} from "../../../api/report";
import {useParams} from "react-router-dom";
import ReportViewer from "./report-viewer";
import {getPathParam, getSelectParam, getSelectParams, getSelectSubParams} from "../../../utils/converter";
import FileCard from "../../common/card/file-card";
import {getFormattedDate} from "../../../utils/timeUtils";
import InputSelect from "../../common/form/input-select";
import {getReportType, LAND, SEA} from "../../../constants/config";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import InputEditor from "../../common/form/input-editor";
import InputRadioSimple from "../../common/form/input-radio-simple";
import InputFormattedNumber from "../../common/form/input-formatted-number";
import AutoSaveSignatureCard from "../upload/autosave-signature-card";
import InputToggle from "../../common/form/input-toggle";
import {useDispatch, useSelector} from "react-redux";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import {fetchCurrencies, fetchLanguages} from "../../../reducers/configReducer";
import ExchangeRateBtn from "../../common/form/exchage-rate-btn";
import {getElement} from "../../../api/config";
import {EXCHANGE} from "../../../api/endpoints";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PDF = 'pdf';
const EXCEL = 'excel';

const MultiGenerate = ({
   isOpen,
   setIsOpen,
   isSeaType = false,
   isDynamic = false,
   params = false,
   reportParams = false,
}) => {

    const { t } = useTranslation();
    let {id}  = useParams();
    const dispatch = useDispatch();

    const shippingType = isSeaType ? SEA : LAND;


    const { loggedCompany } = useSelector((state) => state.user);
    const { isLanguagesLoading, languages, isCurrenciesListLoading, currenciesList } = useSelector((state) => state.config);
    const { company } = useSelector((state) => state.userCompany);


    const [isLoadingExchange, setLoadingExchange] = useState(false);



    // company report Types
    const [isLoadingTypes, setLoadingTypes] = useState(true);
    const [exception, setException] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const [reportType, setReportType] = useState(false);


    // config
    const [documentType, setDocumentType] = useState(PDF);
    const [reportDetail, setReportDetail] = useState(false);

    // report data

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [reportData, setReportData] = useState(false);

    const [isUpdateExtraData, setUpdateExtraData] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isOpenReportView, setOpenReportView] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm();


    const generateReport = (data) => {
        setLoading(true);
        setException(false);

        if(isDynamic){
            putDynamic({ report_type: reportDetail.type, entity: reportDetail.entity, entity_id: id, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(response);
                    setOpenReportView(true);
                    setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }else{
            putImport({ shipping_id: id, report_type: reportDetail.type, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(response);
                    setOpenReportView(true);
                    setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }


    };


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        function fetchReport() {
            if (reportDetail && isOpen && !isLoadingTypes) {
                setLoadingDetails(true);
                setException(false);

                let param = params ? Object.assign({}, params, {file_type: documentType}) : {file_type: documentType};


                (isDynamic ?
                    getDynamicReport({entity: reportDetail.entity, entity_id: id, report_type: reportDetail.type, params: param , signal }) :
                    getReport({ shipping_id: id, report_type: reportDetail.type, params: param , signal }))
                        .then((response) => {
                            let flag = false;

                            if(response.parameters) {

                            for (const n in reportDetail.fields) {
                                let field = reportDetail.fields[n];
                                if (response.parameters[field.name]) {
                                    let value = response.parameters[field.name];

                                    if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                        delete reportDetail.fields[n];
                                    } else {
                                        if (typeof value === "object") {
                                            if(field.name === 'currency'){
                                                flag = true;
                                                setValue(field.name, getSelectParam(value, "code"));
                                            }else{
                                                setValue(field.name, getSelectParam(value, "name"));
                                            }
                                        } else {
                                            setValue(field.name, value);
                                        }
                                    }
                                }else{
                                    if (response.id && field.hideIfValidate) {
                                        delete reportDetail.fields[n];
                                    }
                                }
                            }
                        }


                        if(!flag){
                            setValue('currency', getSelectParam(company.currency, "code"));
                        }

                        setReportData(response);
                        setLoadingDetails(false);
                    })
                    .catch((e) => {
                        setReportData(false);
                        setLoadingDetails(false);
                    });
            }
        }

        fetchReport();
        dispatch(fetchLanguages());
        dispatch(fetchCurrencies());

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [reportDetail, id, isOpen, setValue, isLoadingTypes, documentType, dispatch]);


    // Company Report Types
    useEffect(() => {
        function fetchReportTypes() {
            if (!reportDetail && isOpen && loggedCompany) {
                setLoadingTypes(true);
                setException(false);
                getCompanyReportTypes({ company_id: loggedCompany.id, params: reportParams })
                    .then((response) => {
                        setReportTypes(response);
                        setLoadingTypes(false);
                    })
                    .catch((e) => {
                        setReportTypes([]);
                        setLoadingTypes(true);
                    });
            }
        }

        fetchReportTypes();
    }, [reportDetail, isOpen, reportParams, loggedCompany]);


    const getExchangeRate = async (from, to) => {
        setLoadingExchange(true);
        try {
            const res = await getElement(getPathParam([EXCHANGE, from, to ]));
            setValue('currency_change_rate', res.rate);
        } catch (e) {

        } finally {
            setLoadingExchange(false);
        }
    }


    const clean = () =>{
        setReportDetail(false);
        setReportType(false);

        if(reportData && reportData.parameters){
            for (const k in reportData.parameters) {
                setValue(k, null);
            }
        }

        setReportData(false);
        setUpdateExtraData(false);
        setValue("report_type", null);
        setException(false);





    };


    const onSubmit = async (detail) => {

        let data = detail;
        if(params){
            data = {...data, ...params}
        }
        generateReport(data);
    };

    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={reportData?.file ?? false}
                title={reportData?.media_file ?? ''}
                entityId={id}
                type={'report_log'}
                isActiveConfirm={reportDetail?.config?.isConfirmable ?? false}
                reportId={reportData?.id ?? ''}
                isReportConfirmed={reportData?.confirmed ?? false}
                onUpdateData={(r) => {
                    setReportData(r);
                }}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />


            <SlidePanel
                title={t("app.report.generate_report")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bg={'bg-white'}
                size={'max-w-2xl'}
                setClean={() => {
                    clean();
                }}
            >

                <div className="space-y-6 pt-6 pb-5">
                    <LoaderWrapper isLoading={isLoadingDetails}>

                        <>

                            {
                                reportType && (reportType.generation_type || reportType.available_language || reportType.company_templates.filter(r => r.file_type === documentType).length > 0) && (
                                    <div>
                                        <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.report_config")}</h1>



                                        {
                                            (reportType.generation_type && reportType.generation_type.split("|").length > 1) && (
                                                <div className="grid grid-cols-2  mb-6 flex space-x-1 bg-solitude-400 p-1 items-center text-center">

                                                    {
                                                        reportType.generation_type.split("|").map((data, index) => (
                                                            <div
                                                                key={index}
                                                                onClick={() => {
                                                                    setValue('file_type', data);
                                                                    setDocumentType(data);
                                                                }}
                                                                className={
                                                                    classNames(
                                                                        'rounded-sm py-1 text-sm font-medium  leading-5 cursor-pointer px-2 uppercase',
                                                                        documentType === data
                                                                            ? 'bg-white shadow text-blue-1000'
                                                                            : 'text-blue-1000 hover:bg-white hover:text-blue-1000'
                                                                    )
                                                                }
                                                            >
                                                                {data}
                                                            </div>
                                                        ))
                                                    }

                                                </div>

                                            )
                                        }


                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                            {
                                                reportType.available_language && (
                                                    <div className={reportType.company_templates.filter(r => r.file_type === documentType).length > 0 ? "col-span-4 md:col-span-2" : "col-span-4"}>
                                                        <Controller
                                                            name="file_lang"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({
                                                                         field: { onChange, value, name },
                                                                         fieldState: { error },
                                                                     }) => (
                                                                <InputSelect
                                                                    label={t("app.common.language")}
                                                                    name={name}
                                                                    options={languages.filter(elemento => (reportType.available_language.split("|")).includes(elemento.code))}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        setValue('lang', e.code);
                                                                    }}
                                                                    isLoading={isLanguagesLoading}
                                                                    isDisabled={isLanguagesLoading}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            }


                                            {
                                                reportType.company_templates.filter(r => r.file_type === documentType).length > 0 && (
                                                    <div className="col-span-4 md:col-span-2">
                                                        <Controller
                                                            name="company_template"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({
                                                                         field: { onChange, value, name },
                                                                         fieldState: { error },
                                                                     }) => (
                                                                <InputSelect
                                                                    label={t("app.report.template")}
                                                                    name={name}
                                                                    options={getSelectParams(reportType.company_templates.filter(r => r.file_type === documentType), 'name')}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    isLoading={false}
                                                                    isDisabled={false}
                                                                    isClearable={true}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            }


                                        </div>








                                        {
                                            documentType === EXCEL && (reportDetail?.configXlsParams ?? []).map(((field, index) => (
                                                <Fragment key={index}>
                                                    <div className="grid grid-cols-1 gap-y-5 gap-x-5 mt-3">
                                                        {
                                                            field.type === 'checkbox' && (
                                                                <Controller
                                                                    name={field.name}
                                                                    rules={{ required: field.required }}
                                                                    control={control}
                                                                    render={({
                                                                                 field: { onChange, value, name },
                                                                                 fieldState: { error },
                                                                             }) => (
                                                                        <InputToggle
                                                                            label={t(field.label)}
                                                                            description={t(field.description)}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                            }}
                                                                            defaultChecked={false}
                                                                            errors={error}
                                                                        />
                                                                    )}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </Fragment>
                                            )))
                                        }
                                    </div>

                                )
                            }


                            {
                                reportData.file && (
                                    <div>
                                        <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.generated_report")}</h1>
                                        <FileCard
                                            file={reportData.file}
                                            name={reportData.media_file}
                                            information={getFormattedDate(reportData.upload_date)}
                                            isReportConfirmed={reportData?.confirmed ?? false}
                                            onClick={() => {
                                                setOpenReportView(true);
                                            }}
                                        />
                                    </div>
                                )
                            }

                            {
                                reportDetail ? (
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                            <div className="col-span-4">
                                                <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.report_information")}</h1>
                                            </div>

                                            {
                                                reportDetail.fields.filter(r => (r.shipType === false || r.shipType === shippingType)).map(((field, index) => (
                                                    <Fragment key={index}>

                                                        {
                                                            field.type === 'string' && (
                                                                <div className="col-span-4 md:col-span-2">
                                                                    <InputText
                                                                        id={field.name}
                                                                        type={"text"}
                                                                        errors={errors[field.name]}
                                                                        input={{ ...register(field.name, { required: field.required }) }}
                                                                        label={t(field.translation)}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            field.type === 'date' && (
                                                                <div className="col-span-4 md:col-span-2">
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({field: { onChange, value, name }, fieldState: { error },}) => (
                                                                            <InputDate
                                                                                label={t(field.translation)}
                                                                                format={"YYYY-MM-DD HH:mm:ss"}
                                                                                placeholder={"Es. 01/12/2021"}
                                                                                activeMinDate={false}
                                                                                startDate={value}
                                                                                disabled={false}
                                                                                isShowTime={field?.config?.isActiveTime ?? false}
                                                                                errors={error}
                                                                                name={name}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        }


                                                        {
                                                            field.type === 'editor' && (
                                                                <div className="col-span-4">
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({field: { onChange, value, name },
                                                                                     fieldState: { error },
                                                                                 }) => (
                                                                            <InputEditor
                                                                                label={t(field.translation)}
                                                                                errors={error}
                                                                                defaultValue={value}
                                                                                name={name}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            field.type === 'radio-btn' && (
                                                                <div className="col-span-4">
                                                                    <InputRadioSimple
                                                                        name={field.group}
                                                                        errors={errors.default_type}
                                                                        input={{...register(field.group, { required: field.required })}}
                                                                        label={t(field.translation)}
                                                                        description={t(field.description)}
                                                                        value={field.name}
                                                                    />
                                                                </div>

                                                            )
                                                        }


                                                        {
                                                            field.type === 'number' && (
                                                                <div className="col-span-4 md:col-span-2">
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({
                                                                                     field: { onChange, value, name },
                                                                                     fieldState: { error },
                                                                                 }) => (
                                                                            <InputFormattedNumber
                                                                                label={t(field.translation)}
                                                                                name={name}
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                                prefix={field?.config?.prefix ?? ''}
                                                                                suffix={field?.config?.suffix ?? ''}
                                                                                errors={error}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>

                                                            )
                                                        }




                                                        {
                                                            field.type === 'currency' && (
                                                                <div className="col-span-4 md:col-span-2">
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({
                                                                                     field: { onChange, value, name },
                                                                                     fieldState: { error },
                                                                                 }) => (
                                                                            <InputSelect
                                                                                label={t(field.translation)}
                                                                                name={name}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                                options={currenciesList}
                                                                                value={value}
                                                                                isLoading={isCurrenciesListLoading}
                                                                                isDisabled={isCurrenciesListLoading}
                                                                                errors={error}
                                                                                isRequired={true}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>

                                                            )
                                                        }


                                                        {
                                                            field.type === 'change_rate' && (
                                                                <>
                                                                    {
                                                                        (watch('currency') && (company.currency.code !== watch('currency').code)) && (
                                                                            <>
                                                                                <div className="col-span-2">
                                                                                    <label className="block text-sm font-medium mb-1 text-gray-700">
                                                                                        {t("app.quotations.change_rate_title", {currency_1: '1 '+company.currency.code, currency_2:  '1'+watch('currency').code})}
                                                                                    </label>
                                                                                    <Controller
                                                                                        name={field.name}
                                                                                        rules={{ required: true }}
                                                                                        control={control}
                                                                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                                                            <ExchangeRateBtn
                                                                                                label={t("app.quotations.change_rate_label")}
                                                                                                isShowLabel={false}
                                                                                                name={name}
                                                                                                value={value}
                                                                                                onChange={onChange}
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                suffix={" " + (watch('currency').code ?? '')}
                                                                                                errors={error}
                                                                                                decimalScale={6}
                                                                                                isRequired={true}
                                                                                                isLoading={isLoadingExchange}
                                                                                                onClick={() => {
                                                                                                    getExchangeRate(company.currency.code, watch('currency').code)
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>

                                                            )
                                                        }




                                                        {
                                                            field.type === 'signature' && (
                                                                <div className="col-span-4">
                                                                    <AutoSaveSignatureCard
                                                                        onUpdateData={(r) => {
                                                                            setUpdateExtraData(r);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                    </Fragment>
                                                )))
                                            }

                                        </div>


                                        {/* Warning*/}
                                        {exception && (
                                            <Warning message={exception} />
                                        )}

                                        <div className="flex before:flex-1 items-center justify-between mt-6">
                                            <InputSubmit
                                                isLoading={isLoading}
                                                label={t("app.report.generate_file")}
                                                disabled={isUpdateExtraData}
                                                isFullWith={true}
                                            />
                                        </div>


                                        <div className="flex after:flex-1 items-center justify-between mt-6">
                                            <div
                                                onClick={() => {
                                                    clean();
                                                }}
                                                className="cursor-pointer text-sm underline inline-flex  items-center"
                                            >
                                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                {t("app.common.back")}
                                            </div>
                                        </div>



                                    </form>
                                ) : (
                                    <>
                                        <Controller
                                            name="report_type"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                <InputSelect
                                                    label={t("app.document.document_type")}
                                                    name={name}
                                                    options={getSelectSubParams(reportTypes, "document_type", "name")}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if(e){
                                                            setReportDetail(getReportType(e.code))
                                                            setReportType(reportTypes.find(r => r.code === e.code))
                                                            setValue('file_lang', languages.find(r => r.code === 'en'))
                                                        }
                                                    }}
                                                    isClearable={true}
                                                    isLoading={isLoadingTypes}
                                                    isDisabled={isLoadingTypes}
                                                    errors={error}

                                                />
                                            )}
                                        />


                                    </>
                                )
                            }


                        </>

                    </LoaderWrapper>
                </div>
            </SlidePanel>


        </>

    );
};



export default MultiGenerate;
