import React, { useEffect, useState } from "react";
import { Link, useParams, useOutletContext } from "react-router-dom";
import {
    SHIPPING,
    CONTAINER,
    CONTAINER_GENERAL_INFORMATION,
    SHIPPINGS, CONTAINERS,
    LOGISTICS,
} from "../../../endpoints";
import { getContainers } from "../../../../api/container";
import { useSelector } from "react-redux";
import ContainerIcon from "../../../../resources/icons/ContainerIcon";
import AddContainer from "./add-container";
import {getCustomFormat} from "../../../../utils/timeUtils";
import NumberFormat from "react-number-format";
import {getPathParam, getWebPathParam} from "../../../../utils/converter";
import ListFooter from "../../../../components/partials/footer/list-footer";
import { useTranslation } from "react-i18next";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../../../constants/permissions";
import ListSection from "../../../../components/layout/list-section";
import {CalendarIcon, DocumentReportIcon, TruckIcon} from "@heroicons/react/outline";
import OptionalLink from "../../../../components/common/table/optional-link";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import TableDetail from "../../../../components/common/list/table-detail";
import CardDetail from "../../../../components/common/list/card-detail";
import {SHIPPING_IMPORTER} from "../../../../api/endpoints";
import TableMenu from "../../../../components/common/table/table-menu";
import ReportGenerate from "../../../../components/partials/report/report-generate";
import {SHIPPING_REPORT} from "../../../../constants/config";
import {hasSubscriptionPermission} from "../../../../components/partials/restricted/subscription-wrapper";
import SubscriptionWrapper from "../../../../components/partials/restricted/subscription-wrapper";

const Containers = () => {
  const { loggedCompany, companyType, isExporter } = useSelector((state) => state.user);
  const { subscriptions, permissions } = useSelector((s) => s.userCompany);
  const isGenerateDocumentActive = hasSubscriptionPermission(subscriptions, sp.GENERATE_DOCUMENT_MARKETPLACE_PACKAGE);

  const canView =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.VIEW);
  const canDelete =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.DELETE);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.CREATE);


  const canGenerateDocument =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_GENERATE_DOCUMENT);

  const { t } = useTranslation();
  const { isOwner, isSeaType } = useOutletContext();

  const columns = [
    isSeaType ? t("app.container.container") : t("app.container.truck"),
    t("app.container.packaging_number"),
    t("app.container.volume"),
    t("app.container.gross_weight"),
    t("app.container.net_weight"),
    "",
  ];

  const tableNavigation = [
      canCreate && { name: isSeaType ? t("app.shippings.add_container") : t("app.shippings.add_truck"), permission: [getPermission(permissionGroup.CONTAINER, permissionType.CREATE)], actionType: 'add_container',   icon: isSeaType ? ContainerIcon : TruckIcon, isActive: true },
      (isExporter && isGenerateDocumentActive && false && canGenerateDocument) && { name: t("app.report.generate_report"),  permission: [getPermission(permissionGroup.CONTAINER, permissionSpecific.CONTAINER_GENERATE_DOCUMENT)], actionType: 'generate_report', icon: DocumentReportIcon, isActive: true },
  ].filter(Boolean);

  const [needRefresh, setNeedRefresh] = useState(false);
  const [isFetchContainersLoading, setFetchContainersLoading] = useState(true);
  const [containers, setContainers] = useState({
    containers: [],
    total_rows: 0,
  });
  let { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});
  const [isOpenReportGenerate, setOpenReportGenerate] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchContainers() {
      if (loggedCompany) {
        setFetchContainersLoading(true);
        getContainers({ shipping_id: id, params: { query: query, company_type: companyType }, signal })
          .then((response) => {
            setContainers(response);
            setFetchContainersLoading(false);
              setNeedRefresh(false);
          })
          .catch((e) => {
            setContainers({ containers: [], total_rows: 0 });
            setFetchContainersLoading(false);
          });
      }
    }

    fetchContainers();
    return () => {
      controller.abort();
    };
  }, [query, id, setFetchContainersLoading, loggedCompany, needRefresh, companyType]);

  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.LIST)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>
          <ListSection
              title={isSeaType ? t("app.container.containers") : t("app.container.trucks")}
              columns={columns}
              totalRows={containers.total_rows}
              list={containers.containers}
              onSearch={setQuery}
              isLoading={isFetchContainersLoading}
              mobileRow={ContainerMobRow}
              desktopRow={ContainerDeskRow}
              extras={{
                  isSeaType,
                  shippingId: id,
                  isOwner,
                  canView,
                  canDelete,
                  onRemove: (r, detail) => {
                      setOpenRemove(r);
                      setRemoveDetail(detail);
                  }
              }}
              setNeedRefresh={() => {setNeedRefresh(true)}}
          >
            {
                isOwner && (tableNavigation.length > 0) && (
                    <TableMenu
                        tableNavigation={tableNavigation}
                        setActiveGroup={(r) => {
                            if(r === 'add_container'){
                                setIsOpen(true);
                            }

                            if(r === 'generate_report'){
                                setOpenReportGenerate(true);
                            }
                        }}
                    />
                )
            }


        </ListSection>
          <ListFooter backLink={LOGISTICS + SHIPPINGS} backLabel={t("app.shippings.back")} />

        {isOpen && isOwner && canCreate && (
          <AddContainer
            isSeaType={isSeaType}
            isOpen={isOpen}
            setIsOpen={(r) => {
              setIsOpen(r);
            }}
          />
        )}

          {isOpenRemove && isOwner && canDelete && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  onRemoveConfirmed={() => {
                      setNeedRefresh(true);
                  }}
              />
          )}


          <SubscriptionWrapper requiredPermission={sp.GENERATE_DOCUMENT_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
              {
                  canGenerateDocument && (
                      <ReportGenerate
                          isOpen={isOpenReportGenerate}
                          isSpecific={false}
                          setIsOpen={(r) => {
                              setOpenReportGenerate(r);
                          }}
                          reportParams={{type: SHIPPING_REPORT}}
                      />
                  )
              }
          </SubscriptionWrapper>


      </BasePermissionWrapper>
  );
};

const ContainerMobRow = ({ element, extras}) => {
  const { t } = useTranslation();
  let { id } = useParams();

  const { isExporter } = useSelector((state) => state.user);

  const removeDetail = {
      title: extras.isSeaType ? t("app.container.remove_title") : t("app.container.remove_title_truck"),
      message: extras.isSeaType ? t("app.container.remove_description") : t("app.container.remove_description_truck"),
      endpoint: getPathParam([isExporter ? SHIPPING : SHIPPING_IMPORTER, id, CONTAINER, element.id]),
      redirect: getWebPathParam([LOGISTICS, SHIPPING, id, CONTAINERS]),
  };





    return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
          isLinked={extras.canView}
          to={getWebPathParam([LOGISTICS, LOGISTICS, SHIPPING, extras.shippingId, CONTAINER, element.id, CONTAINER_GENERAL_INFORMATION])}
          className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
            <div className="flex items-center ">
                <div className="text-left">
                    <div className="text-sm text-gray-900">
                        {
                            extras.isSeaType ? (
                                <>{element.container_number ? element.container_number :  t("app.container.container_not_defined")}</>
                            ) : (
                                <>{element.license_plate ? element.license_plate :  t("app.container.container_not_defined")}</>
                            )
                        }                    </div>
                    <div className="flex items-center text-xs flex-wrap text-gray-500">
                        <CalendarIcon
                            className="h-4 w-4 text-solitude-500 mr-1"
                            aria-hidden="true"
                        />

                        <div>{element.load_date ? getCustomFormat(element.load_date, 'DD/MM/YYYY HH:mm') : '-'}</div>
                    </div>
                    {
                        element.container_note && (
                            <div className="text-xs text-gray-500">
                                {t("app.shippings.Note")}: {element.container_note}
                            </div>
                        )
                    }
                </div>
            </div>
            <ActionsMenu>
              {extras.canView && (
                  <Link
                      to={getWebPathParam([
                          SHIPPING,
                          extras.shippingId,
                          CONTAINER,
                          element.id,
                          CONTAINER_GENERAL_INFORMATION,
                      ])}
                  >
                      {t("app.common.edit")}
                  </Link>
              )}
              {extras.canDelete && extras.isOwner && (
                      <button className={'text-left'}
                              onClick={() => {
                                  extras.onRemove(true, removeDetail);
                              }}
                      >
                          {t("app.common.remove")}
                      </button>
              )}
          </ActionsMenu>
        </div>
        <div className="flex flex-col">


            <CardDetail title={t("app.container.packaging_number")}>
                <div className="text-sm text-gray-900">
                    <NumberFormat
                        value={element?.box ?? 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                    />
                </div>
            </CardDetail>

            <CardDetail title={t("app.container.volume")}>
                <div className="text-sm text-gray-900">
                    <NumberFormat
                        value={element?.volume ?? 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={' m³'}
                    />
                </div>
            </CardDetail>

            <CardDetail title={t("app.container.gross_weight")}>
                <div className="text-sm text-gray-900">
                    <NumberFormat
                        value={element?.weight ?? 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={' Kg'}
                    />
                </div>
            </CardDetail>




            <CardDetail title={t("app.container.net_weight")}>
                <div className="text-sm text-gray-900">
                    <NumberFormat
                        value={element?.net_weight ?? 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={' Kg'}
                    />
                </div>
            </CardDetail>

        </div>
      </OptionalLink>
    </li>
  );
};

const ContainerDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  let { id } = useParams();
  const { isExporter } = useSelector((state) => state.user);

  const removeDetail = {
      title: extras.isSeaType ? t("app.container.remove_title") : t("app.container.remove_title_truck"),
      message: extras.isSeaType ? t("app.container.remove_description") : t("app.container.remove_description_truck"),
      endpoint: getPathParam([isExporter ? SHIPPING : SHIPPING_IMPORTER, id, CONTAINER, element.id]),
      redirect: getWebPathParam([LOGISTICS, SHIPPING, id, CONTAINERS]),
  };
  return (
    <tr key={element.id}>


        <TableDetail extraClass={"whitespace-pre-line"}>
            <OptionalLink
                isLinked={extras.canView}
                to={getWebPathParam([
                LOGISTICS, 
                SHIPPING,
                extras.shippingId,
                CONTAINER,
                element.id,
                CONTAINER_GENERAL_INFORMATION,
              ])}
            >

                <div className="flex items-center">
                    <div className="text-left">
                        <div className="text-sm text-gray-900">


                            {
                                extras.isSeaType ? (
                                    <>{element.container_number ? element.container_number :  t("app.container.container_not_defined")}</>
                                ) : (
                                    <>{element.license_plate ? element.license_plate :  t("app.container.container_not_defined")}</>
                                )
                            }


                        </div>
                        <div className="flex items-center text-xs text-gray-700">
                            <CalendarIcon
                                className="h-4 w-4 text-gray-700 mr-1"
                                aria-hidden="true"
                            />
                            <div>{element.load_date ? getCustomFormat(element.load_date, 'DD/MM/YYYY HH:mm') : '-'}</div>
                        </div>
                        {
                            element.container_note && (
                                <div className="text-xs flex-wrap text-gray-500">
                                    {t("app.shippings.Note")}: {element.container_note}
                                </div>
                            )
                        }
                    </div>
                </div>
            </OptionalLink>
      </TableDetail>

        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element?.box ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={0}
                />
            </div>
        </TableDetail>

        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element?.volume ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={' m³'}
                />
            </div>
        </TableDetail>

        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element?.weight ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={' Kg'}
                />
            </div>
        </TableDetail>

        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element?.net_weight ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={' Kg'}
                />
            </div>
        </TableDetail>

      <TableDetail>
        <ActionsMenu>
            {extras.canView && (
                <Link
                    to={getWebPathParam([
                        LOGISTICS,
                        SHIPPING,
                        extras.shippingId,
                        CONTAINER,
                        element.id,
                        CONTAINER_GENERAL_INFORMATION,
                    ])}
                >
                    {t("app.common.edit")}
                </Link>
            )}
            {
                extras.canDelete && extras.isOwner && (
                    <button className={'text-left'}
                            onClick={() => {
                                extras.onRemove(true, removeDetail);
                            }}
                    >
                        {t("app.common.remove")}
                    </button>
                )
            }

        </ActionsMenu>
      </TableDetail>
    </tr>
  );
};

export default Containers;
