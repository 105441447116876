import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useRef } from "react";

const BookingSlideLeft = ({ isOpen, setIsOpen, title, children, setClean}) => {

    let completeButtonRef = useRef(null)

    return (
        <Transition.Root show={isOpen} as={Fragment} initialFocus={completeButtonRef} ref={completeButtonRef}>
            <Dialog as="div" className="fixed z-20 inset-0 overflow-hidden" onClose={async () => {
                await setClean();
                await setIsOpen(false);
            }}>
                <div className="absolute inset-0 overflow-hidden">

                    <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />


                    <div className="fixed inset-y-0 xl:pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={"w-screen bg-white max-w-7xl"}>
                                <div className={"h-full divide-y divide-gray-200 flex flex-col shadow"} id={"slideId"}>


                                    <div className={"flex-1 h-0 overflow-y-auto"}>
                                        <div className="py-2 px-4 bg-gray-900 sm:px-6 relative shadow backdrop-blur-sm bg-gray-900">
                                            <div className="flex items-center justify-between">

                                                <div className="flex items-center">
                                                    <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
                                                </div>


                                                <div className="ml-3 h-7 flex items-center">
                                                    

                                                    <button
                                                        type="button"
                                                        className=" rounded-md text-white hover:text-white/70 "
                                                        onClick={async () => {
                                                            await setClean();
                                                            await setIsOpen(false);
                                                        }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-5 w-5" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className={'divide-y divide-gray-200 '}>
                                                {children}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>


                </div>
            </Dialog>
        </Transition.Root>
    )
};


export default BookingSlideLeft;