import React, {useEffect, useState} from "react";
import {  useOutletContext } from "react-router-dom";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../../constants/permissions";
import {getShippingTracks} from "../../../../api/shipping";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getFormattedDate} from "../../../../utils/timeUtils";
import {ClockIcon, LocationMarkerIcon, MapIcon} from "@heroicons/react/outline";
//import MapMarker from "../../../../components/partials/map/map-marker";

const ShippingTracking = () => {

  // get Shipping
  const { shippingDetail } = useOutletContext();
  const { loggedCompany } = useSelector((state) => state.user);
  const [isFetchListLoading, setFetchListLoading] = useState(true);
  const [trackingDetail, setTrackingDetail] = useState({});

  const { t } = useTranslation();

    useEffect(() => {
        function fetchList() {
            if (loggedCompany) {
                setFetchListLoading(true);
                getShippingTracks({ company_id: loggedCompany.id, shipping_id: shippingDetail.id })
                    .then((response) => {
                        setTrackingDetail(response);
                        setFetchListLoading(false);
                    })
                    .catch((e) => {
                        setTrackingDetail({});
                        setFetchListLoading(false);
                    });
                }
        }

        fetchList();
    }, [loggedCompany, shippingDetail, setFetchListLoading]);

    let code = (trackingDetail && trackingDetail.live_map_url) ? new URL(trackingDetail.live_map_url).searchParams.get("query") : false;


    
    return (
        <BasePermissionWrapper
            requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.EDIT)]}
            renderBaseOnFail={true}
            redirectWrongCompanyType={true}
        >

            {
                ((isFetchListLoading && trackingDetail !== false) || code === false) ? (
                    <div className="border bg-solitude-400 shadow-md rounded-lg p-4 h-32 w-full mx-auto  flex before:flex-1 after:flex-1 flex-col items-center">
                        <div className="animate-pulse border border-gray-500 rounded-full p-4">
                            <LocationMarkerIcon className="text-gray-500 h-6 w-6 stroke-current stroke-1" />
                        </div>
                    </div>
                ) : (
                    <div className="block w-full shadow-md aspect-w-10 aspect-h-10 rounded-lg overflow-hidden">
                        {/*
                            (trackingDetail.location) && (
                                <MapMarker
                                    location={trackingDetail?.location ??  false}
                                    zoom={5}
                                />
                            )
                        */}
                            <iframe
                                id="IframeShipsgoLiveMap"
                                src={`https://shipsgo.com/iframe/where-is-my-container/${code}?tags=hide-search-box`}
                                title="Shipsgo Live Map"
                                style={{ width: '100%', height: '400px', border: 'none' }}
                            />
                    </div>
                )
            }


            <div className="mt-5">
                <div className="bg-white border shadow-md rounded-lg p-4">
                    <h4 className="sr-only">Status</h4>

                    <p className="text-sm font-medium text-blue-1000"> {t("app.shipping_tracking.tracking_progress")}</p>



                    <div className="mt-6" aria-hidden="true">
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div className="h-2 rounded-full bg-blue-1000" style={{ width: (trackingDetail?.tracking_percentage ?? 0)+'%' }} />
                        </div>

                        <div className={trackingDetail.delayed ? "mt-3  grid-cols-3 text-sm font-medium text-gray-600 grid" : "mt-3  grid-cols-2 text-sm font-medium text-gray-600 grid"}>
                            <div className="">
                                <div className="flex items-center">

                                    <div>
                                        <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                            <span className="font-medium leading-none text-white">
                                                <MapIcon className={'h-5 w-5 text-blue-100'} />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{getFormattedDate(shippingDetail.departure_date)}</b></p>
                                        <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{shippingDetail?.departure_port?.name ?? ''}</p>
                                    </div>
                                </div>

                            </div>

                            {
                                trackingDetail.delayed && (
                                    <div className="text-center mt-3">
                                         <span className={'flex-shrink-0 inline-flex  items-center text-sm font-medium  text-red-700'}>
                                                <ClockIcon className={'h-5 w-5  mr-2'} />
                                                 {
                                                     trackingDetail.delay > 1 ? (
                                                         t("app.shipping_tracking.delay_days", {days: trackingDetail.delay})
                                                     ) : (
                                                         t("app.shipping_tracking.delay_day", {days: trackingDetail.delay})
                                                     )
                                                 }
                                        </span>
                                    </div>
                                )
                            }





                            <div className="text-right">
                                <div className="flex justify-end items-center">

                                    <div className="mr-3">
                                        <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{getFormattedDate(shippingDetail.arrival_date)}</b></p>
                                        <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{shippingDetail?.arrival_port?.name ?? ''}</p>
                                    </div>

                                    <div>
                                        <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                            <span className="font-medium leading-none text-white">
                                                <MapIcon className={'h-5 w-5 text-blue-100'} />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>



        </BasePermissionWrapper>
  );
};

export default ShippingTracking;
