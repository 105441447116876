import React, {useState, useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    CATALOG, CATEGORIES,
    BRANDS, PRODUCT_SETTINGS, MANUFACTURE, CATEGORY,
    ATTRIBUTE,
    ENCODING,
} from "../../endpoints";
import PageHeaderMenu from "../../../components/layout/page-header-menu";
import PanelNavigation from "../../../components/partials/panel/panel-navigation";
import {HashtagIcon, TagIcon} from "@heroicons/react/outline";
import CatalogIcon from "../../../resources/icons/CatalogIcon";
import BoxIcon from "../../../resources/icons/BoxIcon";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../components/partials/restricted/base-permission-wrapper";
import {permissionGroup, permissionType} from "../../../constants/permissions";
import {getWebPathParam} from "../../../utils/converter";
import { QrcodeIcon } from "@heroicons/react/solid";

const ProductDetailEdit = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const { pathname } = location;
    const { companyType } = useSelector((state) => state.user);

    const [title, setTitle] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        if(!pathname.includes(CATALOG + PRODUCT_SETTINGS + CATEGORY) && title !== false){
            setTitle(false);
        }

        if(pathname === CATALOG + PRODUCT_SETTINGS){

            if (canEncoding) {
                navigate(getWebPathParam([CATALOG + PRODUCT_SETTINGS, ENCODING]));
            }
            if (canViewAttributies) {
                navigate(getWebPathParam([CATALOG + PRODUCT_SETTINGS, ATTRIBUTE]));
            }
           
            if(canViewManufacture){
                navigate(getWebPathParam([CATALOG + PRODUCT_SETTINGS, MANUFACTURE]));
            }

            if (canViewBrand) {
                navigate(getWebPathParam([CATALOG + PRODUCT_SETTINGS, BRANDS]));
            }

            if (canViewCategory) {
                navigate(getWebPathParam([CATALOG + PRODUCT_SETTINGS, CATEGORIES]));
            }
        }


        // eslint-disable-next-line
    }, [pathname]);


    const { permissions } = useSelector((s) => s.userCompany);

    const canViewCategory = hasPermission(permissions, permissionGroup.PRODUCT_SETTING_CATEGORY, permissionType.LIST, companyType, false);
    const canViewBrand = hasPermission(permissions, permissionGroup.PRODUCT_SETTING_BRAND, permissionType.LIST, companyType, false);
    const canViewManufacture = hasPermission(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.LIST, companyType, false);
    
    // TODO: fix
    const canViewAttributies = hasPermission(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.LIST, companyType, false);
    const canEncoding = hasPermission(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.LIST, companyType, false);


    const navigation = [
        canViewCategory && { name: t("app.categories.categories"), alternative_title: pathname.includes(CATALOG + PRODUCT_SETTINGS + CATEGORY) && title ? title : false, location: CATALOG + PRODUCT_SETTINGS + CATEGORIES  , icon: CatalogIcon, current: pathname.includes(CATALOG + PRODUCT_SETTINGS + CATEGORIES) || pathname.includes(CATALOG + PRODUCT_SETTINGS + CATEGORY) },
        canViewBrand && { name: t("app.catalog.brands"), alternative_title: false, location: CATALOG + PRODUCT_SETTINGS + BRANDS  , icon: TagIcon, current: pathname.includes(CATALOG + PRODUCT_SETTINGS + BRANDS) },
        canViewManufacture && { name: t("app.catalog.manufacture"), alternative_title: false, location: CATALOG + PRODUCT_SETTINGS + MANUFACTURE  , icon: BoxIcon, current: pathname.includes(CATALOG + PRODUCT_SETTINGS + MANUFACTURE) },
        canViewAttributies && { name: t("app.catalog.attribute"), alternative_title: false, location: CATALOG + PRODUCT_SETTINGS + ATTRIBUTE, icon: HashtagIcon, current: pathname.includes(CATALOG + PRODUCT_SETTINGS + ATTRIBUTE) },
        canEncoding && { name: t("app.catalog.encoding"), alternative_title: false, location: CATALOG + PRODUCT_SETTINGS + ENCODING, icon: QrcodeIcon, current: pathname.includes(CATALOG + PRODUCT_SETTINGS + ENCODING) }
    ].filter(Boolean);

    let data = navigation?.find(r => r.current === true) ?? false;

    return (
        <div className="flex h-full">

            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                <div className="relative z-0 flex flex-1 overflow-hidden">
                    <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                        {/* Start main area*/}
                        <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                            {
                                navigation.length > 0 && (
                                    <div className="max-w-7xl mx-auto mb-3">
                                        <PageHeaderMenu
                                            title={data && data.alternative_title ? data.alternative_title : (data && data.name ? data.name : '')}
                                            navigation={navigation}
                                        />
                                    </div>
                                )
                            }

                            <div className="max-w-7xl mx-auto pb-4">
                                <Outlet context={{ navigation, onSetTitle: (r) => { setTitle(r) } }} />
                            </div>
                        </div>
                        {/* End main area */}
                    </main>

                    {
                        navigation.length > 0 && (
                            <aside className="relative hidden w-80 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                                {/* Start secondary column (hidden on smaller screens) */}
                                <div className="absolute inset-0">
                                    <PanelNavigation navigation={navigation} isCenterTitle={true} title={''} />
                                </div>
                                {/* End secondary column */}
                            </aside>
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default ProductDetailEdit;
