import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FormPopup from "../../../../../components/common/popup/form-popup";
import InputText from "../../../../../components/common/form/input-text";
import {postProductTranslation} from "../../../../../api/catalog";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchLanguages} from "../../../../../reducers/configReducer";
import InputSelect from "../../../../../components/common/form/input-select";
import InputEditor from "../../../../../components/common/form/input-editor";

export default function AddTranslation({
                                         isOpen,
                                         setIsOpen,
                                         onAdded
                                     }) {

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({ mode: "onBlur" });


    const { t } = useTranslation();
    let { id } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const { isLanguagesLoading, languages } = useSelector((state) => state.config);


    const storeTranslation = async (data) => {
        setLoading(true);
        postProductTranslation({ product_id: id, data })
            .then((response) => {
                onAdded(response);
                setLoading(false);
                setIsOpen(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        dispatch(fetchLanguages());
    }, [dispatch]);


    const onSubmit = async (data) => {
        await storeTranslation(data);
    };

    return (
        <FormPopup
            title={t("app.products.add_translation")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                        <Controller
                            name="locale"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.common.language")}
                                    name={name}
                                    options={languages}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isLanguagesLoading}
                                    isDisabled={isLanguagesLoading}
                                    errors={error}
                                />
                            )}
                        />

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.form.name")}
                        />



                        <div className="col-span-2">
                            <Controller
                                name="description"
                                rules={{ required: false }}
                                control={control}
                                render={({field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (
                                    <InputEditor
                                        label={t("app.products.description")}
                                        errors={error}
                                        defaultValue={value}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.products.add_translation")}
                    />
                </div>
            </form>
        </FormPopup>
    );
}
