import React, {useState} from 'react'
import SignatureCard from "./signature-card";
import {putUser} from "../../../api/user";
import {updateSignature} from "../../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function AutoSaveSignatureCard({onUpdateData}) {

    const { detail } = useSelector((state) => state.user );
    let dispatch = useDispatch();
    const {t} = useTranslation();
    const [isUpdateLoading, setUpdateLoading] = useState(false);


    const updateUserDetail = async (data) => {
        setUpdateLoading(true);
        onUpdateData(true);
        if(detail){
            putUser({user_id: detail.id, data})
                .then(response => {
                    dispatch(updateSignature(response));
                    setUpdateLoading(false);
                    onUpdateData(false);
                }).catch(e => {
                setUpdateLoading(true);
                onUpdateData(true);
            });
        }
    };

    return (
        <SignatureCard
            label={t("app.common.signature")}
            isUpdateLoading={isUpdateLoading}
            picture={detail?.signature?.reference ?? false}
            showRemove={false}
            onSave={(r) => {
                updateUserDetail({id: detail.id, signature: {media_url: r}});
            }}
            onRemove={(r) => {

            }}
        />
    )
}
