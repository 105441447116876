import React, {useEffect} from "react";
import BaseList from "../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../utils/converter";
import {FORWARDER, FORWARDER_CONTACTS} from "../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../components/common/form/input-text";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import {fetchCountries} from "../../../reducers/configReducer";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";
import { FORWARDER_CONTACT} from "../../endpoints";
import {useParams} from "react-router-dom";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {MailIcon} from "@heroicons/react/outline";
import {emailRegex} from "../../../constants/regex";
import InputToggle from "../../../components/common/form/input-toggle";

const ForwarderContact = () => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);


    let { id } = useParams();


    const { permissions } = useSelector((s) => s.userCompany);

    const canCreate = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);


    return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.VIEW)]} renderBaseOnFail={true}>

        <div className="max-w-5xl mx-auto  py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center flex-wrap gap-4 ">
            <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
              {t("app.forwarder.contacts")}
            </h1>
          </div>
        </div>
        <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">
          {
            loggedCompany && (
              <BaseList
                columns={[
                  t("app.forwarder.fullname"),
                  t("app.forwarder.email"),
                  t("app.forwarder.phone"),
                  "",
                ]}
                endpoint={getPathParam([FORWARDER, id, FORWARDER_CONTACTS])}
                storeEndpoint={getPathParam([FORWARDER, id, FORWARDER_CONTACT])}
                updateEndpoint={getPathParam([FORWARDER, id, FORWARDER_CONTACT, ':id'])}
                baseResult={{ total_rows: 0, contacts: [] }}
                resultLabel={'contacts'}
                title={t("app.forwarder.contacts")}
                showHeader={true}
                mobRow={MobRow}
                deskRow={DeskRow}
                addForm={AddForm}
                icon={MailIcon}
                addFormLabel={t("app.forwarder.add_name")}
                btnAddLabel={canCreate ? t("app.forwarder.add_name") : false}
                addPermission={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.CREATE)]}
                editForm={EditForm}
                editTitle={t("app.forwarder.forwarder")}
                isActiveEdit={canView || canEdit}
              />
            )
          }
        </div>

      </BasePermissionWrapper>

    );
};


const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
    let { id } = useParams();

    const removeDetail = {
      title: t("app.forwarder.remove_contact_title"),
      message: t("app.forwarder.remove_contact_description"),
      endpoint: getPathParam([FORWARDER, id, FORWARDER_CONTACT, element.id])
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.DELETE);


    return (
      <li key={element.id} className="flex items-center gap-4 p-4">
        <div className="flex-grow">
          <div className="pb-4 items-center flex justify-between">
            {element.first_name}{" "}{element?.last_name ?? ''}
              {
                  (canView || canEdit || canDelete) && (
                      <ActionsMenu>
                          {
                              (canView || canEdit) && (
                                  <button className={'text-left'} onClick={() => {
                                      extras.onEdit(true, element);
                                  }}
                                  >
                                      {t("app.common.edit")}
                                  </button>
                              )
                          }
                          {
                              canDelete && (
                                  <button className={'text-left'} onClick={() => {
                                      extras.onRemove(true, removeDetail);
                                  }}
                                  >
                                      {t("app.common.remove")}
                                  </button>
                              )
                          }
                      </ActionsMenu>
                  )
              }
          </div>
          <div className="flex flex-col">
            <CardDetail title={t("app.forwarder.email")}>
              {element.email}
            </CardDetail>

            <CardDetail title={t("app.forwarder.phone")}>
              {element?.phone ?? '-'}
            </CardDetail>


            <CardDetail title={t("app.forwarder.role")}>
              {element?.role ?? '-'}
            </CardDetail>

          </div>
        </div>
      </li>
  );
};

const DeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  let { id } = useParams();

  const removeDetail = {
    title: t("app.forwarder.remove_contact_title"),
    message: t("app.forwarder.remove_contact_description"),
    endpoint: getPathParam([FORWARDER, id, FORWARDER_CONTACT, element.id])
  };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.DELETE);


    return (
      <tr key={element.id}>
        <TableDetail extraClass="max-w-sm">
          <div className="text-sm text-gray-900 capitalize">
            {element.first_name}{" "}{element?.last_name ?? ''}
          </div>
          <div className="text-xs text-gray-500 capitalize">
            {element?.role ?? ''}
          </div>
        </TableDetail>

        <TableDetail>
          {element.email}
        </TableDetail>

        <TableDetail>
          {element?.phone ?? '-'}
        </TableDetail>

        <TableDetail>
            {
                (canView || canEdit || canDelete) && (
                    <ActionsMenu>
                        {
                            (canView || canEdit) && (
                                <button className={'text-left'} onClick={() => {
                                    extras.onEdit(true, element);
                                }}
                                >
                                    {t("app.common.edit")}
                                </button>
                            )
                        }
                        {
                            canDelete && (
                                <button className={'text-left'} onClick={() => {
                                    extras.onRemove(true, removeDetail);
                                }}
                                >
                                    {t("app.common.remove")}
                                </button>
                            )
                        }
                    </ActionsMenu>
                )
            }
        </TableDetail>
      </tr>
  );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    onSubmitData(data);
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);




  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
            <InputText
                id={"first_name"}
                type={"text"}
                errors={errors.first_name}
                input={{ ...register("first_name", { required: true }) }}
                label={t("app.forwarder.firstname")}
            />

             <InputText
                id={"last_name"}
                type={"text"}
                errors={errors.last_name}
                input={{ ...register("last_name", { required: true }) }}
                label={t("app.forwarder.lastname")}
            />


            <InputText
                id={"email"}
                type={"text"}
                errors={errors.email}
                input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                label={t("app.forwarder.email")}
            />

            <InputText
                id={"phone"}
                type={"text"}
                errors={errors.phone}
                input={{ ...register("phone", { required: true }) }}
                label={t("app.forwarder.phone")}
            />

            <InputText
              id={"role"}
              type={"text"}
              errors={errors.role}
              input={{ ...register("role", { required: true }) }}
              label={t("app.forwarder.role")}
            />

          <div className="mr-3 col-span-2">
            <Controller
              name={"is_default"}
              rules={{ required: false }}
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <InputToggle
                  label={t("app.forwarder.default_contact")}
                  description={t("app.forwarder.default_contact_desc")}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  isDisabled={false}
                  defaultChecked={value}
                  errors={error}
                />
              )}
            />
          </div>

          </div>
        </div>

        {/* Warning */}
        {exception && (
            <Warning message={exception} />
        )}

        <div className="flex before:flex-1 items-center justify-between mt-6">
          <InputSubmit
              isLoading={isLoading}
              label={t("app.forwarder.add_name")}
          />
        </div>
      </form>
  );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);


    useEffect(() => {
    if(data !== null){
      for (const k in data) {
        if (typeof data[k] === "object") {
          setValue(k, getSelectParam(data[k], "name"));
        }else{
          setValue(k, data[k]);
        }
      }
    }
  }, [setValue, data]);

  const onSubmit = async (data) => {
    onSubmitData(data);
  };


  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>

            <div className="space-y-4 pt-4 pb-5">

            <InputText
              id={"first_name"}
              type={"text"}
              errors={errors.first_name}
              input={{ ...register("first_name", { required: true }) }}
              label={t("app.forwarder.firstname")}
            />

            <InputText
              id={"last_name"}
              type={"text"}
              errors={errors.last_name}
              input={{ ...register("last_name", { required: true }) }}
              label={t("app.forwarder.lastname")}
            />


            <InputText
              id={"email"}
              type={"text"}
              errors={errors.email}
              input={{ ...register("email", { required: true, pattern: emailRegex }) }}
              label={t("app.forwarder.email")}
            />

            <InputText
              id={"phone"}
              type={"text"}
              errors={errors.phone}
              input={{ ...register("phone", { required: true }) }}
              label={t("app.forwarder.phone")}
            />

            <InputText
              id={"role"}
              type={"text"}
              errors={errors.role}
              input={{ ...register("role", { required: true }) }}
              label={t("app.forwarder.role")}
            />

            <div className="mr-3 col-span-2">
              <Controller
                name={"is_default"}
                rules={{ required: false }}
                control={control}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <InputToggle
                    label={t("app.forwarder.default_contact")}
                    description={t("app.forwarder.default_contact_desc")}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    isDisabled={false}
                    defaultChecked={value}
                    errors={error}
                  />
                )}
              />
            </div>

            </div>
          </div>

          {/* Warning */}
          {exception && (
              <Warning message={exception} />
          )}

            {
                canEdit && (
                    <div className="mt-3">
                        <InputSubmit
                            isLoading={isEditLoading}
                            isFullWith={true}
                            label={t("app.form.save")}
                        />
                    </div>
                )
            }

        </form>

      </>
  );
}

export default ForwarderContact;
