import React from "react";
import Initials from "../avatar/initials";

const CustomerCell = ({ customer, extra, secondExtra }) => {
  return (
    <div className="flex items-center">
    <Initials
        color={customer.color}
        name={customer.business_name}
        isVerified={customer?.status === 'confirmed' ?? false}
        registryType={customer?.registry_type ?? false}
    />
        <div className="text-left">
            {
                secondExtra && (
                    <div className="text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                        {secondExtra}
                    </div>
                )
            }
            <div className="text-sm whitespace-pre-line text-gray-900">
                {customer.business_name}
            </div>
            {
                extra && (
                    <div className="text-xs whitespace-pre-line text-gray-700">
                        {extra}
                    </div>
                )
            }
        </div>

    </div>
  );
};

export default CustomerCell;
