import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {getFormattedDate} from "../../../../utils/timeUtils";
import InputCheckbox from "../../../../components/common/form/input-checkbox";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import UploadFile from "../../../../components/partials/upload/upload-file";
import {
    deleteDocument, deleteDocumentImporter,
    getDocument,
    getDocumentImporter,
    putDocument,
    putDocumentImporter
} from "../../../../api/document";
import {cleanData, getSelectParam} from "../../../../utils/converter";
import {useParams} from "react-router-dom";
import Loader from "../../../../components/common/loader/loader";
import InputSubmit from "../../../../components/common/form/input-submit";
import InputRemove from "../../../../components/common/form/input-remove";
import {Warning} from "../../../../components/common/alert/banner";
import {
    permissionGroup,
    permissionSpecific,
    permissionType
} from "../../../../constants/permissions";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import InputDate from "../../../../components/common/form/input-date";
import {useSelector} from "react-redux";
import FileCard from "../../../../components/common/card/file-card";
import ReportViewer from "../../../../components/partials/report/report-viewer";
import {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";

const DocumentDetail = ({isOpen, setIsOpen, detail, onNeedRefresh}) => {

    const { isExporter, companyType } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const { register, handleSubmit, setValue, reset, watch,control, formState: { errors } } = useForm();
    const [isLoading, setLoading] = useState(true);
    const [document, setDocument] = useState(null);
    let {id}  = useParams();
    const { t } = useTranslation();

    const canView =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.VIEW);
    const canEdit =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.EDIT);
    const canDelete =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.DELETE);


    const isPrivateDocumentPermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_PRIVATE);
    const isAutoRemovePermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_AUTO_REMOVE);
    const isSendNotificationPermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_SEND_NOTIFICATION);


    // put container
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);
    const [isUpdateRefreshed, setUpdateRefreshed] = useState(false);


    const [isOpenReportView, setOpenReportView] = useState(false);

    const updateDocument = async (data) => {
        setUpdateLoading(true);
        setUpdateRefreshed(false);
        (isExporter ?
            putDocument({shipping_id: id, document_id: detail.id, data}) :
            putDocumentImporter({shipping_id: id, document_id: detail.id, data})
        ).then(response => {
                setDocument(response);
                for (const k in response) {
                    if(typeof response[k] === "object"){
                        setValue(k, getSelectParam(response[k], 'name'));
                    }else{
                        setValue(k, response[k]);
                    }
                }
                setUpdateLoading(false);
                setUpdateRefreshed(true);
                onNeedRefresh(true);
                toast.success(t("app.document.update_success"))
                setUpdateException(false);
            }).catch(e => {
            setUpdateException(e.message);
            setUpdateLoading(false);
        });
    };

    // put container
    const [isRemoveLoading, setRemoveLoading] = useState(false);

    const removeDocument = async () => {
        setRemoveLoading(true);
        (isExporter ? deleteDocument({shipping_id: id, document_id: detail.id}) :
        deleteDocumentImporter({shipping_id: id, document_id: detail.id}))
            .then(response => {
                setRemoveLoading(false);
                setIsOpen(false);
                onNeedRefresh(true);
            }).catch(e => {
            setUpdateException(e.message);
            setRemoveLoading(false);
        });
    };

    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;
        function fetchDetail(){
            if(detail){
                setLoading(true);
                setUpdateRefreshed(false);
                reset();
                (isExporter ? getDocument({shipping_id: id, document_id: detail.id, signal}) :
                    getDocumentImporter({shipping_id: id, document_id: detail.id, signal}))
                    .then(response => {
                        setDocument(response);
                        for (const k in response) {
                            if(typeof response[k] === "object"){
                                setValue(k, getSelectParam(response[k], 'name'));
                            }else{
                                setValue(k, response[k]);
                            }
                        }

                        if(response.remove_date){
                            setValue('auto_remove', true);

                        }

                        setLoading(false);
                    }).catch(e => {
                    setLoading(false);
                });
            }
        }
        fetchDetail();
        return () => {controller.abort();};

    }, [id, setValue, detail, reset, isExporter]);

    const onSubmit = async (data) => {
        cleanData(data);
        await updateDocument(data);
    };

    return (

        <>
            {
                canView && (
                    <ReportViewer
                        isOpen={isOpenReportView}
                        type={'document'}
                        reportDetail={document?.file ?? false}
                        title={document?.file_name ?? ''}
                        isActiveConfirm={false}
                        reportId={document?.id ?? false}
                        setIsOpen={(r) => {
                            setOpenReportView(r);
                        }}
                    />
                )
            }

            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={setIsOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />

                        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-4 px-4 bg-blue-1000 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-white">{detail?.document_type?.name}</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-blue-1000 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setIsOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">

                                                {
                                                    isLoading ? (
                                                        <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                            <div className="space-y-6 pt-6 pb-5">
                                                                <Loader />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                            <div className="space-y-6 pt-6 pb-5">
                                                                {
                                                                    document.file && (
                                                                        <div>
                                                                            <label className="block font-medium text-gray-700">{t("app.report.report_detail")}</label>
                                                                            <FileCard
                                                                                file={document.file}
                                                                                name={document.file_name}
                                                                                isActiveShow={canView}
                                                                                information={getFormattedDate(document.upload_date)}
                                                                                onClick={() => {
                                                                                    setOpenReportView(true);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }


                                                                <InputText
                                                                    id={'file_note'}
                                                                    type={'text'}
                                                                    isDisabled={!canEdit}
                                                                    errors={errors.file_note}
                                                                    input={{...register("file_note", { required: false })}}
                                                                    label={t("app.document.document_note")}
                                                                />

                                                                {
                                                                    canEdit && (
                                                                        <UploadFile
                                                                            onUpload={(data) => {
                                                                                setValue('file', {media_url: data.media_url});
                                                                                setValue('file_name', data.detail.name);
                                                                            }}
                                                                            onCancel={(r) => {
                                                                                if(r){
                                                                                    setValue('file', undefined);
                                                                                    setValue('file_name', undefined);
                                                                                }
                                                                            }}
                                                                            cancel={isUpdateRefreshed}
                                                                            onException={(e) => {}}
                                                                            isLoading={false}
                                                                            label={t("app.document.upload_document_question")}
                                                                        />
                                                                    )
                                                                }

                                                                {
                                                                    document.replace_date && (
                                                                        <div className="mt-5 border-t border-gray-200">
                                                                            <dl className="divide-y divide-gray-200">
                                                                                <div className="py-3 flex justify-between text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t("app.document.file_name")}</dt>
                                                                                    <dd className="flex text-right text-sm text-gray-900">
                                                                                        <span className="flex-grow">{getFormattedDate(document.replace_date)}</span>
                                                                                    </dd>
                                                                                </div>
                                                                            </dl>
                                                                        </div>
                                                                    )
                                                                }


                                                                {
                                                                    (companyType === (document?.creator_type ?? null)) && canEdit && (isPrivateDocumentPermission || (isAutoRemovePermission) || isAutoRemovePermission) && (


                                                                        <fieldset>
                                                                            <legend className="text-sm font-medium text-gray-900">Privacy</legend>
                                                                            <div className="mt-4 space-y-5">


                                                                                {
                                                                                    isPrivateDocumentPermission && (
                                                                                        <div className="relative flex items-start">
                                                                                            <InputCheckbox
                                                                                                label={t("app.document.privacy_title")}
                                                                                                description={t("app.document.privacy_description")}
                                                                                                id={'hidden'}
                                                                                                errors={errors.hidden}
                                                                                                input={{...register("hidden", { required: false })}}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }



                                                                                {
                                                                                    isAutoRemovePermission && (
                                                                                        <div className="relative flex items-start">
                                                                                            <InputCheckbox
                                                                                                label={t("app.document.ml_title")}
                                                                                                description={t("app.document.ml_description")}
                                                                                                id={'auto_remove'}
                                                                                                errors={errors.auto_remove}
                                                                                                input={{...register("auto_remove", { required: false })}}
                                                                                            />

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    isAutoRemovePermission && (
                                                                                        <div className="relative flex items-start">


                                                                                            {
                                                                                                watch('auto_remove') && (
                                                                                                    <Controller
                                                                                                        name="remove_date"
                                                                                                        rules={{ required: true }}
                                                                                                        control={control}
                                                                                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                                                            <InputDate
                                                                                                                label={t("app.document.remove_date")}
                                                                                                                format={'YYYY-MM-D HH:mm:ss'}
                                                                                                                placeholder={'Es. 01/12/2021'}
                                                                                                                activeMinDate={false}
                                                                                                                startDate={value}
                                                                                                                isDisabled={!canEdit}
                                                                                                                isShowTime={true}
                                                                                                                errors={error}
                                                                                                                name={name}
                                                                                                                onChange={(e) => {
                                                                                                                    onChange(e);
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                )
                                                                                            }


                                                                                        </div>
                                                                                    )
                                                                                }


                                                                            </div>
                                                                        </fieldset>
                                                                    )
                                                                }



                                                                {
                                                                    !watch('hidden') && (companyType === (document?.creator_type ?? null) && canEdit && isSendNotificationPermission)  && (
                                                                        <fieldset>
                                                                            <legend className="text-sm font-medium text-gray-900">{t("app.document.notification")}</legend>
                                                                            <div className="mt-4 space-y-5">
                                                                                <div className="relative flex items-start">
                                                                                    <InputCheckbox
                                                                                        label={t("app.document.notify_single_title")}
                                                                                        description={t("app.document.notify_single_description")}
                                                                                        id={'send_notification'}
                                                                                        errors={errors.send_notification}
                                                                                        input={{...register("send_notification", { required: false })}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </fieldset>
                                                                    )
                                                                }


                                                                {/* Warning */}
                                                                {updateException && (
                                                                    <Warning
                                                                        message={updateException}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }


                                            </div>
                                        </div>

                                        {
                                            !isLoading && (
                                                <div className="flex-shrink-0 px-4 py-4 flex justify-between">

                                                    {
                                                        ((companyType === (document?.creator_type ?? null)) && canDelete )? (
                                                            <InputRemove
                                                                isLoading={isRemoveLoading}
                                                                label={t("app.common.remove")}
                                                                onClick={async () => {
                                                                    await removeDocument();
                                                                }}
                                                            />
                                                        ) : (
                                                            <div />
                                                        )
                                                    }

                                                    {
                                                        canEdit && (
                                                            <InputSubmit
                                                                isLoading={isUpdateLoading}
                                                                label={t("app.form.save")}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            )
                                        }


                                    </form>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </>


    );
};

export default DocumentDetail;
