import React from "react";
import Initials from "../avatar/initials";

const UserInitial = ({ user, ringClass = ' ring-white ' }) => {
  return (
    <>
        {
            user.picture ? (
                <img className={"h-6 w-6 max-w-none rounded-full ring-2 object-cover "+ringClass} src={user.picture.reference} alt="" />
            ) : (
                <Initials
                    color={user?.color ?? ''}
                    name={user?.fullname ?? ''}
                    isVerified={false}
                    ringClass={' mr-0 ring-2 '+ringClass}
                    iconClass={" h-6 w-6"}
                    fontSize={" text-xs"}
                />
            )
        }
    </>
  );
};

export default UserInitial;
