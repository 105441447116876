import {COMPANY, FORWARDERS, FORWARDER, FORWARDER_EMAILS} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// customer
export const getForwarders = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, FORWARDERS] ) + (params ? serialize(params)  : ''), {signal: signal});

export const getForwarder = ({company_id, forwarder_id, signal}) =>
    API.get(getPathParam([COMPANY, company_id, FORWARDER, forwarder_id ]), {signal: signal});

export const postForwarder = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, FORWARDER]), data);

export const putForwarder = ({company_id, forwarder_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, FORWARDER, forwarder_id]), data);


export const getForwarderMails = ({forwarder_id}) =>
    API.get(getPathParam([FORWARDER, forwarder_id, FORWARDER_EMAILS ]));
