export function getFileType(args) {
    switch (args.toLowerCase()) {
        case   "pdf":  return "application/pdf"
        case  "flac":  return "audio/flac"
        case   "m4a":  return "audio/x-m4a"
        case   "mp3":  return "audio/mpeg"
        case   "oga":  return "audio/ogg"
        case   "ogg":  return "audio/ogg"
        case  "opus":  return "audio/ogg"
        case   "wav":  return "audio/wav"
        case  "weba":  return "audio/webm"
        case  "avif":  return "image/avif"
        case   "bmp":  return "image/bmp"
        case   "gif":  return "image/gif"
        case   "ico":  return "image/x-icon"
        case  "jfif":  return "image/jpeg"
        case  "jpeg":  return "image/jpeg"
        case   "jpg":  return "image/jpeg"
        case   "pjp":  return "image/jpeg"
        case "pjpeg":  return "image/jpeg"
        case   "png":  return "image/png"
        case   "svg":  return "image/svg+xml"
        case  "svgz":  return "image/svg+xml"
        case   "tif":  return "image/tiff"
        case  "tiff":  return "image/tiff"
        case  "webp":  return "image/webp"
        case   "xbm":  return "image/x-xbitmap"
        case   "css":  return "text/css"
        case   "csv":  return "text/csv"
        case "ehtml":  return "text/html"
        case   "htm":  return "text/html"
        case  "html":  return "text/html"
        case  "shtm":  return "text/html"
        case "shtml":  return "text/html"
        case  "text":  return "text/plain"
        case   "txt":  return "text/plain"
        case   "m4v":  return "video/mp4"
        case   "mp4":  return "video/mp4"
        case  "mpeg":  return "video/mpeg"
        case   "mpg":  return "video/mpeg"
        case   "ogm":  return "video/ogg"
        case   "ogv":  return "video/ogg"
        case  "webm":  return "video/webm"
        case  "doc":  return "application/msword"
        case  "dot":  return "application/msword"
        case "docx":  return   "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        case "dotx":  return   "application/vnd.openxmlformats-officedocument.wordprocessingml.template"
        case "docm":  return   "application/vnd.ms-word.document.macroEnabled.12"
        case "dotm":  return   "application/vnd.ms-word.template.macroEnabled.12"
        case "xls":  return   "application/vnd.ms-excel"
        case "xlt":  return   "application/vnd.ms-excel"
        case "xla":  return   "application/vnd.ms-excel"
        case "xlsx":  return   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        case "xltx":  return   "application/vnd.openxmlformats-officedocument.spreadsheetml.template"
        case "xlsm":  return   "application/vnd.ms-excel.sheet.macroEnabled.12"
        case "xltm":  return   "application/vnd.ms-excel.template.macroEnabled.12"
        case "xlam":  return   "application/vnd.ms-excel.addin.macroEnabled.12"
        case "xlsb":  return   "application/vnd.ms-excel.sheet.binary.macroEnabled.12"
        case "ppt":  return   "application/vnd.ms-powerpoint"
        case "pot":  return   "application/vnd.ms-powerpoint"
        case "pps":  return   "application/vnd.ms-powerpoint"
        case "ppa":  return   "application/vnd.ms-powerpoint"
        case "pptx":  return   "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        case "potx":  return   "application/vnd.openxmlformats-officedocument.presentationml.template"
        case "ppsx":  return   "application/vnd.openxmlformats-officedocument.presentationml.slideshow"
        case "ppam":  return   "application/vnd.ms-powerpoint.addin.macroEnabled.12"
        case "pptm":  return   "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
        case "potm":  return   "application/vnd.ms-powerpoint.template.macroEnabled.12"
        case "ppsm":  return   "application/vnd.ms-powerpoint.slideshow.macroEnabled.12"
        case "mdb":  return   "application/vnd.ms-access"
        default:
            return false
    }
}


