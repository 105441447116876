import React, {useEffect, useState} from "react";
import {useParams, useNavigate, useOutletContext} from "react-router-dom";
import {
    SHIPPING,
    CONTAINER,
    CONTAINER_GALLERY,
    CONTAINERS,
    LOGISTICS
} from "../../../../../endpoints";
import UnderlineTab from "../../../../../../components/partials/Tabs/underline-tab";
import {DownloadIcon, EyeIcon, TrashIcon} from "@heroicons/react/outline";
import {
    deleteContainerImage,
    deleteContainerImageImporter,
    putContainer, putContainerGalleryArchive,
    putContainerImporter
} from "../../../../../../api/container";
import UploadImages from "../../../../../../components/partials/gallery/upload-images";
import Loader from "../../../../../../components/common/loader/loader";
import {Warning} from "../../../../../../components/common/alert/banner";
import ListFooter from "../../../../../../components/partials/footer/list-footer";
import LoaderIcon from "../../../../../../resources/icons/LoaderIcon";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
} from "../../../../../../constants/permissions";
import PageHeader, {WhiteButton} from "../../../../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import FsLightbox from "fslightbox-react";
import BasePermissionWrapper , {hasPermissionFor} from "../../../../../../components/partials/restricted/base-permission-wrapper";

const ContainerGallery = () => {

    const { isExporter } = useSelector((state) => state.user);

    const { containerDetail, tabsNavigation, setContainerDetail, isSeaType } = useOutletContext();
    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.EDIT);
    const canDownloadPermission =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_GALLERY_DOWNLOAD);

    // get container
    const [isLoading] = useState(false);
    const [gallery, setGallery] = useState( []);
    const { t } = useTranslation();


    let {id, container_id}  = useParams();
    let navigate = useNavigate();

    // put container
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);


    const [isRemoveLoading, setRemoveLoading] = useState(false);
    const [removeFiles, setRemoveFiles] = useState( []);
    const [toggleSingle, setToggleSingle] = useState(false);
    const [singleImage, setSingleImage] = useState(false);

    const [isLoadingDownload, setLoadingDownload] = useState(false);


    const updateContainer = async (data) => {
        setUpdateLoading(true);
        (isExporter ? putContainer({shipping_id: id, container_id: container_id, data}) :
        putContainerImporter({shipping_id: id, container_id: container_id, data}))
            .then(response => {
                setContainerDetail(response);
                setGallery(response?.gallery?.medias ?? []);
                setUpdateLoading(false);
                setUpdateException(false);
                toast.success(isSeaType ? t("app.container.notification_add_images") : t("app.container.notification_add_images_truck"))
            }).catch(e => {
            setUpdateException(e.message);
            setUpdateLoading(false);
        });
    };

    const removeImage = async (media_id) => {
        if(!isRemoveLoading){
            setRemoveLoading(true);
            setRemoveFiles(res => [...res, {id: media_id}]);
            (isExporter ? deleteContainerImage({shipping_id: id, container_id: container_id, media_id: media_id}) :
            deleteContainerImageImporter({shipping_id: id, container_id: container_id, media_id: media_id}))
                .then(response => {
                    setContainerDetail(response);
                    setGallery(gallery.filter(item => item.id !== media_id));
                    setRemoveFiles(removeFiles.filter(item => item.id !== media_id));
                    setRemoveLoading(false);
                    toast.success(isSeaType ? t("app.container.notification_remove_images") : t("app.container.notification_remove_images_truck"))
                }).catch(e => {
                setUpdateException(e.message);
                setRemoveLoading(false);
            });
        }

    };


    const generateZip = async () => {

        if (!isLoadingDownload && canDownloadPermission) {
            setLoadingDownload(true);
            putContainerGalleryArchive({shipping_id: id, container_id: container_id}).then(async r => {
                window.open(r.file, "_blank");
                setLoadingDownload(false);
            }).catch(e => {
                setUpdateException(e.message);
                setLoadingDownload(false);
            });
        }
    }







    useEffect( () => {
        setGallery(containerDetail?.gallery?.medias ?? []);
    }, [containerDetail]);

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <PageHeader
                    title={isSeaType ? t("app.container.edit_title") : t("app.container.edit_tuck_title")}
                >

                    {
                        canDownloadPermission  && (
                            <WhiteButton
                                onClick={() => {
                                    generateZip();
                                }}
                                icon={
                                    isLoadingDownload ? (<div className={"mr-4"}><Loader /></div>) :  (<DownloadIcon
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                    />)
                                }
                                text={t("app.container.download_gallery")}
                            />
                        )
                    }
                </PageHeader>
                <UnderlineTab
                    tabs={tabsNavigation} activeGroup={CONTAINER_GALLERY}
                    setActiveGroup={(r) => {
                        navigate(LOGISTICS + SHIPPING + '/'+ id + CONTAINER +  '/' + container_id + r);
                    }}
                />
            </div>


            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.VIEW)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>
            <div className="max-w-7xl mt-4 mx-auto px-4 sm:px-6 md:px-8">



                <div className="shadow ">
                    <section className={'mt-3 bg-white py-6 px-4 sm:p-6'}>
                        {
                            isLoading ? (
                                <div className="mt-6">
                                    <div className="w-1/2 m-auto px-4 py-3">
                                        <Loader />
                                    </div>
                                </div>
                            ) : (
                                <ul
                                    className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                                >
                                    {gallery.map((file, index) => (
                                        <li key={index} className="relative cursor-pointer" >
                                            <div className={'group block w-full aspect-w-10 aspect-h-7  bg-solitude-100 overflow-hidden'} >

                                                <div className={'absolute right-10 bg-blue-1000 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'}
                                                     onClick={() => { setSingleImage(file.reference); setToggleSingle(!toggleSingle);  }}>
                                                    <EyeIcon className={'h-5 w-5 text-white'} />
                                                </div>

                                                { canEdit && (
                                                    removeFiles.filter(e => e.id === file.id).length > 0   ? (
                                                        <div className={'absolute right-2 bg-red-400 rounded-full p-1 top-2 z-10 block cursor-pointer'}>
                                                            <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0 text-white"/>
                                                        </div>

                                                    ) : (
                                                        <div className={'absolute right-2 bg-red-400 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'} onClick={() => {
                                                            removeImage(file.id);
                                                        }}>
                                                            <TrashIcon className={'h-5 w-5 text-white'} />
                                                        </div>
                                                    )
                                                    )
                                                }

                                                <img
                                                    src={file?.reference ?? ''}
                                                    alt=""
                                                    className={'object-contain h-48 w-full pointer-events-none group-hover:opacity-75'}
                                                />
                                                <button type="button" className="absolute inset-0 focus:outline-none">
                                                    <span className="sr-only">View details for </span>
                                                </button>
                                            </div>
                                        </li>
                                    ))}

                                    {
                                        canEdit && (
                                            <li className="relative">
                                                <UploadImages
                                                    isLoading={isUpdateLoading}
                                                    onException={(r) => {setUpdateException(r)}}
                                                    onUpload={(r) => {
                                                        let data = {};
                                                        data['id'] = container_id;
                                                        data['gallery'] = {medias: [r] };
                                                        updateContainer(data);
                                                    }}
                                                />
                                            </li>
                                        )
                                    }

                                </ul>
                            )
                        }

                        {/* Warning */}
                        {updateException && (
                            <Warning
                                message={updateException}
                            />
                        )}

                    </section>
                </div>

                <FsLightbox
                    toggler={ toggleSingle }
                    disableLocalStorage={true}
                    sources={ [singleImage]}
                    type="image"
                />

                {
                    !isLoading && (
                        <div className="mt-3">
                            <ListFooter
                                isActive={false}
                                    backLink={LOGISTICS + SHIPPING + '/'+ id + CONTAINERS}
                                backLabel={t('app.container.back')}
                            />
                        </div>
                    )
                }

            </div>

        </BasePermissionWrapper>
        </>
    );
};

export default ContainerGallery;
