import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import FormPopup from "../../../../../../../../components/common/popup/form-popup";
import {Warning} from "../../../../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../../../../components/common/form/input-submit";
import {putContainerPacking} from "../../../../../../../../api/container";
import { useParams } from "react-router-dom";
import {MinusIcon, PlusIcon} from "@heroicons/react/outline/esm";
import InputRange from "../../../../../../../../components/common/form/input-range";
import {useTranslation} from "react-i18next";

export default function EditBox(
    {
        isOpen,
        setIsOpen,
        boxDetail,
        groupId,
        setDetail,
    }) {
    const {
        register,
        handleSubmit,
        watch,
        setValue
    } = useForm();

    let {id, container_id}  = useParams();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const storeBox = async (data) => {
        setLoading(true);
        putContainerPacking({ shipping_id: id, container_id: container_id, group_id: groupId.group_id,  sub_group_id: groupId.sub_group_id, packing_id: boxDetail.id, data })
            .then((response) => {
                setLoading(false);
                setDetail(response);
                setIsOpen(false)
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        setValue('loaded_box', boxDetail.loaded_box)
    }, [boxDetail, setValue]);


    const onSubmit = async (data) => {
        data['id'] = boxDetail.id;
        await storeBox(data);
    };


    return (
        <FormPopup
            title={t("app.container.load_inside_container")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">

                    <div className="grid grid-cols-1 gap-y-5 gap-x-5">

                        <div className="justify-self-center">
                            <h1 className="text-lg font-bold text-gray-900">{t("app.container.packagings")}: {watch('loaded_box')}/{boxDetail.box}</h1>
                        </div>

                        <div className="px-10">
                            <InputRange
                                min={0}
                                max={boxDetail.box}
                                input={{ ...register("loaded_box", { required: true }) }}
                            />
                        </div>

                        <div className={'grid before:col-span-1 after:before:col-span-1 grid-cols-3'}>
                            <div className="justify-self-center">
                                <button className="mr-5 btn bg-red-200 text-red-800 hover:bg-red-500 hover:text-white disabled:opacity-70 disabled:cursor-not-allowed"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if(parseInt(watch('loaded_box')) > 0){
                                                setValue('loaded_box', (parseInt(watch('loaded_box')) - 1));
                                            }
                                        }}
                                        disabled={!(parseInt(watch('loaded_box')) > 0)}
                                >
                                    <MinusIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button className=" btn bg-green-200 text-green-800  hover:bg-green-500 hover:text-white disabled:opacity-70 disabled:cursor-not-allowed"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if(boxDetail.box > parseInt(watch('loaded_box'))){
                                                setValue('loaded_box', (parseInt(watch('loaded_box')) + 1));
                                            }
                                        }}
                                        disabled={!(boxDetail.box > parseInt(watch('loaded_box')))}

                                >
                                    <PlusIcon
                                        className="h-4 w-4 "
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Warning*/}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.form.save")}
                    />
                </div>
            </form>



        </FormPopup>
    );
}
