import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import {cleanData} from "../../../utils/converter";
import InputText from "../../../components/common/form/input-text";
import {useTranslation} from "react-i18next";
import { passwordRegex} from "../../../constants/regex";
import { supportedLocalesSelect} from "../../../i18n";
import InputBaseSelect from "../../../components/common/form/input-base-select";
import {useDispatch, useSelector} from "react-redux";
import EditFooter from "../../../components/partials/footer/edit-footer";
import {putUser} from "../../../api/user";
import {onboardingDetails} from "../../../reducers/userReducer";
import {Warning} from "../../../components/common/alert/banner";
import toast from "react-hot-toast";
import SignatureCard from "../../../components/partials/upload/signature-card";
import InputCheckbox from "../../../components/common/form/input-checkbox";
import SimpleUpload from "../../../components/partials/upload/simple-upload";
import noImage from "../../../resources/images/user-placeholder.jpg"

const Settings = () => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });
    const { t, i18n } = useTranslation();
    const { detail } = useSelector((state) => state.user );

    let dispatch = useDispatch();

    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [fileUrl, setFileUrl] = useState(false);
    const [removeSignature, setRemoveSignature] = useState(false);


    const [picture, setPicture] = useState(false);
    const [removePicture, setRemovePicture] = useState(false);
    const [newPicture, setNewPicture] = useState(false);



    const updateUserDetail = async (data) => {
        if(detail){
            setUpdateLoading(true);
            putUser({user_id: detail.id, data})
                .then(response => {
                    dispatch(onboardingDetails(response));
                    i18n.changeLanguage(response.locale);
                    setUpdateLoading(false);
                    toast.success(t("app.settings.update_success"))
                    setValue('password', '');
                }).catch(e => {
                setException(e.message);
                setUpdateLoading(false);
            });
        }
    };

    const onSubmit = async (data) => {
        cleanData(data);
        if(data['password'] === ''){
            delete data['password']
        }

        if(newPicture !== false){
            data['picture'] = {media_url: newPicture}
        }

        if(removePicture && newPicture === false){
            data['remove_picture'] = true;
        }

        if(fileUrl !== false){
            data['signature'] = {media_url: fileUrl}
        }

        if(removeSignature && fileUrl === false){
            data['remove_signature'] = true;
        }

        await updateUserDetail(data);
    };

    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('firstname', detail.firstname);
            setValue('lastname', detail.lastname);
            setValue('locale', detail.locale);
            setValue('email_notification', detail.email_notification);
            setValue('push_notification', detail.push_notification);
            if(detail.default_type && detail.default_type === 'company'){
                setValue('company_type', detail.company_type);
            }
            if(detail.picture){
                setPicture(detail.picture.reference)
            }
        }
    }, [detail, setValue, dispatch]);


    return (
        <div className="mx-auto">
            <div className="bg-white shadow-sm rounded-sm relative ">

                <form className="lg:col-span-9 shadow" onSubmit={handleSubmit(onSubmit)}>

                    {/* Profile section */}
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                        <div className="flex flex-col lg:flex-row">
                            <div className="flex-grow space-y-6">
                                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">


                                    <div className="col-span-full flex items-center gap-x-8">
                                        <img
                                            src={picture ? picture : noImage}
                                            alt=""
                                            className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                                        />
                                        <div>
                                            <SimpleUpload
                                                label={t("app.form.profile_image")}
                                                onUpload={r => {
                                                    setPicture(r.reference);
                                                    setNewPicture(r.reference);
                                                }}
                                            />
                                        </div>

                                        <div className="ml-1 flex">

                                            {
                                                picture && (
                                                    <button type="button"
                                                            onClick={() => {
                                                                setRemovePicture(true);
                                                                setPicture(false);
                                                            }}
                                                            className="bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-red-900"
                                                    >{t("app.common.remove")}
                                                    </button>
                                                )
                                             }
                                        </div>




                                    </div>


                                    <InputText
                                        id={'firstname'}
                                        type={'text'}
                                        errors={errors.firstname}
                                        input={{...register("firstname", { required: true })}}
                                        label={t("app.form.firstname")}
                                    />

                                    <InputText
                                        id={'lastname'}
                                        type={'text'}
                                        errors={errors.lastname}
                                        input={{...register("lastname", { required: true })}}
                                        label={t("app.form.lastname")}
                                    />

                                    <InputText
                                        id={'email'}
                                        type={'email'}
                                        errors={errors.email}
                                        input={{...register("email", { required: false })}}
                                        label={t("app.form.email")}
                                        isDisabled={true}
                                        defaultValue={detail?.email ?? ''}
                                    />

                                    <InputBaseSelect
                                        id={'locale'}
                                        options={supportedLocalesSelect}
                                        errors={errors.locale}
                                        input={{...register("locale", { required: true })}}
                                        label={t("change-language")}
                                    />

                                    {
                                        (detail && detail.default_type === 'company') && (
                                            <InputBaseSelect
                                                id={'company_type'}
                                                options={[{value: 'exporter', name: t("app.settings.exporter")},{value: 'importer', name: t("app.settings.importer")}]}
                                                errors={errors.company_type}
                                                input={{...register("company_type", { required: false })}}
                                                label={t("app.settings.company_type")}
                                            />
                                        )
                                    }

                                    <InputText
                                        id={'password'}
                                        type={'Password'}
                                        errors={errors.password}
                                        input={{...register("password", { required: false, pattern: passwordRegex })}}
                                        label={t("app.form.password")}
                                        warningRegex={t("app.warning.password_regex")}
                                    />

                                </div>

                                <SignatureCard
                                    label={t("app.common.signature")}
                                    picture={detail?.signature?.reference ?? false}
                                    onSave={(r) => {
                                        setFileUrl(r);
                                    }}
                                    onRemove={(r) => {
                                        setRemoveSignature(r);
                                    }}

                                />

                                <div className="border-b">
                                    <h2 className="text-lg  leading-6 font-medium text-gray-900">{t("app.settings.notification")}</h2>
                                </div>

                                <div className="mt-6 flex flex-col lg:flex-row">
                                    <div className="flex-grow space-y-6">

                                        <InputCheckbox
                                            label={t("app.settings.email_notification_title")}
                                            description={t("app.settings.email_notification_desc")}
                                            id={'email_notification'}
                                            errors={errors.email_notification}
                                            input={{...register("email_notification", { required: false })}}
                                        />

                                        <InputCheckbox
                                            label={t("app.settings.push_notification_title")}
                                            description={t("app.settings.push_notification_desc")}
                                            id={'push_notification'}
                                            errors={errors.push_notification}
                                            input={{...register("push_notification", { required: false })}}
                                        />

                                    </div>
                                </div>


                            </div>
                        </div>

                        {/* Warning */}
                        {exception && (
                            <Warning
                                color={'red'}
                                message={exception}
                            />
                        )}

                    </div>

                    {/* Privacy section */}
                    <div className="pt-6 ">
                        <EditFooter
                            btnLabel={t("app.settings.update_detail")}
                            isLoading={isUpdateLoading}
                        />
                    </div>




                </form>

            </div>
        </div>
    );
};

export default Settings;
