import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import SlideFull from "../../layout/slide-full";
import {ActionButton} from "../../layout/page-header";
import {CheckIcon} from "@heroicons/react/outline";
import {getPathParam} from "../../../utils/converter";
import {REPORT_CONFIRM, SHIPPING} from "../../../api/endpoints";
import ConfirmPopup from "../../common/popup/confirm-popup";
import {getFileType} from "../../../constants/file-extension";
import {putDownloadDocument} from "../../../api/report";
import {useSelector} from "react-redux";

const ReportViewer = ({
   isOpen,
   setIsOpen,
   title,
   type = '',
   reportDetail,
   isActiveConfirm = true,
   entityId = false,
   reportId = false,
   isReportConfirmed = false,
   onUpdateData,
   zIndex = ' z-10 '
}) => {

    const { t } = useTranslation();

    const [generateConfirm, setGenerateConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState(false);
    const { loggedCompany } = useSelector((state) => state.user);

    const Title = () => {
        return(
            <>
                <div className="sm:flex sm:justify-between items-center">
                    {title}
                </div>
            </>

        );
    };


   // const ext = reportDetail?.reference?.split('.')?.pop() ?? '';
   // const fileType = getFileType(ext);



    const downloadDocument = () =>{

        let data = false;

        if(type === 'report_log'){
            data = {report_log: {id: reportId}}
        }

        if(type === 'document'){
            data = {document: {id: reportId}}
        }

        if(loggedCompany && data){
            putDownloadDocument({ company_id: loggedCompany.id, data: data })
                .then((response) => {
                }).catch((e) => {});
        }
    };

    const download = async (path, filename) => {
        const response = await fetch(path);
        const buffer = await response.arrayBuffer();

        const ext = path.split('.').pop();
        const fileType = getFileType(ext)
        if(fileType){
            const pdf = new File([buffer], filename, { type: fileType });
            const files = [pdf];

            // Create a Blob object
            const blob = new Blob(files, {type: fileType});

            // Create an object URL
            const url = URL.createObjectURL(blob);

            // Create a new link
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;

            // Append to the DOM
            document.body.appendChild(anchor);

            // Trigger `click` event
            anchor.click();
            downloadDocument();

            // Remove element from DOM
            document.body.removeChild(anchor);
            // Release the object URL
            URL.revokeObjectURL(url);
        }

    };



    return (
        <>
            <SlideFull
                title={Title}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size={'max-w-7xl'}
                header={Header}
                hasPadding={false}
                activeOverflow={false}
                zIndex={zIndex}
                extras={{
                    isActiveConfirm,
                    isReportConfirmed,
                    onGenerateConfirm: () => {
                        const confirmDetail = {
                            title: t("app.report.confirm_report"),
                            message: t("app.report.confirm_description"),
                            endpoint: getPathParam([SHIPPING, entityId, REPORT_CONFIRM, reportId]),
                            endpointData: {id: entityId}
                        };

                        setConfirmDetail(confirmDetail);
                        setGenerateConfirm(true);
                    },
                    onDownloadFile: () => {
                        download(reportDetail.reference, title);
                    },
                    onShareFile: async () => {
                        const response = await fetch(reportDetail.reference);
                        const buffer = await response.arrayBuffer();

                        const ext = reportDetail.reference.split('.').pop();
                        const fileType = getFileType(ext)
                        if (fileType) {

                            let new_title = title;
                            if (!title.toLowerCase().endsWith('.' + ext.toLowerCase())) {
                                new_title = title + '.' + ext;
                            }


                            const pdf = new File([buffer], new_title, {type: fileType});
                            const files = [pdf];

                            if (navigator.canShare({ files })) {
                                try {
                                    downloadDocument();
                                    await navigator.share({
                                        files,
                                        title: new_title,
                                        text: ''
                                    })
                                } catch (error) {
                                    //alert(`Error: ${error.message}`);
                                }
                            } else {
                                alert(`Your system doesn't support sharing these files.`)
                            }
                        }
                    }
                }}
                setClean={() => {
                }}

            >

                {
                    reportDetail  && (
                        <div className="space-y-6 pb-5">

                            <DocViewer
                                prefetchMethod="GET" 
                                requestHeaders={{
                                    "Cache-Control": 'no-cache'
                                }}
                                pluginRenderers={DocViewerRenderers} documents={[{ uri: reportDetail?.reference ?? '' }]} className="h-screen pb-20"
                                theme={{
                                    primary: "#F8FBFF",
                                    secondary: "#01234b",
                                    tertiary: "#EAF0F6",
                                    text_primary: "#01234b",
                                    text_secondary: "#01234b",
                                    text_tertiary: "#00000099",
                                    //disableThemeScrollbar: false,
                                }}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: true
                                    }
                                }}
                            />
                        </div>
                    )
                }





                {generateConfirm && (
                    <ConfirmPopup
                        isOpen={generateConfirm}
                        setIsOpen={() => {
                            setGenerateConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(response) => {
                            onUpdateData(response);
                        }}
                    />
                )}



            </SlideFull>


        </>

    );
};


const Header = ({extras}) => {

    const { t } = useTranslation();

    let deviceIsMobile = false; //At the beginning we set this flag as false. If we can detect the device is a mobile device in the next line, then we set it as true.
    // eslint-disable-next-line
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        // eslint-disable-next-line
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        deviceIsMobile = true;
    }


    return (

        <div className="sm:flex sm:justify-between items-center">

            {
                !deviceIsMobile ? (
                    <div
                        onClick={() => {
                            extras.onDownloadFile();
                        }}
                        className="text-md text-indigo-900 underline font-semibold lowercase cursor-pointer mr-2"
                    >
                        <span>{t("app.container.download_document")}</span>
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            extras.onShareFile();
                        }}
                        className="text-md text-indigo-900 underline font-semibold lowercase cursor-pointer mr-2"
                    >
                        <span>{t("app.container.download_document")}</span>
                    </div>
                )
            }

            {
                extras.isActiveConfirm && (
                    <>
                        {
                            extras.isReportConfirmed ? (
                                    <div
                                        className="btn text-green-600 border-green-700"
                                    >
                                        <CheckIcon className="w-4 h-4" aria-hidden="true" />
                                       <span className="ml-2">{t("app.report.confirmed_report")}</span>
                                    </div>

                            ) : (
                                    <ActionButton
                                        onClick={() => {
                                            extras.onGenerateConfirm(true);
                                        }}
                                        icon={
                                            <CheckIcon
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                            />
                                        }
                                        text={t("app.report.confirm_report")}
                                    />
                            )
                        }
                    </>


                )
            }

        </div>

    )
};


export default ReportViewer;
