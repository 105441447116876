import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import FormPopup from "../../../../components/common/popup/form-popup";
import {cleanData, getSelectParams} from "../../../../utils/converter";
import InputSubmit from "../../../../components/common/form/input-submit";
import {Warning} from "../../../../components/common/alert/banner";
import InputSelect from "../../../../components/common/form/input-select";
import InputText from "../../../../components/common/form/input-text";
import {getEventType, postEvent, postEventImporter} from "../../../../api/event";
import InputDate from "../../../../components/common/form/input-date";
import {useTranslation} from "react-i18next";

export default function AddEvent({isOpen, setIsOpen, setDetail}) {

    const { loggedCompany, isExporter } = useSelector((state) => state.user);
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    let {id}  = useParams();
    const { t } = useTranslation();


    // containers type list
    const [isEventTypesListLoading, setEventTypesListLoading] = useState(false);
    const [eventTypesList, setEventTypesList] = useState([]);


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const storeData = async (data) => {
        if(loggedCompany){
            setLoading(true);
            (isExporter ? postEvent({shipping_id: id, data}) :
                postEventImporter({shipping_id: id, data}))
                .then(response => {
                    setIsOpen(false);
                    setDetail(response);
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };


    useEffect(() => {
        function fetchList(){
            if(loggedCompany){
                setEventTypesListLoading(true);
                getEventType({shipping_id: id})
                    .then(response => {
                        setEventTypesList(getSelectParams(response, 'name'));
                        setEventTypesListLoading(false);
                    }).catch(e => {
                    setEventTypesListLoading(false);
                });
            }
        }

        fetchList();
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        cleanData(data);
        await storeData(data);
    };

    return (
        <FormPopup title={t("app.event.add_event")} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-y-4">

                        <Controller
                            name="event_type"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputSelect
                                    label={t("app.event.event_type")}
                                    name={name}
                                    options={eventTypesList}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isEventTypesListLoading}
                                    isDisabled={isEventTypesListLoading}
                                    errors={error}
                                />
                            )}
                        />

                        <InputText
                            id={'event_note'}
                            type={'text'}
                            errors={errors.event_note}
                            input={{...register("event_note", { required: false })}}
                            label={t("app.event.event_note")}
                        />

                        <Controller
                            name="event_date"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputDate
                                    label={t("app.event.event_date")}
                                    format={'YYYY-MM-D HH:mm:ss'}
                                    placeholder={'Es. 01/12/2021'}
                                    activeMinDate={false}
                                    startDate={value}
                                    disabled={false}
                                    isShowTime={true}
                                    errors={error}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />

                    </div>

                </div>

                {/* Warning */}
                {exception && (
                    <Warning
                        message={exception}
                    />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.event.add_event")}
                    />
                </div>
            </form>
        </FormPopup>
    )
}
