import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import {useTranslation} from "react-i18next";

export default function JsCodePopup({isOpen, setIsOpen}) {

    const { t } = useTranslation();


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-white shadow-xl">

                            <div className="flex justify-between border-b p-4 items-center">
                                <div>
 <h3 className="text-lg font-medium leading-6 text-blue-1000">{t("app.company_preference.dec_title")}</h3>
                                </div>
                                <div>
 <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                </div>
                            </div>

                            <div className="p-4">
                                <p className="text-sm text-gray-900 mb-1" dangerouslySetInnerHTML={{__html: t("app.company_preference.dec_description")}} />
                                <code className="max-h-96 text-sm text-blue-1000 block whitespace-pre overflow-x-scroll bg-solitude-100 p-2 shadow-inner">
                                 {"const crypto = require('crypto');\n" +
                                     "const algorithm = 'aes-256-cbc';\n" +
                                     "const iv = crypto.randomBytes(16);\n" +
                                     "\n" +
                                     "/**\n" +
                                     "* Decrypt method\n" +
                                     "* @param hash\n" +
                                     "* @param secretKey\n" +
                                     "* @returns {string}\n" +
                                     "*/\n" +
                                     "const decrypt = (hash , secretKey) => {\n" +
                                     "  const key = crypto.createHash('sha256').update(secretKey).digest().slice(0, 32);\n" +
                                     "  const decipher = crypto.createDecipheriv(algorithm, key, Buffer.from(hash.iv, 'hex'));\n" +
                                     "  \n" +
                                     "  const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);\n" +
                                     "  \n" +
                                     "  return decrpyted.toString();\n" +
                                     "};\n" +
                                     "\n" +
                                     "module.exports = { decrypt };"}
                                </code>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
