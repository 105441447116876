import {CheckCircleIcon, ExclamationIcon, XCircleIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";

export default function AlertNotification({color, code}) {

    const { t } = useTranslation();

    let bg_color = 'bg-yellow-50';
    let fist_color = 'text-yellow-800';
    let second_color = 'text-yellow-700';
    if(color === 'red'){
        bg_color = 'bg-red-50';
        fist_color = 'text-red-800';
        second_color = 'text-red-700';
    }
    if(color === 'green'){
        bg_color = 'bg-green-50';
        fist_color = 'text-green-800';
        second_color = 'text-green-700';
    }

    return (
        <div className={"rounded-md p-4 " + bg_color} >
            <div className="flex">
                <div className="flex-shrink-0">

                    {
                        color === 'yellow' && (
                            <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        )
                    }

                    {
                        color === 'red' && (
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        )
                    }

                    {
                        color === 'green' && (
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        )
                    }


                </div>
                <div className="ml-3">
                    <h3 className={"text-sm font-medium " + fist_color}>
                        {t("tracking_notification."+code+".title")}
                    </h3>
                    <div className={"mt-2 text-sm "+ second_color}>
                        <p>
                            {t("tracking_notification."+code+".description")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
