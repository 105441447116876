import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserCompanyDetails } from "../api/user";

export const userCompanyStatus = {
  INITIAL: "initial",
  IDLE: "idle",
  NEED_PROCESSING: "need-processing",
  LOADING: "loading",
  ERROR: "error",
};

export const isUserCompanyProcessing = (status) =>
  !(status === userCompanyStatus.IDLE || status === userCompanyStatus.ERROR);

const initialState = {
  company: null,
  user_connection: null,
  permissionList: [],
  permissions: {},
  user_filters: [],
  subscriptions: [],
  status: userCompanyStatus.INITIAL,
  menuCounter: [],
  error: null,
  // to avoid permissions reprocessing if the permission list hasn't changed we save the hash of the last permission list and avoid reprocessing if the permissions haven't changed
  lastPermissionsHash: null,
};


// in MS
export const permissionsRefreshInterval = 60000;


export const refreshPermissions = createAsyncThunk(
  "userCompany/refreshPermissions",
  async ({ company_id }) => {
    try {
      const response = await getUserCompanyDetails(company_id);
      return response;
    } catch (e) {
      throw e;
    }
  }
);

export const refreshFilters = createAsyncThunk(
    "userCompany/refreshFilters",
    async ({ company_id }) => {
      try {
        const response = await getUserCompanyDetails(company_id);
        return response;
      } catch (e) {
        throw e;
      }
    }
);

export const fakePermissions = createAsyncThunk(
    "userCompany/fakePermissions",
    async () => {
      return [{permission: 'super-admin'}];
    }
);

export const initialPermissionsLoad = () => (dispatch, getState) => {
  const { user } = getState();
  if (user.loggedCompany.id) {
    dispatch(refreshPermissions({ company_id: user.loggedCompany.id }));
  }
};

export const setFakePermission = () => (dispatch, getState) => {
  const { user, userCompany } = getState();
  if (
      !user.loggedCompany &&
      userCompany.status === userCompanyStatus.INITIAL
  ) {
    dispatch(
        fakePermissions()
    );
  }
};

// Hash permission string
const hashPermissionList = (pl) => {
  const strToHash = JSON.stringify(pl);
  let hash = 0;
  for (let i = 0; i < strToHash.length; i++) {
    const char = strToHash.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};


// PROCESS PERMISSIONS
const processPermissions = (list) => {
  let navigation = {DASHBOARD: true, ACCOUNT: true};
  let permissionDict = {};
  let permissionSpecificDict = {};

  let isSuperAdmin = false;
  let isAdmin = false;

  list.forEach((p) => {
    const [collection, group, permission_type] = p.permission.split(".");

    if (permission_type === "menu") {
        navigation[group.toUpperCase()] = true;
    }

    if (collection === "super_admin") {
      navigation['USER'] = true;
      navigation["COMPANY_SETTINGS"] = true;
      isSuperAdmin = true;
    }

    if (collection === "admin" || collection === "super_admin") {
      navigation["ORDER"] = true;
      navigation["QUOTATION"] = true;
      navigation["LEAD"] = true;
      navigation["SALES"] = true;
      navigation["SHIPPING"] = true;
      navigation["CUSTOMER"] = true;
      navigation["PRODUCT"] = true;
      navigation["FULFILLMENT"] = true;
      navigation["BOOKING"] = true;
      navigation["PRODUCT_SETTING"] = true;
      navigation["COMPANY_FORWARDER"] = true;
      navigation["SUPPLIER"] = true;
      navigation["COMPANY_TASK"] = true;
      navigation["COMPANY_CONFIGURATION"] = true;
      isAdmin = true;
    }else {
      if (permission_type === "delete" || permission_type === "edit" || permission_type === "create" ||  permission_type === "list" ||  permission_type === "view") {
        if(!permissionDict[group]){
          permissionDict[group] = {};
        }
        permissionDict[group][permission_type] = true
      }else{
        if(!permissionSpecificDict[group]){
          permissionSpecificDict[group] = {};
        }
        permissionSpecificDict[group][permission_type] = true

      }
    }
  });

  return { navigation, permissionDict, permissionSpecificDict, isSuperAdmin, isAdmin };
};

const userCompanySlice = createSlice({
  name: "userCompany",
  initialState,
  reducers: {

    // PROCESS PEREMISSION
    processPermissionList: (state) => {
      const hash = hashPermissionList(state.permissionList);
      if (state.lastPermissionsHash !== hash) {
        state.permissions = processPermissions(state.permissionList);
        state.lastPermissionsHash = hash;
      }
      state.status = userCompanyStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshPermissions.pending, (state) => {
        state.status = userCompanyStatus.LOADING;
      })
      .addCase(refreshPermissions.fulfilled, (state, action) => {
        state.status = userCompanyStatus.NEED_PROCESSING;
        state.company = action?.payload?.company ?? null;
        state.user_connection = action?.payload?.user_connection ?? null;
        state.subscriptions = action?.payload?.subscriptions ?? [];
        state.user_filters = action?.payload?.filters ?? [];

        if (action.payload && action.payload.company  && action.payload.company.product_manage_status){
          updateMenuCounter(state, 'not_confirmed_product', action?.payload?.not_confirmed_product);
        }

        // TODO CHECK
        state.permissionList = USE_MOCK_PERMISSIONS
          ? MOCK_PERMISSIONS
          : action.payload.permissions;


        state.error = null;
      })
      .addCase(refreshPermissions.rejected, (state, action) => {
        state.status = userCompanyStatus.ERROR;
        state.error = action.error;
      });
    builder
      .addCase(refreshFilters.pending, (state) => {
        state.isLoadingRefreshFilters = true;
      })
      .addCase(refreshFilters.fulfilled, (state, action) => {
        state.user_filters = action?.payload?.filters ?? [];
        if (action.payload && action.payload.company && action.payload.company.product_manage_status) {
          updateMenuCounter(state, 'not_confirmed_product', action?.payload?.not_confirmed_product);
        }
      })
      .addCase(refreshFilters.rejected, (state, action) => {
        state.isLoadingRefreshFilters = false;
      });
    builder
        .addCase(fakePermissions.pending, (state) => {
          state.status = userCompanyStatus.LOADING;
        })
        .addCase(fakePermissions.fulfilled, (state, action) => {
          state.status = userCompanyStatus.NEED_PROCESSING;
          state.permissionList = action.payload;
          state.error = null;
        })
        .addCase(fakePermissions.rejected, (state, action) => {
          state.status = userCompanyStatus.ERROR;
          state.error = action.error;
        });
  },
});

const USE_MOCK_PERMISSIONS = false;
const MOCK_PERMISSIONS = [
  {
    permission: "order.list",
    entity_id: "4d44cd81-c601-4e68-a445-9c0eb6efbc1e",
  },
  {
    permission: "order.create",
    entity_id: "4d44cd81-c601-4e68-a445-9c0eb6efbc1e",
  },
];


function updateMenuCounter(state, label, value) {
  // Trova l'indice dell'elemento con la label specificata
  const index = state.menuCounter.findIndex(item => item.label === label);

  if (index !== -1) {
    // Se l'elemento esiste, aggiorna il valore
    state.menuCounter[index].value = value ?? 0;
  } else {
    // Se l'elemento non esiste, aggiungi un nuovo oggetto
    state.menuCounter.push({
      label: label,
      value: value ?? 0,
    });
  }
}


export const { processPermissionList } = userCompanySlice.actions;

export default userCompanySlice.reducer;
