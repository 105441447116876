import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import {useTranslation} from "react-i18next";
import {XCircleIcon} from "@heroicons/react/outline";

export default function PermissionRequiredPopup({isOpen, setIsOpen, details}) {

    const { t } = useTranslation();

    const getTitleTranslation = ({permission}) => {
        // eslint-disable-next-line
        const [collection, group, type] = permission.split(".");
        if(type === 'view' || type === 'list' || type === 'create' || type === 'edit' || type === 'delete'){
            return t("app.permissions."+group) +' ('+t("app.permissions."+type)+')';
        }else{
            return t("app.permissions."+group+"_"+type);
        }
    }



    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-red-50 shadow-xl">

                            <div className="flex justify-between   py-2 px-4 items-center">
                                <h2 className=" text-xl leading-8 font-extrabold tracking-tight text-red-800">
                                    {t("app.permissions.permission_error_title")}
                                </h2>
                                <div>
                                    <button className=" border-0" onClick={() => {setIsOpen(false);}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-red-800" /></button>
                                </div>



                            </div>

                            <p className="  px-4  text-sm text-red-800">
                                {t("app.permissions.permission_error_description")}
                            </p>


                            <div className="mb-6 p-4">



                                <p className="text-sm mt-4">







                                    <div>
                                        {Object.entries(details).map(([key, values], index) => (
                                            <div className="rounded-md bg-red-50 " key={index}>
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <XCircleIcon className="h-5 w-5 text-red-800" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-red-800">{getTitleTranslation({permission: key})}</h3>
                                                        <div className="mt-2 text-sm text-red-700">
                                                            <ul className="list-disc space-y-1 pl-5">
                                                                {values.map((value, idx) => (
                                                                    <li key={idx}>{getTitleTranslation({permission: value})}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </p>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
