import React from "react";
import Initials from "../avatar/initials";

const UserCell = ({ user, extraData = false }) => {
  return (
    <div className="flex items-center gap-1 text-sm">
        {
            user.picture ? (
                <img className="h-8 w-8 rounded-full ring-4 ring-white object-cover" src={user.picture.reference} alt="" />
            ) : (
                <Initials
                    color={user?.color ?? ''}
                    name={user?.fullname ?? ''}
                    isVerified={false}
                    isDisabled={user?.is_active === false ?? false}
                />
            )
        }

        <div className="text-left">
            <div className="truncate text-sm text-gray-900">
                {user?.fullname ?? ''}
            </div>
            <div className="text-xs flex-wrap text-gray-500">
                {user?.notification_email ?? ''}
            </div>
            {
                extraData && (
                    <div className="text-xs flex-wrap text-gray-500">
                        {extraData}
                    </div>
                )
            }

        </div>
    </div>
  );
};

export default UserCell;
