import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import { joinClassNames } from "../../../utils/converter";

export default function ActionsMenu({ children, extraClass  = 'right-0 ', classExtra = '', baseClass='inline-block', icon = DotsHorizontalIcon }) {
  const hasActiveChildren =
    React.Children.map(children, Boolean).filter(Boolean).length > 0;

  const Icon = icon;

  return (
    hasActiveChildren && (
      <Menu as="div" className={baseClass}>
        <Menu.Button>
          <Icon className="w-4 h-4 ml-4 text-gray-400 group-hover:text-gray-500" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={(extraClass) +  "origin-top-right z-10 absolute text-left mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "+(classExtra)}>
            <div className="py-1">
              {React.Children.map(
                children,
                (child) =>
                  child && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={joinClassNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "px-4 py-2 text-sm flex flex-col"
                          )}
                        >
                          {child}
                        </div>
                      )}
                    </Menu.Item>
                  )
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  );
}
