import {COMPANY, CONNECTION_VERIFY, SUPPLIERS, SUPPLIER, COMPANY_CONNECTION_VERIFY, SEND_CONNECTION} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// connection verify
export const getCompanyConnectionVerify = ({connection_code}) =>
    API.get(getPathParam([COMPANY_CONNECTION_VERIFY, connection_code ]));

export const getConnectionVerify = ({company_id, connection_code}) =>
    API.get(getPathParam([COMPANY, company_id, CONNECTION_VERIFY, connection_code ]));

export const putConnectionVerify = ({company_id, connection_code}) =>
    API.put(getPathParam([COMPANY, company_id, CONNECTION_VERIFY, connection_code]), {});

// Supplier
export const getSuppliers = ({company_id, params, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, SUPPLIERS] ) + (params ? serialize(params)  : ''), signal && {signal: signal});

export const getSupplier = ({company_id, supplier_id, signal}) =>
    API.get(getPathParam([COMPANY, company_id, SUPPLIER, supplier_id ]), {signal: signal});

export const putSupplier = ({company_id, supplier_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, SUPPLIER, supplier_id]), data);

export const putSupplierConnection = ({company_id, supplier_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, SUPPLIER, supplier_id, SEND_CONNECTION]), data);

export const postSupplier = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, SUPPLIER]), data);
