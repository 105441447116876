import React, {useState} from "react";
import CalendarIcon from "../../../resources/icons/CalendarIcon";
import {ChatIcon, LinkIcon, PaperClipIcon} from "@heroicons/react/outline";
import {getPathParam} from "../../../utils/converter";
import {COMPANY} from "../../../routes/endpoints";
import {TASK} from "../../../api/endpoints";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Initials from "../avatar/initials";
import {getFormattedDate} from "../../../utils/timeUtils";
import moment from "moment";
import {createPopper} from "@popperjs/core";
import ActionsMenu from "../table/actions-menu";
import ConfirmPopup from "../popup/confirm-popup";
import ReportViewer from "../../partials/report/report-viewer";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const TaskCard = ({isDragging, isComplete, data, onRemove, onEdit, onChecked, isEditable, dragHandle, onOpenChat}) => {
    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();
    const today = new Date();

    // Confirm Modal
    const [isChecked, setChecked] = useState(isComplete);

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});
    const [url, setUrl] = useState(null);
    const [isOpenReportView, setOpenReportView] = useState(false);

    const removeDetail = {
        title: t("app.tasks.remove_title"),
        message: t("app.tasks.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, data.id])
    };


    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={url ? {reference: url} : false}
                title={t("app.document.preview_file")}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />
            <div className={classNames(
                isDragging  ? 'bg-white opacity-60' : 'bg-white',
                isComplete ? 'opacity-60' : '',
                "shadow-lg rounded-sm border border-slate-200 p-4"
            )}
            >

                <div className="flex justify-between items-center mb-2">

                    <div className="flex items-center">
                        <button className="cursor-move mr-4" {...dragHandle}>
                            <span className="sr-only">Drag</span>
                            <svg className="w-3 h-3 fill-slate-500" viexbox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill="#CBD5E1" fillRule="evenodd" />
                            </svg>
                        </button>

                        <div className={classNames(
                            moment(data.task_date, "YYYY-M-D HH:mm:ss") < today ? 'text-red-500' : '',
                            moment(data.task_date, "YYYY-M-D HH:mm:ss") > today ? 'text-gray-900' : '',
                            "flex items-center mr-4"
                        )}>
                            <CalendarIcon className="w-4 h-4 shrink-0 fill-current mr-1.5"/>
                            <div className="text-sm font-semibold">{getFormattedDate(data.task_date)}</div>
                        </div>

                        {
                            data.file && (
                                <div  onClick={() => {
                                    setUrl(data.file.reference);
                                    setOpenReportView(true);
                                }} className="text-sm font-semibold flex items-center text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                    <LinkIcon className="w-4 h-4  mr-1.5"/>
                                    {data.file_name}
                                </div>
                            )
                        }

                        {
                            (data.company_task_files && data.company_task_files.length > 0 )&& (
                                <div className="flex items-center text-blue-1000 ">
                                    <PaperClipIcon className={'h-4 w-4 mr-2 '} /> {data.company_task_files.length}
                                </div>
                            )
                        }


                    </div>



                    <div className="flex items-center">
                        {
                            (data.company_task_users && data.company_task_users.length > 0 ) && (
                                <>
                                    <div className="flex shrink-0 -space-x-4 -ml-px">

                                        {
                                            data.company_task_users.map(item => (
                                                <Popover key={item.id} item={item} />
                                            ))
                                        }

                                    </div>


                                    <ChatIcon className={

                                        data.is_muted ?  'w-6 h-6 ml-2 text-red-700 hover:text-red-600 cursor-pointer':
                                            'w-6 h-6 ml-2 text-green-700 hover:text-green-600 cursor-pointer'


                                    } onClick={() => {onOpenChat(data)}} />

                                </>

                            )
                        }


                        {
                            isEditable ? (
                                <ActionsMenu extraClass={''}>
                                    <button className={'text-left'} onClick={() => {onEdit(data)}}>{t("app.common.edit")}</button>
                                    <button className={'text-left'} onClick={() => {onRemove(removeDetail)}}>{t("app.common.remove")}</button>
                                </ActionsMenu>
                            ) : (
                                <ActionsMenu extraClass={''}>
                                    <button className={'text-left'} onClick={() => {onEdit(data)}}>{t("app.tasks.view_task")}</button>
                                </ActionsMenu>

                            )
                        }
                    </div>



                </div>

                <div className="sm:flex sm:justify-between sm:items-center">

                    {/* Left side */}
                    <div className="flex items-center">
                        {/* Drag button */}

                        {/* Checkbox button */}
                        <label className="flex items-center">
                            <input type="checkbox"
                                   onChange={(r) => {
                                       const confirmDetail = {
                                           title: r.target.checked ?  t("app.tasks.update_title") : t("app.tasks.update_r_title") ,
                                           message: r.target.checked ?  t("app.tasks.update_description") : t("app.tasks.update_r_description"),
                                           type: 'update',
                                           endpointData: {
                                               id: data.id,
                                               is_confirmed: r.target.checked,
                                           },
                                           endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, data.id])
                                       };
                                       setOpenConfirm(true);
                                       setChecked(r.target.checked);
                                       setConfirmDetail(confirmDetail);
                                   }}
                                   checked={isChecked} defaultChecked={isChecked} className="peer focus:ring-0 focus-visible:ring w-5 h-5 bg-white border border-gray-300 text-indigo-700 rounded-full"
                            />


                            <div>

                                <div className="font-medium text-gray-900 peer-checked:line-through ml-2">
                                    <h1 className="mb-2 text-blue-1000 uppercase text-xs">{t("app.quotations.created_by")} {data.user.fullname}</h1>
                                    <b className="mb-2">{data.title}</b>
                                    {
                                        data.description && (
                                            <p className="text-xs flex-wrap text-gray-500" dangerouslySetInnerHTML={{__html: data.description}} />
                                        )
                                    }
                                </div>



                            </div>

                        </label>
                    </div>
                </div>


                {
                    data.confirmed_user && (
                        <div className="text-xs text-gray-900 peer-checked:line-through ml-2 mt-4">
                            <div className="flex items-center">

                                <b>{t("app.tasks.completed_by", {fullname: data.confirmed_user.fullname, date: getFormattedDate(data.complete_date)})}</b>
                                {
                                    (!data.is_completed && data.draft_completed && isEditable) && (
                                        <>
                                            <b className="mx-2">{' - '}</b>
                                            <b className={"text-indigo-800 cursor-pointer underline"} onClick={() => {
                                                const confirmDetail = {
                                                    title:   t("app.tasks.reopen_title"),
                                                    message: t("app.tasks.reopen_description"),
                                                    type: 'reopen',
                                                    endpointData: {
                                                        id: data.id,
                                                        is_draft_completed: false,
                                                    },
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, data.id])
                                                };
                                                setOpenConfirm(true);
                                                setConfirmDetail(confirmDetail);
                                            }}>{t("app.tasks.re_open")}
                                            </b>
                                        </>

                                    )
                                }
                            </div>
                        </div>
                    )
                }




                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() =>{
                            setOpenConfirm(false);
                            if(confirmDetail.type !== 'reopen'){
                                setChecked(!isChecked);
                            }
                        }}
                        type={'after-confirm'}
                        detail={confirmDetail}
                        setDetail={(r) => {
                            onChecked(r);
                        }}
                    />
                )}

            </div>
        </>



    );
};

const Popover = ({ item }) => {
    const [popoverShow, setPopoverShow] = React.useState(false);
    const btnRef = React.createRef();
    const popoverRef = React.createRef();
    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "left"
        });
        setPopoverShow(true);
    };
    const closePopover = () => {
        setPopoverShow(false);
    };
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full text-center">
                    <div className="block cursor-pointer"
                         ref={btnRef}
                         onClick={() => {
                             popoverShow ? closePopover() : openPopover();
                         }}>
                        {
                            item.user.picture ? (
                                <img className="h-8 w-8 rounded-full ring-4 ring-white object-cover" src={item.user.picture.reference} alt="" />
                            ) : (
                                <Initials
                                    color={item.user?.color ?? ''}
                                    name={item.user?.fullname ?? ''}
                                    iconClass={' h-8 w-8'}
                                />
                            )
                        }
                    </div>
                    <div
                        className={
                            (popoverShow ? "" : "hidden ") +
                            "border-0 mr-3 block z-50"
                        }
                        ref={popoverRef}
                    >
                        <div className="rounded-md shadow-lg  mr-3">
                            <div className="relative bg-solitude-100 px-4 py-2 font-medium text-gray-900">
                                {item.user.fullname}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default TaskCard;
