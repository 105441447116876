import React, {useEffect} from "react";
import BaseList from "../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../utils/converter";
import {COMPANY, MANUFACTURES, MANUFACTURE} from "../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import { useForm} from "react-hook-form";
import InputText from "../../../components/common/form/input-text";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import {fetchCountries} from "../../../reducers/configReducer";
import BoxIcon from "../../../resources/icons/BoxIcon";
import {MANUFACTURE_IMPORT} from "../../../constants/import";
import exampleFile from "../../../resources/import/exemple-file.csv";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";

const Manufacture = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.VIEW);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.LIST)]} >
            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.catalog.catalog_name"),
                            t("app.catalog.catalog_code"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, MANUFACTURES])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, MANUFACTURE])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, MANUFACTURE, ':id'])}
                        baseResult={{total_rows: 0, manufactures: []}}
                        resultLabel={'manufactures'}
                        title={t("app.catalog.manufacture")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={BoxIcon}
                        addFormLabel={t("app.catalog.add", {type: t("app.catalog.manufacture")})}
                        btnAddLabel={canCreate ? t("app.catalog.add", {type: t("app.catalog.manufacture")}) : false}
                        addPermission={[getPermission(permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.CREATE)]}
                        editForm={EditForm}
                        editTitle={t("app.catalog.details")}
                        isActiveEdit={canView || canEdit}
                        isActiveImport={canEdit}
                        btnImportLabel={t("app.catalog.import")}
                        importType={canCreate ? 'product' : false}
                        uploadType={'company_manufacture'}
                        CSVHeader={MANUFACTURE_IMPORT}
                        exampleFile={exampleFile}


                    />
                )
            }
        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.VIEW);


    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, MANUFACTURE, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    {
                        (canView || canEdit || canDelete) && (
                            <ActionsMenu>
                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onEdit(true, element);
                                        }}
                                        >
                                            {t("app.common.edit")}
                                        </button>
                                    )
                                }
                                {
                                    canDelete && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>
                                    )
                                }
                            </ActionsMenu>
                        )
                    }
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.catalog_name")}>
                        {element.name}
                    </CardDetail>

                    <CardDetail title={t("app.catalog.catalog_code")}>
                        {element?.reference ?? '-'}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, MANUFACTURE, element.id])
    };


    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.VIEW);


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element?.reference ?? '-'}
            </TableDetail>

            <TableDetail>
                {
                    (canView || canEdit || canDelete) && (
                        <ActionsMenu>
                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )
                            }
                            {
                                canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )
                            }
                        </ActionsMenu>
                    )
                }
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);




    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.catalog.catalog_name")}
                    />


                    <InputText
                        id={"reference"}
                        type={"text"}
                        errors={errors.reference}
                        input={{ ...register("reference", { required: true }) }}
                        label={t("app.catalog.catalog_code")}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_MANUFACTURE, permissionType.EDIT);

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object") {
                    setValue(k, getSelectParam(data[k], "name"));
                }else{
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };


    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-md font-medium text-gray-900">
                        {t("app.categories.category")}
                    </h3>

                    <div className="space-y-4 pt-4 pb-5">

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.catalog.catalog_name")}
                            isDisabled={!canEdit}
                        />


                        <InputText
                            id={"reference"}
                            type={"text"}
                            errors={errors.reference}
                            input={{ ...register("reference", { required: true }) }}
                            label={t("app.catalog.catalog_code")}
                            isDisabled={!canEdit}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                {
                    canEdit && (
                        <div className="mt-3">
                            <InputSubmit
                                isLoading={isEditLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        </div>
                    )
                }

            </form>

        </>
    );
}

export default Manufacture;
