import React, { useEffect, useState } from "react";
import ListSection from "../../../components/layout/list-section";
import {getElement, postElement, putElement} from "../../../api/config";
import RemovePopup from "../../common/popup/remove-popup";
import FormPopup from "../../common/popup/form-popup";
import SlidePanel from "../../layout/slide-panel";
import BaseImport from "./base-import";
import TableMenu from "../../common/table/table-menu";
import {CloudIcon, DocumentIcon} from "@heroicons/react/outline";
import ImportProgress from "../upload/import-progress";
import ImportErrorPopup from "../../common/popup/import-error-popup";
import ReportViewer from "../report/report-viewer";
import TruckIcon from "../../../resources/icons/TruckIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import AirIcon from "../../../resources/icons/AirIcon";

const BaseList = ({
                  columns,
                  endpoint,
                  baseResult,
                  resultLabel = false,
                  resultTitle = false,
                  isCustomAdd = false,
                  onSetTitle,
                  title,
                  editTitle,
                  mobRow,
                  deskRow,
                  showHeader = false,
                  addForm = null,
                  editForm = null,
                  icon = false,
                  btnAddLabel = false,
                  addFormLabel,
                  storeEndpoint,
                  defaultWith  = 'max-w-2xl',
                  SlideDefaultWith  = 'max-w-md',
                  updateEndpoint,
                  isActiveEdit = false,
                  onStoreData = false,
                  needRefresh = false,
                  isActiveFormPm = true,
                  isActiveImport = false,
                  btnImportLabel = '',
                  btnTemplateGenerateLabel = false,
                  importType = null,
                  uploadType,
                  CSVHeader,
                  extraParams={},
                  exampleFile,
                  onUpdateParent = false,
                  isActiveFileViewer = false,
                  onConfigure = false,
                  onAddData,
                  typeActive = false,
                  removeActive = null,
                  showShippingType = false,
                  addPermission = [],
                  onTemplateGenerate,
                  internalId = null
}) => {


    const Icon = icon;
    const AddForm = addForm;
    const EditForm = editForm;


    useEffect(() => {
        if(typeActive && typeActive === 'attach'){
            setOpenAdd(true);
            removeActive();
        }
        // eslint-disable-next-line
    }, [typeActive]);

    const shippingTypes = [
        { type: 'land', name: '', icon: TruckIcon },
        { type: 'sea', name: '', icon: ShippingIcon },
        { type: 'air', name: '', icon: AirIcon },
    ];
    const [shippingType, setShippingType] = useState(shippingTypes[0]);
    const [selectedShippingType, setSelectedShippingType] = useState(shippingTypes[0].type);

    //File
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [reportDetail, setReportDetail] = useState({});


    // import
    const [isOpenUpload, setOpenUpload] = useState(false);
    const [lastImport, setLastImport] = useState(false);
    const [onRefresh, setRefresh] = useState(false);
    const [isOpenImportError, setOpenImportError] = useState(false);
    const [ImportErrorCode, setImportErrorCode] = useState(false);


    // GET
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState(baseResult);
    const [offset, setOffset] = useState(0);
    const [query, setQuery] = useState("");

    // DELETE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // POST
    const [isOpenAdd, setOpenAdd] = useState(false);
    const [isStoreLoading, setStoreLoading] = useState(false);
    const [exception, setException] = useState(false);

    // PUT
    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editDetail, setEditDetail] = useState(false);
    const [isEditLoading, setEditLoading] = useState(false);




    const storeData = async (data) => {
        setStoreLoading(true);
        setException(false);
        postElement(storeEndpoint.replace(':code', data?.provider_mail_code ?? ''), data)
        .then(response => {
            if(response.redirect_url){
                window.location.href  = response.redirect_url;
            }else{
                if(onStoreData){
                    onStoreData(response);
                }else{
                    setList(response);
                }
                setOpenAdd(false);
                setStoreLoading(false);

                if(onUpdateParent){
                    onUpdateParent(true);
                }
            }
        }).catch(e => {
            setException(e.message);
            setStoreLoading(false);
        });
    };

    const updateData = async (data) => {
        setEditLoading(true);
        setException(false);
        putElement(updateEndpoint.replace(':id', data.id), data)
            .then(response => {
                if(response.redirect_url){
                    window.location.href  = response.redirect_url;
                }else{
                    setList(response);
                    setEditLoading(false);
                    setOpenEdit(false);
                }

                if(onUpdateParent){
                    onUpdateParent(true);
                }
            }).catch(e => {
                setException(e.message);
                setEditLoading(false);
            });
    };



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElement(endpoint, signal, { ...{ query: (query ? query : null), shipping_type: (selectedShippingType && showShippingType ? selectedShippingType  : null), offset: offset, limit: 25}, ...extraParams });
                setList(res);

                if(resultTitle){
                    onSetTitle(res[resultTitle])
                }


            } catch (e) {
                setList(baseResult);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [offset, query, endpoint, needRefresh, onRefresh, selectedShippingType]);


    const tableNavigation = [
        (btnAddLabel && addPermission.length === 0) && { name: btnAddLabel, actionType: 'add', icon: Icon, isActive: true },
        (btnAddLabel && addPermission.length > 0) && { name: btnAddLabel, permission: addPermission, actionType: 'add', icon: Icon, isActive: true },
        importType && { name: btnImportLabel, actionType: 'import', icon: CloudIcon, isActive: true },
        btnTemplateGenerateLabel && { name: btnTemplateGenerateLabel, actionType: 'template-generate', icon: DocumentIcon, isActive: true }
    ].filter(Boolean);


    return (
        <>

            {
                (isActiveImport) && (
                    <ImportProgress
                        extraClass={"w-full py-3 bg-white px-2 mb-2 shadow-sm rounded-sm"}
                        onNeedRefresh={(r) => {
                            if(r.error_description){
                                setImportErrorCode(r.error_description);
                                setOpenImportError(true);
                            }else{
                                setRefresh(!onRefresh)
                            }
                        }}
                        uploadType={uploadType}
                        lastImport={lastImport}
                        importType={importType}
                    />
                )
            }

            {
                (isActiveFileViewer) && (
                    <ReportViewer
                        isOpen={isOpenReportView}
                        type={'document'}
                        reportDetail={reportDetail?.file ?? false}
                        title={reportDetail?.file_name ?? ''}
                        isActiveConfirm={false}
                        setIsOpen={(r) => {
                            setOpenReportView(r);
                        }}

                    />
                )
            }



            <ListSection
                title={title}
                columns={columns}
                totalRows={list?.total_rows ?? 0}
                list={resultLabel ? list[resultLabel] : list}
                onSearch={setQuery}
                showHeader={showHeader}
                isLoading={isLoading}
                paginationOnChange={setOffset}
                pageLimit={25}
                mobileRow={mobRow}
                desktopRow={deskRow}
                showShippingType={showShippingType}
                shippingTypes={shippingTypes}
                shippingType={shippingType}
                setShippingType={(r) => {
                    setShippingType(r);
                    setSelectedShippingType(r.type);
                }}
                extras={{
                    internalId,
                    onRemove: (r, detail) => {
                        setOpenRemove(r);
                        setRemoveDetail(detail);
                    },
                    onEdit: (r, detail) => {
                        setOpenEdit(r);
                        setEditDetail(detail);
                    },
                    onConfigure: (r) => {
                        onConfigure(r);
                    },
                    onOpenFile: (detail) => {
                        setReportDetail(detail);
                        setOpenReportView(true);
                    },
                    onAdd: (detail) =>{
                        onAddData(detail)
                    },
                    onInternalUpdate: () => {
                        setRefresh(!onRefresh)
                    }
                }}
            >

                {
                    tableNavigation.length > 0 && (
                        <TableMenu
                            tableNavigation={tableNavigation}
                            setActiveGroup={(r) => {
                                if(r === 'add'){
                                    setOpenAdd(true);
                                }

                                if(r === 'import'){
                                    setOpenUpload(true);
                                }
                                if(r === 'template-generate'){
                                    onTemplateGenerate(true);
                                }
                            }}
                        />
                    )
                }


            </ListSection>

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setList(r);
                        if(onUpdateParent){
                            onUpdateParent(true);
                        }
                    }}
                />
            )}

            {!isCustomAdd && isOpenAdd && AddForm && (
                <FormPopup
                    title={addFormLabel}
                    defaultWith={defaultWith}
                    isActiveFormPm={isActiveFormPm}
                    isOpen={isOpenAdd}
                    setIsOpen={(r) => {
                        setOpenAdd(r);
                    }}
                >

                    <AddForm
                        onSubmitData={(r) => {
                            storeData(r);
                        }}
                        dataGet={list}
                        exception={exception}
                        isLoading={isStoreLoading}

                    />

                </FormPopup>
            )}


            {isCustomAdd && isOpenAdd && AddForm && (
                <AddForm
                    onSubmitData={(r) => {
                        storeData(r);
                    }}
                    dataGet={list}
                    exception={exception}
                    isLoading={isStoreLoading}
                    isOpen={isOpenAdd}
                    setIsOpen={(r) => {
                        setOpenAdd(r);
                    }}
                />
            )}

            {isActiveEdit && EditForm && (
                <SlidePanel
                    title={editTitle}
                    isOpen={isOpenEdit}
                    setIsOpen={setOpenEdit}
                    bg={'bg-white'}
                    size={SlideDefaultWith}
                    hasDivider={false}
                    setClean={() => {

                    }}
                >
                    <EditForm
                        onSubmitData={(r) => {
                            updateData(r)
                        }}
                        data={editDetail}
                        exception={exception}
                        isEditLoading={isEditLoading}
                    />

                </SlidePanel>
            )}




            {
                isActiveImport && (
                    <BaseImport
                        isOpen={isOpenUpload}
                        setIsOpen={setOpenUpload}
                        importGroup={importType}
                        uploadType={uploadType}
                        CSVHeader={CSVHeader}
                        exampleFile={exampleFile}
                        onImportComplete={(r) => {
                            setLastImport(r);
                        }}

                    />
                )
            }


            {isActiveImport && isOpenImportError && (
                <ImportErrorPopup
                    isOpen={isOpenImportError}
                    errorCode={ImportErrorCode}
                    setIsOpen={() => {
                        setImportErrorCode(false);
                        setOpenImportError(false);
                        setRefresh(!onRefresh);
                    }}
                />
            )}




        </>

    );
};

export default BaseList;
