import React, { useState } from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FormPopup from "../../../../../components/common/popup/form-popup";
import InputText from "../../../../../components/common/form/input-text";
import InputFormattedNumber from "../../../../../components/common/form/input-formatted-number";
import {postProductDimension} from "../../../../../api/catalog";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import InputToggle from "../../../../../components/common/form/input-toggle";
import {useParams} from "react-router-dom";

export default function AddDimension({
  isOpen,
  setIsOpen,
  onAdded
}) {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });


  const { t } = useTranslation();
  let { id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);


  const storeDimension = async (data) => {
      setLoading(true);
      postProductDimension({ product_id: id, data })
      .then((response) => {
          onAdded(response.dimensions);
          setLoading(false);
          setIsOpen(false);
      })
      .catch((e) => {
          setException(e.message);
          setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    await storeDimension(data);
  };

  return (
      <FormPopup
          title={t("app.products.new_dimension")}
          isOpen={isOpen}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">

              <InputText
                  id={"reference"}
                  type={"text"}
                  errors={errors.reference}
                  input={{ ...register("reference", { required: true }) }}
                  label={t("app.products.dimension_reference")}
              />

              <Controller
                  name="volume"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.volume")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' m³'}
                          errors={error}
                      />
                  )}
              />
              <Controller
                  name="gross_weight"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.gross_weight")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' Kg'}
                          errors={error}
                      />
                  )}
              />

              <Controller
                  name="net_weight"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.net_weight")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' Kg'}
                          errors={error}
                      />
                  )}
              />

              <Controller
                  name="length"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.length")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' cm'}
                          errors={error}
                      />
                  )}
              />

              <Controller
                  name="width"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.width")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' cm'}
                          errors={error}
                      />
                  )}
              />

              <Controller
                  name="height"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.height")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          prefix={''}
                          suffix={' cm'}
                          errors={error}
                      />
                  )}
              />

              <Controller
                  name="qty_per_packaging"
                  rules={{ required: false }}
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                      <InputFormattedNumber
                          label={t("app.products.quantity_box")}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          errors={error}
                      />
                  )}
              />

              <div className="col-span-2 mt-2">
                  <Controller
                        name={"default_dimension"}
                        rules={{ required: false }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputToggle
                                label={t("app.products.default_dimension")}
                                description={t("app.products.default_dimension_desc")}
                                onChange={(e) => {
                                    onChange(e)
                                }}
                                defaultChecked={value}
                                errors={error}
                            />
                        )}
                    />
              </div>

            </div>
          </div>

          {/* Warning */}
          {exception && (
              <Warning message={exception} />
          )}

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                isLoading={isLoading}
                label={t("app.products.add_dimension")}
            />
          </div>
        </form>
      </FormPopup>
  );
}
