import React, { useRef, useEffect } from 'react';

import {
  LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';

import Chart from 'chart.js/auto'

import 'chartjs-adapter-moment';

// Import utilities
import {tailwindConfig, formatValue, formatThousands} from '../../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart({
  data,
  width,
  height,
  isNumber= false
}) {

  const canvas = useRef(null);
  const legend = useRef(null);
  useEffect(() => {

    canvas.current.width = width;
    canvas.current.height = height;

  }, [height, width])


  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            beginAtZero: false,
            border: {
              display: false,
            },
            ticks: {
              callback: (value) => formatThousands(value),
              color: tailwindConfig().theme.colors.gray[400],
            },
            grid: {
              color: tailwindConfig().theme.colors.gray[100],
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM',
              unit: 'month',
              displayFormats: {
                month: 'MMM',
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 10,
              maxRotation: 0,
              color: tailwindConfig().theme.colors.gray[400],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, /*function(tooltipItems, data) {
                console.log(tooltipItems);
                return 'Containers: ' + tooltipItems[0]?.dataset?.totalContainer[tooltipItems[0].dataIndex] ?? 0;
              },*/
              callback: (context) => isNumber ? formatThousands(context.parsed.y) : formatValue(context.parsed.y),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [{
        id: 'htmlLegend',
        afterUpdate(c, args, options) {
          const ul = legend.current;
          if (!ul) return;
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
          // Reuse the built-in legendItems generator
          const items = c.options.plugins.legend.labels.generateLabels(c);
          items.forEach((item) => {
            const li = document.createElement('li');
            li.style.marginRight = tailwindConfig().theme.margin[4];
            // Button element
            const button = document.createElement('button');
            button.style.display = 'inline-flex';
            button.style.alignItems = 'center';
            button.style.opacity = item.hidden ? '.3' : '';
            button.onclick = () => {
              c.setDatasetVisibility(item.datasetIndex, !c.isDatasetVisible(item.datasetIndex));
              c.update();
            };
            // Color box
            const box = document.createElement('span');
            box.style.display = 'block';
            box.style.width = tailwindConfig().theme.width[3];
            box.style.height = tailwindConfig().theme.height[3];
            box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
            box.style.marginRight = tailwindConfig().theme.margin[2];
            box.style.borderWidth = '3px';
            box.style.borderColor = c.data.datasets[item.datasetIndex].borderColor;
            box.style.pointerEvents = 'none';
            // Label
            const labelContainer = document.createElement('span');
            labelContainer.style.display = 'flex';
            labelContainer.style.alignItems = 'center';
            const value = document.createElement('span');
            value.style.color = tailwindConfig().theme.colors.gray[800];
            value.style.fontSize = tailwindConfig().theme.fontSize['3xl'][0];
            value.style.lineHeight = tailwindConfig().theme.fontSize['3xl'][1].lineHeight;
            value.style.fontWeight = tailwindConfig().theme.fontWeight.bold;
            value.style.marginRight = tailwindConfig().theme.margin[2];
            value.style.pointerEvents = 'none';
            const label = document.createElement('span');
            label.style.color = tailwindConfig().theme.colors.gray[500];
            label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
            label.style.lineHeight = tailwindConfig().theme.fontSize.sm[1].lineHeight;
            const theValue = c.data.datasets[item.datasetIndex].data.reduce((a, b) => a + b, 0);
            const valueText = document.createTextNode(isNumber ? formatThousands(theValue) : formatValue(theValue));
            const labelText = document.createTextNode(item.text);
            value.appendChild(valueText);
            label.appendChild(labelText);
            li.appendChild(button);
            button.appendChild(box);
            button.appendChild(labelContainer);
            labelContainer.appendChild(value);
            labelContainer.appendChild(label);
            ul.appendChild(li);
          });
        },
      }],
    });
    return () => chart.destroy();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
      <React.Fragment>
        <div className="px-5 py-3">
          <ul ref={legend} className="flex flex-wrap" />
        </div>
        <div className="grow">
          <canvas ref={canvas} width={width} height={height} />
        </div>
      </React.Fragment>
  );
}

export default LineChart;
