import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteItem from './items';
import { useSelector } from 'react-redux';

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) {
        return '#FFEBE6';
    }
    if (isDraggingFrom) {
        return '#E6FCFF';
    }
    return '#EBECF0';
};

const Wrapper = ({
    style,
    children,
    droppableProps,
    isDragging,
}) => {
    return (
        <div
            className={`flex flex-col flex-auto gap-y-2 h-full mx-1 my-0 px-1 py-0`}
            {...droppableProps}
        >
            {children}
        </div>
    );
};

const DropZone = React.forwardRef((props, ref) => (
    <div
        className={`bg-solitude-400 min-h-[10px] mt-1`}
        ref={ref}
    >
        {props.children}
    </div>
));

const InnerQuoteList = React.memo(function InnerQuoteList(props) {

    const { detail } = useSelector((state) => state.user);

    const isTaskMember = (taskDetails) => {
        let admin = true;

        if (props.isAdmin) {
            admin = false;
        }


        if (taskDetails.user && taskDetails.user.id === detail.id) {
            admin = false;
        }


        if (props.isMember) {
            if (taskDetails) {

                if (taskDetails.assigned_users.length === 0) {
                    admin = false;
                }


                if (taskDetails.assigned_users.length > 0 && taskDetails.assigned_users.filter(r => (r.id === detail.id)).length > 0) {
                    admin = false;
                }
            }
        }



        return admin;
    }





    return (props?.taskes?? []).map((task, index) => (
        <Draggable key={task.id} draggableId={task.id} isDragDisabled={isTaskMember(task)} index={index}>
            {(dragProvided, dragSnapshot) => {
                return(
                    <QuoteItem
                        key={task.id}
                        task={task}
                        keyIndex={props.keyIndex}
                        isDragging={dragSnapshot.isDragging}
                        isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                        provided={dragProvided}
                        onEditTask={() => {
                            props.onEditTask(task)
                        }}
                    />
                )    
            }}
        </Draggable>
    ));
});

function InnerList(props) {
    const { taskes, dropProvided, keyIndex} = props;

    return (
        <div className='flex flex-col gap-y-2 scroll-m-20' >
            <DropZone ref={dropProvided.innerRef}>
                <InnerQuoteList 
                    isDragDisabled={props.isDragDisabled}
                    taskes={taskes} 
                    title={props.title} 
                    isAdmin={props.isAdmin}
                    isMember={props.isMember}
                    keyIndex={keyIndex}
                    onEditTask={props.onEditTask}
                />
                {dropProvided.placeholder}
            </DropZone>
        </div>
    );
}

export default function QuoteList(props) {
    const {
        ignoreContainerClipping,
        isDragDisabled,
        isCombineEnabled,
        listId = 'LIST',
        listType,
        style,
        taskes,
        title,
        keyIndex
    } = props;

    return (
        <Droppable
            droppableId={listId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDragDisabled}
            isDragDisabled={isDragDisabled}
            isCombineEnabled={isCombineEnabled}
        >
            {(dropProvided, dropSnapshot) => (
                <Wrapper
                    style={style}
                    title={title}
                    isDragging={dropSnapshot.isDragging}
                    isDraggingOver={dropSnapshot.isDraggingOver}
                    isDropDisabled={isDragDisabled}
                    isDragDisabled={isDragDisabled}
                    isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                    {...dropProvided.droppableProps}
                >
                    <InnerList 
                        isAdmin={props.isAdmin}
                        isMember={props.isMember}
                        taskes={taskes} isDragDisabled={isDragDisabled} title={title} keyIndex={keyIndex}  dropProvided={dropProvided} onEditTask={props.onEditTask} />
                </Wrapper>
            )}
        </Droppable>
    );
}
