import React from 'react';
import {PlusIcon} from "@heroicons/react/outline";
import {ArrowLeftIcon} from "@heroicons/react/solid";

export const AccordionAdd = ({title, isOpen, onOpen, children}) => {


    return (
        <>
            {
                isOpen && (
                    <>
                        <p className="md:col-span-2 -mb-2 block text-sm font-medium text-gray-700">
                            <a href={'#0'}
                               onClick={() => {
                                   onOpen(false);
                               }}
                               className="text-sm underline inline-flex items-center"
                            >
                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                {title}
                            </a>
                        </p>
                        <div className="md:col-span-2 border-dotted border-4 p-3 grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
                            {children}
                        </div>
                    </>
                )
            }
        </>
    )
};


export const AccordionBtnAdd = ({label, onOpen}) => {


    return (
        <div className="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0 flex justify-between items-end">
            <div
                onClick={() => {
                    onOpen(true);
                }}
                className="w-full btn bg-green-500 hover:bg-green-700 text-white cursor-pointer"
            >
                <PlusIcon className={label ? "h-5 w-5 mr-2": "h-5 w-5"}/> {label ? label : ''}
            </div>
        </div>
    )

};

