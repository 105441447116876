import React, {useEffect, useState} from "react";
import SlidePanel from "../../../../components/layout/slide-panel";
import CSVReader from "react-csv-reader";
import CsvTable from "../../../../components/partials/csv/csv-table";
import {
    PRODUCT_DIMENSION_IMPORT,
    PRODUCT_ELABORATION,
    MANUFACTURE_ELABORATION,
    SUPPLIER_ELABORATION,
    PRODUCT_DETAIL_IMPORT,
    PRODUCT_SUPPLIER_IMPORT,
    PRODUCT_RATES_IMPORT,
    PRODUCT_TRANSLATION_IMPORT
} from "../../../../constants/import";
import ButtonSubmit from "../../../../components/common/form/button-submit";
import {getImportModels, postImport} from "../../../../api/import";
import {getSignedRequests} from "../../../../api/config";
import {sendMedia} from "../../../../utils/apiUtils";
import {useDispatch, useSelector} from "react-redux";
import lodash from "lodash";
import {Warning} from "../../../../components/common/alert/banner";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {getSelectParams} from "../../../../utils/converter";
import InputSelect from "../../../../components/common/form/input-select";
import ImportModelPopup from "../../../../components/common/popup/import-model-popup";
import MD5 from 'crypto-js/md5';
import {sp} from "../../../../constants/permissions";
import SubscriptionWrapper from "../../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../../reducers/subscriptionReducer";
import InputRadioSimple from "../../../../components/common/form/input-radio-simple";
import Papa from 'papaparse';
import ImportDetail from "./import-detail";

export default function ImportProduct({ isOpen, setIsOpen, onImportComplete, importGroup }) {

    const { loggedCompany } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // Dynamic Table
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [confirmedHeader, setConfirmedHeader] = useState([]);
    const [originalFile, setOriginalFile] = useState(false);


    const [isOpenDetail, setOpenDetail] = useState(false);


    // Form
    const {register, watch, setValue, control, formState: { errors }} = useForm();

    const [isOpenModel, setOpenModel] = useState(false);
    const [columnsCode, setColumnsCode] = useState(false);
    const [importModels, setImportModels] = useState([]);
    const [isLoadingModels, setLoadingModels] = useState([]);
    const [isFoundModel, setFoundModel] = useState(false);


    // Load Process
    const [isLoading ,setLoading] = useState(false);
    const [exception ,setException] = useState(false);
    const { t } = useTranslation();

    const options = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true
    };


    const imports = [
        {
            value: 1,
            label: t("app.products.import_products"),
            show_units: true,
            params: [
                {
                    type: PRODUCT_ELABORATION.type,
                    data: PRODUCT_ELABORATION.fields.concat(PRODUCT_DETAIL_IMPORT).concat(MANUFACTURE_ELABORATION.fields).concat(SUPPLIER_ELABORATION.fields)
                },
                {
                    type: MANUFACTURE_ELABORATION.type,
                    data: MANUFACTURE_ELABORATION.fields.concat(PRODUCT_DETAIL_IMPORT)
                },
                {
                    type: SUPPLIER_ELABORATION.type,
                    data: SUPPLIER_ELABORATION.fields.concat(PRODUCT_DETAIL_IMPORT)
                }
            ],
            type: 'product'
        },
        {
            value: 2,
            label: t("app.products.import_supplier"),
            params: [
                {
                    type: PRODUCT_ELABORATION.type,
                    data: PRODUCT_ELABORATION.fields.concat(PRODUCT_SUPPLIER_IMPORT)
                },
                {
                    type: MANUFACTURE_ELABORATION.type,
                    data: MANUFACTURE_ELABORATION.fields.concat(PRODUCT_SUPPLIER_IMPORT)
                }
            ],
            type: 'product'
        },
        {
            value: 3,
            label: t("app.products.import_product_rates"),
            params: [
                {
                    type: PRODUCT_ELABORATION.type,
                    data: PRODUCT_ELABORATION.fields.concat(PRODUCT_RATES_IMPORT)
                },
                {
                    type: MANUFACTURE_ELABORATION.type,
                    data: MANUFACTURE_ELABORATION.fields.concat(PRODUCT_RATES_IMPORT)
                },
                {
                    type: SUPPLIER_ELABORATION.type,
                    data: SUPPLIER_ELABORATION.fields.concat(PRODUCT_RATES_IMPORT)
                }
            ],
            type: 'product_price_history'
        },
        {
            value: 4,
            label: t("app.products.import_dimensions"),
            params: [
                {
                    type: PRODUCT_ELABORATION.type,
                    data: PRODUCT_ELABORATION.fields.concat(PRODUCT_DIMENSION_IMPORT)
                },
                {
                    type: MANUFACTURE_ELABORATION.type,
                    data: MANUFACTURE_ELABORATION.fields.concat(PRODUCT_DIMENSION_IMPORT)
                },
                {
                    type: SUPPLIER_ELABORATION.type,
                    data: SUPPLIER_ELABORATION.fields.concat(PRODUCT_DIMENSION_IMPORT)
                }
            ],
            type: 'product_dimension'
        },
        {
            value: 5,
            label: t("app.products.import_translation"),
            params: [
                {
                    type: PRODUCT_ELABORATION.type,
                    data: PRODUCT_ELABORATION.fields.concat(PRODUCT_TRANSLATION_IMPORT)
                },
                {
                    type: MANUFACTURE_ELABORATION.type,
                    data: MANUFACTURE_ELABORATION.fields.concat(PRODUCT_TRANSLATION_IMPORT)
                },
                {
                    type: SUPPLIER_ELABORATION.type,
                    data: SUPPLIER_ELABORATION.fields.concat(PRODUCT_TRANSLATION_IMPORT)
                }
            ],
            type: 'product_translation'
        }
    ];

    const downloadCSV = (data, title) => {
        const csv = Papa.unparse([data.map(item => {
            item = item.replace(/_/g, ' ');
            item = item.charAt(0).toUpperCase() + item.slice(1);
            return item;
        })], { delimiter: ';' });
        const blob = new Blob(["\ufeff", csv], { type: 'text/csv;charset=utf-8;' });

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = title+'.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }




    const uploadCsv = async (file)  => {
        setLoading(true);
        getSignedRequests({params: {ctype: file.type ,ext: file.name.split(".").pop()}})
            .then( signed => {
                sendMedia(signed.action, signed, file)
                    .then( r => {
                        let url = signed.action + '/' + signed.key;
                        let data = {};
                        data['path'] = url;
                        data['file_name'] = file.name;
                        data['params'] = confirmedHeader;
                        data['import_type'] = watch('upload_type').type;
                        data['config_params'] = {elaboration_type: watch("elaboration_type")};
                        storeImport(data);
                    }).catch(e => {
                    setException(e.message);
                    setLoading(false);
                });
            }).catch(e => {
            setException(e.message);
            setLoading(false);
        });
    };

    const storeImport = async (data)  => {
        setLoading(true);
        if(loggedCompany){
            postImport({company_id: loggedCompany.id, import_group: importGroup,  data})
                .then(response => {
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                    setLoading(false);
                    onImportComplete(response);
                    clean();
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };

    const handleForce = (data, fileInfo, originalFile) =>{
        if(data.length > 0){
            let column = [];
            for (const k in data[0]) {
                column.push(k);
            }
            setColumns(column);
            setColumnsCode(MD5(column.join('-')).toString());
            setRows(data.length > 10 ? data.slice(0, 10) : data);
            setOriginalFile(originalFile);
        }
    };

    const clean = async () => {
        setRows([]);
        setColumns([]);
        setConfirmedHeader([]);
        setImportModels([]);
        setColumnsCode(false);
        setOriginalFile(false);
        setValue('upload_type', undefined);
        setValue('elaboration_type', undefined);
        await setIsOpen(false)
    };


    useEffect(() => {
        if(importModels.length > 0 && columnsCode !== false){
            let data = importModels.find(r => r.code === columnsCode);
            if(data){
                setValue('import_model', data);
                setFoundModel(true);
                setConfirmedHeader(importModels?.find(r => r.code === columnsCode)?.params ?? []);
            }
        }

        // eslint-disable-next-line
    }, [setValue, importModels, columnsCode, watch('upload_type')]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchModels() {
            if (loggedCompany && isOpen) {
                setLoadingModels(true);
                try {
                    const res = await getImportModels({
                        company_id: loggedCompany.id,
                        params: { import_type: watch('upload_type').type},
                        signal,
                    });
                    setValue('import_model', null)
                    setFoundModel(false);
                    setImportModels(getSelectParams(res.import_models, "name"));
                } catch (e) {
                    setImportModels([]);
                } finally {
                    setLoadingModels(false);
                }
            }
        }
        fetchModels();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [watch('upload_type'), setValue, loggedCompany, isOpen]);


    return (
        <>

            <ImportDetail
                isOpen={isOpenDetail}
                setIsOpen={ () => {
                    setOpenDetail(false);
                }}
            />

            <SlidePanel
                title={t("app.products.import_title")}
                bg={"bg-white"}
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsOpen(false)
                }}
                size={'max-w-7xl'}
                footer={Footer}
                setClean={() => {
                    clean();
                }}
                extras={{
                    isLoading,
                    confirmedHeader,
                    onStartLoad: () => {
                        if(isFoundModel){
                            uploadCsv(originalFile);
                        }else{
                            setOpenModel(true);
                        }
                    }
                }}
            >
                <SubscriptionWrapper requiredPermission={sp.IMPORT_PRODUCT_MARKETPLACE_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
                    <div className="space-y-6 pt-6 pb-5">

                        <div className="grid grid-cols-2 gap-2 mb-6">


                            <div className="col-span-2">
                                <Controller
                                    name="upload_type"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({
                                                 field: { onChange, value, name },
                                                 fieldState: { error },
                                             }) => (
                                        <InputSelect
                                            label={t("app.products.import_sub_title")}
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            options={imports}
                                            value={value}
                                            isLoading={false}
                                            isDisabled={false}
                                            isRequired={true}
                                            errors={error}
                                        />
                                    )}
                                />
                            </div>


                            {
                                watch('upload_type') && (
                                    <>

                                        {
                                            (watch('upload_type')?.show_units ?? false) && (
                                                <p className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer col-span-2" onClick={() => {
                                                    setOpenDetail(true);
                                                }}>
                                                    {t("app.products.view_unit")}
                                                </p>
                                            )
                                        }


                                        <div className="col-span-2 mt-3">

                                            {
                                                watch('upload_type').params.map((data, index) => (
                                                    <InputRadioSimple
                                                        key={index}
                                                        name={"elaboration_type"}
                                                        errors={errors.elaboration_type}
                                                        input={{ ...register("elaboration_type", { required: true }) }}
                                                        label={t("app.products."+data.type+"_title")}
                                                        value={data.type}
                                                        description={t("app.products."+data.type+"_description")}
                                                    />
                                                ))
                                            }

                                        </div>

                                        {
                                            watch("elaboration_type") && (
                                                <p className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer col-span-2">
                                                    <div  onClick={() => {downloadCSV(watch('upload_type').params.find(r => r.type === watch("elaboration_type")).data, watch('upload_type').params.find(r => r.type === watch("elaboration_type")).type)}} rel="noreferrer">{t("app.orders.import_example_file")}</div>
                                                </p>
                                            )
                                        }


                                    </>

                                )
                            }









                            <div className="col-span-2 mt-3">
                                <label className="block text-sm font-medium mb-1 text-gray-900">{t("app.orders.import_file_title")}</label>
                                <CSVReader
                                    cssClass={watch('upload_type') !== 'undefined' ? "p-2 border-2 mt-1 border-gray-300 border-dashed rounded-md" : "p-2 border-2 mt-1 border-gray-300 border-dashed rounded-md opacity-50" }
                                    cssInputClass={'text-sm font-medium text-indigo-600'}
                                    cssLabelClass={''}
                                    label=""
                                    disabled={watch('upload_type') === undefined}
                                    onFileLoaded={handleForce}
                                    parserOptions={options}
                                />
                            </div>


                            {
                                (columns.length > 0 && watch('upload_type') !== undefined) && (
                                    <>
                                        {
                                            importModels.length > 0 && (
                                                <div className="col-span-2 mt-3">
                                                    <Controller
                                                        name="import_model"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.import_model.select_model")}
                                                                name={name}
                                                                options={importModels}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    if(e){
                                                                        setFoundModel(true);
                                                                        setConfirmedHeader(e.params);
                                                                    }
                                                                }}
                                                                isLoading={isLoadingModels}
                                                                isDisabled={isLoadingModels}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                        }

                                        <div className="col-span-2 mt-3">
                                            <label className="block text-sm font-medium text-gray-900">{t("app.orders.import_configuration_title")}</label>
                                            <CsvTable
                                                params={watch('upload_type').params.find(r => r.type === watch("elaboration_type")).data}
                                                columns={columns}
                                                rows={rows}
                                                onSetConfirmedHeader={(value, data) => {
                                                    let items = confirmedHeader;
                                                    lodash.remove(confirmedHeader, function (e) {
                                                        return e.file_param === data;
                                                    });
                                                    setConfirmedHeader([...items, {file_param: data, confirmed_param: value}])
                                                }}
                                                confirmedHeader={confirmedHeader}
                                            />
                                        </div>
                                    </>

                                )
                            }

                        </div>

                        {/* Warning */}
                        {exception && <Warning message={exception} />}


                    </div>


                    {isOpenModel && (
                        <ImportModelPopup
                            isOpen={isOpenModel}
                            setIsOpen={setOpenModel}
                            importType={watch('upload_type').type}
                            columnsCode={columnsCode}
                            confirmedHeader={confirmedHeader}
                            onConfirmed={() => {
                                uploadCsv(originalFile);
                            }}
                        />
                    )}
                </SubscriptionWrapper>
            </SlidePanel>

        </>
    );
}

const Footer = ({extras}) => {
    const { t } = useTranslation();

    return (
        <SubscriptionWrapper requiredPermission={sp.IMPORT_PRODUCT_MARKETPLACE_PACKAGE} renderBaseOnFail={false} checkUsage={true}>
            <div className="flex-shrink-0 before:flex-1 px-4 py-4 flex justify-between">
                <ButtonSubmit
                    isLoading={extras.isLoading}
                    label={t("app.common.confirm_import")}
                    isFullWith={false}
                    onClick={() => {
                        extras.onStartLoad()
                    }}
                    disabled={extras.confirmedHeader.length === 0}
                />
            </div>
        </SubscriptionWrapper>
    );
};
