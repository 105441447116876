import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid/esm";
import {Link} from "react-router-dom";
import {safeLogout, updateLoggedCompany, updateOwnerLoggedCompany} from "../../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import lodash  from "lodash";
import Initials from "../../common/avatar/initials";
import {refreshPermissions} from "../../../reducers/userCompanyReducer";
import {LogoutIcon} from "@heroicons/react/outline";
import {clearConnections, customersList} from "../../../reducers/connectionReducer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function CompanySwitch() {
  const { detail, loggedCompany, isLogoutLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();


  return (
    <>
      <div className="flex-shrink-0 flex border-t border-white/[0.4] p-4">
        <Menu as="div" className=" flex-shrink-0 w-full group block">
          <div className="flex items-center justify-between">
            <div className={"flex items-center"}>

              {
                (detail && detail.picture) ? (
                    <img className="h-8 w-8 rounded-full ring-1 ring-white object-cover mr-2" src={detail.picture.reference} alt="" />
                ) : (
                    <Initials
                        color={detail?.color ?? 'blue'}
                        name={detail?.firstname ?? "" + detail?.lastname ?? ""}
                    />
                )
              }

              <div>
                <p className="text-sm font-medium text-white ">
                  {detail?.firstname ?? ""} {detail?.lastname ?? ""}
                </p>
                <p className="text-xs font-medium text-white/[0.4] ">
                  {loggedCompany?.business_name ?? ''}
                </p>
              </div>
            </div>
            <Menu.Button>
              <SelectorIcon
                className={`w-5 h-5 shrink-0 ml-1 fill-current text-gray-400 transform rotate-180`}
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute bottom-0 left-0 ml-2 mb-16 w-60  overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">


              {
                // eslint-disable-next-line
                (detail?.companies?.length > 1 || (detail?.companies?.length === 1 && !lodash.some([{id: loggedCompany.id}], {id: detail.companies[0].id}))) && (
                    <b className={'text-gray-900 group flex items-center px-4 py-2 text-xs'} >My Companies</b>
                )
              }

              {detail?.companies?.length > 0 &&
              detail.companies.map((company) => (
                  (lodash.some([{id: loggedCompany?.id}], {id: company?.id})) === false  && (
                      <Menu.Item key={company.id}>
                        {({ active }) => (
                            <Link
                                to={'#'}
                                onClick={() => {
                                  dispatch(updateOwnerLoggedCompany(company));
                                  dispatch(refreshPermissions({ company_id: company.id }));
                                  dispatch(customersList({ company_id: company.id }))
                                }}
                                className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'group flex items-center px-4 py-2 text-sm'
                                )}
                            >
                              {company.business_name}
                            </Link>
                        )}
                      </Menu.Item>
                  )

              ))}


              {
                // eslint-disable-next-line
                (detail?.connections?.length > 1) || (detail?.connections?.length === 1 &&  !lodash.some([{id: loggedCompany?.id}], {id: detail?.connections[0]?.company?.id})) && (
                    <b className={' text-gray-900 group flex items-center px-4 py-2 text-xs'} >
                      Connected Company
                    </b>
                )
              }


              {detail?.connections?.length > 0 &&
                detail.connections.map((connection) => (
                    (lodash.some([{id: loggedCompany?.id}], {id: connection.company.id})) === false  && (
                        <Menu.Item key={connection.id}>
                          {({ active }) => (
                              <Link
                                  to={'#'}
                                  onClick={() => {
                                    dispatch(updateLoggedCompany(connection));
                                    dispatch(refreshPermissions({ company_id: connection.company.id }))
                                    dispatch(customersList({ company_id: connection.company.id }))
                                  }}
                                  className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'group flex items-center px-4 py-2 text-sm'
                                  )}
                              >
                                {connection.company.business_name}
                              </Link>
                          )}
                        </Menu.Item>
                    )

                ))}

                <Menu.Item>
                  {({ active }) => (
                      <Link
                          to={"#"}
                          onClick={async () => {
                            await dispatch(clearConnections());
                            await dispatch(safeLogout());
                          }}
                          className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                          )}
                      >
                        {
                          isLogoutLoading ? (
                              <>
                                <svg className="animate-spin mr-3 h-5 w-5 text-gray-400 fill-current shrink-0" viewBox="0 0 16 16">
                                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                </svg>
                                Logout
                              </>
                          )  : (
                              <>
                                <LogoutIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                Logout
                              </>
                          )
                        }

                      </Link>
                  )}
                </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export default CompanySwitch;
