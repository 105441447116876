import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {makeid} from "../../../utils/converter";
import DiscountEditor from "../card/discount-editor";
import PriceRange from "../card/price-range";

export default function InputDiscountNumber ({
     isFullW = false,
     isDisabled = false,
     value,
     prefix,
     suffix,
     label,
     isShowLabel = true,
     customClass = '',
     isRequired,
     id,
     errors,
     warning,
     warningRegex,
     onChange,
     onChangeValues,
     discountList = [],
     isShowDiscount = true,
     isShowFinalPrice = false,
     isShowPriceRange = false,
     isShowTotalPrice = false,
     symbol, purchasePrice, basePrice, boxRangeExtraClass = 'right-12'

}){

    const { t } = useTranslation();


    const fixDiscount = (discount_data) => {
        let discount_list = [];
        // eslint-disable-next-line
        (discount_data ?? []).map((discount, index) => {
            discount_list.push({id: makeid(20), price: discount})
        })
        return discount_list;
    }



    const [listPrice, setListPrice] = useState(value);
    const [discounts, setDiscounts] = useState(fixDiscount(discountList));

    const [discountLabel, setDiscountLabel] = useState(t("app.quotations.no_discount"));

    const getFinalPrice = () => {
        let final_price = listPrice ?? 0;

        // eslint-disable-next-line
        (discounts ?? []).map((discount) => {
            final_price = final_price - (final_price * (discount.price / 100));
        });

        return final_price;
    }

    useEffect(() => {
        if(discounts.length > 0){

            let discount_string = '';
            // eslint-disable-next-line
            (discounts ?? []).map((discount) => {
                if(discount.price){
                    discount_string = discount_string + discount.price + '% + ';
                }
            })
            // eslint-disable-next-line
            setDiscountLabel(discount_string.slice(0,-3));
        }else{
            setDiscountLabel(t("app.quotations.no_discount"));
        }


        // eslint-disable-next-line
    }, [discounts]);


    const updateData = () => {
        if(discounts.length > 0){
            let final_price = listPrice ?? 0;
            let discount_string = '';
            let discounts_list = [];

            // eslint-disable-next-line
            (discounts ?? []).map((discount) => {
                if(discount && discount.price){
                    discounts_list.push(discount.price);
                    final_price = final_price - (final_price * (discount.price / 100));
                    discount_string = discount_string + discount.price + '|';
                }
            })
            onChangeValues({list_price: listPrice, discount_string: discount_string.slice(0, -1), discount: discounts_list, final_price: getFinalPrice()});
        }else{
            onChangeValues({list_price: listPrice, discount_string: "", discount: [], final_price: listPrice});
        }

        if(listPrice === 0){
            setListPrice(null);
        }
    }


    useEffect(() => {
        updateData();
        // eslint-disable-next-line
    }, [discounts, listPrice]);


    return (

        <div className={isFullW  ? 'w-full' : ''}>
            {
                isShowLabel && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }
            <div className="flex flex-col">
                <div className="mx-auto my-auto w-full">
                    <div className="flex flex-row h-10 form-input p-0">


                        {
                            (!isDisabled && isShowDiscount) && (
                                <DiscountEditor
                                    hover={''}
                                    btnClass={'w-full  justify-start items-center'}
                                    boxClass={'group p-2 flex bg-green-600 hover:bg-green-700 rounded-tl-sm rounded-bl-sm'}
                                    discounts={discounts}
                                    element={{id: 'new'}}
                                    onUpdateDiscount={(id, value, index) => {
                                        let filtered = discounts;
                                        filtered[index]['price'] = value !== false ? value : null;
                                        filtered = filtered.filter(r => r.price !== null);
                                        setDiscounts(filtered);
                                    }}
                                    onAddDiscount={(id) => {
                                        let filtered = discounts;
                                        filtered = [...discounts, {id: makeid(20), price: null}];
                                        setDiscounts(filtered)

                                    }}
                                />
                            )
                        }

                        <NumberFormat
                            value={value}
                            className={`${customClass !== '' ? customClass : 'form-input'} flex input-discount  items-center border-0 w-full shadow-none disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
                            inputMode="decimal"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            onValueChange={(values) => {
                               setListPrice(values.floatValue);
                               onChange(values.floatValue);
                            }}
                            placeholder={t("app.common.placeholder", {field: label.toLowerCase()})}
                            disabled={isDisabled}
                            prefix={prefix}
                            suffix={suffix}
                        />

                        {
                            (discounts.length > 0 && listPrice  && getFinalPrice() > 0 && isShowFinalPrice) && (

                                <div className="w-full text-right mr-2 items-center mt-1">
                                    <div className="text-xs text-gray-500 ">
                                        {t("app.quotations.net_price")}
                                    </div>
                                    <div className="text-xs font-semibold text-gray-900">
                                        <NumberFormat
                                            value={getFinalPrice()}
                                            displayType={"text"}
                                            decimalScale={4}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={prefix}
                                            suffix={suffix}
                                        />
                                    </div>
                                </div>


                            )
                        }



                        {
                            (listPrice  && isShowTotalPrice) && (

                                <div className="w-full flex  justify-end text-right mr-2 items-center ">
                                    <div className="text-xs text-gray-500 mr-1">
                                        {t("app.quotations.total_price")}:
                                    </div>
                                    <div className="text-md font-extrabold text-gray-900">
                                        <NumberFormat
                                            value={basePrice * (listPrice ?? 0)}
                                            displayType={"text"}
                                            decimalScale={2}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            suffix={" "+symbol}
                                            prefix={prefix}
                                        />
                                    </div>
                                </div>


                            )
                        }


                        {
                            (isShowPriceRange && purchasePrice && listPrice) ? (
                                <div className={' flex justify-between items-center border border-gray-900 border-dashed pl-3 rounded-tr-sm rounded-br-sm'}>
                                    <PriceRange
                                        symbol={symbol}
                                        boxExtraClass={boxRangeExtraClass}
                                        boxHeight={'max-h-40'}
                                        purchasePrice={purchasePrice}
                                        price={getFinalPrice()}
                                        showMargin={true}
                                    />
                                </div>
                            ) : (<div>{''}</div>)
                        }

                    </div>
                    {
                        isShowDiscount && (
                            <p className="mt-1 text-xs text-gray-500">{t("app.quotations.discount")} {discountLabel}</p>
                        )
                    }
                    {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
                    {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
                    {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}
                    {errors && errors.type === 'custom'   && (  <p className="mt-2 text-sm text-red-600">{errors.message}</p>)}

                </div>
            </div>

        </div>

    )
};
