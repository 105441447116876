import React, {useEffect, useState, useRef} from "react";
import SlideFull from "../../../../../../../components/layout/slide-full";
import {ActionButton, FilterButton, InfoButton} from "../../../../../../../components/layout/page-header";
import InputSearch from "../../../../../../../components/common/form/input-search";
import BoxIcon from "../../../../../../../resources/icons/BoxIcon";
import Loader from "../../../../../../../components/common/loader/loader";
import ContainerLoadIcon from "../../../../../../../resources/icons/ContainerLoadIcon";
import {InformationCircleIcon} from "@heroicons/react/outline";
import {getContainerGroups, putContainerGroups} from "../../../../../../../api/container";
import {useSelector} from "react-redux";
import {useOutletContext, useParams} from "react-router-dom";
import LoadDetail from "./load-detail";
import AddGroup from "./group/add-group";
import EditGroup from "./group/edit-group";
import Packaging from "./packaging";
import ConfirmPopup from "../../../../../../../components/common/popup/confirm-popup";
import AddBoxToContainer from "./box/add-box-to-container";
import EditBox from "./box/edit-box";
import {getFormattedDate} from "../../../../../../../utils/timeUtils";
import {useTranslation} from "react-i18next";
import ContainerPackagingCard from "../../../../../../../components/common/card/conrainer-packaging-card";
import ContainerGroupCard from "../../../../../../../components/common/card/conrainer-group-card";
import RemoveSimplePopup from "../../../../../../../components/common/popup/remove-simple-popup";
import ContainerSubGroupCard from "../../../../../../../components/common/card/conrainer-sub-group-card";
import DragDropList from "../../../../../../../components/common/drag-drop/drag-drop-list";
import toast from "react-hot-toast";
import {Controller, useForm} from "react-hook-form";
import InputToggle from "../../../../../../../components/common/form/input-toggle";
import {getPathParam} from "../../../../../../../utils/converter";
import {CONTAINER, SHIPPING} from "../../../../../../endpoints";
import {hasPermissionFor} from "../../../../../../../components/partials/restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific} from "../../../../../../../constants/permissions";
import AddSubGroup from "./group/add-subgroup";
import EditSubGroup from "./group/edit-subgroup";


export default function PackingList({ isOpen, setIsOpen }) {

    const { loggedCompany } = useSelector((state) => state.user);
    const { containerDetail, setContainerDetail, isSeaType } = useOutletContext();
    let {id, container_id}  = useParams();
    const { t } = useTranslation();
    const lastGroup = useRef(null);
    const {control, setValue } = useForm({ mode: "onBlur" });


    const { permissions } = useSelector((s) => s.userCompany);

    const draftLoading =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_DRAFT_LOADING);
    const requireGenerateDocument =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_GENERATE_DOCUMENT);



    const totalVolume = isSeaType ? containerDetail.container_type.volume : (containerDetail?.truck_volume ?? 0)

    // Group Modal
    const [isAddGroupOpen, setAddGroupOpen] = useState(false);
    const [isAddSubGroupOpen, setAddSubGroupOpen] = useState(false);
    const [isEditGroupOpen, setEditGroupOpen] = useState(false);
    const [isSubEditGroupOpen, setSubEditGroupOpen] = useState(false);

    // Remove Modal
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // Confirm Modal
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    // Load inside container Modal and edit box
    const [isOpenAddBox, setOpenAddBox] = useState(false);
    const [isOpenEditBox, setOpenEditBox] = useState(false);
    const [boxDetail, setBoxDetail] = useState({});
    const [groupId, setGroupId] = useState(null);

    // Detail Panel
    const [isDetailOpen, setDetailOpen] = useState(false);

    // Add Packaging
    const [isAddOpen, setAddOpen] = useState(false);
    const [groupDetail, setGroupDetail] = useState(null);
    const [subGroupDetail, setSubGroupDetail] = useState(false);

    // Detail data set
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState({
        groups: [],
        total_rows: 0,
    });
    const [refreshLoad, setRefreshLoad] = useState(false);
    const [query, setQuery] = useState("");

    const [containerLoadDetails, setContainerLoadDetails] = useState({
        total_volume: 0,
        total_gross_weight: 0,
        total_net_weight: 0,
        percentage: 0,
        });

    useEffect(() => {
        if(detail.groups.length > 0){
            setContainerLoadDetails(groupContainerLoad(detail, containerDetail));
        }
        setValue("draft_loaded", containerDetail.draft_loaded);
        // eslint-disable-next-line
    }, [detail, refreshLoad, containerDetail, setValue]);

    const scrollToBottom = () => {
        lastGroup.current.scrollIntoView({ behavior: "smooth" })
    }

    const clean = () =>{

    };

    const updateGroups = (data) =>{
        let detail = [];
        if(data.length > 0){
            // eslint-disable-next-line
            data.map(item => {
                detail.push({id: item.id })
            })
        }

        putContainerGroups({ shipping_id: id, container_id: container_id, data: detail })
            .then((response) => {
                toast.success(t("app.container.group_update"))
            }).catch((e) => {});
    };


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchContainerProducts() {
            if (loggedCompany && isOpen) {
                setLoading(true);
                getContainerGroups({ shipping_id: id, container_id: container_id, params: { query: query }, signal })
                    .then((response) => {
                        setDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setDetail({ groups: [], total_rows: 0 });
                        setLoading(false);
                    });
            }
        }

        fetchContainerProducts();
        return () => {
            controller.abort();
        };
    }, [query, id, loggedCompany, container_id, isOpen]);


    const groupContainerLoad = (detail, containerDetail) => {
        let result = {
            total_volume: 0,
            total_net_weight: 0,
            total_gross_weight: 0,
        };

        detail.groups.reduce(function(res, value) {
            if(value.is_specific){
                result.total_volume = result.total_volume + value.volume;
                result.total_net_weight = result.total_net_weight + value.net_weight;
                result.total_gross_weight = result.total_gross_weight  + value.gross_weight;
            }

            if(value.pallet){
                result.total_gross_weight = result.total_gross_weight  + (value?.pallet_type?.max_weight ?? 0);
            }

            value.container_sub_groups.reduce(function(sub_res, value_sub) {

                if(value_sub.pallet){
                    result.total_gross_weight = result.total_gross_weight  + (value_sub?.pallet_type?.max_weight ?? 0);
                }
                value_sub.container_packages.reduce(function(res_container_load, value_container_load) {
                    value_container_load.product_packages.reduce(function(res_container_product, value_container_product) {

                        if(!value.is_specific){
                            let quantity = ((value_container_product.quantity / value_container_load.box) * value_container_load.loaded_box);
                            if(quantity > 0 ){
                                result.total_volume = result.total_volume + (value_container_product.container_product.base_volume * quantity);
                                result.total_net_weight = result.total_net_weight + (value_container_product.container_product.base_net_weight * quantity);
                                result.total_gross_weight = result.total_gross_weight + (value_container_product.container_product.base_gross_weight * quantity);
                            }
                        }



                        return res_container_product;
                    }, {});
                    return res_container_load;
                }, {});
                return sub_res;
            }, {});


            return res;
        }, {});

        result['percentage'] = result.total_volume > 0 ? (result.total_volume / totalVolume) * 100 : 0;
        return result;
    };

    const Title = () => {
        return(
            <>
                <div className="sm:flex sm:justify-between items-center">
                    {t("app.container.packing_list")}
                    {
                        containerDetail.document_number  && (
                            <div className="text-gray-400 sm:ml-2">#{containerDetail.document_number}</div>
                        )
                    }
                </div>

                {
                    containerDetail.document_date && (
                        <div className="text-xs text-gray-500">
                            {getFormattedDate(containerDetail.document_date)}
                        </div>
                    )
                }


            </>

        );
    };

    return (
        <>



            <SlideFull
                title={Title}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size={'max-w-7xl'}
                header={Header}
                extras={{
                    isSeaType,
                    containerDetail,
                    setDetailOpen: (r) => {
                        setDetailOpen(r);
                    },
                    requireGenerateDocument
                }}
                setClean={() => {
                    clean();
                }}
            >
                <div className="space-y-6 pt-6 pb-5">

                    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8">
                        <div className="flex justify-between items-center flex-wrap gap-4 mb-6">
                            <div>
                                <InputSearch
                                    onSearch={(r) => {
                                        setQuery(r);
                                    }}
                                    hasMargin={false}
                                    autocomplete={false}
                                />
                            </div>


                            <div className="flex items-center justify-between">

                                {
                                    draftLoading && (
                                        <div className="mr-2 items-center">
                                            <Controller
                                                name={"draft_loaded"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                             field: { onChange, value, name },
                                                             fieldState: { error },
                                                         }) => (
                                                    <InputToggle
                                                        label={t("app.container.draft_loaded")}
                                                        isDisabled={containerDetail.draft_loaded === true}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                            if(e){
                                                                const confirmDetail = {
                                                                    title: t("app.container.draft_loaded"),
                                                                    type: 'draft_loaded',
                                                                    message: t("app.container.draft_loaded_description"),
                                                                    endpoint: getPathParam([SHIPPING, id, CONTAINER, container_id]),
                                                                    endpointData: {id: container_id, draft_loaded: e}
                                                                };
                                                                setConfirmDetail(confirmDetail);
                                                                setOpenConfirm(true);
                                                            }

                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }


                                <FilterButton
                                    onClick={() => {
                                        setAddGroupOpen(true);
                                    }}
                                    icon={
                                        <BoxIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                        />
                                    }
                                    text={t("app.container.add_group")}
                                />


                            </div>


                        </div>
                    </div>

                    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8">
                        <div className="lg:col-span-2 xl:col-span-1">

                            {
                                isLoading ? (
                                    <div className="bg-white shadow overflow-hidden py-4 px-6">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            detail.groups.length === 0 && (
                                                <div className="bg-white shadow overflow-hidden mb-5">
                                                    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                                                        <div className="text-center">
                                                            <ContainerLoadIcon
                                                                className="h-24 w-24 text-indigo-600 m-auto"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                                                {t("app.container.group_title")}
                                                            </p>
                                                            <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                                                                {t("app.container.group_description")}
                                                            </p>
                                                            <ActionButton
                                                                onClick={() => {
                                                                    setAddGroupOpen(true);
                                                                }}
                                                                icon={
                                                                    <BoxIcon
                                                                        className="w-4 h-4 fill-current flex-shrink-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                }
                                                                text={t("app.container.add_group")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }





                                        <DragDropList
                                            header={HeaderGroup}
                                            groups={detail.groups}
                                            onUpdateGroup={(r) => {
                                                setDetail({total_rows: detail.total_rows , groups: r});
                                                updateGroups(r);
                                            }}
                                            extras={{
                                                onAddSubCategory: (r) => {
                                                    setGroupDetail(r);
                                                    setAddSubGroupOpen(true);
                                                },
                                                onEditGroup: (r, n = false) => {
                                                    setGroupDetail(r);
                                                    n && setSubGroupDetail(n);
                                                    n ? setSubEditGroupOpen(true) : setEditGroupOpen(true);
                                                },
                                                onLoadAll: (r) => {
                                                    setConfirmDetail(r);
                                                    setOpenConfirm(true);
                                                },
                                                onAdd: (r, n = false) => {
                                                    setGroupDetail(r);
                                                    n && setSubGroupDetail(n);
                                                    setAddOpen(true);
                                                },
                                                onRemove: (r, n = false) => {
                                                    setRemoveDetail(r);
                                                    n && setGroupDetail(n);
                                                    setOpenRemove(true);
                                                },
                                                onEditDetailBox: (r, n = false) => {
                                                    setBoxDetail(r);
                                                    setGroupId(n);
                                                    setOpenEditBox(true);
                                                },
                                                onEditBox: (r, n = false) => {
                                                    setBoxDetail(r);
                                                    setGroupId(n);
                                                    setOpenAddBox(true);
                                                }
                                            }}
                                        />

                                    </>
                                )
                            }
                            <div ref={lastGroup} />

                        </div>
                    </div>


                    <LoadDetail
                        isOpen={isDetailOpen}
                        setIsOpen={(r) => {
                            setDetailOpen(r);
                        }}
                        containerDetail={containerDetail}
                        containerLoadDetails={containerLoadDetails}
                    />

                    <Packaging
                        isOpen={isAddOpen}
                        groupDetail={groupDetail}
                        subGroupDetail={subGroupDetail}
                        setDetail={(r) => {
                            let foundIndex = detail.groups.findIndex(x => x.id === groupDetail.id);
                            if(subGroupDetail){
                                let subFoundIndex = detail['groups'][foundIndex]['container_sub_groups'].findIndex(x => x.id === subGroupDetail.id);
                                detail['groups'][foundIndex]['container_sub_groups'][subFoundIndex]['container_packages'] = r.products;
                            }else{
                                detail['groups'][foundIndex]['container_sub_groups'] = r.container_sub_groups;
                            }
                            setGroupDetail(null);
                            setSubGroupDetail(null);
                            setRefreshLoad(!refreshLoad);
                        }}
                        setIsOpen={(r) => {
                            setAddOpen(r);
                        }}
                    />



                    {
                        isAddGroupOpen && (
                            <AddGroup
                                isOpen={isAddGroupOpen}
                                setIsOpen={(r) => {
                                    setAddGroupOpen(r);
                                }}
                                setDetail={(r) => {
                                    setDetail(r);
                                    scrollToBottom();
                                }}
                            />
                        )
                    }

                    {
                        isAddSubGroupOpen && (
                            <AddSubGroup
                                isOpen={isAddSubGroupOpen}
                                groupDetail={groupDetail}
                                setIsOpen={(r) => {
                                    setAddSubGroupOpen(r);
                                }}
                                setDetail={(r) => {
                                    let foundIndex = detail.groups.findIndex(x => x.id === groupDetail.id);
                                    detail['groups'][foundIndex]['container_sub_groups'] = r.groups;
                                    setGroupDetail(null);
                                    setRefreshLoad(!refreshLoad);
                                }}
                            />
                        )
                    }

                    {
                        isEditGroupOpen && (
                            <EditGroup
                                isOpen={isEditGroupOpen}
                                groupDetail={groupDetail}
                                setIsOpen={(r) => {
                                    setGroupDetail(null);
                                    setEditGroupOpen(r);
                                }}
                                setDetail={(r) => {
                                    setGroupDetail(null);
                                    setDetail(r);
                                }}
                            />
                        )
                    }

                    {
                        isSubEditGroupOpen && (
                            <EditSubGroup
                                isOpen={isSubEditGroupOpen}
                                groupDetail={groupDetail}
                                subGroupDetail={subGroupDetail}
                                setIsOpen={(r) => {
                                    setGroupDetail(null);
                                    setSubGroupDetail(null);
                                    setSubEditGroupOpen(r);
                                }}
                                setDetail={(r) => {
                                    let foundIndex = detail.groups.findIndex(x => x.id === groupDetail.id);
                                    detail['groups'][foundIndex]['container_sub_groups'] = r.groups;
                                    setGroupDetail(null);
                                    setSubEditGroupOpen(null);
                                    setRefreshLoad(!refreshLoad);
                                }}
                            />
                        )
                    }

                    {isOpenRemove && (
                        <RemoveSimplePopup
                            isOpen={isOpenRemove}
                            setIsOpen={setOpenRemove}
                            detail={removeDetail}
                            setDetail={(r) => {
                                if(removeDetail?.type === 'box'){
                                    let foundIndex = detail.groups.findIndex(x => x.id === removeDetail.group_id);
                                    let foundSubIndex = detail['groups'][foundIndex]['container_sub_groups'].findIndex(x => x.id === removeDetail.sub_group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'][foundSubIndex]['container_packages'] = r.products;
                                    setRefreshLoad(!refreshLoad);
                                }else if(removeDetail?.type === 'sub'){
                                    let foundIndex = detail.groups.findIndex(x => x.id === removeDetail.group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'] = r.groups;
                                    setGroupDetail(null);
                                    setRefreshLoad(!refreshLoad);
                                }else if(removeDetail?.type === 'external'){
                                    let foundIndex = detail.groups.findIndex(x => x.id === removeDetail.group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'] = r.groups;
                                    setGroupDetail(null);
                                    setRefreshLoad(!refreshLoad);
                                }else{
                                    setDetail(r);
                                    setRefreshLoad(!refreshLoad);
                                }
                            }}
                        />
                    )}

                    {isOpenConfirm && (
                        <ConfirmPopup
                            isOpen={isOpenConfirm}
                            setIsOpen={ () => {
                                if(confirmDetail?.type === 'draft_loaded'){
                                    setValue("draft_loaded",false);
                                }
                                setOpenConfirm(false);
                            }}
                            detail={confirmDetail}
                            setDetail={(r) => {
                                if(confirmDetail?.type === 'container'){
                                    setContainerDetail(r);
                                }else if(confirmDetail?.type === 'sub_group'){
                                    let foundIndex = detail.groups.findIndex(x => x.id === confirmDetail.group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'] = r.groups;
                                    setRefreshLoad(!refreshLoad);
                                }else if(confirmDetail?.type === 'clone_box'){
                                    let foundIndex = detail.groups.findIndex(x => x.id === confirmDetail.group_id);
                                    let foundSubIndex = detail['groups'][foundIndex]['container_sub_groups'].findIndex(x => x.id === confirmDetail.sub_group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'][foundSubIndex]['container_packages'] = r.products;
                                    setRefreshLoad(!refreshLoad);
                                }else if(confirmDetail?.type === 'draft_loaded'){
                                    setContainerDetail(r);
                                }else{
                                    setDetail(r);
                                }
                            }}
                        />
                    )}

                    {
                        isOpenAddBox && (
                            <AddBoxToContainer
                                isOpen={isOpenAddBox}
                                setIsOpen={(r) => {
                                    setOpenAddBox(r);
                                }}
                                boxDetail={boxDetail}
                                groupId={groupId}
                                setDetail={(r) => {
                                    let foundIndex = detail.groups.findIndex(x => x.id === groupId.group_id);
                                    let foundSubIndex = detail['groups'][foundIndex]['container_sub_groups'].findIndex(x => x.id === groupId.sub_group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'][foundSubIndex]['container_packages'] = r.products;
                                    setRefreshLoad(!refreshLoad);
                                }}
                            />
                        )
                    }

                    {
                        isOpenEditBox && (
                            <EditBox
                                isOpen={isOpenEditBox}
                                setIsOpen={(r) => {
                                    setOpenEditBox(r);
                                }}
                                boxDetail={boxDetail}
                                groupId={groupId}
                                setDetail={(r) => {
                                    let foundIndex = detail.groups.findIndex(x => x.id === groupId.group_id);
                                    let foundSubIndex = detail['groups'][foundIndex]['container_sub_groups'].findIndex(x => x.id === groupId.sub_group_id);
                                    detail['groups'][foundIndex]['container_sub_groups'][foundSubIndex]['container_packages'] = r.products;
                                    setRefreshLoad(!refreshLoad);
                                }}
                            />
                        )
                    }

                </div>
            </SlideFull>
        </>


    );
}


function SubGroup({grouped, children}) {

    return (
        grouped  ? (
            <ul className="border-t p-2 border-gray-200 grid  grid-cols-1">
                {children}
            </ul>
        ) : (
            <>{children}</>
        )
    );
}

const Header = ({extras}) => {

    const { t } = useTranslation();

    return (

        <>


            <div className="text-center">
                <InfoButton
                    onClick={() => {
                        extras.setDetailOpen(true);
                    }}
                    icon={
                        <InformationCircleIcon
                            className="w-4 h-4"
                            aria-hidden="true"
                        />
                    }
                    text={extras.isSeaType ? t("app.container.container_status") : t("app.container.truck_status")}
                />
            </div>

        </>

    )
};

const HeaderGroup = ({item, extras, dragHandle}) => {
    const { t } = useTranslation();

    return (
        <ContainerGroupCard
            dragHandle={dragHandle}
            hasChildren={(item.container_sub_groups.length > 0 && item.container_sub_groups.filter(r => r.container_packages.length > 0).length > 0)}
            groupDetail={item}
            onAddSubCategory={() => {
                extras.onAddSubCategory(item);
            }}
            onEditGroup={() => {
                extras.onEditGroup(item);
            }}
            onLoadAll={(confirmDetail) => {
                extras.onLoadAll(confirmDetail);
            }}
            onAdd={() => {
                extras.onAdd(item);
            }}
            onRemove={(removeDetail) => {
                extras.onRemove(removeDetail);
            }}

        >

            {
                item.container_sub_groups.length === 0 ? (
                    <div className="bg-white border-t border-gray-200 px-4">
                        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <BoxIcon
                                    className="h-24 w-24 text-blue-1000 m-auto"
                                />
                                <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                    {t("app.container.packaging_title")}
                                </p>
                                <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                                    {t("app.container.packaging_description")}
                                </p>

                            </div>
                        </div>
                    </div>
                ) : (
                    <>

                        <ul className="border-t border-gray-200 p-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">

                            {
                                item.container_sub_groups.map((sub, index) => (
                                    <ContainerSubGroupCard
                                        key={index}
                                        isGrouped={sub.grouped}
                                        groupDetail={sub}
                                        baseGroupDetail={item}
                                        hasChildren={sub.container_packages.length > 0}
                                        isSub={true}
                                        onEditGroup={() => {
                                            extras.onEditGroup(item, sub);
                                        }}
                                        onLoadAll={(confirmDetail) => {
                                            extras.onLoadAll(confirmDetail);
                                        }}
                                        onAdd={() => {
                                            extras.onAdd(item, sub);
                                        }}
                                        onRemove={(removeDetail) => {
                                            extras.onRemove(removeDetail, item);
                                        }}
                                    >
                                        <SubGroup grouped={sub.grouped}>

                                            {
                                                sub.container_packages.length === 0 ? (
                                                    <div className=" bg-white mx-auto py-8 px-6">
                                                        <div className="text-center">
                                                            <BoxIcon
                                                                className="h-24 w-24 text-indigo-600 m-auto"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                                                {t("app.container.packaging_title")}
                                                            </p>
                                                            <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                                                                {t("app.container.packaging_description")}
                                                            </p>

                                                        </div>
                                                    </div>
                                                ) : (
                                                    sub.container_packages.map((box, index) => (
                                                        <ContainerPackagingCard
                                                            key={box.id}
                                                            box={box}
                                                            isGrouped={sub.grouped}
                                                            groupDetail={sub}
                                                            baseGroupDetail={item}
                                                            onEditDetailBox={() => {
                                                                extras.onEditDetailBox(box, {group_id: item.id, sub_group_id: sub.id} );
                                                            }}
                                                            onEditBox={() => {
                                                                extras.onEditBox(box, {group_id: item.id, sub_group_id: sub.id} );
                                                            }}
                                                            onRemove={(removeDetail) => {
                                                                extras.onRemove(removeDetail);
                                                            }}
                                                            onClone={(confirmDetail)=> {
                                                                extras.onLoadAll(confirmDetail);
                                                            }}

                                                        />
                                                    ))
                                                )
                                            }
                                        </SubGroup>
                                    </ContainerSubGroupCard>
                                ))

                            }

                        </ul>



                    </>
                )
            }

        </ContainerGroupCard>
    )
};
