import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import ListSection from "../../../components/layout/list-section";
import {useSelector} from "react-redux";
import {FilterButton, ImportButton} from "../../../components/layout/page-header";
import {ArrowRightIcon, PlusIcon} from "@heroicons/react/outline";
import ProductCell from "../../../components/common/table/product-cell";
import TableDetail from "../../../components/common/list/table-detail";
import {useTranslation} from "react-i18next";
import {getOrderCatalogProducts} from "../../../api/order";

const SearchProduct = ({selectedProductList, onSelectedProductList, onChangeStep, isBack, cachedDetail, setCachedDetail }) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const { t } = useTranslation();

    const columns = [
        t("app.container.product")
    ];

    let {id}  = useParams();
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState(cachedDetail);
    const [detailRows, setDetailRows] = useState(0);


    const [query, setQuery] = useState("");
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const handleSelectAll = () => {
        if(detail.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(detail);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let product = detail.find(r => r.id === id);
        setIsCheck([...isCheck, product]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };

    const fetchMoreData = () => {
        setOffset(limit + offset);
    };


    useEffect(() => {
        if(isBack && detail.filter(item => !selectedProductList.some(e => e.id === item.id)).length === 0){
            setLimit(selectedProductList.length*2);
        }
        // eslint-disable-next-line
    }, [isBack]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchContainerProducts() {
            if (loggedCompany) {
                setLoading(true);
                try {
                    const r = await getOrderCatalogProducts({
                        company_id: loggedCompany.id,
                        order_id: id,
                        params: {
                            limit: limit,
                            offset: query.length > 0  ? 0 : offset,
                            query: query
                        },
                        signal,
                    });

                    if(query.length > 0 || offset === 0){
                        setDetail(r.products);
                        setCachedDetail(r.products);
                        setDetailRows(r.total_rows);
                    }else{
                        if(r.total_rows > 0 ){
                            setDetail(prevState => [...prevState, ...r.products]);
                            setDetailRows(r.total_rows);
                        }else{
                            setDetail([])
                        }
                    }
                } catch (e) {
                    setDetail([]);
                    setDetailRows(0);
                    if(signal.aborted){
                        setLoading(true);
                    }
                } finally {
                    if(signal.aborted){
                        setLoading(true);
                    }else{
                        setLoading(false);
                    }
                }
            }
        }



        fetchContainerProducts();
        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, [offset, query, loggedCompany, limit, id]);


    return (
       <>

           <div className="sm:flex sm:items-center">
               <div className="sm:flex-auto">
                   <h1 className="text-xl font-medium text-gray-700">{t("app.quotations.product_title")}</h1>
                   <p className="mt-2 text-sm text-gray-700">
                       {t("app.orders.product_title_description")}
                   </p>
               </div>

               <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                   {
                       selectedProductList.length > 0  && (
                           <FilterButton
                               onClick={() => {
                                   onChangeStep();
                               }}
                               isRightIcon={true}
                               icon={
                                   <ArrowRightIcon
                                       className="w-4 h-4 ml-2"
                                       aria-hidden="true"
                                   />
                               }
                               text={t("app.common.back")}
                           />
                       )
                   }

               </div>
           </div>



           <ListSection
               columns={columns}
               totalRows={detailRows}
               list={detail.filter(item => !selectedProductList.some(e => e.id === item.id))}
               onSearch={setQuery}
               isLoading={(offset > 0 && query.length === 0) ? false : isLoading}
               showMobile={false}
               desktopRow={ContainerDeskRow}
               hasCheckbox={true}
               selectAll={selectAll}
               onInfiniteScroll={fetchMoreData}
               isPanelScroll={true}
               handleSelectAll={handleSelectAll}
               extras={{
                   isCheck,
                   selectedProductList,
                   handleClick
               }}
           >

               {
                   isCheck.length > 0 && (
                       <div className="inline-flex items-center justify-center">
                           <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                           <ImportButton
                               onClick={() => {
                                   let newList = isCheck.map(item => {
                                       return {...item, discounts: []};
                                   });
                                   onSelectedProductList(newList)
                               }}
                               icon={
                                   <PlusIcon
                                       className="w-4 h-4 fill-current flex-shrink-0"
                                       aria-hidden="true"
                                   />
                               }
                           />
                       </div>
                   )
               }

           </ListSection>

       </>
    );
};


const ContainerDeskRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    return (
        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                       id={element.id}
                       onChange={extras.handleClick}
                       checked={isCheck}
                       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <div>
                    <ProductCell product={element} isActiveImage={true}/>
                </div>
            </TableDetail>
        </tr>
    );
};



export default SearchProduct;
