import React, {useEffect, useState} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getTemplateSelectParam, getTemplateSelectParams} from "../../../../utils/converter";
import {
    COMPANY,
    DOCUMENT_TEMPLATES,
    DOCUMENT_TEMPLATE,
    FONT, FONTS
} from "../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {DocumentIcon} from "@heroicons/react/outline";
import {getElement, postElement} from "../../../../api/config";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import FileCell from "../../../../components/common/table/file-cell";
import UploadFile from "../../../../components/partials/upload/upload-file";
import ReportViewer from "../../../../components/partials/report/report-viewer";
import UnderlineTab from "../../../../components/partials/Tabs/underline-tab";
import InputSelect from "../../../../components/common/form/input-select";
import {TEMPLATE_FONTS} from "../../../../constants/config";
import {TrashIcon} from "@heroicons/react/outline/esm";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import {PRODUCT_DOCUMENT_TEMPLATE} from "../../../../constants/import";
import ButtonSubmit from "../../../../components/common/form/button-submit";
import Loader from "../../../../components/common/loader/loader";


const DocumentTemplate = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_DOCUMENT_TEMPLATE)]} renderBaseOnFail={true} >
        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.company_preference.template"),
                            t("app.company_preference.template_note"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATES])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, ':id'])}
                        baseResult={{total_rows: 0, templates: []}}
                        resultLabel={'templates'}
                        title={t("app.company_preference.template")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        icon={DocumentIcon}
                        editForm={EditForm}
                        editTitle={t("app.permissions.company_configuration_document_template")}
                        addForm={AddForm}
                        addFormLabel={t("app.catalog.add", {type: "Template"})}
                        btnAddLabel={t("app.catalog.add", {type: "Template"})}
                        isActiveEdit={true}
                        SlideDefaultWith={' max-w-3xl '}
                        isActiveFileViewer={true}

                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <FileCell file={{file: {reference: element.pdf.reference}, file_name: element.template_name}} canView={true} onOpenFile={() => {
                        extras.onOpenFile({file: {reference: element.pdf.reference},  file_name: element.template_name});
                    }} />
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                        )
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.template_note")}>
                        {element.template_note}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        <FileCell file={{file: {reference: element.pdf.reference}, file_name: element.template_name}} canView={true} onOpenFile={() => {
                            extras.onOpenFile({file: {reference: element.pdf.reference},  file_name: element.template_name});
                        }} />
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.template_note}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();

    const { t } = useTranslation();
    const [fileException, setFileException] = useState(null);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-1">
                    <UploadFile
                        onUpload={(data) => {
                            setValue('pdf', {media_url: data.media_url});
                            setValue('template_name', data.detail.name);
                        }}
                        onCancel={(r) => {
                            if(r){
                                setValue('pdf', undefined);
                                setValue('template_name', undefined);
                            }
                        }}
                        size={'30MB'}
                        onException={(e) => {
                            setFileException(e);
                        }}
                        isLoading={false}
                        label={t("app.company_preference.upload_template")}
                    />

                    <InputText
                        id={'template_note'}
                        type={'text'}
                        errors={errors.template_note}
                        input={{...register("template_note", { required: false })}}
                        label={t("app.company_preference.template_note")}
                    />
                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            {/* Warning */}
            {fileException && (
                <Warning message={fileException} />
            )}


            {
                watch('pdf') && (
                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.catalog.add", {type: "Template"})}
                        />
                    </div>
                )
            }


        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch
    } = useForm();



    //Fonts
    const [isLoading, setLoading] = useState(true);
    const [storeLoadingFont, setStoreLoadingFont] = useState(false);
    const [fontException, setFontException] = useState(false);
    const [cleanData, setClenData] = useState(false);
    const [templateFonts, setTemplateFonts] = useState([]);


    const [associations, setAssociations] = useState([]);


    // DELETE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    //File
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [reportDetail, setReportDetail] = useState({});

    //Tabs
    const [activeGroup, setActiveGroup] = useState("template-information");
    const tabs = [
        { name: t('app.company_preference.general_information'), group: "template-information" },
        { name: t('app.company_preference.font_configuration'), group: "template-fonts" },
    ];




    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, data.id, FONTS]), signal, {});
                setTemplateFonts(res.fonts);
            } catch (e) {
                setTemplateFonts([]);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, data, activeGroup]);


    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }

                if (k === "config_fields") {
                    // eslint-disable-next-line
                    data["config_fields"].map((e) => {
                        if(e.associations){
                            setValue('field_association_'+e.field_code, getTemplateSelectParam(e.associations));
                        }
                        if(e.font){
                            setValue('font_code_'+e.field_code, TEMPLATE_FONTS.find(r => r.value === e.font));
                        }
                    });
                }
            }

            setAssociations(data.document_fields.map((e) => ({
                field_code: e.field,
                associations: data['config_fields']?.find(r => e.field === r.field_code)?.associations ?? null,
                font: data['config_fields']?.find(r => e.field === r.field_code)?.font ?? null
            })));

        }
    }, [setValue, data]);


    const onSubmit = async (data) => {
        for (const k in data) {
            if (typeof data[k] === "object" && k !== 'pdf') {
               delete data[k];
            }
        }

        if(!data['pdf']){
            data['config_fields'] = associations
        }

        onSubmitData(data);
    };

    const onSubmitFonts = async (detail) => {
        setStoreLoadingFont(true);
        setFontException(false);
        postElement(getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, data.id, FONT]), detail)
            .then(response => {
                setValue('file', undefined);
                setValue('font_code', null);
                setClenData(!cleanData);
                setTemplateFonts(response.fonts);
                setStoreLoadingFont(false);
            }).catch(e => {
            setFontException(e.message);
            setStoreLoadingFont(false);
        });

    };




    return (
        <>

            <ReportViewer
                isOpen={isOpenReportView}
                type={'document'}
                reportDetail={reportDetail?.file ?? false}
                title={reportDetail?.file_name ?? ''}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />

            <div className={'mt-5'}>


                <UnderlineTab
                    marginTop={''}
                    tabs={tabs}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                />


                {
                    activeGroup === 'template-information' && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-4 pt-4 pb-5">

                                <div className="p-2 border border-gray-200 rounded">
                                    <FileCell file={{file: {reference: data.pdf.reference}, file_name: data.template_name}} canView={true} onOpenFile={() => {
                                        setReportDetail({file: {reference: data.pdf.reference},  file_name: data.template_name});
                                        setOpenReportView(true);
                                    }} />
                                </div>


                                <UploadFile
                                    onUpload={(data) => {
                                        setValue('pdf', {media_url: data.media_url});
                                        setValue('template_name', data.detail.name);
                                    }}
                                    onCancel={(r) => {
                                        if(r){
                                            setValue('pdf', undefined);
                                            setValue('template_name', undefined);
                                        }
                                    }}
                                    size={'30MB'}
                                    onException={(e) => {

                                    }}
                                    isLoading={false}
                                    label={t("app.company_preference.upload_template")}
                                />

                                <InputText
                                    id={'template_note'}
                                    type={'text'}
                                    errors={errors.template_note}
                                    input={{...register("template_note", { required: false })}}
                                    label={t("app.company_preference.template_note")}
                                />

                                {!watch('pdf') && (
                                    <div>
                                        <label className="block text-sm font-medium mb-1 text-gray-700" >
                                            {t("app.company_preference.template_configuration")}
                                        </label>

                                        <div className="mt-2 align-middle inline-block sm:min-w-full lg:max-w-full  overflow-x-auto w-24 min-w-full mx-auto border">
                                            <table className={"min-w-full"}>
                                                <thead className="bg-solitude-400 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider">
                                                <tr>
                                                    <th  className="px-6 py-3">
                                                        {t("app.company_preference.field")}
                                                    </th>
                                                    <th className=" px-6 py-3 ">
                                                        {t("app.company_preference.association")}
                                                    </th>
                                                    <th  className=" px-6 py-3 ">
                                                        Font
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                {data.document_fields.map((field) => (
                                                    <tr key={field.field}>
                                                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 ">{field.field} </td>
                                                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                                            <Controller
                                                                name={"field_association_" + field.field}
                                                                rules={{ required: true }}
                                                                control={control}
                                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                    <InputSelect
                                                                        label={t("app.company_preference.association")}
                                                                        isShowLabel={false}
                                                                        name={name}
                                                                        options={getTemplateSelectParams(PRODUCT_DOCUMENT_TEMPLATE).filter(r => r.type === field.type)}
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            let filtered = [...associations];
                                                                            let foundIndex = filtered.findIndex((x) => x.field_code === field.field);
                                                                            filtered[foundIndex]['associations'] = e?.value ?? null;
                                                                            setAssociations(filtered);
                                                                            onChange(e);
                                                                        }}
                                                                        isLoading={false}
                                                                        isDisabled={false}
                                                                        isClearable={true}
                                                                        errors={error}
                                                                    />
                                                                )}
                                                            />
                                                        </td>
                                                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">

                                                            {
                                                                field.type && field.type === 'string' && (
                                                                    <Controller
                                                                        name={"font_code_"+field.field}
                                                                        rules={{ required: false }}
                                                                        control={control}
                                                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                            <InputSelect
                                                                                label={'Font'}
                                                                                isShowLabel={false}
                                                                                name={name}
                                                                                options={TEMPLATE_FONTS.filter(obj1 => templateFonts.some(obj2 => obj2.font_code === obj1.value))}
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    let filtered = [...associations];
                                                                                    let foundIndex = filtered.findIndex((x) => x.field_code === field.field);
                                                                                    filtered[foundIndex]['font'] = e?.value ?? null;
                                                                                    setAssociations(filtered);
                                                                                    onChange(e);
                                                                                }}
                                                                                isLoading={false}
                                                                                isDisabled={false}
                                                                                isClearable={true}
                                                                                errors={error}
                                                                            />
                                                                        )}
                                                                    />
                                                                )
                                                            }


                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}


                            </div>


                            {/* Warning */}
                            {exception && (
                                <Warning message={exception} />
                            )}

                            <div className="mt-3">
                                <InputSubmit
                                    isLoading={isEditLoading}
                                    isFullWith={true}
                                    label={t("app.form.save")}
                                />
                            </div>
                        </form>
                    )
                }


                {
                    activeGroup === 'template-fonts' && (
                        <>
                                <div className="space-y-4  pb-5">

                                    <Controller
                                        name="font_code"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                            <InputSelect
                                                label={'Font'}
                                                name={name}
                                                options={TEMPLATE_FONTS.filter(obj1 => !templateFonts.some(obj2 => obj2.font_code === obj1.value))}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                isLoading={false}
                                                isDisabled={false}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <UploadFile
                                        canViewFile={false}
                                        cleanData={cleanData}
                                        onUpload={(data) => {
                                            setValue('file', {media_url: data.media_url});
                                        }}
                                        onCancel={(r) => {
                                            if(r){
                                                setValue('file', undefined);
                                            }
                                        }}
                                        size={'30MB'}
                                        onException={(e) => {
                                            setFontException(e)
                                        }}
                                        isLoading={false}
                                        label={t("app.company_preference.upload_font")}
                                    />
                                </div>


                                {/* Warning */}
                                {fontException && (
                                    <Warning message={fontException} />
                                )}

                                <div className="mt-3">
                                    <ButtonSubmit
                                        onClick={() => {
                                            let data = {
                                                file: watch('file'),
                                                font_code: watch('font_code').value,
                                            }
                                            onSubmitFonts(data)
                                        }}
                                        disabled={!(watch('file') && watch('font_code'))}
                                        isLoading={storeLoadingFont}
                                        isFullWith={true}
                                        label={t("app.form.add")}
                                    />
                                </div>




                            {
                                templateFonts.length > 0 && (
                                    <div className=" bg-white mt-5 rounded-sm border border-slate-200">
                                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                                            <div className="relative">
                                                <ul className="relative z-0 divide-y divide-gray-200">

                                                    {
                                                        isLoading ? (
                                                           <div className={"p-4"}>
                                                               <Loader />
                                                           </div>
                                                        ) : (
                                                            <>
                                                                {templateFonts.map((font) => (
                                                                    <li key={font.id} className=" px-2 bg-white hover:bg-gray-50">
                                                                        <div className="py-2 flex items-center justify-between space-x-3">
                                                                            <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                                                <div className="min-w-0 flex-1">
                                                                                    <p className="text-sm font-medium text-gray-900 truncate">
                                                                                        {TEMPLATE_FONTS.find(r => r.value === font.font_code).label}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                                                <button
                                                                                    onClick={() => {
                                                                                        const removeDetail = {
                                                                                            title: t("app.common.remove_generic_title"),
                                                                                            message: t("app.common.remove_generic_description"),
                                                                                            endpoint: getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATE, data.id, FONT, font.id ])
                                                                                        };
                                                                                        setRemoveDetail(removeDetail);
                                                                                        setOpenRemove(true);
                                                                                    }}
                                                                                    className={
                                                                                        "w-full btn bg-red-500 hover:bg-red-700 "
                                                                                    }
                                                                                >
                                                                                    <TrashIcon
                                                                                        className="h-4 w-4 text-white"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </>
                                                        )
                                                    }



                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                )
                            }




                        </>
                    )
                }
            </div>


            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setTemplateFonts(r.fonts);
                    }}
                />
            )}

        </>
    );
}

export default DocumentTemplate;
