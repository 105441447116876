import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import ListSection from "../../../../../components/layout/list-section";
import {useSelector} from "react-redux";
import {PlusIcon} from "@heroicons/react/outline";
import ProductCell from "../../../../../components/common/table/product-cell";
import TableDetail from "../../../../../components/common/list/table-detail";
import {useTranslation} from "react-i18next";
import { generateParamsFormLocation } from "../../../../../constants/dynamic-filter";
import DynamicFilterMenu from "../../../../../components/common/table/dynamic-filter-menu";
import CardDetail from "../../../../../components/common/list/card-detail";
import { StatusBarIcon } from '../../../../../components/common/alert/status-bar-icon';
import { getProductsFilter } from '../../../../../api/catalog';
import SlidePanel from '../../../../../components/layout/slide-panel';
import ButtonSubmit from '../../../../../components/common/form/button-submit';

const SearchProduct = ({ selectedProductList, onSelectedProductList, setIsOpen, isOpen, title, description, isLoadingData, exludeId = false }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const { company } = useSelector((state) => state.userCompany);

    const { t } = useTranslation();
    const location = useLocation();

    const columns = [
        t("app.container.product"),
        t("app.products.manufacture_code"),
        (company?.product_manage_status ?? false) && t("app.products.status")
    ].filter(Boolean);

    let {id}  = useParams();
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState([]);
    const [detailRows, setDetailRows] = useState(0);

    const checkSearch = () => {
        const querySearch = new URLSearchParams(window.location.search).get(
            "querySearch"
        );
        if(querySearch){
            return querySearch;
        }
        return "";
    }


    const [query, setQuery] = useState(checkSearch());
    const [limit] = useState(25);
    const [offset, setOffset] = useState(0);

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
    const [defaultParams, setDefaultParams] = useState(false);

    const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
    const [dynamicFilters, setDynamicFilters] = useState(false);


    const handleSelectAll = () => {
        if(detail.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(detail);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let product = detail.find(r => r.id === id);
        setIsCheck([...isCheck, product]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };

    const fetchMoreData = () => {
        setOffset(limit + offset);
    };


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchProducts() {
            if (loggedCompany) {
                setLoading(true);
                try {
                    const r = await getProductsFilter({
                        company_id: loggedCompany.id,
                        params: {
                            limit: limit,
                            offset: query.length > 0  ? 0 : offset,
                            query: query
                        },
                        data: activeDynamicFilter,
                        signal,
                    });

                    if(r.filters){
                        setDynamicFilters(r.filters);
                    }

                    if (r.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)) {
                        setDefaultParams('?' + r.default_filter);
                    }

                    if(query.length > 0 || offset === 0){
                        setDetail(r.products);
                        setDetailRows(r.total_rows);
                    }else{
                        if(r.total_rows > 0 ){
                            setDetail(r.products);
                            setDetailRows(r.total_rows);
                        }else{
                            setDetail([])
                        }
                    }
                } catch (e) {
                    setDetail([]);
                    setDetailRows(0);
                    if(signal.aborted){
                        setLoading(true);
                    }
                } finally {
                    if(signal.aborted){
                        setLoading(true);
                    }else{
                        setLoading(false);
                    }
                }
            }
        }



        fetchProducts();
        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, [offset, query, loggedCompany, limit, id, activeDynamicFilter]);


    return (
       <>
            <SlidePanel
                title={t("app.catalog.alternative")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bg={'bg-white'}
                size={'max-w-5xl'}
                hasDivider={false}
                setClean={() => {

                }}
            >
           <div className="sm:flex sm:items-center mt-5">
               <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-700">{title}</h1>
                   <p className="mt-2 text-sm text-gray-700">
                            {description}
                   </p>
               </div>

             
           </div>

           <ListSection
               title={t("app.products.products")}
               columns={columns}
               totalRows={detailRows}
                    list={detail.filter(item => !selectedProductList.some(e => e.product.id === item.id) && !(item.id === exludeId) )}
               onSearch={setQuery}
               isLoading={(offset > 0 && query.length === 0) ? false : isLoading}
               mobileRow={ProductMobRow}
               desktopRow={ProductDeskRow}
               hasCheckbox={true}
               isDynamicSearch={true}
               selectAll={selectAll}
               onInfiniteScroll={fetchMoreData}
               isPanelScroll={true}
               handleSelectAll={handleSelectAll}
               activeDynamicFilter={true}
               extras={{
                   isCheck,
                   selectedProductList,
                   handleClick
               }}
           >


               <DynamicFilterMenu
                   filters={dynamicFilters}
                   isLoading={isLoading && offset === 0}
                    defaultParams={defaultParams}
                   onChange={(r) => {
                       setDynamicDateFilter(r);
                       setOffset(0);
                       setQuery('');
                       setDefaultParamsUsed(true);
                       setDefaultParams(false);

                   }}
               />

               {
                   isCheck.length > 0 && (
                       <div className="inline-flex items-center justify-center">
                           <ButtonSubmit
                               isLoading={isLoadingData}
                               onClick={() => {
                                    onSelectedProductList(isCheck)
                               }}
                               icon={
                                   <PlusIcon
                                       className="w-4 h-4 fill-current flex-shrink-0"
                                       aria-hidden="true"
                                   />
                               }
                                    label={t("app.catalog.add", { type: isCheck.length + " "+t("app.common.item_selected") })}
                           />
                       </div>
                   )
               }


           </ListSection>

        </SlidePanel>
       </>
    );
};


const ProductMobRow = ({ element, extras }) => {

    const { t } = useTranslation();
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { company } = useSelector((state) => state.userCompany);

    return (
        <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
            <div className="flex-grow">


                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex justify-between items-center"}>
                        <input type="checkbox"
                               id={element.id}
                               onChange={extras.handleClick}
                               checked={isCheck}
                               className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        <ProductCell product={element} isActiveImage={true} isActiveManufacture={false}/>
                    </div>
                </div>



                <div className="flex flex-col">
                    <CardDetail title={t("app.products.manufacture_code")}>
                        {element.manufacture_reference}
                    </CardDetail>
                   
                    {
                        company.product_manage_status && (
                            <CardDetail title={t("app.products.status")}>
                                <StatusBarIcon status={element.status} />
                            </CardDetail>
                        )
                    }

                </div>
            </div>
        </li>
    );
};


const ProductDeskRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { company } = useSelector((state) => state.userCompany);


    return (
        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                       id={element.id}
                       onChange={extras.handleClick}
                       checked={isCheck}
                       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <div>
                    <ProductCell product={element} isActiveImage={true} isActiveManufacture={false}/>
                </div>
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900">
                    {element.manufacture_reference}
                </div>
                <div className="text-xs flex-wrap text-gray-700">
                    {element?.company_manufacture?.name ?? ''}
                </div>
            </TableDetail>

            {
                company.product_manage_status && (
                    <TableDetail>
                        <StatusBarIcon status={element.status} />
                    </TableDetail>
                )
            }
           
        </tr>
    );
};



export default SearchProduct;
