import React, {useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {checkResetPassword, resetPassword} from "../../reducers/userReducer";

import HeaderProfiling from "../../components/layout/header-profiling";
import {useForm} from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import {passwordRegex} from "../../constants/regex";
import {Warning} from "../../components/common/alert/banner";
import InputSubmit from "../../components/common/form/input-submit";
import Loader from "../../components/common/loader/loader";
import { useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SuccessBoxLink} from "../../components/common/alert/success-box";
import {FailedBoxLink} from "../../components/common/alert/failed-box";
import {LOGIN} from "../endpoints";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const ResetPassword = () => {
  const { isCheckConfirmed, checkResetException, isCheckResetLoading, resetException, isResetPasswordLoading, isResetConfirmed } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { confirmation_code } = useParams();
  const { t } = useTranslation();

  const { register, handleSubmit,watch, formState: { errors } } = useForm();
  let currentPassword = useRef({});
  currentPassword = watch("password", "");


  const onSubmit = (data) => {
   // console.log(data);
    dispatch(resetPassword({confirmation_code: confirmation_code, data: data}));
  };

  useEffect(() => {
    dispatch( checkResetPassword(confirmation_code));
  }, [dispatch, confirmation_code]);


  return(
      <main className="bg-white">

        <div className="relative md:flex">

          {/* Content */}
          <div className="w-full md:w-1/2">
            <div className="min-h-screen h-full flex flex-col after:flex-1">
              <div className="flex-1">

                {/* Header */}
                <HeaderProfiling
                    isActiveSignin={true}
                />
              </div>

              <div className="max-w-lg mx-auto px-4 py-8 w-3/4">


              {
                isCheckResetLoading ? (
                    <Loader/>
                ) : (
                    isCheckConfirmed ? (
                        !isResetConfirmed ? (
                            <>
                              <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("oauth.reset_password.title")}</h1>

                              {/* Form */}
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="space-y-4">
                                  <InputText
                                      id={'password'}
                                      type={'password'}
                                      label={t("app.form.password")}
                                      errors={errors.password}
                                      input={{...register("password", { required: true, pattern: passwordRegex })}}
                                      warningRegex={t("app.warning.password_regex")}
                                  />

                                  <InputText
                                      id={'password_repeat'}
                                      type={'password'}
                                      label={t('app.form.password_repeat')}
                                      errors={errors.passwordRepeat}
                                      input={{...register("passwordRepeat", { validate: value => value === currentPassword || "The passwords do not match" })}}
                                      warning={t('app.warning.password_match')}
                                  />

                                </div>

                                {/* Warning */}
                                {resetException && (
                                    <Warning
                                        message={resetException}
                                    />
                                )}

                                <div className="flex items-center justify-between mt-6">
                                  <InputSubmit
                                      isLoading={isResetPasswordLoading}
                                      label={t('oauth.reset_password.confirm_name')}
                                      isFullWith={true}
                                  />
                                </div>
                              </form>
                            </>
                        ) : (
                            <SuccessBoxLink
                                title={t('oauth.reset_password.success_title')}
                                message={t('oauth.reset_password.success_message')}
                                link={LOGIN}
                                linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                            />
                        )
                    ) : (
                        <FailedBoxLink
                            title={t('app.message.generic_not_found')}
                            message={checkResetException}
                            link={LOGIN}
                            linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                        />
                    )
                )
              }


              </div>






            </div>
          </div>

          {/* Image */}
          <SidebarProfiling />


        </div>

      </main>
  );
};

export default ResetPassword;
