import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {confirmMail, userOnboardingStatus} from "../../reducers/userReducer";
import {useNavigate, useParams} from "react-router-dom";
import HeaderProfiling from "../../components/layout/header-profiling";
import Loader from "../../components/common/loader/loader";
import {FailedBoxLink} from "../../components/common/alert/failed-box";
import {SuccessBoxBtn} from "../../components/common/alert/success-box";
import {useTranslation} from "react-i18next";
import {INDEX, LOGIN, ONBOARDING} from "../endpoints";
import SidebarProfiling from "../../components/layout/sidebar-profiling";
import {getWebPathParam} from "../../utils/converter";
import {confirmVerificationMail} from "../../api/user";

const ConfirmRegistration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let {confirmation_code}  = useParams();

  const [isCheckMailLoading, setCheckMailLoading] = useState(true);
  const [isConfirmMailLoading, setConfirmMailLoading] = useState(false);
  const [checkMailException, setCheckMailException] = useState(false);
  let navigate = useNavigate();

  const confirmUserMail = async () => {
    setConfirmMailLoading(true);
    await confirmVerificationMail(confirmation_code)
        .then((response) => {
          //console.log(response);
          setConfirmMailLoading(false);

          if(response.onboarding_status === userOnboardingStatus.COMPANY_DEFAULT_TYPE){
              dispatch(confirmMail(response));
              navigate(getWebPathParam([ONBOARDING]));
          }

          if(response.onboarding_status === userOnboardingStatus.CONFIRMED){
              dispatch(confirmMail(response));
              navigate(getWebPathParam([INDEX]));
          }
        })
        .catch((e) => {
          setCheckMailException(e.message);
          setConfirmMailLoading(false);
        });
  };



    useEffect(() => {
        function checkUser() {
            setCheckMailLoading(true);
            confirmVerificationMail(confirmation_code + "/check")
                .then((response) => {
                    setCheckMailLoading(false);
                })
                .catch((e) => {
                    setCheckMailException(e.message);
                    setCheckMailLoading(false);
                });
        }
        checkUser();
    }, [confirmation_code]);





    return (
      <main className="bg-white">

        <div className="relative flex">

          {/* Content */}
          <div className="w-full md:w-1/2">
            <div className="min-h-screen h-full flex flex-col after:flex-1">
              <div className="flex-1">

                {/* Header */}
                <HeaderProfiling
                    isActiveSignin={true}
                />
              </div>


              <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
                {
                  isCheckMailLoading ? (
                      <Loader />
                  ) : (
                      checkMailException ? (
                          <FailedBoxLink
                              title={t('app.message.generic_not_found')}
                              message={checkMailException}
                              link={LOGIN}
                              linkTitle={t('app.common.go_to', {label: ' Login ->'})}
                          />
                      ) : (
                          <SuccessBoxBtn
                              title={t('oauth.confirm_registration.success_title')}
                              message={t('oauth.confirm_registration.success_message')}
                              label={t('oauth.confirm_registration.name')}
                              isLoading={isConfirmMailLoading}
                              loadingLabel={t('app.common.loading')}
                              onClick={() => {confirmUserMail(confirmation_code)}}
                          />
                      )
                  )
                }
              </div>

            </div>

          </div>

          {/* Image */}
          <SidebarProfiling />


        </div>

      </main>
  )



};

export default ConfirmRegistration;
