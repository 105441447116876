import React from "react";
import Loader from "./loader";

const LoaderWrapper = ({ isLoading, children }) =>
  isLoading ? (
    <div className="w-full py-4 flex justify-center">
      <Loader />
    </div>
  ) : (
    <>{children}</>
  );

export default LoaderWrapper;
