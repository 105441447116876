import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { P404} from "../endpoints";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import {getQuotation} from "../../api/quotation";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import QuotationHeader from "./overview/quotation-header";
import QuotationContainers from "./overview/quotation-containers";
import {SALES, QUOTATIONS} from "../endpoints";

const importType = 'quotation_product';

const QuotationOverview = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const { loggedCompany, isExporter } = useSelector((state) => state.user);

  // get Quotation details
  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState(null);

  const [needRefresh, setNeedRefresh] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [lastImport, setLastImport] = useState(false);
  const [containerId, setContainerId] = useState(false);
  const [isConfirmedPackaging, setConfirmedPackaging] = useState(true);

  const [isHideSensitiveData, setHideSensitiveData] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (loggedCompany) {

      if(needRefresh === null){
        setLoading(true);
      }
      getQuotation({ company_id: loggedCompany.id, quotation_id: id, signal })
          .then((response) => {
            setDetail(response);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            e.code === 404 && navigate(P404);
          });
    }

    return () => {
      controller.abort();
    };
  }, [id, loggedCompany, navigate, needRefresh]);

  useEffect(() => {
    if(!isLoading){
      navigate(SALES + QUOTATIONS);
    }
    // eslint-disable-next-line
  }, [isExporter]);


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.QUOTATION, permissionType.VIEW)]} renderBaseOnFail={true}>

      <LoaderWrapper isLoading={isLoading}>

        <div className="py-6">

          {/* Page Header */}
          <QuotationHeader
              detail={detail}
              setDetail={setDetail}
              importType={importType}
              lastImport={lastImport}
              setLastImport={setLastImport}
              containerId={containerId}
              isConfirmedPackaging={isConfirmedPackaging}
              ontHideSensitiveData={(r) => {
                setHideSensitiveData(r);
              }}
              isHideSensitiveData={isHideSensitiveData}
              setRefresh={(r) => {
                setNeedRefresh(needRefresh === null ? r : !needRefresh)
                setRefresh(!refresh)
              }
          }/>

          {/* container */}
          <QuotationContainers
              quotationDetail={detail}
              setContainerId={setContainerId}
              importType={importType}
              setLastImport={setLastImport}
              refresh={refresh}
              onConfirmedPackaging={(r) => {setConfirmedPackaging(r)}}
              isHideSensitiveData={isHideSensitiveData}
              setNeedRefresh={(r) => {setNeedRefresh(needRefresh === null ? r : !needRefresh)}}
          />


        </div>

      </LoaderWrapper>

    </BasePermissionWrapper>
  );
};


export default QuotationOverview;
