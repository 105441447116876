import {
    COMPANY,
    USER_CONNECTIONS,
    USER_CONNECTION,
    CUSTOMER,
    USER_PERMISSIONS,
    USER_PERMISSION, CUSTOMERS,
} from "./endpoints";
import API from "../utils/apiUtils";
import { getPathParam, serialize } from "../utils/converter";

// Inviter
export const getCompanyUserConnections = ({ company_id, params, signal }) =>
  API.get(
    getPathParam([COMPANY, company_id, USER_CONNECTIONS]) + serialize(params),
      signal && { signal: signal }
  );


export const getCompanyUserCustomers = ({ company_id, connection_id }) =>
    API.get(getPathParam([COMPANY, company_id, USER_CONNECTION, connection_id, CUSTOMERS]));

export const getCompanyUserConnection = ({ company_id, connection_id }) =>
  API.get(getPathParam([COMPANY, company_id, USER_CONNECTION, connection_id]));

export const postCompanyUserConnection = ({ company_id, data }) =>
  API.post(getPathParam([COMPANY, company_id, USER_CONNECTION]), data);

export const putCompanyUserConnection = ({ company_id, connection_id, data }) =>
  API.put(
    getPathParam([COMPANY, company_id, USER_CONNECTION, connection_id]),
    data
  );

export const deleteCompanyUserConnection = ({
  company_id,
  connection_id,
  data,
}) =>
  API.put(
    getPathParam([COMPANY, company_id, USER_CONNECTION, connection_id]),
    data
  );

// Inviter Customer
export const postCompanyUserCustomer = ({
  company_id,
  connection_id,
  customer_id,
}) =>
  API.post(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      CUSTOMER,
      customer_id,
    ]),
    {}
  );

export const deleteCompanyUserCustomer = ({
  company_id,
  connection_id,
  customer_id,
}) =>
  API.delete(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      CUSTOMER,
      customer_id,
    ]),
    {}
  );

export const getCompanyUserPermissions = ({ company_id, connection_id, params = {} }) =>
  API.get(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      USER_PERMISSIONS,
    ]) + serialize(params)

  );

export const postCompanyUserPermission = ({
  company_id,
  connection_id,
  data,
}) =>
  API.post(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      USER_PERMISSION,
    ]),
    data
  );

export const updateCompanyUserPermissions = ({
  company_id,
  connection_id,
  data,
}) =>
  API.post(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      USER_PERMISSIONS,
    ]),
    data
  );

export const deleteCompanyUserPermission = ({
  company_id,
  connection_id,
  permission_id,
}) =>
  API.delete(
    getPathParam([
      COMPANY,
      company_id,
      USER_CONNECTION,
      connection_id,
      USER_PERMISSION,
      permission_id,
    ])
  );
