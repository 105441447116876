import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";

import PageHeader, {ActionButton} from "../../../components/layout/page-header";
import { useSelector } from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import {COMPANY_DETAIL, COMPANY} from "../../endpoints";
import { getWebPathParam } from "../../../utils/converter";
import AddCompany from "./add-company";
import { useTranslation } from "react-i18next";
import { getCompanies } from "../../../api/company";
import  {filterByPermission} from "../../../components/partials/restricted/permission-wrapper";
import ListSection from "../../../components/layout/list-section";
import { useMemo } from "react";
import CustomerIcon from "../../../resources/icons/CustomerIcon";
import CompanyCell from "../../../components/common/table/company-cell";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";

const Companies = () => {
  const { permissions } = useSelector((s) => s.userCompany);
  const { loggedCompany } = useSelector((state) => state.user);

  const [isFetchCompaniesLoading, setFetchCompaniesLoading] = useState(true);
  const [companiesList, setCompaniesList] = useState({
    companies: [],
    total_rows: 0,
  });
  let navigate = useNavigate();

  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const columns = [
    t("app.company.business_name"),
    t("app.company.country"),
    "",
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    if(loggedCompany){
      // eslint-disable-next-line
      navigate(getWebPathParam([COMPANY, COMPANY_DETAIL, loggedCompany.id]));
    }



    async function fetchCompanies() {
      setFetchCompaniesLoading(true);
      try {
        const res = await getCompanies({
          params: { limit: 10, offset: query.length > 0 ? 0 : offset, query: query },
          signal,
        });
        setCompaniesList(res);
      } catch (e) {
        setCompaniesList({ companies: [], total_rows: 0 });
      } finally {
        setFetchCompaniesLoading(false);
      }
    }

    fetchCompanies();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [offset, query]);

  const allowed = useMemo(
    () =>
      (companiesList.companies ?? []).filter(
        filterByPermission(
            '',
          permissions,
          (ord) => ord.companies.id
        )
      ),
    [companiesList, permissions]
  );

  return (
    <>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.company.companies")}>
            <ActionButton
                icon={
                  <CustomerIcon
                      className="w-4 h-4 fill-current flex-shrink-0"
                      aria-hidden="true"
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                }}
                text={t("app.company.new_company")}
            />
          </PageHeader>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <ListSection
            title={t("app.company.companies")}
            columns={columns}
            totalRows={companiesList.total_rows}
            list={allowed}
            onSearch={setQuery}
            isLoading={isFetchCompaniesLoading}
            paginationOnChange={setOffset}
            mobileRow={MobRow}
            desktopRow={DeskRow}
          />
        </div>
      </div>
      {isOpen && <AddCompany isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

const MobRow = ({ element }) => {
  const { t } = useTranslation();
  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <Link to={getWebPathParam([COMPANY, COMPANY_DETAIL, element.id])} className="flex-grow">
        <div className="pb-4 items-center flex justify-between">
          <CompanyCell name={element.business_name} color={element.color} />
          <ActionsMenu>
            <Link to={getWebPathParam([COMPANY, COMPANY_DETAIL, element.id])}>
              {t("app.common.edit")}
            </Link>
          </ActionsMenu>
        </div>
        <div className="flex flex-col">

          <CardDetail title={t("app.company.country")}>
            {element.country.name}
          </CardDetail>

        </div>
      </Link>
    </li>
  );
};

const DeskRow = ({ element }) => {
  const { t } = useTranslation();
  return (
    <tr key={element.id}>
      <TableDetail extraClass="max-w-sm">
        <div className="flex items-center space-x-3 lg:pl-2">
          <Link
            to={getWebPathParam([COMPANY, COMPANY_DETAIL, element.id])}
            className="truncate hover:text-gray-600"
          >
            <CompanyCell name={element.business_name} color={element.color} />
          </Link>
        </div>
      </TableDetail>
      <TableDetail>
        {element.country.name}
      </TableDetail>
      <TableDetail>
        <ActionsMenu>
          <Link to={getWebPathParam([COMPANY, COMPANY_DETAIL, element.id])}>
            {t("app.common.edit")}
          </Link>
        </ActionsMenu>
      </TableDetail>
    </tr>
  );
};

export default Companies;
