import * as Endpoint from "../routes/endpoints";
import DashboardIcon from "../resources/icons/DashboardIcon";
import OrderIcon from "../resources/icons/OrderIcon";
import CustomerIcon from "../resources/icons/CustomerIcon";
import CatalogIcon from "../resources/icons/CatalogIcon";
import AccountIcon from "../resources/icons/AccountIcon";
import {COMPANY_IMPORTER} from "./config";
import {permissionGroup, permissionSpecific, sp} from "./permissions";
import BoxIcon from "../resources/icons/BoxIcon";
import BoardIcon from "../resources/icons/BoardIcon";
import CompanyIcon from "../resources/icons/CompanyIcon";

// getMenu returns the layout of the menu
export const getMenu = (companyType) => {
  let menu = {};
  if(companyType === COMPANY_IMPORTER){
    menu = { ...menu, ...{ "ND": ["DASHBOARD", "SALES", "LOGISTICS", "REGISTRY", "CATALOG", "COMPANY_TASK"]}};
    menu = {...menu, ...{settings: ["COMPANY_CONFIG","ACCOUNT"]}};
  }else{
    menu = { ...menu, ...{ "ND": ["DASHBOARD", "SALES", "LOGISTICS", "REGISTRY", "CATALOG", "COMPANY_TASK"]}};
    menu = {...menu, ...{settings: ["COMPANY_CONFIG", "ACCOUNT"]}};
  }
  return menu;
};

// sidebarElements describe each menu voice (and eventual sub menu voices)
export const sidebarElements = {
  DASHBOARD: {
    name: "dashboard",
    icon: DashboardIcon,
    location: Endpoint.DASHBOARD,
    indexLocation: Endpoint.DASHBOARD,
    subscriptionGroup: []
  },
  SALES: {
    name: "sales",
    icon: OrderIcon,
    location: "#",
    locationGroup: Endpoint.SALES,
    subscriptionGroup: [],
    submenu: {
      LEAD: {
        name: "lead",
        hideForImporter: false,
        location: Endpoint.SALES + Endpoint.LEADS,
        subscriptionGroup: []
      },
      QUOTATION: {
        name: "quotation",
        hideForImporter: false,
        location: Endpoint.SALES + Endpoint.QUOTATIONS,
        subscriptionGroup: []
      },
      ORDER: {
        name: "order",
        hideForImporter: false,
        location: Endpoint.SALES + Endpoint.ORDERS,
        subscriptionGroup: []
      }
    },
  },
  LOGISTICS: {
    name: "logistics",
    icon: BoxIcon,
    location: "#",
    locationGroup: Endpoint.LOGISTICS,
    subscriptionGroup: [],
    submenu: {
     /* FULFILLMENT: {
        name: "fulfillment",
        hideForImporter: false,
        location: Endpoint.LOGISTICS + Endpoint.FULFILLMENTS,
        subscriptionGroup: []
      },
      BOOKING: {
        name: "booking",
        hideForImporter: false,
        location: Endpoint.LOGISTICS + Endpoint.BOOKINGS,
        subscriptionGroup: []
      }, */
      SHIPPING: {
        name: "shipping",
        hideForImporter: false,
        location: Endpoint.LOGISTICS + Endpoint.SHIPPINGS,
        subscriptionGroup: []
      },
    },
  },
  REGISTRY: {
    name: "registry",
    icon: CustomerIcon,
    location: "#",
    locationGroup: Endpoint.REGISTRY,
    subscriptionGroup: [],
    submenu: {
      CUSTOMER: {
        name: "customer",
        hideForImporter: false,
        location: Endpoint.REGISTRY + Endpoint.CUSTOMERS,
        subscriptionGroup: [],
      },
      SUPPLIER: {
        name: "supplier",
        hideForImporter: false,
        location: Endpoint.REGISTRY + Endpoint.SUPPLIERS,
        subscriptionGroup: []
      },
      COMPANY_FORWARDER: {
        name: "forwarder",
        hideForImporter: false,
        location: Endpoint.REGISTRY + Endpoint.FORWARDERS,
        subscriptionGroup: [sp.FORWARDER_INTERNAL_PACKAGE]
      },
      USER: {
        name: "user",
        hideForImporter: false,
        location: Endpoint.REGISTRY + Endpoint.USERS,
        subscriptionGroup: [sp.EMPLOYEE_INTERNAL_PACKAGE, sp.AGENT_INTERNAL_PACKAGE]
      },
    },
  },
  CATALOG: {
    name: "catalog",
    icon: CatalogIcon,
    location: "#",
    locationGroup: Endpoint.CATALOG,
    subscriptionGroup: [sp.CATALOG_INTERNAL_PACKAGE],
    submenu: {
      PRODUCT: {
        name: "product",
        hideForImporter: false,
        location: Endpoint.CATALOG + Endpoint.PRODUCTS,
        subscriptionGroup: [],
        countAlertLabel: 'not_confirmed_product',
        countAlertPermissionGroup: permissionGroup.PRODUCT,
        countAlertPermissionSpecific: permissionSpecific.PRODUCT_MANAGE_STATUS
      },
      PRODUCT_SETTING: {
        name: "settings",
        hideForImporter: false,
        location: Endpoint.CATALOG + Endpoint.PRODUCT_SETTINGS,
        subscriptionGroup: []
      },
    },
  },
  COMPANY_CONFIG: {
    name: "company",
    icon: CompanyIcon,
    location: "#",
    locationGroup: Endpoint.COMPANY,
    subscriptionGroup: [],
    submenu: {
      COMPANY_CONFIGURATION: {
        name: "config",
        hideForImporter: false,
        location: Endpoint.COMPANY + Endpoint.CONFIGURATION,
        subscriptionGroup: [sp.EMPLOYEE_INTERNAL_PACKAGE, sp.AGENT_INTERNAL_PACKAGE, sp.SHIPPING_INTERNAL_PACKAGE, sp.IMPORT_PRODUCT_MARKETPLACE_PACKAGE, sp.IMPORT_ORDER_MARKETPLACE_PACKAGE]
      },
      COMPANY_SETTINGS: {
        name: "settings",
        hideForImporter: false,
        location: Endpoint.COMPANY + Endpoint.COMPANY_DETAIL,
        subscriptionGroup: []
      }
    },
  },
  ACCOUNT: {
    name: "account",
    icon: AccountIcon,
    location: Endpoint.ACCOUNT + Endpoint.PROFILE_SETTINGS,
    locationGroup: Endpoint.ACCOUNT,
    subscriptionGroup: []
  },
  COMPANY_TASK: {
    name: "boards",
    icon: BoardIcon,
    location: Endpoint.BOARDS,
    locationGroup: Endpoint.BOARDS,
    subscriptionGroup: [sp.TASK_MARKETPLACE_PACKAGE]
  },
  /*

  SHIPPING: {
    name: "shippings",
    icon: BoxIcon,
    location: Endpoint.SHIPPINGS,
    locationGroup: Endpoint.SHIPPING,
    subscriptionGroup: []
  },
  ORDER: {
    name: "orders",
    icon: OrderIcon,
    location: Endpoint.SALES + Endpoint.ORDERS,
    locationGroup: Endpoint.ORDER,
    subscriptionGroup: []
  },
  QUOTATION: {
    name: "quotation",
    icon: DocumentTextIcon,
    location: Endpoint.SALES + Endpoint.QUOTATIONS,
    locationGroup: Endpoint.QUOTATION,
    subscriptionGroup: []
  },

    CUSTOMER: {
    name: "customers",
    icon: CustomerIcon,
    location: Endpoint.CUSTOMERS,
    locationGroup: Endpoint.CUSTOMER,
    subscriptionGroup: []
  },
  SUPPLIER: {
    name: "supplier",
    icon: ContainerIcon,
    location: Endpoint.SUPPLIERS,
    locationGroup: Endpoint.SUPPLIER,
    subscriptionGroup: []
  },
 ACCOUNT: {
   name: "account",
   icon: AccountIcon,
   location: "#",
   locationGroup: Endpoint.ACCOUNT,
   subscriptionGroup: [],
   submenu: {
     CONNECTION: {
       name: "connection",
       hideForImporter: false,
       location: Endpoint.ACCOUNT + Endpoint.CONNECTIONS,
       subscriptionGroup: []
     },
     SETTINGS: {
       name: "profile",
       hideForImporter: false,
       location: Endpoint.ACCOUNT + Endpoint.PROFILE_SETTINGS,
       subscriptionGroup: []
     }
   },
 },
CONNECTION: {
   name: "connection",
   icon: UserGroupIcon,
   location: Endpoint.CONNECTIONS,
   locationGroup: Endpoint.CONNECTION,
   subscriptionGroup: []
 },*/
};
