import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function InfiniteScrollPagination({panelHeight = 800, isActiveScrollPagination, dataLength, onInfiniteScroll, children, isPanelScroll}) {

    if(!isActiveScrollPagination){
        return (<>{children}</>);
    }


    return (

        <>
            {
                isPanelScroll ? (
                    <InfiniteScroll
                        dataLength={dataLength} //This is important field to render the next data
                        next={onInfiniteScroll}
                        hasMore={true}
                        loader={<></>}
                        height={panelHeight}
                    >
                        {children}
                    </InfiniteScroll>
                ) : (
                    <InfiniteScroll
                        dataLength={dataLength} //This is important field to render the next data
                        next={onInfiniteScroll}
                        hasMore={true}
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                    >
                        {children}
                    </InfiniteScroll>
                )
            }
        </>


    )
}
