import { Tab } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StepsTabs({categories, activeCategory, onActiveCategories, children}) {

    return (
        <div className="w-full px-2">
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-solitude-400 p-1">
                    {categories.map((category) => (
                        <Tab
                            key={category.name}
                            onClick={() => {
                                onActiveCategories(category.name);
                            }}
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-1000',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-1000 hover:bg-solitude-100 hover:text-blue-1000'
                                )
                            }
                        >
                            {category.name}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {categories.map((category, idx) => (
                        <Tab.Panel
                            key={idx}
                        >
                            { category.name === activeCategory && (
                                <>{children}</>
                            )}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
