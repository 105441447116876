import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import { useParams} from "react-router-dom";
import {fetchDocumentTypes} from "../../../../reducers/configReducer";
import FormPopup from "../../../../components/common/popup/form-popup";
import {postDocument, postDocumentImporter} from "../../../../api/document";
import {cleanData} from "../../../../utils/converter";
import InputSubmit from "../../../../components/common/form/input-submit";
import {Warning} from "../../../../components/common/alert/banner";
import InputSelect from "../../../../components/common/form/input-select";
import UploadFile from "../../../../components/partials/upload/upload-file";
import InputCheckbox from "../../../../components/common/form/input-checkbox";
import InputText from "../../../../components/common/form/input-text";
import {useTranslation} from "react-i18next";
import {LAND, SEA} from "../../../../constants/config";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../../constants/permissions";

export default function AddDocument({isOpen, setIsOpen, setDetail, isSeaType}) {

    const { loggedCompany, isExporter } = useSelector((state) => state.user);
    const { register, watch, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    let {id}  = useParams();
    const { t } = useTranslation();


    // containers type list
    const { documentTypesList, isDocumentTypesListLoading } = useSelector((state) => state.config);



    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const storeData = async (data) => {
        if(loggedCompany){
            setLoading(true);
            (isExporter ? postDocument({shipping_id: id, data}) :
            postDocumentImporter({shipping_id: id, data}))
                .then(response => {
                    setIsOpen(false);
                    setDetail(response);
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };


    useEffect(() => {
        dispatch(fetchDocumentTypes());
    }, [dispatch]);

    const onSubmit = async (data) => {
        cleanData(data);
        await storeData(data);
    };

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.DOCUMENT, permissionType.CREATE)]} renderBaseOnFail={true}>
            <FormPopup title={t("app.document.add_document")} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 gap-y-4">

                            <Controller
                                name="document_type"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                    <InputSelect
                                        label={t("app.document.document_type")}
                                        name={name}
                                        options={documentTypesList.filter(d => (d.type_code === null || d.type_code === (isSeaType ? SEA : LAND)) )}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isLoading={isDocumentTypesListLoading}
                                        isDisabled={isDocumentTypesListLoading}
                                        errors={error}
                                    />
                                )}
                            />

                            <InputText
                                id={'file_note'}
                                type={'text'}
                                errors={errors.file_note}
                                input={{...register("file_note", { required: false })}}
                                label={t("app.document.document_note")}
                            />

                            <InputCheckbox
                                label={t("app.document.upload_document_question")}
                                description={''}
                                id={'upload_document'}
                                input={{...register("upload_document", { required: false })}}
                            />

                            {
                                watch('upload_document') && (
                                    <UploadFile
                                        onUpload={(data) => {
                                            setValue('file', {media_url: data.media_url});
                                            setValue('file_name', data.detail.name);
                                        }}
                                        onCancel={(r) => {
                                            if(r){
                                                setValue('file', undefined);
                                                setValue('file_name', undefined);
                                            }
                                        }}
                                        onException={(e) => setException(e)}
                                        isLoading={false}
                                        label={t("app.document.upload_document")}
                                    />
                                )
                            }

                        </div>

                    </div>

                    {/* Warning */}
                    {exception && (
                        <Warning
                            message={exception}
                        />
                    )}

                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.document.add_document")}
                        />
                    </div>
                </form>
            </FormPopup>
        </BasePermissionWrapper>
    )
}
