import PageHeader, {ImportButton, WhiteButton} from "../../../components/layout/page-header";
import React, {useState} from "react";
import EditQuotation from "../edit-quotation";
import {useTranslation} from "react-i18next";
import Initials from "../../../components/common/avatar/initials";
import NumberFormat from "react-number-format";
import {getCustomFormat} from "../../../utils/timeUtils";
import {CheckIcon, DocumentTextIcon, EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import  {
    hasSubscriptionPermission
} from "../../../components/partials/restricted/subscription-wrapper";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
    pg,
    pt,
    sp
} from "../../../constants/permissions";
import {QUOTATION_REPORT} from "../../../constants/config";
import {useSelector} from "react-redux";
import {StatusList} from "../../../components/common/alert/status-list";
import {putQuotationStatus} from "../../../api/quotation";
import toast from "react-hot-toast";
import SingleReport from "../../../components/partials/report/single-report";
import PriceRange from "../../../components/common/card/price-range";
import CircleBtn from "../../../components/common/button/circle-btn";
import ConfirmTextPopup from "../../../components/common/popup/confirm-text-popup";
import ReportViewer from "../../../components/partials/report/report-viewer";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";
import {getPathParam} from "../../../utils/converter";
import {COMPANY, CONFIRMED, QUOTATION, STATUS} from "../../../api/endpoints";
import {hasPermission} from "../../../components/partials/restricted/base-permission-wrapper";
import UsersToolbar from "../../../components/common/card/users-toolbar";
import UserInitial from "../../../components/common/table/user-initial";
import RequiredPopup from "../../../components/common/popup/required-popup";

const QuotationHeader = ({detail, setDetail, importType, setRefresh, lastImport, setLastImport, containerId, isHideSensitiveData, ontHideSensitiveData, isConfirmedPackaging}) => {

    const { t } = useTranslation();
    const [isEdit, setEdit] = useState(false);
    const [isOpenReportGenerate, setOpenReportGenerate] = useState(false);
    const [isOpenReportView, setOpenReportView] = useState(false);

    const {subscriptions, permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((state) => state.user);
    const { company } = useSelector((state) => state.userCompany);


    const canEdit =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.EDIT, companyType, true);
    const isActiveMarginData = hasPermission(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_MARGIN_DATA, companyType, true);
    const isActiveGenerateDocument = hasPermission(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_GENERATE_DOCUMENT, companyType, true);
    const isActiveConfirm = hasPermission(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_CONFIRM, companyType, false);


    const isSubImportActive = hasSubscriptionPermission(subscriptions, sp.QUOTATION_QUOTATION_PACKAGE);



    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false);

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [openType, setOpenType] = useState('');
    const [isOpenConfirmPackaging, setOpenConfirmPackaging] = useState(false);
    const [isOpenConfirmCheck, setOpenConfirmCheck] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    // Required Fields
    const [isOpenRequird, setOpenRequird] = useState(false);


    const isExporter = detail?.can_edit ?? false;

    const onChangeStatus = async (status) => {
        if (loggedCompany) {
            setLoading(true);
            putQuotationStatus({ company_id: loggedCompany.id, quotation_id: detail.id, status: status })
                .then((response) => {
                    setLoading(false);
                    toast.success(t("app.quotations.update_success"))
                    setDetail(response);
                })
                .catch((e) => {
                    setLoading(false);
                });
        }
    };








    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={(detail.importer_file && detail.importer_file.reference) ? {reference: detail.importer_file.reference} : false}
                title={(detail.quotation_reference) ? (detail.quotation_reference) : t("app.document.preview_file")}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />


            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <PageHeader
                    taskContext={'quotation'}
                    taskContextId={detail.id}
                    isActiveEdit={(isSubImportActive && canEdit) && (detail?.is_closed ?? false) === false && isExporter}
                    onClickEdit={() => {
                        if(isExporter){
                            setEdit(true);
                        }
                    }}
                    title={t("app.quotations.quotation") + (detail ? ' #'+detail?.quotation_reference ?? '' : '')}
                    importGroup={[getPermission(pg.QUOTATION, pt.IMPORT)]}
                    onNeedRefresh={(r) => {
                        setLastImport(null);
                        setRefresh(r);
                    }}
                    importEntityId={containerId}
                    importType={importType}
                    lastImport={lastImport}
                >


                    <div className="flex items-center">


                        {isExporter && (
                            <UsersToolbar users={(detail?.users ?? [])} isActive={(detail?.users ?? []).length > 1}  extraData={getCustomFormat(detail.quotation_date, 'DD/MM/YYYY')}className={'relative'}>
                                <div className="flex items-center space-x-2 mr-3">
                                    <div
                                        className={(detail?.users ?? []).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                                        {(detail.users.length >= 4 ? detail.users.slice(0, 4) : detail.users).map((user, index) => (
                                            index === 0 ? (
                                                <UserInitial user={user} ringClass={" ring-yellow-400 "}/>
                                            ) : (
                                                <UserInitial user={user}/>
                                            )

                                        ))}

                                        {
                                            detail.users.length === 1 && (
                                                <div className="text-left ml-1">

                                                    <div className="truncate text-sm text-gray-900">
                                                        {detail.users[0]?.fullname ?? ''}
                                                    </div>
                                                    <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                                                        {t("app.quotations.created_on", {date: getCustomFormat(detail.quotation_date, 'DD/MM/YYYY')})}
                                                    </div>
                                                </div>
                                            )
                                        }




                                    </div>
                                    {detail.users.length > (detail.users.length >= 4 ? detail.users.slice(0, 4) : detail.users).length ? (
                                        <span className="flex-shrink-0 text-xs font-medium leading-5">
                                +{detail.users.length - (detail.users.length >= 4 ? detail.users.slice(0, 4) : detail.users).length}
                              </span>
                                    ) : null}
                                </div>
                            </UsersToolbar>
                        )}

                        {
                            (isExporter && isActiveMarginData) && (
                                <div className="mr-3">
                                    {
                                        isHideSensitiveData ? (
                                            <CircleBtn
                                                icon={EyeIcon}
                                                onClick={() => {
                                                    ontHideSensitiveData(false);
                                                }}
                                                extraClass={"bg-white text-gray-800 border border-gray-200 shadow-md  hover:bg-gray-200"}
                                            />

                                        ) : (
                                            <CircleBtn
                                                icon={EyeOffIcon}
                                                onClick={() => {
                                                    setOpenConfirm(true);
                                                }}
                                                extraClass={"bg-white text-gray-800 border border-gray-200 shadow-md  hover:bg-gray-200"}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }

                        {
                            canEdit && (
                                <StatusList
                                    isLoading={isLoading}
                                    status={detail?.status ?? 'draft'}
                                    isEditable={isExporter}
                                    onChangeStatus={(r) => {
                                        if (detail.can_confirm === false && r === 'confirmed' && company.product_manage_status){
                                            setOpenRequird(true);
                                        }else{
                                            if (r !== 'draft' && detail.can_view) {
                                                const detailData = {
                                                    title: t("app.quotations.status_confirm_title"),
                                                    message: t("app.quotations.status_confirm_description", { status: t("app.quotations.status_" + r) }),
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, QUOTATION, detail.id, STATUS, r]),
                                                };
                                                setConfirmDetail(detailData);
                                                setOpenConfirmCheck(true);
                                            } else {
                                                if (!isConfirmedPackaging) {
                                                    setOpenConfirmPackaging(true);
                                                    setOpenType(r);
                                                } else {
                                                    onChangeStatus(r)
                                                }
                                            }
                                        }
                                    }}
                                />
                            )
                        }

                    </div>


                    {(isOpenConfirm && isExporter && isActiveMarginData) && (
                        <ConfirmTextPopup
                            isOpen={isOpenConfirm}
                            setIsOpen={setOpenConfirm}
                            detail={{title: t("app.quotations.hide_title"), message: t("app.quotations.hide_description")}}
                            onConfirmed={(r) => {
                                if(r){
                                    ontHideSensitiveData(true);
                                }else{
                                    ontHideSensitiveData(false);
                                }
                                setOpenConfirm(false);
                            }}
                        />
                    )}



                    {(isOpenConfirmPackaging && isExporter) && (
                        <ConfirmTextPopup
                            isOpen={isOpenConfirmPackaging}
                            setIsOpen={setOpenConfirmPackaging}
                            detail={{title: t("app.quotations.packing_title"), message: t("app.quotations.packing_description")}}
                            onConfirmed={(r) => {
                                if(openType === 'doc'){
                                    setOpenReportGenerate(true);
                                }
                                if(openType !== 'doc' && openType !== ''){
                                    onChangeStatus(openType)
                                }
                                setOpenConfirmPackaging(false);
                            }}
                        />
                    )}


                </PageHeader>
            </div>


            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-5">
                <div className="flex flex-col col-span-full bg-white shadow-sm rounded-sm">
                    <div className="px-5 py-6">

                        <div className="md:flex md:justify-between md:items-center">

                            <div className="md:flex md:items-center mb-4 md:mb-0">
                                <div className="flex items-center mb-4 md:mb-0">
                                    <div className="mr-4">
                                        <Initials
                                            color={detail?.customer?.color ?? ''}
                                            name={detail?.customer?.business_name?? ''}
                                            iconClass={' h-16 w-16'}
                                            verifiedIcon={' h-6 w-6'}
                                            isVerified={detail?.customer?.status === 'confirmed' ?? false}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-left flex items-center">
                                            <div>
                                                <div className="text-md font-semibold text-gray-900">
                                                    {detail?.customer?.business_name ?? ''}
                                                </div>

                                                <div className="text-2xl font-bold text-indigo-800">
                                                    <NumberFormat
                                                        value={detail?.totals?.total_discounted ?? 0}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        suffix={ " " + (detail?.currency?.currency_symbol ?? '')}
                                                    />
                                                </div>
                                                <div className="text-xs font-semibold text-gray-500">
                                                    {isExporter ? getCustomFormat(detail?.quotation_date ?? null, 'DD/MM/YYYY') : getCustomFormat(detail?.expire_date ?? null, 'DD/MM/YYYY')}
                                                </div>
                                            </div>

                                            <div>
                                                {
                                                    (detail?.totals?.total_purchase_price ?? 0 ) > 0 && isHideSensitiveData && (
                                                        <PriceRange
                                                            symbol={(detail?.currency?.currency_symbol ?? '')}
                                                            purchasePrice={detail.totals.total_purchase_price}
                                                            price={detail?.totals?.total_discounted ?? 0}
                                                            isLeft={true} showMargin={true} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div>

                                {
                                    !isExporter ? (
                                        <>
                                            {
                                                (detail.importer_file) && (
                                                    <WhiteButton
                                                        extraClassName={"mr-2"}
                                                        onClick={() => {
                                                            setOpenReportView(true);
                                                        }}
                                                        icon={
                                                            <DocumentTextIcon
                                                                className="w-4 h-4"
                                                                aria-hidden="true"
                                                            />
                                                        }
                                                        text={t("app.report.view_file")}
                                                    />
                                                )
                                            }


                                            {
                                                (!detail.confirmed_date && detail?.status === 'review' && isActiveConfirm) && (
                                                        <ImportButton
                                                            onClick={() => {
                                                                const detailData = {
                                                                    title: t("app.quotations.import_confirm_title"),
                                                                    message: t("app.quotations.import_confirm_description"),
                                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, QUOTATION, detail.id, CONFIRMED ]),
                                                                };

                                                                setConfirmDetail(detailData);
                                                                setOpenConfirmCheck(true);
                                                            }}
                                                            icon={
                                                                <CheckIcon
                                                                    className="w-4 h-4"
                                                                    aria-hidden="true"
                                                                />
                                                            }
                                                            text={t("app.quotations.confirm_document")}
                                                        />
                                                )
                                            }

                                        </>


                                    ) : (
                                        isActiveGenerateDocument && (
                                            <WhiteButton
                                                extraClassName={"mr-2"}
                                                onClick={() => {
                                                    if(!isConfirmedPackaging){
                                                        setOpenConfirmPackaging(true);
                                                        setOpenType('doc');
                                                    }else{
                                                        setOpenReportGenerate(true);
                                                    }
                                                }}
                                                icon={
                                                    <DocumentTextIcon
                                                        className="w-4 h-4"
                                                        aria-hidden="true"
                                                    />
                                                }
                                                text={t("app.container.generate_file")}
                                            />
                                        )

                                    )
                                }






                            </div>
                        </div>


                        {
                            isExporter && detail.creator_confirmed_date && (
                                <div className={" text-blue-1000 font-medium text-xs flex items-center mt-3"} >
                                    <CheckIcon className={"w-4 h-4 mr-1 flex-shrink-0"} />

                                    <p>
                                        {
                                            t("app.quotations.confirmed_document", {
                                                date: getCustomFormat(detail.creator_confirmed_date, 'DD/MM/YYYY'),
                                                time: getCustomFormat(detail.creator_confirmed_date, 'HH:mm')
                                            })
                                        }

                                        {
                                            detail.confirmed_date && (
                                                t("app.quotations.second_confirmed_document", {
                                                    date_confirm: getCustomFormat(detail.confirmed_date, 'DD/MM/YYYY'),
                                                    user: detail?.customer?.business_name ?? '',
                                                    time: getCustomFormat(detail.confirmed_date, 'HH:mm')
                                                })
                                            )
                                        }.
                                    </p>

                                </div>
                            )
                        }


                        {
                            isExporter && !detail.creator_confirmed_date && detail.confirmed_date && (
                                <div className={" text-blue-1000 font-medium text-xs flex items-center mt-3"} >
                                    <CheckIcon className={"w-4 h-4 mr-1 flex-shrink-0"} />
                                    <p>
                                        {
                                            t("app.quotations.user_confirmed_document", {
                                                date_confirm: getCustomFormat(detail.confirmed_date, 'DD/MM/YYYY'),
                                                user: detail.customer ? (detail.customer.business_name + ' ') : '',
                                                time: getCustomFormat(detail.confirmed_date, 'HH:mm')
                                            })
                                        }.
                                    </p>
                                </div>
                            )
                        }


                        {
                            !isExporter && detail.confirmed_date && (
                                <div className={" text-blue-1000 font-medium text-xs flex items-center mt-3"} >
                                    <CheckIcon className={"w-4 h-4 mr-1 flex-shrink-0"} />

                                    <p>
                                        {
                                            t("app.quotations.confirmed_document", {
                                                date: getCustomFormat(detail.confirmed_date, 'DD/MM/YYYY'),
                                                time: getCustomFormat(detail.confirmed_date, 'HH:mm')
                                            })
                                        }

                                        {
                                            detail.creator_confirmed_date && (
                                                t("app.quotations.second_confirmed_document", {
                                                    date_confirm: getCustomFormat(detail.creator_confirmed_date, 'DD/MM/YYYY'),
                                                    time: getCustomFormat(detail.creator_confirmed_date, 'HH:mm'),
                                                    user: detail?.customer?.business_name ?? ''
                                                })
                                            )
                                        }.
                                    </p>

                                </div>
                            )
                        }


                        {
                            !isExporter && detail.creator_confirmed_date && !detail.confirmed_date && (
                                <div className={" text-blue-1000 font-medium text-xs flex items-center mt-3"} >
                                    <CheckIcon className={"w-4 h-4 mr-1 flex-shrink-0"} />
                                    <p>
                                        { t("app.quotations.user_confirmed_document", {
                                            date_confirm: getCustomFormat(detail.creator_confirmed_date, 'DD/MM/YYYY'),
                                            user: detail.customer ? (detail.customer.business_name + ' ') : '',
                                            time: getCustomFormat(detail.creator_confirmed_date, 'HH:mm')
                                        })}.
                                    </p>
                                </div>
                            )
                        }




                    </div>
                </div>
            </div>




            {canEdit && (
                <EditQuotation
                    isOpen={isEdit}
                    setIsOpen={() => {
                        setEdit(false);
                    }}
                    detail={detail}
                    setDetail={(r) => {
                        setDetail(r);
                    }}
                />
            )}



            {isOpenConfirmCheck && (
                <ConfirmPopup
                    isOpen={isOpenConfirmCheck}
                    setIsOpen={() =>{
                        setOpenConfirmCheck(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        setDetail(r);
                    }}
                />
            )}

            <SingleReport
                isActiveForm={isExporter}
                isOpen={isOpenReportGenerate}
                setIsOpen={(r) => {
                    setOpenReportGenerate(r);
                }}
                isSpecific={true}
                isDynamic={true}
                isPersonalized={true}
                reportDetails={QUOTATION_REPORT}
            />

            {isOpenRequird && (
                <RequiredPopup
                    title={t("app.quotations.required_error_title")}
                    description={t("app.quotations.required_error_description")}
                    isOpen={isOpenRequird}
                    setIsOpen={() => {
                        setOpenRequird(false);
                    }}
                    requireds={[]}
                />
            )}


        </>



    )
};
export default QuotationHeader;
