import React, {useState} from 'react'
import SlidePanel from "../../../../../../../../components/layout/slide-panel";
import {Warning} from "../../../../../../../../components/common/alert/banner";
import SearchProduct from "./search-product";
import {postContainerPacking, postContainerSubPacking} from "../../../../../../../../api/container";
import {useParams} from "react-router-dom";
import PackagingConfiguration from "./packaging-configuration";
import {useTranslation} from "react-i18next";

const searchProduct = 'search-product';
const packagingConfiguration  = 'packaging-configuration';


const Packaging = ({isOpen, setIsOpen, setDetail, groupDetail, subGroupDetail}) => {

    let {id, container_id}  = useParams();
    const { t } = useTranslation();

    const [step, setStep] = useState(searchProduct);
    const [exception, setException] = useState(false);

    const [selectedProductList, setSelectedProductList] = useState([]);
    const [addInsideBox, setAddInsideBox] = useState(false);

    const [isCreateBoxLoading, setCreateBoxLoading] = useState(false);

    const storeProduct = () => {

        let data = {container_packages: []};
        // eslint-disable-next-line
        selectedProductList.map((item) => {

            let details = {};
            details['box'] = item.need_box;
            details['packaging_type'] = {id: item.packaging_type.id} ;
            details['packaging'] = {
                length: item?.length ?? 0,
                width:  item?.width ?? 0,
                radius: item?.radius ?? 0,
                height: item?.height ?? 0
            };
            details['products'] = [];

            // eslint-disable-next-line
            item.products.map(product => {
                details['products'].push({product_id: product.id, quantity: product.quantity});
            })
            data['container_packages'].push(details);
        });

        if (data && groupDetail) {
            setCreateBoxLoading(true);
            setException(false);
            if(subGroupDetail){
                postContainerSubPacking({ shipping_id: id, container_id: container_id, group_id: groupDetail.id, sub_group_id: subGroupDetail.id, data })
                .then((response) => {
                    setDetail(response);
                    setCreateBoxLoading(false);
                    clean();
                    setIsOpen(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setCreateBoxLoading(false);
                });
            }else{
                postContainerPacking({ shipping_id: id, container_id: container_id, group_id: groupDetail.id, data })
                .then((response) => {
                    setDetail(response);
                    setCreateBoxLoading(false);
                    clean();
                    setIsOpen(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setCreateBoxLoading(false);
                });
            }

        }
    };

    const clean = () => {
        setStep(searchProduct);
        setException(false)
        setSelectedProductList([]);
        setAddInsideBox(false);
    };



    return (
        <SlidePanel title={t("app.container.add_packaging")}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    size='max-w-7xl'
                    setClean={() => {
                        clean();
                    }}
        >
            <div className="space-y-6 pt-6 pb-5">

                {
                    step === searchProduct && (
                        <SearchProduct
                            onChangeStep={() => {
                                setStep(packagingConfiguration);
                            }}
                            groupDetail={subGroupDetail ? subGroupDetail : groupDetail}
                            addInsideBox={addInsideBox}
                            selectedProductList={selectedProductList}
                            onSelectedProductList={(p) => {
                                if(addInsideBox !== false){
                                    let filtered = [...selectedProductList];
                                    let foundIndex = filtered.findIndex((x) => x.id === addInsideBox);
                                    filtered[foundIndex]['products'] = filtered[foundIndex]['products'].concat(...p);
                                    setSelectedProductList(filtered);
                                }else{
                                    setSelectedProductList(selectedProductList.concat(...p))
                                }

                                setAddInsideBox(false);
                                setStep(packagingConfiguration);
                            }}
                        />
                    )
                }


                {
                    step === packagingConfiguration && (
                        <PackagingConfiguration
                            onChangeStep={() => {
                                setStep(searchProduct);
                            }}
                            onSave={() => {
                                storeProduct()
                            }}
                            isLoading={isCreateBoxLoading}
                            selectedProductList={selectedProductList}
                            onChangeValue={(id, value, type) => {
                                let filtered = [...selectedProductList];

                                let foundIndex = filtered.findIndex((x) => x.id === id);
                                filtered[foundIndex][type] = value

                                setSelectedProductList(filtered)

                            }}
                            onDelete={(id, item) => {
                                let filtered = [...selectedProductList];
                                let foundIndex = filtered.findIndex((x) => x.id === id);

                                // remove internal product
                                filtered[foundIndex]['products'] = filtered[foundIndex]['products'].filter(function( obj ) {
                                    return obj.id !== item.id;
                                });

                                if(filtered[foundIndex]['products'].length === 0){
                                    filtered =  selectedProductList.filter(function( obj ) {
                                        return obj.id !== id;
                                    });

                                }
                                setSelectedProductList(filtered);
                            }}
                            onSetQuantity={(id, item, value) => {
                                let filtered = [...selectedProductList];

                                let foundIndex = filtered.findIndex((x) => x.id === id);

                                let prodIndex = filtered[foundIndex]['products'].findIndex((x) => x.id === item.id);
                                filtered[foundIndex]['products'][prodIndex]['quantity'] = value

                                setSelectedProductList(filtered)

                            }}
                            onAddInsideBox={(id) =>{
                                setAddInsideBox(id);
                                setStep(searchProduct);
                            }}
                        />
                    )
                }




                {/* Warning */}
                {exception && <Warning message={exception} />}


            </div>
        </SlidePanel>

    );
};


export default Packaging;
