import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonSubmit from "../../components/common/form/button-submit";
import { getElement, putElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { BOARD, NOTIFICATION_SETTINGS } from "../endpoints";
import { Warning } from "../../components/common/alert/banner";
import BoardPopup from "../../components/board/board-popup";
import { useSelector } from "react-redux";
import InputToggle from "../../components/common/form/input-toggle";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const BoardManageNotification = ({ isOpen, setIsOpen, boardData, onUpdateBoard  }) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false)
    const [isLoadingUpdate, setLoadingUpdate] = useState(false)
    const [exception, setException] = useState(false)

    const {
        handleSubmit,
        setValue,
        control
    } = useForm();
    const { t } = useTranslation();



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function feactUserNotifications() {
            setLoading(true);
            if (boardData && isOpen) {
                try {
                    const res = await getElement(getPathParam([BOARD, boardData.id, NOTIFICATION_SETTINGS]), signal);
                    for (const k in res) {
                        setValue(k, res[k]);
                    }
                } catch (e) {
                    //setStatuses([]);
                } finally {
                    setLoading(false);
                }
            }
        }
        
        feactUserNotifications(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, loggedCompany]);


    
    
    const onUpdate = async (data) => {
        setLoadingUpdate(true);
        setException(false);
        putElement(getPathParam([BOARD, boardData.id, NOTIFICATION_SETTINGS]), data)
            .then(response => {
                setIsOpen(false);
            }).catch(e => {
                setException(e.message);
                setLoadingUpdate(false);
            });
    };



    return (

        <>

            <BoardPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                canClose={true}
                setClean={() => {
                
                }}
            >
                <div className={classNames(
                    " -mt-4 mb-3 bg-white"
                    )}>

                    <div className="px-4 sm:px-6">
                        
                        <>
                            {/* TITLE */}
                            <div className="flex items-center justify-between mt-3">
                                <div className="flex items-start space-x-5">
                                    <div className="pt-1.5">
                                        <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.edit_notification")}</h1>
                                    </div>
                                </div>
                            </div>

                            <LoaderWrapper isLoading={isLoading}>

                                <form onSubmit={handleSubmit(onUpdate)} className={' border-none pb-5 mt-5'}>
                                    <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">



                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_new_comment"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_new_comment")}
                                                        description={t("app.board.notify_new_comment_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_expire_date"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_expire_date")}
                                                        description={t("app.board.notify_expire_date_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_remove_task"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_remove_task")}
                                                        description={t("app.board.notify_remove_task_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_new_attachment"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_new_attachment")}
                                                        description={t("app.board.notify_new_attachment_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_new_task"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_new_task")}
                                                        description={t("app.board.notify_new_task_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>



                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_move_task"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_move_task")}
                                                        description={t("app.board.notify_move_task_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>




                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_archive_task"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_archive_task")}
                                                        description={t("app.board.notify_archive_task_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="col-span-4 md:col-span-2">
                                            <Controller
                                                name={"notify_mention"}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <InputToggle
                                                        label={t("app.board.notify_mention")}
                                                        description={t("app.board.notify_mention_description")}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={value}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>

                                        {/* Warning */}
                                        {exception && (
                                            <div className="col-span-4 md:col-span-2">
                                                <Warning message={exception} />
                                            </div>
                                        )}


                                        <div className={classNames(
                                            "col-span-4 md:col-span-2  mt-6",
                                        )}>

                                            <div className="flex flex-col items-center">
                                                <ButtonSubmit
                                                    btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                    isLoading={isLoadingUpdate}
                                                    isFullWith={true}
                                                    label={t("app.board.board_edit_btn")}
                                                />
                                            </div>


                                        </div>

                                    </div>
                                </form>

                            </LoaderWrapper>

                        </>
                    </div>

                </div>


            </BoardPopup>
        </>


    )
};



export default BoardManageNotification;