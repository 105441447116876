import React, {Fragment, useEffect, useState} from 'react';
import BarChart from '../charts/BarChart01';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../common/popup/form-popup";
import InputFormattedNumber from "../common/form/input-formatted-number";
import InputSubmit from "../common/form/input-submit";

function ShippingStatistics({data, height, width, isEditing, setIsOpen, dashboardWidget, onFinalSubmit}) {


  const getMonth = (n) => {
    let month = moment().month(n).format("MMM");
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  const labels = [getMonth(0),getMonth(1), getMonth(2), getMonth(3), getMonth(4), getMonth(5), getMonth(6),
    getMonth(7), getMonth(8), getMonth(9), getMonth(10), getMonth(11)];


  const getFormattedData = (list) => {
    let result = [];
    // eslint-disable-next-line
    labels.map((item, index) => {
      // eslint-disable-next-line
      let val = list.find(r => r.month == (index + 1))?.month_value ?? 0;
      result.push(parseFloat(val))
    });
    return result;
  }


  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
      // Light blue bars
      {
        label: data.labels.last_year,
        data: getFormattedData(data.last_year),
        backgroundColor: tailwindConfig().theme.colors.blue[400],
        hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      // Blue bars
      {
        label: data.labels.this_year,
        data: getFormattedData(data.this_year),
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  });


  useEffect(() => {
    setChartData({
      labels: labels,
      datasets: [
        // Light blue bars
        {
          label: data.labels.last_year,
          data: getFormattedData(data.last_year),
          backgroundColor: tailwindConfig().theme.colors.blue[400],
          hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
          barPercentage: 0.66,
          categoryPercentage: 0.66,
        },
        // Blue bars
        {
          label: data.labels.this_year,
          data: getFormattedData(data.this_year),
          backgroundColor: tailwindConfig().theme.colors.indigo[500],
          hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
          barPercentage: 0.66,
          categoryPercentage: 0.66,
        },
      ],
    })
    // eslint-disable-next-line
  }, [data]);


  return (
    <>
      <BarChart data={chartData} width={width} height={height} isNumber={true} />
      <ChartEdit
          isEditing={isEditing}
          setIsOpen={setIsOpen}
          dashboardWidget={dashboardWidget}
          onFinalSubmit={(r) => {
            onFinalSubmit(r);
          }}
      />
    </>
  );
}

function ChartEdit({isEditing, setIsOpen, dashboardWidget, onFinalSubmit}) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setValue
  } = useForm();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const lastYear = currentYear - 1;


  const onSubmit = (data) => {

    const output = {
      id: dashboardWidget.id,
      configuration: {
        shipping_totals: {
          last_year: [],
          this_year: []
        }
      }
    };

    for (const key in data) {
      const [year, month] = key.split("-");
      const month_value = data[key];

      const entry = {
        month_value: month_value !== undefined ? month_value.toString() : null,
        month: month,
        year: year
      };

      if (parseInt(year) === lastYear) {
        output.configuration.shipping_totals.last_year.push(entry);
      } else if (parseInt(year) === currentYear) {
        output.configuration.shipping_totals.this_year.push(entry);
      }
    }
    onFinalSubmit(output)
  };


  useEffect(() => {

  // eslint-disable-next-line
    (dashboardWidget?.configuration?.shipping_totals?.last_year ?? []).map((data) => {
      if(lastYear === parseInt(data.year) && data.month_value){
        setValue(lastYear+"-"+data.month, data.month_value);
      }
    });
  // eslint-disable-next-line
    (dashboardWidget?.configuration?.shipping_totals?.this_year ?? []).map((data) => {
      if(currentYear === parseInt(data.year) && data.month_value){
        setValue(currentYear+"-"+data.month, data.month_value);
      }
    });



  // eslint-disable-next-line
  }, [dashboardWidget, setValue]);

  const months = [
    {title: t("app.common.january"), number: 1},
    {title: t("app.common.february"), number: 2},
    {title: t("app.common.march"), number: 3},
    {title: t("app.common.april"), number: 4},
    {title: t("app.common.may"), number: 5},
    {title: t("app.common.june"), number: 6},
    {title: t("app.common.july"), number: 7},
    {title: t("app.common.august"), number: 8},
    {title: t("app.common.september"), number: 9},
    {title: t("app.common.october"), number: 10},
    {title: t("app.common.november"), number: 11},
    {title: t("app.common.december"), number: 12},
  ];


  return (
      <FormPopup
          title={dashboardWidget.dashboard_widget.name}
          isOpen={isEditing}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1 max-h-80	overflow-y-scroll p-1">


            {months.map((month, index) => (
                <Fragment key={index}>

                  <Controller
                      name={lastYear+"-"+month.number}
                      rules={{ required: false }}
                      control={control}
                      render={({field: { onChange, value, name }, fieldState: { error }}) => (
                          <InputFormattedNumber
                              label={month.title  + '('+lastYear+')'}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              prefix={''}
                              suffix={''}
                              errors={error}
                          />
                      )}
                  />



                  <Controller
                      name={currentYear+"-"+month.number}
                      rules={{ required: false }}
                      control={control}
                      render={({field: { onChange, value, name }, fieldState: { error }}) => (
                          <InputFormattedNumber
                              label={month.title  + '('+currentYear+')'}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              prefix={''}
                              suffix={''}
                              errors={error}
                          />
                      )}
                  />

                </Fragment>
            ))}
          </div>

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                isLoading={false}
                label={t("app.form.save")}
            />
          </div>

        </form>

      </FormPopup>
  );
}

export default ShippingStatistics;
