export default function DeliveryIcon(props) {
    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="transparent" xmlns="http://www.w3.org/2000/svg"   {...props}>
            <path fill={'currentColor'} d="M7.95554 20.1124C8.12249 20.7211 8.74768 21.0783 9.35194 20.9102C9.95621 20.742 10.3107 20.1121 10.1438 19.5033L7.95554 20.1124ZM1.43887 4.04191C0.834792 3.87307 0.209209 4.22953 0.0416064 4.83808C-0.125997 5.44665 0.22784 6.07687 0.831932 6.24572L1.43887 4.04191ZM27.15 20.8149C27.7568 20.6562 28.1209 20.0318 27.9632 19.4205C27.8055 18.8093 27.1859 18.4426 26.5791 18.6015L27.15 20.8149ZM12.0291 23.6103C12.4977 25.319 11.4691 27.1216 9.64706 27.5987L10.2181 29.8123C13.2103 29.0289 15.0387 25.9966 14.2173 23.0013L12.0291 23.6103ZM9.64706 27.5987C7.81312 28.0789 5.97282 27.0065 5.5003 25.2835L3.31204 25.8924C4.12951 28.8734 7.2377 30.5926 10.2181 29.8123L9.64706 27.5987ZM5.5003 25.2835C5.03172 23.5747 6.06027 21.7721 7.88235 21.295L7.31133 19.0814C4.3191 19.8648 2.49063 22.8972 3.31204 25.8924L5.5003 25.2835ZM7.88235 21.295C9.71627 20.8149 11.5566 21.8872 12.0291 23.6103L14.2173 23.0013C13.3999 20.0204 10.2917 18.3013 7.31133 19.0814L7.88235 21.295ZM10.1438 19.5033L6.88639 7.62516L4.69814 8.23418L7.95554 20.1124L10.1438 19.5033ZM4.01739 4.76261L1.43887 4.04191L0.831932 6.24572L3.41045 6.96641L4.01739 4.76261ZM6.88639 7.62516C6.50328 6.22818 5.40508 5.15048 4.01739 4.76261L3.41045 6.96641C4.05457 7.14645 4.53427 7.63664 4.69814 8.23418L6.88639 7.62516ZM13.4087 24.4126L27.15 20.8149L26.5791 18.6015L12.8376 22.199L13.4087 24.4126Z" />
            <path stroke={'currentColor'} d="M26.3235 6.08005C25.5415 3.32389 25.1506 1.9458 24.001 1.319C22.8517 0.692198 21.3924 1.06147 18.4742 1.79997L15.3789 2.58328C12.4606 3.32179 11.0014 3.69104 10.3378 4.77671C9.6741 5.86236 10.0651 7.24043 10.847 9.99659L11.6764 12.92C12.4583 15.6761 12.8493 17.0542 13.9988 17.6811C15.1483 18.3078 16.6076 17.9385 19.5258 17.2L22.6211 16.4168C25.5395 15.6783 26.9985 15.309 27.6621 14.2232C28.0268 13.627 28.0731 12.9425 27.9112 11.9897"  strokeWidth="2" strokeLinecap="round"/>
        </svg>



    );
}
