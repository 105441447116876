import React from "react";
import LoaderWrapper from "../loader/loader-wrapper";
import Nodata from "../../../resources/images/no-data.gif";
import {useTranslation} from "react-i18next";

const List = ({ children, isLoading, dataLength }) => {
    const { t } = useTranslation();

    return (
    <ul className=" border-t border-gray-200 divide-y divide-gray-100">
      <LoaderWrapper isLoading={isLoading}>{children}</LoaderWrapper>
      {dataLength === 0 && !isLoading && (
          <div className="lg:w-1/2 mx-auto px-4 py-3 text-center">
              <div className="text-center p-10">
                  <img src={Nodata} className="-mt-8 mx-auto"  alt="not-found"  />
                  <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                      {t("app.common.not_found")}
                  </h1>
              </div>
          </div>
      )}
    </ul>
  );
};

export default List;
