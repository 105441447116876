// file that contains actions to be dispatched according to certain state found in the app
// consider splitting if it gets to large

import { store } from "./rootReducer";
import { processPermissionList, userCompanyStatus } from "./userCompanyReducer";

// after loading the permissions we dispatch a processing action
export const processPermissions = () => {
  const currentStatus = store.getState().userCompany.status;
  if (currentStatus === userCompanyStatus.NEED_PROCESSING) {
    store.dispatch(processPermissionList());
  }
};

export const toSubscribeOnLoad = [
  processPermissions
];
