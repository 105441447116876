
export function setData(key, value){
    localStorage.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(value)))));
}
export function getData(key){
    if(localStorage.getItem(key)){
        let data = localStorage.getItem(key);
        return JSON.parse(decodeURIComponent(escape(atob(data))));
    }
    return false;
}

export const clearData = (key) => localStorage.removeItem(key);


// import {setCookie, getCookie, eraseCookie} from '../constants/cookie';
/*
export const setToken = token => setCookie('token', token, 10);
export const getToken = () =>  getCookie('token');
export const clearToken = () => eraseCookie('token');
export const isAuthenticated = () => getCookie('token') !== null;
*/
