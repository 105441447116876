import React, { useEffect, useState } from "react";

import PageHeader, { ActionButton } from "../../components/layout/page-header";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {PencilAltIcon} from "@heroicons/react/outline";
import TaskCard from "../../components/common/card/task-card";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {getTasks, putTasks} from "../../api/task";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import AddTask from "./add-task";
import RemovePopup from "../../components/common/popup/remove-popup";
import EditTask from "./edit-task";
import toast from "react-hot-toast";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import TaskMessage from "./task-message";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const Tasks = ({entityId = null, entityType = null, typeActive= false, removeActive= null, onRefresh= null}) => {
  const { loggedCompany, detail } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [list, setList] = useState({ list_to_do: [], list_in_completed: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [isOpenEdit, setOpenEdit] = useState(false);
  const [editDetail, setEditDetail] = useState({});


  const [isOpenChat, setOpenChat] = useState(false);
  const [taskDetail, setTaskDetail] = useState(false);


  useEffect(() => {
    if(typeActive && typeActive === 'task'){
      setIsOpen(true);
      removeActive();
    }
    // eslint-disable-next-line
  }, [typeActive]);


  const updateTasks = async (data) => {
    let info = {};
    info['to_do'] = [];
    info['completed'] = [];

    if(data['to_do'] && data['to_do'].length > 0){
      // eslint-disable-next-line
      data['to_do'].map(item => {
        info['to_do'].push({id: item.id })
      })
    }

    if(data['completed'] && data['completed'].length > 0){
      // eslint-disable-next-line
      data['completed'].map(item => {
        info['completed'].push({id: item.id })
      })
    }


    if (loggedCompany) {
      putTasks({ company_id: loggedCompany.id, data: info })
          .then((response) => {
            toast.success(t("app.tasks.update_success"))
          }).catch((e) => {
      });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchTasks() {
      if (loggedCompany) {
        setLoading(true);
        try {
          const res = await getTasks({
            company_id: loggedCompany.id,
            params: {entity_type: entityType, entity_id: entityId},
            signal,
          });
          setList(res);
        } catch (e) {
          setList({ list_to_do: [], list_in_completed: [] });
        } finally {
          setLoading(false);
        }
      }
    }

    fetchTasks();
    return () => {
      controller.abort();
    };
  }, [loggedCompany, entityType, entityId]);

  const  droppableIds = {
    to_do: 'list_to_do',
    //in_progress: 'list_in_progress',
    completed: 'list_in_completed'
  }

  const getList = id => list[droppableIds[id]];

  const onDragEnd = result => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) { return }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
      )


      if (source.droppableId === 'to_do') {
        list['list_to_do'] = items
      } else if (source.droppableId === 'in_progress') {
        list['list_in_progress'] = items
      } else if (source.droppableId === 'completed') {
        list['list_in_completed'] = items
      }
      setList(list);
      updateTasks(list);
    } else {
      const result = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
      )

      updateTasks(result);

      setList({
        list_to_do: result.to_do ? result.to_do : list.list_to_do,
        //list_in_progress: result.in_progress ? result.in_progress : list.list_in_progress,
        list_in_completed: result.completed ? result.completed : list.list_in_completed
      });

    }
  }

  const lists = [
    {
      droppableId: 'to_do',
      listId: 'list_to_do',
      title: t("app.tasks.to_do")
    },
    //{
   //   droppableId: 'in_progress',
  //    listId: 'list_in_progress',
  //    title: t("app.tasks.in_progress")
  //  },
    {
      droppableId: 'completed',
      listId: 'list_in_completed',
      title:  t("app.tasks.completed")
    },
  ]


  return (
  <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_TASK_PERMISSION, permissionType.MENU)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.tasks.task")}>
            <ActionButton
                icon={
                  <PencilAltIcon
                      className="w-4 h-4"
                      aria-hidden="true"
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                }}
                text={t("app.tasks.new_task")}
            />
          </PageHeader>
        </div>
        <div className="max-w-5xl  mx-auto px-4 sm:px-6 md:px-8 relative">

          <LoaderWrapper isLoading={isLoading}>

            <DragDropContext onDragEnd={onDragEnd}>

              {lists.map((items, listIndex) =>
                  <Droppable key={'list-droppable-' + listIndex} droppableId={items.droppableId}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} className={'space-y-2 mt-5'}>
                          <h2 className="grow font-semibold text-slate-800 truncate mb-4">
                            {items.title}
                          </h2>
                          {list[items.listId] && list[items.listId].map((item, index) => (
                              <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        { ...provided.draggableProps }>
                                        <TaskCard
                                            dragHandle={provided.dragHandleProps}
                                            isDragging={snapshot.isDragging}
                                            data={item}
                                            isComplete={items.droppableId  === 'completed'}
                                            onRemove={(removeDetail) => {
                                              setOpenRemove(true);
                                              setRemoveDetail(removeDetail);
                                            }}
                                            isEditable={detail.id === item.user.reference}
                                            onEdit={(data) => {
                                              setOpenEdit(true);
                                              setEditDetail(data);
                                            }}
                                            onChecked={(response) => {
                                              setList(response);
                                              toast.success(t("app.tasks.update_success"))
                                            }}
                                            onOpenChat={(data) => {
                                              setTaskDetail(data);
                                              setOpenChat(true);
                                            }}

                                        />
                                    </div>
                                )}
                              </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                    )}
                  </Droppable>
              )}
            </DragDropContext>
          </LoaderWrapper>

        </div>
      </div>


      {isOpen && <AddTask isOpen={isOpen} setIsOpen={setIsOpen} entityType={entityType} entityId={entityId} onAddTask={(res) => {
        setList(res);
        if(onRefresh){
          onRefresh(true);
        }
      }} />}

      {isOpenEdit && <EditTask isOpen={isOpenEdit} setIsOpen={setOpenEdit} data={editDetail}
                               isEditable={detail.id === editDetail.user.reference}
                               onAddTask={(res) => {
        setList(res);
                                 if(onRefresh){
                                   onRefresh(true);
                                 }
      }} />}

      {isOpenRemove && (
          <RemovePopup
              isOpen={isOpenRemove}
              setIsOpen={setOpenRemove}
              detail={removeDetail}
              setDetail={(res) => {
                setList(res);
                if(onRefresh){
                  onRefresh(true);
                }
              }}
          />
      )}


        <TaskMessage
            isOpen={isOpenChat}
            setIsOpen={setOpenChat}
            taskDetail={taskDetail}
            onRefreshData={(res) => {
              setList(res);
            }}
        />



      </BasePermissionWrapper>
  );
};

export default Tasks;
