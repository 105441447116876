import React from "react";
import LoaderWrapper from "../loader/loader-wrapper";
import Nodata from "../../../resources/images/no-data.gif";
import {useTranslation} from "react-i18next";

const Table = ({ columns, children, isLoading, dataLength, isActiveSelectAll = true, hasCheckbox = false, selectAll, handleSelectAll }) => {
    const { t } = useTranslation();

    return (
    <div className="align-middle inline-block sm:min-w-full lg:max-w-full  overflow-x-auto w-24 min-w-full mx-auto">
      <table className={"min-w-full"}>
        <thead className="bg-solitude-400 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider">
          <tr>
              {(hasCheckbox && isActiveSelectAll) && (
                <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                  {
                    isActiveSelectAll && (
                      <input type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
                    )
                  }
                </th>
            )}
            {columns.map((column) => (
              <th key={column ?? "untitled-voice"} className="px-6 py-3">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        {!isLoading && (
            <>{children}</>
        )}
      </table>

      <LoaderWrapper isLoading={isLoading} />

      {dataLength === 0 && !isLoading && (
        <div className="lg:w-1/2 mx-auto px-4 py-3 text-center">
            <div className="text-center p-10">
                <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                    {t("app.common.not_found")}
                </h1>
            </div>
        </div>
      )}
    </div>
  );
};

export default Table;
