import React from "react";
import PdfIcon from "../../../resources/icons/PdfIcon";
import DocIcon from "../../../resources/icons/DocIcon";
import XlsIcon from "../../../resources/icons/XlsIcon";
import AttentionIcon from "../../../resources/icons/AttentionIcon";
import FileTextIcon from "../../../resources/icons/FileTextIcon";
import {LockClosedIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";

const FileCell = ({ file, onOpenFile = false, canView = true }) => {
    const { t } = useTranslation();


    const getFileType = () => {
        if(file.file){
            let fileType = file.file.reference.split('.').pop();

            if(fileType.toLowerCase() === 'pdf'){
                return { icon: <PdfIcon className={'h-5 w-5 text-red-800'} />, color: 'bg-red-100'};
            }
            if(fileType.toLowerCase() === 'docx'){
                return { icon: <DocIcon className={'h-5 w-5 text-blue-800'} />, color: 'bg-blue-100'};
            }
            if(fileType.toLowerCase() === 'xls' || fileType === 'xlsx'){
                return { icon: <XlsIcon className={'h-5 w-5 text-green-800'} />, color: 'bg-green-100'};
            }

            return { icon: <FileTextIcon className={'h-5 w-5 text-indigo-800'} />, color: 'bg-indigo-100'};
        }
        return { icon: <AttentionIcon className={'h-5 w-5 text-yellow-800'} />, color: 'bg-yellow-100'};
    };

  return (
      <div className="flex items-center">

          <div>
            <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md '+getFileType().color}>
                <span className="font-medium leading-none text-white">{getFileType().icon}</span>
                {
                    (file?.hidden ?? false) &&  (
                        <LockClosedIcon className={"absolute -bottom-0.5 -right-0.5 block p-1 rounded-full ring-1 ring-white bg-green-400 text-white h-4 w-4"} />
                    )
                }
            </span>
          </div>

          {
              file.document_type ? (
                  <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{file.document_type.name ?? ''}</p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{file?.file_note ?? ''}</p>
                  </div>
              ) : (
                  <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{file?.file_name ?? ''}</p>
                      {
                          (onOpenFile && canView) && (
                              <p className="text-xs text-indigo-900 underline mt-1 cursor-pointer" onClick={() => {onOpenFile()}}>{t("app.catalog.view_attachment")}</p>
                          )
                      }
                  </div>
              )
          }


      </div>
  );
};

export default FileCell;
