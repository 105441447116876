import React from "react";

const EmptyState = ({image, title, description}) => {

    return (
        <div className=" mx-auto px-4 py-3 text-center">
            <div className="text-center p-10">
                <img src={image} className="-mt-8 mx-auto w-64" alt="not-found"  />
                <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                    {title}
                </h2>
                <p className="mt-2 max-w-2xl mx-auto text-center text-md text-gray-500">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default EmptyState;
