import {useTranslation} from "react-i18next";
import React from "react";

export default function InputTextarea ({ label, isShowLabel = true, isRequired, id, errors, warning, warningRegex, input}){

    const { t } = useTranslation();

    return (
        <div>
            {
                isShowLabel && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }
            <textarea
                id={id}
                className={`form-input w-full ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
                placeholder={t("app.common.placeholder", {field: label.toLowerCase()})}
                {...input}
            />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}

        </div>

    )
};
