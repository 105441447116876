import React from "react";

export const InfiniteScroller = React.forwardRef(
    (
        {
            fetchNextPage,
            hasNextPage,
            isLoading,
            loadingMessage,
            children,
            ...props
        },
        ref
    ) => {
        const observerTarget = React.useRef(null);

        React.useEffect(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0]?.isIntersecting) fetchNextPage();
                },
                { threshold: 1 }
            );

            if (observerTarget.current) {
                observer.observe(observerTarget.current);
            }

            return () => observer.disconnect();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <div ref={ref} {...props} style={{ overflowAnchor: "none" }}>
                {children}
                <div ref={observerTarget} />
                {
                    isLoading && (
                        <>{loadingMessage}</>
                    )
                }
            </div>
        );
    }
);
