import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../../routes/endpoints";
import BaseRestricted from "./base-restricted";
import {COMPANY_EXPORTER, COMPANY_IMPORTER} from "../../../constants/config";

// @requiredPermissions is an array of permissions where the type of the permission is optional (e.g. both order.view or order are valid permissions)
// @renderBaseOnFail : bool if true render BaseRestricted component otherwise null
// @adminHasAccess : bool if a user with role admin has automatically access on this resource
// @requireExporter : bool if true require the user connected to be in exporter mode
// @requireImporter : bool if true require the user connected to be in importer mode
// @redirectWrongCompanyType : bool if true redirect user to dashboard if a specific required company mode is not satisfied
const PermissionWrapper = ({
  requiredPermissions = null,
  entityId = null,
  renderBaseOnFail = false,
  adminHasAccess = true,
  requireExporter = false,
  requireImporter = false,
  redirectWrongCompanyType = false,
  children,
}) => {
  const { permissions } = useSelector((s) => s.userCompany);
  const { companyType } = useSelector((s) => s.user);
  const navigate = useNavigate();
  // if true show children, otherwise show its value
  const [shouldShow, setShouldShow] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line
    if(companyType !== null && (requireExporter && (companyType === COMPANY_IMPORTER)) || (requireImporter && (companyType === COMPANY_EXPORTER)) ){
      if (redirectWrongCompanyType) {
        navigate(DASHBOARD);
      } else {
        setShouldShow(null);
        return;
      }
    }

    if (requiredPermissions === null) {
      setShouldShow(true);
      return;
    }

    const hasAccess =
      permissions?.isSuperAdmin ||
      (adminHasAccess && permissions?.isAdmin) ||
      (requiredPermissions ?? [])
        .map((rq) => rq.split("."))
        .some(
          ([group, type]) =>
            group in (permissions?.permissionDict ?? {}) &&
            (typeof type === "undefined" ||
              (entityId === null
                ? type in (permissions?.permissionDict[group] ?? {})
                : permissions.permissionDict[group][type] === true ||
                  (permissions.permissionDict[group][type] ?? []).includes(
                    entityId
                  )))
        );

    if (hasAccess) {
      setShouldShow(true);
    } else {
      setShouldShow(renderBaseOnFail ? <BaseRestricted /> : null);
    }
  }, [
    adminHasAccess,
    entityId,
    companyType,
    navigate,
    permissions?.isAdmin,
    permissions?.isSuperAdmin,
    permissions.permissionDict,
    redirectWrongCompanyType,
    renderBaseOnFail,
    requireExporter,
    requireImporter,
    requiredPermissions,
  ]);

  // careful must be exactly equal (===) to true
  return shouldShow === true ? <>{children}</> : shouldShow;
};

// generate function to filter array according to some permissions
// @field : string | function field is used to get the id from the element of the array to field
export const filterByPermission = (
  requiredPermission,
  permissions,
  field = "id",
  adminHasAccess = true
) => {
  if (permissions.isSuperAdmin || (adminHasAccess && permissions.isAdmin)) {
    return (_) => true;
  }

  const [group, type] = requiredPermission.split(".");
  const pd = permissions?.permissionDict ?? {};
  if (!(pd[group] && pd[group][type])) {
    return (_) => false;
  }

  if (pd[group][type] === true) {
    return (_) => true;
  }

  const idSet = new Set(pd[group][type]);

  return (e) => idSet.has(typeof field === "string" ? e[field] : field(e));
};

export const hasPermissionFor = (
  permissions,
  group,
  type,
  id,
  hasAdminAccess = true
) =>
  permissions.isSuperAdmin ||
  (permissions.isAdmin && hasAdminAccess) ||
  (group in permissions.permissionDict &&
    type in permissions.permissionDict[group] &&
    (permissions.permissionDict[group][type] === true ||
      permissions.permissionDict[group][type].includes(id)));

export const hasPermission = (
    permissions,
    group,
    type,
    id,
    companyType = '',
    requireExporter = false,
    requireImporter = false,
    hasAdminAccess = true
) => {
  // eslint-disable-next-line
  if(companyType !== null && (requireExporter && companyType === COMPANY_IMPORTER) || (requireImporter && companyType === COMPANY_EXPORTER)){
    return false;
  }
  return permissions.isSuperAdmin ||
  (permissions.isAdmin && hasAdminAccess) ||
  (group in permissions.permissionDict &&
      type in permissions.permissionDict[group] &&
      (permissions.permissionDict[group][type] === true ||
          permissions.permissionDict[group][type].includes(id)));

};


export default PermissionWrapper;
