import SlideFull from "../../../components/layout/slide-full";
import {XIcon} from "@heroicons/react/outline/esm";
import React, {useEffect, useState} from "react";
import {CheckIcon} from "@heroicons/react/outline";
import InputText from "../../../components/common/form/input-text";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import InputSelect from "../../../components/common/form/input-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchCountries, fetchPhonePrefix} from "../../../reducers/configReducer";
import InputTextarea from "../../../components/common/form/input-textarea";
import InputCheckbox from "../../../components/common/form/input-checkbox";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import {postProposalFrom} from "../../../api/subscription";
import toast from "react-hot-toast";
import logo from "../../../resources/images/logo.svg";

const SalesProposal = ({isOpen, setIsOpen}) => {

    const { t } = useTranslation();
    const { register, handleSubmit, control, formState: { errors } } = useForm({ mode: 'onBlur' });
    const { isCountriesLoading, countries, isPhonePrefixLoading, prefix } =
        useSelector((state) => state.config);

    const dispatch = useDispatch();

    const [exception, setException] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const storeProposal = async (data) => {
        setLoading(true);
        postProposalFrom({ data })
            .then((response) => {
                setLoading(false);
                toast.success(t("app.plan.proposal.send_success"));
                setIsOpen(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };


    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchPhonePrefix());
        // eslint-disable-next-line
    }, [dispatch]);

    const onSubmit = async (data) => {
        for (const k in data) {
            if (typeof data[k] === "object") {
                data[k] = data[k].label;
            }
        }
        data['phone_number'] = data['phone_prefix'] + data['business_phone'];
        storeProposal(data);
    };
    return (
        <SlideFull
            title={'Title'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            hasPadding={false}
            customHeader={CustomHeader}
            activeOverflow={true}
            showTopHeader={false}
            extras={{
                setIsOpen: (r) => {
                    setIsOpen(r);
                }
            }}
            setClean={() => {
            }}
        >

            <div className="relative bg-solitude-100">
                <div className="relative pt-6 pb-16 sm:pb-24">
                    <main className="">
                        <div className="mx-auto max-w-7xl">
                            <div className="lg:mt-16 lg:grid lg:grid-cols-12 lg:gap-8">
                                <div className="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                                    <div>
                                        <h1 className="mt-4 text-4xl font-bold tracking-tight text-blue-1000 sm:text-5xl md:text-6xl">
                                            {t("app.plan.proposal.title")}
                                        </h1>

                                        <p className="mt-3 text-base text-blue-1000 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                            {t("app.plan.proposal.description")}
                                        </p>


                                        <ul className="mt-5 mb-10 sm:mb-0 sm:mt-20">
                                            <li className="flex items-center py-2 text-blue-1000 sm:text-xl lg:text-lg xl:text-xl">
                                                <CheckIcon className="h-8 w-8 flex-shrink-0 text-blue-1000 mr-2" aria-hidden="true" />
                                                <div className="text-base">{t("app.plan.proposal.feature_1")}</div>
                                            </li>
                                            <li className="flex items-center py-2 text-blue-1000 sm:text-xl lg:text-lg xl:text-xl">
                                                <CheckIcon className="h-8 w-8 flex-shrink-0 text-blue-1000 mr-2" aria-hidden="true" />
                                                <div className="text-base">{t("app.plan.proposal.feature_2")}</div>
                                            </li>
                                            <li className="flex items-center py-2 text-blue-1000 sm:text-xl lg:text-lg xl:text-xl">
                                                <CheckIcon className="h-8 w-8 flex-shrink-0 text-blue-1000 mr-2" aria-hidden="true" />
                                                <div className="text-base">{t("app.plan.proposal.feature_3")}</div>
                                            </li>
                                            <li className="flex items-center py-2 text-blue-1000 sm:text-xl lg:text-lg xl:text-xl">
                                                <CheckIcon className="h-8 w-8 flex-shrink-0 text-blue-1000 mr-2" aria-hidden="true" />
                                                <div className="text-base">{t("app.plan.proposal.feature_4")}</div>
                                            </li>
                                            <li className="flex items-center py-2 text-blue-1000 sm:text-xl lg:text-lg xl:text-xl">
                                                <CheckIcon className="h-8 w-8 flex-shrink-0 text-blue-1000 mr-2" aria-hidden="true" />
                                                <div className="text-base">{t("app.plan.proposal.feature_5")}</div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="lg:col-span-6">
                                    <div className="bg-white p-4 mx-3 sm:p-0 shadow-md sm:mx-auto sm:w-full sm:max-w-2xl sm:overflow-hidden sm:rounded-lg">
                                        <div className="px-4 pb-8 sm:px-10">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-2 sm:grid-cols-1">


                                                <InputText
                                                    id={'first_name'}
                                                    type={'text'}
                                                    errors={errors.first_name}
                                                    input={{...register("first_name", { required: true })}}
                                                    label={t("app.plan.proposal.firstname")}
                                                />

                                                <InputText
                                                    id={'last_name'}
                                                    type={'text'}
                                                    errors={errors.last_name}
                                                    input={{...register("last_name", { required: true })}}
                                                    label={t("app.plan.proposal.lastname")}
                                                />


                                                <div className="col-span-2">
                                                    <InputText
                                                        id={'email'}
                                                        type={'email'}
                                                        errors={errors.email}
                                                        input={{...register("email", { required: false })}}
                                                        label={t("app.plan.proposal.email")}
                                                    />
                                                </div>

                                                <div className="col-span-2">
                                                    <Controller
                                                        name="country"
                                                        rules={{ required: true }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.plan.proposal.country")}
                                                                name={name}
                                                                options={countries}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={isCountriesLoading}
                                                                isDisabled={isCountriesLoading}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <InputText
                                                    id={"company"}
                                                    type={"text"}
                                                    errors={errors.company}
                                                    input={{
                                                        ...register("company", { required: true }),
                                                    }}
                                                    label={t("app.plan.proposal.business_name")}
                                                />

                                                <Controller
                                                    name="company_size"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputSelect
                                                            label={t("app.plan.proposal.company_size")}
                                                            name={name}
                                                            options={[
                                                                { value: '1-10', label: '1-10'},
                                                                { value: '11-20', label: '11-20'},
                                                                { value: '21-50', label: '21-50'},
                                                                { value: '51-250', label: '51-250'},
                                                                { value: '251+', label: '251+'},
                                                            ]}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={false}
                                                            isDisabled={false}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <InputText
                                                    id={"department"}
                                                    type={"text"}
                                                    errors={errors.department}
                                                    input={{
                                                        ...register("department", { required: true }),
                                                    }}
                                                    label={t("app.plan.proposal.department")}
                                                />

                                                <Controller
                                                    name="role"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputSelect
                                                            label={t("app.plan.proposal.role")}
                                                            name={name}
                                                            options={[
                                                                { value: t("app.plan.proposal.role_type.cxo"), label: t("app.plan.proposal.role_type.cxo")},
                                                                { value: t("app.plan.proposal.role_type.vp"), label: t("app.plan.proposal.role_type.vp")},
                                                                { value: t("app.plan.proposal.role_type.Director"), label: t("app.plan.proposal.role_type.Director")},
                                                                { value: t("app.plan.proposal.role_type.Manager"), label: t("app.plan.proposal.role_type.Manager")},
                                                                { value: t("app.plan.proposal.role_type.Contributor"), label: t("app.plan.proposal.role_type.Contributor")},
                                                            ]}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={false}
                                                            isDisabled={false}
                                                            errors={error}
                                                        />
                                                    )}
                                                />


                                                <Controller
                                                    name="phone_prefix"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputSelect
                                                            label={t("app.plan.proposal.phone_prefix")}
                                                            name={name}
                                                            options={prefix}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={isPhonePrefixLoading}
                                                            isDisabled={isPhonePrefixLoading}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <InputText
                                                    id={"business_phone"}
                                                    type={"text"}
                                                    errors={errors.business_phone}
                                                    input={{
                                                        ...register("business_phone", { required: true }),
                                                    }}
                                                    label={t("app.plan.proposal.business_phone")}
                                                />

                                                <div className="col-span-2">
                                                    <Controller
                                                        name="question"
                                                        rules={{ required: true }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.plan.proposal.question")}
                                                                name={name}
                                                                options={[
                                                                    { value: t("app.plan.proposal.question_type.q1"), label: t("app.plan.proposal.question_type.q1") },
                                                                    { value: t("app.plan.proposal.question_type.q2"), label: t("app.plan.proposal.question_type.q2")},
                                                                    { value: t("app.plan.proposal.question_type.q3"), label: t("app.plan.proposal.question_type.q3")},
                                                                    { value: t("app.plan.proposal.question_type.q4"), label: t("app.plan.proposal.question_type.q4") },
                                                                    { value: t("app.plan.proposal.question_type.q5"), label: t("app.plan.proposal.question_type.q5")},
                                                                ]}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={false}
                                                                isDisabled={false}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="col-span-2">
                                                    <InputTextarea
                                                        id={"note"}
                                                        errors={errors.note}
                                                        input={{
                                                            ...register("note", { required: false }),
                                                        }}
                                                        label={t("app.plan.proposal.note")}
                                                    />
                                                </div>

                                                <div className="col-span-2">
                                                    <InputCheckbox
                                                        label={t("oauth.register.privacy_checkbox.title")}
                                                        description={(<a href="https://www.dokless.com/privacy" target="_blank" rel="noreferrer" className="underline">{t("oauth.register.privacy_checkbox.description")}</a> )}
                                                        id={'privacy'}
                                                        errors={errors.privacy}
                                                        input={{...register("privacy", { required: true })}}
                                                    />
                                                </div>

                                            </div>


                                                {exception && <Warning message={exception} />}

                                                <div className="flex items-center justify-between mt-6">
                                                    <InputSubmit
                                                        isLoading={isLoading}
                                                        label={t("app.plan.proposal.send")}
                                                        isFullWith={true}
                                                    />
                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </SlideFull>

    )
}

const CustomHeader = ({extras}) => {
    return (
        <div className="py-6 mx-auto max-w-7xl bg-solitude-100">
            <div className="flex items-center justify-between">



                <div>
                    <img src={logo} width={150} alt={'logo'} />
                </div>

                <div className="flex items-center justify-between cursor-pointer">
                    {/*
                        <p className="text-xl  text-blue-1000  font-bold " onClick={() => {extras.setIsOpen(false);}}>Close</p>
                    */}
                    <div className="ml-3 h-7 flex items-center">
                        <button
                            type="button"
                            className="  mr-2"
                            onClick={() => {extras.setIsOpen(false);}}
                        >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-8 w-8 text-blue-1000" aria-hidden="true" />
                        </button>
                    </div>

                </div>

            </div>
        </div>
    )
}



export default SalesProposal;
