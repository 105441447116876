import React, {useEffect, useState} from "react";
import {getElement} from "../../../api/config";
import {getPathParam} from "../../../utils/converter";
import {COMPANY, CONNECTION, WIDGET} from "../../../api/endpoints";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Loader from "../../common/loader/loader";
import NumberFormat from "react-number-format";
import {tailwindConfig, hexToRGB} from '../../../utils/Utils';
import PieChart from "../../charts/PieChart";
import moment from "moment";
import BarChart01 from "../../charts/BarChart01";
import LineChart from "../../charts/LineChart";
import {useTranslation} from "react-i18next";
import Nodata from "../../../resources/images/no-data.gif";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ConnectionOverview() {

    const { t } = useTranslation();

    const stats = [
        { key: 1, title: t("dashboard.connection_overview.sq_closing_percentage"), code: 'SQ_CLOSING_PERCENTAGE', extraClass: 'border-red-600' },
        { key: 2, title: t("dashboard.connection_overview.sq_avg_confirmed_day"), code: 'SQ_AVG_CONFIRMED_DAY', extraClass: 'border-yellow-600' },
        { key: 3, title: t("dashboard.connection_overview.sq_avg_closed_amount"), code: 'SQ_AVG_CLOSED_AMOUNT', extraClass: 'border-green-600' },
        { key: 4, title: t("dashboard.connection_overview.ship_avg_turnover"), code: 'SHIP_AVG_TURNOVER', extraClass: 'border-blue-600' },
        { key: 5, title: t("dashboard.connection_overview.ship_avg_cost"), code: 'SHIP_AVG_COST', extraClass: 'border-indigo-600' },
        { key: 6, title: t("dashboard.connection_overview.ship_avg_cost_land"), code: 'SHIP_AVG_COST_LAND', extraClass: 'border-pink-600' },
        { key: 6, title: t("dashboard.connection_overview.ship_cost_teu"), code: 'SHIP_COST_TEU', extraClass: 'border-yellow-900' },
        { key: 7, title: t("dashboard.connection_overview.ship_total_teu"), code: 'SHIP_TOTAL_TEU', extraClass: 'border-gray-600' },
        { key: 8, title: t("dashboard.connection_overview.ship_avg_volume"), code: 'SHIP_AVG_VOLUME', extraClass: 'border-purple-600' },
    ];

    return (
        <>
            <div className="max-w-7xl mx-auto mb-6 pt-6 px-4 sm:px-6 lg:px-8">
                <h3 className="font-semibold text-blue-1000">{t("dashboard.connection_overview.kpi_title")}</h3>
                <div className="mt-5 grid gap-3 grid-cols-2 xl:grid-cols-3">
                    {stats.map((item) => (
                        <FixedCardOverview key={item.key} title={item.title} cardType={item.code} extraClass={item.extraClass}  />
                    ))}
                </div>



                <h3 className="mt-5 font-semibold text-blue-1000">{t("dashboard.connection_overview.quotation_title")}</h3>
                <div className="mt-5 grid flex h-full gap-3 grid-cols-1 xl:grid-cols-3">
                    <QuotationStatusOverview />
                    <QuotationClosed />
                </div>


                <h3 className="mt-5 font-semibold text-blue-1000">{t("dashboard.connection_overview.shipping_title")}</h3>
                <div className="mt-5 grid flex h-full gap-3 grid-cols-1 xl:grid-cols-2">
                    <ShippingTurnover />
                    <ShippingCounterClosed />
                </div>


                <div className="mt-5 pb-10 grid flex h-full gap-3 grid-cols-1 xl:grid-cols-3">
                    <ShippingCost />
                    <ForwarderTop />
                </div>



            </div>



        </>
    );
}


const FixedCardOverview = ({title, cardType, extraClass}) => {

    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isLoadingData, setLoadingData] = useState(true);
    const [dataCard, setDataCard] = useState(false);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {});
                setDataCard(res);
                setLoadingData(false);
            } catch (e) {
                setDataCard(false);
            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);




    return (
        <div className={classNames("overflow-hidden bg-white px-4 py-5  shadow sm:p-6 border-t-4", extraClass)}>
            <p className="text-xs font-medium leading-6 text-gray-500">{title}</p>
            <p className="mt-1 text-3xl  tracking-tight text-blue-1000">
                {
                    isLoadingData ? (
                        <div className="animate-pulse flex space-x-4 h-10 items-center	">
                            <div className="h-4 bg-gray-400 rounded w-12" />
                        </div>
                    ) : (

                        <>
                            {
                                (dataCard?.data?.type === 'number' ?? false) && (
                                    <NumberFormat
                                        value={dataCard.data.value}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={dataCard.data.prefix+" "}
                                        suffix={" "+dataCard.data.suffix}
                                    />
                                )
                            }
                            {
                                (dataCard?.data?.type === 'currency' ?? false) && (
                                    <NumberFormat
                                        value={dataCard.data.value}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={'€ '}
                                    />
                                )
                            }
                            {
                                (dataCard?.data?.type === 'teu' ?? false) && (
                                    <NumberFormat
                                        value={dataCard.data.value}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={' TEU'}
                                    />
                                )
                            }

                            {
                                (dataCard?.data?.type === 'day' ?? false) && (
                                    <NumberFormat
                                        value={dataCard.data.value}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix={dataCard.data.prefix+" "}
                                        suffix={" "+dataCard.data.suffix}
                                    />
                                )
                            }

                        </>


                    )
                }
            </p>
        </div>
    );

}


const QuotationClosed = () => {
    const { t } = useTranslation();

    const cardType = 'SQ_CLOSED_COUNT';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();


    const getMonth = (n) => {
        let month = moment().month(n).format("MMM");
        return month.charAt(0).toUpperCase() + month.slice(1);
    }

    const [isLoadingData, setLoadingData] = useState(true);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: t("dashboard.connection_overview.this_year"),
                data: [],
                backgroundColor: tailwindConfig().theme.colors.indigo[500],
                hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
                barPercentage: 0.66,
                categoryPercentage: 0.66,
            },
            {
                label: t("dashboard.connection_overview.last_year"),
                data: [],
                backgroundColor: tailwindConfig().theme.colors.blue[400],
                hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
                barPercentage: 0.66,
                categoryPercentage: 0.66,
            }
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {date: 'all_time'});



                setChartData({
                    labels: res.data.this.map(item => getMonth((item.month - 1))),
                    datasets: [
                        {
                            label: t("dashboard.connection_overview.this_year"),
                            data: res.data.this.map(item => item.total),
                            backgroundColor: tailwindConfig().theme.colors.indigo[500],
                            hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
                            barPercentage: 0.66,
                            categoryPercentage: 0.66,
                        },
                        {
                            label: t("dashboard.connection_overview.last_year"),
                            data: res.data.last.map(item => item.total),
                            backgroundColor: tailwindConfig().theme.colors.blue[400],
                            hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
                            barPercentage: 0.66,
                            categoryPercentage: 0.66,
                        },
                    ],
                })


                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={'flex flex-col   col-span-2 flex-grow bg-white shadow  border-gray-200 p-2'} >
                <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.quotation_closed")}</p>

                {
                        isLoadingData ? (
                            <div className="grow items-center flex flex-col justify-center h-36">
                                <Loader />
                            </div>
                        ) : (
                            <BarChart01 data={chartData} width={800} height={250} isNumber={true} />
                        )
                    }
            </div>
        </>


    )
}

const QuotationStatusOverview = () => {

    const { t } = useTranslation();
    const cardType = 'SQ_GENERAL_STATUS';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isLoadingData, setLoadingData] = useState(true);
    const [showChart, setShowChart] = useState(false);


    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: [
                    tailwindConfig().theme.colors.yellow[400],
                    tailwindConfig().theme.colors.green[500],
                    tailwindConfig().theme.colors.red[500],
                ],
                hoverBackgroundColor: [
                    tailwindConfig().theme.colors.yellow[500],
                    tailwindConfig().theme.colors.green[600],
                    tailwindConfig().theme.colors.red[600],
                ],
                borderWidth: 0,
            },
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {});



                setShowChart(res.data.filter(item => item.item.value > 0).length > 0)

                setChartData({
                    labels: res.data.filter(item => item.type !== 'draft').map(item => t("filter.general."+item.type)),
                    datasets: [
                        {
                            label: '',
                            data: res.data.filter(item => item.type !== 'draft').map(item => parseFloat(item.item.value)),
                            backgroundColor: [
                                tailwindConfig().theme.colors.yellow[400],
                                tailwindConfig().theme.colors.green[500],
                                tailwindConfig().theme.colors.red[500],
                            ],
                            hoverBackgroundColor: [
                                tailwindConfig().theme.colors.yellow[500],
                                tailwindConfig().theme.colors.green[600],
                                tailwindConfig().theme.colors.red[600],
                            ],
                            borderWidth: 0,
                        },
                    ],
                })

                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col  bg-white shadow  border-gray-200 p-2  flex-grow">
            <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.quotation_status")}</p>
            {
                isLoadingData ? (
                    <div className="grow items-center flex flex-col justify-center h-36">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {
                            showChart ? (
                                <PieChart data={chartData} width={389} height={220} />
                            ) : (
                                <div className="grow items-center flex flex-col justify-center" style={{height: '220px'}}>
                                    <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                    <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                        {t("app.common.not_found")}
                                    </h1>
                                </div>
                            )
                        }


                    </>
                )
            }

        </div>

    );

}


const ShippingTurnover = () => {

    const { t } = useTranslation();
    const cardType = 'SHIP_TOTAL_TURNOVER';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isLoadingData, setLoadingData] = useState(true);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            // Light blue bars
            {
                label: t("dashboard.connection_overview.this_year"),
                data: [],
                totalContainer: [],
                borderColor: tailwindConfig().theme.colors.indigo[500],
                fill: true,
                backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
            // Blue bars
            {
                label: t("dashboard.connection_overview.last_year"),
                data: [],
                totalContainer: [],
                borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                fill: false,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {date: 'all_time'});



                setChartData({
                    labels: res.data.this.map(item => (item.month)),
                    datasets: [
                        // Light blue bars
                        {
                            label: t("dashboard.connection_overview.this_year"),
                            data: res.data.this.map(item => parseFloat(item.total)),
                            borderColor: tailwindConfig().theme.colors.indigo[500],
                            fill: true,
                            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                            borderWidth: 2,
                            tension: 0,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                            pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            clip: 20,
                        },
                        // Blue bars
                        {
                            label: t("dashboard.connection_overview.last_year"),
                            data: res.data.last.map(item => parseFloat(item.total)),
                            borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            fill: false,
                            borderWidth: 2,
                            tension: 0,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            clip: 20,
                        },
                    ],
                })

                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col  bg-white shadow  border-gray-200 p-2  flex-grow">
            <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.shipping_turnover")}</p>
            {
                isLoadingData ? (
                    <div className="grow items-center flex flex-col justify-center h-36">
                        <Loader />
                    </div>
                ) : (
                    <LineChart data={chartData} width={800} height={250} isNumber={false} />
                )
            }
        </div>

    )
}


const ShippingCounterClosed = () => {

    const { t } = useTranslation();
    const cardType = 'SHIP_TOTALS';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isLoadingData, setLoadingData] = useState(true);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            // Light blue bars
            {
                            label: t("dashboard.connection_overview.this_year"),
                data: [],
                borderColor: tailwindConfig().theme.colors.indigo[500],
                fill: true,
                backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
            // Blue bars
            {
                            label: t("dashboard.connection_overview.last_year"),
                data: [],
                borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                fill: false,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                clip: 20,
            },
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {date: 'all_time'});



                setChartData({
                    labels: res.data.this.map(item => (item.month)),
                    datasets: [
                        // Light blue bars
                        {
                            label: t("dashboard.connection_overview.this_year"),
                            data: res.data.this.map(item => item.total),
                            borderColor: tailwindConfig().theme.colors.indigo[500],
                            fill: true,
                            backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
                            borderWidth: 2,
                            tension: 0,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                            pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            clip: 20,
                        },
                        // Blue bars
                        {
                            label: t("dashboard.connection_overview.last_year"),
                            data: res.data.last.map(item => item.total),
                            borderColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            fill: false,
                            borderWidth: 2,
                            tension: 0,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            pointHoverBackgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[500])}, 0.25)`,
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            clip: 20,
                        },
                    ],
                })


                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col  bg-white shadow  border-gray-200 p-2  flex-grow">
            <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.shipping_closed")}</p>
            {
                isLoadingData ? (
                    <div className="grow items-center flex flex-col justify-center h-36">
                        <Loader />
                    </div>
                ) : (
                    <LineChart data={chartData} width={800} height={250} isNumber={true} />
                )
            }
        </div>

    )
}


const ShippingCost = () => {
    const { t } = useTranslation();

    const cardType = 'TOTAL_SHIPPING_COST';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();


    const getMonth = (n) => {
        let month = moment().month(n).format("MMM");
        return month.charAt(0).toUpperCase() + month.slice(1);
    }

    const [isLoadingData, setLoadingData] = useState(true);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            // Light blue bars
            {
                label: t("dashboard.connection_overview.this_year"),
                data: [],
                backgroundColor: tailwindConfig().theme.colors.blue[400],
                hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
                barPercentage: 0.66,
                categoryPercentage: 0.66,
            },
            // Blue bars
            {
                label: t("dashboard.connection_overview.last_year"),
                data: [],
                backgroundColor: tailwindConfig().theme.colors.indigo[500],
                hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
                barPercentage: 0.66,
                categoryPercentage: 0.66,
            },
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {date: 'all_time'});



                setChartData({
                    labels: res.data.this.map(item => getMonth((item.month - 1))),
                    datasets: [
                        // Blue bars
                        {
                            label: t("dashboard.connection_overview.this_year"),
                            data: res.data.this.map(item => item.total),
                            backgroundColor: tailwindConfig().theme.colors.indigo[500],
                            hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
                            barPercentage: 0.66,
                            categoryPercentage: 0.66,
                        },
                        // Light blue bars
                        {
                            label: t("dashboard.connection_overview.last_year"),
                            data: res.data.last.map(item => item.total),
                            backgroundColor: tailwindConfig().theme.colors.blue[400],
                            hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
                            barPercentage: 0.66,
                            categoryPercentage: 0.66,
                        }
                    ],
                })


                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className={'flex flex-col   col-span-2 flex-grow bg-white shadow  border-gray-200 p-2'} >
            <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.average_shipping_cost")}</p>
            {
                isLoadingData ? (
                    <div className="grow items-center flex flex-col justify-center h-36">
                        <Loader />
                    </div>
                ) : (
                    <BarChart01 data={chartData} width={800} height={250} isNumber={false} />
                )
            }
        </div>

    )
}


const ForwarderTop = () => {
    const { t } = useTranslation();

    const cardType = 'TOP_TEN_FORWARDER_BASED_SHIPPING_COST';
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isLoadingData, setLoadingData] = useState(true);
    const [showChart, setShowChart] = useState(false);


    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: [
                ],
                hoverBackgroundColor: [
                ],
                borderWidth: 0,
            },
        ],
    });


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        const fetchData = async () => {
            setLoadingData(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, id, WIDGET, cardType]), signal, {});
                setShowChart(res.data.length > 0)

                setChartData({
                    labels: res.data.map(item => item.forwarder_name),
                    datasets: [
                        {
                            label: '',
                            data: res.data.map(item => item.total_shipping),
                            backgroundColor: [
                                tailwindConfig().theme.colors.gray[500],
                                tailwindConfig().theme.colors.red[400],
                                tailwindConfig().theme.colors.yellow[500],
                                tailwindConfig().theme.colors.green[500],
                                tailwindConfig().theme.colors.blue[500],
                                tailwindConfig().theme.colors.indigo[500],
                                tailwindConfig().theme.colors.indigo[500],
                                tailwindConfig().theme.colors.purple[800],
                                tailwindConfig().theme.colors.purple[900],
                                tailwindConfig().theme.colors.pink[500],
                            ],
                            hoverBackgroundColor: [
                                tailwindConfig().theme.colors.gray[400],
                                tailwindConfig().theme.colors.red[300],
                                tailwindConfig().theme.colors.yellow[400],
                                tailwindConfig().theme.colors.green[400],
                                tailwindConfig().theme.colors.blue[400],
                                tailwindConfig().theme.colors.indigo[400],
                                tailwindConfig().theme.colors.indigo[400],
                                tailwindConfig().theme.colors.purple[700],
                                tailwindConfig().theme.colors.purple[800],
                                tailwindConfig().theme.colors.pink[400],
                            ],
                            borderWidth: 0,
                        },
                    ],
                })

                setLoadingData(false);
            } catch (e) {

            } finally {
                setLoadingData(false);
            }
        }

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col  bg-white shadow  border-gray-200 p-2  flex-grow">
            <p className="px-5 text-xs font-medium leading-6 text-gray-500">{t("dashboard.connection_overview.top_10_shippers")}</p>

            {
                isLoadingData ? (
                    <div className="grow items-center flex flex-col justify-center h-36">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {
                            showChart ? (
                                <PieChart data={chartData} width={389} height={220} />
                            ) : (
                                <div className="grow items-center flex flex-col justify-center" style={{height: '220px'}}>
                                    <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                    <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                        {t("app.common.not_found")}
                                    </h1>
                                </div>
                            )
                        }
                    </>
                )
            }


        </div>
    );

}






