import React, {useState} from "react";
import LoaderWrapper from "../loader/loader-wrapper";
import Nodata from "../../../resources/images/no-data.gif";
import {useTranslation} from "react-i18next";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/outline";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const TableSort = ({ columns, children, isLoading, dataLength, isActiveSelectAll = true, hasCheckbox = false, selectAll, handleSelectAll, sortBy, onSortBy, activeFixedColumn = false }) => {
    const { t } = useTranslation();


    return (
        <div className="align-middle inline-block sm:min-w-full lg:max-w-full  overflow-x-auto w-24 min-w-full mx-auto">
            <table className={classNames(
                "min-w-full",
                (activeFixedColumn && hasCheckbox) ? "scrollableTable" : "",
                (activeFixedColumn && !hasCheckbox) ? "scrollableColumnTable" : "",
            )}>
                <thead className="bg-solitude-400 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider">
                <tr>
                        {(hasCheckbox && isActiveSelectAll)  && (
                        <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                            {
                                    isActiveSelectAll && (
                                        <input type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />

                                    )
                            }
                        </th>
                    )}
                    {columns.map((column) => (
                        <Header key={column.header ?? "untitled-voice"} column={column} sortBy={sortBy} onSortBy={onSortBy} />
                    ))}
                </tr>
                </thead>
                {!isLoading && (
                    <>{children}</>

                )}
            </table>

            <LoaderWrapper isLoading={isLoading} />

            {dataLength === 0 && !isLoading && (
                <div className="lg:w-1/2 mx-auto px-4 py-3 text-center">
                    <div className="text-center p-10">
                        <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                        <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                            {t("app.common.not_found")}
                        </h1>
                    </div>
                </div>
            )}
        </div>
    );
};

const Header = ({column, sortBy, onSortBy}) => {
    const [show, setShow] = useState(false);


    return (
        <th onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            className="px-6 py-3">


            {/* // eslint-disable-next-line  */}
            <a href="#0" className="flex justify-start items-center" onClick={() => {

                if(sortBy.position === 0 || sortBy.id !== column.id){
                    onSortBy({accessor: column.accessor, id: column.id, type: 'asc', position: 1})
                }

                if(sortBy.id === column.id && sortBy.position === 1){
                    onSortBy({accessor: column.accessor, id: column.id, type: 'desc', position: 2})
                }

                if(sortBy.id === column.id && sortBy.position === 2){
                    onSortBy({accessor: null, type: null, id: null, position: 0})
                }

            }}>
                {column.header}
                <span className="flex-none rounded text-gray-900 ml-2">
                    {
                        !show &&  sortBy.position === 0 ? (
                            <div className="h-3 w-3"/>
                        ) : (
                            <>
                                {
                                    (sortBy.position === 0 && show) && (
                                        <ArrowDownIcon className="h-3 w-3" aria-hidden="true" />
                                    )
                                }

                                {
                                    (sortBy.id === column.id && sortBy.position === 2) && (
                                        <ArrowUpIcon className="h-3 w-3" aria-hidden="true" />
                                    )
                                }

                                {
                                    (sortBy.id === column.id && sortBy.position === 1) && (
                                        <ArrowDownIcon className="h-3 w-3" aria-hidden="true" />
                                    )
                                }
                            </>
                        )
                    }



                </span>


            </a>
        </th>
    )

};

export default TableSort;
