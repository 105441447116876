import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader, {
  ActionButton,
  ImportButton,
} from "../../components/layout/page-header";
import { useSelector } from "react-redux";
import ActionsMenu from "../../components/common/table/actions-menu";
import {ORDER, ORDER_OVERVIEW, ORDERS, SALES} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import AddOrder from "./add-order";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../api/order";
import CustomerCell from "../../components/common/table/customer-cell";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../constants/permissions";
import ListSection from "../../components/layout/list-section";
import NumberFormat from "react-number-format";
import {getCustomFormat} from "../../utils/timeUtils";
import OptionalLink from "../../components/common/table/optional-link";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import ImportOrder from "./import/import-order";
import ImportLog from "./import/import-log";
import {hasSubscriptionPermission} from "../../components/partials/restricted/subscription-wrapper";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {COMPANY} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import BasePermissionWrapper, {
    hasPermission,
    hasPermissionFor
} from "../../components/partials/restricted/base-permission-wrapper";
import { PlusIcon } from "@heroicons/react/outline";

const importType = 'order';

const Orders = () => {
  const { loggedCompany, isExporter, companyType } = useSelector(
    (state) => state.user
  );
  const { permissions, subscriptions } = useSelector((s) => s.userCompany);
  const isSubImportActive = hasSubscriptionPermission(subscriptions, sp.IMPORT_ORDER_MARKETPLACE_PACKAGE);


  const canView =  hasPermissionFor(permissions, permissionGroup.ORDER, permissionType.VIEW);
  const canEdit =  hasPermission(permissions, permissionGroup.ORDER, permissionType.EDIT, companyType, true);
  const canCreate =  hasPermission(permissions, permissionGroup.ORDER, permissionType.CREATE, companyType, true);
  const canRemove =  hasPermission(permissions, permissionGroup.ORDER, permissionType.DELETE, companyType, true);

  const isActiveImport =  hasPermission(permissions, permissionGroup.ORDER, permissionSpecific.ORDER_IMPORT_PRODUCT, companyType, true);
  const isSubscriptionActive = hasSubscriptionPermission(subscriptions, sp.ORDER_INTERNAL_PACKAGE);

  const [isFetchOrdersLoading, setFetchOrdersLoading] = useState(true);
  const [orderList, setOrderList] = useState({
    orders: [],
    total_rows: 0,
  });

  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpload, setOpenUpload] = useState(false);
  const [isOpenImportLog, setOpenImportLog] = useState(false);

  const [lastImport, setLastImport] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});


  const { t } = useTranslation();

  const columns = [
    isExporter || isExporter === null ? t("app.common.customer") : t("app.common.supplier"),
    t("app.orders.order_reference"),
    t("app.orders.total"),
    t("app.orders.order_date"),
    "",
  ];



  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchOrders() {
      if (loggedCompany) {
        setFetchOrdersLoading(true);
        try {
           const res = await getOrders({
            company_id: loggedCompany.id,
            params: {
              limit: 10,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              company_type: companyType,
            },
            signal,
          });
          setOrderList(res);
        } catch (e) {
          setOrderList({ orders: [], total_rows: 0 });
        } finally {
          setFetchOrdersLoading(false);
        }
      }
    }

     fetchOrders();
    return () => {
      controller.abort();
    };
  }, [offset, query, loggedCompany, companyType, needRefresh]);

  return (

      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.ORDER, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader
              canSwitch={true}
              title={t("app.orders.orders")}
              subtitle={(isSubImportActive && isActiveImport) ? t("app.orders.view_import_log") : false}
              importGroup={[getPermission(permissionGroup.ORDER, permissionSpecific.ORDER_IMPORT_PRODUCT)]}
              onSubtitleClick={() => {
                  setOpenImportLog(true)
              }}
              onNeedRefresh={(r) => {
                  setNeedRefresh(r);
              }}
              importType={importType}
              lastImport={lastImport}

          >
              {
                  canCreate && (
                      <ActionButton
                          icon={
                              <PlusIcon
                                  className="w-4 h-4"
                              />
                          }
                          onClick={() => {
                              setIsOpen(true);
                          }}
                          text={t("app.orders.new_order")}
                      />
                  )
              }

             <SubscriptionWrapper requiredPermission={sp.IMPORT_ORDER_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
                 {
                     isActiveImport && (
                         <ImportButton
                             onClick={() => {
                                 setOpenUpload(true);
                             }}
                             text={t("app.orders.import_orders")}
                         />
                     )
                 }
            </SubscriptionWrapper>

          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <ListSection
            title={isExporter || isExporter === null ? t("app.common.customer") : t("app.common.supplier")}
            columns={columns}
            totalRows={orderList.total_rows}
            list={orderList?.orders ?? []}
            onSearch={setQuery}
            isLoading={isFetchOrdersLoading}
            paginationOnChange={setOffset}
            mobileRow={OrderMobRow}
            desktopRow={OrderDeskRow}
            extras={{
                onRemove: (r, detail) => {
                    setOpenRemove(r);
                    setRemoveDetail(detail);
                },
                isSubscriptionActive,
                canEdit,
                canView,
                canRemove
            }}
          />
        </div>
      </div>

        {(isOpenRemove && canRemove) && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                onRemoveConfirmed={() => {
                    setNeedRefresh(!needRefresh);
                }}
            />
        )}

      {isOpen && <AddOrder isOpen={isOpen} setIsOpen={setIsOpen} />}

        <SubscriptionWrapper requiredPermission={sp.IMPORT_ORDER_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
            {
                isActiveImport && (
                    <>
                        <ImportOrder
                            isOpen={isOpenUpload}
                            setIsOpen={setOpenUpload}
                            importGroup={importType}
                            onImportComplete={(r) => {
                                setLastImport(r);
                            }}
                        />

                        <ImportLog
                            isOpen={isOpenImportLog}
                            setIsOpen={setOpenImportLog}
                            type={importType}
                        />
                    </>
                )
            }
        </SubscriptionWrapper>

      </BasePermissionWrapper>
  );
};

const OrderMobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

    const removeDetail = {
        title: t("app.orders.remove_title"),
        message: t("app.orders.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id , ORDER, element.id]),
        redirect: getWebPathParam([SALES, ORDERS]),
    };

  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
          isLinked={extras.canView}
          to={getWebPathParam([SALES, ORDER, element.id, ORDER_OVERVIEW])}
            className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
          <CustomerCell customer={element.customer} />
          <ActionsMenu>
            {extras.canView && (
              <Link to={getWebPathParam([SALES, ORDER, element.id, ORDER_OVERVIEW])}>
                {t("app.common.overview")}
              </Link>
            )}
              {extras.isSubscriptionActive && extras.canRemove && (
                  <button className={'text-left'} onClick={() => {
                      extras.onRemove(true, removeDetail);
                  }}
                  >
                      {t("app.common.remove")}
                  </button>
              )}
          </ActionsMenu>
        </div>

        <div className="flex flex-col">
            <CardDetail title={t("app.orders.order_reference")}>
              {element.order_reference}
          </CardDetail>

            <CardDetail title={t("app.orders.total")}>
                <NumberFormat
                    value={element.total_order}
                    displayType={"text"}
                    decimalScale={2}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"€ "}
                />
            </CardDetail>

          <CardDetail title={t("app.orders.order_date")}>
            {getCustomFormat(element.order_date, 'DD/MM/YYYY')}
          </CardDetail>



        </div>
      </OptionalLink>
    </li>
  );
};

const OrderDeskRow = ({ element , extras}) => {
  const { t } = useTranslation();
  const {loggedCompany } = useSelector((s) => s.user);


    const removeDetail = {
        title: t("app.orders.remove_title"),
        message: t("app.orders.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id , ORDER, element.id]),
        redirect: getWebPathParam([SALES, ORDERS]),
    };


  return (
    <tr key={element.id}>
      <TableDetail extraClass="max-w-sm">
        <div className="flex items-center space-x-3 lg:pl-2">
          <OptionalLink
              isLinked={extras.canView}
              to={getWebPathParam([SALES, ORDER, element.id, ORDER_OVERVIEW])}
              className="truncate hover:text-gray-600"
          >
            <CustomerCell customer={element.customer} />
          </OptionalLink>
        </div>
      </TableDetail>

        <TableDetail>
            {element.order_reference}
        </TableDetail>

      <TableDetail>
        <NumberFormat
            value={element.total_order}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            prefix={"€ "}
        />
      </TableDetail>

        <TableDetail>
            {getCustomFormat(element.order_date, 'DD/MM/YYYY')}
        </TableDetail>

      <TableDetail>
        <ActionsMenu>
          {extras.canView && (
              <Link to={getWebPathParam([SALES, ORDER, element.id, ORDER_OVERVIEW])}>
                {t("app.common.overview")}
              </Link>
          )}
        {extras.isSubscriptionActive && extras.canRemove && (
            <button className={'text-left'} onClick={() => {
                extras.onRemove(true, removeDetail);
            }}
            >
                {t("app.common.remove")}
            </button>
        )}
        </ActionsMenu>
      </TableDetail>

    </tr>
  );
};

export default Orders;
