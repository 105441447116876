import React, {useEffect, useRef, useState} from "react";
import SlidePanel from "../../components/layout/slide-panel";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getTaskMessages, postTaskMessage} from "../../api/task";
import moment from "moment";
import Initials from "../../components/common/avatar/initials";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import {useForm} from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import {VolumeOffIcon, VolumeUpIcon} from "@heroicons/react/outline";
import {getPathParam} from "../../utils/converter";
import {COMPANY} from "../endpoints";
import {NOTIFICATION, TASK} from "../../api/endpoints";
import ConfirmPopup from "../../components/common/popup/confirm-popup";

export default function TaskMessage({ isOpen, setIsOpen, taskDetail, onRefreshData }) {

    const { loggedCompany, detail } = useSelector((state) => state.user);
    const lastMessage = useRef(null);


    const [isLoading, setLoading] = useState(true);
    const [isLoadingSend, setLoadingSend] = useState(false);
    const [messages, setMessages] = useState([]);
    const [oldMessages, setOldMessages] = useState([]);


    // Counter details
    const [second, setSecond] = useState(1);
    const [startCounter, setStartCounter] = useState(false);

    const audio = new Audio('https://freesound.org/data/previews/91/91926_7037-lq.mp3');


    const clean = async () => {
        setMessages([]);
        await setIsOpen(false)
    };

    const getFiltered = () => {
        if(messages.length > 0){
            let list = messages,
                result = list.reduce(function (r, a) {
                    let date = moment(a.date, "YYYY-M-D HH:mm:ss").startOf('day').format("YYYY-M-D HH:mm:ss");
                    let flag = moment(date, "YYYY-M-D HH:mm:ss").format("D MMMM YYYY")

                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }


    const sendMessage = async (data) => {
        setLoadingSend(true);
        try {
            const res = await postTaskMessage({
                company_id: loggedCompany.id,
                task_id: taskDetail.id,
                data,
            });
            setMessages(res.messages);
            setOldMessages(res.messages);
            scrollToBottom();
        } catch (e) {
            setMessages([]);
        } finally {
            setLoadingSend(false);
        }
    }




    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchMessages() {
            if (loggedCompany && isOpen && taskDetail) {
                setLoading(true);
                try {
                    const res = await getTaskMessages({
                        company_id: loggedCompany.id,
                        task_id: taskDetail.id,
                        signal,
                    });
                    setMessages(res.messages);
                    setOldMessages(res.messages);
                    setStartCounter(true);
                    setTimeout(function(){
                        scrollToBottom();
                    }, 500);

                } catch (e) {
                    setMessages([]);
                } finally {
                    setLoading(false);
                }
            }
        }
        fetchMessages();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpen, taskDetail]);



    useEffect(() => {
        const interval = setInterval(  () => {
            if (startCounter && isOpen) {
                setSecond(second + 1);
                if (second === 30) {
                    if (loggedCompany && isOpen && taskDetail) {
                        setSecond(0);
                        getTaskMessages({company_id: loggedCompany.id, task_id: taskDetail.id})
                            .then(response => {
                                setSecond(0);
                                setMessages(response.messages);
                                scrollToBottom();

                                if(response.messages.length !== oldMessages.length){
                                    audio.play();
                                    setOldMessages(response.messages);
                                }

                            }).catch(e => {
                                setStartCounter(false);
                        });
                    }

                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });




    const scrollToBottom = () => {
        lastMessage.current.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>

            <SlidePanel
                title={taskDetail.title}
                bg={"bg-white"}
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsOpen(false)
                }}
                size={'max-w-2xl'}
                hasDivider={false}
                paddingStyle={''}
                activeOverflow={false}
                footer={Footer}
                setClean={() => {
                    clean();
                }}
                extras={{
                    isLoading,
                    isLoadingSend,
                    isMuted: taskDetail.is_muted,
                    taskDetail,
                    onSend: (e) => {
                        sendMessage(e);
                    },
                    onRefreshData:(r) => {
                        onRefreshData(r)
                    }
                }}
            >


                <LoaderWrapper isLoading={isLoading}>
                    <div className="grow overflow-y-auto px-4 sm:px-6" style={{height: document.getElementById('slideId')?.offsetHeight - 140 ?? 'auto'}}>



                        {Object.keys(getFiltered()).map((date) => (
                            <>
                                {/* Date separator */}
                                <div className="flex justify-center" key={date}>
                                    <div className="inline-flex items-center justify-center text-xs text-slate-600 font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full my-5">
                                        <h3>{date.charAt(0).toUpperCase() + date.slice(1)}</h3>
                                    </div>
                                </div>

                                {getFiltered()[date].map((item) => (
                                    <>


                                        {
                                            item.user.id === detail.id  ? (
                                                <div className="flex items-start mb-4 last:mb-0 justify-end">
                                                    <div>
                                                        <div className="text-sm bg-white dark:bg-slate-800 text-slate-800 dark:text-slate-100 p-3 rounded-lg rounded-tr-none border border-slate-200 dark:border-slate-700 shadow-md mb-1">
                                                            {item.message}
                                                        </div>
                                                        <div className="flex items-center justify-end">
                                                            <div className="text-xs text-slate-500 font-medium">{moment(item.date, "YYYY-M-D HH:mm:ss").format("HH:mm")}</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        item.user.picture ? (
                                                            <img className="h-10 w-10 rounded-full ring-4 ring-white object-cover ml-4" src={item.user.picture.reference} alt="" />
                                                        ) : (
                                                            <Initials
                                                                color={item.user?.color ?? ''}
                                                                name={item.user?.fullname ?? ''}
                                                                iconClass={' h-10 w-10 ml-4'}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <div className="flex items-start mb-4 last:mb-0">

                                                    {
                                                        item.user.picture ? (
                                                            <img className="h-10 w-10 rounded-full ring-4 ring-white object-cover mr-4" src={item.user.picture.reference} alt="" />
                                                        ) : (
                                                            <Initials
                                                                color={item.user?.color ?? ''}
                                                                name={item.user?.fullname ?? ''}
                                                                iconClass={' h-10 w-10 mr-4'}

                                                            />
                                                        )
                                                    }
                                                    <div>
                                                        <div className="text-sm bg-indigo-800 text-white p-3 rounded-lg rounded-tl-none border border-transparent shadow-md mb-1">
                                                            <div className="text-xs text-white font-semibold mb-1">{item.user.fullname}</div>
                                                            {item.message}
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <div className="text-xs text-slate-500 font-medium">{moment(item.date, "YYYY-M-D HH:mm:ss").format("HH:mm")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }


                                    </>

                                ))}

                            </>


                        ))}



                        <div ref={lastMessage} className={"mb-10"} />

                    </div>


                </LoaderWrapper>


            </SlidePanel>

        </>
    );
}

const Footer = ({extras}) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);


    const [isMuted, setMuted] = useState(extras.isMuted);
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({ mode: "onBlur" });


    const onSubmit = async (data) => {
        extras.onSend(data);
        setValue('message', undefined);
    };


    return (
        !extras.isLoading && (
            <>


                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="sticky bottom-0">
                        <div className="flex items-center  bg-white dark:bg-slate-900 border-t border-slate-200 dark:border-slate-700 px-4 sm:px-6 md:px-5 h-16">

                            <div>
                                {
                                    isMuted ? (
                                        <div className={"bg-red-700 p-2 rounded-full hover:bg-red-500 cursor-pointer mr-2"}>
                                            <VolumeOffIcon
                                                className={'w-5 h-5  text-white '}
                                                onClick={() => {
                                                    const confirmDetail = {
                                                        title: t("app.tasks.unmute_title"),
                                                        message:t("app.tasks.unmute_description"),
                                                        endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, extras.taskDetail.id, NOTIFICATION])
                                                    };
                                                    setOpenConfirm(true);
                                                    setConfirmDetail(confirmDetail);
                                                }}
                                            />
                                        </div>
                                    ) : (

                                        <div className={"bg-green-700 p-2 rounded-full hover:bg-green-500 cursor-pointer mr-2"}>
                                            <VolumeUpIcon
                                                className={'w-5 h-5  text-white '}
                                                onClick={() => {
                                                    const confirmDetail = {
                                                        title: t("app.tasks.mute_title"),
                                                        message:t("app.tasks.mute_description"),
                                                        endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, extras.taskDetail.id, NOTIFICATION])
                                                    };
                                                    setOpenConfirm(true);
                                                    setConfirmDetail(confirmDetail);
                                                }}
                                            />
                                        </div>

                                    )
                                }
                            </div>

                            <div className="mr-3 flex-auto">
                                <InputText
                                    id={"message"}
                                    type={"text"}
                                    isShowError={false}
                                    isShowLabel={false}
                                    errors={errors.message}
                                    input={{
                                        ...register("message", { required: true }),
                                    }}
                                    label={t("app.provider.message")}
                                />
                            </div>
                            <InputSubmit
                                isLoading={extras.isLoadingSend}
                                disabled={watch('message')?.length === 0 ?? true}
                                label={t("app.common.send")}
                            />


                        </div>
                    </div>
                </form>

                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() =>{
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(r) => {
                            setMuted(!isMuted);
                            extras.onRefreshData(r);
                        }}
                    />
                )}
            </>
        )

    );
};
