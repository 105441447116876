import React from "react";
import { Link } from "react-router-dom";

const OptionalLink = ({ isLinked, children, ...props }) =>
  isLinked ? (
    <Link {...props}>{children}</Link>
  ) : (
    <div {...props}>{children}</div>
  );

export default OptionalLink;
