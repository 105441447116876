import React, {useEffect, useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import InputText from "../../../components/common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import {getPathParam, getSelectParam, getUnitSelectParam, getUnitSelectParams} from "../../../utils/converter";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import InputTextarea from "../../../components/common/form/input-textarea";
import {Link, useParams} from "react-router-dom";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {postQuotationProduct, putQuotationProduct} from "../../../api/quotation";
import toast from "react-hot-toast";
import InputRadioSquare from "../../../components/common/form/input-radio-square";
import {PlusIcon} from "@heroicons/react/outline";
import CatalogIcon from "../../../resources/icons/CatalogIcon";
import PurchaseHistory from "../../../components/common/card/purchase-history";
import InputSelect from "../../../components/common/form/input-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchCountries, fetchUnits} from "../../../reducers/configReducer";
import UploadFile from "../../../components/partials/upload/upload-file";
import InputDate from "../../../components/common/form/input-date";
import {CATALOG, CONTAINER, DIMENSIONS, PRODUCT, QUOTATION} from "../../../api/endpoints";
import WhiteButton from "../../../components/common/button/white-btn";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";
import PriceHistory from "../../../components/common/card/price-history";
import {permissionGroup, permissionSpecific, permissionType} from "../../../constants/permissions";
import InputDiscountNumber from "../../../components/common/form/input-discount-number";
import {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import DynamicSearch from "../../../components/partials/common/dynamic-search";

const STEP0 = 'type';
const STEP1 = 'info';
const STEP2 = 'package';
const STEP3 = 'pricing';
const STEP_SERVICE = 'service';


const ManageProduct = ({isOpen, setIsOpen, quotationDetail, containerDetail, isEditing, productInfo, onUpdateDetail, onRefreshProduct, isHideSensitiveData }) => {

    const { t } = useTranslation();
    let {id}  = useParams();
    const dispatch = useDispatch();

    const {handleSubmit, control, setValue, watch, formState: { errors }, register, clearErrors, setError} = useForm();
    const { isCountriesLoading, countries, unitList, isUnitsLoading } = useSelector((state) => state.config);

    const {  permissions } = useSelector((s) => s.userCompany);

    const isActiveProductPrice =  hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.VIEW);
    const isActivePurchaseHistory = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_PURCHASE_HISTORY);


    const isActiveSellingHistory = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_SELLING_HISTORY);
    const isActiveProductCreate =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.CREATE);
    const isActiveCreateCatalog = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_CREATE_PRODUCT_ON_CATALOG);


    const getStep = () => {
        if(isEditing){
            if(productInfo && productInfo.type === STEP_SERVICE){
                return STEP_SERVICE;
            }else{
                return STEP3;
            }
        }else{
            return STEP0;
        }
    }

    const [tab, setTab] = useState(getStep());

    const [isProductLoading, setProductLoading] = useState(false);
    const [productException, setProductException] = useState(false);
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const tabsNavigation = [
        { name: t("app.products.info"),  group: STEP1 },
        { name: t("app.products.package_title"), group: STEP2 },
        { name: t("app.quotations.pricing_config"), group: STEP3 }
    ];



    const clean = () => {
        setValue('id', undefined);
        setValue('product_name', undefined);
        setValue('product_reference', undefined);
        setValue('manufacture_reference', undefined);
        setValue('base_volume', undefined);
        setValue('base_gross_weight', undefined);
        setValue('base_net_weight', undefined);
        setValue('qty_per_packaging', undefined);
        setValue('base_price', undefined);
        setValue('quantity', undefined);
        setValue('length', undefined);
        setValue('width', undefined);
        setValue('height', undefined);
        setValue('purchase_price', undefined);
        setValue('note', undefined);
        setValue('price_note', undefined);
        setValue('type', undefined);
        setValue('country_origin', undefined);
        setValue('unit_value', undefined);
        setValue('hs_code', undefined);
        setValue('delivery_date', undefined);
        setValue('catalog_import', undefined);
        setValue('list_price', undefined);
        setValue('list_discount', undefined);
        setValue('discount_string', undefined);
        setValue('discount', undefined);
        setValue('discount_price', undefined);
        setValue('picture', null);
        setTab(STEP0);
        setProductLoading(false);
        setProductException(false);
        clearErrors('quantity');

    };

    const createProduct = async (data) => {

        if(data.purchase_price === undefined){
            data['purchase_price'] = null;
            data['list_price'] = null;
            data['list_discount'] = null;
        }



        if(data.base_price === undefined){
            data['base_price'] = null;
            data['discount_price'] = null;
            data['discount'] = null;
            data['quantity'] = null;
        }

        if(data.base_price > 0 && (!data.quantity)){
            data['quantity'] = 1;
        }

        if(data.dimensions){
            delete data.dimensions;
        }


        setProductLoading(true);
        (isEditing ? putQuotationProduct({ quotation_id: id, container_id: containerDetail.id, product_id: productInfo.id, data })
            : postQuotationProduct({ quotation_id: id, container_id: containerDetail.id, data }) )
            .then((response) => {

                if(isEditing){
                    toast.success(t("app.products.update_success"))
                }

                setProductLoading(false);
                onUpdateDetail(response);
                setIsOpen(false);
                clean();
            })
            .catch((e) => {
                setProductException(e.message);
                setProductLoading(false);
            });

    };


    const getFilteredOptions = () => {
        if(unitList.length > 0){

            let result = unitList.reduce(function (r, a) {
                let flag = a.code;
                r[flag] = r[flag] || [];
                r[flag].push(a.unit_values);
                return r;
            }, Object.create(null));

            let data = [];
            // eslint-disable-next-line
            Object.keys(result).map((r) => {
                data.push({label: t("app.products."+r), options: getUnitSelectParams(result[r][0]) });
            });
            return data;
        }
        return [];
    }


    useEffect(() => {
        if(isOpen){
            if(isEditing){
                setValue('id', productInfo.id);
                setValue('product_name', productInfo.product.name);
                setValue('product_reference', productInfo.product.product_reference);
                setValue('manufacture_reference', productInfo.product.manufacture_reference);
                setValue('type', productInfo.type);
                setValue('delivery_date', productInfo.delivery_date);

                setValue('note', productInfo.note);
                setValue('price_note', productInfo.price_note);

                setValue('base_volume', productInfo.product.volume);
                setValue('base_gross_weight', productInfo.product.gross_weight);
                setValue('base_net_weight', productInfo.product.net_weight);
                setValue('qty_per_packaging', productInfo.product.qty_per_packaging);


                setValue('length', productInfo.product.length);
                setValue('width', productInfo.product.width);
                setValue('height', productInfo.product.height);

                setTab(getStep());


                if (productInfo.country_origin) {
                    setValue('country_origin', getSelectParam(productInfo.country_origin, "name"));
                }

                if (productInfo.unit_value) {
                    setValue('unit_value', getUnitSelectParam(productInfo.unit_value));
                }else{
                    if (productInfo.product.unit_value) {
                        setValue('unit_value', getUnitSelectParam(productInfo.product.unit_value));
                    }
                }

                setValue('hs_code', productInfo.product.hs_code);


                setValue('base_price', productInfo.base_price);
                setValue('quantity', productInfo.quantity);
                validateQuantity(productInfo.quantity);

                if(productInfo.product.list_price){
                    setValue('list_price', productInfo.product.list_price);
                }else{
                    if (productInfo.product.purchase_price) {
                        setValue('list_price', productInfo.product.purchase_price);
                    }
                }

            }

            dispatch(fetchCountries());
            dispatch(fetchUnits());
        }


        // eslint-disable-next-line
    }, [setValue, isEditing, productInfo, isOpen, dispatch]);






    const onSubmit = async (data) => {
        if(isEditing){
            createProduct(data);
        }else{
            if(tab === STEP1){
                setTab(STEP2);
            }
            if(tab === STEP2){
                setTab(STEP3);
            }
            if(tab === STEP3){
                createProduct(data);
            }
        }
    };

    const onSubmitSingle = async (data) => {
        createProduct(data);
    };


    const validateQuantity = (quantity = null) => {

        let closedBoxes = watch('qty_per_packaging');
        if(closedBoxes > 0){

            let desiredQuantity = quantity;
            if(!desiredQuantity){
                desiredQuantity = watch('quantity');
            }

            if(closedBoxes === desiredQuantity){
                clearErrors('quantity')
            }

            if(isNaN(desiredQuantity)){
                clearErrors('quantity')
            }else{
                if (desiredQuantity === 0) {
                    setError('quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                }else{
                    if(closedBoxes > desiredQuantity){
                        setError('quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                    }else{
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if((closedBoxes * suggestedQuantityPerBox) === desiredQuantity){
                            clearErrors('quantity')
                        }else{
                            setError('quantity', { type: 'custom', message: t("app.container.product_predict")+(closedBoxes * suggestedQuantityPerBox)});
                        }
                    }
                }
            }



        }else {
            clearErrors('quote_quantity')
        }
    }


    return (
        <SlidePanel
            title={isEditing ? t("app.quotations.edit_inside") : t("app.quotations.add_inside")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
                clean();
            }}
            bg={'bg-white'}
            size='max-w-4xl'
            setClean={() => {
                clean();
            }}
        >
            <div className="space-y-6 pb-5">

                {
                    isEditing && tab !== STEP_SERVICE && (
                       <div className="mt-3">
                           <UnderlineTab
                               tabs={tabsNavigation}
                               activeGroup={tab}
                               setActiveGroup={(r) => {
                                   setTab(r);
                               }}
                           />
                       </div>
                    )
                }


                {
                    tab === STEP_SERVICE ? (
                        <form className={"col-span-2 mt-5"} onSubmit={handleSubmit(onSubmitSingle)}>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                <div className="col-span-4 ">
                                    <InputText
                                        id={"product_name"}
                                        type={"text"}
                                        errors={errors.product_name}
                                        input={{ ...register("product_name", { required: true }) }}
                                        label={t("app.form.name")}
                                    />
                                </div>




                                <div className="col-span-4 md:col-span-2">
                                    <Controller
                                        name="list_price"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                            <InputDiscountNumber
                                                label={t("app.quotations.purchase_price")}
                                                name={name}
                                                value={value}
                                                decimalScale={4}
                                                onChangeValues={(e) => {
                                                    setValue('list_discount',e.discount_string);
                                                    setValue('purchase_price',e.final_price);
                                                }}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                suffix={" " + (quotationDetail?.currency?.currency_symbol ?? '')}
                                                errors={error}
                                                isShowFinalPrice={true}
                                                discountList={productInfo?.product?.list_discount ?? []}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-span-4 md:col-span-2">
                                    <Controller
                                        name="base_price"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                            <InputDiscountNumber
                                                label={t("app.quotations.net_price")}
                                                name={name}
                                                value={value}
                                                decimalScale={4}
                                                onChangeValues={(e) => {
                                                    setValue('discount_string',e.discount_string);
                                                    setValue('discount',e.discount_string);
                                                    setValue('discount_price',e.final_price);
                                                }}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                suffix={" " + (quotationDetail?.currency?.currency_symbol ?? '')}
                                                errors={error}
                                                isShowFinalPrice={true}
                                                isShowPriceRange={isHideSensitiveData}
                                                discountList={productInfo?.discount ?? []}
                                                symbol={quotationDetail?.currency?.currency_symbol ?? ''}
                                                purchasePrice={watch('purchase_price')}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-span-4 md:col-span-4">
                                    <Controller
                                        name="quantity"
                                        rules={{ required: (watch("base_price") && watch("base_price") > 0) }}
                                        control={control}
                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                            <InputDiscountNumber
                                                label={t("app.quotations.quantity")}
                                                name={name}
                                                value={value}
                                                decimalScale={4}
                                                onChange={(e) => {
                                                    let price = e;
                                                    if(price === 0){
                                                        price = 1;
                                                    }
                                                    onChange(price);
                                                }}
                                                onChangeValues={(e) => {

                                                }}
                                                prefix={""}
                                                suffix={""}
                                                errors={error}
                                                isShowDiscount={false}
                                                isShowTotalPrice={true}
                                                symbol={quotationDetail?.currency?.currency_symbol ?? ''}
                                                basePrice={watch('discount_price')}

                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-span-4">
                                    <InputTextarea
                                        id={"price_note"}
                                        errors={errors.price_note}
                                        input={{
                                            ...register("price_note", { required: watch("purchase_price")?.length > 0 ?? false }),
                                        }}
                                        label={t("app.quotations.price_note")}
                                    />
                                </div>





                            </div>



                            {/* Warning */}
                            {productException && <Warning message={productException} />}



                            <div className={"flex  items-center justify-between mt-6"}>

                                <div>
                                    {
                                        !isEditing && (
                                            <Link
                                                to={'#'}
                                                onClick={() => {
                                                    setTab(STEP0);
                                                }}
                                                className="text-sm  inline-flex  items-center"
                                            >
                                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                {t("app.common.back")}
                                            </Link>
                                        )
                                    }
                                </div>

                                <InputSubmit
                                    isLoading={isProductLoading}
                                    label={isEditing ? t("app.container.edit_product") : t("app.container.add_product")}
                                />
                            </div>

                        </form>
                    ) : (
                        <form className={isEditing ? "col-span-2 ": "col-span-2 mt-5"} onSubmit={handleSubmit(onSubmit)}>


                            {
                                tab === STEP0 && (
                                    <div className="grid grid-cols-2 gap-6 mb-6 ">


                                        <InputRadioSquare
                                            name={"type"}
                                            errors={errors.type}
                                            input={{ ...register("type", { required: false }) }}
                                            value={"product"}
                                            onCheck={() => {
                                                setTab(STEP1);
                                            }}
                                            label={t("app.quotations.insert_product_title")}
                                            description={t("app.quotations.insert_product_description")}
                                            icon={<CatalogIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                        />


                                        <InputRadioSquare
                                            name={"type"}
                                            errors={errors.type}
                                            input={{ ...register("type", { required: false }) }}
                                            label={t("app.quotations.insert_service_title")}
                                            value={"service"}
                                            onCheck={() => {
                                                setTab(STEP_SERVICE);
                                            }}
                                            description={t("app.quotations.insert_service_description")}
                                            icon={<PlusIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                        />
                                    </div>
                                )
                            }




                            {
                                tab === STEP1 && (
                                    <>
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={"product_name"}
                                                    type={"text"}
                                                    isDisabled={productInfo?.product?.id ?? false}
                                                    errors={errors.product_name}
                                                    input={{ ...register("product_name", { required: true }) }}
                                                    label={t("app.form.name")}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={"product_reference"}
                                                    type={"text"}
                                                    isDisabled={productInfo?.product?.id ?? false}
                                                    errors={errors.product_reference}
                                                    input={{ ...register("product_reference", { required: true }) }}
                                                    label={t("app.products.product_code")}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={"manufacture_reference"}
                                                    type={"text"}
                                                    errors={errors.manufacture_reference}
                                                    input={{
                                                        ...register("manufacture_reference", { required: true }),
                                                    }}
                                                    label={t("app.products.manufacture_code")}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={"hs_code"}
                                                    type={"text"}
                                                    errors={errors.hs_code}
                                                    input={{ ...register("hs_code", { required: false }) }}
                                                    label={'Hs Code'}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="country_origin"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputSelect
                                                            label={t("app.products.country_origin")}
                                                            name={name}
                                                            options={countries}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={isCountriesLoading}
                                                            isDisabled={isCountriesLoading}
                                                            isClearable={true}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="unit_value"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error }}) => (
                                                        <InputSelect
                                                            label={t("app.products.unit_value")}
                                                            name={name}
                                                            onChange={(e) => {
                                                                onChange(e);

                                                            }}
                                                            options={getFilteredOptions()}
                                                            value={value}
                                                            isLoading={isUnitsLoading}
                                                            isDisabled={isUnitsLoading}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {
                                                (!productInfo ||  (productInfo && productInfo.product && !productInfo.product.id)) && (
                                                    <div className="col-span-4">
                                                        <UploadFile
                                                            fileTypeLabel={'PNG, JPEG, JPG'}
                                                            reference={productInfo?.product?.picture?.reference ?? null}
                                                            accept={["image/png","image/jpg","image/jpeg"]}
                                                            onUpload={(data) => {
                                                                setValue('picture', {media_url: data.media_url});
                                                            }}
                                                            onCancel={(r) => {
                                                                if(r){
                                                                    setValue('picture', null);
                                                                }
                                                            }}
                                                            onException={(e) => setProductException(e)}
                                                            isLoading={false}
                                                            label={t("app.products.image_cover")}
                                                        />
                                                    </div>
                                                )
                                            }

                                            <div className="col-span-4">
                                                <InputTextarea
                                                    id={"note"}
                                                    errors={errors.note}
                                                    input={{
                                                        ...register("note", { required: false }),
                                                    }}
                                                    label={t("app.quotations.note")}
                                                />
                                            </div>


                                        </div>


                                        <div className="flex  items-center justify-between mt-6">

                                            <div>
                                                {
                                                    !isEditing && (
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => {
                                                                setTab(STEP0);
                                                            }}
                                                            className="text-sm  inline-flex  items-center"
                                                        >
                                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                            {t("app.common.back")}
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                            <InputSubmit
                                                isLoading={isProductLoading}
                                                label={isEditing ? t("app.container.edit_product") : t("app.common.next")}
                                            />
                                        </div>
                                    </>

                                )
                            }


                            {
                                tab === STEP2 && (

                                    <>
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                            {
                                                isEditing && (
                                                    <div className="col-span-4 md:col-span-4">
                                                        <Controller
                                                            name="dimensions"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({
                                                                         field: { onChange, value, name },
                                                                         fieldState: { error },
                                                                     }) => (
                                                                <DynamicSearch
                                                                    selectLabel={t("app.products.package_title")}
                                                                    endpoint={getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, PRODUCT, productInfo.id, DIMENSIONS ])}
                                                                    label={'reference'}
                                                                    errors={error}
                                                                    value={value}
                                                                    name={name}
                                                                    onChange={(r) => {
                                                                        setValue('base_volume', r.data.volume );
                                                                        setValue('base_gross_weight', r.data.gross_weight);
                                                                        setValue('base_net_weight', r.data.net_weight);
                                                                        setValue('qty_per_packaging',r.data.qty_per_packaging );
                                                                        setValue('length', r.data.length);
                                                                        setValue('width', r.data.width);
                                                                        setValue('height', r.data.height);
                                                                        onChange(r)
                                                                    }}
                                                                    isClearable={false}
                                                                    isRequired={false}
                                                                    isDisabled={false}
                                                                    onResponse={(r) => {
                                                                        setValue('dimensions', r?.find(r =>
                                                                            r.data.volume === watch('base_volume') &&
                                                                            r.data.gross_weight === watch('base_gross_weight') &&
                                                                            r.data.net_weight === watch('base_net_weight') &&
                                                                            r.data.qty_per_packaging === watch('qty_per_packaging') &&
                                                                            r.data.length === watch('length') &&
                                                                            r.data.width === watch('width') &&
                                                                            r.data.height === watch('height')
                                                                        ) ?? null)
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            }


                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="base_volume"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.volume")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' m³'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="base_gross_weight"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.gross_weight")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' Kg'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="base_net_weight"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.net_weight")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' Kg'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="qty_per_packaging"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.quantity_box")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={""}
                                                            suffix={" "+(watch('unit_value')?.symbol ?? '')}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="length"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.length")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="width"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.width")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="height"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.height")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>


                                        </div>



                                        <div className="flex  items-center justify-between mt-6">

                                            <div>
                                                {
                                                    !isEditing && (
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => {
                                                                setTab(STEP1);
                                                            }}
                                                            className="text-sm  inline-flex  items-center"
                                                        >
                                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                            {t("app.common.back")}
                                                        </Link>
                                                    )
                                                }
                                            </div>


                                            <InputSubmit
                                                isLoading={isProductLoading}
                                                label={isEditing ? t("app.container.edit_product") : t("app.common.next")}
                                            />
                                        </div>

                                    </>


                                )
                            }



                            {
                                tab === STEP3 && (
                                    <>

                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                            <div className="col-span-4 md:col-span-2">
                                                <div className="flex items-center">
                                                    {
                                                        (isEditing && productInfo.product.id && isActivePurchaseHistory && isActiveProductPrice) && (
                                                            <div className={'mr-1'}>
                                                                <PurchaseHistory product={productInfo.product}  />
                                                            </div>
                                                        )
                                                    }
                                                    <label  className="block text-sm font-medium mb-1 text-gray-700">
                                                        {t("app.quotations.purchase_price")}
                                                    </label>
                                                </div>
                                                <Controller
                                                    name="list_price"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                        <InputDiscountNumber
                                                            label={t("app.quotations.net_price")}
                                                            isShowLabel={false}
                                                            name={name}
                                                            value={value}
                                                            decimalScale={4}
                                                            onChangeValues={(e) => {
                                                                setValue('list_discount',e.discount_string);
                                                                setValue('purchase_price',e.final_price);
                                                            }}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            suffix={" " + (quotationDetail?.currency?.currency_symbol ?? '')}
                                                            errors={error}
                                                            isShowFinalPrice={true}
                                                            discountList={productInfo?.product?.list_discount ?? []}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="col-span-4 md:col-span-2">
                                                <div className="flex items-center">
                                                    {
                                                        (isEditing && productInfo.product.id && isActiveSellingHistory) && (
                                                            <div className={'mr-1'}>
                                                                <PriceHistory prices={productInfo.last_prices}  />
                                                            </div>
                                                        )
                                                    }
                                                    <label  className="block text-sm font-medium mb-1 text-gray-700">
                                                        {t("app.quotations.base_price")}
                                                    </label>
                                                </div>
                                                <Controller
                                                    name="base_price"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                        <InputDiscountNumber
                                                            label={t("app.quotations.net_price")}
                                                            isShowLabel={false}
                                                            name={name}
                                                            value={value}
                                                            decimalScale={4}
                                                            onChangeValues={(e) => {
                                                                setValue('discount_string',e.discount_string);
                                                                setValue('discount',e.discount_string);
                                                                setValue('discount_price',e.final_price);
                                                            }}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            suffix={" " + (quotationDetail?.currency?.currency_symbol ?? '')}
                                                            errors={error}
                                                            isShowFinalPrice={true}
                                                            isShowPriceRange={isHideSensitiveData}
                                                            discountList={productInfo?.discount ?? []}
                                                            symbol={quotationDetail?.currency?.currency_symbol ?? ''}
                                                            purchasePrice={watch('purchase_price')}
                                                        />
                                                    )}
                                                />



                                            </div>



                                            <div className="col-span-4 md:col-span-2">

                                                <Controller
                                                    name="quantity"
                                                    rules={{ required: (watch("base_price") && watch("base_price") > 0) }}
                                                    control={control}
                                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                        <InputDiscountNumber
                                                            label={t("app.quotations.quantity")}
                                                            name={name}
                                                            value={value}
                                                            decimalScale={4}
                                                            onChange={(e) => {
                                                                let price = e;
                                                                if(price === 0){
                                                                    price = 1;
                                                                }
                                                                validateQuantity(price);
                                                                onChange(price);
                                                            }}
                                                            onChangeValues={(e) => {

                                                            }}
                                                            suffix={" "+(watch('unit_value')?.symbol ?? '')}
                                                            prefix={""}
                                                            errors={error}
                                                            isShowDiscount={false}
                                                            isShowTotalPrice={true}
                                                            symbol={quotationDetail?.currency?.currency_symbol ?? ''}
                                                            basePrice={watch('discount_price')}

                                                        />
                                                    )}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-2">
                                                <Controller
                                                    name="delivery_date"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                             }) => (
                                                        <InputDate
                                                            label={t("app.quotations.delivery_date")}
                                                            format={"YYYY-MM-D HH:mm:ss"}
                                                            placeholder={"Es. 01/12/2021"}
                                                            activeMinDate={false}
                                                            startDate={value}
                                                            disabled={false}
                                                            isShowTime={false}
                                                            errors={error}
                                                            name={name}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-4">
                                                <InputText
                                                    id={"price_note"}
                                                    type={"text"}
                                                    errors={errors.price_note}
                                                    input={{
                                                        ...register("price_note", { required: false }),
                                                    }}
                                                    label={t("app.quotations.price_note")}
                                                />
                                            </div>

                                        </div>

                                        <div className="flex before:flex-1  items-center justify-between mt-6">

                                            <div className="mt-5 w-full sm:w-1/2">
                                                <dl className="divide-y divide-gray-200">


                                                    {/*
                                                        !isEditing && (
                                                            <div className="py-3 flex justify-between text-sm font-medium">
                                                                <Controller
                                                                    name={"catalog_import"}
                                                                    rules={{ required: false }}
                                                                    control={control}
                                                                    render={({
                                                                                 field: { onChange, value, name },
                                                                                 fieldState: { error },
                                                                             }) => (
                                                                        <InputToggle
                                                                            label={t("app.quotations.create_on_catalog_title")}
                                                                            description={t("app.quotations.create_on_catalog_description_2")}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                            }}
                                                                            defaultChecked={value}
                                                                            errors={error}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )
                                                    */}

                                                </dl>
                                            </div>
                                        </div>




                                        {/* Warning */}
                                        {productException && <Warning message={productException} />}



                                        <div className={"flex  items-center justify-between mt-6"}>

                                            <div>
                                                {
                                                    !isEditing && (
                                                        <Link
                                                            to={'#'}
                                                            onClick={() => {
                                                                setTab(STEP2);
                                                            }}
                                                            className="text-sm  inline-flex  items-center"
                                                        >
                                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                            {t("app.common.back")}
                                                        </Link>
                                                    )
                                                }
                                            </div>





                                            <div className="flex items-center">


                                                {
                                                    (isEditing && !productInfo.product.id && (isActiveProductCreate && isActiveCreateCatalog))  && (
                                                        <div className="mr-2">
                                                            <WhiteButton
                                                                isLoading={false}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const removeContainerDetail = {
                                                                        title: t("app.quotations.create_on_catalog_title"),
                                                                        message: t("app.quotations.create_on_catalog_description"),
                                                                        endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, PRODUCT, productInfo.id, CATALOG ]),
                                                                    };
                                                                    setConfirmDetail(removeContainerDetail);
                                                                    setOpenConfirm(true);
                                                                }}
                                                                label={t("app.quotations.create_on_catalog_btn")}
                                                            />
                                                        </div>
                                                    )
                                                }


                                                <InputSubmit
                                                    isLoading={isProductLoading}
                                                    label={isEditing ? t("app.container.edit_product") : t("app.container.add_product")}
                                                />
                                            </div>
                                        </div>

                                    </>
                                )
                            }






                        </form>
                    )
                }



                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() =>{
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(r) => {
                            toast.success(t("app.products.update_success"))
                            setIsOpen(false);
                            onRefreshProduct(true);
                            clean();
                        }}
                    />
                )}


            </div>
        </SlidePanel>

    );
};

export default ManageProduct;
