import React, {useEffect, useState} from 'react'
import { getContainerProducts} from "../../../../../../../../api/container";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import ListSection from "../../../../../../../../components/layout/list-section";
import {useSelector} from "react-redux";
import {FilterButton, ImportButton} from "../../../../../../../../components/layout/page-header";
import {ArrowRightIcon, PlusIcon} from "@heroicons/react/outline";
import {makeid} from "../../../../../../../../utils/converter";
import ProductCell from "../../../../../../../../components/common/table/product-cell";
import TableDetail from "../../../../../../../../components/common/list/table-detail";
import InputFormattedNumber from "../../../../../../../../components/common/form/input-formatted-number";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

const SearchProduct = ({groupDetail, selectedProductList, onSelectedProductList, addInsideBox, onChangeStep}) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const { t } = useTranslation();

    const columns = [
        t("app.container.product"),
        t("app.container.quantity"),
        t("app.container.stock"),
        "",
    ];

    let {id, container_id}  = useParams();
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState({
        products: [],
        stocks: [],
        total_rows: 0,
    });

    const [query, setQuery] = useState("");

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const handleSelectAll = () => {
        if(detail.products.length > 0){
            setSelectAll(!selectAll);

            // eslint-disable-next-line
            detail.products.map(data => {
                data['quantity'] = data.available_quantity
            })



            setIsCheck(detail.products.filter(e => getProductQuantity(e) > 0 ));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const onChangeQuantity = (value, id) => {
        let filtered = [...isCheck];
        let foundIndex = filtered.findIndex((x) => x.id === id);
        filtered[foundIndex]['quantity'] = value
        setIsCheck(filtered)
    };


    const getProductQuantity = (element) => {
        let product_qty = 0;
        let quantity  = element.available_quantity;
        // eslint-disable-next-line
        selectedProductList.map((p) => {
            p.products.reduce(function(res, value){
                if(value.id === element.id){
                    product_qty += value.quantity;
                }
                return res;
            }, {});
        })

        if(product_qty > 0){
            quantity = element.available_quantity - product_qty;
        }

        return quantity;
    };

    const handleClick = e => {
        const { id, checked, value } = e.target;
        setSelectAll(false);
        let data = detail.products.find(data => data.id === id);
        data['quantity'] = value
        setIsCheck([...isCheck, data]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };


    const groupData = (response) => {
        let result = [];
        response.products.reduce(function(res, value) {

            let groupId = value.id+'-'+value.quantity_box;

            if (!res[groupId]) {
                res[groupId] = {
                    id: value.id,
                    product_reference: value.product.product_reference,
                    picture: value.product.picture,
                    manufacture_reference: value?.product?.manufacture_reference ?? false,
                    name: value.product.name,
                    available_quantity: 0,
                    quantity: 0,
                    quantity_box: value.quantity_box,
                    base_price: value.base_price,
                    base_volume: value.base_volume,
                    base_gross_weight: value.base_gross_weight,
                    base_net_weight: value.base_net_weight,
                    length: (value.length && value.length > 0) ? value.length : 0,
                    width: (value.width && value.width > 0) ? value.width : 0,
                    height: (value.height && value.height > 0) ? value.height : 0,
                };
                result.push(res[groupId])
            }
            res[groupId].available_quantity += value.available_quantity;
            return res;
        }, {});
        response['products'] =  result;
        return response;
    };





    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchContainerProducts() {
            if (loggedCompany) {
                setLoading(true);
                getContainerProducts({ shipping_id: id, container_id: container_id, params: { query: query }, signal })
                    .then((response) => {

                        groupData(response);

                        setDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setDetail({ products: [], total_rows: 0, stocks: []});
                        setLoading(false);
                    });
            }
        }

        fetchContainerProducts();
        return () => {
            controller.abort();
        };
    }, [query, id, loggedCompany, container_id]);


    return (
       <>

           <div className="sm:flex sm:items-center">
               <div className="sm:flex-auto">
                   <h1 className="text-xl font-medium text-gray-700">{t("app.container.group_configuration")}</h1>
                   <p className="mt-2 text-sm text-gray-700">
                       {t("app.container.group_configuration_description")} <b>{groupDetail?.group_name ?? ''}</b>
                   </p>
               </div>
               <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                   {
                       selectedProductList.length > 0  && (
                           <FilterButton
                               onClick={() => {
                                   onChangeStep();
                               }}
                               isRightIcon={true}
                               icon={
                                   <ArrowRightIcon
                                       className="w-4 h-4 ml-2"
                                       aria-hidden="true"
                                   />
                               }
                               text={t("app.common.back")}
                           />


                       )
                   }

               </div>
           </div>
           <ListSection
               columns={columns}
               totalRows={detail.total_rows}
               list={detail.products.filter(e => getProductQuantity(e) > 0 )}
               onSearch={setQuery}
               isLoading={isLoading}
               showMobile={false}
               desktopRow={ContainerDeskRow}
               hasCheckbox={true}
               selectAll={selectAll}
               handleSelectAll={handleSelectAll}
               extras={{
                   isCheck,
                   selectedProductList,
                   stocks: detail.stocks,
                   handleClick,
                   onChangeQuantity
               }}
           >

               {
                   isCheck.length > 0 && (
                       <div className="inline-flex items-center justify-center">
                           <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                           <ImportButton
                               onClick={() => {
                                   let result = [];

                                    if(addInsideBox){
                                        // eslint-disable-next-line
                                        isCheck.map(data =>{
                                            result.push(data)
                                        })
                                    }else{
                                        // eslint-disable-next-line
                                        isCheck.map(data =>{
                                            result.push({
                                                id: makeid(20),
                                                length: data.length,
                                                width: data.width,
                                                height: data.height,
                                                need_box: 0,
                                                products: [data],
                                            })
                                        })
                                    }
                                    onSelectedProductList(result);
                               }}
                               icon={
                                   <PlusIcon
                                       className="w-4 h-4 fill-current flex-shrink-0"
                                       aria-hidden="true"
                                   />
                               }
                           />
                       </div>
                   )
               }


               <div>

               </div>

           </ListSection>

       </>
    );
};


const ContainerDeskRow = ({ element, extras }) => {

    const {control, setValue, watch} = useForm();
    const { t } = useTranslation();

    let quantity  = element.available_quantity;
    let product_qty = 0;
    // eslint-disable-next-line
    extras.selectedProductList.map((p) => {
        p.products.reduce(function(res, value){
            if(value.id === element.id){
                product_qty += value.quantity;
            }
            return res;
        }, {});
    })

    if(product_qty > 0){
        quantity = element.available_quantity - product_qty;
    }



    useEffect(() => {
        setValue('quantity', quantity)
        // eslint-disable-next-line
    }, [setValue])


    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    return (
        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>


            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                       id={element.id}
                       value={watch('quantity')}
                       onChange={extras.handleClick} checked={isCheck}
                       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <div>
                    <ProductCell product={element} isActiveImage={true}/>
                </div>
            </TableDetail>
            <TableDetail extraClass={"w-1/5"}>

                {
                    isCheck ? (
                        <Controller
                            name={'quantity'}
                            rules={{ required: true }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    isShowLabel={false}
                                    maxValue={quantity}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        extras.onChangeQuantity(e, element.id);
                                        onChange(e);
                                    }}
                                    prefix={""}
                                    suffix={""}
                                    errors={error}
                                />
                            )}
                        />
                    ) : (
                        <NumberFormat
                            value={quantity}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                        />
                    )
                }



            </TableDetail>
            <TableDetail>
                <div className="text-sm text-gray-900">
                    <NumberFormat
                        value={extras.stocks.find(r => r.product_id === element.id)?.stock ?? 0 }
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                    />
                </div>
                <div className="text-xs text-gray-500">
                    {t("app.container.availability")}
                </div>
            </TableDetail>

            <TableDetail>

            </TableDetail>
        </tr>
    );

};



export default SearchProduct;
