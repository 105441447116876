import {useTranslation} from "react-i18next";
import React from "react";

const InputLineRadio = ({label,description, icon, name, errors, warning, defaultChecked = false, input, value, onCheck = null, extraClass = 'sm:w-48'}) => {

    const { t } = useTranslation();

    return (
        <label className="relative block cursor-pointer mt-2">
            <input type="radio"
                   name={name}
                   className="peer sr-only"
                   defaultChecked={defaultChecked}
                   onClick={() => {
                       if(onCheck){
                           onCheck(true);
                       }
                   }}
                   value={value}
                   {...input}
            />
            <div className=" h-28 flex items-center bg-white p-2 text-sm font-medium text-gray-800 p-4 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                {icon}
                <div className={"ml-3"}>
                    <div className="text-sm text-gray-900 capitalize mb-1">{label}</div>
                    <div className="text-xs text-gray-500">{description}</div>
                </div>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true" />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label.toLowerCase()})}</p>)}
        </label>
    )
};
export default InputLineRadio;
