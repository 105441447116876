import React, {useEffect, useState} from "react";
import BaseList from "../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../utils/converter";
import { FORWARDER, FORWARDER_LINE, FORWARDER_LINES } from "../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import {fetchCountries, fetchPorts} from "../../../reducers/configReducer";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";
import {useParams} from "react-router-dom";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {PlusIcon} from "@heroicons/react/outline";
import TruckIcon from "../../../resources/icons/TruckIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import AirIcon from "../../../resources/icons/AirIcon";
import { RadioGroup } from "@headlessui/react";
import { AIR, LAND, SEA } from "../../../constants/config";
import InputSelect from "../../../components/common/form/input-select";
import CitiesSearch from "../../../components/common/form/cities-search";
import InputPortSelect from "../../../components/common/form/input-port-select";
import RegionsSearch from "../../../components/common/form/regions-search";
import MunicipalitySearch from "../../../components/common/form/municipality-search";

const ForwarderLine = () => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);

 ;

    let { id } = useParams();


    const { permissions } = useSelector((s) => s.userCompany);

    const canCreate = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);


    return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.VIEW)]} renderBaseOnFail={true}>

        <div className="max-w-5xl mx-auto  py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center flex-wrap gap-4 ">
            <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
              {t("app.forwarder.traffic_line")}
            </h1>
          </div>
        </div>
        <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">
          {
            loggedCompany && (
              <BaseList
                columns={[
                  t("app.forwarder.destination"),
                  t("app.forwarder.area"),
                  "",
                ]}
                endpoint={getPathParam([FORWARDER, id, FORWARDER_LINES])}
                storeEndpoint={getPathParam([FORWARDER, id, FORWARDER_LINE])}
                updateEndpoint={getPathParam([FORWARDER, id, FORWARDER_LINE, ':id'])}
                baseResult={{ total_rows: 0, lines: [] }}
                resultLabel={'lines'}
                title={t("app.forwarder.traffic_line")}
                showHeader={true}
                showShippingType={true}
                mobRow={MobRow}
                deskRow={DeskRow}
                addForm={AddForm}
                icon={PlusIcon}
                addFormLabel={t("app.forwarder.add_line")}
                btnAddLabel={canCreate ? t("app.forwarder.add_line") : false}
                addPermission={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.CREATE)]}
                editForm={EditForm}
                editTitle={t("app.forwarder.traffic_line")}
                isActiveEdit={canView || canEdit}
              />
            )
          }
        </div>

      </BasePermissionWrapper>

    );
};


const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
    let { id } = useParams();

    const removeDetail = {
      title: t("app.forwarder.remove_line_title"),
      message: t("app.forwarder.remove_line_description"),
      endpoint: getPathParam([FORWARDER, id, FORWARDER_LINE, element.id])
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.DELETE);


    return (
      <li key={element.id} className="flex items-center gap-4 p-4">
        <div className="flex-grow">
          <div className="pb-4 items-center flex justify-between">
            <div className={"flex items-center"}>
              <div className="mr-4">
                {
                  (element.shipping_type && (element.shipping_type.code === LAND)) && (
                    <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                  )
                }

                {
                  (element.shipping_type && (element.shipping_type.code === SEA)) && (
                    <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                  )
                }
                {
                  (element.shipping_type && (element.shipping_type.code === AIR)) && (
                    <AirIcon className={'w-5 h-5 text-blue-1000'} />
                  )
                }
              </div>
              <div>


                {
                  (element.destination_type === 'city') && (
                    <>
                      <div className="text-sm text-gray-900 capitalize">
                        {element?.city?.name ?? ''}
                      </div>
                      {
                        element.region && (
                          <div className="text-xs text-gray-500 capitalize">
                            {element?.region?.name ?? '-'}
                          </div>
                        )
                      }
                    </>
                  )
                }



                {
                  (element.destination_type === 'country') && (
                    <>
                      <div className="text-sm text-gray-900 capitalize">
                        {element?.country?.name ?? ''}
                      </div>
                    </>
                  )
                }


                {
                  (element.destination_type === 'port') && (
                    <>
                      <div className="text-sm text-gray-900 capitalize">
                        {element?.port?.name ?? ''}
                      </div>
                      {
                        element.departure_port && (
                          <div className="text-xs text-gray-500 capitalize">
                            {t("app.shippings.departure_port")}:{" "}{element?.departure_port?.name ?? '-'}
                          </div>
                        )
                      }
                    </>
                  )
                }

              </div>
            </div>
              {
                  (canView || canEdit || canDelete) && (
                      <ActionsMenu>
                          {
                              (canView || canEdit) && (
                                  <button className={'text-left'} onClick={() => {
                                      extras.onEdit(true, element);
                                  }}
                                  >
                                      {t("app.common.edit")}
                                  </button>
                              )
                          }
                          {
                              canDelete && (
                                  <button className={'text-left'} onClick={() => {
                                      extras.onRemove(true, removeDetail);
                                  }}
                                  >
                                      {t("app.common.remove")}
                                  </button>
                              )
                          }
                      </ActionsMenu>
                  )
              }
          </div>
          <div className="flex flex-col">
            <CardDetail title={t("app.forwarder.area")}>
              {t("app.forwarder." + element.destination_type)}
            </CardDetail>
          </div>

        </div>
      </li>
  );
};

const DeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  let { id } = useParams();

  const removeDetail = {
    title: t("app.forwarder.remove_line_title"),
    message: t("app.forwarder.remove_line_description"),
    endpoint: getPathParam([FORWARDER, id, FORWARDER_LINE, element.id])
  };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.DELETE);


    return (
      <tr key={element.id}>
       
       
       
       
        <TableDetail extraClass="max-w-sm">
          <div className={"flex items-center"}>
            <div className="mr-4">
              {
                (element.shipping_type && (element.shipping_type.code === LAND)) && (
                  <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                )
              }

              {
                (element.shipping_type && (element.shipping_type.code === SEA)) && (
                  <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                )
              }
              {
                (element.shipping_type && (element.shipping_type.code === AIR)) && (
                  <AirIcon className={'w-5 h-5 text-blue-1000'} />
                )
              }
            </div>
            <div>


              {
                (element.destination_type === 'city') && (
                 <>
                    <div className="text-sm text-gray-900 capitalize">
                      {element?.city?.name ?? ''}
                    </div>
                    <div className="text-xs text-gray-500 capitalize">
                      {element?.city?.description ?? '-'}
                    </div>
                 </>
                )
              }


              {
                (element.destination_type === 'region') && (
                  <>
                    <div className="text-sm text-gray-900 capitalize">
                      {element?.region?.name ?? ''}
                    </div>
                    <div className="text-xs text-gray-500 capitalize">
                      {element?.region?.description ?? '-'}
                    </div>
                  </>
                )
              }

              {
                (element.destination_type === 'municipality') && (
                  <>
                    <div className="text-sm text-gray-900 capitalize">
                      {element?.municipality?.name ?? ''}
                    </div>
                    <div className="text-xs text-gray-500 capitalize">
                      {element?.municipality?.description ?? '-'}
                    </div>
                  </>
                )
              }



              {
                (element.destination_type === 'country') && (
                  <>
                    <div className="text-sm text-gray-900 capitalize">
                      {element?.country?.name ?? ''}
                    </div>
                  </>
                )
              }


              {
                (element.destination_type === 'port') && (
                  <>
                    <div className="text-sm text-gray-900 capitalize">
                      {element?.port?.name ?? ''}
                    </div>
                    {
                      element.departure_port && (
                        <div className="text-xs text-gray-500 capitalize">
                          {t("app.shippings.departure_port")}:{" "}{element?.departure_port?.name ?? '-'}
                        </div>
                      )
                    }
                  </>
                )
              }

            </div>
          </div>
        </TableDetail>

        <TableDetail>
          {t("app.common." + element.destination_type)}
        </TableDetail>

        <TableDetail>
            {
                (canView || canEdit || canDelete) && (
                    <ActionsMenu>
                        {
                            (canView || canEdit) && (
                                <button className={'text-left'} onClick={() => {
                                    extras.onEdit(true, element);
                                }}
                                >
                                    {t("app.common.edit")}
                                </button>
                            )
                        }
                        {
                            canDelete && (
                                <button className={'text-left'} onClick={() => {
                                    extras.onRemove(true, removeDetail);
                                }}
                                >
                                    {t("app.common.remove")}
                                </button>
                            )
                        }
                    </ActionsMenu>
                )
            }
        </TableDetail>
      </tr>
  );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

  const {
    handleSubmit,
    control,
    watch,
    setValue
  } = useForm();

  const dispatch = useDispatch();
  const { t } = useTranslation();


  const shippingTypes = [
    { type: 'land', name: '', icon: TruckIcon },
    { type: 'sea', name: '', icon: ShippingIcon },
    { type: 'air', name: '', icon: AirIcon },
  ];
  const [shippingType, setShippingType] = useState(shippingTypes[0])
  const { isCountriesLoading, countries, isPortsLoading, portList } = useSelector((state) => state.config);


  
  const onSubmit = async (data) => {

    if (shippingType.type !== SEA){

      if (data.country){
        data['destination_type'] = 'country';
        data['country'] = { id: data.country.id };
      }

      if (data.city) {
        data['destination_type'] = 'city';
        data['city'] = { id: data.city.id };
      }

      if (data.region) {
        data['destination_type'] = 'region';
        data['region'] = { id: data.region.id };
      }

      if (data.municipality) {
        data['destination_type'] = 'municipality';
        data['municipality'] = { id: data.municipality.id };
      }

      delete data['departure_port'];
      delete data['port'];
    }else{
        delete data['country'];
        delete data['city'];
        data['destination_type'] = 'port';
        data['departure_port'] = { id: data.departure_port.id };
        data['port'] = { id: data.port.id };
    }
    data['shipping_type'] = shippingType.type;
    onSubmitData(data);
  };

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchPorts());
  }, [dispatch]);


  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
            
            
          <fieldset className="col-span-2">
            <label
              className="block text-sm font-medium mb-1 text-gray-700"
            >
              {t("app.fulfillment.fulfillment_type")}<span className="text-red-500">*</span>
            </label>

            <RadioGroup value={shippingType} onChange={setShippingType} className="flex items-center  rounded-md ring-1 ring-gray-200">
              {shippingTypes.map((option) => (
                <RadioGroup.Option
                  key={option.name}
                  value={option}
                  className={({ active, checked }) =>
                    `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                    ${(shippingType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                  }
                >
                  <option.icon className="h-5 w-5" />
                  <span className={"ml-2"}>{option.name}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </fieldset>


          {
            shippingType.type !== SEA ? (

              <>


                <Controller
                  name="country"
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <InputSelect
                      label={t("app.forwarder.country")}
                      name={name}
                      options={countries}
                      value={value}
                      isRequired={true}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      isLoading={isCountriesLoading}
                      isDisabled={isCountriesLoading}
                      errors={error}
                    />
                  )}
                />
                
                <RegionsSearch
                  label={t("app.common.region")}
                  placeholder={t("app.common.region")}
                  isRequired={false}
                  selectedItem={watch('region') ?? null}
                  countryCode={watch('country')?.code ?? null}
                  isDisabled={false}
                  onSelect={(e) => {
                    setValue('region', e)
                  }}
                />
                    
                <CitiesSearch
                  label={t("app.common.city")}
                  placeholder={t("app.common.city")}
                  isRequired={false}
                  selectedItem={watch('city') ?? null}
                  countryCode={watch('country')?.code ?? null}
                  isDisabled={false}
                  onSelect={(e) => {
                    setValue('city', e)
                  }}
                />
                <MunicipalitySearch
                  label={t("app.common.municipality")}
                  placeholder={t("app.common.municipality")}
                  isRequired={false}
                  selectedItem={watch('municipality') ?? null}
                  countryCode={watch('country')?.code ?? null}
                  isDisabled={false}
                  onSelect={(e) => {
                    setValue('municipality', e)
                  }}
                />

              </>

            ) : (
              <>
                  <InputPortSelect
                    label={t("app.shippings.departure_port")}
                    placeholder={t("app.shippings.departure_port")}
                    isLoadingData={isPortsLoading}
                    data={(watch('departure_port') && !portList.some(vessel => vessel.id === watch('departure_port').id)) ? [...portList, watch('departure_port')] : portList}
                    selectedItem={watch('departure_port')}
                    onSelect={(e) => {
                      setValue('departure_port', e)
                    }}
                  />

                  <InputPortSelect
                    label={t("app.shippings.arrival_port")}
                    placeholder={t("app.shippings.arrival_port")}
                    isLoadingData={isPortsLoading}
                    data={(watch('port') && !portList.some(vessel => vessel.id === watch('port').id)) ? [...portList, watch('port')] : portList}
                    selectedItem={watch('port')}
                    onSelect={(e) => {
                      setValue('port', e)
                    }}
                  />
              
              </>
            )
          }
            

          </div>
        </div>

        {/* Warning */}
        {exception && (
            <Warning message={exception} />
        )}

        <div className="flex before:flex-1 items-center justify-between mt-6">
          <InputSubmit
            isLoading={isLoading}
            label={t("app.forwarder.add_line")}
          />
        </div>
      </form>
  );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

  const {
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
  const { isCountriesLoading, countries, isPortsLoading, portList } = useSelector((state) => state.config);


    useEffect(() => {
    if(data !== null){
      for (const k in data) {
        if (typeof data[k] === "object") {
          setValue(k, getSelectParam(data[k], "name"));
        }else{
          setValue(k, data[k]);
        }
      }
    }
  }, [setValue, data]);


  const onSubmit = async (data) => {
    delete data['shipping_type'];
    onSubmitData(data);
  };

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchPorts());
  }, [dispatch]);

  return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>

            <div className="space-y-4 pt-4 pb-5">

            {
              data.shipping_type.code !== SEA ? (
                <>

                  <Controller
                    name="country"
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <InputSelect
                        label={t("app.forwarder.country")}
                        name={name}
                        options={countries}
                        value={value}
                        isRequired={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        isLoading={isCountriesLoading}
                        isDisabled={isCountriesLoading}
                        errors={error}
                      />
                    )}
                  />

                  <RegionsSearch
                    label={t("app.common.region")}
                    placeholder={t("app.common.region")}
                    isRequired={false}
                    selectedItem={watch('region') ?? null}
                    countryCode={watch('country')?.code ?? null}
                    isDisabled={false}
                    onSelect={(e) => {
                      setValue('region', e)
                    }}
                  />

                  <CitiesSearch
                    label={t("app.common.city")}
                    placeholder={t("app.common.city")}
                    isRequired={false}
                    selectedItem={watch('city') ?? null}
                    countryCode={watch('country')?.code ?? null}
                    isDisabled={false}
                    onSelect={(e) => {
                      setValue('city', e)
                    }}
                  />
                  <MunicipalitySearch
                    label={t("app.common.municipality")}
                    placeholder={t("app.common.municipality")}
                    isRequired={false}
                    selectedItem={watch('municipality') ?? null}
                    countryCode={watch('country')?.code ?? null}
                    isDisabled={false}
                    onSelect={(e) => {
                      setValue('municipality', e)
                    }}
                  />

                </>
              ) : (
                <>
                  <InputPortSelect
                    label={t("app.shippings.departure_port")}
                    placeholder={t("app.shippings.departure_port")}
                    isLoadingData={isPortsLoading}
                    data={(watch('departure_port') && !portList.some(vessel => vessel.id === watch('departure_port').id)) ? [...portList, watch('departure_port')] : portList}
                    selectedItem={watch('departure_port')}
                    onSelect={(e) => {
                      setValue('departure_port', e)
                    }}
                  />

                  <InputPortSelect
                    label={t("app.shippings.arrival_port")}
                    placeholder={t("app.shippings.arrival_port")}
                    isLoadingData={isPortsLoading}
                    data={(watch('port') && !portList.some(vessel => vessel.id === watch('port').id)) ? [...portList, watch('port')] : portList}
                    selectedItem={watch('port')}
                    onSelect={(e) => {
                      setValue('port', e)
                    }}
                  />

                </>
              )
            }

            </div>
          </div>

          {/* Warning */}
          {exception && (
              <Warning message={exception} />
          )}

            {
                canEdit && (
                    <div className="mt-3">
                        <InputSubmit
                            isLoading={isEditLoading}
                            isFullWith={true}
                            label={t("app.form.save")}
                        />
                    </div>
                )
            }

        </form>

      </>
  );
}

export default ForwarderLine;
