import React, { useEffect, useState } from 'react'
import ListSection from "../../../components/layout/list-section";
import { useSelector } from "react-redux";
import { FilterButton, ImportButton } from "../../../components/layout/page-header";
import { ArrowRightIcon, MailIcon, PlusIcon } from "@heroicons/react/outline";
import TableDetail from "../../../components/common/list/table-detail";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import CardDetail from "../../../components/common/list/card-detail";
import { BOOKINGS, COMPANY, CONFIGURATION, EMAIL_INTEGRATION, EMAIL_TEMPLATE, FORWARDER, FORWARDERS_LINE, FORWARDER_CONTACT, LOGISTICS } from '../../endpoints';
import { getElement, postElement, putElement } from '../../../api/config';
import { getEmailsParam, getEmailsParams, getPathParam, getWebPathParam, serialize } from '../../../utils/converter';
import SlidePanel from '../../../components/layout/slide-panel';
import Initials from '../../../components/common/avatar/initials';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/outline';
import { Controller, useForm } from 'react-hook-form';
import InputSubmit from '../../../components/common/form/input-submit';
import { emailRegex } from '../../../constants/regex';
import { EMAIL, EMAIL_PREVIEW, EMAIL_TEMPLATES, FORWARDER_CONTACTS, FULFILLMENT_NEGOTIATION } from '../../../api/endpoints';
import InputToggle from '../../../components/common/form/input-toggle';
import InputText from '../../../components/common/form/input-text';
import FormPopup from '../../../components/common/popup/form-popup';
import { PICKUP } from '../../../constants/config';
import DynamicSearch from '../../../components/partials/common/dynamic-search';
import LoaderWrapper from '../../../components/common/loader/loader-wrapper';
import EmailSelect from '../../../components/common/form/email-select';
import InputUserSelect from '../../../components/common/form/input-user-select';
import { useNavigate } from 'react-router-dom';
import { Warning } from '../../../components/common/alert/banner';


const FulfillmentNegotiation = ({ fulfillments, shippingType, fulfillmentType, isOpen, setIsOpen }) => {

    const { t } = useTranslation();
    const STEP_1 = 'select_forwarder';
    const STEP_2 = 'configure_forwarder';

    const [selectedForwarders, setSelectedForwarders] = useState([]);
    const [params, setParams] = useState({});
    const [step, setStep] = useState(STEP_1);

    const [isOpenSendMail, setOpenSendMail] = useState(false);
    const [isAppliedFilter, setAppliedFilter] = useState(false);

   
    const getFulfillmentParams = () => {
        let data = { filters: [], portFilters: [] };
        if (fulfillments.length > 0) {
            
            // eslint-disable-next-line
            fulfillments.map(fulfillment => {
                if (fulfillment.connection_address && fulfillment.connection_address.city){
                    let cr = "MU|" + fulfillment.connection_address.municipality.internal_code + "|CI|" + fulfillment.connection_address.city.internal_code + "|RE|" + fulfillment.connection_address.region.internal_code + "|CO|" + fulfillment.connection_address.country.internal_code;
                    if (!data.filters.find(r => r === cr)) {
                        data.filters.push(cr);
                    }
                }

                if (fulfillment.departure_port && fulfillment.destination_port) {
                    let cr = "DP|" + fulfillment.departure_port.id + "|AP|" + fulfillment.destination_port.id;
                    if (!data.portFilters.find(r => r === cr)) {
                        data.portFilters.push(cr);
                    }
                }
            })
            

            data.filters = data.filters.length > 0 ? data.filters.map((n) => n).join(',') : null;
            data.portFilters = data.portFilters.length > 0 ? data.portFilters.map((n) => n).join(',') : null;
        }
        return data
    };
    


    useEffect(() => {
        if (isOpen) {
            setParams(getFulfillmentParams());
        }
        // eslint-disable-next-line
    }, [isOpen]);


    useEffect(() => {
        if (selectedForwarders.length === 0 && step === STEP_2) {
            setStep(STEP_1);
        }
    }, [selectedForwarders, step]);


    const isActiveSend = () => {
        let data = false
        if (selectedForwarders.length > 0) {
            // eslint-disable-next-line
            selectedForwarders.map(selectedForwarder => {
                if (selectedForwarder.primary_contacts && selectedForwarder.primary_contacts.length === 0) {
                    data = true
                }
                if (!selectedForwarder.primary_contacts) {
                    data = true
                }
            })
        }
        return data
    };


    return (
        <SlidePanel
            title={t('app.fulfillment.create_booking', { values: fulfillments.length })}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
            hasDivider={false}
            bg={'bg-white'}
            size='max-w-4xl'
            setClean={() => {
                
            }}
        >
        

            {
                step === STEP_1 && (
                    <>
                        <div className="sm:flex sm:items-center my-3">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-medium text-gray-900">{t("app.fulfillment.nogo_title")}</h1>
                                <p className="mt-2 text-sm text-gray-800">
                                    {t("app.fulfillment.nogo_description", { type: fulfillmentType.name })}
                                </p>
                            </div>

                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                {
                                    selectedForwarders.length > 0 && (
                                        <FilterButton
                                            onClick={() => {
                                                setStep(STEP_2);
                                            }}
                                            isRightIcon={true}
                                            icon={
                                                <ArrowRightIcon
                                                    className="w-4 h-4 ml-2"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t("app.common.back")}
                                        />
                                    )
                                }
                            </div>
                        </div>

                        


                        {
                            isOpen && (
                                <ForwarderList 
                                    isOpen={isOpen}
                                    params={params} 
                                    shippingType={shippingType} 
                                    selectedForwarders={selectedForwarders} 
                                    setSelectedForwarders={(r) => { setSelectedForwarders(r); setStep(STEP_2); }}
                                    onAppliedFilter={(r) => {
                                        setAppliedFilter(r);
                                    }}
                                />
                            )
                        }
                    </>
                )
            }

            {
                step === STEP_2 && (
                    <>
                        <div className="my-3">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-medium text-gray-900">{t("app.fulfillment.conf_title")}</h1>
                                <p className="mt-2 text-sm text-gray-800">
                                    {t("app.fulfillment.conf_description", { type: fulfillmentType.name })}
                                </p>
                            </div>

                            <div className="mt-4 flex items-center justify-between">
                                <div
                                    className="text-sm underline inline-flex items-center cursor-pointer"
                                    onClick={() => { setStep(STEP_1); }}
                                >
                                    <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                    {t("app.common.back")}
                                </div>


                                <FilterButton
                                    onClick={() => {
                                        setOpenSendMail(true);
                                    }}
                                    disabled={isActiveSend()}
                                    isRightIcon={false}
                                    icon={
                                        <MailIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                        />
                                    }
                                    text={t("app.fulfillment.send_email")}
                                />
                            </div>
                        </div>

                        <div className=' mb-10'>
                            <ForwarderCard 
                                isOpen={isOpen} 
                                fulfillmentType={fulfillmentType} 
                                shippingType={shippingType}
                                fulfillments={fulfillments}
                                forwarderList={selectedForwarders}
                                isAppliedFilter={isAppliedFilter}
                                onRemove={(res) => {
                                    setSelectedForwarders(selectedForwarders.filter(r => r.id !== res.id ))
                                }} 
                                onUpdateData={(r) => {
                                    setSelectedForwarders(r)
                                }}
                            />
                        </div>    


                        {
                            isOpenSendMail && (
                                <SendEmail
                                    fulfillments={fulfillments}
                                    selectedForwarders={selectedForwarders}
                                    isOpenPreview={isOpenSendMail}
                                    setOpenPreview={setOpenSendMail}
                                    shippingType={shippingType}
                                    fulfillmentType={fulfillmentType}
                                />
                            )
                        }
                      
                        
                    </>
                )
            }

        </SlidePanel>
    );
};



const SendEmail = ({ shippingType, fulfillmentType, selectedForwarders, fulfillments, isOpenPreview, setOpenPreview }) => {
    
    

    const { t } = useTranslation();
    let navigate = useNavigate();

    const { detail, loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);


    const {
        // eslint-disable-next-line
        formState: { errors },
        handleSubmit,
        control
    } = useForm();


    const onSubmit = async (data) => {
        if (loggedCompany) {

            let forworderData = [];
            // eslint-disable-next-line
            selectedForwarders.map(selectedForwarder => {
                forworderData.push({ forwarder: { id: selectedForwarder.id }, first_contact: selectedForwarder.primary_contacts, cc_contact: selectedForwarder?.cc_recipients ?? [], create_line: selectedForwarder?.create_line ?? false  })
            })


            let fulfels = [];
            // eslint-disable-next-line
            fulfillments.map(fulfillment => {
                fulfels.push({ id: fulfillment.id },)
            })

            let users = [];
            if (selectedUsers.length > 0) {
                // eslint-disable-next-line
                selectedUsers.map((user) => {
                    users.push({ user: { id: user.id } })
                })
            }

            let dataSend = {
                fulfillments: fulfels,
                forwarders: forworderData,
                company_email_template: { id: data.email_template.id },
                company_email: { id: data.mail_provider.id },
                users: users,
                type: fulfillmentType, 
                shipping_type: shippingType.type, 
                send: true
            }

            setLoading(true);
            setException(false);
            postElement(getPathParam([COMPANY, loggedCompany.id, FULFILLMENT_NEGOTIATION]), dataSend)
                .then(response => {
                    navigate(getWebPathParam([LOGISTICS, BOOKINGS]) + serialize({ booking_id: response.id }));
                    setOpenPreview(false);
                }).catch(e => {
                    setException(e.message);
                    setLoading(false);
                });
        }
    };




    return (
        <FormPopup
            title={t("app.fulfillment.send_email")}
            defaultWith={'max-w-xl'}
            isActiveFormPm={true}
            isOpen={isOpenPreview}
            setIsOpen={setOpenPreview}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                        <div>
                            <Controller
                                name="mail_provider"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <DynamicSearch
                                        selectLabel={t("app.provider.email_from")}
                                        endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL]) + serialize({ filter_by: 'active' })}
                                        label={'email'}
                                        errors={error}
                                        value={value}
                                        name={name}
                                        onChange={(r) => {
                                            onChange(r);
                                        }}
                                        isClearable={false}
                                        isRequired={true}
                                        isDisabled={false}

                                    />
                                )}
                            />

                            <div className={"flex justify-between before:flex-1"}>
                                <a target="__blank" href={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION])} className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                    {t("app.provider.add_name")}
                                </a>
                            </div>
                        </div>

                        <div>
                            <Controller
                                name="email_template"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <DynamicSearch
                                        selectLabel={t("app.company_preference.template")}
                                        endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATES])}
                                        responseLabel={'templates'}
                                        label={'title'}
                                        errors={error}
                                        value={value}
                                        name={name}
                                        onChange={(r) => {
                                            onChange(r);
                                        }}
                                        isClearable={false}
                                        isRequired={true}
                                        isDisabled={false}

                                    />
                                )}
                            />

                            <div className={"flex justify-between before:flex-1"}>
                                <a target="__blank" href={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE])} className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                    {t("app.settings.email_template_add")}
                                </a>
                            </div>
                        </div>
                      


                        <div className='mb-2'>
                            <InputUserSelect
                                label={t("app.fulfillment.include_users")}
                                placeholder={''}
                                multiple={true}
                                excludeId={detail.id}
                                includeAll={false}
                                selectedUsers={selectedUsers}
                                onSelect={setSelectedUsers}
                            />
                            <div className={"flex justify-start  mt-1 flex items-center text-xs text-gray-500"}>
                                {t("app.fulfillment.cc_user_desc")}
                            </div>
                        </div>

                    </div>

                    {/* Warning*/}
                    {exception && (
                        <Warning message={exception} />
                    )}

                    <div className="flex  items-center justify-end mt-6">
                        <InputSubmit
                            disabled={false}
                            isLoading={isLoading}
                            label={t("app.fulfillment.send_email")}
                        />
                    </div>

                </div>
            </form>
        </FormPopup>
    );
}




const ForwarderList = ({ isOpen, params, shippingType, selectedForwarders, setSelectedForwarders, onAppliedFilter }) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState(selectedForwarders ?? []);
    const [isLoading, setLoading] = useState(true);

    const [forwarders, setForwarders] = useState({ filter_applied: false, forwarders: [], total_rows: 0 });
    const [query, setQuery] = useState("");


    const columns = [
        {
            header: t("app.fulfillment.forwarder"),
            id: 'business_name',
            accessor: r => r.business_name,
        },
        {
            header: t("app.fulfillment.last_price"),
            id: 'reference',
            accessor: r => r.last_price.length > 0 ? r.last_price[0].price : 0,
        },
        {
            header: t("app.fulfillment.primary_contact"),
            id: 'reference',
            accessor: r => r?.primary_contact?.email ?? '',
        },
    ];

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (isOpen && loggedCompany) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, FORWARDERS_LINE]), signal, {
                        ...{ query: query, shipping_type: shippingType.type }, ...params
                    });
                    setForwarders(res);
                    onAppliedFilter(res.filter_applied);
                } catch (e) {
                    setForwarders({ filter_applied: false, forwarders: [], total_rows: 0 });
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, loggedCompany, query]);



    const handleSelectAll = () => {
        if (forwarders.forwarders.length > 0) {
            setSelectAll(!selectAll);
            setIsCheck(forwarders.forwarders);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };


    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let forwarderList = forwarders.forwarders.find(r => r.id === id);
        setIsCheck([...isCheck, forwarderList]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };
    

    
    return (

        <>
       
            {( (forwarders?.forwarders ?? []).length > 0 && (forwarders?.filter_applied ?? false) === false ) && (
               <div className='mb-3'>
                    <Warning message={t("app.fulfillment.forwarder_no_match")} />
               </div>
            )}
            <div className='border  border-gray-100 mb-10'>

                <ListSection
                    title={t("app.common.forwarders")}
                    columns={columns}
                    totalRows={forwarders.total_rows}
                    list={forwarders?.forwarders ?? []}
                    onSearch={setQuery}
                    isLoading={isLoading}
                    mobileRow={MobRow}
                    desktopRow={DeskRow}
                    hasCheckbox={true}
                    isDynamicSearch={true}
                    selectAll={selectAll}
                    handleSelectAll={handleSelectAll}
                    isTableSort={true}
                    sortBy={sortBy}
                    onSortBy={(n) => {
                        setSortBy(n);
                    }}
                    extras={{
                        isCheck,
                        selectedForwarders,
                        handleClick
                    }}
                >

                    {
                        isCheck.length > 0 && (
                            <div className="inline-flex items-center justify-center">
                                <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.fulfillment.item_selected")}</p>
                                <ImportButton
                                    onClick={() => {
                                        setSelectedForwarders(isCheck)
                                    }}
                                    icon={
                                        <PlusIcon
                                            className="w-4 h-4 fill-current flex-shrink-0"
                                            aria-hidden="true"
                                        />
                                    }
                                />
                            </div>
                        )
                    }


                </ListSection>
            </div>
        
        </>

     
    )
}

const MobRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { t } = useTranslation();

    return (
        <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
            <div className="flex-grow">


                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex justify-between items-center"}>
                        <input type="checkbox"
                            id={element.id}
                            onChange={extras.handleClick}
                            checked={isCheck}
                            className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                       
                        <span className="flex min-w-0 items-center justify-between space-x-1 mt-2 lg:hidden">
                            <Initials
                                color={element?.color ?? ''}
                                name={element?.name ?? ''}
                                isVerified={false}
                            />
                            <span className="flex min-w-0 flex-1 flex-col">
                                <span className="truncate text-sm font-medium text-gray-900">{element?.name ?? ''}</span>
                            </span>
                        </span>

                    </div>
                </div>



                <div className="flex flex-col">
                    <CardDetail title={t("app.fulfillment.last_price")}>
                        {
                            element.last_price.length > 0 ? (
                                <NumberFormat
                                    value={element.last_price[0].price ?? 0}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    suffix={" €"}
                                />
                            ) : (
                                    t("app.fulfillment.no_last_price")
                            )
                        }

                    </CardDetail>



                    <CardDetail title={t("app.fulfillment.primary_contact")}>
                        {
                            element.primary_contact ? (
                                <>
                                    <div className="text-sm text-gray-900">
                                        {element.primary_contact.first_name}{' '}{element.primary_contact.last_name}
                                    </div>
                                    <div className="text-xs flex-wrap text-gray-700">
                                        {element.primary_contact.email}
                                    </div>
                                </>
                            ) : (
                                t("app.container.container_not_defined")
                            )
                        }
                    </CardDetail>
                  


                </div>
            </div>
        </li>
    );
};


const DeskRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { t } = useTranslation();


    return (

        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                    id={element.id}
                    onChange={extras.handleClick}
                    checked={isCheck}
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <span className="flex items-center">
                    <Initials
                        color={element?.color ?? ''}
                        name={element?.name ?? ''}
                        isVerified={false}
                    />
                    <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900">{element?.name ?? ''}</span>
                    </span>
                </span>
            </TableDetail>

            <TableDetail>
               
                {
                    element.last_price.length > 0 ? (
                        <NumberFormat
                            value={element.last_price[0].price ?? 0}
                            displayType={"text"}
                            decimalScale={2}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={" €"}
                        />
                    ) : (
                            t("app.fulfillment.no_last_price")
                    )
                }

                
            </TableDetail>

            <TableDetail >
                {
                    element.primary_contact ? (
                        <>
                            <div className="text-sm text-gray-900">
                                {element.primary_contact.first_name}{' '}{element.primary_contact.last_name}
                            </div>
                            <div className="text-xs flex-wrap text-gray-700">
                                {element.primary_contact.email}
                            </div>
                        </>
                    ) : (
                        t("app.container.container_not_defined")
                    )
                }
            </TableDetail>
        </tr>
    );
};


const ForwarderCard = ({ forwarderList, fulfillments, fulfillmentType, shippingType, onRemove, onUpdateData, isAppliedFilter }) => {

    return (
        <>
            <div className="mt-6">
                <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2">

                {
                    forwarderList.map((forwarder, index) => (
                        <ForwarderDetail
                            key={forwarder.id}
                            forwarderList={forwarderList}
                            fulfillmentType={fulfillmentType}
                            shippingType={shippingType}
                            fulfillments={fulfillments}
                            forwarder={forwarder}
                            isAppliedFilter={isAppliedFilter}
                            onRemove={() => {
                                onRemove(forwarder)
                            }}
                            onUpdateData={onUpdateData}

                        />
                    ))

                }
                </ul>
            </div>

        </>
    )
}


const ForwarderDetail = ({ forwarderList, fulfillments, shippingType, forwarder, fulfillmentType, onRemove, onUpdateData, isAppliedFilter }) => {

    const { t } = useTranslation();


    // get Shipping
    const [defaultEmails, setDefaultEmails] = useState([]);
    const [CcEmails, setCcEmails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isOpenAdd, setOpenAdd] = useState(false);
    const [isAddDefault, setAddDefault] = useState(false);
    const [isOpenPreview, setOpenPreview] = useState(false);
    
    const {
        watch,
        control,
        setValue
    } = useForm({ mode: "onBlur" });



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (forwarder) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([FORWARDER, forwarder.id, FORWARDER_CONTACTS]), signal);
                    setDefaultEmails(getEmailsParams(res.contacts.filter(r => r.is_default), "email"));
                    setCcEmails(getEmailsParams(res.contacts.filter(r => r.is_default === false), "email"));
                    
                    if (forwarder.primary_contacts && forwarder.primary_contacts.length > 0) {
                        setValue('primary_recipient', forwarder.primary_contacts);
                    }else{
                        if (forwarder.primary_contact) {
                            setValue('primary_recipient', getEmailsParam(res.contacts.find(r => r.id === forwarder.primary_contact.id), 'email'));
                        }
                    }

                    if (forwarder.cc_recipients && forwarder.cc_recipients.length > 0) {
                        setValue('cc_recipient', forwarder.cc_recipients);
                    }

                    if (forwarder.create_line) {
                        setValue('create_line', forwarder.create_line);
                    }
                    

                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [forwarder]);


    useEffect(() => {
        updatePrimaryContacts(watch('primary_recipient'));
        // eslint-disable-next-line
    }, [watch('primary_recipient')]);



    const updatePrimaryContacts = (e) => {
        let filtered = [...forwarderList];
        let foundIndex = filtered.findIndex((x) => x.id === forwarder.id);
        filtered[foundIndex]['primary_contacts'] = e;
        onUpdateData(filtered)
    };


    useEffect(() => {
        updateCcContacts(watch('cc_recipient'));
        // eslint-disable-next-line
    }, [watch('cc_recipient')]);



    const updateCcContacts = (e) => {
        let filtered = [...forwarderList];
        let foundIndex = filtered.findIndex((x) => x.id === forwarder.id);
        filtered[foundIndex]['cc_recipients'] = e;
        onUpdateData(filtered)
    };


    return (

        <>

            <li className="col-span-1 border flex flex-col divide-y divide-gray-200 rounded-lg bg-white" >
                
                
                <div className='flex items-center justify-between'>
                    <span className="flex items-center py-3 px-6">
                        <Initials
                            color={forwarder?.color ?? ''}
                            name={forwarder?.name ?? ''}
                            isVerified={false}
                        />
                        <span className="flex min-w-0 text-left flex-1 flex-col">
                            <span className="truncate text-sm font-medium whitespace-pre-line text-gray-900">{forwarder?.name ?? ''}</span>
                        </span>
                    </span>

                    <span className='cursor-pointer' onClick={onRemove}>
                     <TrashIcon className='h-5 w-5 text-red-800 hover:text-red-700 mr-3' />
                    </span>
                </div>
                
                
                
                
                <div className="flex flex-1 flex-col p-6">

                    <dl className="flex flex-grow flex-col justify-start">
                        <div className="">
                            <Controller
                                name="primary_recipient"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <EmailSelect
                                        label={t("app.fulfillment.primary_recipient")}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        options={defaultEmails}
                                        value={value}
                                        isMulti={false}
                                        isClearable={true}
                                        isShowLabel={true}
                                        isLoading={isLoading}
                                        isDisabled={isLoading}
                                        errors={error}
                                    />
                                )}
                            />
                            <div className={"flex justify-between before:flex-1"}>
                                <div className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer" onClick={() => {
                                    setAddDefault(true);
                                    setOpenAdd(true);
                                }}>
                                    {t("app.fulfillment.add_email")}
                                </div>
                            </div>
                        </div>


                        <div className="mt-4">
                            <Controller
                                name="cc_recipient"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <EmailSelect
                                        label={t("app.fulfillment.cc_recipient")}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        regex={emailRegex}
                                        options={CcEmails}
                                        value={value}
                                        isMulti={true}
                                        isClearable={true}
                                        isShowLabel={true}
                                        isLoading={isLoading}
                                        isDisabled={isLoading}
                                        errors={error}
                                    />
                                )}
                            />
                            <div className={"flex justify-between before:flex-1"}>
                                <div className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer" onClick={() => {
                                    setAddDefault(false);
                                    setOpenAdd(true);
                                }}>
                                    {t("app.fulfillment.add_email")}
                                </div>
                            </div>
                        </div>

                        
                        {
                            !isAppliedFilter && (
                                <div className="mt-2">
                                    <Controller
                                        name={"create_line"}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputToggle
                                                label={t("app.fulfillment.create_line_title")}
                                                description={fulfillmentType === PICKUP ? t("app.fulfillment.p_create_line_desc") : t("app.fulfillment.d_create_line_desc")}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    let filtered = [...forwarderList];
                                                    let foundIndex = filtered.findIndex((x) => x.id === forwarder.id);
                                                    filtered[foundIndex]['create_line'] = e;
                                                    onUpdateData(filtered)

                                                }}
                                                isDisabled={false}
                                                defaultChecked={value}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </div>
                            )
                        }

                       
                    </dl>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex hover:bg-gray-50 w-0 flex-1">
                            <div 
                                onClick={() => {
                                    setOpenPreview(true);
                                }}
                                className="cursor-pointer relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                {t("app.fulfillment.preview_email")}
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            
            
            {
                isOpenAdd && (
                    <AddContactForm
                        forwarder={forwarder}
                        isOpenAdd={isOpenAdd}
                        setOpenAdd={setOpenAdd}
                        isAddDefault={isAddDefault}
                        onSubmitData={(res, data) => {
                            setDefaultEmails(getEmailsParams(res.contacts.filter(r => r.is_default), "email"));
                            setCcEmails(getEmailsParams(res.contacts.filter(r => r.is_default === false), "email"));
                            
                            if (res.contacts.filter(r => r.is_default && r.email === data.email).length === 1) {
                                setValue('primary_recipient', getEmailsParam(res.contacts.find(r => r.is_default && r.email === data.email), 'email'))
                            }
                            
                            if (res.contacts.filter(r => !r.is_default && r.email === data.email).length === 1) {
                                if (watch('cc_recipient') === undefined){
                                    setValue('cc_recipient', getEmailsParam(res.contacts.find(r => r.is_default && r.email === data.email), 'email'))
                                }else{
                                    setValue('cc_recipient', [...watch('cc_recipient'), getEmailsParam(res.contacts.find(r => r.email === data.email), 'email')])
                                }
                            }
                        }}
                    />

                )
            }

            {
                isOpenPreview && (
                    <PreviewEmail
                        fulfillments={fulfillments}
                        forwarder={forwarder}
                        fulfillmentType={fulfillmentType}
                        shippingType={shippingType}
                        isOpenPreview={isOpenPreview}
                        setOpenPreview={setOpenPreview}
                       
                    />

                )
            }
           
        </>
    )
}


const AddContactForm = ({ forwarder, isOpenAdd, setOpenAdd, isAddDefault, onSubmitData}) => {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const { t } = useTranslation();


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const onSubmit = async (data) => {
        data['is_default'] = isAddDefault;
        setLoading(true);
        setException(false);
        postElement(getPathParam([FORWARDER, forwarder.id, FORWARDER_CONTACT]), data)
            .then(response => {
                onSubmitData(response, data);
                setOpenAdd(false);
                setLoading(false);
            }).catch(e => {
                console.log(e);
                setLoading(false);
            });
    };

    




    return (
        <FormPopup
            title={t("app.forwarder.add_name")}
            defaultWith={'max-w-2xl'}
            isActiveFormPm={true}
            isOpen={isOpenAdd}
            setIsOpen={setOpenAdd}
        >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"first_name"}
                        type={"text"}
                        errors={errors.first_name}
                        input={{ ...register("first_name", { required: true }) }}
                        label={t("app.forwarder.firstname")}
                    />

                    <InputText
                        id={"last_name"}
                        type={"text"}
                        errors={errors.last_name}
                        input={{ ...register("last_name", { required: true }) }}
                        label={t("app.forwarder.lastname")}
                    />


                    <InputText
                        id={"email"}
                        type={"text"}
                        errors={errors.email}
                        input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                        label={t("app.forwarder.email")}
                    />

                    <InputText
                        id={"phone"}
                        type={"text"}
                        errors={errors.phone}
                        input={{ ...register("phone", { required: false }) }}
                        label={t("app.forwarder.phone")}
                    />

                    <InputText
                        id={"role"}
                        type={"text"}
                        errors={errors.role}
                        input={{ ...register("role", { required: false }) }}
                        label={t("app.forwarder.role")}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.forwarder.add_name")}
                />
            </div>
        
        
        </form>
        </FormPopup>
    );
}


const PreviewEmail = ({ forwarder, fulfillments, shippingType, fulfillmentType, isOpenPreview, setOpenPreview  }) => {
   
   
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(false);
    const [emailContent, setEmailContent] = useState(false);
    const [showPreview, setShowPreview] = useState(false);


    const {
        control,
        watch
    } = useForm();




    useEffect(() => {
        async function fetchData() {
            if (loggedCompany && watch('email_template') && watch('mail_provider')){
                setShowPreview(true);

                let fulfels = [];
                // eslint-disable-next-line
                fulfillments.map(fulfillment => {
                    fulfels.push({ id: fulfillment.id },)
                })

                let data = {
                    fulfillments: fulfillments,
                    type: fulfillmentType,
                    shipping_type: shippingType.type, 
                    forwarder: { id: forwarder.id},
                    primary_contact: { id: forwarder.primary_contacts.id },
                    company_email_template: { id: watch('email_template').id},
                    company_email: { id: watch('mail_provider').id }
                }

                setLoading(true);
                try {
                    const res = await putElement(getPathParam([COMPANY, loggedCompany.id, EMAIL_PREVIEW]), data);
                    setEmailContent(res);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [forwarder, watch('email_template'), watch('mail_provider')]);





    return (
        <FormPopup
            title={t("app.fulfillment.preview_email")}
            defaultWith={'max-w-xl'}
            isActiveFormPm={true}
            isOpen={isOpenPreview}
            setIsOpen={setOpenPreview}
        >
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <div>
                        <Controller
                            name="mail_provider"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={t("app.provider.email_from")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL]) + serialize({ filter_by: 'active' })}
                                    label={'email'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={(r) => {
                                        onChange(r);
                                    }}
                                    isClearable={false}
                                    isRequired={true}
                                    isDisabled={false}

                                />
                            )}
                        />
                        <div className={"flex justify-between before:flex-1"}>
                            <a target="__blank" href={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION])} className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                {t("app.provider.add_name")}
                            </a>
                        </div>
                    </div>
                   
                    <Controller
                        name="email_template"
                        rules={{ required: true }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <DynamicSearch
                                selectLabel={t("app.company_preference.template")}
                                endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATES])}
                                responseLabel={'templates'}
                                label={'title'}
                                errors={error}
                                value={value}
                                name={name}
                                onChange={(r) => {
                                    onChange(r);
                                }}
                                isClearable={false}
                                isRequired={true}
                                isDisabled={false}

                            />
                        )}
                    />
                    <div className={"flex justify-between before:flex-1"}>
                        <a target="__blank" href={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE])} className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                            {t("app.settings.email_template_add")}
                        </a>
                    </div>
                </div>
                
                {
                    showPreview && (
                        <LoaderWrapper isLoading={isLoading}>
                            <div className='overflow-y-auto max-h-80 border p-6'>
                                <div style={{ zoom: '0.8' }} dangerouslySetInnerHTML={{ __html: emailContent.content }} />
                            </div>
                        </LoaderWrapper>
                    )
                }
              


            </div>
            
        </FormPopup>
    );
}



export default FulfillmentNegotiation;
