import React, {useEffect, useState} from 'react'
import FormPopup from "../../../components/common/popup/form-popup";
import {useTranslation} from "react-i18next";
import {Warning} from "../../common/alert/banner";
import {getSelectParam} from "../../../utils/converter";
import InputText from "../../common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputSubmit from "../../common/form/input-submit";
import {useDispatch, useSelector} from "react-redux";
import {updateCompanyDetails} from "../../../reducers/userReducer";
import InputSelect from "../../common/form/input-select";
import {fetchCountries} from "../../../reducers/configReducer";
import {putUser} from "../../../api/user";
import {italianVat} from "../../../constants/regex";

export default function BillingPopup({isOpen, setIsOpen, onConfirm}) {

    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user );
    let dispatch = useDispatch();
    const { isCountriesLoading, countries } =
        useSelector((state) => state.config);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm();


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const updateCompany = async (data) => {
        setLoading(true);
        putUser({user_id: detail.id, data})
            .then((response) => {
                setLoading(false);
                setException(false);
                //toast.success(t("app.company.update_success"));
                dispatch(updateCompanyDetails(response));
                onConfirm(false);
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };


    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('billing_company_name', detail?.billing_company_name ?? null);
            setValue('city', detail?.city ?? null);
            setValue('address', detail?.address ?? null);
            setValue('zip_code', detail?.zip_code ?? null);

            if(detail.country){
                setValue('country', getSelectParam(detail.country, "name"));
            }

            setValue('vat_code', detail?.vat_code ?? null);
            setValue('billing_note_detail', detail?.billing_note_detail ?? null);
        }

        dispatch(fetchCountries());
    }, [setValue, detail, dispatch]);


    const onSubmit = async (data) => {
        updateCompany(data);
    };



    return (

        <FormPopup title={t("app.company.billing_information")} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
            <form onSubmit={handleSubmit(onSubmit)}>


                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-6 sm:grid-cols-1">

                    <InputText
                        id={"billing_company_name"}
                        type={"text"}
                        errors={errors.billing_company_name}
                        input={{
                            ...register("billing_company_name", { required: true }),
                        }}
                        label={t("app.form.business_name")}
                    />

                    <InputText
                        id={"city"}
                        type={"text"}
                        errors={errors.city}
                        input={{
                            ...register("city", { required: false }),
                        }}
                        label={t("app.company.city")}
                    />

                    <InputText
                        id={"address"}
                        type={"text"}
                        errors={errors.address}
                        input={{
                            ...register("address", { required: false }),
                        }}
                        label={t("app.company.address")}
                    />

                    <InputText
                        id={"zip_code"}
                        type={"text"}
                        errors={errors.zip_code}
                        input={{
                            ...register("zip_code", { required: false }),
                        }}
                        label={t("app.company.zip_code")}
                    />

                    <Controller
                        name="country"
                        rules={{ required: true }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputSelect
                                label={t("app.form.country")}
                                name={name}
                                options={countries}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isLoading={isCountriesLoading}
                                isDisabled={isCountriesLoading}
                                errors={error}
                            />
                        )}
                    />

                    <InputText
                        id={"vat_code"}
                        type={"text"}
                        isDisabled={watch("country") === null}
                        errors={errors.vat_code}
                        input={{
                            ...register("vat_code", { required: true, pattern: watch("country")?.code === 'IT' ? italianVat: false }),
                        }}
                        label={t("app.company.vat_code")}
                    />

                    <InputText
                        id={"billing_note_detail"}
                        type={"text"}
                        errors={errors.billing_note_detail}
                        input={{
                            ...register("billing_note_detail", { required: false }),
                        }}
                        label={t("app.company.billing_note_detail")}
                    />
                </div>



                {
                    watch("country")?.code === 'IT' && (
                        <p className="mt-3 text-xs" dangerouslySetInnerHTML={{__html: t("app.plan.intent_declaration_info")}} />
                    )
                }


                {/* Warning */}
                {exception && <Warning message={exception} />}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.form.save")}
                    />
                </div>
            </form>
        </FormPopup>
    )
}

