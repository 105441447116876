import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import ButtonSubmit from "../form/button-submit";
import {useTranslation} from "react-i18next";

export default function ConfirmTextPopup({isOpen, setIsOpen, detail, onConfirmed = false}) {

    const { t } = useTranslation();
    const confirm = async () => {
        onConfirmed(true);
    };


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-white shadow-xl">

                            <div className="flex justify-between border-b p-4 items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">{detail.title}</h3>
                                </div>
                                <div>
                                    <button className="bg-white border-0" onClick={() => { setIsOpen(false);}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                </div>
                            </div>

                            <div className="mt-2 p-4">
                                <p className="text-sm text-gray-800">
                                    {detail.message}
                                </p>
                            </div>

                            <div className="p-4 flex before:flex-1 items-center justify-between ">


                                <ButtonSubmit
                                    isLoading={false}
                                    label={t("app.common.confirm")}
                                    onClick={() => {
                                        confirm()
                                    }}
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
