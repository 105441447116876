import React, {useEffect, useState} from "react";
import { useSelector} from "react-redux";
import {getConnectionVerify, putConnectionVerify} from "../../api/supplier";
import {Link, useParams} from "react-router-dom";
import Loader from "../../components/common/loader/loader";
import InputButton from "../../components/common/form/input-button";
import {useTranslation} from "react-i18next";
import {FailedBoxLink} from "../../components/common/alert/failed-box";
import {ACCOUNT, COMPANIES, DASHBOARD, CUSTOMERS} from "../endpoints";
import {SuccessBoxLink} from "../../components/common/alert/success-box";
import {getWebPathParam} from "../../utils/converter";
import ConnectionImage from "../../resources/images/logistic2.jpeg";

const CustomerVerifyConnection = () => {

    const { loggedCompany } = useSelector((state) => state.user);

    let {connection_code}  = useParams();

    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState(null);
    const [needNewCompany, setNeedNewCompany] = useState(null);

    const [isConfirmLoading, setConfirmLoading] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [exception, setException] = useState(true);

    const [notFound, setNotFound] = useState(false);
    const { t } = useTranslation();


    const confirmConnection = async () => {
        if(loggedCompany){
            setConfirmLoading(true);
            setNeedNewCompany(false);
            putConnectionVerify({company_id: loggedCompany.id, connection_code: connection_code})
                .then(response => {
                    if(response.status === 'confirmed' ){
                        setConfirmed(true);
                    }else{
                        setNotFound(true);
                    }
                    setConfirmLoading(false);
                }).catch(e => {
                    if(e.code === 409){
                        setNeedNewCompany(true)
                    }else{
                        setException(e.message);
                    }
                    setConfirmLoading(false);
            });
        }
    };

    useEffect(() => {
        function fetchConnection(){
            if(loggedCompany){
                setLoading(true);
                setNeedNewCompany(false);
                getConnectionVerify({company_id: loggedCompany.id, connection_code: connection_code})
                    .then(response => {
                        setDetail(response);
                        setLoading(false);
                    }).catch(e => {
                    if(e.code === 409){
                        setNeedNewCompany(true)
                    }else{
                        setNotFound(true);
                        setException(e.message);
                    }
                    setLoading(false);
                });
            }
        }
        fetchConnection();
    }, [connection_code, loggedCompany]);

    return (
        <div className="py-4  h-full flex flex-col before:flex-1 after:flex-1">
            <div className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {
                    isLoading ? (
                        <Loader />
                    ) : (
                        notFound ? (
                            <FailedBoxLink
                                title={t('app.message.generic_not_found')}
                                message={exception}
                                link={DASHBOARD}
                                linkTitle={t('app.common.go_to', {label: ' dashboard ->'})}
                            />
                        ) : (
                            isConfirmed ? (
                                <SuccessBoxLink
                                    title={t("app.supplier.connection_confirmed")}
                                    message={t("app.supplier.connection_confirmed_description")}
                                    link={CUSTOMERS}
                                    linkTitle={t('app.customers.back')}
                                />
                            ) : (
                                needNewCompany ? (
                                    <div className="py-16 bg-white shadow overflow-hidden lg:py-24">
                                        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                                            <div className="relative">
                                                <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                                                    {t("app.supplier.connection_title")}
                                                </h2>
                                                <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                                    To connect with this company you have to create a new company so that it is already connected
                                                </p>
                                            </div>

                                            <div className="mt-8 flex justify-center">
                                                <div className="inline-flex rounded-md shadow">
                                                    <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white" to={getWebPathParam([ACCOUNT, COMPANIES])}>{t('app.common.go_to', {label: 'Company List ->'})}</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ) : (


                                    <div className="relative bg-gray-800">
                                        <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                                            <img
                                                className="w-full h-full object-cover"
                                                src={ConnectionImage}
                                                alt=""
                                            />
                                        </div>


                                        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">

                                            <div className="md:ml-auto md:w-1/2 md:pl-10">
                                                <p className="mt-3 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                                                    {t("app.supplier.connection_title")}
                                                </p>
                                                <p className="mt-3 text-lg text-gray-300">
                                                    {t("app.supplier.connection_description", {business_name: loggedCompany?.business_name ?? '', business_name_2: detail?.business_name ?? ''})}
                                                </p>
                                                <div className="mt-8">
                                                    <div className="items-center">
                                                        <div className="flex justify-center after:flex-1">
                                                            <InputButton
                                                                isLoading={isConfirmLoading}
                                                                onClick={() => {
                                                                    confirmConnection()
                                                                }}
                                                                label={t("app.supplier.connection_confirm")}
                                                                extraClass={'text-white bg-indigo-800 hover:bg-indigo-900'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                )


                            )
                        )
                    )
                }



            </div>
        </div>
    );
};

export default CustomerVerifyConnection;
