import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PageHeader, {ActionButton} from "../../components/layout/page-header";
import { useDispatch, useSelector } from "react-redux";
import ActionsMenu from "../../components/common/table/actions-menu";
import {REGISTRY, SUPPLIER} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import { useTranslation } from "react-i18next";
import { getSuppliers } from "../../api/supplier";
import ListSection from "../../components/layout/list-section";
import CustomerCell from "../../components/common/table/customer-cell";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionSpecific, permissionType} from "../../constants/permissions";
import OptionalLink from "../../components/common/table/optional-link";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import AddSupplier from "./add-supplier";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import ConfirmPopup from "../../components/common/popup/confirm-popup";
import RemovePopup from "../../components/common/popup/remove-popup";
import {ARCHIVE, COMPANY} from "../../api/endpoints";
import {STATUS_CONFIRMED, STATUS_PENDING} from "../../constants/config";
import {loadSuppliers} from "../../reducers/connectionReducer";
import { PlusIcon } from "@heroicons/react/outline";

const Suppliers = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);


  const canView =  hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionType.VIEW);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionType.CREATE);

  const canArchive = hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_ARCHIVE);
  const canRevoke = hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_REVOKE);



  const [isFetchSuppliersLoading, setFetchSuppliersLoading] = useState(true);
  const [suppliersList, setSuppliersList] = useState({
    suppliers: [],
    total_rows: 0,
  });

  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);


  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmDetail, setConfirmDetail] = useState({});

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [needRefresh, setNeedRefresh] = useState(false);


  const { t } = useTranslation();
  const dispatch = useDispatch();


  const tabs = [
    { name: t("app.shippings.shipping_all"), group: null },
    { name: t("app.customers.disconnected"), group: "disconnected" },
    canArchive && { name: t("app.customers.archived"), group: "archived" },
  ].filter(Boolean);

  const columns = [
    t("app.supplier.business_name"),
    t("app.supplier.reference"),
    "",
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchSuppliers() {
      if (loggedCompany) {
        setFetchSuppliersLoading(true);
        try {
          const res = await getSuppliers({
            company_id: loggedCompany.id,
            params: { limit: 10, filter: activeFilter, offset: query.length > 0 ? 0 : offset, query: query },
            signal,
          });
          setSuppliersList(res);
        } catch (e) {
          setSuppliersList({ suppliers: [], total_rows: 0 });
        } finally {
          if(signal.aborted){
            setFetchSuppliersLoading(true);
          }else{
            setFetchSuppliersLoading(false);
          }
        }
      }
    }

    fetchSuppliers();
    return () => {
      controller.abort();
    };
  }, [offset, query, loggedCompany, needRefresh, dispatch, activeFilter]);


  return (

   <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SUPPLIER, permissionType.LIST)]}>

      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.supplier.suppliers")}>
            {
              canCreate && (
                    <ActionButton
                        icon={
                          <PlusIcon
                              className="w-4 h-4"
                          />
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        text={t("app.supplier.add_supplier")}
                    />
                )
            }

          </PageHeader>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

          <UnderlineTab
              tabs={tabs}
              activeGroup={activeFilter}
              setActiveGroup={(r) => {
                setActiveFilter(r);
                setOffset(0);
              }}
          />


          <ListSection
            title={t("app.supplier.suppliers")}
            columns={columns}
            totalRows={suppliersList?.total_rows ?? ""}
            list={suppliersList.suppliers}
            onSearch={setQuery}
            isLoading={isFetchSuppliersLoading}
            paginationOnChange={setOffset}
            mobileRow={MobRow}
            desktopRow={DeskRow}
            extras={{
              canView,
              canRevoke,
              canArchive,
              onArchive: (r, detail) => {
                setConfirmDetail(detail);
                setOpenConfirm(r);
              },
              onRevoke: (r, detail) => {
                setRemoveDetail(detail);
                setOpenRemove(r);
              }
            }}
          />
        </div>
      </div>
      {(isOpen && canCreate) && <AddSupplier isOpen={isOpen} setIsOpen={setIsOpen} />}

      {isOpenConfirm && (
          <ConfirmPopup
              isOpen={isOpenConfirm}
              setIsOpen={setOpenConfirm}
              detail={confirmDetail}
              setDetail={() => {
                dispatch(loadSuppliers(loggedCompany.id));
                setNeedRefresh(!needRefresh);
              }}
          />
      )}

      {isOpenRemove && (
          <RemovePopup
              isOpen={isOpenRemove}
              setIsOpen={setOpenRemove}
              detail={removeDetail}
              setDetail={(response) => {
                dispatch(loadSuppliers(loggedCompany.id));
                setNeedRefresh(!needRefresh);
              }}
          />
      )}

      </BasePermissionWrapper>
  );
};

const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);


  const archiveDetail = {
    title: t("app.supplier.archive_title"),
    message: t("app.supplier.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, SUPPLIER, element.id, ARCHIVE])
  };

  const revokeDetail = {
    title: t("app.supplier.remove_title"),
    message: t("app.supplier.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, SUPPLIER, element.id])
  };



  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={extras.canView}
        to={getWebPathParam([REGISTRY,SUPPLIER, element.id])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
          <CustomerCell customer={element} />

          {
              (extras.canArchive || extras.canRevoke || extras.canView) && (
                  <ActionsMenu>
                    {extras.canView && (
                  <Link to={getWebPathParam([REGISTRY, SUPPLIER, element.id])}>
                          {t("app.common.edit")}
                        </Link>
                    )}

                    {extras.canArchive && ((element.status && element.status === STATUS_PENDING) || (!element.status)) && (
                        <button className={'text-left'} onClick={() => {
                          extras.onArchive(true, archiveDetail);
                        }}
                        >
                          {t("app.customers.archive")}
                        </button>
                    )}

                    {extras.canRevoke && (element.status && element.status === STATUS_CONFIRMED) && (
                        <button className={'text-left text-gray-700'} onClick={() => {
                          extras.onRevoke(true, revokeDetail);
                        }}
                        >
                          {t("app.customers.remove")}
                        </button>
                    )}

                  </ActionsMenu>
              )
          }


        </div>
        <div className="flex flex-col">

          <CardDetail title={t("app.supplier.reference")}>
            {element?.exporter_reference ?? "-"}
          </CardDetail>
        </div>

      </OptionalLink>
    </li>
  );
};

const DeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const archiveDetail = {
    title: t("app.supplier.archive_title"),
    message: t("app.supplier.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, SUPPLIER, element.id, ARCHIVE])
  };

  const revokeDetail = {
    title: t("app.supplier.remove_title"),
    message: t("app.supplier.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, SUPPLIER, element.id])
  };




  return (
    <tr key={element.id}>
      <TableDetail extraClass="max-w-sm">
        <OptionalLink
            isLinked={extras.canView}
          to={getWebPathParam([REGISTRY, SUPPLIER, element.id])}
            className="truncate hover:text-gray-600"
        >
          <CustomerCell customer={element} />
        </OptionalLink>
      </TableDetail>

      <TableDetail>
        {element?.exporter_reference ?? "-"}
      </TableDetail>
      <TableDetail>
        {
            (extras.canArchive || extras.canRevoke || extras.canView) && (
                <ActionsMenu>
                  {extras.canView && (
                <Link to={getWebPathParam([REGISTRY, SUPPLIER, element.id])}>
                        {t("app.common.edit")}
                      </Link>
                  )}

                  {extras.canArchive && ((element.status && element.status === STATUS_PENDING) || (!element.status)) && (
                      <button className={'text-left'} onClick={() => {
                        extras.onArchive(true, archiveDetail);
                      }}
                      >
                        {t("app.customers.archive")}
                      </button>
                  )}

                  {extras.canRevoke && (element.status && element.status === STATUS_CONFIRMED) && (
                      <button className={'text-left text-gray-700'} onClick={() => {
                        extras.onRevoke(true, revokeDetail);
                      }}
                      >
                        {t("app.customers.remove")}
                      </button>
                  )}

                </ActionsMenu>
            )
        }
      </TableDetail>
    </tr>
  );
};

export default Suppliers;
