import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  useParams, useOutletContext } from "react-router-dom";
import { getFormattedDate } from "../../../../utils/timeUtils";
import ListFooter from "../../../../components/partials/footer/list-footer";
import {LOGISTICS, SHIPPING, SHIPPINGS} from "../../../endpoints";
import { getDocuments } from "../../../../api/document";
import DocumentDetail from "./document-detail";
import AddDocument from "./add-document";
import DocumentIcon from "../../../../resources/icons/DocumentIcon";
import FileCell from "../../../../components/common/table/file-cell";
import {ChevronRightIcon, DocumentReportIcon, MailIcon, PaperAirplaneIcon} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../../../constants/permissions";
import ListSection from "../../../../components/layout/list-section";
import {getPathParam} from "../../../../utils/converter";
import {DOCUMENT_SEND_NOTIFICATION} from "../../../../api/endpoints";
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import toast from "react-hot-toast";
import TableMenu from "../../../../components/common/table/table-menu";
import {hasSubscriptionPermission} from "../../../../components/partials/restricted/subscription-wrapper";
import SubscriptionWrapper from "../../../../components/partials/restricted/subscription-wrapper";
import EmailSender from "../../../../components/partials/email/email-sender";
import MultiReport from "../../../../components/partials/report/multi-report";
import {LAND, SEA, SHIPPING_REPORT} from "../../../../constants/config";
import {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";

const Documents = () => {
  const { isExporter, loggedCompany, companyType } = useSelector((state) => state.user);
  const { subscriptions, permissions } = useSelector((s) => s.userCompany);



  const isGenerateDocumentActive = hasSubscriptionPermission(subscriptions, sp.GENERATE_DOCUMENT_MARKETPLACE_PACKAGE);
  const checkEmailIntegration = hasSubscriptionPermission(subscriptions, sp.EMAIL_INTEGRATION_MARKETPLACE_PACKAGE);


  const canView =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.VIEW);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.CREATE);


  const isGenerateDocumentPermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_GENERATE);
  const isSendDocumentPermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_SEND_MAIL);
  const isNotifyCustomerPermission =  hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_NOTIFY_CUSTOMER);



  const [isFetchListLoading, setFetchListLoading] = useState(true);
  const [list, setList] = useState({ documents: [], total_rows: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const { t } = useTranslation();

  const [detail, setDetail] = useState(null);
  const [isOpenDetail, setOpenDetail] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const { shippingDetail, isSeaType } = useOutletContext();

  // Confirm Modal
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmDetail, setConfirmDetail] = useState({});

  const [isOpenReportGenerate, setOpenReportGenerate] = useState(false);


  const [isOpenEmailSender, setOpenEmailSender] = useState(false);


  let { id } = useParams();

  const columns = [t("app.shippings.document"), t("app.shippings.upload_date")];


  const checkDocuments = () => {
    let conf = 0;
    let confDoc = 0;

    if(list.documents.length > 0){
      // eslint-disable-next-line
      list.documents.map(data => {
        if(!data.hidden){
          conf++;
          if(data.file_name){
            confDoc++;
          }
        }
      });

      if(conf === confDoc && conf > 0){
        return true;
      }
    }


    return false;
  };

  const tableNavigation = [
      (canCreate) && { name: t("app.shippings.add_document"), permission: [getPermission(permissionGroup.DOCUMENT, permissionType.CREATE)], actionType: 'add_document', icon: DocumentIcon, isActive: true },
      (checkEmailIntegration && isSendDocumentPermission) && { name: t("app.document.send_document"), permission: [getPermission(permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_SEND_MAIL)], actionType: 'send_document', icon: MailIcon, isActive: true },
      (isExporter && isNotifyCustomerPermission) && { name: t("app.document.notify_title", {type: true ? t("app.customers.customer") : t("app.supplier.supplier") }),  permission: [getPermission(permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_NOTIFY_CUSTOMER)], actionType: 'notify' , icon: PaperAirplaneIcon, isActive: checkDocuments() },
      (isExporter && isGenerateDocumentActive && isGenerateDocumentPermission) && { name: t("app.report.generate_report"),  permission: [getPermission(permissionGroup.DOCUMENT, permissionSpecific.DOCUMENT_GENERATE)], actionType: 'generate_report' , icon: DocumentReportIcon, isActive: true },
  ].filter(Boolean);



  useEffect(() => {
    function fetchList() {
      if (loggedCompany && !isOpen) {
        setFetchListLoading(true);
        getDocuments({ shipping_id: id, params: { query: query, company_type: companyType } })
          .then((response) => {
            setList(response);
            setFetchListLoading(false);
            setNeedRefresh(false);
          })
          .catch((e) => {
            setList({ documents: [], total_rows: 0 });
            setFetchListLoading(false);
          });
      }
    }

    fetchList();
  }, [query, id, setFetchListLoading, loggedCompany, isOpen, needRefresh, companyType]);

  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.DOCUMENT, permissionType.LIST)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>
        <ListSection
          title={t("app.document.documents")}
          columns={columns}
          totalRows={list?.total_rows ?? 0}
          list={list?.documents ?? []}
          onSearch={setQuery}
          isLoading={isFetchListLoading}
          mobileRow={DocumentMobRow}
          desktopRow={DocumentDeskRow}
          extras={{
              setDetail,
              setOpenDetail,
              canView
        }}
        >

            {
                (tableNavigation.length > 0) && (
                    <TableMenu
                        tableNavigation={tableNavigation}
                        setActiveGroup={(r) => {
                            if(r === 'add_document'){
                                setIsOpen(true);
                            }

                            if(r === 'notify'){
                                const confirmDetail = {
                                    title: t("app.document.notify_title", {type: true ? t("app.customers.customer") : t("app.supplier.supplier") }),
                                    message: t("app.document.notify_description"),
                                    endpoint: getPathParam([SHIPPING, id, DOCUMENT_SEND_NOTIFICATION]),
                                };

                                setOpenConfirm(true);
                                setConfirmDetail(confirmDetail);
                            }

                            if(r === 'generate_report'){
                                setOpenReportGenerate(true);
                            }

                            if(r === 'send_document'){
                                setOpenEmailSender(true);
                            }
                        }}
                    />
                )
            }



        </ListSection>
      <ListFooter backLink={LOGISTICS + SHIPPINGS} backLabel={t("app.shippings.back")} />

        {isOpen && canCreate && (
          <AddDocument
            isOpen={isOpen}
            isSeaType={isSeaType}
            setIsOpen={setIsOpen}
            setDetail={(r) => {
              setDetail(r);
              setOpenDetail(true);
            }}
          />
        )}


          <SubscriptionWrapper requiredPermission={sp.GENERATE_DOCUMENT_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
              {
                  isGenerateDocumentPermission && (
                      <MultiReport
                          isOpen={isOpenReportGenerate}
                          isSpecific={false}
                          isSeaType={isSeaType}
                          reportParams={{type: SHIPPING_REPORT, shipping_type: isSeaType ? SEA : LAND}}
                          setIsOpen={(r) => {
                              if(r === false){
                                  setNeedRefresh(true);
                              }
                              setOpenReportGenerate(r);
                          }}

                      />
                  )
              }
          </SubscriptionWrapper>

        <ConfirmPopup
            isOpen={isOpenConfirm}
            setIsOpen={setOpenConfirm}
            detail={confirmDetail}
            setDetail={(r) => {
              toast.success(t("app.document.notify_success"))
            }}
        />

          <SubscriptionWrapper requiredPermission={sp.EMAIL_INTEGRATION_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
              {
                  isSendDocumentPermission && (
                      <EmailSender
                          isOpen={isOpenEmailSender}
                          shippingDetail={shippingDetail}
                          documents={list.documents}
                          setIsOpen={(r) => {
                              setOpenEmailSender(r);
                          }}
                      />
                  )
              }
          </SubscriptionWrapper>


          {
              (canView) &&  (
                  <DocumentDetail
                      isOpen={isOpenDetail}
                      detail={detail}
                      onNeedRefresh={(r) => {
                          setNeedRefresh(r);
                      }}
                      setIsOpen={(r) => {
                          setOpenDetail(r);
                      }}
                  />
              )
          }

      </BasePermissionWrapper>
  );
};

const DocumentMobRow = ({ element, extras }) => {
  return (
    <li
      key={element.id}
      className="flex items-center justify-between gap-4 p-4 cursor-pointer"
      onClick={() => {
          if( extras.canView){
              extras.setOpenDetail(true);
              extras.setDetail(element);
          }
      }}
    >
      <FileCell file={element} />
      <ChevronRightIcon
        className="flex-shrink-0 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
    </li>
  );
};

const DocumentDeskRow = ({ element, extras }) => {
  return (
    <tr
      key={element.id}
      className={(extras.canView) ? "hover:bg-gray-100/[0.4] cursor-pointer" : "hover:bg-gray-100/[0.4]"}
      onClick={() => {
          if(extras.canView){
              extras.setOpenDetail(true);
              extras.setDetail(element);
          }
      }}
    >
      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        <FileCell file={element} />
      </td>
      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {element.upload_date ? getFormattedDate(element.upload_date) : "-"}
      </td>
    </tr>
  );
};

export default Documents;
