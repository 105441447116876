import React, {useEffect, useState} from "react";
import UserInitial from "../../common/table/user-initial";
import {ActionButton} from "../../layout/page-header";
import {PlusCircleIcon} from "@heroicons/react/outline";
import DynamicForm from "../../common/form/dynamic-form";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../utils/converter";
import {COMPANY, CONNECTION, NOTE, NOTES} from "../../../api/endpoints";
import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";
import {getElementBody} from "../../../api/config";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import moment from "moment";
import {getFormattedDate} from "../../../utils/timeUtils";
import ActionsMenu from "../../common/table/actions-menu";
import RemovePopup from "../../common/popup/remove-popup";
import Nodata from "../../../resources/images/no-data.gif";

export default function ConnectionNotes() {

    const { t } = useTranslation();
    const [isOpenAdd, setIsOpenAdd] = useState(false);


    const [isLoading, setLoading] = useState(false);
    const [list, setList] = useState({total_rows: 0, notes: []});


    const { loggedCompany } = useSelector((state) => state.user);

    const { connectionId, typeActive, removeActive, onRefresh } = useOutletContext();



    const getFiltered = () => {
        if(list.notes.length > 0){
            let dataList = list.notes,
                result = dataList.reduce(function (r, a) {
                    let flag = moment(a.note_date, "YYYY-M-D HH:mm:ss").format("MMMM YYYY")

                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }


    useEffect(() => {
        if(typeActive && typeActive === 'note'){
            setIsOpenAdd(true);
            removeActive();
        }
        // eslint-disable-next-line
    }, [typeActive]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElementBody(getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, NOTES ]), signal);
                setList(res);
            } catch (e) {
                setList({total_rows: 0, notes: []});
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, connectionId]);



    return (
        <>
            <div className="max-w-5xl mx-auto mb-3 py-6 px-4 sm:px-6 lg:px-8 ">

                <div className="flex justify-between items-center flex-wrap gap-4 mb-6">
                    <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                        {t("app.leads.notes")}
                    </h1>

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 w-full sm:w-auto">
                        <ActionButton
                            icon={
                                <PlusCircleIcon
                                    className="w-4 h-4 "
                                />
                            }
                            onClick={() => {
                                setIsOpenAdd(true);
                            }}
                            text={t("app.leads.add_note")}
                        />
                    </div>
                </div>



            </div>

            <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8 pb-12">

                <LoaderWrapper isLoading={isLoading}>


                    {
                        list.notes.length > 0 ? (
                            <>
                                {Object.keys(getFiltered()).map((date) => (
                                    <div>
                                        <h2 className="grow font-semibold text-slate-800 dark:text-slate-100 truncate mb-6">{date.charAt(0).toUpperCase() + date.slice(1)}</h2>

                                        <div className="grid grid-cols-12 gap-6">
                                            {getFiltered()[date].map((item) => (
                                                <NoteCard data={item} connectionId={connectionId} onRefresh={(r) => {
                                                    setList(r);
                                                    onRefresh(true);
                                                }} />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className="text-center shadow rounded-md flex bg-white justify-center   h-56">
                                <div className={'self-center'}>
                                    <div className="text-center p-10">
                                        <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                        <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                            {t("app.common.not_found")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        )
                    }







                </LoaderWrapper>

            </div>


            <DynamicForm
                isOpen={isOpenAdd}
                setIsOpen={setIsOpenAdd}
                title={t("app.leads.generic_edit_title")}
                fields={[
                    {field: 'note', translation: 'app.connection.note_description',  value: '', viewType: '', formType: 'textarea', required: true},
                    {field: 'color', translation: 'app.connection.note_color',  value: '', viewType: '', formType: 'colors', required: true}
                ]}
                isCreate={true}
                endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, NOTE ])}
                onResponse={(r) => {
                    setList(r);
                    onRefresh(true);
                }}
            />

        </>
    );
}


const NoteCard = ({data, connectionId, onRefresh}) => {

    const { t } = useTranslation();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const { loggedCompany } = useSelector((state) => state.user);


    const [isOpenRemove, setOpenRemove] = useState(false);

    const getColor = (color) => {

        switch (color) {
            case "gray":
                return { border:  "border-gray-600", bg: "bg-gray-100/[0.5]" };
            case "red":
                return { border:  "border-red-600", bg: "bg-red-100/[0.5]" };
            case "yellow":
                return { border:  "border-yellow-600", bg: "bg-yellow-100/[0.5]" };
            case "green":
                return { border:  "border-green-600", bg: "bg-green-100/[0.5]" };
            case "blue":
                return { border:  "border-blue-600", bg: "bg-blue-100/[0.5]" };
            case "indigo":
                return { border:  "border-indigo-600", bg: "bg-indigo-100/[0.5]" };
            case "purple":
                return { border:  "border-purple-600", bg: "bg-purple-100/[0.5]" };
            case "pink":
                return { border:  "border-pink-600", bg: "bg-pink-100/[0.5]" };
            default:
                return { border:  "border-blue-1000", bg: "bg-blue-1000/[0.5]" };
        }
    }


    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, NOTE, data.id])
    };


    return (
        <>
            <div className={" col-span-full sm:col-span-6 xl:col-span-4 shadow-lg rounded-sm border-l-4 "+getColor(data.color).bg+" "+getColor(data.color).border}>
                <div className="flex flex-col h-full p-5">


                    <div className="relative  flex justify-end ">
                        <ActionsMenu>
                            <button className={'text-left'} onClick={() => {setIsOpenEdit(true)}}>
                                {t("app.common.edit")}
                            </button>
                            <button className={'text-left'} onClick={() => {setOpenRemove(true)}}>
                                {t("app.common.remove")}
                            </button>
                        </ActionsMenu>
                    </div>

                    <div className="flex-grow -mt-6 pr-4">
                        <div className="text-sm">{data.note}</div>
                    </div>





                    <footer className="mt-5">
                        <div className="flex justify-between items-center">
                            <div className="text-xs inline-flex font-medium bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400 rounded-full text-center  ">
                                {getFormattedDate(data.note_date)}
                            </div>
                            <div>
                                <UserInitial user={data.user} />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>


            <DynamicForm
                isOpen={isOpenEdit}
                setIsOpen={setIsOpenEdit}
                title={'title'}
                refId={data.id}
                fields={[
                    {field: 'note', translation: 'app.connection.note_description',  value: data.note, viewType: 'textarea', formType: 'textarea', required: true},
                    {field: 'color', translation: 'app.connection.note_color',  value: data.color, viewType: 'colors', formType: 'colors', required: true}
                ]}
                isCreate={false}
                endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, NOTE, data.id ])}
                onResponse={(r) => {
                    onRefresh(r);
                }}
            />


            {(isOpenRemove) && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        onRefresh(r);
                    }}
                />
            )}
        </>

    )
}
