import React from 'react';
import FullMapMarkers from "../partials/map/full-map-markers";

function ShippingTrackingVessel({data, height}) {

  return (
    <>
        <FullMapMarkers positions={data.positions} zoom={2} height={height} />
    </>
  );
}



export default ShippingTrackingVessel;
