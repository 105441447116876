import React, {Fragment, useState, useEffect} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import Initials from "../avatar/initials";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {fetchCustomers} from "../../../reducers/connectionReducer";



export default function InputCustomerSelect({label = '', isRequired = false, selectedItem = null, onSelect}) {

    const { loggedCompany } = useSelector((state) => state.user);
    const {customers, isLoadingCustomers} = useSelector((state) => state.connection);

    const { t } = useTranslation();
    const dispatch = useDispatch();


    const [query, setQuery] = useState('');


    const filteredData =
        query === ''
            ? customers
            : customers.filter((item) =>
                item.business_name.toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    useEffect(() => {
        dispatch(fetchCustomers({company_id: loggedCompany.id, params: {filter: 'all'}}));
    }, [dispatch, loggedCompany]);




    return (
        <div className="w-full">
            <Combobox value={selectedItem ? customers.find(elem => elem.id === selectedItem) : null} onChange={(r) => {
                onSelect(r);
            }}>

                <div className="relative ">


                    <label className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm ">
                        <Combobox.Input
                            id={'test'}
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize"
                            placeholder={t("app.common.placeholder", {
                                field: label.toLowerCase(),
                            })}
                            displayValue={(item) => item?.business_name ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoadingCustomers ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {filteredData.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredData.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (

                                                        <>
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    <Initials
                                                                        color={item.color}
                                                                        name={item.business_name}
                                                                        isVerified={false}
                                                                    />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <div className="focus:outline-none">
                                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                                        <p className="text-sm font-medium text-gray-900">{item.business_name}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
