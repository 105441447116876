import React from "react";
import {CogIcon, DocumentIcon, DocumentTextIcon, MapIcon, PencilAltIcon, TruckIcon} from "@heroicons/react/outline";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import BoxIcon from "../../../resources/icons/BoxIcon";

const InitialsStates = ({type}) => {


  let extraClassName = "";
  let Icon = null;
  switch (type) {
    case "shipping_document":
      extraClassName = "bg-red-500";
      Icon = DocumentIcon
      break;
    case "shipping_container":
      extraClassName = "bg-yellow-500";
      Icon = ContainerIcon
      break;
    case "shipping_truck":
      extraClassName = "bg-green-500";
      Icon = TruckIcon
      break;
    case "company_quotation":
      extraClassName = "bg-blue-500";
      Icon = DocumentTextIcon
      break;
    case "company_task":
    case "board":
    case "task":
      extraClassName = "bg-indigo-500";
      Icon = PencilAltIcon
      break;
    case "sea_tracking":
    case "ocean_tracking":
      extraClassName = "bg-purple-500";
      Icon = MapIcon
      break;
    case "shipping":
      extraClassName = "bg-pink-500";
      Icon = BoxIcon
      break;
    case "system":
      extraClassName = "bg-pink-500";
      Icon = CogIcon
      break;
    default:
      extraClassName = "bg-gray-500";
      Icon = CogIcon
      break;

  }

  return (
    <span className={"flex-shrink-0 inline-flex relative items-center justify-center mr-2 rounded-full h-8 w-8 "+extraClassName}>
      <span className={"font-medium leading-none text-white"}>
        {
            Icon && (
                <Icon className={'h-4 w-4'} />
            )
        }
      </span>
    </span>
  );
};
export default InitialsStates;
