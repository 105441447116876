import React from "react";
import {useTranslation} from "react-i18next";
import AuthBg from "../../resources/images/auth-bg.png";


const SidebarProfiling = () => {

    const { t } = useTranslation();

    return (
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2 bg-gradient-to-r from-indigo-700 to-blue-900" aria-hidden="true">
            <div className=" h-full flex flex-col before:flex-1 after:flex-1">
                <div className="flex-1">
                    <div className="max-w-7xl mx-auto py-16 px-4 ">
                        <div className="text-center">
                            <p className="mt-1 text-4xl font-extrabold text-white">
                                {t('app.slogan')}
                            </p>
                            <p className="max-w-xl mt-5  mx-auto text-m text-white">
                                {t('app.description')}
                            </p>
                            <img className="object-contain object-center m-auto mt-5 w-3/4" src={AuthBg}  alt="Authentication" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SidebarProfiling;
