import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";

const getCurrentYear = () => new Date().getFullYear().toString().slice(-2);


const DraggableTag = ({ value, index, onChange, isClosed }) => {
    const handleDragStart = (event) => {
        event.dataTransfer.setData('text/plain', index);
        event.currentTarget.style.backgroundColor = '#edf2f7';
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        const sourceIndex = event.dataTransfer.getData('text/plain');
        onChange(sourceIndex, index);
    };

    return (
        <div
            className={isClosed ? "px-2 py-1 rounded-md bg-solitude-400  text-blue-1000 w-full text-center" : "px-2 py-1 rounded-md bg-solitude-400 cursor-move text-blue-1000 w-full text-center"}
            draggable={!isClosed}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {value}
        </div>
    );
};


const CustomFormatter = ({
                             fullPrefix = '%second_prefix%%year%%incrementer%%first_prefix%',
                             isClosed = false,
                             firstPrefix = '',
                             secondPrefix = '',
                             numberDigit = 5,
                             previewTitle,
                             onChangePrefix
                         }) => {


    const { t } = useTranslation();


    const formatTagString = (tagString) => {
        const formattedString = tagString
            .replaceAll('%first_prefix%', firstPrefix)
            .replaceAll('%second_prefix%', secondPrefix)
            .replaceAll('%year%', getCurrentYear())
            .replaceAll('%incrementer%', '0'.repeat(numberDigit > 0 ? (numberDigit-1) : 0)+'1');
        return formattedString;
    };



    const getOrderData = () => {
        const arr = [
            { id: 0, label: firstPrefix, value: '%first_prefix%', title: t("app.company_preference.prefix_1")},
            { id: 1, label: secondPrefix, value: '%second_prefix%', title: t("app.company_preference.prefix_2")},
            { id: 2, label:  getCurrentYear(), value: '%year%', title: t("app.company_preference.year")},
            { id: 3, label:  '0'.repeat(numberDigit > 0 ? (numberDigit-1) : 0)+'1', value: '%incrementer%', title: t("app.company_preference.incrementer")}
        ]
        const sortOrder = fullPrefix?.match(/%\w+%/g) ?? [];


        arr.sort((a, b) => {
            return sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value);
        });

        arr.forEach((item, index) => {
            item.id = index;
        });

        return arr;


    };

    const [tagString, setTagString] = useState(getOrderData());


    const handleTagChange = (sourceIndex, targetIndex) => {
        if(!isClosed){
            const newTagString = [...tagString];
            const [removedTag] = newTagString.splice(sourceIndex, 1);
            newTagString.splice(targetIndex, 0, removedTag);
            setTagString(newTagString);
            setFormattedTagString(formatTagString(newTagString.map((tag) => tag.value).join('')));
            onChangePrefix(newTagString.map(item => item.value).join(''));
        }

    };

    const [formattedTagString, setFormattedTagString] = useState(
        formatTagString(tagString.map((tag) => tag.value).join(''))
    );


    useEffect(() => {
        setTagString(prevState => prevState.map(item => {
            if (item.value === '%first_prefix%') {
                return { ...item, label: firstPrefix, value: '%first_prefix%', title: t("app.company_preference.prefix_1") };
            }
            return item;
        }));

        setFormattedTagString(formatTagString(tagString.map((tag) => tag.value).join('')));
        // eslint-disable-next-line
    }, [firstPrefix]);


    useEffect(() => {
        setTagString(prevState => prevState.map(item => {
            if (item.value === '%second_prefix%') {
                return { ...item, label: secondPrefix, value: '%second_prefix%', title: t("app.company_preference.prefix_2") };
            }
            return item;
        }));

        setFormattedTagString(formatTagString(tagString.map((tag) => tag.value).join('')));
        // eslint-disable-next-line
    }, [secondPrefix]);


    useEffect(() => {
        setTagString(prevState => prevState.map(item => {
            if (item.value === '%incrementer%') {
                return { ...item, label: '0'.repeat(numberDigit > 0 ? (numberDigit-1) : 0)+'1', value: '%incrementer%', title: t("app.company_preference.incrementer") };
            }
            return item;
        }));

        setFormattedTagString(formatTagString(tagString.map((tag) => tag.value).join('')));
        // eslint-disable-next-line
    }, [numberDigit]);


    return (
        <div className="flex flex-col space-y-4">
            <div className="flex flex-row space-x-4 items-center">
                <div
                    id="tag-input"
                    className={"flex flex-row space-x-2 border border-gray-300 rounded-md overflow-hidden p-2 w-full"}
                    onDragOver={(event) => event.preventDefault()}
                >
                    {tagString.map((tag, index) => (
                        <DraggableTag
                            key={tag.id}
                            value={(<>{tag.title}<br /><b>{tag.label}</b></>)}
                            index={index}
                            onChange={handleTagChange}
                        />
                    ))}
                </div>
            </div>

            <label className="block text-sm font-medium mb-1 text-gray-700">
                {previewTitle}
            </label>

            <div className="flex flex-row space-x-2">
                <div className="px-2 py-1 rounded-md bg-solitude-400 text-blue-1000">{formattedTagString}</div>
            </div>
        </div>
    );
};
export default CustomFormatter;
