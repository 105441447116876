import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import {
    SHIPPING,
    CONTAINER,
    CONTAINER_LOAD_CONFIGURATION, CONTAINERS, PRODUCTS,
    LOGISTICS,
} from "../../../../../endpoints";
import { getContainerProducts } from "../../../../../../api/container";
import {useSelector} from "react-redux";
import ListFooter from "../../../../../../components/partials/footer/list-footer";
import { useTranslation } from "react-i18next";
import {
    getPermission,
    permissionGroup,
    permissionSpecific, permissionType,
    sp
} from "../../../../../../constants/permissions";
import ListSection from "../../../../../../components/layout/list-section";
import PageHeader, {
    ActionButton,
    FilterButton, InfoButton,
    TrashButton
} from "../../../../../../components/layout/page-header";
import UnderlineTab from "../../../../../../components/partials/Tabs/underline-tab";
import CatalogIcon from "../../../../../../resources/icons/CatalogIcon";
import AddProduct from "./add-product/add-product";
import NumberFormat from "react-number-format";
import ContainerIcon from "../../../../../../resources/icons/ContainerIcon";
import LoadDetail from "./load-detail";
import ActionsMenu from "../../../../../../components/common/table/actions-menu";
import ProductEdit from "./product-edit";
import {getPathParam, getWebPathParam} from "../../../../../../utils/converter";
import {PRODUCT} from "../../../../../../api/endpoints";
import RemovePopup from "../../../../../../components/common/popup/remove-popup";
import PackingList from "./packing-list/packing-list";
import {TrashIcon, InformationCircleIcon, DocumentTextIcon} from "@heroicons/react/outline";
import ProductCell from "../../../../../../components/common/table/product-cell";
import TableDetail from "../../../../../../components/common/list/table-detail";
import CardDetail from "../../../../../../components/common/list/card-detail";
import ImportProduct from "./add-product/import-product";
import SubscriptionWrapper from "../../../../../../components/partials/restricted/subscription-wrapper";
import BasePermissionWrapper, {
    hasPermissionFor
} from "../../../../../../components/partials/restricted/base-permission-wrapper";
import MultiReport from "../../../../../../components/partials/report/multi-report";
import {CONTAINER_REPORT, LAND, SEA} from "../../../../../../constants/config";

const importType = 'container';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LoadConfiguration = () => {
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);


    const isActivePackingPermission =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_PACKING_LIST);


    const isImportProductsPermission =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_IMPORT_PRODUCT);
    const requireProductList =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.LIST);
    const requireGenerateDocument =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_GENERATE_DOCUMENT);



    let navigate = useNavigate();
    const { t } = useTranslation();

    const columns = [
        t("app.container.product"),
        t("app.container.quantity"),
        t("app.container.stock"),
        t("app.container.net_weight"),
        t("app.container.gross_weight"),
        t("app.container.volume"),
        t("app.container.price"),
        "",
    ];

    let {id, container_id}  = useParams();
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState({
        products: [],
        stocks: [],
        total_rows: 0,
    });

    const { containerDetail, tabsNavigation, setForceReloadDetails, isSeaType } = useOutletContext();
    const [query, setQuery] = useState("");
    const [productToEdit, setProductToEdit] = useState(null);


    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPackingList, setOpenPackingList] = useState(false);
    const [isOpenDetail, setOpenDetail] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // Generate File
    const [isGenerateOpen, setGenerateOpen] = useState(false);


    const [isOpenUpload, setOpenUpload] = useState(false);
    const [lastImport, setLastImport] = useState(false);


    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const totalVolume = isSeaType ? containerDetail.container_type.volume : (containerDetail?.truck_volume ?? 0)

    const [containerLoadDetails, setContainerLoadDetails] = useState({
        total_volume: 0,
        total_gross_weight: 0,
        total_net_weight: 0,
        total_price: 0,
        percentage: 0,
    });



    const groupContainerLoad = (detail, containerDetail) => {
        let result = {
            total_volume: 0,
            total_gross_weight: 0,
            total_net_weight: 0,
            total_price: 0
        };

        // eslint-disable-next-line
        detail.products.map(data => {
            result['total_volume'] = result['total_volume'] + (data.base_volume * data.quantity);
            result['total_gross_weight'] = result['total_gross_weight'] + (data.base_gross_weight * data.quantity);
            result['total_net_weight'] = result['total_net_weight'] + (data.base_net_weight * data.quantity);
            result['total_price'] = result['total_price'] + (data.base_price * data.quantity);
        });

        result['percentage'] = result.total_volume > 0 && totalVolume > 0 ? (result.total_volume / totalVolume) * 100 : 0;
        return result;
    };


    useEffect( () => {
        if(detail.products){
            setContainerLoadDetails(groupContainerLoad(detail, containerDetail));
        }
        // eslint-disable-next-line
    }, [detail.products]);


    const handleSelectAll = () => {
        if(detail.products.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(detail.products.map(li => li.id));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };


    useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchContainerProducts() {
      if (loggedCompany && isOpenPackingList === false) {
        setLoading(true);
        getContainerProducts({ shipping_id: id, container_id: container_id, params: { query: query }, signal })
            .then((response) => {
              setDetail(response);
              setLoading(false);
            })
            .catch((e) => {
              setDetail({ products: [], total_rows: 0, stocks: []});
              setLoading(false);
            });
      }
    }

    fetchContainerProducts();
    return () => {
      controller.abort();
    };
  }, [query, id, loggedCompany, needRefresh, container_id, isOpenPackingList]);

  return (

      <SubscriptionWrapper requiredPermission={sp.PACKING_LIST_MARKETPLACE_PACKAGE} renderBaseOnFail={true} checkUsage={true}>


          {
              requireGenerateDocument && (
                  <MultiReport
                      isOpen={isGenerateOpen}
                      isSpecific={false}
                      isSeaType={isSeaType}
                      params={{container_id: container_id}}
                      reportParams={{type: CONTAINER_REPORT, shipping_type: isSeaType ? SEA : LAND}}
                      setIsOpen={(r) => {
                          setGenerateOpen(r);
                      }}

                  />
              )
          }



          <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.EDIT)]} requireExporter={true} renderBaseOnFail={true} redirectWrongCompanyType={true}>

          <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionSpecific.CONTAINER_LOAD_CONFIGURATION)]}>

              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  <PageHeader
                      title={isSeaType ? t("app.container.edit_title") : t("app.container.edit_tuck_title")}
                      importGroup={[getPermission(permissionGroup.CONTAINER, permissionSpecific.CONTAINER_IMPORT_PRODUCT)]}
                      onNeedRefresh={(r) => {
                          setLastImport(null);
                          setNeedRefresh(r);
                      }}
                      importEntityId={container_id}
                      importType={importType}
                      lastImport={lastImport}
                  >


                      <InfoButton
                          onClick={() => {
                              setOpenDetail(true);
                          }}
                          icon={
                              <InformationCircleIcon
                                  className="w-6 h-6"
                                  aria-hidden="true"
                              />
                          }
                          text={isSeaType ? t("app.container.container_status") : t("app.container.truck_status")}
                      />


                      <div className="text-center">
                          <ActionButton
                              onClick={() => {
                                  setGenerateOpen(true);
                              }}
                              icon={
                                  <DocumentTextIcon
                                      className="w-4 h-4"
                                      aria-hidden="true"
                                  />
                              }
                              text={t("app.container.generate_file")}
                          />


                      </div>

                  </PageHeader>

                  <UnderlineTab tabs={tabsNavigation} activeGroup={CONTAINER_LOAD_CONFIGURATION} setActiveGroup={(r) => {
                          navigate(LOGISTICS + SHIPPING + '/'+ id + CONTAINER +  '/' + container_id + r);
                  }} />
              </div>

              <div className="max-w-7xl mt-4 mx-auto px-4 sm:px-6 md:px-8">

                  <section className={'mt-3'}>

                      <ListSection
                          title={t("app.container.products")}
                          columns={columns}
                          totalRows={detail.total_rows}
                          list={detail.products}
                          onSearch={setQuery}
                          isLoading={isLoading}
                          mobileRow={ContainerMobRow}
                          desktopRow={ContainerDeskRow}
                          hasCheckbox={true}
                          selectAll={selectAll}
                          handleSelectAll={handleSelectAll}
                          extras={{
                              setProductToEdit,
                              onRemove: (r, detail) => {
                                  setOpenRemove(r);
                                  setRemoveDetail(detail);
                              },
                              isCheck,
                              stocks: detail.stocks,
                              handleClick
                          }}
                      >

                          {
                              isCheck.length > 0 && (
                                  <div className="inline-flex items-center justify-center">
                                      <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                                      <TrashButton
                                          onClick={() => {

                                              const removeDetail = {
                                                  title: t("app.products.remove_title"),
                                                  message: t("app.products.remove_description"),
                                                  endpoint: getPathParam([SHIPPING, id, CONTAINER, container_id, PRODUCTS]) + '?products='+isCheck,
                                              };
                                              setOpenRemove(true);
                                              setRemoveDetail(removeDetail);
                                          }}
                                          icon={
                                              <TrashIcon
                                                  className="w-4 h-4"
                                                  aria-hidden="true"
                                              />
                                          }
                                      />
                                  </div>
                              )
                          }

                          {
                              requireProductList && (
                                  <FilterButton
                                      onClick={() => {
                                          setIsOpen(true);
                                      }}
                                      icon={
                                          <CatalogIcon
                                              className="w-4 h-4"
                                              aria-hidden="true"
                                          />
                                      }
                                      text={t("app.container.add_product")}
                                  />
                              )
                          }






                          {
                              isActivePackingPermission && (
                                  <ActionButton
                                      disabled={detail.total_rows === 0}
                                      icon={
                                          <ContainerIcon
                                              className="w-4 h-4 fill-current flex-shrink-0"
                                              aria-hidden="true"
                                          />
                                      }
                                      onClick={() => {
                                          setOpenPackingList(true);
                                      }}
                                      text={t("app.container.packing_list")}
                                  />
                              )
                          }
                      </ListSection>


                      <ListFooter
                              backLink={LOGISTICS + SHIPPING + '/'+ id + CONTAINERS}
                          backLabel={t('app.container.back')}
                      />

                      {(isOpen && requireProductList) && (
                          <AddProduct
                              isOpen={isOpen}
                              containerDetail={containerDetail}
                              setDetail={(r) => {
                                  setDetail(r);
                              }}
                              onOpenUpload={(r) => {
                                  setOpenUpload(r);
                              }}
                              setIsOpen={(r) => {
                                  setIsOpen(r);
                              }}
                          />
                      )}


                      {
                          isImportProductsPermission && (
                              <ImportProduct
                                  isOpen={isOpenUpload}
                                  setIsOpen={setOpenUpload}
                                  importGroup={importType}
                                  onImportComplete={(r) => {
                                      setLastImport(r);
                                  }}
                              />
                          )
                      }



                      {productToEdit && (
                          <ProductEdit
                              isOpen={productToEdit !== null}
                              detail={productToEdit}
                              setIsOpen={() => {
                                  setProductToEdit(null);
                              }}
                              setDetail={(r) => {
                                  setDetail(r);
                              }}
                              setNeedRefresh={setNeedRefresh}
                          />
                      )}

                      {isOpenRemove && (
                          <RemovePopup
                              isOpen={isOpenRemove}
                              setIsOpen={setOpenRemove}
                              detail={removeDetail}
                              setDetail={(r) => {
                                  setDetail(r);
                                  setIsCheck([]);
                                  setSelectAll(false);
                              }}
                          />
                      )}


                      <LoadDetail
                          isOpen={isOpenDetail}
                          isSeaType={isSeaType}
                          containerDetail={containerDetail}
                          containerLoadDetails={containerLoadDetails}
                          setIsOpen={(r) => {
                              setOpenDetail(r);
                          }}
                      />



                      <PackingList
                          isOpen={isOpenPackingList}
                          setIsOpen={(r) => {
                              setOpenPackingList(r);
                              setForceReloadDetails(true);
                          }}
                      />

                  </section>


              </div>

          </BasePermissionWrapper>

          </BasePermissionWrapper>

      </SubscriptionWrapper>

  );
};

const ContainerMobRow = ({ element, extras}) => {

    const { t } = useTranslation();
    let {id, container_id}  = useParams();

    const isCheck = extras.isCheck.includes(element.id);
    const { company } = useSelector((state) => state.userCompany);

    const removeDetail = {
        title: t("app.products.remove_title"),
        message: t("app.products.remove_description"),
        endpoint: getPathParam([SHIPPING, id, CONTAINER, container_id, PRODUCT, element.id]),
    };


  return (
      <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
          <div className="flex-grow">

              <div className="pb-4 items-center flex justify-between">

                  <div className={"flex justify-between items-center"}>
                      <input type="checkbox"
                             id={element.id}
                             onChange={extras.handleClick} checked={isCheck}
                             className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <ProductCell  isActiveImage={true}  product={element.product}/>
                  </div>


                  <ActionsMenu>
                      <button className={'text-left'}
                              onClick={(e) => {
                                  extras.setProductToEdit(element);
                              }}
                      >
                          {t("app.common.edit")}
                      </button>
                      <button className={'text-left'}
                              onClick={() => {
                                  extras.onRemove(true, removeDetail);
                              }}
                      >
                          {t("app.common.remove")}
                      </button>
                  </ActionsMenu>
              </div>

              <div className="flex flex-col">

                  <CardDetail title={t("app.container.quantity")}>
                      <NumberFormat
                          value={(element.quantity - element.available_quantity)}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                      />
                      /
                      <NumberFormat
                          value={element.quantity}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                      />
                  </CardDetail>

                  <CardDetail title={t("app.container.stock")}>
                      <div className="flex justify-between gap-1 items-center">
                          <NumberFormat
                              value={extras.stocks.find(r => r.product_id === element.product.id)?.stock ?? 0 }
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                          />
                     </div>
                  </CardDetail>

                  <CardDetail title={t("app.container.net_weight")}>
                      <div className="flex justify-between gap-1 items-center">
                          <NumberFormat
                              value={element.base_net_weight * element.quantity}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={2}
                              suffix={' Kg'}
                          />
                          (
                          <div className="text-xs text-gray-500">
                              <NumberFormat
                                  value={element.base_net_weight}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={5}
                                  suffix={' Kg'}
                              />
                          </div>
                          )
                      </div>
                  </CardDetail>

                  <CardDetail title={t("app.container.gross_weight")}>
                      <div className="flex justify-between gap-1 items-center">
                          <NumberFormat
                              value={element.base_gross_weight * element.quantity}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={2}
                              suffix={' Kg'}
                          /> (
                          <div className="text-xs text-gray-500">
                              <NumberFormat
                                  value={element.base_gross_weight}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={5}
                                  suffix={' Kg'}
                              />
                          </div>
                          )
                      </div>
                  </CardDetail>
                  <CardDetail title={t("app.container.volume")}>
                      <div className="flex justify-between gap-1 items-center">
                          <NumberFormat
                              value={element.base_volume * element.quantity}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalScale={2}
                              decimalSeparator={","}
                              suffix={' m³'}
                          />
                          (
                          <div className="text-xs text-gray-500">
                              <NumberFormat
                                  value={element.base_volume}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  decimalScale={5}
                                  suffix={' m³'}
                              />
                          </div>
                          )

                      </div>
                  </CardDetail>
                  <CardDetail title={t("app.container.price")}>
                      <div className="flex justify-between gap-1 items-center">
                          <NumberFormat
                              value={element?.base_price * element.quantity ?? 0}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              decimalScale={2}
                              suffix={" " + (company.currency?.currency_symbol ?? '')}
                          />
                          (
                          <div className="text-xs text-gray-500">
                              <NumberFormat
                                  value={element?.base_price ?? 0}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  suffix={" " + (company.currency?.currency_symbol ?? '')}
                              />
                          </div>
                          )

                      </div>
                  </CardDetail>
              </div>
          </div>
      </li>
  );
};

const ContainerDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
    let {id, container_id}  = useParams();

    const isCheck = extras.isCheck.includes(element.id);
    const { company } = useSelector((state) => state.userCompany);

    const removeDetail = {
        title: t("app.products.remove_title"),
        message: t("app.products.remove_description"),
        endpoint: getPathParam([SHIPPING, id, CONTAINER, container_id, PRODUCT, element.id]),
        redirect: getWebPathParam([LOGISTICS,SHIPPING, id, CONTAINER, container_id, CONTAINER_LOAD_CONFIGURATION]),
    };

    const percentage =  ((element.quantity - element.available_quantity) * (100 / element.quantity));

  return (
      <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
          <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
              {
                  isCheck && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )
              }
              <input type="checkbox"
                     id={element.id}
                     onChange={extras.handleClick} checked={isCheck}
                     className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
          </TableDetail>
          <TableDetail extraClass={"whitespace-pre-line"}>
              <ProductCell isActiveImage={true} product={element.product}/>
          </TableDetail>

          <TableDetail>
              <div className="flex before:flex-1 justify-between mb-1">
                  <div className="text-xs text-gray-900">
                      <NumberFormat
                          value={(element.quantity - element.available_quantity)}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                      />
                       /
                      <NumberFormat
                          value={element.quantity}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                      />
                  </div>
              </div>
              <div className="w-full bg-gray-200 rounded">
                  <div className={classNames(
                      percentage >= 50 ? 'shim-green' : '',
                      percentage < 50  ? 'shim-red' : '',
                      "text-xs font-medium rounded text-white text-center p-2 leading-none"
                  )} style={{width: percentage+'%'}}>
                  </div>
              </div>


         </TableDetail>


        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={extras.stocks.find(r => r.product_id === element.product.id)?.stock ?? 0 }
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </div>
            <div className="text-xs text-gray-500">
                {t("app.container.availability")}
            </div>
        </TableDetail>


        <TableDetail>
          <div className="text-sm text-gray-900">
              <NumberFormat
                  value={element.base_net_weight * element.quantity}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  suffix={' Kg'}
              />
          </div>
          <div className="text-xs text-gray-500">
              <NumberFormat
                  value={element.base_net_weight}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={5}
                  suffix={' Kg'}
              />
          </div>
        </TableDetail>

        <TableDetail>
          <div className="text-sm text-gray-900">
              <NumberFormat
                  value={element.base_gross_weight * element.quantity}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  suffix={' Kg'}
              />
          </div>
          <div className="text-xs text-gray-500">
              <NumberFormat
                  value={element.base_gross_weight}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={5}
                  suffix={' Kg'}
              />
          </div>
        </TableDetail>


        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element.base_volume * element.quantity}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                    suffix={' m³'}
                />
            </div>
            <div className="text-xs text-gray-500">
                <NumberFormat
                    value={element.base_volume}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={5}
                    suffix={' m³'}
                />
            </div>
        </TableDetail>

        <TableDetail>
            <div className="text-sm text-gray-900">
                <NumberFormat
                    value={element?.base_price * element.quantity ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    decimalScale={2}
                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                />
            </div>
            <div className="text-xs text-gray-500">
                <NumberFormat
                    value={element?.base_price ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                />
            </div>
        </TableDetail>



          <TableDetail>
            <ActionsMenu>
                <button className={'text-left'}
                        onClick={(e) => {
                            extras.setProductToEdit(element);
                        }}
                >
                    {t("app.common.edit")}
                </button>
                <button className={'text-left'}
                        onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                >
                    {t("app.common.remove")}
                </button>
            </ActionsMenu>
        </TableDetail>
      </tr>
  );
};




export default LoadConfiguration;
