import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    postCompany
} from "../api/company";

export const userStatus = {
    FAILED: "failed",
    SUCCESS: "success",
    PENDING: "pending"
};

const initialState = {
  companyDetail: null,
};



/***************
 * ASYNC THUNK *
 ***************/

export const createCompany = createAsyncThunk("company/create", async ({data}) => {
    try {
        return {
            companyDetail: await postCompany({data}),
        };
    } catch (e) {
        throw e;
    }
});

/*******************
 * EXPOSED          *
 *******************/




/*******************
 * REDUCERS EVENTS *
 *******************/

export const companyReducer = createSlice({
  name: "company",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(createCompany.pending, (state) => {
            state.isCreateCompanyLoading = true;
        })
        .addCase(createCompany.fulfilled, (state, action) => {
            for (const k in action.payload) {
                state[k] = action.payload[k];
            }
            state.isCreateCompanyLoading = false;
        })
        .addCase(createCompany.rejected, (state, action) => {
            state.isCreateCompanyLoading = false;
            state.createCompanyException = action.error.message;
        });
  },
});

export default companyReducer.reducer;
