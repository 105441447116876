import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router";
import {
  loadUserDataIfMissing,
  userOnboardingStatus,
} from "../../reducers/userReducer";
import Sidebar from "../partials/sidebar/sidebar";
import {DASHBOARD, LOGIN, ONBOARDING} from "../../routes/endpoints";
import Header from "../partials/header/header";
import { store } from "../../reducers/rootReducer";
import { toSubscribeOnLoad } from "../../reducers/subscribers";
import Notification from "../partials/Notification/notification";
import {
  setFakePermission,
  initialPermissionsLoad,
  isUserCompanyProcessing,
  processPermissionList
} from "../../reducers/userCompanyReducer";
import { useTranslation } from "react-i18next";
import Loading from "./loading";
import {USER_AGENT, USER_EMPLOYEE} from "../../constants/config";
import moment from "moment";
import italianLocale from "moment/locale/it";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import {getWebPathParam} from "../../utils/converter";
import {useNavigate} from "react-router-dom";


const RequireAuth = () => {
  const { isAuthenticated, onboardingStatus, loggedCompany, detail, isSwitched } =
    useSelector((state) => state.user);

  const { status } =
      useSelector((state) => state.userCompany);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (detail) {
      i18n.changeLanguage(detail.locale);
      if(detail.locale === 'it'){
        moment.locale(detail.locale, italianLocale);
      }else{
        moment.locale(detail.locale);
      }

    }
    // eslint-disable-next-line
  }, [detail]);

  useEffect(() => {
    const unsubList = toSubscribeOnLoad.map((fn) => store.subscribe(fn));

    return () => {
      unsubList.forEach((fn) => {
        fn();
      });
    };
  }, []);

  useEffect( () => {
    dispatch(loadUserDataIfMissing());
    if (loggedCompany) {
        document.title = 'Dokless | ' + (loggedCompany?.business_name ?? 'The app that makes your business borderless');
       dispatch(processPermissionList());
       dispatch(initialPermissionsLoad());
       dispatch(subscriptionUsageLimits(loggedCompany.id));
    } else {
      if (detail && ((detail.default_type === USER_AGENT) || (detail.default_type === USER_EMPLOYEE)) && detail?.connections?.length === 0) {
        dispatch(setFakePermission())
      }
    }
    // eslint-disable-next-line
  }, [dispatch, loggedCompany]);


  useEffect( () => {
    if (isSwitched !== undefined) {
        navigate(getWebPathParam([DASHBOARD]))
    }
    // eslint-disable-next-line
  }, [isSwitched]);

  if (loggedCompany && isUserCompanyProcessing(status)) {
    return <Loading />;
  }


  if (!isAuthenticated) {
    return <Navigate to={LOGIN} state={{ from: location }} />;
  }

  if (
    onboardingStatus !== undefined &&
    onboardingStatus !== userOnboardingStatus.CONFIRMED
  ) {
    return <Navigate to={ONBOARDING} />;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Notification />

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="md:pl-64 flex flex-col flex-1">
        <Header setSidebarOpen={setSidebarOpen} />
        <main id="scrollableDiv" className={'overflow-y-auto min-h-screen pb-20'}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RequireAuth;
