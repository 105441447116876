import {COMPANY, SHIPPINGS, SHIPPING, SHIPPING_IMPORTER, EMAIL_LIST, TRACKING, STATUS} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";


// shipping global
export const getShippings = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, SHIPPINGS] ) + serialize(params), {signal: signal});

export const getShippingDynamic = ({company_id, params, data, signal}) =>
    API.post(getPathParam([COMPANY, company_id, SHIPPINGS] ) + serialize(params), data, {signal: signal});


export const getShipping = ({company_id, shipping_id, params = null}) =>
    API.get(getPathParam([COMPANY, company_id, SHIPPING, shipping_id ] ) + (params ? serialize(params) : ''));


export const getShippingTracks = ({company_id, shipping_id, params = null}) =>
    API.get(getPathParam([COMPANY, company_id, SHIPPING, shipping_id, TRACKING ] ) + (params ? serialize(params) : ''));

// shipping exporter
export const postShipping = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, SHIPPING]), data);

export const putShipping = ({company_id, shipping_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, SHIPPING, shipping_id]), data);

// shipping importer
export const postShippingImporter = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, SHIPPING_IMPORTER]), data);

export const putShippingImporter = ({company_id, shipping_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, SHIPPING_IMPORTER, shipping_id]), data);

export const putShippingStatus = ({company_id, shipping_id, status,  data}) =>
    API.put(getPathParam([COMPANY, company_id, SHIPPING, shipping_id, STATUS, status]), data);


export const getCompanyShippingMails = ({company_id, shipping_id}) =>
    API.get(getPathParam([COMPANY, company_id, SHIPPING, shipping_id, EMAIL_LIST ] ));
