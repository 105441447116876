import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";
import BaseList from "../common/base-list";
import {getPathParam} from "../../../utils/converter";
import {ATTACHMENT, ATTACHMENTS, COMPANY, CONNECTION} from "../../../api/endpoints";
import {PaperClipIcon} from "@heroicons/react/outline";
import FileCell from "../../common/table/file-cell";
import ActionsMenu from "../../common/table/actions-menu";
import CardDetail from "../../common/list/card-detail";
import TableDetail from "../../common/list/table-detail";
import {useForm} from "react-hook-form";
import UploadFile from "../upload/upload-file";
import InputText from "../../common/form/input-text";
import {Warning} from "../../common/alert/banner";
import InputSubmit from "../../common/form/input-submit";
import {fetchCountries} from "../../../reducers/configReducer";

export default function ConnectionAttachments() {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const { connectionId, typeActive, removeActive, onRefresh } = useOutletContext();

    return (
            <>
                <div className="max-w-5xl mx-auto  py-6 px-4 sm:px-6 lg:px-8">

                    <div className="flex justify-between items-center flex-wrap gap-4 ">
                        <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                            {t("app.leads.attachments")}
                        </h1>
                    </div>



                </div>

                <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">



                    <BaseList
                        columns={[
                            t("app.catalog.attachment"),
                            t("app.catalog.note"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ATTACHMENTS])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ATTACHMENT])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ATTACHMENT, ':id'])}
                        baseResult={{total_rows: 0, attachments: []}}
                        resultLabel={'attachments'}
                        title={t("app.catalog.attachments")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={PaperClipIcon}
                        addFormLabel={t("app.catalog.add", {type: t("app.catalog.attachment")})}
                        btnAddLabel={t("app.catalog.add", {type: t("app.catalog.attachment")})}
                        editForm={EditForm}
                        editTitle={t("app.catalog.attachment")}
                        isActiveEdit={true}
                        isActiveFileViewer={true}
                        typeActive={typeActive}
                        removeActive={removeActive}
                        onUpdateParent={() => {
                            onRefresh(true);
                        }}
                    />
                </div>
            </>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ATTACHMENT, element.id])
    };



    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <FileCell file={element} canView={true} onOpenFile={() => {
                        extras.onOpenFile(element);
                    }} />
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>

                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.note")}>
                        {element.file_note}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ATTACHMENT, element.id])
    };


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        <FileCell file={element} canView={true} onOpenFile={() => {
                            extras.onOpenFile(element);
                        }} />
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.file_note}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-1">
                    <UploadFile
                        onUpload={(data) => {
                            setValue('file', {media_url: data.media_url});
                            setValue('file_name', data.detail.name);
                        }}
                        onCancel={(r) => {
                            if(r){
                                setValue('file', undefined);
                                setValue('file_name', undefined);
                            }
                        }}
                        onException={(e) => {}}
                        isLoading={false}
                        label={t("app.catalog.upload_attachment")}
                    />

                    <InputText
                        id={'file_note'}
                        type={'text'}
                        errors={errors.file_note}
                        input={{...register("file_note", { required: false })}}
                        label={t("app.catalog.note")}
                    />
                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            {
                watch('file') && (
                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.catalog.add")}
                        />
                    </div>
                )
            }


        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };


    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="space-y-4 pt-4 pb-5">

                        <UploadFile
                            onUpload={(data) => {
                                setValue('file', {media_url: data.media_url});
                                setValue('file_name', data.detail.name);
                            }}
                            onCancel={(r) => {
                                if(r){
                                    setValue('file', undefined);
                                    setValue('file_name', undefined);
                                }
                            }}
                            onException={(e) => {}}
                            isLoading={false}
                            label={t("app.catalog.upload_attachment")}
                        />
                        <InputText
                            id={'file_note'}
                            type={'text'}
                            errors={errors.file_note}
                            input={{...register("file_note", { required: false })}}
                            label={t("app.catalog.note")}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>

            </form>

        </>
    );
}
