import {
    GET_LOGGED_USER,
  OAUTH_LOGIN,
  OAUTH_LOGOUT,
  OAUTH_REGISTER,
  VERIFY_RESEND_MAIL,
  VERIFY_USER_MAIL,
  RESET_PASSWORD,
  USER,
  COMPANY,
  ME,
    NOTIFICATIONS,
    NOTIFICATION_READ
} from "./endpoints";
import API from "../utils/apiUtils";
import {getPathParam, serialize} from "../utils/converter";

// oauth
export const oauthLogin = (data) => API.post(OAUTH_LOGIN, data);

export const oauthLogout = () => API.post(OAUTH_LOGOUT);

export const oauthRegister = (data) => API.post(OAUTH_REGISTER, data);

// User
export const getUserDetails = () => API.get(GET_LOGGED_USER);

export const putUser = ({ user_id, data }) =>
  API.put(getPathParam([USER, user_id]), data);

// Verify
export const resendVerificationMail = (user_id) =>
  API.put(VERIFY_RESEND_MAIL + "/" + user_id);

export const confirmVerificationMail = (confirmation_code) =>
  API.put(VERIFY_USER_MAIL + "/" + confirmation_code);

// reset password
export const sendResetPassword = (data) => API.post(RESET_PASSWORD, data);

export const checkConfirmResetPassword = (confirmation_code) =>
  API.get(RESET_PASSWORD + "/" + confirmation_code + "/check");

export const confirmResetPassword = (confirmation_code, data) =>
  API.put(RESET_PASSWORD + "/" + confirmation_code, data);

// user company details
export const getUserCompanyDetails = (company_id) =>
  API.get(getPathParam([COMPANY, company_id, ME]));

// notification
export const getNotifications = ({params, signal}) =>
    API.get(getPathParam([NOTIFICATIONS] ) + (params ? serialize(params)  : ''), signal && {signal: signal});

export const postNotificationRead = () =>
    API.post(getPathParam([NOTIFICATION_READ] ), {});
