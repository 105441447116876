import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getUserConnectionVerify, putUserConnectionVerify} from "../../api/connection";
import {useParams} from "react-router-dom";
import Loader from "../../components/common/loader/loader";
import InputButton from "../../components/common/form/input-button";
import {FailedBoxLink} from "../../components/common/alert/failed-box";
import {ACCOUNT, CONNECTIONS, DASHBOARD} from "../endpoints";
import {useTranslation} from "react-i18next";
import {SuccessBoxLink} from "../../components/common/alert/success-box";
import {getRefreshdDetails} from "../../reducers/userReducer";

const UserVerifyConnection = () => {


    let {connection_code}  = useParams();

    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState(null);

    const [isConfirmLoading, setConfirmLoading] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [exception, setException] = useState(true);

    const [notFound, setNotFound] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const confirmConnection = async () => {
        setConfirmLoading(true);
        putUserConnectionVerify({connection_code: connection_code})
            .then(response => {
                if(response.status === 'confirmed' ){
                    setConfirmed(true);
                    dispatch(getRefreshdDetails(response));
                }else{
                    setNotFound(true);
                }
                setConfirmLoading(false);
            }).catch(e => {
            setException(e.message);
            setConfirmLoading(false);
        });
    };

    useEffect(() => {
        function fetchConnection(){
            setLoading(true);
            getUserConnectionVerify({connection_code: connection_code})
                .then(response => {
                    if(response.status === 'confirmed' ){
                        setConfirmed(true);
                    }else{
                        setDetail(response);
                    }
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
                setNotFound(true);
            });
        }
        fetchConnection();
    }, [connection_code]);

    return (
        <div className="py-4  h-full flex flex-col before:flex-1 after:flex-1">
            <div className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {
                    isLoading ? (
                        <Loader />
                    ) : (
                        notFound ? (
                            <FailedBoxLink
                                title={t('app.message.generic_not_found')}
                                message={exception}
                                link={DASHBOARD}
                                linkTitle={t('app.common.go_to', {label: ' dashboard ->'})}
                            />
                        ) : (
                            isConfirmed ? (
                                <SuccessBoxLink
                                    title={t("app.supplier.connection_confirmed")}
                                    message={t("app.supplier.connection_confirmed_description")}
                                    link={ACCOUNT + CONNECTIONS}
                                    linkTitle={t('app.connection.back')}
                                />
                            ) : (
                                <div className="py-16 bg-white shadow overflow-hidden lg:py-24">
                                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                                        <div className="relative">
                                            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                                                {t("app.connection.connection_title")}
                                            </h2>
                                            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                                {t("app.connection.connection_description", {business_name: detail?.company?.business_name ?? ''})}
                                            </p>
                                        </div>

                                        <div className="mt-8 flex justify-center">
                                            <div className="inline-flex rounded-md shadow">
                                                <InputButton
                                                    isLoading={isConfirmLoading}
                                                    onClick={() => {
                                                        confirmConnection()
                                                    }}
                                                    label={t("app.supplier.connection_confirm")}
                                                    extraClass={'text-white bg-indigo-600 hover:bg-indigo-700'}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )

                        )
                    )
                }



            </div>
        </div>
    );
};

export default UserVerifyConnection;
