export default function SaveIcon(props) {
    return (

    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}  style={{strokeWidth: '1.5'}}>
            <path d="M17 4.828C16.9999 4.29761 16.7891 3.78899 16.414 3.414L14.586 1.586C14.211 1.2109 13.7024 1.00011 13.172 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V4.828Z"  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 1V5H5V1M5 17V9H13V17" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

