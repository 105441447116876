import React, { useEffect, useState } from "react";
import {useOutletContext} from "react-router-dom";
import PageHeader from "../../layout/page-header";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getElement } from "../../../api/config";
import { getPathParam } from "../../../utils/converter";
import { BOARDS, COMPANY } from "../../../routes/endpoints";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import BoardDetail from "../../../routes/board/board-details";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ConnectionTasks() {

    const { loggedCompany } = useSelector((state) => state.user);

    const { entityId } = useOutletContext();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [boards, setBoards] = useState({ total_rows: 0, boards: [] });


    // Panel Details
    const [isOpenPanel, setOpenPanel] = useState(false);
    const [boardData, setBoardData] = useState(false);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            if (loggedCompany) {
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, BOARDS]), signal, { entity_id: entityId });
                    setBoards(res);
                } catch (e) {
                    setBoards({ total_rows: 0, boards: [] });
                } finally {
                    setLoading(false);
                }
            }
        }


        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpenPanel]);



    return (
        <div className="py-6">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Page header */}
                <PageHeader title={t("app.board.title")}>

                </PageHeader>
            </div>
            <div className="max-w-5xl  mx-auto px-4 sm:px-6 md:px-8 relative">

                <LoaderWrapper isLoading={isLoading}>
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

                        {boards.boards.map((board) => (
                            <li key={board.id} className="col-span-1 divide-y divide-gray-200 ">
                                <div
                                    style={board.picture ? { backgroundImage: "url('" + board.picture.reference + "')" } : {}}
                                    className={classNames(
                                        "rounded-lg  min-h-[100px] shadow bg-center bg-cover text-white leading-5 relative no-underline p-2",
                                        !board.picture && "bg-gray-900",
                                    )}
                                >

                                    {
                                        board.picture && (
                                            <div className="rounded-lg absolute min-h-[100px] inset-0 bg-black  bg-opacity-50 transition-opacity" />
                                        )

                                    }

                                    <div className="flex flex-col h-20 justify-between relative">

                                        <div className="relative inline-block flex-auto text-base font-bold max-h-[50px] overflow-hidden w-full cursor-pointer" onClick={() => {
                                            setBoardData(board);
                                            setOpenPanel(true);
                                        }}>
                                            <h3 className="truncate text font-bold text-white">{board.title}</h3>
                                        </div>

                                        
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </LoaderWrapper>



                <BoardDetail
                    isOpenPanel={isOpenPanel}
                    setOpenPanel={setOpenPanel}
                    boardData={boardData}
                />


            </div>
        </div>
       
    );
}
