import React, {useEffect, useState} from "react";
import OnboardingIndex from "./index";
import InputText from "../../components/common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputSelect from "../../components/common/form/input-select";
import {Warning} from "../../components/common/alert/banner";
import InputSubmit from "../../components/common/form/input-submit";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {fetchCountries, fetchPhonePrefix} from "../../reducers/configReducer";
import {createCompany} from "../../reducers/companyReducer";
import {userOnboardingStatus, onboardingDetails} from "../../reducers/userReducer";
import {ONBOARDING, ONBOARDING_COMPANY_TYPE, ONBOARDING_DONE} from "../endpoints";
import {useNavigate} from "react-router-dom";
import BackLink from "../../components/common/form/back-link";
import {getUserDetails} from "../../api/user";


const StepCreateCompany = () => {


    const { isCountriesLoading, countries, isPhonePrefixLoading, prefix } = useSelector((state) => state.config);
    const { onboardingStatus } = useSelector((state) => state.user);
    const { isCreateCompanyLoading, createCompanyException } = useSelector((state) => state.company);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchPhonePrefix());

        if( onboardingStatus === userOnboardingStatus.CONFIRMED){
            navigate(ONBOARDING + ONBOARDING_DONE);
        }

        // eslint-disable-next-line
    }, [dispatch, onboardingStatus ]);

    const [isLoading, setLoading] = useState(false);

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        await dispatch(createCompany({data}));
        await getDetail();
    };

    const getDetail = async () => {
        setLoading(true);
        getUserDetails()
            .then(response => {
                dispatch(onboardingDetails(response));
                setLoading(false);
            }).catch(e => {
            setLoading(false);
        });
    };



    return (
       <OnboardingIndex>
           <div className="max-w-lg mx-auto px-4 py-8 w-3/4">

               <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("onboarding.company_create.title")}</h1>
               {/* Form */}
               <form onSubmit={handleSubmit(onSubmit)}>
                   <div className="space-y-4 mb-8">

                       <InputText
                           id={'business_name'}
                           type={'text'}
                           errors={errors.business_name}
                           input={{...register("business_name", { required: true })}}
                           label={t("app.form.business_name")}
                       />

                       <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
                           <Controller
                               name="phone_prefix"
                               rules={{ required: true }}
                               control={control}
                               render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                   <InputSelect
                                       label={t("app.form.phone_prefix")}
                                       name={name}
                                       options={prefix}
                                       value={value}
                                       onChange={(e) => {
                                           onChange(e);
                                       }}
                                       isLoading={isPhonePrefixLoading}
                                       isDisabled={isPhonePrefixLoading}
                                       errors={error}
                                   />
                               )}
                           />

                           <InputText
                               id={'phone'}
                               type={'text'}
                               errors={errors.business_phone}
                               input={{...register("business_phone", { required: true })}}
                               label={t("app.form.phone")}
                           />
                       </div>

                       <Controller
                           name="country"
                           rules={{ required: true }}
                           control={control}
                           render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                               <InputSelect
                                   label={t("app.form.country")}
                                   name={name}
                                   options={countries}
                                   value={value}
                                   onChange={(e) => {
                                       onChange(e);
                                   }}
                                   isLoading={isCountriesLoading}
                                   isDisabled={isCountriesLoading}
                                   errors={error}
                               />
                           )}
                       />


                   </div>

                   {/* Warning */}
                   {createCompanyException && (
                       <Warning
                           color={'red'}
                           message={createCompanyException}
                       />
                   )}

                   <div className="flex items-center justify-between mt-6">

                       <BackLink
                           backLabel={t("app.common.back")}
                           backLink={ONBOARDING + ONBOARDING_COMPANY_TYPE}
                       />

                       <InputSubmit
                           isLoading={isCreateCompanyLoading || isLoading}
                           label={t("onboarding.company_create.name")}
                       />
                   </div>

               </form>




           </div>
       </OnboardingIndex>
    );
};

export default StepCreateCompany;
