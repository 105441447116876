import {FilterButton, TrashButton} from "../../../components/layout/page-header";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import SubscriptionWrapper, {
    hasSubscriptionPermission
} from "../../../components/partials/restricted/subscription-wrapper";
import { permissionGroup, permissionType, sp} from "../../../constants/permissions";
import {useSelector} from "react-redux";
import ListSection from "../../../components/layout/list-section";
import ProductCell from "../../../components/common/table/product-cell";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import {getOrderProducts} from "../../../api/order";
import {PRODUCTS} from "../../endpoints";
import {useParams} from "react-router-dom";
import CatalogIcon from "../../../resources/icons/CatalogIcon";
import {getPathParam} from "../../../utils/converter";
import { ORDER, PRODUCT} from "../../../api/endpoints";
import ActionsMenu from "../../../components/common/table/actions-menu";
import EditProduct from "./edit-product";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {TrashIcon} from "@heroicons/react/outline";
import AddProduct from "../product";
import {hasPermission} from "../../../components/partials/restricted/base-permission-wrapper";

const OrderProducts = ({detail, setNeedRefresh}) => {

    const { t } = useTranslation();
    let { id } = useParams();

    const { permissions, subscriptions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((state) => state.user );

    const isSubActive = hasSubscriptionPermission(subscriptions, sp.ORDER_INTERNAL_PACKAGE);
    const canEditOrder =  hasPermission(permissions, permissionGroup.ORDER, permissionType.EDIT, companyType, true);


    const columns = [
        {
            header:  t("app.products.product"),
            id: 'product_code',
            accessor: r => r.product.name,
        },
        {
            header: t("app.orders.stock"),
            id: 'stock',
            accessor: r => r.stock
        },
        {
            header: t("app.orders.base_price"),
            id: 'base_price',
            accessor: r => r.base_price
        },
        {
            header: t("app.orders.quantity"),
            id: 'quantity',
            accessor: r => r.quantity
        },
        {
            header: t("app.orders.price"),
            id: 'quantity',
            accessor:  r =>  (r.quantity * r.base_price)
        },
        {
            header: "",
            accessor: null
        }
    ];


    const [isFetchProductsLoading, setFetchProductsLoading] = useState(true);

    const [query, setQuery] = useState("");
    const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});
    // eslint-disable-next-line
    const [limit] = useState(10000);
    const [offset] = useState(0);

    const [productList, setProductList] = useState( { products: [], total_rows: 0 });

    const [isOpenAdd, setIsOpenAdd] = useState(false);

    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const [productToEdit, setProductToEdit] = useState(null);
    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchOrderProducts() {
            setFetchProductsLoading(true);
            try {
                const res = await getOrderProducts({
                    order_id: id,
                    params: { limit: limit, offset: query.length > 0 ? 0 : offset, query: query },
                    signal,
                });
                setProductList(res);
            } catch (e) {
                setProductList({ products: [], total_rows: 0 });
                if(signal.aborted){
                    setFetchProductsLoading(true);
                }
            } finally {
                if(signal.aborted){
                    setFetchProductsLoading(true);
                }else{
                    setFetchProductsLoading(false);
                }
            }
        }

        fetchOrderProducts();
        return () => {
            controller.abort();
        };
    }, [offset, query, limit, id]);




    const handleSelectAll = () => {
        if(productList.products.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(productList.products.map(li => li.id));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };



    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <ListSection
                title={t("app.products.products")}
                columns={columns}
                totalRows={productList.total_rows}
                list={productList.products}
                onSearch={setQuery}
                isLoading={isFetchProductsLoading}
                mobileRow={OrderMobRow}
                desktopRow={OrderDeskRow}
                sortBy={sortBy}
                isTableSort={true}
                hasCheckbox={canEditOrder}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                onSortBy={(n) => {
                    setSortBy(n);
                }}
                extras={{
                    isSubActive,
                    isCheck,
                    handleClick,
                    setProductToEdit,
                    setIsOpenEdit,
                    canEditOrder,
                    onRemove: (r, detail) => {
                        setOpenRemove(r);
                        setRemoveDetail(detail);
                    },
                }}
            >

                <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={false} redirectOnFail={false}>

                    {
                        (isCheck.length > 0 && canEditOrder) && (
                            <div className="inline-flex items-center justify-center">
                                <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                                <TrashButton
                                    onClick={() => {
                                        const removeDetail = {
                                            title: t("app.orders.remove_product"),
                                            message: t("app.orders.remove_product_description"),
                                            endpoint: getPathParam([ORDER, id, PRODUCTS]) + '?order_products='+isCheck,

                                        };
                                        setOpenRemove(true);
                                        setRemoveDetail(removeDetail);
                                    }}
                                    icon={
                                        <TrashIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                        />
                                    }
                                />
                            </div>
                        )
                    }


                    {
                        canEditOrder && (
                            <FilterButton
                                onClick={() => {
                                    setIsOpenAdd(true);
                                }}
                                icon={
                                    <CatalogIcon
                                        className="w-4 h-4 fill-current flex-shrink-0"
                                        aria-hidden="true"
                                    />
                                }
                                text={t("app.products.add_product")}
                            />
                        )
                    }

                </SubscriptionWrapper>



            </ListSection>


            <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={false} redirectOnFail={false}>

                {(isOpenEdit && canEditOrder) && (
                    <EditProduct
                        isOpen={isOpenEdit}
                        product={productToEdit}
                        setIsOpen={setIsOpenEdit}
                        setDetails={(r) => {
                            setProductList(r);
                            setNeedRefresh(true);
                        }}
                    />
                )}


                {
                    canEditOrder && (
                        <AddProduct
                            isOpen={isOpenAdd}
                            detail={detail}
                            setIsOpen={(r) => {
                                setIsOpenAdd(r);
                            }}
                            setDetail={(r) => {
                                setProductList(r);
                                setNeedRefresh(true);
                            }}
                        />
                    )
                }




                {isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(r) => {
                            setProductList(r);
                            setIsCheck([]);
                            setSelectAll(false);
                            setNeedRefresh(true);
                        }}
                    />
                )}

            </SubscriptionWrapper>

        </div>
    )
};

const OrderMobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const isCheck = extras.isCheck.includes(element.id);

    const removeDetail = {
        title: t("app.orders.remove_product"),
        message: t("app.orders.remove_product_description"),
        endpoint: getPathParam([ORDER, id, PRODUCT, element.id])
    };




    return (
        <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>

            <div className="flex-grow">

                <div className="pb-4 items-center flex justify-between">

                   <div className="flex items-center">

                    {
                        extras.canEditOrder && extras.isSubActive && (
                            <input type="checkbox"
                                   id={element.id}
                                   onChange={extras.handleClick} checked={isCheck}
                                   className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        )
                    }

                    <ProductCell product={element.product} isActiveManufacture={false} />
                   </div>

                    {
                        extras.canEditOrder && (
                            <ActionsMenu>
                                {extras.canEditOrder && extras.isSubActive && (
                                    <button
                                        className={"text-left"}
                                        onClick={(e) => {
                                            extras.setProductToEdit(element);
                                            extras.setIsOpenEdit(true);
                                        }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )}
                                {extras.canEditOrder && extras.isSubActive && (
                                    <button
                                        className={"text-left"}
                                        onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )}
                            </ActionsMenu>
                        )
                    }

                </div>


                <div className="flex flex-col">
                    <CardDetail title={t("app.products.stock")}>
                        {element.product.stock}
                    </CardDetail>

                    <CardDetail title={t("app.products.manufacture_code")}>
                        {element.product.manufacture_reference}
                    </CardDetail>

                    <CardDetail title={t("app.products.product_code")}>
                        {element.product.product_reference}
                    </CardDetail>

                    <CardDetail title={t("app.orders.base_price")}>
                        <NumberFormat
                            value={element.discount_price}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalScale={2}
                            decimalSeparator={","}
                            prefix={"€ "}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.orders.quantity")}>
                        <NumberFormat
                            value={element.quantity}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.orders.price")}>
                        <NumberFormat
                            value={element.quantity * element.discount_price}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalScale={2}
                            decimalSeparator={","}
                            prefix={"€ "}
                        />
                    </CardDetail>
                </div>

            </div>
        </li>
    );
};

const OrderDeskRow = ({ element, extras }) => {

    const { t } = useTranslation();
    let { id } = useParams();
    const isCheck = extras.isCheck.includes(element.id);

    const removeDetail = {
        title: t("app.orders.remove_product"),
        message: t("app.orders.remove_product_description"),
        endpoint: getPathParam([ORDER, id, PRODUCT, element.id])
    };




    return (
        <tr>

            {
                extras.canEditOrder && extras.isSubActive && (
                    <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                        {
                            isCheck && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )
                        }
                        <input type="checkbox"
                               id={element.id}
                               onChange={extras.handleClick} checked={isCheck}
                               className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
                    </TableDetail>
                )
            }

            <TableDetail extraClass={"whitespace-pre-line"} >
                <ProductCell product={element.product}/>
            </TableDetail>

            <TableDetail>
                <NumberFormat
                    value={element?.stock ?? 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={""}
                />
            </TableDetail>

            <TableDetail>
                <NumberFormat
                    value={element.discount_price}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                    prefix={"€ "}
                />
            </TableDetail>
            <TableDetail>
                <NumberFormat
                    value={element.quantity}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />
            </TableDetail>
            <TableDetail>
                <NumberFormat
                    value={element.quantity * element.discount_price}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalScale={2}
                    decimalSeparator={","}
                    prefix={"€ "}
                />
            </TableDetail>

            <TableDetail>
                {
                    extras.canEditOrder && (
                        <ActionsMenu>
                            {extras.canEditOrder && extras.isSubActive && (
                                <button
                                    className={"text-left"}
                                    onClick={(e) => {
                                        extras.setProductToEdit(element);
                                        extras.setIsOpenEdit(true);
                                    }}
                                >
                                    {t("app.common.edit")}
                                </button>
                            )}
                            {extras.canEditOrder && extras.isSubActive && (
                                <button
                                    className={"text-left"}
                                    onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                >
                                    {t("app.common.remove")}
                                </button>
                            )}
                        </ActionsMenu>
                    )
                }

            </TableDetail>
        </tr>
    );
};


export default OrderProducts;
