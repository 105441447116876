import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import { getTemplateFields } from "../../../constants/config";

export default function TemplateEmailEditor({ label, isRequired, category = '', isDisabled = false, id, errors, warning, warningRegex, defaultValue, onChange, className = '' }) {
    const { t } = useTranslation();
    const quillRef = useRef(null);

    const editor = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ color: [] }, { background: [] }],
            ["clean"]
        ],
        clipboard: {
            matchVisual: false
        }
    };


    console.log(defaultValue);

    const [value, setValue] = useState(defaultValue ? defaultValue : '<p></p>');

    const handleVariableInsert = (variable) => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        if (range) {
            quill.insertText(range.index, variable);
        }
    };

    return (
        <div>
            {label && (
                <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                    {label} {isRequired && <span className="text-red-500">*</span>}
                </label>
            )}

            
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                modules={editor}
                className={className}
                readOnly={isDisabled}
                bounds={'.app'}
                onChange={(content) => {
                    setValue(content);
                    onChange(content);
                }}
            />
            {errors && errors.type === 'required' && (
                <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", { field: label })}</p>
            )}
            {errors && errors.type === 'validate' && (
                <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", { field: label })}</p>
            )}
            {errors && errors.type === 'pattern' && (
                <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", { field: label })}</p>
            )}


            {
                getTemplateFields(t).find(r => r.type === category) && (
                    <div className="mt-2">
                        <select onChange={(e) => handleVariableInsert(e.target.value)} className="p-2 form-input w-full disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed">
                            <option value="">
                                {t("app.common.placeholder", {
                                    field: t("app.settings.email_variable").toLowerCase(),
                                })}
                            </option>
                            {getTemplateFields(t).find(r => r.type === category).fields.map((variable, index) => (
                                <option key={variable.id} value={variable.value}>{variable.label}</option>
                            ))}
                        </select>
                    </div>
                )
            }

        </div>
    );
};
