import React, {useEffect, useState} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getWebPathParam} from "../../../../utils/converter";
import {COMPANY, EMAIL_PROVIDER, EMAIL} from "../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {getEmailProvider} from "../../../../api/config";
import placeholder from "../../../../resources/images/placeholder.jpeg";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import {AtSymbolIcon, MailIcon} from "@heroicons/react/outline";
import {emailRegex} from "../../../../constants/regex";
import {ArrowLeftIcon} from "@heroicons/react/solid/esm";
import {useNavigate, useParams} from "react-router-dom";
import FormPopup from "../../../../components/common/popup/form-popup";
import {putCompanyEmailProviderVerify} from "../../../../api/company";
import {CONFIGURATION, EMAIL_INTEGRATION} from "../../../endpoints";
import ButtonSubmit from "../../../../components/common/form/button-submit";
import {StatusBar} from "../../../../components/common/alert/status-bar";
import InputEmailEditor from "../../../../components/common/form/input-email-editor";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";

const EmailIntegration = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let {provider_type}  = useParams();

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [isLoadingConfirm, setLoadingConfirm] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);



    useEffect(() => {
        async function fetchConfirm() {

            const confirmCode = new URLSearchParams(window.location.search).get(
                "code"
            );

            if(confirmCode && provider_type && loggedCompany){
                setOpenConfirm(true);
                setLoadingConfirm(true);

                try {
                    const confirm = await putCompanyEmailProviderVerify({company_id: loggedCompany.id, type: provider_type, data: {code: confirmCode}});
                    if(confirm.status){
                        setConfirmed(true);
                        setNeedRefresh(!needRefresh);
                    }
                    setLoadingConfirm(false);
                } catch (e) {
                    setLoadingConfirm(false);
                } finally {
                    setLoadingConfirm(false);
                }
            }
        }


        fetchConfirm();
        // eslint-disable-next-line
    }, [loggedCompany, provider_type]);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_EMAIL_INTEGRATION)]} renderBaseOnFail={true} >
            {
                loggedCompany && (

                    <>

                        <ConfirmPopup
                            isOpenConfirm={isOpenConfirm}
                            isConfirmLoading={isLoadingConfirm}
                            isConfirmed={isConfirmed}
                            setOpenConfirm={(r) => {
                                setOpenConfirm(r);
                            }}
                        />

                        <BaseList
                            columns={[
                                t("app.provider.name"),
                                t("app.provider.email"),
                                t("app.provider.status"),
                                "",
                            ]}
                            endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL])}
                            storeEndpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_PROVIDER, ':code'])}
                            updateEndpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL, ':id'])}
                            baseResult={[]}
                            title={t("app.provider.name")}
                            showHeader={true}
                            mobRow={MobRow}
                            deskRow={DeskRow}
                            addForm={AddProvider}
                            icon={MailIcon}
                            addFormLabel={t("app.provider.add_name")}
                            btnAddLabel={t("app.provider.add_name")}
                            editForm={EditProvider}
                            editTitle={t("app.provider.edit_mail")}
                            defaultWith={'max-w-md'}
                            isActiveEdit={true}
                            isActiveFormPm={false}
                            needRefresh={needRefresh}
                            onStoreData={(response) => {
                                setNeedRefresh(!needRefresh);
                            }}

                        />
                    </>

                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.provider.remove_title"),
        message: t("app.provider.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, EMAIL, element.id])
    };

    const getStatus = () => {
        if(element.status === 'pending'){
            return {status: 'processing', title: t("app.provider.pending")};
        }

        if(element.status === 'active'){
            return {status: 'success', title: t("app.provider.active")};
        }

        if(element.status === 'expired'){
            return {status: 'blocked', title: t("app.provider.expired")};
        }

        return {status: 'failed', title: t("app.provider.failed")};
    }


    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.provider.name")}>
                        {element.name}
                    </CardDetail>

                    <CardDetail title={t("app.provider.email")}>
                        {element.email}
                    </CardDetail>

                    <CardDetail title={t("app.provider.status")}>
                        <StatusBar
                            status={getStatus().status}
                            title={getStatus().title}
                        />
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.provider.remove_title"),
        message: t("app.provider.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, EMAIL, element.id])
    };


    const getStatus = () => {
        if(element.status === 'pending'){
            return {status: 'processing', title: t("app.provider.pending")};
        }

        if(element.status === 'active'){
            return {status: 'success', title: t("app.provider.active")};
        }

        if(element.status === 'expired'){
            return {status: 'blocked', title: t("app.provider.expired")};
        }

        return {status: 'failed', title: t("app.provider.failed")};
    }

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.email}
            </TableDetail>


            <TableDetail>
                <StatusBar
                    status={getStatus().status}
                    title={getStatus().title}
                />
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddProvider = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();


    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const [isProviderLoading, setProviderLoading] = useState(true);
    const [providerList, setProviderList] = useState([]);
    const [provider, setProvider] = useState(null);


    const onSubmit = async (data) => {
        data['provider_mail_code'] = provider.code;
        onSubmitData(data);
    };


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchProviders() {
            if (loggedCompany) {
                setProviderLoading(true);
                try {
                    const res = await getEmailProvider({signal});
                    setProviderList(res);
                    setProviderLoading(false);
                } catch (e) {
                    setProviderList([]);
                    setProviderLoading(false);
                } finally {
                    setProviderLoading(false);
                }
            }
        }

        fetchProviders();
        return () => {
            controller.abort();
        };
    }, [loggedCompany]);



    return (

        <LoaderWrapper isLoading={isProviderLoading}>

            {
                provider ? (
                    <form onSubmit={handleSubmit(onSubmit)} className="mt-2 p-4">
                        <div className="space-y-4">
                            <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                                <InputText
                                    id={"name"}
                                    type={"text"}
                                    errors={errors.name}
                                    input={{ ...register("name", { required: true }) }}
                                    label={t("app.provider.name")}
                                />

                                <InputText
                                    id={"email"}
                                    type={"text"}
                                    errors={errors.email}
                                    input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                                    label={t("app.provider.email")}
                                />
                            </div>

                            {
                                provider.code === 'other' && (
                                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                                        <InputText
                                            id={"smtp_host"}
                                            type={"text"}
                                            errors={errors.smtp_host}
                                            input={{ ...register("smtp_host", { required: true }) }}
                                            label={t("app.provider.smtp_host")}
                                        />

                                        <InputText
                                            id={"smtp_port"}
                                            type={"text"}
                                            errors={errors.smtp_port}
                                            input={{ ...register("smtp_port", { required: true }) }}
                                            label={t("app.provider.smtp_port")}
                                        />

                                        <InputText
                                            id={"smtp_username"}
                                            type={"text"}
                                            errors={errors.smtp_username}
                                            input={{ ...register("smtp_username", { required: true }) }}
                                            label={t("app.provider.smtp_username")}
                                        />

                                        <InputText
                                            id={'smtp_password'}
                                            type={'Password'}
                                            errors={errors.smtp_password}
                                            input={{...register("smtp_password", { required: true })}}
                                            label={t("app.provider.smtp_password")}
                                        />
                                    </div>

                                )
                            }

                        </div>
                        <p className="mt-3 text-xs" dangerouslySetInnerHTML={{__html: t("app.provider.terms_privacy_verify")}} />

                        {/* Warning */}
                        {exception && (
                            <Warning message={exception} />
                        )}

                        <div className="flex items-center justify-between mt-6">
                            <div
                                className="text-sm underline inline-flex  items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setValue('smtp_host', null);
                                    setValue('smtp_port', null);
                                    setValue('smtp_username', null);
                                    setValue('smtp_password', null);
                                    setValue('name', null);
                                    setValue('email', null);
                                    setProvider(null);
                                }}
                            >
                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                {t("app.common.back")}
                            </div>

                            <InputSubmit
                                isLoading={isLoading}
                                label={provider.code !== 'other' ? t("app.provider.add_name_verify") : t("app.provider.add_name")}
                            />
                        </div>
                    </form>
                ) : (
                    <>
                        <ul className="divide-y divide-gray-200">
                            {
                                providerList.map(provider => (
                                    <li className={"block hover:bg-indigo-50/[0.5] cursor-pointer px-4"} onClick={() => {setProvider(provider)} }>
                                        <div className="flex items-center  py-3 ">
                                            <div className="min-w-0 flex-1 flex items-center">
                                                <div className="ml-2">

                                                    {
                                                        provider.code !== 'other' ? (
                                                            <img className="object-contain h-8 w-8 rounded-md" src={provider?.picture?.reference ?? placeholder} alt="" />
                                                        ) : (
                                                            <div className={'rounded-md'}><AtSymbolIcon className="h-8 w-8" /></div>
                                                        )
                                                    }

                                                </div>

                                                <div className="min-w-md flex-1 px-4 ">
                                                    <div>
                                                        <p className="text-sm font-medium text-blue-1000">
                                                            {provider.code !== 'other' ? provider.name : t("app.provider.other_provider")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )
            }
        </LoaderWrapper>









    );
}

const EditProvider = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    const { t } = useTranslation();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1 mt-4">
                        <InputText
                            id={"email"}
                            type={"text"}
                            errors={errors.email}
                            input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                            label={t("app.provider.email")}
                            isDisabled={true}
                        />


                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.provider.name")}
                            isDisabled={data.status !== 'active'}
                        />

                        {
                            data.email_provider.code === 'other' && data.status === 'expired' && (
                                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1 mt-3">

                                    <InputText
                                        id={"smtp_host"}
                                        type={"text"}
                                        errors={errors.smtp_host}
                                        input={{ ...register("smtp_host", { required: true }) }}
                                        label={t("app.provider.smtp_host")}
                                    />

                                    <InputText
                                        id={"smtp_port"}
                                        type={"text"}
                                        errors={errors.smtp_port}
                                        input={{ ...register("smtp_port", { required: true }) }}
                                        label={t("app.provider.smtp_port")}
                                    />

                                    <InputText
                                        id={"smtp_username"}
                                        type={"text"}
                                        errors={errors.smtp_username}
                                        input={{ ...register("smtp_username", { required: true }) }}
                                        label={t("app.provider.smtp_username")}
                                    />

                                    <InputText
                                        id={'smtp_password'}
                                        type={'Password'}
                                        errors={errors.smtp_password}
                                        input={{...register("smtp_password", { required: false })}}
                                        label={t("app.provider.smtp_password")}
                                    />
                                </div>

                            )
                        }

                        <Controller
                            name="email_footer"
                            rules={{ required: false }}
                            control={control}
                            render={({field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputEmailEditor
                                    label={t("app.provider.email_footer")}
                                    errors={error}
                                    imageEdit={true}
                                    defaultValue={value}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />


                    </div>




                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3 mb-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={data.status !== 'active' ? t("app.provider.verify_mail") : t("app.form.save") }
                    />
                </div>
            </form>

        </>
    );
}

const ConfirmPopup = ({isOpenConfirm, setOpenConfirm, isConfirmLoading, isConfirmed}) => {

    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <FormPopup
            title={t("app.provider.email_verify")}
            isOpen={isOpenConfirm}
            defaultWith={'max-w-lg'}
            setIsOpen={(r) => {
                if(r === false){
                    navigate(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION]));
                }
                setOpenConfirm(r);
            }}
        >

            <LoaderWrapper isLoading={isConfirmLoading}>
                {
                    isConfirmed  ? (
                        <div className="text-center p-6">
                            <h1 className="text-blue-1000 font-bold text-4xl"> {t("app.provider.confirm_title")}</h1>
                            <p className="my-2 text-gray-800">{t("app.provider.confirm_description")}</p>
                        </div>
                    ) : (
                        <div className="text-center p-6">
                            <h1 className="text-blue-1000 font-bold text-4xl"> {t("app.provider.error_title")}</h1>
                            <p className="my-2 text-gray-800">{t("app.provider.error_description")}</p>
                        </div>
                    )
                }


                <div className="p-4 flex before:flex-1 items-center justify-between ">
                    <ButtonSubmit
                        isLoading={false}
                        label={t("app.common.close")}
                        onClick={() => {
                            navigate(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION]));
                            setOpenConfirm(false);
                        }}
                    />
                </div>

            </LoaderWrapper>
        </FormPopup>
    )
}




export default EmailIntegration;
