import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import React, {Fragment} from "react";
import BasePermissionWrapper from "../../partials/restricted/base-permission-wrapper";
import {useTranslation} from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableMenu({tableNavigation, setActiveGroup}) {

    const { t } = useTranslation();

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div className="group">
                <Menu.Button className="btn border-blue-1000 rounded-full text-blue-1000 hover:bg-blue-900  group-hover:text-white" >
                    {t("app.common.more")}
                    <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-blue-1000 group-hover:text-white" aria-hidden="true"/>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                    {tableNavigation.map((item) => (
                        item.isActive && (
                            <BasePermissionWrapper key={item.name} requiredPermissions={item?.permission ?? null}>
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() => {
                                                    setActiveGroup(item.actionType)
                                                }}
                                                className={`${
                                                    active || item.current ? 'bg-blue-1000 text-solitude-200' : 'bg-solitude-200'
                                                } group flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer`}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        active || item.current
                                                            ? 'text-solitude-200'
                                                            : 'text-blue-1000 group-hover:text-solitude-200',
                                                        'w-5 h-5 mr-2',
                                                        item.extraClass ? item.extraClass : ''
                                                    )}
                                                    aria-hidden="true" />
                                                {item.name}
                                            </div>
                                        )}
                                    </Menu.Item>
                                </div>
                            </BasePermissionWrapper>
                        )

                    ))}



                </Menu.Items>
            </Transition>
        </Menu>
    )
}
