import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import {getSelectParam} from "../../../utils/converter";
import InputText from "../../../components/common/form/input-text";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import EditFooter from "../../../components/partials/footer/edit-footer";
import {putUser} from "../../../api/user";
import {onboardingDetails} from "../../../reducers/userReducer";
import {Warning} from "../../../components/common/alert/banner";
import toast from "react-hot-toast";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import InputSelect from "../../../components/common/form/input-select";
import {fetchCountries} from "../../../reducers/configReducer";
import {italianVat} from "../../../constants/regex";

const BillingSettings = () => {

    const { register, handleSubmit, setValue, control, watch, formState: { errors } } = useForm({ mode: 'onBlur' });
    const { t, i18n } = useTranslation();
    const { detail } = useSelector((state) => state.user );
    const { isCountriesLoading, countries } =
        useSelector((state) => state.config);

    let dispatch = useDispatch();

    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [exception, setException] = useState(false);


    const updateUserDetail = async (data) => {
        if(detail){
            setUpdateLoading(true);
            putUser({user_id: detail.id, data})
                .then(response => {
                    dispatch(onboardingDetails(response));
                    i18n.changeLanguage(response.locale);
                    setUpdateLoading(false);
                    toast.success(t("app.settings.update_success"))
                    setValue('password', '');
                }).catch(e => {
                setException(e.message);
                setUpdateLoading(false);
            });
        }
    };

    const onSubmit = async (data) => {
        //cleanData(data);
        await updateUserDetail(data);
    };

    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('billing_company_name', detail?.billing_company_name ?? null);
            setValue('city', detail?.city ?? null);
            setValue('address', detail?.address ?? null);
            setValue('zip_code', detail?.zip_code ?? null);
            if(detail.country){
                setValue('country', getSelectParam(detail.country, "name"))
            }
            setValue('vat_code', detail?.vat_code ?? null);
            setValue('billing_note_detail', detail?.billing_note_detail ?? null);
        }
        dispatch(fetchCountries());
    }, [detail, setValue, dispatch]);


    return (
        <div className="mx-auto">
            <div className="bg-white shadow-sm rounded-sm relative ">

                <form className="lg:col-span-9 shadow" onSubmit={handleSubmit(onSubmit)}>

                    {/* Profile section */}
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                        <div className="flex flex-col lg:flex-row">
                            <div className="flex-grow space-y-6">

                                <BasePermissionWrapper
                                    adminHasAccess={false}
                                    requiredPermissions={[]}
                                >

                                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                                        <InputText
                                            id={"billing_company_name"}
                                            type={"text"}
                                            errors={errors.billing_company_name}
                                            input={{
                                                ...register("billing_company_name", { required: true }),
                                            }}
                                            label={t("app.form.business_name")}
                                        />

                                        <InputText
                                            id={"city"}
                                            type={"text"}
                                            errors={errors.city}
                                            input={{
                                                ...register("city", { required: false }),
                                            }}
                                            label={t("app.company.city")}
                                        />

                                        <InputText
                                            id={"address"}
                                            type={"text"}
                                            errors={errors.address}
                                            input={{
                                                ...register("address", { required: false }),
                                            }}
                                            label={t("app.company.address")}
                                        />

                                        <InputText
                                            id={"zip_code"}
                                            type={"text"}
                                            errors={errors.zip_code}
                                            input={{
                                                ...register("zip_code", { required: false }),
                                            }}
                                            label={t("app.company.zip_code")}
                                        />

                                        <Controller
                                            name="country"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={t("app.form.country")}
                                                    name={name}
                                                    options={countries}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isCountriesLoading}
                                                    isDisabled={isCountriesLoading}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <InputText
                                            id={"vat_code"}
                                            type={"text"}
                                            isDisabled={watch("country") === null}
                                            errors={errors.vat_code}
                                            input={{
                                                ...register("vat_code", { required: false, pattern: watch("country")?.code === 'IT' ? italianVat: false }),
                                            }}
                                            label={t("app.company.vat_code")}
                                        />

                                        <InputText
                                            id={"billing_note_detail"}
                                            type={"text"}
                                            errors={errors.billing_note_detail}
                                            input={{
                                                ...register("billing_note_detail", { required: false }),
                                            }}
                                            label={t("app.company.billing_note_detail")}
                                        />

                                    </div>
                                </BasePermissionWrapper>


                            </div>
                        </div>

                        {/* Warning */}
                        {exception && (
                            <Warning
                                color={'red'}
                                message={exception}
                            />
                        )}

                    </div>

                    {/* Privacy section */}
                    <div className="pt-6 ">
                        <EditFooter
                            btnLabel={t("app.settings.update_detail")}
                            isLoading={isUpdateLoading}
                        />
                    </div>




                </form>

            </div>
        </div>
    );
};

export default BillingSettings;
