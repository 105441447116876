import React, { useCallback, useEffect, useState } from "react";
import InputSubmit from "../../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import { fetchContainerTypes, fetchTermTypes } from "../../../reducers/configReducer";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../components/common/form/input-select";
import { Warning } from "../../../components/common/alert/banner";
import { COMPANY, CONNECTION, FULFILLMENT, STATUS } from "../../endpoints";
import { getSelectParams, getPathParam, getSelectParam, getShippingTypeParam } from "../../../utils/converter";
import { getPermission, permissionGroup, permissionType } from "../../../constants/permissions";
import {useTranslation} from "react-i18next";
import InputPortSelect from "../../../components/common/form/input-port-select";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import { RadioGroup } from '@headlessui/react';
import { AIR, DELIVERY, LAND, SEA } from "../../../constants/config";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import { ADDRESSES, ATTACHMENT, COMBINATION } from "../../../api/endpoints";
import DynamicSearch from "../../../components/partials/common/dynamic-search";
import InputDate from "../../../components/common/form/input-date";
import { deleteElement, getElement, getWarehouses, postElement, putElement } from "../../../api/config";
import FulfillmentSlideLeft from "../../../components/logistic/fulfillment-slide-left";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import Initials from "../../../components/common/avatar/initials";
import toast from "react-hot-toast";
import BoxUploadMultifile from "../../../components/partials/upload/box-upload-multifile";
import {  DuplicateIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import BoxIcon from "../../../resources/icons/BoxIcon";
import { ActionButton } from "../../../components/layout/page-header";
import { Disclosure } from '@headlessui/react'
import PalletIcon from "../../../resources/icons/PalletIcon";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import NumberFormat from "react-number-format";
import RemovePopup from "../../../components/common/popup/remove-popup";
import InputText from "../../../components/common/form/input-text";
import { debounce } from 'lodash';
import AirIcon from "../../../resources/icons/AirIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import TruckIcon from "../../../resources/icons/TruckIcon";
import { getFormattedDate } from "../../../utils/timeUtils";
import { FulfillmentStatusList } from "../../../components/common/alert/fulfillment-status-list";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function ManageFulfillment({ isOpen, setIsOpen, manageDetails, onRefresh }) {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (manageDetails && isOpen && loggedCompany) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, FULFILLMENT, manageDetails.id]), signal);
                    setResponseData(res);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, manageDetails, loggedCompany]);



    const onUpdate = async (data) => {
        if (manageDetails && isOpen && loggedCompany) {
            data['id'] = manageDetails.id;
            putElement(getPathParam([COMPANY, loggedCompany.id, FULFILLMENT, manageDetails.id]), data)
                .then(response => {
                    setResponseData(response);
                    toast.success(t("app.tasks.update_success"));
                    onRefresh(true);
                }).catch(e => {
                    toast.error(e.message);
                });
        }
    };


    const getTitle = () => {
        let title = '';

        if (responseData){
            title = (responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup"))  +" #"+responseData.internal_reference;
        }
        return title;
    }
   

    return (
        <FulfillmentSlideLeft
            title={getTitle()}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setClean={() => {
                
            }}
        >
            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.EDIT)]}>
                <div className={classNames(" mb-6 bg-white ")}>

                <LoaderWrapper isLoading={isLoading}>
                        <div className="px-4 sm:px-6  ">

                            {/* TITLE */}
                            <div className=" mt-1 pt-1.5 ">

                                
                                <div className="flex items-center justify-between">

                                    <div className="flex items-center">
                                        <Initials
                                            color={responseData?.connection?.color ?? ''}
                                            name={responseData?.connection?.business_name ?? ''}
                                            isVerified={false}
                                            registryType={responseData.connection?.registry_type ?? false}
                                        />
                                        <div className="text-left">
                                            <div className="text-2xl -mb-1 font-bold whitespace-pre-line text-blue-1000">
                                                {responseData?.connection?.business_name ?? ''}
                                            </div>
                                            <p>{responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup")}</p>
                                        </div>
                                    </div>


                                    <FulfillmentStatusList
                                        isLoading={false}
                                        fulfillmentType={responseData.fulfillment_type}
                                        status={responseData?.status ?? 'draft'}
                                        isEditable={true}
                                        onChangeStatus={(r) => {
                                            const detailData = {
                                                title: t("app.fulfillment.status_confirm_title"),
                                                message: t("app.fulfillment.status_confirm_description", { status: t("app.fulfillment." + (responseData.fulfillment_type === DELIVERY ? 'd' : 'p')+"_status_" + r) }),
                                                endpoint: getPathParam([COMPANY, loggedCompany.id, FULFILLMENT, responseData.id, STATUS, r]),
                                            };

                                            setConfirmDetail(detailData);
                                            setOpenConfirm(true);
                                        }}
                                    />
                                
                                </div>

                            </div>

                            <div className={classNames("mt-3 p-2 relative")}>
                                
                                
                                
                                {/* PROPERIES OK */}
                                <PropertiesCard
                                    responseData={responseData}
                                    canEdit={true}
                                    onUpdate={async (property, value) => {
                                         await onUpdate({ [property]: value });
                                    }}
                                />



                                {/* ATTACHMENTS OK */}
                                <AttachmentsCard
                                    canEdit={true}
                                    responseData={responseData}
                                />


                                {/* COMBINATIONS  OK */}
                                <CombinationCard
                                    canEdit={true}
                                    responseData={responseData}
                                    onRefresh={() => {
                                        onRefresh(true);
                                    }}
                                />

                            </div>
                        </div>


               

                </LoaderWrapper>
                </div>


                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() => {
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(r) => {
                            onRefresh(true);
                            setResponseData(r);
                        }}
                    />
                )}
            </BasePermissionWrapper>
        </FulfillmentSlideLeft>
    );
}






// PROPERTIES
const PropertiesCard = ({ responseData, onUpdate, canEdit }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);
    const { termTypesList, isTermTypesListLoading } = useSelector((state) => state.config);

    const {
        register,
        control,
        formState: { errors },
        setValue,
        watch
    } = useForm();



    useEffect(() => {
        dispatch(fetchTermTypes());
    }, [dispatch, loggedCompany]);

  
    useEffect(() => {
        if (responseData.terms_type) {
            setValue('terms_type', getSelectParam(responseData.terms_type, "name"));
        }

        if (responseData.expected_date) {
            setValue('expected_date', responseData.expected_date);
        }

        if (responseData.destination_port) {
            setValue('destination_port', responseData.destination_port);
        }

        if (responseData.departure_port) {
            setValue('departure_port', responseData.departure_port);
        }
        

        if (responseData.reference) {
            setValue('reference', responseData.reference);
        }
        
    }, [responseData, setValue]);


    // eslint-disable-next-line
    const debouncedFetchData = useCallback(debounce((reference) => {
        onUpdate('reference', reference);
    }, 1000), []);

  

    useEffect(() => {
        if (watch('reference') !== responseData.reference){
            debouncedFetchData(watch('reference'));

        }
        // eslint-disable-next-line
    }, [watch('reference')]);



    return (
        <dl>

            {
                responseData && (
                    <>
                        <PropertyCard title={t("app.fulfillment.internal_reference", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}>
                            <p>{responseData.internal_reference}</p>
                        </PropertyCard>

                        <PropertyCard title={t("app.fulfillment.shipping_type")}>
                            <div className={"flex items-center"}>
                                <div className="mr-2">
                                    {
                                        (responseData.shipping_type && (responseData.shipping_type.code === LAND)) && (
                                            <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }

                                    {
                                        (responseData.shipping_type && (responseData.shipping_type.code === SEA)) && (
                                            <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }
                                    {
                                        (responseData.shipping_type && (responseData.shipping_type.code === AIR)) && (
                                            <AirIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }
                                </div>


                                <p>{responseData.shipping_type.name}</p>
                            </div>
                        </PropertyCard>

                        <PropertyCard title={t("app.fulfillment.reference")}>
                            <InputText
                                id={'reference'}
                                type={'text'}
                                isShowLabel={false}
                                errors={errors.reference}
                                input={{ ...register("reference", { required: false }) }}
                                label={t("app.fulfillment.reference")}
                            />
                        </PropertyCard>

                        <PropertyCard title={t("app.fulfillment.expected_date", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}>
                            <Controller
                                name="expected_date"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.fulfillment.expected_date", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}
                                        isShowLabel={false}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2021"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        isShowTime={true}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            setValue('expected_date', e);
                                            onChange(e);
                                            onUpdate('expected_date', e);
                                        }}
                                    />
                                )}
                            />
                        </PropertyCard>

                        {
                            responseData.shipping_type.code !== SEA && (
                                <PropertyCard title={t("app.fulfillment.destination_address", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}>
                                    <Controller
                                        name="connection_address"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <DynamicSearch
                                                isDisabled={false}
                                                selectLabel={t("app.fulfillment.destination_address", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}
                                                endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, responseData.connection.id, ADDRESSES])}
                                                responseLabel={'addresses'}
                                                label={'full_address'}
                                                isShowLabel={false}
                                                errors={error}
                                                value={value}
                                                name={name}
                                                onResponse={(r) => {
                                                    let item = r.find(item => item.id === responseData?.connection_address?.id ?? '');
                                                    if (item) {
                                                        setValue('connection_address', item);
                                                    }
                                                }}
                                                onChange={async (r) => {
                                                    onChange(r);
                                                    onUpdate('connection_address', r);
                                                }}
                                            />
                                        )}
                                    />

                                </PropertyCard>
                            )
                        }

                        {
                            responseData.shipping_type.code === SEA && (
                                <PropertyCard title={t("app.fulfillment.pickup_port")}>

                                    <InputPortSelect
                                        isShowLabel={false}
                                        placeholder={t("app.fulfillment.pickup_port")}
                                        selectedItem={watch('departure_port')}
                                        onSelect={(e) => {
                                            setValue('departure_port', e)
                                            onUpdate('departure_port', { id: e.id});
                                        }}
                                    />

                                </PropertyCard>
                            )
                         }

                        {
                            responseData.shipping_type.code === SEA && (
                                <PropertyCard title={t("app.fulfillment.delivery_port")}>

                                    <InputPortSelect
                                        isShowLabel={false}
                                        placeholder={t("app.fulfillment.delivery_port")}
                                        selectedItem={watch('destination_port')}
                                        onSelect={(e) => {
                                            setValue('destination_port', e)
                                            onUpdate('destination_port', { id: e.id });
                                        }}
                                    />

                                </PropertyCard>
                            )
                        }

                        <PropertyCard title={t("app.shippings.terms_type")}>
                            <Controller
                                name="terms_type"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputSelect
                                        isShowLabel={false}
                                        label={t("app.shippings.terms_type")}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                            onUpdate('terms_type', e);
                                        }}
                                        options={termTypesList}
                                        value={value}
                                        isLoading={isTermTypesListLoading}
                                        isDisabled={isTermTypesListLoading}
                                        errors={error}
                                    />
                                )}
                            />
                        </PropertyCard>
                    </>      
                )
            }
           
            






        </dl>
    )
}

const PropertyCard = ({ title, children }) => {

    return (
        <div className="p-1 md:grid md:grid-cols-4 gap-4 px-0 md:flex md:items-center ">
            <dt className="text-sm font-medium leading-6 text-gray-900  md:py-1 flex items-center group ">
                <p>{title}</p>
            </dt>

            <dd className={classNames("md:col-span-3 mt-0 text-sm leading-6  text-gray-900")}>
                {children}
            </dd>
        </div>
    )
}

// ATTACHMENTS
const AttachmentsCard = ({ responseData, onRefresh, canEdit }) => {

    const [selectedAttachments, setSelectedAttachments] = useState(responseData?.fulfillment_attachments ?? []);
    const [isLoading, setLoading] = useState(false);


    const { t } = useTranslation();


    const onSubmit = async (data) => {
        postElement(getPathParam([FULFILLMENT, responseData.id, ATTACHMENT]), data)
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"));
                onRefresh(true);
            }).catch(e => {
                //toast.error(e.message);
                setLoading(false);
            });

    };

    const onRemove = async (id) => {
        deleteElement(getPathParam([FULFILLMENT, responseData.id, ATTACHMENT, id]))
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"))
                onRefresh(true);
            }).catch(e => {
                toast.error(e.message);
            });

    };
    const onUpload = async (data) => {
        setLoading(true);
        data.map(async r => {
            await setTimeout(async function () {
                await onSubmit({
                    file_name: r.name,
                    file: {
                        media_url: r.reference
                    }
                })
            }, 500);
        })
        setLoading(false);
    };


    return (
        <>
            <div className="mt-6 ">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.board.attachments")}</h1>


                <BoxUploadMultifile
                    onUpload={async (data) => {
                        await onUpload(data);
                    }}
                    onException={(e) => { }}
                    isLoading={isLoading}
                    canEdit={canEdit}
                    isDepend={true}
                    genericDocuments={selectedAttachments}
                    onRemoveGeneric={(id) => {
                        setSelectedAttachments(selectedAttachments.filter(function (obj) {
                            return obj.id !== id;
                        }));
                        onRemove(id);
                    }}
                />
            </div>
        </>
    )
}

// COMBINATIONS
const CombinationCard = ({ responseData, onRefresh, canEdit }) => {

    const [combinations, setCombinations] = useState(responseData?.fulfillment_combinations ?? []);
    const [isAddCombination, setAddCombination] = useState(false);
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});


    const { t } = useTranslation();



    const onCloneData = async (data) => {
        postElement(getPathParam([FULFILLMENT, responseData.id, COMBINATION]), data)
        .then(response => {
            setCombinations(response.combinations);
            onRefresh(true);
            toast.success(t("app.tasks.update_success"));
        }).catch(e => {
        });


    };

    return (
        <>
            <div className="mt-6">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.fulfillment.configuration", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}</h1>                
        
                {
                    combinations.length > 0 ? (
                        <>

                            {
                                combinations.map((combination) => (
                                    <CombinationDetail
                                        key={combination.id}
                                        combination={combination}
                                        responseData={responseData}
                                        canEdit={true}
                                        canRemove={true}
                                        onRemove={(detail) => {
                                            setRemoveDetail(detail);
                                            setOpenRemove(true);
                                        }}
                                        onClone={(detail) => {
                                            delete detail['id'];
                                            onCloneData(detail);
                                        }}
                                        onUpdateData={(detail) => {
                                            setCombinations(detail.combinations);
                                            onRefresh(true);
                                        }}

                                    />
                                ))

                            }

                            <div className={'mt-5 mx-auto w-1/2 '}>
                                <div
                                    onClick={() => {
                                        setAddCombination(true);
                                    }}
                                    className="flex p-2 justify-center items-center  rounded-full border border-gray-200 px-4 text-center text-sm font-medium   text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer">
                                    <div className="flex items-center ">
                                        <div className={' mr-2  text-blue-1000'}>
                                            <PlusIcon
                                                className="h-4 w-4"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <span>{t("app.fulfillment.add_configuration")}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="bg-white border-2 border-gray-300 border-dashed rounded-md px-4">
                            <div className="max-w-7xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:px-8">
                                <div className="text-center">
                                    <BoxIcon
                                        className="h-20 w-20 text-blue-1000 m-auto"
                                    />
                                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                        {t("app.container.packaging_title")}
                                    </p>
                                    <p className="max-w-xl -mt-1 mb-5 mx-auto text-md text-gray-500">
                                        {t("app.container.packaging_description")}
                                    </p>

                                    <ActionButton
                                        extraClass={' md:ml-0 ml-0'}
                                        onClick={() => {
                                            setAddCombination(true);
                                        }}
                                        text={t("app.fulfillment.add_configuration")}
                                    />

                                </div>
                            </div>
                        </div>
                    )
                }


                
            </div>

            
            {
                isAddCombination && (
                    <AddConfiguration
                        isOpen={isAddCombination}
                        setIsOpen={setAddCombination}
                        responseData={responseData}
                        onAdded={(r) => {
                            setCombinations(r.combinations);
                            onRefresh(true);
                        }}
                    />
                )
            }

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setCombinations(r.combinations);
                        onRefresh(true);
                    }}
                />
            )}

        </>
    )
}


const CombinationDetail = ({ combination, responseData, onClone, onUpdateData, onRemove, canEdit, canRemove }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);


    // get Shipping
    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(combination?.fulfillment_combination_warehouses ?? []);

    // containers type list
    const { containerTypesList, isContainerTypesListLoading } = useSelector((state) => state.config);


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(fetchContainerTypes());

        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [dispatch, loggedCompany]);


    const {
        watch,
        handleSubmit,
        control,
        setValue
    } = useForm({ mode: "onBlur" });



    useEffect(() => {
        if (combination !== null) {
            for (const k in combination) {

                if (typeof combination[k] === "object" && k !== 'delivery_type') {
                    setValue(k, getSelectParam(combination[k], 'name'));
                } else if (typeof combination[k] === "object" && k === 'delivery_type') {
                    setValue('delivery_type', getShippingTypeParam(combination[k]));
                } else {
                    setValue(k, combination[k]);
                }
            }
        }

    }, [combination, setValue]);

    const onSubmit = async (data) => {
        if (combination && responseData) {
            delete data['fulfillment'];

            if(responseData.shipping_type.code === SEA && combination.combination_type === "container"){
                let toRemove = combination?.fulfillment_combination_warehouses ?? [];
                data['warehouses'] = { to_add: [], to_remove: [] };
                if (selectedWarehouse.length > 0) {
                    data['warehouses']['to_add'] = selectedWarehouse;
                    toRemove = (combination.fulfillment_combination_warehouses ?? []).filter(obj1 => !selectedWarehouse.some(obj2 => obj1.company_warehouse.id === obj2.company_warehouse.id));
                }
                data['warehouses']['to_remove'] = toRemove;
            }


            setLoading(true);
            putElement(getPathParam([FULFILLMENT, responseData.id, COMBINATION, combination.id]), data)
                .then(response => {
                    toast.success(t("app.tasks.update_success"));
                    onUpdateData(response);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    setException(e.message);
                });


            
            
        }
    };


    const onUpdateVolume = () => {
        if (watch('length') > 0 && watch('width') > 0 && watch('height') > 0) {
            setValue('volume', (watch('length') * watch('width') * watch('height')) / 1000000);
        }
    };


    return (

        <Disclosure defaultOpen={false} as={'div'} className={'mt-3 mx-auto w-full border rounded-md bg-white'}>
            {({ open }) => (
                <>

                    <dl className={combination.combination_type === 'container' ? 'group grid grid-cols-1 gap-0.5 overflow-hidden text-center sm:grid-cols-2 hover:bg-solitude-100 lg:grid-cols-2'  : 'group grid grid-cols-1 gap-0.5 overflow-hidden text-center sm:grid-cols-2 hover:bg-solitude-100 lg:grid-cols-6'}>
                        <div className={combination.combination_type === 'container' ? "flex flex-col p-3" : "flex flex-col p-2 lg:col-span-2"}>
                            <div className={combination.combination_type === 'container' ? "flex items-center text-left" : "flex items-center justify-center text-left lg:justify-start lg:text-center" }>
                                <div className="mr-3 text-center">
                                    {
                                        (combination.combination_type === 'box') && (
                                            <BoxIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }

                                    {
                                        (combination.combination_type === 'pallet') && (
                                            <PalletIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }

                                    {
                                        (combination.combination_type === 'container') && (
                                            <ContainerIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }
                                </div>
                                <div>
                                    <div className="text-sm text-gray-900">
                                        {
                                            (combination.combination_type !== 'container') ? (
                                                <span>
                                                    <NumberFormat
                                                        value={combination?.length ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    x
                                                    <NumberFormat
                                                        value={combination?.width ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    x
                                                    <NumberFormat
                                                        value={combination?.height ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    {' '}cm
                                                </span>
                                            ) : (
                                                    <span>
                                                        {combination?.container_type?.name ?? '-'}
                                                    </span>
                                            )
                                        }
                                    </div>

                                    {
                                        (responseData.shipping_type.code !== SEA && combination.combination_type !== "container") && (
                                            <div className="text-xs text-gray-500" style={{ fontSize: '0.70rem' }}>
                                                {'Dimensions'}
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        {
                            combination.combination_type !== 'container' && (
                                <>
                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.volume ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' m³'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.volume")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.net_weight ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' Kg'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.gross_weight")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.gross_weight ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' Kg'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.gross_weight")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center p-2 group-hover:hidden">
                                        <div className="text-sm text-gray-900 text-center">
                                            {combination?.company_warehouse && (
                                                <div className="flex items-center justify-center  capitalize">
                                                    <WarehouseIcon className={'w-4 h-4 mr-2'} />
                                                    {combination?.company_warehouse?.name ?? ''}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        }        

                     

                        


                        <div className="flex flex-col inline-flex content-evenly  p-2 hidden group-hover:block">

                            <div className="text-sm text-gray-900 text-center">

                                <div className={combination.combination_type === 'container' ? "flex items-center justify-end  " : "flex items-center justify-center  "}>
                                    {
                                        canEdit && (
                                            <button
                                                type="button"
                                                className="text-gray-900 hover:text-gray-700  mr-2"
                                                onClick={() => {
                                                    onClone(combination)
                                                }}
                                            >
                                                <DuplicateIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        )
                                    }

                                    {
                                        canEdit && (
                                            <Disclosure.Button>
                                                <PencilIcon className="h-5 w-5 text-gray-900 hover:text-gray-700" />
                                            </Disclosure.Button>
                                        )
                                    }

                               

                                    {canRemove  && (
                                        <button
                                            type="button"
                                            className="text-red-700 hover:text-red-900  ml-2"
                                            onClick={() => {
                                                const removeDetail = {
                                                    title: t("app.common.remove_generic_title"),
                                                    message: t("app.common.remove_generic_description"),
                                                    endpoint: getPathParam([FULFILLMENT, responseData.id, COMBINATION, combination.id]),
                                                };
                                                onRemove(removeDetail)
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    )}
                              
                            </div>
                            </div>
                       
                        </div>

                        
                    </dl>

                    {
                        canEdit && (
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border-t">

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="space-y-2">
                                        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">

                                            {
                                                combination.combination_type === "container" && (
                                                    <div className={"col-span-2"}>

                                                        <Controller
                                                            name="container_type"
                                                            rules={{ required: true }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputSelect
                                                                    label={t("app.container.cont_type")}
                                                                    name={name}
                                                                    options={containerTypesList}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    isLoading={isContainerTypesListLoading}
                                                                    isDisabled={isContainerTypesListLoading}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )}


                                            {
                                                responseData.shipping_type.code !== SEA && (
                                                    <div className={"col-span-2"}>
                                                        <Controller
                                                            name="company_warehouse"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputSelect
                                                                    label={responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.pickup_place") : t("app.fulfillment.delivery_place")}
                                                                    name={name}
                                                                    options={warehouseList}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    isLoading={isWarehouseLoading}
                                                                    isDisabled={isWarehouseLoading}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            }





                                            {combination.combination_type !== "container" && (
                                                <>
                                                    <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">
                                                        <Controller
                                                            name="length"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.length")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        onUpdateVolume();
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="width"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.width")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        onUpdateVolume();
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="height"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.height")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        onUpdateVolume();
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                    </div>

                                                    <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">

                                                        <Controller
                                                            name="volume"
                                                            rules={{ required: true }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.volume")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' m³'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="gross_weight"
                                                            rules={{ required: true }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.gross_weight")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' Kg'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="net_weight"
                                                            rules={{ required: true }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.products.net_weight")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' Kg'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )}


                                            {
                                                (responseData.shipping_type.code === SEA && combination.combination_type !== "container") && (
                                                    <div className={"col-span-2"}>
                                                        <Controller
                                                            name="company_warehouse"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                                <InputSelect
                                                                    label={responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.pickup_load") : t("app.fulfillment.delivery_load")}
                                                                    name={name}
                                                                    options={warehouseList}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    isLoading={isWarehouseLoading}
                                                                    isDisabled={isWarehouseLoading}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            }

                                            {
                                                (responseData.shipping_type.code === SEA && combination.combination_type === "container") && (
                                                    <div className={"col-span-2"}>
                                                        <ManageWarehouses
                                                            selectedWarehouse={selectedWarehouse}
                                                            setSelectedWarehouse={setSelectedWarehouse}
                                                            fulfillmentType={responseData.fulfillment_type}
                                                        />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>

                                    {/* Warning */}
                                    {exception && (
                                        <Warning message={exception} />
                                    )}

                                    <div className="flex items-center  justify-end mt-6">
                                        <div className="flex items-center">
                                            <InputSubmit
                                                isLoading={isLoading}
                                                label={t("app.fulfillment.update_configuration")}
                                            />
                                        </div>
                                    </div>
                                </form>



                            </Disclosure.Panel>
                        )
                    }
                    
                </>
            )}
        </Disclosure>
       
    )
}




const AddConfiguration = ({ isOpen, setIsOpen, responseData, onAdded }) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const {
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm({ mode: "onBlur" });

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    // get Shipping
    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);

    // containers type list
    const { containerTypesList, isContainerTypesListLoading } = useSelector((state) => state.config);



    const combinationTypes = [
        responseData.shipping_type.code !== SEA && { type: 'box', name: '', icon: BoxIcon },
        { type: 'pallet', name: '', icon: PalletIcon },
        responseData.shipping_type.code === SEA && { type: 'container', name: '', icon: ContainerIcon },
    ].filter(Boolean);
    const [combinationType, setCombinationType] = useState(combinationTypes[0])



    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(fetchContainerTypes());

        function fetchWarehouses() {
            if (loggedCompany && isOpen) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [dispatch, loggedCompany, isOpen]);


    const storeCombination = async (data) => {
        setLoading(true);
        postElement(getPathParam([FULFILLMENT, responseData.id, COMBINATION]), data)
            .then(response => {
                toast.success(t("app.tasks.update_success"));
                onAdded(response);
                setLoading(false);
                setIsOpen(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });


    };

    const onSubmit = async (data) => {
        data['combination_type'] = combinationType.type;
        if (combinationType.type === "container") {
            delete data['length'];
            delete data['width'];
            delete data['height'];
            delete data['volume'];
            delete data['gross_weight'];
            delete data['net_weight'];

            if (selectedWarehouse.length > 0){
                data['warehouses'] = selectedWarehouse;
            }

        }
        await storeCombination(data);
    };


    const onUpdateVolume = () => {
        if (watch('length') > 0 && watch('width') > 0 && watch('height') > 0) {
            setValue('volume', (watch('length') * watch('width') * watch('height')) / 1000000);
        }
    };

    return (
        <FormPopup
            title={t("app.fulfillment.configuration", { type: responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup") })}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">


                        <fieldset className={"col-span-2"}>
                            <label className="block text-sm font-medium mb-1 text-gray-700">
                                {t("app.fulfillment.combination_type")}<span className="text-red-500">*</span>
                            </label>

                            <RadioGroup value={combinationType} onChange={setCombinationType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                                {combinationTypes.map((option) => (
                                    <RadioGroup.Option
                                        key={option.name}
                                        value={option}
                                        className={({ active, checked }) =>
                                            `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                                    ${(combinationType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                        }
                                    >
                                        <option.icon className="h-5 w-5" />
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                        </fieldset>




                       

                        {
                            combinationType.type === "container" && (
                                <>
                                    <Controller
                                        name="container_type"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputSelect
                                                label={t("app.container.cont_type")}
                                                name={name}
                                                options={containerTypesList}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                isLoading={isContainerTypesListLoading}
                                                isDisabled={isContainerTypesListLoading}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </>
                            )}



                        {combinationType.type !== "container" && (
                            <>
                                <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">
                                    <Controller
                                        name="length"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.length")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="width"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.width")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="height"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.height")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                </div>

                                <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">

                                    <Controller
                                        name="volume"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.volume")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' m³'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="gross_weight"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.gross_weight")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' Kg'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="net_weight"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.net_weight")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' Kg'}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}

                        <Controller
                            name="quantity_clone"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    errors={error}
                                />
                            )}
                        />


                        {
                            responseData.shipping_type.code !== SEA && (
                                <Controller
                                    name="company_warehouse"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <InputSelect
                                            label={responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.pickup_place") : t("app.fulfillment.delivery_place")}
                                            name={name}
                                            options={warehouseList}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            isLoading={isWarehouseLoading}
                                            isDisabled={isWarehouseLoading}
                                            errors={error}
                                        />
                                    )}
                                />
                            )
                        }


                        {
                            (responseData.shipping_type.code === SEA && combinationType.type !== "container") && (
                                <Controller
                                    name="company_warehouse"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <InputSelect
                                            label={responseData.fulfillment_type === 'delivery' ? t("app.fulfillment.pickup_load") : t("app.fulfillment.delivery_load")}
                                            name={name}
                                            options={warehouseList}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            isLoading={isWarehouseLoading}
                                            isDisabled={isWarehouseLoading}
                                            errors={error}
                                        />
                                    )}
                                />
                            )
                        }


                        {
                            (responseData.shipping_type.code === SEA && combinationType.type === "container") && (
                                <div className="col-span-2">
                                    <ManageWarehouses 
                                        selectedWarehouse={selectedWarehouse}
                                        setSelectedWarehouse={setSelectedWarehouse}
                                        fulfillmentType={responseData.fulfillment_type} 
                                    />
                                </div>
                            )
                        }


                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.fulfillment.add_configuration")}
                    />
                </div>
            </form>
        </FormPopup>
    );
}

const ManageWarehouses = ({ selectedWarehouse, setSelectedWarehouse, fulfillmentType }) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    // get Shipping
    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);

    const {
        watch,
        control,
        setValue
    } = useForm();


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [loggedCompany]);



    return(
        <>
            <h3 className="block text-sm font-medium mb-1 text-gray-700">
                {fulfillmentType === 'delivery' ? t("app.fulfillment.delivery_load") : t("app.fulfillment.pickup_load")}
            </h3>

            {
                (selectedWarehouse.length === 0  || warehouseList.filter(
                    (c) =>
                        !selectedWarehouse
                            .map((c) => c.company_warehouse.id)
                            .includes(c.id)
                ).length > 0) && (
                    <div className="col-span-2 grid md:grid-cols-7 lg:grid-cols-7 gap-x-5 sm:grid-cols-1">
                        <div className="col-span-3">
                            <Controller
                                name="company_warehouse"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputSelect
                                        label={fulfillmentType === 'delivery' ? t("app.fulfillment.pickup_place") : t("app.fulfillment.delivery_place")}
                                        isShowLabel={false}
                                        name={name}
                                        options={warehouseList.filter((c) => !selectedWarehouse.map((c) => c.company_warehouse.id).includes(c.id))}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isLoading={isWarehouseLoading}
                                        isDisabled={isWarehouseLoading}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-span-3">
                            <Controller
                                name="expected_date"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.fulfillment.expected_date", { type: fulfillmentType === 'delivery' ? t("app.fulfillment.pickup") : t("app.fulfillment.delivery") })}
                                        isShowLabel={false}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2021"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        isShowTime={false}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>


                        <div className="mt-3 md:mt-0 w-full mx-auto sm:flex-shrink-0 flex justify-between items-end">
                            <div
                                style={{ height: '2.4rem' }}
                                className={classNames(
                                    "btn cursor-pointer shadow-none bg-blue-1000 hover:bg-blue-900 rounded-md text-white w-full   ",
                                    (!watch('company_warehouse') || !watch('expected_date')) && "border-gray-200 bg-gray-100 text-gray-400 cursor-not-allowed",
                                )}
                                onClick={() => {
                                    if (watch('company_warehouse') && watch('expected_date')){
                                        let data = { company_warehouse: { id: watch('company_warehouse').id }, expected_date: watch('expected_date') }
                                        setValue('company_warehouse', null);
                                        setValue('expected_date', null);
                                        setSelectedWarehouse([...selectedWarehouse, data]);
                                    }
                                }}
                            >
                                <PlusIcon className="w-4 h-4 " />
                            </div>
                        </div>


                    </div>
                )
            }

            {
                (selectedWarehouse.length > 0 && warehouseList.length > 0) && (
                    <div className=" bg-white mt-3  rounded-sm border border-slate-200">
                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {selectedWarehouse.map((n, index) => (
                                    <li key={index} className=" px-2 bg-white hover:bg-gray-50">
                                        <div className="py-2 flex items-center justify-between space-x-3">
                                            <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                <div className="min-w-0 flex-1">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {warehouseList.find(r => r.id === n.company_warehouse.id).name}
                                                    </p>
                                                    <p className="text-xs font-normal text-gray-700">
                                                        {getFormattedDate(n.expected_date)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                <div
                                                    onClick={() => {
                                                        let da = selectedWarehouse.filter(r => r.company_warehouse.id !== n.company_warehouse.id)
                                                        setSelectedWarehouse(da);
                                                    }}
                                                    className={
                                                        "w-full btn bg-red-500 hover:bg-red-700 cursor-pointer"
                                                    }
                                                >
                                                    <TrashIcon
                                                        className="h-4 w-4 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                )
            }


            
        
        </>
    )
}




