import React, { useEffect, useState } from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import {fetchCountries, fetchShippingTypes, fetchPorts} from "../../reducers/configReducer";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../components/common/form/input-select";
import { Warning } from "../../components/common/alert/banner";
import { LOGISTICS, SHIPPING, SHIPPING_DETAIL } from "../endpoints";
import { getWebPathParam, getSelectParams } from "../../utils/converter";
import {Link, useNavigate} from "react-router-dom";
import InputDate from "../../components/common/form/input-date";
import {postShipping, postShippingImporter} from "../../api/shipping";
import {getPermission, permissionGroup, permissionType, sp} from "../../constants/permissions";
import {useTranslation} from "react-i18next";
import InputToggle from "../../components/common/form/input-toggle";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import moment from "moment";
import InputPortSelect from "../../components/common/form/input-port-select";
import CargoImg from "../../resources/illustration/ocean-shipping.svg";
import TruckImg from "../../resources/illustration/truck-shipping.svg";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {fetchCustomers, fetchSuppliers} from "../../reducers/connectionReducer";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import InputRadioImage from "../../components/common/form/input-radio-image";
import CitiesSearch from "../../components/common/form/cities-search";

const TYPE_STEP = 'shipping_type';
const INFORMATION_STEP = 'shipping_information';

const SEA = 'sea';
const LAND = 'land';


export default function AddShipping({ isOpen, setIsOpen }) {

  const { loggedCompany, isExporter } = useSelector((state) => state.user);
  const { isShippingTypesListLoading, shippingTypesList, isCountriesLoading, countries, isPortsLoading, portList } = useSelector((state) => state.config);
  const {customers, isLoadingCustomers, suppliers, isLoadingSuppliers} = useSelector((state) => state.connection);

  // shipping
  const [isCreateShippingLoading, setCreateShippingLoading] = useState(false);
  const [createShippingException, setShippingException] = useState(false);

  const [shippingType, setShippingType] = useState(false);
  const [step, setStep] = useState(TYPE_STEP);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
    const { t } = useTranslation();

  const storeShipping = async (data) => {
    if (loggedCompany) {
      setCreateShippingLoading(true);
        (isExporter ? postShipping({ company_id: loggedCompany.id, data }) :
            postShippingImporter({ company_id: loggedCompany.id, data }))
        .then((response) => {
            dispatch(subscriptionUsageLimits(loggedCompany.id));
            navigate(getWebPathParam([LOGISTICS, SHIPPING, response.id, SHIPPING_DETAIL]));
            setCreateShippingLoading(false);
        })
        .catch((e) => {
          setShippingException(e.message);
          setCreateShippingLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchShippingTypes());
    dispatch(fetchPorts());

    if(isExporter){
        dispatch(fetchCustomers({company_id: loggedCompany.id}));
    }else{
        dispatch(fetchSuppliers({company_id: loggedCompany.id}));
    }

  }, [dispatch, loggedCompany, isExporter]);


    const onSubmit = async (data) => {
    if(shippingType){
        data['shipping_type'] = shippingType;
    }
    await storeShipping(data);
  };

  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.CREATE)]}>
        <FormPopup
            showHeader={step !== TYPE_STEP}
            isActiveFormPm={step !== TYPE_STEP}
            title={t("app.shippings.new_shipping")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <SubscriptionWrapper requiredPermission={sp.SHIPPING_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
                <form onSubmit={handleSubmit(onSubmit)}>


                    {
                        step === TYPE_STEP && (
                            <>
                                <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl upercase">{t("app.shippings.shipping_type_title")}</h2>
                                <p className="mt-2 mb-6 max-w-2xl mx-auto text-center text-md text-gray-500">{t("app.shippings.shipping_type_description")}</p>

                                    <LoaderWrapper isLoading={isShippingTypesListLoading}>
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-5 sm:space-y-0 my-6 max-w-lg mx-auto sm:justify-center">
                                            <InputRadioImage
                                                name={"type"}
                                                errors={errors.type}
                                                input={{ ...register("type", { required: false }) }}
                                                value={SEA}
                                                onCheck={() => {
                                                    setShippingType(shippingTypesList.find(r => r.code === SEA));
                                                    setStep(INFORMATION_STEP);
                                                }}
                                                label={t("app.shippings.sea_title")}
                                                description={''}
                                                icon={<img src={CargoImg} alt={''} className="inline-flex w-48 text-indigo-700 mb-2" />}
                                            />


                                            <InputRadioImage
                                                name={"type"}
                                                errors={errors.type}
                                                input={{ ...register("type", { required: false }) }}
                                                value={LAND}
                                                onCheck={() => {
                                                    setShippingType(shippingTypesList.find(r => r.code === LAND));
                                                    setStep(INFORMATION_STEP);
                                                }}
                                                label={t("app.shippings.land_title")}
                                                description={''}
                                                icon={<img src={TruckImg} alt={''} className="inline-flex w-48 text-indigo-700 mb-2" />}
                                            />
                                        </div>

                                    </LoaderWrapper>



                            </>

                        )
                    }


                    {
                        (step === INFORMATION_STEP && watch('type') === SEA)  && (
                            <>
                                <div className="space-y-4">
                                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
                                        <Controller
                                            name="customer"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={isExporter ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                                    name={name}
                                                    options={isExporter ? getSelectParams(customers, "business_name") : getSelectParams(suppliers, "business_name")}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isExporter ? isLoadingCustomers : isLoadingSuppliers}
                                                    isDisabled={isExporter ? isLoadingCustomers : isLoadingSuppliers}
                                                    errors={error}
                                                />
                                            )}
                                        />


                                        <InputText
                                            id={"tracking"}
                                            type={"text"}
                                            errors={errors.tracking}
                                            input={{ ...register("tracking", { required: false }) }}
                                            label={t("app.shippings.tracking")}
                                        />

                                        <Controller
                                            name="departure_date"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputDate
                                                    label={t("app.shippings.departure_date")}
                                                    format={"YYYY-MM-D HH:mm:ss"}
                                                    placeholder={"Es. 01/12/2021"}
                                                    activeMinDate={false}
                                                    startDate={value}
                                                    disabled={false}
                                                    isShowTime={false}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        setValue('arrival_date', e);
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="arrival_date"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputDate
                                                    label={t("app.shippings.arrival_date")}
                                                    format={"YYYY-MM-D"}
                                                    placeholder={"Es. 01/12/2021"}
                                                    activeMinDate={true}
                                                    selectsEnd={true}
                                                    minDate={new Date(moment(watch('departure_date')).format('MM/DD/YYYY'))}
                                                    startDate={value}
                                                    disabled={!watch('departure_date')}
                                                    isShowTime={false}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />


                                        <InputPortSelect
                                            label={t("app.shippings.departure_port")}
                                            placeholder={t("app.shippings.departure_port")}
                                            isLoadingData={isPortsLoading}
                                            data={(watch('departure_port') && !portList.some(vessel => vessel.id === watch('departure_port').id)) ? [...portList, watch('departure_port')] : portList}
                                            selectedItem={watch('departure_port')}
                                            onSelect={ (e) => {
                                                setValue('departure_port', e)
                                            }}
                                        />



                                        <InputPortSelect
                                            label={t("app.shippings.arrival_port")}
                                            placeholder={t("app.shippings.arrival_port")}
                                            isLoadingData={isPortsLoading}
                                            data={(watch('arrival_port') && !portList.some(vessel => vessel.id === watch('arrival_port').id)) ? [...portList, watch('arrival_port')] : portList}
                                            selectedItem={watch('arrival_port')}
                                            onSelect={ (e) => {
                                                setValue('arrival_port', e)
                                            }}
                                        />

                                        <div className="col-span-2 pr-6">
                                            <Controller
                                                name="can_edit"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                             field: { onChange, value, name },
                                                             fieldState: { error },
                                                         }) => (
                                                    <InputToggle
                                                        label={t("app.shippings.can_edit_title")}
                                                        description={t("app.shippings.can_edit_description", {type: isExporter ?  t("app.customers.customer") : t("app.supplier.supplier")})}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={false}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>


                                        {/*
                    <Controller
                        name="terms_of_delivery"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                            <InputSelect
                                label={'Terms of delivery'}
                                name={name}
                                options={customerSelectList}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isLoading={isFetchCustomersLoading}
                                isDisabled={isFetchCustomersLoading}
                                errors={error}
                            />
                        )}
                    />

                    */}
                                    </div>
                                </div>
                                {/* Warning*/}
                                {createShippingException && (
                                    <Warning message={createShippingException} />
                                )}

                                <div className="flex  items-center justify-between mt-6">
                                    <div>
                                        <Link
                                            to={'#'}
                                            onClick={() => {
                                                setStep(TYPE_STEP);
                                            }}
                                            className="text-sm  inline-flex  items-center"
                                        >
                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                            {t("app.common.back")}
                                        </Link>
                                    </div>


                                    <InputSubmit
                                        isLoading={isCreateShippingLoading}
                                        label={t("app.shippings.add_shipping")}
                                    />
                                </div>
                            </>

                        )
                    }

                    {
                        (step === INFORMATION_STEP && watch('type') === LAND)  && (
                            <>
                                <div className="space-y-4">
                                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
                                        <Controller
                                            name="customer"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={isExporter ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                                    name={name}
                                                    options={isExporter ? getSelectParams(customers, "business_name") : getSelectParams(suppliers, "business_name")}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isExporter ? isLoadingCustomers : isLoadingSuppliers}
                                                    isDisabled={isExporter ? isLoadingCustomers : isLoadingSuppliers}
                                                    errors={error}
                                                />
                                            )}
                                        />


                                        <InputText
                                            id={"tracking"}
                                            type={"text"}
                                            errors={errors.tracking}
                                            input={{ ...register("tracking", { required: false }) }}
                                            label={t("app.shippings.tracking")}
                                        />

                                        <Controller
                                            name="departure_date"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputDate
                                                    label={t("app.shippings.departure_date")}
                                                    format={"YYYY-MM-D HH:mm:ss"}
                                                    placeholder={"Es. 01/12/2021"}
                                                    activeMinDate={false}
                                                    startDate={value}
                                                    disabled={false}
                                                    isShowTime={false}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        setValue('arrival_date', e);
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="arrival_date"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputDate
                                                    label={t("app.shippings.arrival_date")}
                                                    format={"YYYY-MM-D"}
                                                    placeholder={"Es. 01/12/2021"}
                                                    activeMinDate={true}
                                                    selectsEnd={true}
                                                    minDate={new Date(moment(watch('departure_date')).format('MM/DD/YYYY'))}
                                                    startDate={value}
                                                    disabled={!watch('departure_date')}
                                                    isShowTime={false}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="departure_country"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={t("app.shippings.departure_country")}
                                                    name={name}
                                                    options={countries}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isCountriesLoading}
                                                    isDisabled={isCountriesLoading}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                          <CitiesSearch
                                              label={t("app.shippings.departure_city")}
                                              placeholder={t("app.shippings.departure_city")}
                                              isRequired={true}
                                              selectedItem={watch('departure_city') ?? null}
                                              countryCode={watch('departure_country')?.code ?? null}
                                              isDisabled={!watch('departure_country')}
                                              onSelect={(e) => {
                                                  setValue('departure_city', e)
                                              }}
                                          />


                                        <Controller
                                            name="arrival_country"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={t("app.shippings.arrival_country")}
                                                    name={name}
                                                    options={countries}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isCountriesLoading}
                                                    isDisabled={isCountriesLoading}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                          <CitiesSearch
                                              label={t("app.shippings.arrival_city")}
                                              placeholder={t("app.shippings.arrival_city")}
                                              isRequired={true}
                                              selectedItem={watch('arrival_city') ?? null}
                                              countryCode={watch('arrival_country')?.code ?? null}
                                              isDisabled={!watch('arrival_country')}
                                              onSelect={(e) => {
                                                  setValue('arrival_city', e)
                                              }}
                                          />

                                        <div className="col-span-2 pr-6">
                                            <Controller
                                                name="can_edit"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                             field: { onChange, value, name },
                                                             fieldState: { error },
                                                         }) => (
                                                    <InputToggle
                                                        label={t("app.shippings.can_edit_title")}
                                                        description={t("app.shippings.can_edit_description", {type: isExporter ?  t("app.customers.customer") : t("app.supplier.supplier")})}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                        }}
                                                        defaultChecked={false}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {/* Warning*/}
                                {createShippingException && (
                                    <Warning message={createShippingException} />
                                )}

                                <div className="flex  items-center justify-between mt-6">

                                    <div>
                                        <Link
                                            to={'#'}
                                            onClick={() => {
                                                setStep(TYPE_STEP);
                                            }}
                                            className="text-sm  inline-flex  items-center"
                                        >
                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                            {t("app.common.back")}
                                        </Link>
                                    </div>

                                    <InputSubmit
                                        isLoading={isCreateShippingLoading}
                                        label={t("app.shippings.add_shipping")}
                                    />
                                </div>
                            </>

                        )
                    }









            </form>
            </SubscriptionWrapper>

        </FormPopup>
    </BasePermissionWrapper>
  );
}
