import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import SlideFull from "../../../../../components/layout/slide-full";
import UnderlineTab from "../../../../../components/partials/Tabs/underline-tab";
import {getElement, getPermissionSpecific, getPermissionTypes, putElement} from "../../../../../api/config";
import LoaderWrapper from "../../../../../components/common/loader/loader-wrapper";
import {Controller, useForm} from "react-hook-form";
import InputToggle from "../../../../../components/common/form/input-toggle";
import {getPathParam} from "../../../../../utils/converter";
import {COMPANY, PERMISSION, PERMISSION_GROUP} from "../../../../../api/endpoints";
import {getPermission, permissionGroup, permissionSpecific as ps, permissionType} from "../../../../../constants/permissions";
import PrimaryButton from "../../../../../components/common/button/primary-btn";
import toast from "react-hot-toast";
import BasePermissionWrapper from "../../../../../components/partials/restricted/base-permission-wrapper";
import PermissionRequiredPopup from "../../../../../components/common/popup/permission-required-popup";
import {FilterIcon} from "@heroicons/react/outline";
import PermissionFilter from "./permission-filter";
export const typesList = Object.values(permissionType);





const CompanyPermission = ({isOpen, setIsOpen, groupDetail, onClose}) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { setValue, control, watch } = useForm();
    const sortOrder = ['dashboard','sales', 'shipment', 'connection', 'catalog', 'marketplace', 'company_configuration'];

    const tabs = [
        { name: t("app.permissions.menu_tab"), group: "menu" },
        { name: t("app.permissions.general_permission_tab"), group: "general_permission" },
        { name: t("app.permissions.specific_permission_tab"), group: "specific_permission" },
    ];

    const [activeGroup, setActiveGroup] = useState("menu");
    const [isLoadingPermissions, setLoadingPermissions] = useState(true);
    const [permissionDetails, setPermissionDetails] = useState(false);
    const [isOpeRequired, setOpenRequired] = useState(false);
    const [requiredLog, setRequiredLog] = useState(false);



    //Type & menu
    const [permissions, setPermissions] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [activePermissions, setActivePermissions] = useState([]);


    //Specific
    const [permissionSpecific, setPermissionSpecific] = useState([]);
    const [specificList, setSpecificList] = useState([]);
    const [activePermissionSpecific, setActivePermissionSpecific] = useState([]);


    //Create
    const [isLoadingLoad, setLoadingLoad] = useState(false);


    //filter
    const [isOpenFilter, setOpenFilter] = useState(false);
    const [filterType, setFilterType] = useState(false);

    const createPermissions = () => {
        setLoadingLoad(true);

        let permissions = [];
        let remove_permission = [];
        let permission_specific = [];
        let remove_permission_specific = [];

        // eslint-disable-next-line
        activePermissions.map(data => {
            if(data.active){
                permissions.push(data.id);
            }else{
                remove_permission.push(data.id);
            }
        })

        // eslint-disable-next-line
        activePermissionSpecific.map(data => {
            if(data.active){
                permission_specific.push(data.id);
            }else{
                remove_permission_specific.push(data.id);
            }
        })


        putElement(getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, groupDetail.id, PERMISSION]), {id:groupDetail.id, permissions, remove_permission, permission_specific, remove_permission_specific}).then((response) => {
           // setPermissions(response);
            toast.success(t("app.permissions.permission_update"));
            setActivePermissions([]);
            setActivePermissionSpecific([]);
            setLoadingLoad(false);

            if(response.required_log){
                setRequiredLog(response.required_log);
                setOpenRequired(true);
            }

        }).catch((e) => {
            setLoadingLoad(false);
        });
    }


    useEffect(() => {
        if(activePermissions.length > 0  || activePermissionSpecific.length > 0){
            createPermissions();
        }
        // eslint-disable-next-line
    }, [activeGroup]);


    const elaboratePermissions = (permissions) => {
        const menuList = [];
        const permissionList = [];
        const collections = [];
        const collectionsPermissions = [];

        permissions.forEach((p) => {
            const [collection, group, permission_type] = (p.name).split(".");
            if (permission_type === "menu") {

                if(groupDetail.connection_type){
                    if((groupDetail.connection_type === 'importer' && collection !== 'connection' && collection !== 'catalog' && collection !== 'company_configuration') || (groupDetail.connection_type === 'exporter' && collection !== 'connection' && collection !== 'company_configuration')){
                        if(group  !== 'lead'){
                            if (!menuList[collection]) {
                                menuList[collection] = [];
                            }
                            menuList[collection].push(group);
                        }
                    }
                }else{
                    if (!menuList[collection]) {
                        menuList[collection] = [];
                    }
                    menuList[collection].push(group);
                }
            }else{

                if(groupDetail.connection_type){
                    if((groupDetail.connection_type === 'importer' && collection !== 'connection' && collection !== 'catalog' && collection !== 'company_configuration') || (groupDetail.connection_type === 'exporter' && collection !== 'connection' && collection !== 'company_configuration')){
                        if(group  !== 'lead') {

                            if (!permissionList[collection]) {
                                permissionList[collection] = [];
                            }

                            if (!permissionList[collection][group]) {
                                permissionList[collection][group] = [];
                            }
                            permissionList[collection][group].push(permission_type);
                        }
                    }
                }else{
                    if (!permissionList[collection]) {
                        permissionList[collection] = [];
                    }

                    if (!permissionList[collection][group]) {
                        permissionList[collection][group] = [];
                    }
                    permissionList[collection][group].push(permission_type);

                }




            }
        });

        sortOrder.forEach((p) => {
            if(menuList[p]){
                collections[p] = menuList[p];
            }
        });

        sortOrder.forEach((p) => {
            if(permissionList[p]){
                collectionsPermissions[p] = permissionList[p];
            }
        });


        setMenuList(collections);
        setPermissionList(permissionList);
    };

    const elaboratePermissionSpecific = (permissions) => {
        const menuList = [];
        const collections = [];

        permissions.forEach((p) => {
            const [collection, group, permission_type] = (p.name).split(".");

            if(groupDetail.connection_type){
                if((groupDetail.connection_type === 'importer' && collection !== 'connection' && collection !== 'catalog' && collection !== 'company_configuration') || (groupDetail.connection_type === 'exporter' && collection !== 'connection' && collection !== 'company_configuration')){
                    if(group  !== 'lead') {
                        if (!menuList[collection]) {
                            menuList[collection] = [];
                        }

                        if (!menuList[collection][group]) {
                            menuList[collection][group] = [];
                        }
                        menuList[collection][group].push(permission_type);
                    }
                }
            }else{
                if (!menuList[collection]) {
                    menuList[collection] = [];
                }

                if (!menuList[collection][group]) {
                    menuList[collection][group] = [];
                }
                menuList[collection][group].push(permission_type);

            }





        });

        sortOrder.forEach((p) => {
            if(menuList[p]){
                collections[p] = menuList[p];
            }
        });


        setSpecificList(collections);
    }


    useEffect(() => {

        if(permissionDetails && permissionDetails.company_group_permissions.length > 0){
            // eslint-disable-next-line
            permissionDetails.company_group_permissions.map(data => {
                if(data.permission_specific){
                    setValue(data.permission_specific.name, true);
                }
                if(data.permission){
                    setValue(data.permission.name, true);
                }

            })
        }
        // eslint-disable-next-line
    }, [permissionDetails]);




    useEffect(() => {

        async function fetchPermissions() {
            if (loggedCompany && isOpen) {


                setLoadingPermissions(true);
                try {
                    const permissionTypes = await getPermissionTypes();
                    const permissionSpecific = await getPermissionSpecific();
                    const permissionDetails = await getElement(getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, groupDetail.id]));

                    elaboratePermissions(permissionTypes);
                    elaboratePermissionSpecific(permissionSpecific);


                    setPermissions(permissionTypes);
                    setPermissionDetails(permissionDetails);
                    setPermissionSpecific(permissionSpecific);


                    setLoadingPermissions(false);
                } catch (e) {
                    setPermissions([]);
                } finally {
                    setLoadingPermissions(false);
                }
            }
        }
        //fetchShipping();
        fetchPermissions();


        // eslint-disable-next-line
    }, [loggedCompany, isOpen]);




    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, ps.COMPANY_CONF_PERMISSIONS)]} renderBaseOnFail={true} >

            <SlideFull
                title={t("app.permissions.title_slide")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size={'max-w-7xl'}
                header={Header}
                extras={{
                    isLoading: isLoadingLoad,
                    onClick: () => {
                        if(activePermissions.length > 0  || activePermissionSpecific.length > 0) {
                            createPermissions();
                        }
                    },
                    isActive: ( activePermissions.length > 0  || activePermissionSpecific.length > 0)

                }}
                setClean={() => {

                    if(permissionDetails && permissionDetails.company_group_permissions.length > 0){
                        // eslint-disable-next-line
                        permissionDetails.company_group_permissions.map(data => {
                            if(data.permission_specific){
                                setValue(data.permission_specific.name, null);
                            }
                            if(data.permission){
                                setValue(data.permission.name, null);
                            }

                        })
                    }

                    setActiveGroup('menu');
                    setPermissionDetails(false);
                    setMenuList([]);
                    setPermissionList([]);
                    setActivePermissions([]);
                    setSpecificList([]);
                    setActivePermissionSpecific([]);
                    onClose()
                }}
            >


                <div className="space-y-6 pt-6 pb-5">

                    <div className="max-w-7xl mx-auto">
                        <div className="pb-5 sm:pb-0">
                            <h3 className="text-base font-bold  text-blue-1000 text-3xl md:text-4xl md:mt-4">{groupDetail.group_name}</h3>
                            <p className="mt-1 flex items-center text-sm text-gray-500">{groupDetail.description}</p>
                            <div className={"mt-8"}>
                                <UnderlineTab
                                    marginTop={''}
                                    tabs={tabs}
                                    activeGroup={activeGroup}
                                    setActiveGroup={setActiveGroup}
                                />
                            </div>
                        </div>
                    </div>

                    <LoaderWrapper isLoading={isLoadingPermissions}>

                        {
                            activeGroup === 'menu' && (
                                Object.keys(menuList).sort((a, b) => {return a.localeCompare(b);}).map((collection) => (
                                    <div className="max-w-7xl mx-auto shadow bg-white rounded-md">
                                        <h3 className="text-xl font-bold leading-6 border-b border-gray-200 p-4 text-blue-1000">{t("app.permissions."+collection)}</h3>

                                        <ul className="divide-y divide-gray-100 pb-4">
                                            {menuList[collection].map((permission) => (
                                                <li key={collection+'.'+permission+'.menu'} className="  px-4 py-2">
                                                    <Controller
                                                        name={collection+'.'+permission+'.menu'}
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                            <InputToggle
                                                                label={t("app.permissions."+permission)}
                                                                onChange={(e) => {
                                                                    let permissionName = collection+'.'+permission+'.menu';
                                                                    if(activePermissions.find(r => r.permission === permissionName)){
                                                                        let filtered = [...activePermissions];
                                                                        let foundIndex = filtered.findIndex((x) => x.permission === permissionName);
                                                                        filtered[foundIndex]['active'] = e;
                                                                        setActivePermissions(filtered);
                                                                    }else{

                                                                        let permissionDetail = permissions.find(p => p.name === permissionName);
                                                                        if(permissionDetail){
                                                                            setActivePermissions([...activePermissions, {id: permissionDetail.id, permission: permissionName, active: e}]);
                                                                        }

                                                                    }
                                                                    onChange(e)
                                                                }}
                                                                defaultChecked={value}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))
                            )
                        }

                        {
                            activeGroup === 'general_permission' && (
                                Object.keys(permissionList).sort((a, b) => {return a.localeCompare(b);}).map((collection) => (
                                    <div className="max-w-7xl mx-auto shadow bg-white rounded-md">
                                        <h3 className="text-xl font-bold leading-6 border-b border-gray-200 p-4 text-blue-1000">{t("app.permissions."+collection)}</h3>


                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto">
                                                <div className="align-middle inline-block w-24 min-w-full mx-auto">
                                                    <table className="min-w-full divide-y divide-gray-200 scrollable">
                                                        <thead className={"bg-solitude-100"}>
                                                            <tr>

                                                                <th className="px-6 py-3 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider md:w-60" />

                                                                {[...typesList.filter(r => r !== 'menu')].sort((a, b) => {return a.localeCompare(b);}).map((type) => (
                                                                    <th
                                                                        key={type}
                                                                        className="px-6 py-3 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider"
                                                                    >{t("app.permissions."+type)}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                        {
                                                            Object.keys(permissionList[collection]).sort((a, b) => {return a.localeCompare(b);}).map((group) => (

                                                                <tr key={group}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 md:w-60">
                                                                        {t("app.permissions."+group)}
                                                                    </td>


                                                                    {[...typesList.filter(r => r !== 'menu')].sort((a, b) => {return a.localeCompare(b);}).map((type) => (
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" key={collection+'.'+group+'.'+type}>

                                                                            {
                                                                                permissionList[collection][group].find(r => r === type)  && (
                                                                                    <>
                                                                                        {
                                                                                            (group === 'product' && type ==='list') ? (
                                                                                                <div className={'flex items-center'}>


                                                                                                    <Controller
                                                                                                        name={collection+'.'+group+'.'+type}
                                                                                                        rules={{ required: false }}
                                                                                                        control={control}
                                                                                                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                                                                            <InputToggle
                                                                                                                showDetails={false}
                                                                                                                onChange={(e) => {
                                                                                                                    let permissionName = collection+'.'+group+'.'+type;
                                                                                                                    if(activePermissions.find(r => r.permission === permissionName)){
                                                                                                                        let filtered = [...activePermissions];
                                                                                                                        let foundIndex = filtered.findIndex((x) => x.permission === permissionName);
                                                                                                                        filtered[foundIndex]['active'] = e;
                                                                                                                        setActivePermissions(filtered);
                                                                                                                    }else{

                                                                                                                        let permissionDetail = permissions.find(p => p.name === permissionName);
                                                                                                                        if(permissionDetail){
                                                                                                                            setActivePermissions([...activePermissions, {id: permissionDetail.id, permission: permissionName, active: e}]);
                                                                                                                        }
                                                                                                                    }
                                                                                                                    onChange(e)
                                                                                                                }}
                                                                                                                defaultChecked={value}
                                                                                                                errors={error}
                                                                                                            />
                                                                                                        )}
                                                                                                    />

                                                                                                    {
                                                                                                        watch(collection+'.'+group+'.'+type) && (
                                                                                                            <FilterIcon className="w-4 h-4 ml-2 text-violet-200 hover:text-violet-100 cursor-pointer" aria-hidden="true" onClick={() => {
                                                                                                                let permissionName = collection+'.'+group+'.'+type;
                                                                                                                let permissionDetail = permissions.find(p => p.name === permissionName);
                                                                                                                setFilterType({name: permissionName, id: permissionDetail.id});
                                                                                                                setOpenFilter(true);
                                                                                                            }}/>
                                                                                                        )
                                                                                                    }



                                                                                                </div>
                                                                                            ) : (
                                                                                                <Controller
                                                                                                    name={collection+'.'+group+'.'+type}
                                                                                                    rules={{ required: false }}
                                                                                                    control={control}
                                                                                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                                                                        <InputToggle
                                                                                                            showDetails={false}
                                                                                                            onChange={(e) => {
                                                                                                                let permissionName = collection+'.'+group+'.'+type;
                                                                                                                if(activePermissions.find(r => r.permission === permissionName)){
                                                                                                                    let filtered = [...activePermissions];
                                                                                                                    let foundIndex = filtered.findIndex((x) => x.permission === permissionName);
                                                                                                                    filtered[foundIndex]['active'] = e;
                                                                                                                    setActivePermissions(filtered);
                                                                                                                }else{

                                                                                                                    let permissionDetail = permissions.find(p => p.name === permissionName);
                                                                                                                    if(permissionDetail){
                                                                                                                        setActivePermissions([...activePermissions, {id: permissionDetail.id, permission: permissionName, active: e}]);
                                                                                                                    }
                                                                                                                }
                                                                                                                onChange(e)
                                                                                                            }}
                                                                                                            defaultChecked={value}
                                                                                                            errors={error}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        }


                                                                                    </>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    ))}






                                                                </tr>

                                                            ))
                                                        }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        }


                        {
                            activeGroup === 'specific_permission' && (
                                Object.keys(specificList).sort((a, b) => {return a.localeCompare(b);}).map((collection) => (
                                    <div className="max-w-7xl mx-auto shadow bg-white rounded-md">
                                        <h3 className="text-xl font-bold leading-6 border-b border-gray-200 p-4 text-blue-1000">{t("app.permissions."+collection)}</h3>


                                        <nav className="h-80 overflow-y-auto" aria-label="Directory">
                                            {Object.keys(specificList[collection]).sort((a, b) => {return a.localeCompare(b);}).map((group) => (
                                                <div key={group} className="relative">
                                                    <div className="sticky top-0 z-10  shadow bg-solitude-100  px-4 py-1.5  leading-6 text-xs font-medium text-blue-1000 uppercase">
                                                        {t("app.permissions."+group)}
                                                    </div>
                                                    <ul className="divide-y divide-gray-100">


                                                        {specificList[collection][group].map((type) => (
                                                            <li key={collection+'.'+group+'.'+type} className=" px-3 py-5">
                                                                <Controller
                                                                    name={collection+'.'+group+'.'+type}
                                                                    rules={{ required: false }}
                                                                    control={control}
                                                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                                        <InputToggle
                                                                            label={t("app.permissions."+group+"_"+type)}
                                                                            description={t("app.permissions."+group+"_"+type+"_description")}
                                                                            onChange={(e) => {
                                                                                let permissionName = collection+'.'+group+'.'+type;
                                                                                if(activePermissionSpecific.find(r => r.id === permissionName)){
                                                                                    let filtered = [...activePermissionSpecific];
                                                                                    let foundIndex = filtered.findIndex((x) => x.permission === permissionName);
                                                                                    filtered[foundIndex]['active'] = e;
                                                                                    setActivePermissionSpecific(filtered);
                                                                                }else{
                                                                                    let permissionDetail = permissionSpecific.find(p => p.name === permissionName);
                                                                                    if(permissionDetail){
                                                                                        setActivePermissionSpecific([...activePermissionSpecific, {id: permissionDetail.id, permission: permissionName, active: e}]);
                                                                                    }
                                                                                }
                                                                                onChange(e)
                                                                            }}
                                                                            defaultChecked={value}
                                                                            errors={error}
                                                                        />
                                                                    )}
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </nav>
                                    </div>
                                ))
                            )
                        }


                    </LoaderWrapper>










                </div>
            </SlideFull>


            <PermissionFilter
                isOpen={isOpenFilter}
                groupDetail={groupDetail}
                setIsOpen={setOpenFilter}
                filterType={filterType}
            />




            {
                isOpeRequired && (
                    <PermissionRequiredPopup
                        isOpen={isOpeRequired}
                        setIsOpen={setOpenRequired}
                        details={requiredLog}
                    />
                )
            }


        </BasePermissionWrapper>
    );
};

const Header = ({extras}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="text-center">
                <PrimaryButton
                    isLoading={extras.isLoading}
                    disabled={!extras.isActive}
                    label={t("app.permissions.save_permissions")}
                    onClick={() => {
                        extras.onClick();
                    }}
                />
            </div>

        </>

    )
};


export default CompanyPermission;
