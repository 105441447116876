import React, {useState} from 'react';
import ListSection from "../layout/list-section";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import OptionalLink from "../common/table/optional-link";
import {getWebPathParam} from "../../utils/converter";
import {LOGISTICS, SHIPPING, SHIPPING_DETAIL, SHIPPING_OVERVIEW} from "../../routes/endpoints";
import CustomerCell from "../common/table/customer-cell";
import CardDetail from "../common/list/card-detail";
import {getCustomFormat, getFormattedDate} from "../../utils/timeUtils";
import TableDetail from "../common/list/table-detail";
import {Link} from "react-router-dom";
import ActionsMenu from "../common/table/actions-menu";
import {hasPermission} from "../partials/restricted/permission-wrapper";
import {pg, pt} from "../../constants/permissions";
import {LAND} from "../../constants/config";
import {TruckIcon} from "@heroicons/react/outline";
import ShippingIcon from "../../resources/icons/ShippingIcon";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function OpenedShipping({data, height, showType}) {

  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});
  const { t } = useTranslation();
  const { isExporter } = useSelector((state) => state.user)

  const columns = [
      {
        header:  isExporter || isExporter === null ? t("dashboard.active_shipping.customer") : t("dashboard.active_shipping.supplier"),
        id: 'business_name',
        accessor: r => r.customer.business_name,
      },
      {
          header: t("app.container.load_date"),
          id: 'load_date',
          accessor: r => r.load_date ? new Date(r.load_date.fist_load_date).getTime() : '-',
      },
      {
        header: t("dashboard.active_shipping.departure_date"),
        id: 'departure_date',
        accessor: r => new Date(r.departure_date).getTime()
      },
      {
        header: t("dashboard.active_shipping.arrival_date"),
        id: 'arrival_date',
        accessor: r => new Date(r.arrival_date).getTime()
      },
      {
        header: t("dashboard.active_shipping.document"),
        id: 'document',
        accessor:  r =>  (r.document_counter.uploaded / r.document_counter.total)
      },
      {
        header: t("dashboard.active_shipping.event"),
        id: 'event',
        accessor:  r => r.last_event.event_type.name
      },
      {
        header: "",
        accessor: null
      }
  ];

  return (
      <div style={{height: height+'px'}} className="overflow-y-scroll">
          <ListSection
              columns={columns}
              totalRows={data?.shippings?.filter(r => r.confirmed_containers === true )?.length ?? 0}
              list={data?.shippings?.filter(r => r.confirmed_containers === true ) ?? []}
              showHeader={false}
              isLoading={false}
              mobileRow={ShippingMobRow}
              desktopRow={ShippingDeskRow}
              sortBy={sortBy}
              isTableSort={true}
              showType={showType}
              onSortBy={(n) => {
                  setSortBy(n);
              }}
          />
      </div>

  );
}

const ShippingMobRow = ({ element }) => {
  const { t } = useTranslation();
    const { permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((s) => s.user);

    const isLinked = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.EDIT,
        element.customer.id,
        companyType,
        true
    );
    const canView = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.VIEW,
        element.customer.id,
    );
  return (
      <li key={element.id} className="flex items-center gap-4 p-4">
        <OptionalLink
            isLinked={isLinked}
            to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
            className="flex-grow"
        >
          <div className="pb-4 items-center flex justify-between">
            <CustomerCell customer={element.customer} />
              <ActionsMenu>
                  {isLinked && (
                      <Link
                              to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
                      >
                          {t("app.common.edit")}
                      </Link>
                  )}
                  {canView && (
                      <Link
                              to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_OVERVIEW])}
                      >
                          {t("app.common.overview")}
                      </Link>
                  )}
              </ActionsMenu>
          </div>

          <div className="flex flex-col">



              <CardDetail title={t("app.container.load_date")}>
                  {
                      element.load_date ? (
                          <>
                              {getCustomFormat(element.load_date.fist_load_date, 'DD/MM/YYYY HH:mm')}
                          </>
                      ) : (
                          <>{t("app.container.container_not_defined")}</>
                      )
                  }
              </CardDetail>

            <CardDetail title={t("dashboard.active_shipping.departure_date")}>
               {getFormattedDate(element.departure_date)}
            </CardDetail>

              <CardDetail title={t("dashboard.active_shipping.arrival_date")}>
               {getFormattedDate(element.arrival_date)}
            </CardDetail>

            <CardDetail title={ t("dashboard.active_shipping.document")}>
                {element.document_counter.uploaded} / {element.document_counter.total}
            </CardDetail>

            <CardDetail title={t("dashboard.active_shipping.event")}>
                {element.last_event.event_type.name}
            </CardDetail>



          </div>
        </OptionalLink>
      </li>
  );
};

const ShippingDeskRow = ({ element }) => {
  const percentage =  (element.document_counter.uploaded * (100 / element.document_counter.total));
    const { permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((s) => s.user);

    const isLinked = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.EDIT,
        element.customer.id,
        companyType,
        true
    );
    const canView = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.VIEW,
        element.customer.id,
    );
    const { t } = useTranslation();

  return (
      <tr key={element.id}>
        <TableDetail extraClass="max-w-sm">
          <div className="flex items-center space-x-3 lg:pl-2">
            <OptionalLink
                isLinked={false}
                      to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
                className="truncate hover:text-gray-600"
            >
              <CustomerCell customer={element.customer} />
            </OptionalLink>
          </div>
        </TableDetail>

        <TableDetail>

            <div className={"flex items-center"}>

                <div className="mr-4">
                    {
                        element.shipping_type.code === LAND ? (
                            <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                        ) : (
                            <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                        )
                    }
                </div>


                <div>
                    {
                        element.load_date ? (
                            <>
                                <div className="text-sm text-gray-900">
                                    {getCustomFormat(element.load_date.fist_load_date, 'DD/MM/YYYY HH:mm')}
                                </div>
                            </>
                        ) : (
                            <>{t("app.container.container_not_defined")}</>
                        )
                    }
                </div>

            </div>



        </TableDetail>

        <TableDetail>
          {getFormattedDate(element.departure_date)}
        </TableDetail>


        <TableDetail>
          {getFormattedDate(element.arrival_date)}
        </TableDetail>

        <TableDetail>

            <div className="w-full bg-gray-200 rounded">
                <div className={classNames(
                    percentage >= 50 ? 'shim-green' : '',
                    percentage < 50  ? 'shim-red' : '',
                    "text-xs font-medium rounded text-white text-center p-2 leading-none"
                )} style={{width: percentage+'%'}}> {element.document_counter.uploaded} / {element.document_counter.total}
                </div>
            </div>
        </TableDetail>

        <TableDetail>
            <div className="text-left">
                <div className="text-sm text-gray-900">
                    {element.last_event.event_type.name}
                </div>
                <div className="text-xs text-gray-500">
                    {getFormattedDate(element.last_event.event_date)}
                </div>
            </div>
        </TableDetail>

          <TableDetail>
              <ActionsMenu>
                  {isLinked && (
                      <Link to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}>
                          {t("app.common.edit")}
                      </Link>
                  )}
                  {canView && (
                      <Link
                          to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_OVERVIEW])}
                      >
                          {t("app.common.overview")}
                      </Link>
                  )}
              </ActionsMenu>
          </TableDetail>
      </tr>
  );
};


export default OpenedShipping;
