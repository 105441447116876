import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { BOOKING, COMPANY, FULFILLMENT } from "../../endpoints";
import {  getPathParam } from "../../../utils/converter";
import { getPermission, permissionGroup, permissionType } from "../../../constants/permissions";
import {useTranslation} from "react-i18next";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import { AIR, LAND, SEA } from "../../../constants/config";
import { deleteElement, getElement, postElement } from "../../../api/config";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import { ShippingStatusList } from "../../../components/common/alert/shipping-status-list";
import toast from "react-hot-toast";
import BoxUploadMultifile from "../../../components/partials/upload/box-upload-multifile";
import AirIcon from "../../../resources/icons/AirIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import TruckIcon from "../../../resources/icons/TruckIcon";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import BookingSlideLeft from "../../../components/logistic/booking-slide-left";
import { NegotiationCard } from "./booking-component/negotiation";
import { FulfillmentCard } from "./booking-component/fulfillment";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const NEGOTIATION_TAB = 'negotiation';
const FULFILLMENT_TAB = 'fulfillment';
const ATTACHMENT_TAB = 'attachment';


export default function ManageBooking({ isOpen, setIsOpen, manageDetails, onRefresh }) {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(false);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (manageDetails && isOpen && loggedCompany) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, BOOKING, manageDetails.id]), signal);
                    setResponseData(res);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, manageDetails, loggedCompany]);



  

    const tabs = [
        { name: t("app.booking.forworder_negotiation"), group: NEGOTIATION_TAB },
        { name: (responseData && responseData.type === 'delivery') ? t("app.booking.fulfillment_delivery") : t("app.booking.fulfillment_pickup"), group: FULFILLMENT_TAB },
        { name: t("app.booking.attachments"), group: ATTACHMENT_TAB },
    ];
    const [activeGroup, setActiveGroup] = useState(NEGOTIATION_TAB);


    return (
        <BookingSlideLeft
            title={t("app.booking.booking_manage")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setClean={() => {
                
            }}
        >
            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.EDIT)]}>
                <div className={classNames(" mb-6 bg-white ")}>

                <LoaderWrapper isLoading={isLoading}>
                        <div className="px-4 sm:px-6  ">

                            {/* TITLE */}
                            <div className=" mt-1 pt-1.5 ">
                                <div className="flex items-center justify-between">

                                    <div className="flex items-center">
                                    

                                        <div className="mr-3">
                                            {
                                                (responseData.shipping_type && (responseData.shipping_type.code === LAND)) && (
                                                    <TruckIcon className={'w-8 h-8 text-blue-1000'} />
                                                )
                                            }

                                            {
                                                (responseData.shipping_type && (responseData.shipping_type.code === SEA)) && (
                                                    <ShippingIcon className={'w-8 h-8 text-blue-1000'} />
                                                )
                                            }
                                            {
                                                (responseData.shipping_type && (responseData.shipping_type.code === AIR)) && (
                                                    <AirIcon className={'w-8 h-8 text-blue-1000'} />
                                                )
                                            }
                                        </div>
                                        <div className="text-left">
                                            <div className="text-2xl -mb-1 font-bold whitespace-pre-line text-blue-1000">
                                                {(responseData.type === 'delivery' ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup")) + " #" + responseData.reference}
                                            </div>
                                        </div>
                                    </div>


                                    <ShippingStatusList
                                        isLoading={false}
                                        status={'draft'}
                                        isEditable={true}
                                        onChangeStatus={(r) => {
                                           
                                        }}
                                    />
                                
                                </div>
                            </div>

                            <div className={classNames(" relative")}>
                                

                                <UnderlineTab
                                    tabs={tabs}
                                    activeGroup={activeGroup}
                                    setActiveGroup={setActiveGroup}
                                />



                                {/* COMBINATIONS */}
                                {
                                    activeGroup === NEGOTIATION_TAB && (
                                        <NegotiationCard
                                            canEdit={true}
                                            responseData={responseData}
                                            onRefresh={() => {
                                            }}
                                        />
                                    )
                                }


                                {/* COMBINATIONS */}
                                {
                                    activeGroup === FULFILLMENT_TAB && (
                                        <FulfillmentCard
                                            canEdit={true}
                                            responseData={responseData}
                                            onRefresh={() => {
                                            }}
                                        />
                                    )
                                }



                                {/* ATTACHMENTS */}
                                {
                                    activeGroup === ATTACHMENT_TAB && (
                                        <AttachmentsCard
                                            canEdit={true}
                                            responseData={responseData}
                                            onRefresh={() => {
                                            }}
                                        />
                                    )
                                }



                               


                                


                            </div>
                        </div>


               

                </LoaderWrapper>
                </div>
            </BasePermissionWrapper>
        </BookingSlideLeft>
    );
}


// ATTACHMENTS
const AttachmentsCard = ({ responseData, onRefresh, canEdit }) => {

    const [selectedAttachments, setSelectedAttachments] = useState(responseData?.fulfillment_attachments ?? []);
    const [isLoading, setLoading] = useState(false);


    const { t } = useTranslation();


    const onSubmit = async (data) => {
        postElement(getPathParam([FULFILLMENT, responseData.id, BOOKING]), data)
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"));
                onRefresh(true);
            }).catch(e => {
                //toast.error(e.message);
                setLoading(false);
            });

    };

    const onRemove = async (id) => {
        deleteElement(getPathParam([FULFILLMENT, responseData.id, BOOKING, id]))
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"))
                onRefresh(true);
            }).catch(e => {
                toast.error(e.message);
            });

    };
    const onUpload = async (data) => {
        setLoading(true);
        data.map(async r => {
            await setTimeout(async function () {
                await onSubmit({
                    file_name: r.name,
                    file: {
                        media_url: r.reference
                    }
                })
            }, 500);
        })
        setLoading(false);
    };


    return (
        <>
            <div className="mt-6 ">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.board.attachments")}</h1>


                <BoxUploadMultifile
                    onUpload={async (data) => {
                        await onUpload(data);
                    }}
                    onException={(e) => { }}
                    isLoading={isLoading}
                    canEdit={canEdit}
                    isDepend={true}
                    genericDocuments={selectedAttachments}
                    onRemoveGeneric={(id) => {
                        setSelectedAttachments(selectedAttachments.filter(function (obj) {
                            return obj.id !== id;
                        }));
                        onRemove(id);
                    }}
                />
            </div>
        </>
    )
}



