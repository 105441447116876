import {useTranslation} from "react-i18next";
import React from "react";
import ReactQuill from 'react-quill'; // ES6
import Quill from "quill";
import ImageResize from "quill-image-resize";

import 'react-quill/dist/quill.snow.css';


export default function InputEmailEditor({ label, isRequired, isDisabled = false,  id, errors, warning, warningRegex, defaultValue, onChange, className = '', imageEdit = false, ref = null}){

    const { t } = useTranslation();
    if(imageEdit){
        Quill.register('modules/imageResize', ImageResize);
    }

    const editorWithImg = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }]
                [{ size: ["small", false, "large", "huge"] }],
            ["image"],
            [{ color: [] }, { background: [] }],
            ["clean"]
        ],
        clipboard: {
            matchVisual: false
        },
        imageResize: {}
    };

    const editorWithoutImg = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }]
                [{ size: ["small", false, "large", "huge"] }],
            [{ color: [] }, { background: [] }],
            ["clean"]
        ],
        clipboard: {
            matchVisual: false
        }
    };

    return (
        <div>
            {
                label && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }
          
            <ReactQuill
                theme="snow"
                value={defaultValue ? defaultValue : '<p></p>'}
                modules={imageEdit ? editorWithImg : editorWithoutImg}
                className={className}
                readOnly={isDisabled}
                bounds={'.app'}
                onChange={(content) => {
                    onChange(content);
                }}
            />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}

        </div>

    )
};

