import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import SidebarLink from "./sidebar-link";
import { useSelector } from "react-redux";
import {
  getMenu,
  sidebarElements,
} from "../../../constants/menu-configuration";
import { useTranslation } from "react-i18next";

const SidebarMenu = () => {
  const {
    userCompany: { permissions, subscriptions },
    user: { companyType, isExporter },
  } = useSelector((s) => s);
  const { pathname } = useLocation();
  const { t } = useTranslation();


  const [open, setOpen] = useState(false);

  const navigationPermissions = permissions?.navigation ?? {};
  const menu = getMenu(companyType);

  const checkMenu = (item) => {
    if(item?.submenu ?? false){
      let list = Object.entries(item.submenu).filter(([k, _]) => k in (permissions?.navigation ?? {}));
      if(list.length > 0){

        let show = false;
        // eslint-disable-next-line
        list.map(([_, subItem]) => {
          if((((!isExporter && subItem.hideForImporter === false) || isExporter) && match(subItem))){
            show = true;
          }
        })

        return show;
      }
    }
    return true;
  }

  const match = (item) => {
    if(item.subscriptionGroup.length === 0){
      return true;
    }

    return (subscriptions ?? []).filter(subscription => {
      return item.subscriptionGroup.find((group) => {
        return subscription === group
      });
    }).length > 0
  }


  const hasPermissionForElement = (e) => e in navigationPermissions ||
      Object.keys(sidebarElements[e]?.submenu ?? {}).some(
          (se) => se in navigationPermissions
      );

  return Object.entries(menu).map(([title, elements]) => {
    const allowedElements = elements.filter(hasPermissionForElement).filter(r => (checkMenu(sidebarElements[r]) && match(sidebarElements[r])));

    if (allowedElements.length === 0) return null;

    return (
      <div key={title || "untitled_section"}>
        {(title && title !== "ND" )&& (
          <h3 className="text-xs uppercase text-white/[0.4] tracking-widest mt-7 font-normal pl-3">
            <span className="lg:sidebar-expanded:block 2xl:block">
              {t(`app.menu.${title}`)}
            </span>
          </h3>
        )}

        <ul className="mt-3 space-y-1">
          {allowedElements.map((k) => (
            <SidebarLink
              key={k}
              item={sidebarElements[k]}
              open={open}
              onOpenMenu={setOpen}
              currentPath={pathname}
            />
          ))}
        </ul>
      </div>
    );
  });
};

export default SidebarMenu;
