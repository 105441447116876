import {COMPANY, COMPANIES, EMAIL_VERIFY, EMAIL, SEND_EMAIL, USERS, EMAIL_LIST} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// company
export const getCompanies = ({params, signal}) =>
    API.get(getPathParam([COMPANIES] ) + serialize(params), {signal: signal});

export const getCompany = ({company_id, params = null}) =>
    API.get(getPathParam([COMPANY, company_id ] ) + (params ? serialize(params) : ''));

export const postCompany = ({data}) =>
    API.post(getPathParam([COMPANY]), data);

export const putCompany = ({company_id, data}) =>
    API.put(getPathParam([COMPANY, company_id]), data);

export const putCompanyEmailProviderVerify = ({company_id, type, data}) =>
    API.put(getPathParam([COMPANY, company_id, EMAIL_VERIFY, type]), data);

export const getCompanyMailList = ({company_id, params = null}) =>
    API.get(getPathParam([COMPANY, company_id, EMAIL_LIST ] ) + (params ? serialize(params) : ''));

export const getCompanyMails = ({company_id, params = null}) =>
    API.get(getPathParam([COMPANY, company_id, EMAIL ] ) + (params ? serialize(params) : ''));

export const getSendCompanyMail = ({company_id, email_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, EMAIL, email_id, SEND_EMAIL ]), data);


export const getCompanyUsers = ({ company_id, params, signal }) =>
    API.get( getPathParam([COMPANY, company_id, USERS]) + serialize(params),
        signal && { signal: signal }
    );
