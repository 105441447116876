import { useTranslation } from "react-i18next";
import React from "react";

export default function InputText({
                                    type,
                                    label,
                                    isShowLabel = true,
                                    isShowError = true,
                                    isRequired,
                                    id,
                                    errors,
                                    warning,
                                    warningRegex,
                                    input,
                                    defaultValue,
                                    className = "",
                                    extraClassName = "",
                                    isDisabled = false,
                                    placeholder = ''
                                  }) {
  const { t } = useTranslation();
                                    
    const autoGrow = (ease) => {
        ease.target.style.height = (ease.target.scrollHeight) + "px";

    };

  return (
      <div className={className}>
          {
              isShowLabel && (
                  <label
                      htmlFor={id}
                      className="block text-sm font-medium mb-1 text-gray-700"
                  >
                      {label} {isRequired && <span className="text-red-500">*</span>}
                  </label>
              )
          }

        {type === "textarea" ? (
            <textarea
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`form-input w-full disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={t("app.common.placeholder", {
                  field: label.toLowerCase(),
                })}
                {...input}
            />
          ) : type === "textarea-2" ? (
              <textarea
                      style={{
                          resize: 'none',
                          overflowY: 'scroll',
                          minHeight: '38px',
                          maxHeight: '200px',
                      }}
                      id={id}
                      defaultValue={defaultValue}
                      onInput={autoGrow}
                      onFocusCapture={autoGrow}
                      disabled={isDisabled}
                      className={`form-input  w-full h-9 overflow-hidden break-words overflow-y-auto resize-none m-0 px-3 py-2  border-[none] disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          : ""
                          }`}
                      placeholder={t("app.common.placeholder", {
                          field: label.toLowerCase(),
                      })}
                      {...input}
                />
              ) : type === "textarea-3" ? (
                  <textarea
                      style={{
                          resize: 'none',
                          overflow: 'hidden',
                          minHeight: '60px',
                          maxHeight: '200px',
                      }}
                      id={id}
                      defaultValue={defaultValue}
                      onInput={autoGrow}
                      onFocusCapture={autoGrow}
                      disabled={isDisabled}
                          className={` ${extraClassName}  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          : ""
                          }`}
                      placeholder={t("app.common.placeholder", {
                          field: label.toLowerCase(),
                      })}
                      {...input}
                  />
              ) : (
            <input
                type={type}
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`form-input w-full  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={label ? t("app.common.placeholder", {
                  field: label.toLowerCase(),
                }) : t("app.common.placeholder", {
                  field: placeholder.toLowerCase(),
                })}
                {...input}
            />
        )}

          {
              isShowError && (
                  <>
                      {errors && errors.type === "required" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning ? warning : t("app.warning.description", { field: label })}
                          </p>
                      )}
                      {errors && errors.type === "validate" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning ? warning : (errors.message ? errors.message : t("app.warning.description", { field: label }))}
                          </p>
                      )}
                      {errors && errors.type === "pattern" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warningRegex
                                  ? warningRegex
                                  : t("app.warning.description_regex", { field: label })}
                          </p>
                      )}
                  </>
              )
          }


      </div>
  );
}
