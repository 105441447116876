import {
    COMPANY, LEADS, LEAD
} from "./endpoints";
import API from "../utils/apiUtils";
import { getPathParam, serialize } from "../utils/converter";

// detail
export const getLeads = ({ company_id, params, data, signal }) =>
    API.post(
        getPathParam([COMPANY, company_id, LEADS]) +
        (params ? serialize(params) : ""),
        data, { signal: signal }
    );


export const postLeads = ({ company_id, data }) =>
    API.post(getPathParam([COMPANY, company_id, LEAD]), data);

export const getLead = ({company_id, lead_id}) =>
    API.get(getPathParam([COMPANY, company_id, LEAD, lead_id ]));
