import React, {useEffect, useMemo, useState} from "react";
import SlidePanel from "../../../components/layout/slide-panel";
import {useSelector} from "react-redux";
import {getImports} from "../../../api/import";
import ListSection from "../../../components/layout/list-section";
import {filterByPermission} from "../../../components/partials/restricted/permission-wrapper";
import {getPermission, pg, pt} from "../../../constants/permissions";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import {StatusBar} from "../../../components/common/alert/status-bar";
import {getCustomFormat} from "../../../utils/timeUtils";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

export default function ImportLog({ isOpen, setIsOpen, type }) {


    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);
    const { t } = useTranslation();

    // Load Process
    const [isLoading ,setLoading] = useState(false);


    const [detail , setDetail] = useState({ imports: [], total_rows: 0 });

    const [offset, setOffset] = useState(0);
    const [query, setQuery] = useState("");


    const columns = [
        t("app.import_log.file_name"),
        t("app.import_log.status"),
        t("app.import_log.imported"),
        t("app.import_log.not_imported"),
        t("app.import_log.removed"),
        t("app.import_log.date")
    ];

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchImports() {
            if (loggedCompany && isOpen) {
                setLoading(true);
                getImports({
                    company_id: loggedCompany.id,
                    params: { limit: 15, offset: offset, type: type },
                    signal,
                })
                    .then((response) => {
                        setDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setDetail({ imports: [], total_rows: 0 });
                        setLoading(false);
                    });
            }
        }

        fetchImports();
        return () => {
            controller.abort();
        };
    }, [offset, query, loggedCompany, type, isOpen]);


    const allowedImports = useMemo(
        () =>
            (detail.imports ?? []).filter(
                filterByPermission(getPermission(pg.ORDER, pt.LIST), permissions)
            ),
        [permissions, detail.imports]
    );

    return (
        <SlidePanel
            title={t("app.import_log.import_title")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            size={'max-w-4xl'}
            bg={"bg-white"}
            setClean={() => {
            }}
        >

            <div className="space-y-6 pt-6 pb-5">

                <div className="border">
                    <ListSection
                        title={"Imports"}
                        showHeader={false}
                        columns={columns}
                        totalRows={detail.total_rows}
                        list={allowedImports}
                        onSearch={setQuery}
                        isLoading={isLoading}
                        paginationOnChange={setOffset}
                        mobileRow={OrderMobRow}
                        desktopRow={OrderDeskRow}
                    />
                </div>

            </div>

        </SlidePanel>
    );
}


const OrderMobRow = ({ element }) => {

    const { t } = useTranslation();


    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.file_name}
                </div>

                <div className="flex flex-col">
                    <CardDetail title={t("app.import_log.status")}>
                        <StatusBar
                            status={element.status === 'done' ? "success" : "processing"}
                            title={element.status === 'done' ? t("app.import_log.import_success") : t("app.import_log.import_processing")}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.import_log.imported")}>
                        {element.total_processed}
                    </CardDetail>

                    <CardDetail title={t("app.import_log.not_imported")}>
                        {element.total_error}
                    </CardDetail>

                    <CardDetail title={t("app.import_log.removed")}>
                        {element.total_removed}
                    </CardDetail>

                    <CardDetail title={t("app.import_log.date")}>
                        {getCustomFormat(element.upload_date, 'DD/MM/YYYY HH:mm')}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const OrderDeskRow = ({ element }) => {

    const { t } = useTranslation();
    const [isActive , setActive] = useState(false);
    return (
        <>
            <tr key={element.id}>
                <TableDetail>
                    {element.file_name}
                </TableDetail>
                <TableDetail>
                    <StatusBar
                        status={element.status === 'done' ? "success" : "processing"}
                        title={element.status === 'done' ? t("app.import_log.import_success") : t("app.import_log.import_processing")}
                    />
                </TableDetail>

                <TableDetail>
                    {element.total_processed}
                </TableDetail>

                <TableDetail>
                    <p className="text-indigo-600 underline cursor-pointer">
                        <span onClick={() => {setActive(!isActive)}}>{element.total_error}</span>
                    </p>
                </TableDetail>

                <TableDetail>
                    {element.total_removed}
                </TableDetail>

                <TableDetail>
                    {getCustomFormat(element.upload_date, 'DD/MM/YYYY HH:mm')}
                </TableDetail>
            </tr>


            {
                (element.import_process_errors.length > 0 && isActive) &&  (
                    <tr>
                        <td colSpan="6" className="px-6 py-3 bg-gray-100">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                        {t("app.import_log.company_reference")}
                                    </th>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                        {t("app.import_log.product_reference")}
                                    </th>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                        {t("app.import_log.quantity")}
                                    </th>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                        {t("app.import_log.stock")}
                                    </th>
                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                                        {t("app.import_log.base_price")}

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {element.import_process_errors.map((item) => (
                                    <tr key={item.id} className="border-b border-gray-200">

                                        <td className={
                                            item?.detail?.is_missing_connection ? "py-2 px-2 text-sm bg-red-700 text-white" :
                                                "py-2 px-2 text-sm text-gray-900"}>
                                            <div className="text-left">
                                                <div className="text-sm">
                                                    {item.detail.company_reference}
                                                </div>
                                                {
                                                    item.detail.is_missing_connection && (
                                                        <div className="text-xs flex-wrap text-white">
                                                            {t("app.import_log.missing")}
                                                        </div>
                                                    )
                                                }

                                            </div>

                                        </td>
                                        <td className={
                                            item?.detail?.is_missing_product ? "py-2 px-2 text-sm bg-red-700 text-white" :
                                                "py-2 px-2 text-sm text-gray-900"}>
                                            <div className="text-left">
                                                <div className="text-sm">
                                                    {item?.detail?.product_reference ?? '-'}
                                                </div>
                                                {
                                                    item.detail.is_missing_product && (
                                                        <div className="text-xs flex-wrap text-white">
                                                            {t("app.import_log.missing")}
                                                        </div>
                                                    )
                                                }

                                            </div>

                                        </td>



                                        <td className="py-2 px-2 text-sm text-gray-900">
                                            <NumberFormat
                                                value={item?.detail?.quantity ?? 0}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                            />
                                        </td>
                                        <td className="py-2 px-2 text-sm text-gray-900">
                                            <NumberFormat
                                                value={item?.detail?.stock ?? 0}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                            />
                                        </td>
                                        <td className="py-2 px-2 text-sm text-gray-900">
                                            <NumberFormat
                                                value={item?.detail?.base_price ?? 0}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalScale={2}
                                                decimalSeparator={","}
                                                prefix={"€ "}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )
            }


        </>
    );
};

