import {COMPANY, CATEGORIES, CATEGORY, SUB_CATEGORIES} from "./endpoints";
import API from "../utils/apiUtils";
import { getPathParam, serialize } from "../utils/converter";

// category
export const getCategories = ({ company_id, params, signal }) =>
  API.get(
    getPathParam([COMPANY, company_id, CATEGORIES]) +
      (params ? serialize(params) : ""),
    { signal: signal }
  );

export const getSubCategories = ({ category_id, params }) =>
    API.get(
        getPathParam([ CATEGORY, category_id, SUB_CATEGORIES]) +
        (params ? serialize(params) : "")
    );


export const getCategory = ({ company_id, category_id, signal }) =>
  API.get(getPathParam([COMPANY, company_id, CATEGORY, category_id]), {
    signal: signal,
  });

export const postCategory = ({ company_id, data }) =>
  API.post(getPathParam([COMPANY, company_id, CATEGORY]), data);

export const putCategory = ({ company_id, category_id, data }) =>
  API.put(getPathParam([COMPANY, company_id, CATEGORY, category_id]), data);
