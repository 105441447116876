import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const AnimatedPing = ({color, extraClass, style = {}}) => {



    let fist_color = 'bg-yellow-400';
    let second_color = 'bg-yellow-500';
    if(color === 'red'){
        fist_color = 'bg-red-400';
        second_color = 'bg-red-500';
    }
    if(color === 'green'){
        fist_color = 'bg-green-400';
        second_color = 'bg-green-500';
    }


    return (
        <span className={classNames("flex absolute h-3 w-3", extraClass)} style={style}>
          <span className={classNames(
              "animate-ping absolute inline-flex h-full w-full rounded-full  opacity-75",
              fist_color
          )} />
           <span className={classNames(
               "relative inline-flex rounded-full h-3 w-3",
               second_color
           )} />
        </span>
    )
};
export default AnimatedPing;
