import React, {useEffect, useState} from 'react'
import FormPopup from "../../../components/common/popup/form-popup";
import {useTranslation} from "react-i18next";
import {Warning} from "../../common/alert/banner";
import ButtonSubmit from "../../common/form/button-submit";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import {getSubscriptionDowngradeCheck, putSubscriptionChange} from "../../../api/subscription";
import {loadStripe} from "@stripe/stripe-js";
import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import {getPathParam, getWebPathParam, numberFormat} from "../../../utils/converter";
import {ACCOUNT, SUBSCRIPTION_COMPLATE} from "../../../routes/endpoints";
import {useNavigate} from "react-router-dom";
import InputSubmit from "../../common/form/input-submit";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function PaymentPopup({isOpen, setIsOpen, detail, changeDetailData = false }) {

    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [isActivePayment, setActivePayment] = useState(changeDetailData !== false);

    let navigate = useNavigate();

    const [changeDetail, setChangeDetail] = useState(changeDetailData);
    const [isSubscriptionCheckLoading, setSubscriptionCheckLoading] = useState(false);
    const [isValidChange, setValidChange] = useState(false);

    const confirm = async () => {
        setLoading(true);
        putSubscriptionChange({subscription_id: detail.subscription_id})
        .then((response) => {

            if(response.clientSecret){
                setActivePayment(true);
                setChangeDetail(response);
                setLoading(false);
            }

            if(response.offline_secret){
                navigate(getWebPathParam([ACCOUNT, SUBSCRIPTION_COMPLATE]) + '?offline_secret='+response.offline_secret)
            }

        })
        .catch((e) => {
            setException(e.message);
            setLoading(false);
        });
    };

    useEffect(() => {

        async function fetchSubscriptionCheck() {
            setSubscriptionCheckLoading(true);
            try {
                const res = await getSubscriptionDowngradeCheck({subscription_id: detail.subscription_id});
                if(res.status === 'OK'){
                    setValidChange(true);
                }else{
                    setValidChange(false);
                }
                setSubscriptionCheckLoading(false);
            } catch (e) {
                setValidChange(false);
                setSubscriptionCheckLoading(false);
            } finally {
                setSubscriptionCheckLoading(false);
            }
        }

        fetchSubscriptionCheck();
    }, [detail]);







    return (

        <FormPopup title={detail.title} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
            <LoaderWrapper isLoading={isSubscriptionCheckLoading} >

                {
                    isActivePayment ? (
                        <LoaderWrapper isLoading={isLoading}>
                            <Elements
                                options={{
                                    clientSecret: changeDetail.clientSecret,
                                    appearance: {theme: 'stripe'}
                                }}
                                stripe={stripePromise}>

                                <CheckoutForm changeDetail={changeDetail} />

                            </Elements>

                        </LoaderWrapper>
                    ) : (

                        isValidChange ? (
                            <>
                                <div className="mt-2">

                                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{__html: detail?.description ?? ''}} />

                                    {/* Warning */}
                                    {exception && <Warning message={exception} />}
                                </div>

                                <div className="p-4 flex before:flex-1 items-center justify-between">
                                    <ButtonSubmit
                                        isLoading={isLoading}
                                        label={t("app.common.confirm")}
                                        onClick={() => {
                                            confirm()
                                        }}
                                    />
                                </div>

                            </>
                        ) : (
                            <>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{__html: t("app.settings.change_plan_decline")}} />
                                </div>

                                <div className="p-4 flex before:flex-1 items-center justify-between">
                                    <ButtonSubmit
                                        isLoading={isLoading}
                                        label={t("app.common.confirm")}
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                    />
                                </div>
                            </>
                        )

                    )
                }


            </LoaderWrapper>
        </FormPopup>
    )
}

const CheckoutForm = ({changeDetail}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoadingPayment(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: window.location.origin +'/'+getPathParam([ACCOUNT, SUBSCRIPTION_COMPLATE]),
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoadingPayment(false);
    };

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>

                {
                    elements && (
                        <label className="block text-sm font-medium mb-2 text-gray-700" >
                            {t("app.settings.payment_information")}
                        </label>
                    )
                }

                <PaymentElement id="payment-element" />

                { message && <Warning message={message} />}

                {
                    elements && (
                        <div className="mt-2 mb-2">
                            <InputSubmit
                                isLoading={isLoadingPayment || !stripe || !elements}
                                isFullWith={true}
                                label={t("app.common.pay_now", {value: numberFormat(changeDetail.price)})}
                            />
                        </div>
                    )
                }

            </form>


        </>
    )
}
