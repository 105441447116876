import React, { useState } from "react";
import InputSubmit from "../../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../components/common/alert/banner";
import { useParams } from "react-router-dom";
import {putOrderProduct} from "../../../api/order";
import { useEffect } from "react";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import ProductCard from "../../../components/common/card/product-card";
import NumberFormat from "react-number-format";
import DiscountEditor from "../../../components/common/card/discount-editor";
import {makeid} from "../../../utils/converter";

export default function EditProduct({
  isOpen,
  setIsOpen,
  setDetails,
  product
}) {


  const fixDiscount = (discount_data) => {
    let discount_list = [];
    // eslint-disable-next-line
    (discount_data ?? []).map((discount, index) => {
        discount_list.push({id: makeid(20), price: discount})
    })
    return discount_list;
  }


  const { loggedCompany } = useSelector((state) => state.user);
  const { control, handleSubmit, setValue, watch } = useForm();
  const { t } = useTranslation();
  const { id } = useParams();

  const [isCreateProductLoading, setCreateProductLoading] = useState(false);
  const [createProductException, setCreateProductException] = useState(false);
  const [discounts, setDiscounts] = useState(fixDiscount(product?.discount ?? []));
  const [discountLabel, setDiscountLabel] = useState(t("app.quotations.no_discount"));

  const updateProduct = async (data) => {

      let final_price = watch('base_price') ?? 0;
      let discount_string = '';
      // eslint-disable-next-line
      (discounts ?? []).map((discount) => {
          final_price = final_price - (final_price * (discount.price / 100));
          discount_string = discount_string + discount.price + '|';
      })
      data['discount_price'] = final_price;
      data['discount'] = discount_string.slice(0, -1);




      if (loggedCompany) {
      setCreateProductLoading(true);
      putOrderProduct({ order_id: id, product_id: product.id, data }).then((response) => {
          setCreateProductLoading(false);
          setDetails(response);
          setIsOpen(false);
        }).catch((e) => {
          setCreateProductException(e.message);
          setCreateProductLoading(false);
        });
    }
  };



  useEffect(() => {
    setValue("id", product.id);
    setValue("quantity", product.quantity);
    setValue("base_price", product.base_price);
    setValue("stock", product.stock);

      if(discounts.length > 0){
          let discount_string = '';
          // eslint-disable-next-line
          (discounts ?? []).map((discount) => {
              if(discount.price){
                  discount_string = discount_string + discount.price + '% + ';
              }
          })
          // eslint-disable-next-line
          setDiscountLabel(discount_string.slice(0,-3));
      }else{
          setDiscountLabel(t("app.quotations.no_discount"));
      }

      // eslint-disable-next-line
  }, [product, setValue, discounts]);

  const onSubmit = async (data) => {
    await updateProduct(data);
  };


    const getFinalPrice = () => {
        let final_price = watch('base_price') ?? 0;

        // eslint-disable-next-line
        (discounts ?? []).map((discount) => {
            final_price = final_price - (final_price * (discount.price / 100));
        });

        return final_price;
    }

  return (
    <FormPopup
      title={t("app.orders.edit-product-in-order")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="col-span-2 p-2 border flex justify-between ">
          <ProductCard
              isSelected={true}
              isList={false}
              product={product.product}
              onSelect={(e) => {
                e.preventDefault();
              }}
          />
        </div>

        <form className="col-span-2 " onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Controller
                name="quantity"
                rules={{ required: true }}
                control={control}
                render={({
                           field: { onChange, value, name },
                           fieldState: { error },
                         }) => (
                    <InputFormattedNumber
                        label={t("app.orders.quantity")}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        prefix={""}
                        suffix={""}
                        errors={error}
                    />
                )}
            />

            <Controller
                name="stock"
                rules={{ required: false }}
                control={control}
                render={({
                           field: { onChange, value, name },
                           fieldState: { error },
                         }) => (
                    <InputFormattedNumber
                        label={t("app.orders.stock")}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        prefix={""}
                        suffix={""}
                        errors={error}
                    />
                )}
            />

            <Controller
                name="base_price"
                rules={{ required: true }}
                control={control}
                render={({
                           field: { onChange, value, name },
                           fieldState: { error },
                         }) => (
                    <InputFormattedNumber
                        label={t("app.orders.base_price")}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        prefix={"€ "}
                        suffix={""}
                        errors={error}
                    />
                )}
            />
          </div>

            <div className="flex before:flex-1  items-center justify-between mt-6">

                <div className="mt-5 w-full sm:w-1/2">
                    <dl className="divide-y divide-gray-200">



                        <div className="py-3 flex justify-between text-sm font-medium items-center">
                            <dt className="text-gray-500">{t("app.orders.discount_label")}</dt>
                            <dd className="flex text-right text-sm text-gray-500">
                                <DiscountEditor
                                    btnClass={'w-full justify-start'}
                                    boxClass={''}
                                    label={discountLabel}
                                    discounts={discounts}
                                    element={product}
                                    onUpdateDiscount={(id, value, index) => {
                                        let filtered = discounts;
                                        filtered[index]['price'] = value !== false ? value : null;
                                        filtered = filtered.filter(r => r.price !== null);
                                        setDiscounts(filtered);
                                    }}
                                    onAddDiscount={(id) => {
                                        let filtered = discounts;
                                        filtered = [...discounts, {id: makeid(20), price: null}];
                                        setDiscounts(filtered)

                                    }}
                                />
                            </dd>
                        </div>

                        <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="text-gray-500">{t("app.orders.net_price")}</dt>
                            <dd className="flex text-right text-sm text-gray-500">
                                        <span className="flex-grow">
                                            <NumberFormat
                                                value={getFinalPrice()}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={3}
                                                suffix={" €"}
                                            />
                                        </span>
                            </dd>
                        </div>

                        <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="text-gray-900">{t("app.quotations.total_price")}</dt>
                            <dd className="flex text-right text-sm text-gray-900">
                                        <span className="flex-grow">
                                              <NumberFormat
                                                  value={getFinalPrice()  * (watch('quantity') ?? 0)}
                                                  displayType={"text"}
                                                  thousandSeparator={"."}
                                                  decimalSeparator={","}
                                                  decimalScale={2}
                                                  suffix={" €"}
                                              />
                                        </span>
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>

          {/* Warning */}
          {createProductException && <Warning message={createProductException} />}

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                isLoading={isCreateProductLoading}
                label={t("app.orders.edit-product")}
            />
          </div>
        </form>

      </div>

    </FormPopup>
  );
}
