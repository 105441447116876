import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getCustomers} from "../api/customer";
import {getSuppliers} from "../api/supplier";
import {getCompanyUsers} from "../api/company";

const initialState = {
    customers: [],
    connections: [],
    suppliers: [],
    users: [],
    allCustomers: []
};



/***************
 * ASYNC THUNK *
 ***************/

export const customersList = createAsyncThunk(
    "customers/list",
    async ({ company_id, params, signal }) => {
        try {
            const res = await getCustomers({ company_id, params, signal });
            return res;
        } catch (e) {
            throw e;
        }
    }
);

export const allCustomersList = createAsyncThunk(
    "allcustomers/list",
    async ({ company_id, params, signal }) => {
        try {
            const res = await getCustomers({ company_id, params, signal });
            return res;
        } catch (e) {
            throw e;
        }
    }
);

export const connectionsList = createAsyncThunk(
    "connections/list",
    async ({ company_id, params, signal }) => {
        try {
            const res = await getCustomers({ company_id, params, signal });
            return res;
        } catch (e) {
            throw e;
        }
    }
);

export const suppliersList = createAsyncThunk(
    "suppliers/list",
    async ({ company_id, params, signal }) => {
        try {
            const res = await getSuppliers({ company_id, params, signal });

            return res;

        } catch (e) {
            throw e;
        }
    }
);


export const usersList = createAsyncThunk(
    "users/list",
    async ({ company_id, params, signal }) => {
        try {
            return await getCompanyUsers({ company_id, params, signal });
        } catch (e) {
            throw e;
        }
    }
);


/*******************
 * EXPOSED          *
 *******************/

export const fetchCustomers = ({company_id, signal, params = null}) => (dispatch, getState) => {
    const { customers, isLoadingCustomers } = getState().connection;
    if (customers.length === 0 && !isLoadingCustomers) {
        dispatch(customersList({company_id, params: params, signal }));
    }
};



export const fetchAllCustomers = ({ company_id, signal, params = null }) => (dispatch, getState) => {
    const { allCustomers, isLoadingAllCustomers } = getState().connection;
    if (allCustomers.length === 0 && !isLoadingAllCustomers) {
        dispatch(allCustomersList({ company_id, params: params, signal }));
    }
};



export const fetchConnections = ({company_id, signal, params = null}) => (dispatch, getState) => {
    const { connections, isLoadingConnections } = getState().connection;
    if (connections.length === 0 && !isLoadingConnections) {
        dispatch(connectionsList({company_id, params: params, signal }));
    }
};

export const loadConnections = ({company_id, signal, params = null}) => (dispatch, getState) => {
        const { user } = getState();
        if (user.loggedCompany) {
            dispatch(connectionsList({ company_id, params: params, signal }));
        }
    };


export const fetchSuppliers = ({company_id, signal}) => (dispatch, getState) => {
    const { suppliers, isLoadingSuppliers } = getState().connection;
    if (suppliers.length === 0 && !isLoadingSuppliers) {
        dispatch(suppliersList({company_id, params: null, signal }));
    }
};

export const fetchUsers = ({company_id, params = null, signal}) => (dispatch, getState) => {
    const { users, isLoadingUsers } = getState().connection;
    if (users.length === 0 && !isLoadingUsers) {
        dispatch(usersList({company_id, params: params, signal }));
    }
};

export const fetchAllUsers = ({company_id, signal}) => (dispatch, getState) => {
    const { users, isLoadingUsers } = getState().connection;
    if (users.length === 0 && !isLoadingUsers) {
        dispatch(usersList({company_id, params: {include_all: true}, signal }));
    }
};


export const loadCustomers =
    (company_id, signal) => (dispatch, getState) => {
        const { user } = getState();
        if (user.loggedCompany) {
            dispatch(customersList({ company_id, params: null, signal }));
        }
    };

export const loadSuppliers =
    (company_id, signal) => (dispatch, getState) => {
        const { user } = getState();
        if (user.loggedCompany) {
            dispatch(suppliersList({ company_id, params: null, signal }));
        }
    };

export const loadUsers =
    (company_id, signal) => (dispatch, getState) => {
        const { user } = getState();
        if (user.loggedCompany) {
            dispatch(usersList({ company_id, params: null, signal }));
        }
    };


/*******************
 * REDUCERS EVENTS *
 *******************/

export const connectionReducer = createSlice({
  name: "connection",
  initialState,
    reducers: {
        // action to clear the state (in case of logout/company change/etc...)
        clearConnections: (state) => {
            state.total_rows = 0;
            state.customers = [];
            state.allCustomers = [];
            state.isLoadingAllCustomers = false;
            state.isLoadingCustomers = false;
            state.suppliers = [];
            state.isLoadingSuppliers = false;
            state.users = [];
            state.isLoadingUsers = false;
        },
  },
  extraReducers: (builder) => {
    builder
        .addCase(customersList.pending, (state) => {
            state.isLoadingCustomers = true;
        })
        .addCase(customersList.fulfilled, (state, action) => {
            state.total_rows = action.payload.total_rows;
            state.customers = action.payload.customers;
            state.isLoadingCustomers = false;
        })
        .addCase(customersList.rejected, (state, action) => {
            state.isLoadingCustomers = false;
            state.customersException = action.error.message;
        });
    builder 
          .addCase(allCustomersList.pending, (state) => {
              state.isLoadingAllCustomers = true;
          })
          .addCase(allCustomersList.fulfilled, (state, action) => {
              state.total_rows = action.payload.total_rows;
              state.allCustomers = action.payload.customers;
              state.isLoadingAllCustomers = false;
          })
          .addCase(allCustomersList.rejected, (state, action) => {
              state.isLoadingAllCustomers = false;
              state.customersException = action.error.message;
          });    
    builder
        .addCase(connectionsList.pending, (state) => {
            state.isLoadingConnections = true;
        })
        .addCase(connectionsList.fulfilled, (state, action) => {
            state.total_rows = action.payload.total_rows;
            state.connections = action.payload.customers;
            state.isLoadingConnections = false;
        })
        .addCase(connectionsList.rejected, (state, action) => {
            state.isLoadingConnections = false;
            state.connectionsException = action.error.message;
        });
    builder
        .addCase(suppliersList.pending, (state) => {
            state.isLoadingSuppliers = true;
        })
        .addCase(suppliersList.fulfilled, (state, action) => {
            state.total_rows = action.payload.total_rows;
            state.suppliers = action.payload.suppliers;
            state.isLoadingSuppliers = false;
        })
        .addCase(suppliersList.rejected, (state, action) => {
            state.isLoadingSuppliers = false;
            state.suppliersException = action.error.message;
        });
    builder
        .addCase(usersList.pending, (state) => {
            state.isLoadingUsers = true;
        })
        .addCase(usersList.fulfilled, (state, action) => {
            state.users = action.payload;
            state.isLoadingUsers = false;
        })
        .addCase(usersList.rejected, (state, action) => {
            state.isLoadingUsers = false;
            state.usersException = action.error.message;
        });

  }
});

export const { clearConnections } = connectionReducer.actions;

export default connectionReducer.reducer;
