import React  from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {resetPasswordRequest} from "../../reducers/userReducer";

import HeaderProfiling from "../../components/layout/header-profiling";
import {useForm} from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import {emailRegex} from "../../constants/regex";
import {Success, Warning} from "../../components/common/alert/banner";
import InputSubmit from "../../components/common/form/input-submit";
import {useTranslation} from "react-i18next";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const ResetPasswordRequest = () => {
  const { isRequestSend, isResetRequestLoading, resetRequestException } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    dispatch(resetPasswordRequest(data));
  };

  return(
      <main className="bg-white">

        <div className="relative md:flex">

          {/* Content */}
          <div className="md:w-1/2">
            <div className="min-h-screen h-full flex flex-col after:flex-1">

              {/* Header */}
              <HeaderProfiling
                  isActiveSignin={true}
              />

              <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
                <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("oauth.reset_password.title")}</h1>
                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-4">
                    <InputText
                        id={'email'}
                        type={'email'}
                        label={t("app.form.email")}
                        errors={errors.email}
                        input={{...register("email", { required: true, pattern: emailRegex })}}
                    />

                  </div>

                  {/* Warning */}
                  {resetRequestException && (
                      <Warning
                          message={resetRequestException}
                      />
                  )}

                  {isRequestSend && (
                      <Success
                          message={t('oauth.reset_password.success_message')}
                      />
                  )}

                  <div className="flex items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isResetRequestLoading}
                        label={t('oauth.reset_password.name')}
                        isFullWith={true}
                    />
                  </div>
                </form>


              </div>

            </div>
          </div>

          {/* Image */}
          <SidebarProfiling />


        </div>

      </main>
  );
};

export default ResetPasswordRequest;
