export const SHIPPING_TOURNOVER = 'SHIPPING_TOURNOVER';
export const ACTIVE_SHIPPING = 'ACTIVE_SHIPPING';
export const SCHEDULED_CONTAINER_TRUCK = 'SCHEDULED_CONTAINER_TRUCK';
export const COMPANY_TASK = 'COMPANY_TASK';
export const UNCONFIRMED_SHIPPING = 'UNCONFIRMED_SHIPPING';
export const SHIPPING_STATISTICS = 'SHIPPING_STATISTICS';
export const SHIPPING_TRACKING_LOG = 'SHIPPING_TRACKING_LOG';
export const ACTIVE_DOCUMENT = 'ACTIVE_DOCUMENT';
export const COMPANY_BOARD = 'COMPANY_BOARD';

export const SHIPPING_TRACKING_VESSELS = 'SHIPPING_TRACKING_VESSELS';
export const CONTAINER_MAP_IFRAME = 'CONTAINER_MAP_IFRAME';

