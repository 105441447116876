import React from 'react';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BookmarkIcon, PencilIcon, PlusCircleIcon, XIcon} from "@heroicons/react/outline";
import CompanyTypeSwitch from '../partials/header/company-type-switch';

function WelcomeBanner({onAddWidget, isEditing, setEditing, onCancel,showEditing}) {
    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user);



    const getGreetingTime = (m) => {
        let g = null; //return g

        if(!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

        let split_afternoon = 12 //24hr time to split the afternoon
        let split_evening = 17 //24hr time to split the evening
        let currentHour = parseFloat(m.format("HH"));

        if(currentHour >= split_afternoon && currentHour <= split_evening) {
            g = "afternoon";
        } else if(currentHour >= split_evening) {
            g = "evening";
        } else {
            g = "morning";
        }

        return g;
    }


  return (
      <div className="relative md:flex md:items-center md:justify-between md:space-x-5 px-3 mb-5">
          <div className="flex items-start space-x-5">
            <div className='flex items-center'>
                  <CompanyTypeSwitch isActiveBtn={true} />
                  <div className="pt-1.5">
                      <h1 className="text-2xl font-bold text-gray-900">  {t("dashboard." + getGreetingTime(moment()))}, {detail?.firstname ?? ""} {detail?.lastname ?? ""}</h1>
                      <p className="text-sm font-medium text-gray-500">
                          {t("dashboard.description")}
                      </p>

                  </div>
            </div>
         
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">


              {
                  !isEditing ? (
                      <button
                          type="button"
                          onClick={() => {
                              onAddWidget()
                          }}
                          className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                          <PlusCircleIcon className="mr-3 h-4 w-4" />
                          {t("dashboard.add_widget")}
                      </button>
                  ) : (
                      <button
                          type="button"
                          onClick={() => {
                              onCancel()
                          }}
                          className="inline-flex items-center justify-center rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                      >
                          <XIcon className="mr-3 h-4 w-4" />
                          {t("dashboard.cancel")}
                      </button>
                  )
              }

              {
                  showEditing && (
                    <button
                        type="button"
                        onClick={() => {
                            setEditing()
                        }}
                        className="inline-flex items-center justify-center rounded-md bg-blue-1000 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900"
                    >

                        {
                            isEditing ? (
                                <>
                                    <BookmarkIcon className="mr-3 h-4 w-4" />
                                    {t("dashboard.save")}
                                </>
                            ) : (

                                <>
                                    <PencilIcon className="mr-3 h-4 w-4" />
                                    {t("dashboard.edit")}
                                </>
                            )
                        }




                    </button>
                  )
              }



          </div>
      </div>

  );
}


export default WelcomeBanner;
