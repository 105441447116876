import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline/esm";

const SlideFull = ({isOpen, setIsOpen, title, children, header = null, extras = {}, setClean, hasPadding = true, activeOverflow = true, showTopHeader = true, customHeader = null, zIndex = ' z-10 '}) => {

    const Header = header;
    const CustomHeader = customHeader;

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={"fixed inset-0 overflow-hidden "+zIndex} onClose={() => {

            }}>
                <div className="absolute inset-0 overflow-hidden">

                    <div className="fixed inset-y-0  max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                        >
                            <div className={"w-screen max-w-full"}>
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-solitude-100 shadow-xl">

                                    <div className={activeOverflow ? "flex-1 h-0 overflow-y-auto" : "flex-1 h-0"}>


                                        {showTopHeader && (
                                            <div className="py-4 px-4 bg-white sm:px-6 shadow">
                                                <div className="flex items-center justify-between">

                                                    <div className="flex items-center justify-between">
                                                        <div className="ml-3 h-7 flex items-center">
                                                            <button
                                                                type="button"
                                                                className="text-gray-500 hover:text-gray-800  mr-2"
                                                                onClick={() => {setIsOpen(false); setClean()}}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                                            </button>
                                                            <div className=" w-px h-6 bg-gray-300 mr-4" />
                                                        </div>
                                                        <Dialog.Title className="text-xl  text-blue-1000 uppercase font-bold ">{title}</Dialog.Title>
                                                    </div>


                                                    <div className="ml-3 h-7 flex items-center">
                                                        {
                                                            header  && (
                                                                <Header extras={extras} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {
                                            CustomHeader && (
                                                <CustomHeader extras={extras} />
                                            )
                                        }



                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className={hasPadding ? "px-4 divide-y divide-gray-200 sm:px-6" : "divide-y divide-gray-200" }>
                                                {children}
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

export default SlideFull;
