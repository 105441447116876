import React, {useEffect} from 'react'
import ListSection from "../../../../../../../../components/layout/list-section";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../../../../../../../../components/common/form/input-formatted-number";
import ProductCell from "../../../../../../../../components/common/table/product-cell";
import TableDetail from "../../../../../../../../components/common/list/table-detail";
import InputSelect from "../../../../../../../../components/common/form/input-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchPackagingTypes} from "../../../../../../../../reducers/configReducer";
import {PlusIcon, TrashIcon} from "@heroicons/react/outline";
import {
    FilterButton,
    ImportButton,
    TrashButton
} from "../../../../../../../../components/layout/page-header";
import NumberFormat from "react-number-format";
import CatalogIcon from "../../../../../../../../resources/icons/CatalogIcon";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../../../../../../components/common/form/button-submit";



const PackagingConfiguration = ({
  onChangeStep,
  selectedProductList,
  onDelete,
  onSetQuantity,
  onAddInsideBox,
  onChangeValue,
  onSave,
  isLoading
}) => {

    const { packagingTypesList, isPackagingTypesListLoading } = useSelector((state) => state.config);

    const dispatch = useDispatch();
    const { t } = useTranslation();


    useEffect(() => {
        dispatch(fetchPackagingTypes());
    }, [dispatch])





    const columns = [
        t("app.container.packaging_type"),
        t("app.container.packaging_number"),
        t("app.container.dimensions"),
        "",
    ];

    return (
        <>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-medium text-gray-700">{t("app.container.group_configuration")}</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex justify-between items-center  ">
                    <FilterButton
                        onClick={() => {
                            onChangeStep();
                        }}
                        icon={
                            <CatalogIcon
                                className="w-4 h-4 fill-current flex-shrink-0"
                                aria-hidden="true"
                            />
                        }
                        text={t("app.container.add_product")}
                    />

                    <div className="ml-2">
                        <ButtonSubmit
                            onClick={() => {
                                onSave();
                            }}
                            isFullWith={true}
                            isLoading={isLoading}
                            label={t("app.container.add_packaging")}
                        />
                    </div>
                </div>
            </div>


            <ListSection
                columns={columns}
                totalRows={selectedProductList.length}
                list={selectedProductList}
                showHeader={false}
                isLoading={false}
                showMobile={false}
                desktopRow={DeskRow}
                extras={{
                    packagingTypesList,
                    isPackagingTypesListLoading,
                    onDeleteProduct: (id, item) => {
                        onDelete(id, item)
                    },
                    onSetQuantity: (id, item, value) => {
                        onSetQuantity(id, item, value)
                    },
                    onAddInsideBox: (id) => {
                        onAddInsideBox(id)
                    },
                    onChangeValue: (id, value, type) =>{
                        onChangeValue(id, value, type);
                    },
                }}
            />

        </>
    );
};


const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();

    const {control, setValue, watch, setError, clearErrors} = useForm();

    useEffect(() => {
        if(element.length > 0){
            setValue('length', element.length)
        }
        if(element.width > 0){
            setValue('width', element.width)
        }
        if(element.height > 0){
            setValue('height', element.height)
        }

        if(element.packaging_type !== undefined){
            setValue('packaging_type', element.packaging_type)
        }else{
            if(extras.packagingTypesList.length > 0){
                setValue('packaging_type', extras.packagingTypesList[0])
                extras.onChangeValue(element.id, extras.packagingTypesList[0], 'packaging_type');
            }
        }

        if(element.products.length > 0){
            // eslint-disable-next-line
            element.products.map(item => {
                setValue(item.id, item.quantity)
            })

            if(element.products.length === 1){
                setValue('need_box', element.need_box > 0 ? element.need_box : Math.floor(element.products[0]?.quantity / element.products[0]?.quantity_box))
            }else{
                setValue('need_box', 1)
            }


        }
        // eslint-disable-next-line
    }, [setValue, extras.packagingTypesList])


    const validateBox = (box, product, quantity = null) => {
        if(element.products.length === 1){

            let totalQuantity = quantity;
            if(!totalQuantity){
                totalQuantity = watch(product.id);
            }

            if(((totalQuantity / box) % 1 !== 0 ) || ((totalQuantity / product.quantity_box) !== box) ){
                setError('need_box', { type: 'custom', message: t("app.container.packaging_error")});

                let needQuantity = ( box * product.quantity_box) > product.available_quantity ? product.available_quantity : ( box * product.quantity_box);
                setError(product.id, { type: 'custom', message: t("app.container.product_predict") + needQuantity});
            }else {
                clearErrors('need_box')
                clearErrors(product.id)
            }
        }else {
            clearErrors('need_box')
            clearErrors(product.id)
        }
    }

    return (
        <>
            <tr className="border-t border-gray-200">
                <TableDetail  extraClass="px-6 py-3 whitespace-nowrap text-sm font-medium bg-gray-50 text-gray-900">
                    <Controller
                        name="packaging_type"
                        rules={{required: false}}
                        control={control}
                        render={({field: { onChange, value, name },
                                     fieldState: { error }}) => (
                            <InputSelect
                                label={t("app.container.packaging_type")}
                                isShowLabel={false}
                                name={name}
                                options={extras.packagingTypesList}
                                value={value}
                                onChange={(e) => {
                                    extras.onChangeValue(element.id, e, 'packaging_type');
                                    onChange(e);
                                }}
                                isLoading={extras.isPackagingTypesListLoading}
                                isDisabled={extras.isPackagingTypesListLoading}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>
                <TableDetail extraClass="px-6 py-3 whitespace-nowrap text-sm font-medium bg-gray-50 text-gray-900">
                    <Controller
                        name="need_box"
                        rules={{ required: true }}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputFormattedNumber
                                label={t("app.container.total_packagings")}
                                isShowLabel={false}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    validateBox(e, element.products[0])
                                    extras.onChangeValue(element.id, e, 'need_box');
                                }}
                                warningRegex={"il numero dei box e incongruente con la quantità inserita"}
                                prefix={""}
                                suffix={" Box"}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>
                <TableDetail  extraClass="px-6 py-3 whitespace-nowrap  text-sm font-medium bg-gray-50 text-gray-900">
                    <div className=" grid md:grid-cols-3 lg:grid-cols-3 gap-y-5 gap-x-5 sm:grid-cols-3">

                        <Controller
                            name="length"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.length")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(element.id, e, 'length');
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="width"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.width")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(element.id, e, 'width');
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="height"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.height")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(element.id, e, 'height');
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                    </div>
                </TableDetail>

                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium bg-gray-50 text-gray-900">

                    {
                        watch('need_box') === 1 && (
                            <ImportButton
                                onClick={() => {
                                    extras.onAddInsideBox(element.id);
                                }}
                                icon={
                                    <PlusIcon
                                        className="w-4 h-4 fill-current flex-shrink-0"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        )
                    }

                </td>
            </tr>


            {element.products.map((item, personIdx) => (
                <tr>
                    <TableDetail colSpan={1} extraClass={"whitespace-pre-line"} >
                        <div>
                            <ProductCell product={item}/>
                        </div>
                    </TableDetail>
                    <TableDetail>
                        <div className="text-sm text-gray-900">
                            <NumberFormat
                                value={item.quantity_box }
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                suffix={' pz'}
                            />
                        </div>
                        <div className="text-xs text-gray-500">
                            {t("app.container.quantity_box")}
                        </div>
                    </TableDetail>
                    <TableDetail>
                        <Controller
                            name={item.id}
                            rules={{ required: true }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    maxValue={item.available_quantity}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        validateBox(watch("need_box"), element.products[0], e);
                                        extras.onSetQuantity(element.id, item, e);

                                    }}
                                    prefix={""}
                                    suffix={""}
                                    errors={error}
                                />
                            )}
                        />
                    </TableDetail>
                    <TableDetail>
                        <TrashButton
                            onClick={() => {
                                extras.onDeleteProduct(element.id, item)
                            }}
                            icon={
                                <TrashIcon
                                    className="w-4 h-4  "
                                    aria-hidden="true"
                                />
                            }
                        />

                    </TableDetail>
                </tr>
            ))}




        </>
    );
};


export default PackagingConfiguration;
