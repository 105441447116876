import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import configReducer from "./configReducer";
import companyReducer from "./companyReducer";
import userCompanyReducer from "./userCompanyReducer";
import subscriptionReducer from "./subscriptionReducer";
import connectionReducer from "./connectionReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    config: configReducer,
    company: companyReducer,
    userCompany: userCompanyReducer,
    subscription: subscriptionReducer,
    connection: connectionReducer,
  },
});
