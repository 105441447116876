import {useTranslation} from "react-i18next";
import React from "react";
import NumberFormat from "react-number-format";

export default function InputFormattedNumber ({isFullW = false, decimalScale = false, isDisabled = false, value, prefix, suffix, label, isShowLabel = true, customClass = '', isRequired, id, errors, warning, warningRegex, onChange, maxValue}){

    const { t } = useTranslation();

    return (
        <div className={isFullW  ? 'w-full' : ''}>
            {
                isShowLabel && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }

            {
                decimalScale ? (
                    <NumberFormat
                        value={value}
                        className={`${customClass !== '' ? customClass : 'form-input'} w-full disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
                        inputMode="decimal"
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        onValueChange={(values) => {maxValue && values.floatValue > maxValue ? onChange(maxValue) : onChange(values.floatValue)}}
                        placeholder={t("app.common.placeholder", {field: label.toLowerCase()})}
                        prefix={prefix}
                        suffix={suffix}
                        disabled={isDisabled}
                        decimalScale={decimalScale}
                    />
                ) : (
                    <NumberFormat
                        value={value}
                        className={`${customClass !== '' ? customClass : 'form-input'} w-full disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
                        inputMode="decimal"
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        onValueChange={(values) => {maxValue && values.floatValue > maxValue ? onChange(maxValue) : onChange(values.floatValue)}}
                        placeholder={t("app.common.placeholder", {field: label.toLowerCase()})}
                        prefix={prefix}
                        suffix={suffix}
                        disabled={isDisabled}
                    />
                )
            }


            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}
            {errors && errors.type === 'custom'  && (  <p className="mt-2 text-sm text-red-600">{errors.message}</p>)}
        </div>

    )
};
