import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import {
  COMPANY_EXPORTER,
  COMPANY_IMPORTER
} from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { safeChangeCompany } from "../../../reducers/userReducer";
import {hasPermissionFor} from "../restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";

export default function CompanyTypeSwitch({isActiveBtn = false}) {
  const { companyType } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);

  const dispatch = useDispatch();

  const SwitchType = (type) => {
    dispatch(safeChangeCompany(type));
  };

  const isActiveSwitch = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_SWITCH);



  return (
    (isActiveSwitch) && (

        <>
           

            {
                  isActiveBtn ? (
                        <div>
                          <MobileSwitch position={'origin-top-left absolute left-0'} type={companyType} OnSwitchType={(r) => { SwitchType(r) }} />
                        </div>
                  ) : (
                    <>
                        <div className={'hidden md:block'}>
                            <DesktopSwitch type={companyType} OnSwitchType={(r) => { SwitchType(r) }} />
                        </div>
                        <div className={'md:hidden'}>
                            <MobileSwitch position={'origin-top-right absolute right-0'} type={companyType} OnSwitchType={(r) => { SwitchType(r) }} />
                        </div>
                    </>
                  )
            }

           
        </>


    )
  );
}


const DesktopSwitch = ({OnSwitchType, type}) => {

    return (
        <div className={'ml-3 relative'}>
            <div className="w-48 rounded-full ">
                <BtnSwitch OnSwitchType={OnSwitchType} type={type} />

            </div>
        </div>
    )
}

const MobileSwitch = ({ OnSwitchType, type, position }) => {

    return (
        <Menu as="div" className={'mr-3 relative'}>
            <div>
                <div
                    className={'max-w-xs bg-white flex items-center text-sm rounded-full border'}>
                    <Menu.Button
                        className={'flex items-center truncate w-8 h-8 justify-center group hover:bg-blue-1000 transition duration-150 rounded-full'}>
                        <SwitchHorizontalIcon
                            className={'h-4 w-4 text-blue-1000 group-hover:text-white'}
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={position + " mt-2 w-48 mt-3 z-50  bg-white rounded-full"}>
                    <BtnSwitch OnSwitchType={OnSwitchType} type={type} />
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const BtnSwitch = ({OnSwitchType, type}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex justify-center  ">
                <div className="relative flex w-full p-0.5 border bg-white rounded-full">
                <span
                    className="absolute inset-0  pointer-events-none"
                    aria-hidden="true"
                >
                  <span
                      className={`absolute inset-0 w-1/2 bg-blue-1000 shadow-md rounded-full transform transition duration-150 ease-in-out ${
                          type === COMPANY_EXPORTER
                                ? "translate-x-full"
                                : "translate-x-0"
                      }`}
                  />
                </span>
                    <button
                        className={`relative ${type === COMPANY_IMPORTER ? "text-white " : "text-blue-1000"
                            } flex-1 text-sm font-medium p-1 duration-150 ease-in-out`}
                        onClick={(e) => {
                            e.preventDefault();
                            OnSwitchType(COMPANY_IMPORTER);
                        }}
                    >
                        {t("app.menu.buy")}
                    </button>
                    <button
                        className={`relative ${
                            type === COMPANY_EXPORTER ? "text-white " : "text-blue-1000"
                        } flex-1 text-sm font-medium p-1 duration-150 ease-in-out`}
                        onClick={(e) => {
                            e.preventDefault();
                            OnSwitchType(COMPANY_EXPORTER);
                        }}
                    >
                        {t("app.menu.sell")}
                    </button>
                   
                </div>
            </div>
        </>
    )
}
