import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, {ActionButton, ImportExportButton} from "../../components/layout/page-header";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../components/common/table/actions-menu";
import {LEAD_OVERVIEW, SALES} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import AddLead from "./add-lead";
import { useTranslation } from "react-i18next";
import CustomerCell from "../../components/common/table/customer-cell";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {
    getPermission,
    permissionGroup, permissionSpecific,
    permissionType,
    sp
} from "../../constants/permissions";
import ListSection from "../../components/layout/list-section";
import NumberFormat from "react-number-format";
import {getCustomFormat} from "../../utils/timeUtils";
import OptionalLink from "../../components/common/table/optional-link";
import TableDetail from "../../components/common/list/table-detail";
import {COMPANY, LEAD} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import {PlusIcon} from "@heroicons/react/outline";
import DynamicFilterMenu from "../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../constants/dynamic-filter";
import BasePermissionWrapper, {
    hasPermission,
    hasPermissionFor
} from "../../components/partials/restricted/base-permission-wrapper";
import UserInitial from "../../components/common/table/user-initial";
import {AcquisitionBar} from "../../components/common/alert/acquisition-bar";
import {getLeads} from "../../api/lead";
import {LeadsBarIcon} from "../../components/common/alert/leads-bar-icon";
import AnimatedPing from "../../components/common/progress/animated-ping";
import CardDetail from "../../components/common/list/card-detail";
import {loadConnections} from "../../reducers/connectionReducer";
import ImportLog from "../catalog/product/import/import-log";
import GenericImport from "../../components/common/import-export/generic-import";
import {LEAD_IMPORT} from "../../constants/import";
import GenericExport from "../../components/common/import-export/generic-export";
import ImportErrorPopup from "../../components/common/popup/import-error-popup";

function checkDate(data) {
    let today = new Date();
    let checkDate = new Date(data);
    let diff = today - checkDate;

    let days = diff / (1000 * 3600 * 24);


    if (days >= 8) {
        return "red";
    } else if (days >= 4) {

        return "";
    } else {
        return false;
    }
}



const Leads = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  const { loggedCompany, isExporter, companyType } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);

  const canView =  hasPermission(permissions, permissionGroup.LEAD, permissionType.VIEW, companyType, true);
  const canCreate =  hasPermission(permissions, permissionGroup.LEAD, permissionType.CREATE, companyType, true);
  const canRemove =  hasPermission(permissions, permissionGroup.LEAD, permissionType.DELETE, companyType, true);

  const canImport = hasPermissionFor(permissions, permissionGroup.LEAD, permissionSpecific.LEAD_IMPORT);
  const canExport = hasPermissionFor(permissions, permissionGroup.LEAD, permissionSpecific.LEAD_EXPORT);


  const [isFetchLoading, setFetchLoading] = useState(true);

  const [limit] = useState(30);
  const [leadList, setLeadList] = useState({leads: [], total_rows: 0});


  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
        return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [needRefresh, setNeedRefresh] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});


  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);
  const [totals, setTotals] = useState([]);

  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});

  const [isOpenUpload, setOpenUpload] = useState(false);
  const [isOpenExport, setOpenExport] = useState(false);
  const [lastImport, setLastImport] = useState(false);
  const [isOpenImportLog, setOpenImportLog] = useState(false);
  const [ImportErrorCode, setImportErrorCode] = useState(false);
  const [isOpenImportError, setOpenImportError] = useState(false);




  const { t } = useTranslation();

    const columns = [
        {
            header:  t("app.leads.opportunity"),
            id: 'business_name',
            accessor: "business_name"
        },
        {
            header: t("app.leads.stage"),
            id: 'lead_status',
            accessor: 'lead_status',
        },
        {
            header: t("app.leads.priority"),
            id: 'priority',
            accessor: "priority"
        },
        {
            header: t("app.leads.estimated_value"),
            id: 'estimated_value',
            accessor: "estimated_value"
        },
        {
            header: t("app.leads.last_contact_date"),
            id: 'last_contact',
            accessor: r => new Date(r.last_contact_date).getTime()
        },
        {
            header: t("app.leads.owner"),
            id: 'creator',
            accessor: r => r.creator.fullname,
        },
        {
            header: "",
            accessor: null
        }
    ];


   useEffect(() => {
       if(companyType !== lastCompanyType){
           setDynamicDateFilter([]);
           setDefaultParams(false);
           setDefaultParamsUsed(false);
       }
       // eslint-disable-next-line
   }, [companyType]);





    useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchLeads() {
      if (loggedCompany) {
        setFetchLoading(true);
          setTotals([]);
          try {
           const res = await getLeads({
            company_id: loggedCompany.id,
            params: {
              limit: activeDynamicFilter.length > 0 ? 1000000 : limit,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              status: null,
              remove_default: !defaultParamsUsed,
              company_type: companyType
            },
            data: activeDynamicFilter,
            signal,
          });
            setLeadList(res);
            setLastCompanyType(companyType);
            if(res.filters){
                setDynamicFilters(res.filters);
            }
            if(res.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
                setDefaultParams('?'+res.default_filter);
            }




            if(activeDynamicFilter.length > 0 && res.leads.length > 0){
                const totalsAmounts = res.leads.reduce((accumulator, current) => {
                    let symbol = '€';
                    let total = current?.estimated_value  ?? 0;

                    const found = accumulator.find(item => item.symbol === symbol);

                    if (found) {
                        found.total += total;
                    } else {
                        accumulator.push({ symbol: symbol, total: total });
                    }

                    return accumulator;
                }, []);
                setTotals(totalsAmounts);
            }


        } catch (e) {
            setLeadList({ leads: [], total_rows: 0 });
            if(signal.aborted){
                setFetchLoading(true);
            }
        } finally {
            if(signal.aborted){
                setFetchLoading(true);
            }else{
                setFetchLoading(false);
            }
        }
      }
    }

        fetchLeads();
    return () => {
      controller.abort();
    };
        // eslint-disable-next-line
  }, [offset, query, loggedCompany, companyType, needRefresh, activeDynamicFilter, limit]);

  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.LEAD, permissionType.LIST)]} renderBaseOnFail={true} requireExporter={true} >
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader
              title={t("app.leads.leads")}
              subtitle={canImport ? t("app.products.view_import_log") : false}
              importGroup={[getPermission(permissionGroup.LEAD, permissionSpecific.LEAD_IMPORT)]}
              onSubtitleClick={() => {
                  setOpenImportLog(true)
              }}
              onNeedRefresh={(r) => {
                  if(r.error_description){
                      setImportErrorCode(r.error_description);
                      setOpenImportError(true);
                  }else{
                      setNeedRefresh(!needRefresh);
                  }
              }}
              importType={LEAD_IMPORT.type}
              lastImport={lastImport}


          >
                  {
                      canCreate && (
                        <div className="mb-3 md:mb-0">
                            <ActionButton
                                icon={
                                    <PlusIcon
                                        className="w-4 h-4 flex-shrink-0"
                                        aria-hidden="true"
                                    />
                                }
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                                text={t("app.leads.new_lead")}
                            />
                        </div>
                      )
                  }
           <SubscriptionWrapper requiredPermission={sp.LEAD_PACKAGE} renderBaseOnFail={false}>

                  {
                      (canImport || canExport) && (
                          <ImportExportButton
                              canImport={canImport}
                              canExport={canExport}
                              onImport={() => {
                                  setOpenUpload(true);
                              }}
                              onExport={() => {
                                  setOpenExport(true);
                              }}
                          />
                      )
                  }
              </SubscriptionWrapper>
          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


          <ListSection
            pageLimit={activeDynamicFilter.length > 0 ? 1000000 : limit}
            title={t("app.leads.opportunity")}
            columns={columns}
            totalRows={leadList.total_rows}
            list={leadList?.leads ?? []}
            onSearch={setQuery}
            isLoading={isFetchLoading}
            paginationOnChange={setOffset}
            isDynamicPagination={true}
            mobileRow={MobRow}
            desktopRow={DeskRow}
            isTableSort={true}
            sortBy={sortBy}
            onSortBy={(n) => {
                setSortBy(n);
            }}
            activeDynamicFilter={true}
            extras={{
                isExporter,
                canView,
                canRemove,
                onRemove: (r, detail) => {
                    setOpenRemove(r);
                    setRemoveDetail(detail);
                }
            }}
          >

          <DynamicFilterMenu
              filters={dynamicFilters}
              defaultParams={defaultParams}
              lastCompanyType={lastCompanyType}
              isLoading={isFetchLoading && offset === 0}
              filterType={'company_lead'}
              onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);
              }}
          />
          </ListSection>
        </div>
      </div>

        {(canRemove && isOpenRemove) && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                onRemoveConfirmed={() => {
                    setNeedRefresh(!needRefresh);
                    dispatch(loadConnections({company_id: loggedCompany.id, params: {find_all: true}}));
                }}
            />
        )}

          <AddLead isOpen={isOpen} setIsOpen={setIsOpen} />

      {
          !isFetchLoading && totals.length > 0 && (
              <StickyDiv totals={totals} />
          )
      }

      {
          canImport && (
              <>
                  <GenericImport
                      title={t('app.common.import_title')}
                      importData={LEAD_IMPORT}
                      isOpen={isOpenUpload}
                      setIsOpen={setOpenUpload}
                      importGroup={LEAD_IMPORT.type}
                      onImportComplete={(r) => {
                          setLastImport(r);
                      }}
                  />

                  <ImportLog
                      isOpen={isOpenImportLog}
                      setIsOpen={setOpenImportLog}
                      type={LEAD_IMPORT.type}
                  />

                  {isOpenImportError && (
                      <ImportErrorPopup
                          isOpen={isOpenImportError}
                          errorCode={ImportErrorCode}
                          setIsOpen={() => {
                              setImportErrorCode(false);
                              setOpenImportError(false);
                              setNeedRefresh(!needRefresh);
                          }}
                      />
                  )}

              </>
          )
      }


      {canExport && (
          <GenericExport
              isOpen={isOpenExport}
              setIsOpen={setOpenExport}
              exportInfo={LEAD_IMPORT}
          />
      )}


    </BasePermissionWrapper>
  );
};


const StickyDiv = ({totals}) => {
    const { t } = useTranslation();



    return (
        <div id="stickyDiv"
            className={`sticky -bottom-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 z-10`}
        >
            <div className="overflow-x-auto shadow-2xl" style={{boxShadow: '0px -3px 15px #00000026'}}>
                <div className="align-middle inline-block w-24 min-w-full mx-auto">
                    <table className="min-w-full divide-y divide-gray-200 scrollableSticky ">
                <thead className={"bg-white  "}>
                <tr>
                    <th className="px-6 py-4 text-left text-xs font-medium  uppercase tracking-wider w-80 shadow-xl" >
                        {t("app.leads.total_leads")}
                    </th>

                    {
                        totals.map((total, index) => (
                            <th key={index} className="px-6 py-4 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider border-r last:border-r-0 shadow-xl">
                                <NumberFormat
                                    value={total.total}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={total.symbol  + " "}
                                />
                            </th>
                        ))
                    }


                </tr>
                </thead>
            </table>
                </div>
            </div>
        </div>
    );
};

const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
      title: t("app.leads.remove_title"),
      message: t("app.leads.remove_description"),
      endpoint: getPathParam([COMPANY, loggedCompany.id , LEAD, element.id])
  };


  return (
      <li key={element.id} className="flex items-center gap-4 p-4">
          <OptionalLink
              isLinked={extras.canView}
              to={getWebPathParam([SALES, LEAD, element.id, LEAD_OVERVIEW])}
              className="flex-grow"
          >
              <div className="pb-4 items-center flex justify-between">
                  <CustomerCell
                      customer={{business_name: element.business_name, color: element.color}}
                      extra={element.contact_first_name +' '+element.contact_last_name}
                      secondExtra={element.lead_reference}
                  />
                  <ActionsMenu>
                      {(extras.canView) && (
                          <Link to={getWebPathParam([SALES, LEAD, element.id, LEAD_OVERVIEW])}>
                              {t("app.common.overview")}
                          </Link>
                      )}
                      {extras.canRemove && (
                          <button className={'text-left'} onClick={() => {  extras.onRemove(true, removeDetail);}}>
                              {t("app.common.remove")}
                          </button>
                      )}
                  </ActionsMenu>
              </div>


              <div className="flex flex-col">
                  <CardDetail title={t("app.leads.stage")}>
                      <LeadsBarIcon status={element.lead_status} />
                  </CardDetail>

                  <CardDetail title={t("app.leads.priority")}>
                      <AcquisitionBar status={element.priority} />
                  </CardDetail>

                  <CardDetail title={t("app.leads.estimated_value")}>
                      <NumberFormat
                          value={element?.estimated_value ?? 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          decimalScale={2}
                          suffix={" €"}
                      />
                  </CardDetail>

                  <CardDetail title={t("app.leads.last_contact_date")}>
                      {
                          (element.last_contact_date && checkDate(element.last_contact_date) !== false) && (
                              <AnimatedPing color={checkDate(element.last_contact_date)} extraClass={'-ml-5'} style={{marginTop: '4.1px'}} />
                          )
                      }
                      {getCustomFormat(element.last_contact_date, 'DD/MM/YYYY')}
                  </CardDetail>

                  <CardDetail title={t("app.leads.owner")}>
                      <div className="flex items-center space-x-2">
                          <div className={"flex items-center gap-1 text-sm"}>
                              <UserInitial user={element.creator} />
                              <div className="text-left ml-1">

                                  <div className="truncate text-sm text-gray-900">
                                      {element.creator.fullname ?? ''}
                                  </div>
                                  <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                                      {t("app.quotations.created_on", {date: getCustomFormat(element.lead_date, 'DD/MM/YYYY')})}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </CardDetail>

              </div>
          </OptionalLink>
      </li>
  );
};

const DeskRow = ({ element , extras}) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
      title: t("app.leads.remove_title"),
      message: t("app.leads.remove_description"),
      endpoint: getPathParam([COMPANY, loggedCompany.id , LEAD, element.id])
  };


  return (
    <tr key={element.id}>

      <TableDetail extraClass="max-w-sm">
        <div className="flex items-center space-x-3 lg:pl-2">
          <OptionalLink
              isLinked={extras.canView}
              to={getWebPathParam([SALES, LEAD, element.id, LEAD_OVERVIEW])}
              className="truncate hover:text-gray-600"
          >
              <CustomerCell
                  customer={{business_name: element.business_name, color: element.color}}
                  extra={element.contact_first_name +' '+element.contact_last_name}
                  secondExtra={element.lead_reference}
              />
          </OptionalLink>
        </div>
      </TableDetail>


      <TableDetail>
          <LeadsBarIcon status={element.lead_status} />
      </TableDetail>

      <TableDetail>
          <AcquisitionBar status={element.priority} />
      </TableDetail>

      <TableDetail>
        <NumberFormat
            value={element?.estimated_value ?? 0}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            suffix={" €"}
        />
      </TableDetail>

      <TableDetail>
          {
              (element.last_contact_date && checkDate(element.last_contact_date) !== false) && (
                  <AnimatedPing color={checkDate(element.last_contact_date)} extraClass={'-ml-5'} style={{marginTop: '4.1px'}} />
              )
          }
          {getCustomFormat(element.last_contact_date, 'DD/MM/YYYY')}
      </TableDetail>

      <TableDetail>
        <div className="flex items-center space-x-2">
            <div className={"flex items-center gap-1 text-sm"}>
                <UserInitial user={element.creator} />
                <div className="text-left ml-1">

                    <div className="truncate text-sm text-gray-900">
                        {element.creator.fullname ?? ''}
                    </div>
                    <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                        {t("app.quotations.created_on", {date: getCustomFormat(element.lead_date, 'DD/MM/YYYY')})}
                    </div>
                </div>
            </div>
        </div>
       </TableDetail>




      <TableDetail>
        <ActionsMenu>
          {(extras.canView) && (
              <Link to={getWebPathParam([SALES, LEAD, element.id, LEAD_OVERVIEW])}>
                {t("app.common.overview")}
              </Link>
          )}
          {extras.canRemove && (
              <button className={'text-left'} onClick={() => {  extras.onRemove(true, removeDetail);}}>
                  {t("app.common.remove")}
              </button>
          )}
        </ActionsMenu>
      </TableDetail>

    </tr>
  );
};




export default Leads;
