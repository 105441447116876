import React from "react";
import BoxIcon from "../../../resources/icons/BoxIcon";

const ProductServiceCell = ({ product }) => {


    return (
    <div className="flex items-center ">

        <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-12 w-12 rounded-md bg-white mr-3'}>
                <span className="font-medium leading-none text-white">
                    <BoxIcon className={'h-5 w-5 text-blue-1000'} />
                </span>
            </span>

        <div className="text-left">
            <div className="text-sm text-gray-900">
                {product?.name ?? ''}
            </div>
        </div>

    </div>
  );
};

export default ProductServiceCell;
