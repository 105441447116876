import React, {useEffect, useState} from 'react'
import FormPopup from "../../../../../../components/common/popup/form-popup";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../../../../../../components/common/form/input-formatted-number";
import {Warning} from "../../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../../components/common/form/input-submit";
import {useTranslation} from "react-i18next";
import {putContainerProduct} from "../../../../../../api/container";
import { useParams } from "react-router-dom";
import ProductCard from "../../../../../../components/common/card/product-card";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";

const ProductEdit = ({isOpen, setIsOpen, detail, setDetail}) => {

    const {handleSubmit, control, setValue} = useForm();
    const { t } = useTranslation();
    const [isProductLoading, setProductLoading] = useState(false);
    const [productException, setProductException] = useState(false);
    const { company } = useSelector((state) => state.userCompany);

    let {id, container_id}  = useParams();


    const updateProduct = async (data) => {
        setProductLoading(true);
        putContainerProduct({ shipping_id: id, container_id: container_id, container_product_id: detail.id, data })
            .then((response) => {
                //console.log(response);
                toast.success(t("app.products.update_success"))
                setProductLoading(false);
                setDetail(response);
                setIsOpen(false);
            })
            .catch((e) => {
                setProductException(e.message);
                setProductLoading(false);
            });
    };

    const onSubmit = async (data) => {
        updateProduct(data);
    };

    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            setValue('base_volume', detail.base_volume);
            setValue('base_gross_weight', detail.base_gross_weight);
            setValue('base_net_weight', detail.base_net_weight);
            setValue('base_price', detail.base_price);
            setValue('quantity_box', detail.quantity_box);
            setValue('quantity', detail.quantity);

            if(detail.length){
                setValue('length', detail.length);
            }

            if(detail.width){
                setValue('width', detail.width);
            }

            if(detail.height){
                setValue('height', detail.height);
            }

            if(detail.radius){
                setValue('radius', detail.radius);
            }

        }
    }, [setValue, detail]);



    return (
        <FormPopup
            title={t("app.container.edit_product")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >

            <div className="grid grid-cols-2 gap-6 mb-4">


                <div className="col-span-2 p-2 border flex justify-between ">
                    <ProductCard
                        isSelected={true}
                        isList={false}
                        product={detail.product}
                        stock={detail.stock}
                        onSelect={(e) => {
                            e.preventDefault();
                            // setSelected(null);
                        }}
                    />
                </div>


                <form className="col-span-2 mt-3" onSubmit={handleSubmit(onSubmit)}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                        <Controller
                            name="quantity"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={""}
                                    suffix={""}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="quantity_box"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity_box")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={""}
                                    suffix={""}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="base_price"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputFormattedNumber
                                    label={t("app.container.price")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={""}
                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="base_volume"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.volume")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' m³'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="base_gross_weight"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.gross_weight")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' Kg'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="base_net_weight"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.net_weight")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' Kg'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="length"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.length")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="width"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.width")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="height"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.height")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                    </div>


                    {/* Warning */}
                    {productException && <Warning message={productException} />}

                    <div className="flex before:flex-1  items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isProductLoading}
                            label={t("app.container.update_product")}
                        />
                    </div>
                </form>
            </div>


        </FormPopup>
    );
};

export default ProductEdit;
