import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import BaseList from "../../../../../components/partials/common/base-list";
import {getPathParam, getSelectParam, getSelectParams} from "../../../../../utils/converter";
import {
    COMPANY,
    PERMISSION_GROUPS,
    PERMISSION_GROUP
} from "../../../../../api/endpoints";
import ActionsMenu from "../../../../../components/common/table/actions-menu";
import CardDetail from "../../../../../components/common/list/card-detail";
import TableDetail from "../../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../../components/common/form/input-text";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import {LockClosedIcon} from "@heroicons/react/outline";
import CompanyPermission from "./company-permission";
import InputSelect from "../../../../../components/common/form/input-select";
import {fetchCustomers, fetchSuppliers} from "../../../../../reducers/connectionReducer";
import InputToggle from "../../../../../components/common/form/input-toggle";
import {TrashIcon} from "@heroicons/react/outline/esm";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../../constants/permissions";
import BasePermissionWrapper from "../../../../../components/partials/restricted/base-permission-wrapper";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CompanyPermissionGroup = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const [isOpenConfigure, setOpenConfigure] = useState(false);
    const [groupDetail, setGroupDetail] = useState(false);


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_PERMISSIONS)]} renderBaseOnFail={true} >

        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.permissions.perm_name"),
                            t("app.permissions.perm_description"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUPS])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, ':id'])}
                        baseResult={{total_rows: 0, user_groups: []}}
                        resultLabel={'user_groups'}
                        title={t("app.permissions.category_title")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddFrom}
                        icon={LockClosedIcon}
                        addFormLabel={t("app.permissions.add_name")}
                        btnAddLabel={t("app.permissions.add_name")}
                        editForm={EditForm}
                        editTitle={t("app.permissions.edit_title")}
                        isActiveEdit={true}
                        onConfigure={(r) => {
                            setGroupDetail(r);
                            setOpenConfigure(true);
                        }}

                    />
                )
            }


            <CompanyPermission
                groupDetail={groupDetail}
                isOpen={isOpenConfigure}
                setIsOpen={setOpenConfigure}
                onClose={() => {
                    setGroupDetail(false);
                }}

            />

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.permissions.permission_remove_title"),
        message: t("app.permissions.permission_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onConfigure(element);
                        }}
                        >
                            {t("app.common.configure")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.permissions.perm_name")}>
                        {element.group_name}
                    </CardDetail>

                    <CardDetail title={t("app.permissions.perm_description")}>
                        {element.description}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.permissions.permission_remove_title"),
        message: t("app.permissions.permission_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.group_name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.description}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onConfigure(element);
                    }}
                    >
                        {t("app.common.configure")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddFrom = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control
    } = useForm();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { loggedCompany, companyType } = useSelector((state) => state.user);

    const {customers, isLoadingCustomers, suppliers, isLoadingSuppliers} = useSelector((state) => state.connection);
    const [activeGroup, setActiveGroup] = useState(companyType);


    const onSubmit = async (data) => {

        if(data.limited){
            if(data.connections.length > 0){
                let connection_ids = [];

                // eslint-disable-next-line
                data.connections.map(data => {
                    connection_ids.push(data.id);
                });

                data['connection_type'] = activeGroup;
                data['connection_ids'] = connection_ids;
            }
        }



        onSubmitData(data);
    };

    useEffect(() => {
        dispatch(fetchCustomers({company_id: loggedCompany.id}));
        dispatch(fetchSuppliers({company_id: loggedCompany.id}));
    }, [dispatch, loggedCompany]);


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"group_name"}
                        type={"text"}
                        errors={errors.group_name}
                        input={{ ...register("group_name", { required: true }) }}
                        label={t("app.permissions.perm_name")}
                    />

                    <InputText
                        id={"description"}
                        type={"text"}
                        errors={errors.description}
                        input={{ ...register("description", { required: false }) }}
                        label={t("app.permissions.perm_description")}
                    />


                    <div className={"md:col-span-2 mt-2"}>
                        <Controller
                            name="limited"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputToggle
                                    label={t("app.permissions.group_add_title")}
                                    description={t("app.permissions.group_add_description")}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    defaultChecked={value}
                                    errors={error}
                                />
                            )}
                        />
                    </div>


                </div>



                {
                    watch("limited") && (
                        <>
                            <div className="grid grid-cols-2  mb-6 flex space-x-1 bg-solitude-400 p-1 items-center text-center">

                                <div
                                    onClick={() => {
                                        setActiveGroup('exporter');
                                        setValue('connections', null);
                                    }}
                                    className={
                                        classNames(
                                            'rounded-sm py-1 text-xs font-medium  leading-5 cursor-pointer px-2 uppercase',
                                            activeGroup === 'exporter'
                                                ? 'bg-white shadow text-blue-1000'
                                                : 'text-blue-1000 hover:bg-white hover:text-blue-1000'
                                        )
                                    }
                                >
                                    {t("app.permissions.customer")}
                                </div>


                                <div
                                    onClick={() => {
                                        setActiveGroup('importer');
                                        setValue('connections', null);
                                    }}
                                    className={
                                        classNames(
                                            'rounded-sm py-1 text-xs font-medium  leading-5 cursor-pointer px-2 uppercase',
                                            activeGroup === 'importer'
                                                ? 'bg-white shadow text-blue-1000'
                                                : 'text-blue-1000 hover:bg-white hover:text-blue-1000'
                                        )
                                    }
                                >
                                    {t("app.permissions.supplier")}
                                </div>
                            </div>


                            <Controller
                                name="connections"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                             field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={activeGroup === 'exporter' ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                        name={name}
                                        options={activeGroup === 'exporter' ?
                                            getSelectParams(customers, "business_name") :
                                            getSelectParams(suppliers, "business_name")
                                        }
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isMulti={true}
                                        isLoading={activeGroup === 'exporter' ? isLoadingCustomers : isLoadingSuppliers}
                                        isDisabled={activeGroup === 'exporter' ? isLoadingCustomers : isLoadingSuppliers}
                                        errors={error}
                                    />
                                )}
                            />
                        </>
                    )
                }









            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.permissions.add_name")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);
    const {customers, isLoadingCustomers, suppliers, isLoadingSuppliers} = useSelector((state) => state.connection);
    const [removeConnections, setRemoveConnections] = useState([]);


    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object") {
                    setValue(k, getSelectParam(data[k], "name"));
                }else{
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (info) => {


        if(info.connections && info.connections.length > 0){
            let connection_ids = [];

            // eslint-disable-next-line
            info.connections.map(data => {
                // eslint-disable-next-line
                connection_ids.push(data.id);
            });
            info['connection_ids_to_add'] = connection_ids;
        }

        if(removeConnections.length > 0){
            info['connection_ids_to_remove'] = removeConnections;
            setRemoveConnections([]);
        }
        onSubmitData(info);
    };


    useEffect(() => {
        dispatch(fetchCustomers({company_id: loggedCompany.id}));
        dispatch(fetchSuppliers({company_id: loggedCompany.id}));
    }, [dispatch, loggedCompany]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-sm font-medium text-gray-900">
                        {t("app.permissions.information")}
                    </h3>


                    <div className="space-y-4 pt-4 pb-5">
                        <InputText
                            id={"group_name"}
                            type={"text"}
                            errors={errors.group_name}
                            input={{ ...register("group_name", { required: true }) }}
                            label={t("app.permissions.perm_name")}
                        />

                        <InputText
                            id={"description"}
                            type={"text"}
                            errors={errors.description}
                            input={{ ...register("description", { required: false }) }}
                            label={t("app.permissions.perm_description")}
                        />


                        {
                            data.connection_type && (
                                <>
                                    <h3 className="mt-4 text-sm font-medium text-gray-900">
                                        {data.connection_type === 'exporter' ? t("app.permissions.manage_customer") : t("app.permissions.manage_supplier") }
                                    </h3>


                                    <Controller
                                        name="connections"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                                     field: { onChange, value, name },
                                                     fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={data.connection_type === 'exporter' ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                                name={name}
                                                options={data.connection_type === 'exporter' ?
                                                    getSelectParams(customers.filter(
                                                        (c) =>
                                                            !data.company_user_connections
                                                                .map((c) => c.connection.id)
                                                                .includes(c.id)
                                                    ), "business_name") :
                                                    getSelectParams(suppliers.filter(
                                                        (c) =>
                                                            !data.company_user_connections
                                                                .map((c) => c.connection.id)
                                                                .includes(c.id)
                                                    ), "business_name")
                                                }
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                isMulti={true}
                                                isLoading={data.connection_type === 'exporter' ? isLoadingCustomers : isLoadingSuppliers}
                                                isDisabled={data.connection_type === 'exporter' ? isLoadingCustomers : isLoadingSuppliers}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <div className=" bg-white  rounded-sm border border-slate-200">
                                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                                            {data.company_user_connections.filter(
                                                (c) =>
                                                    !removeConnections
                                                        .map((c) => c)
                                                        .includes(c.connection.id)
                                            ).map((connection) => (
                                                <ul key={connection.id} className="relative z-0 divide-y divide-gray-200">
                                                    <li className=" px-2 bg-white hover:bg-gray-50">
                                                        <div className="py-2 flex items-center justify-between space-x-3">
                                                            <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                                <div className="min-w-0 flex-1">
                                                                    <p className="text-sm font-medium text-gray-900 truncate">
                                                                        {connection.connection.company_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="space-x-3 flex md:mt-0 md:ml-4">
                                                                <button
                                                                    onClick={() => {
                                                                        setRemoveConnections([...removeConnections, connection.connection.id])
                                                                    }}
                                                                    className={
                                                                        "w-full btn bg-red-500 hover:bg-red-700 "
                                                                    }
                                                                >
                                                                    <TrashIcon
                                                                        className="h-4 w-4 text-white"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            ))}
                                        </nav>
                                    </div>
                                </>
                            )
                        }











                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>
            </form>

        </>
    );
}


export default CompanyPermissionGroup;
