import {SHIPPING, REPORT, REPORT_TYPES, DOCUMENT_DOWNLAOD, COMPANY, REPORT_TYPE} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// file
export const putImport = ({shipping_id, report_type, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, REPORT, report_type]), data);

export const getReport = ({shipping_id, report_type, params, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, REPORT, report_type]) + (params ? serialize(params) : ''), {signal: signal} );

export const getReportTypes = ({params, signal}) =>
    API.get(getPathParam([REPORT_TYPES]) + (params ? serialize(params) : ''), signal && {signal: signal} );

export const getCompanyReportTypes = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, REPORT_TYPES]) + (params ? serialize(params) : ''), signal && {signal: signal} );

export const getCompanyReportType = ({company_id, code, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, REPORT_TYPE, code]) + (params ? serialize(params) : ''), signal && {signal: signal} );

// file
export const putDownloadDocument = ({company_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, DOCUMENT_DOWNLAOD]), data);



export const putDynamic = ({report_type, entity, entity_id, data}) =>
    API.put(getPathParam([REPORT, report_type, entity, entity_id]), data);

export const getDynamicReport = ({report_type, entity, entity_id, params, signal = null}) =>
    API.get(getPathParam([REPORT, report_type, entity, entity_id]) + (params ? serialize(params) : ''), (signal && {signal: signal}) );
