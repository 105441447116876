import React from "react";
import HeaderProfiling from "../../components/layout/header-profiling";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const OnboardingIndex = ({children}) => {

    return (
        <main className="bg-white">

            <div className="relative md:flex">

                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-screen h-full flex flex-col after:flex-1">

                        {/* Header */}
                        <HeaderProfiling isActiveLogout={true} />
                        {children}
                    </div>
                </div>

                {/* Image */}
                <SidebarProfiling />

            </div>

        </main>
    );
};

export default OnboardingIndex;
