import React from "react";

const CardDetail = ({title, children, extraclass= ''}) => {
    return (
        <div className={"py-1 flex justify-between text-sm font-medium "+extraclass}>
            <dt className="text-gray-700">{title}</dt>
            <dd className="text-gray-900">
                {children}
            </dd>
        </div>
    );
};
export default CardDetail;
