// welcome
export const INDEX = "/";

// oauth
export const LOGIN = "/login";
export const REGISTER = "/register";
export const COMPANY_REGISTER_CONNECTION = "/company-register-connection";
export const REGISTER_CONNECTION = "/register-connection";
export const RESET_PASSWORD = "/reset-password";
export const VERIFY_REGISTRATION = "/verify-registration";

// Onboarding
export const ONBOARDING = "/onboarding";
export const ONBOARDING_COMPANY_TYPE = "/company-type";
export const ONBOARDING_ADD_COMPANY = "/add-company";
export const ONBOARDING_DONE = "/confirm";

// welcome
export const DASHBOARD = "/";

// shipping
export const SHIPPINGS = "/shippings";
export const SHIPPING = "/shipping";

export const FREIGHTS = "/freights";
export const FREIGHT = "/freight";


//container
export const CONTAINERS = "/containers";
export const CONTAINER = "/container";
export const CONTAINER_ID = "/:container_id";
export const CONTAINER_GENERAL_INFORMATION = "/general-information";
export const CONTAINER_GALLERY = "/gallery";
export const CONTAINER_LOAD_CONFIGURATION = "/load-configuration";
export const PACKING_LIST = "/packing-list";
export const CONFIRM_LOAD = "/confirm_load";

export const SHIPPING_OVERVIEW = "/overview";
export const SHIPPING_DETAIL = "/shipping-detail";
export const DOCUMENTS = "/documents";
export const EVENTS = "/events";
export const TRACKING = "/tracking";


export const C_SHIPPING_DETAIL = "shipping-detail";
export const C_DOCUMENTS = "documents";
export const C_EVENTS = "events";
export const C_TRACKING = "tracking";
export const C_CONTAINERS = "containers";
export const C_PRODUCTS = "products";
export const C_CONTAINER_GENERAL_INFORMATION = "general-information";
export const C_CONTAINER_GALLERY = "gallery";
export const C_CONTAINER_LOAD_CONFIGURATION = "load-configuration";
export const C_PACKING_LIST = "packing-list";
export const C_DIMENSIONS = "dimensions";
export const C_TRANSLATION = "translation";
export const C_PRODUCT_DETAIL = "details";
export const PRODUCT_DETAIL = "/details";

export const C_PRODUCT_MEDIA = "media";
export const PRODUCT_MEDIA = "/media";


export const C_PRODUCT_ALTERNATIVE = "alternative";
export const PRODUCT_ALTERNATIVE = "/alternative";


// customer
export const CUSTOMERS = "/customers";
export const CUSTOMER = "/customer";

// Forwarders
export const FORWARDERS = "/forwarders";
export const FORWARDER = "/forwarder";
export const FORWARDER_CONTACT = "/forwarder_contact";
export const C_FORWARDER_CONTACT = "forwarder_contact";
export const FORWARDER_LINES = "/forwarder_line";
export const C_FORWARDER_LINES = "forwarder_line";


// Supplier
export const TASKS = "/tasks";
export const TASK = "/task";
export const RELETED_TASK = "/related_task";
export const ACTIVITIES = "/activities";
export const ACTIVITY = "/activity";
export const RELETED_ENTITY = "/related_entity";
export const RELETED_ENTITIES = "/related_entities";
export const SEND_NOTIFICATION = "/send_notification";


// Supplier
export const SUPPLIERS = "/suppliers";
export const SUPPLIER = "/supplier";

// order
export const ORDERS = "/orders";
export const ORDER = "/order";

// order
export const QUOTATIONS = "/quotations";
export const QUOTATION = "/quotation";
export const QUOTATION_OVERVIEW = "/overview";
export const LEADS = "/leads";
export const LEAD = "/lead";
export const LEAD_OVERVIEW = "/connec_overview";
export const CONNECTION_OVERVIEW = "/connec_overview";
export const C_CONNECTION_OVERVIEW = "connec_overview";
export const C_CONNECTION_NOTES = "connec_notes";
export const C_CONNECTION_ADDRESSES = "connec_addresses";
export const C_CONNECTION_ATTACHMENTS = "connec_attachments";
export const C_CONNECTION_TASKS = "connec_tasks";
export const C_CONNECTION_LOGS = "connec_logs";
export const SALES = "/sales";
export const LOGISTICS = "/logistics";
export const FULFILLMENTS = "/fulfillments";
export const FULFILLMENT = "/fulfillment";
export const BOOKINGS = "/bookings";
export const BOOKING = "/booking";
export const FORWARDERS_LINE = "/forwarders_line";



// user
export const USERS = "/users";
export const USER = "/user";
export const USER_DETAIL = "detail";
export const USER_PERMISSION = "permission";
export const USER_CUSTOMERS = "user_customers";

export const ORDER_OVERVIEW = "/overview";
export const CATALOG = "/catalog";
export const REGISTRY = "/registry";
export const PRODUCTS = "/products";
export const HIDE_PRODUCTS = "/hide_products";
export const SHOW_PRODUCTS = "/show_products";
export const PRODUCT = "/product";
export const ARCHIVE = "/archive";
export const CATEGORIES = "/categories";
export const CATEGORY = "/category";
export const DIMENSIONS = "/dimensions";
export const TRANSLATION = "/translation";
export const PRICING = "/pricing";
export const C_PRICING = "pricing";
export const PRODUCT_SUPPLIERS = "/pro-sups";
export const C_PRODUCT_SUPPLIERS= "pro-sups";
export const PRODUCT_LOG = "/pro-log";
export const C_PRODUCT_LOG= "pro-log";
export const STATUS = "status";


export const BOARDS = "/boards";
export const BOARD = "/board";
export const STATUSES = "/statuses";
export const STATUS_POSITION = "/status_position";
export const TASK_POSITION = "/task_position";
export const NOTIFICATION_SETTINGS = "/notification_setting";




export const PRODUCT_SETTINGS = "/settings";
export const C_CATEGORIES = "categories";
export const C_CATEGORY = "category";
export const C_SUB_CATEGORIES = "/sub_categories";
export const BRANDS = "/brands";
export const C_BRANDS = "brands";
export const COMPANY_SUPPLIERS = "/comp-sups";
export const C_COMPANY_SUPPLIERS = "comp-sups";
export const MANUFACTURE = "/comp-manu";
export const C_MANUFACTURE = "comp-manu";
export const ATTRIBUTE = "/comp-attribute";
export const C_ATTRIBUTE = "comp-attribute";
export const ENCODING = "/comp-encoding";
export const C_ENCODING = "comp-encoding";


export const VERIFY_CONNECTION = "/supplier/verify";
export const COMPANY_VERIFY_CONNECTION = "/customer/verify";
export const USER_VERIFY_CONNECTION = "/connection/verify";

export const ACCOUNT = "/account";
export const INFORMATION = "/information";
export const BILLING_INFORMATION = "/billing_information";

export const COMPANIES = "/companies";
export const COMPANY = "/company";
export const COMPANY_DETAIL = "/company_detail";
export const SETTINGS = "/settings";
export const COMPANY_TASKS = "/company_tasks";

export const CONNECTIONS = "/connections";
export const CONNECTION = "/connection";

export const PASSWORD = "/password";
export const NOTIFICATION = "/notification";

export const CONFIGURATION = "/config";
export const IMPORT_MODELS = "import-models";
export const WAREHOUSE = "warehouse";
export const COMPANY_PERMISSION = "company-permissions";
export const CONNECTION_CATEGORIES = "connection-categories";
export const EMAIL_INTEGRATION = "email-integration";
export const EMAIL_TEMPLATE = "email-template";
export const COMPANY_PREFERENCES = "company-preferences";
export const WEBHOOKS = "webhooks";
export const DOCUMENT_TEMPLATE = "document_template";



// profile
export const PROFILE_SETTINGS = "/profile_information";
export const PROFILE_CONNECTION = "/profile_connection";
export const C_PROFILE_SETTINGS = "profile_information";
export const C_PROFILE_CONNECTION = "profile_connection";
export const C_BILLING_INFORMATION = "billing_information";
export const SUBSCRIPTION = "/profile_subscription";
export const C_SUBSCRIPTION = "profile_subscription";
export const C_USER_SUBSCRIPTION = "profile_active_subscription";
export const USER_SUBSCRIPTION = "/profile_active_subscription";
export const SUBSCRIPTION_COMPLATE = "/profile_subscription_confirmed";


export const P404 = "/404";


// Params
export const ID = "/:id";
