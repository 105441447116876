import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PageHeader, { ActionButton } from "../../components/layout/page-header";
import { useSelector } from "react-redux";
import ActionsMenu from "../../components/common/table/actions-menu";
import {COMPANY, FORWARDER, FORWARDER_CONTACT, REGISTRY} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import AddForwarder from "./add-forwarder";
import { useTranslation } from "react-i18next";
import { getForwarders } from "../../api/forwarder";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import ListSection from "../../components/layout/list-section";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import OptionalLink from "../../components/common/table/optional-link";
import RemovePopup from "../../components/common/popup/remove-popup";
import { PlusIcon } from "@heroicons/react/outline";
import Initials from "../../components/common/avatar/initials";

const Forwarders = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);

  const canView =  hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
  const canEdit =  hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.EDIT);
  const canDelete =  hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.DELETE);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.CREATE);


  const [isFetchForwardersLoading, setFetchForwardersLoading] = useState(true);
  const [forwardersList, setForwardersList] = useState({
    forwarders: [],
    total_rows: 0,
  });

  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});
  const [needRefresh, setNeedRefresh] = useState(false);


  const { t } = useTranslation();

  const columns = [
    t("app.forwarder.business_name"),
    t("app.forwarder.reference"),
    "",
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchForwarders() {
      if (loggedCompany) {
        setFetchForwardersLoading(true);
        try {
          const res = await getForwarders({
            company_id: loggedCompany.id,
            params: { limit: 10, offset: query.length > 0 ? 0 : offset, query: query },
            signal,
          });
          setForwardersList(res);
        } catch (e) {
          setForwardersList({ forwarders: [], total_rows: 0 });
        } finally {
          setFetchForwardersLoading(false);
        }
      }
    }

    fetchForwarders();
    return () => {
      controller.abort();
    };
  }, [offset, query, loggedCompany, needRefresh]);


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.LIST)]} renderBaseOnFail={true}>


      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.forwarder.forwarders")}>

            {
              canCreate && (
                    <ActionButton
                        icon={
                          <PlusIcon
                              className="w-4 h-4"
                          />
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        text={t("app.forwarder.new_forwarder")}
                    />
                )
            }

          </PageHeader>

        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <ListSection
            title={t("app.forwarder.forwarders")}
            columns={columns}
            totalRows={forwardersList.total_rows}
            list={forwardersList?.forwarders ?? []}
            onSearch={setQuery}
            isLoading={isFetchForwardersLoading}
            paginationOnChange={setOffset}
            mobileRow={MobRow}
            desktopRow={DeskRow}
            extras={{
              canView,
              canDelete,
              canCreate,
              canEdit,
              onRemove: (r, detail) => {
                setOpenRemove(r);
                setRemoveDetail(detail);
              }
            }}
          />
        </div>
      </div>


      {(isOpen && canCreate) && <AddForwarder isOpen={isOpen} setIsOpen={setIsOpen} />}


      {isOpenRemove && canDelete && (
          <RemovePopup
              isOpen={isOpenRemove}
              setIsOpen={setOpenRemove}
              detail={removeDetail}
              onRemoveConfirmed={() => {
                setNeedRefresh(!needRefresh);
              }}
          />
      )}

    </BasePermissionWrapper>
  );
};

const MobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
    title: t("app.forwarder.remove_title"),
    message: t("app.forwarder.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, FORWARDER, element.id]),
  };

  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={extras.canEdit || extras.canView}
        to={getWebPathParam([REGISTRY, FORWARDER, element.id, FORWARDER_CONTACT])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
          <span className="flex items-center">
            <Initials
              color={element?.color ?? ''}
              name={element?.name ?? ''}
              isVerified={false}
            />
            <span className="flex min-w-0 text-left flex-1 flex-col">
              <span className="truncate text-sm font-medium whitespace-pre-line text-gray-900">{element?.name ?? ''}</span>
            </span>
          </span>
          <ActionsMenu>
            {(extras.canEdit || extras.canView) && (
              <Link to={getWebPathParam([REGISTRY, FORWARDER, element.id, FORWARDER_CONTACT])}>
                  {t("app.common.edit")}
                </Link>
            )}

            {extras.canDelete && (
                <button className={'text-left'} onClick={() => {
                  extras.onRemove(true, removeDetail);
                }}
                >
                  {t("app.common.remove")}
                </button>
            )}
          </ActionsMenu>

        </div>
        <div className="flex flex-col gap-2 text-gray-500 text-sm truncate">
          <div className="flex gap-1 justify-between">
            {t("app.forwarder.reference")}{" "}
            <span className="font-bold">{element.code}</span>
          </div>
        </div>
      </OptionalLink>
    </li>
  );
};

const DeskRow = ({ element, extras }) => {
  const { t } = useTranslation();

  const { loggedCompany } = useSelector((s) => s.user);

  const removeDetail = {
    title: t("app.forwarder.remove_title"),
    message: t("app.forwarder.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, FORWARDER, element.id]),
  };

  return (
    <tr key={element.id}>
      <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <OptionalLink
              isLinked={extras.canEdit || extras.canView}
            to={getWebPathParam([REGISTRY, FORWARDER, element.id, FORWARDER_CONTACT])}
            className="truncate hover:text-gray-600"
          >
            <span className="flex items-center">
              <Initials
                color={element?.color ?? ''}
                name={element?.name ?? ''}
                isVerified={false}
              />
              <span className="flex min-w-0 text-left flex-1 flex-col">
                <span className="truncate text-sm font-medium whitespace-pre-line text-gray-900">{element?.name ?? ''}</span>
              </span>
            </span>
          </OptionalLink>
        </div>
      </td>
      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {element.code}
      </td>
      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
        <ActionsMenu>
          {(extras.canEdit || extras.canView) && (
            <Link to={getWebPathParam([REGISTRY, FORWARDER, element.id, FORWARDER_CONTACT])}>
                {t("app.common.edit")}
              </Link>
          )}

          {extras.canDelete && (
              <button className={'text-left'} onClick={() => {
                extras.onRemove(true, removeDetail);
              }}
              >
                {t("app.common.remove")}
              </button>
          )}
        </ActionsMenu>

      </td>
    </tr>
  );
};

export default Forwarders;
