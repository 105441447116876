import React from "react";
import { Draggable } from "react-beautiful-dnd";
import QuoteList from "./styles/list";
import { PlusIcon } from "@heroicons/react/outline";
import LoaderWrapper from "../common/loader/loader-wrapper";


function addNewLine(stringa, len) {
    let risultato = '';
    let parole = stringa.split(' ');
    let rigaCorrente = '';

    for (let i = 0; i < parole.length; i++) {
        if (rigaCorrente.length + parole[i].length + 1 > len) {
            risultato += rigaCorrente.trim() + '\n';
            rigaCorrente = '';
        }
        rigaCorrente += parole[i] + ' ';
    }

    risultato += rigaCorrente.trim();

    return risultato;
}

const Container = React.forwardRef(({ children, ...props }, ref) => (
    <div
        ref={ref}
        className={`self-start shrink-0 block h-full   whitespace-nowrap px-1.5 py-0`}
        {...props}
    >
        <div className="bg-solitude-400  rounded-md shadow-md  flex justify-between  flex-col grow-0 shrink-0 basis-[272px] relative whitespace-normal w-[272px] box-border align-top pb-2 scroll-m-2"> 
        {children}
        </div>
    </div>
));

const Header = ({ children, columnData,  ...props }) => {

    const options = [
        {
            color: "gray",
            bg: "bg-gray-600",
        },
        {
            color: "red",
            bg: "bg-red-600",
        },
        {
            color: "yellow",
            bg: "bg-yellow-600",
        },
        {
            color: "green",
            bg: "bg-green-600",
        },
        {
            color: "blue",
            bg: "bg-blue-600",
        },
        {
            color: "indigo",
            bg: "bg-indigo-600",
        },
        {
            color: "purple",
            bg: "bg-purple-600",
        },
        {
            color: "pink",
            bg: "bg-pink-600",
        }];

    return (
        <div
            className={`gap-y-0  relative  grow-0  items-start flex-wrap mx-1  py-1 px-2`}
            {...props}
        >

            <div className="flex items-center justify-between ">
                <div className=" my-2 ">
                    {
                        columnData.color ? (
                            <span className={`block truncate flex items-center`}>
                                <div className={"h-4 w-4 rounded-full mr-3 " + (options?.find(r => r.color === columnData.color)?.bg ?? '')} />
                                <div className='text-blue-1000 font-semibold whitespace-pre-line' style={{ fontSize: '14px' }}>
                                    {addNewLine(columnData?.title ?? '', 45)}
                                </div>
                            </span>
                        ) : (
                                <div className='text-blue-1000 font-semibold whitespace-pre-line' style={{ fontSize: '14px' }}>
                                {addNewLine(columnData?.title ?? '', 45)}
                            </div>
                        )
                    }
                </div>
                
                
                {
                    (props.isAdmin || props.isMember) && (
                        <div className="cursor-pointer" onClick={() => {
                            props.onAddTask(true)
                        }}>
                            <PlusIcon className="w-4 h-4 ml-4 text-gray-900 group-hover:text-gray-500" />
                        </div>
                    ) 
                }

            </div>

            <div className='text-blue-1000 break-words' style={{ fontSize: '10px' }}> {columnData?.description ?? ''}</div>

           
            
        </div>
    );
};


const Column = (props) => {
    const { title, taskes, columnId, index, isScrollable, isCombineEnabled, isLoadingTaskes, columnData } = props;
    return (
        <Draggable draggableId={columnId} index={index} isDragDisabled={!(props.isAdmin || props.isMember)} canDragInteractiveElements={false}>
            {(provided, snapshot) => (
                <Container ref={provided.innerRef} {...provided.draggableProps}>
                    <Header 
                        columnData={columnData}
                        isAdmin={props.isAdmin}
                        isMember={props.isMember}
                        isDragging={snapshot.isDragging} {...provided.dragHandleProps} 
                        onAddTask={() => {
                            props.onAddTask()
                        }} 
                    />

                    <LoaderWrapper isLoading={isLoadingTaskes}>
                        <QuoteList
                            listId={columnId}
                            title={title}
                            keyIndex={index}
                            isAdmin={props.isAdmin}
                            isMember={props.isMember}
                            isDragDisabled={!(props.isAdmin || props.isMember)}
                            listType="QUOTE"
                            style={{
                                backgroundColor: snapshot.isDragging ? '#ffffff' : null
                            }}
                            taskes={taskes}
                            {...provided.dragHandleProps}
                            internalScroll={isScrollable}
                            isCombineEnabled={Boolean(isCombineEnabled)}
                            onEditTask={props.onEditTask}
                        />

                    </LoaderWrapper>
                    
                </Container>
            )}
        </Draggable>
    );
};

export default Column;
