import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/layout/page-header";
import { Warning } from "../../../components/common/alert/banner";
import InputText from "../../../components/common/form/input-text";
import InputSelect from "../../../components/common/form/input-select";
import {P404} from "../../endpoints";
import { cleanData, getSelectParam } from "../../../utils/converter";
import noImage from "../../../resources/images/placeholder.jpeg"

import {
  fetchCountries, fetchCurrencies,
  fetchPhonePrefix,
} from "../../../reducers/configReducer";
import Loader from "../../../components/common/loader/loader";
import EditFooter from "../../../components/partials/footer/edit-footer";
import { getCompany, putCompany } from "../../../api/company";
import Upload from "../../../components/partials/upload/upload";
import InputEditor from "../../../components/common/form/input-editor";
import toast from "react-hot-toast";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import InputToggle from "../../../components/common/form/input-toggle";
const CompanyDetail = () => {
  const { isCountriesLoading, countries, isPhonePrefixLoading, prefix, isCurrenciesListLoading, currenciesList } = useSelector((state) => state.config);

  const { detail } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get container
  const [isLoading, setLoading] = useState(true);
  const [companyLogo, setCompanyLogo] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [companyNewLogo, setCompanyNewLogo] = useState(false);

  // put container
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [updateException, setUpdateException] = useState(false);

  const [activeGroup, setActiveGroup] = useState("business_profile");

  const tabs = [
    { name: t("app.company.business_profile"), group: "business_profile" },
    { name: t("app.company.conpany_config"), group: "company_config" },
    { name: t("app.company.document_detail"), group: "document_detail" },
  ];


  const updateCompany = async (data) => {

    if(companyNewLogo !== false){
      data['picture'] = {media_url: companyNewLogo}
    }

    if(removeLogo && companyNewLogo === false){
      data['remove_picture'] = true;
    }

    setUpdateLoading(true);
    putCompany({ company_id: id, data })
      .then((response) => {
        setUpdateLoading(false);
        setUpdateException(false);
        toast.success(t("app.company.update_success"));
        setCompanyNewLogo(false);
        setRemoveLogo(false);
      })
      .catch((e) => {
        setUpdateException(e.message);
        setUpdateLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchCompany() {
      if (detail) {
        setLoading(true);
        getCompany({ company_id: id, signal })
          .then((response) => {

            if(response.picture){
              setCompanyLogo(response.picture.reference)
            }


            for (const k in response) {
              if (typeof response[k] === "object") {
                if (k === "phone_prefix") {
                  setValue(k, getSelectParam(response[k], "prefix"));
                } else {
                  if (k !== "picture"){
                    setValue(k, getSelectParam(response[k], "name"));
                  }
                  if (k === "currency"){
                    setValue('currency', getSelectParam(response[k], "code"));
                  }
                }
              } else {
                setValue(k, response[k]);
              }
            }
            setLoading(false);
          })
          .catch((e) => {
            (e.code === 404 || e.code === 403) && navigate(P404);
            setLoading(false);
          });
      }
    }
    fetchCompany();

    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchPhonePrefix());
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, id, setValue]);

  const onSubmit = async (data) => {
    cleanData(data);
    await updateCompany(data);
  };

  return (
    <>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
          {/* Page header */}
          <PageHeader title={t("app.company.company")} />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


          <UnderlineTab
              marginTop={''}
              tabs={tabs}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
          />

          <section>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="shadow ">
                <div className="bg-white py-6 px-4 sm:p-6">

                  {isLoading ? (
                    <div className="mt-6">
                      <div className="w-1/2 m-auto px-4 py-3">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      {activeGroup === "business_profile" && (

                          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                            <div className="col-span-2">
                              <InputText
                                  id={"business_name"}
                                  type={"text"}
                                  errors={errors.business_name}
                                  input={{
                                    ...register("business_name", { required: true }),
                                  }}
                                  label={t("app.form.business_name")}
                              />
                            </div>

                            <Controller
                                name="phone_prefix"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={t("app.form.phone_prefix")}
                                        name={name}
                                        options={prefix}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        isLoading={isPhonePrefixLoading}
                                        isDisabled={isPhonePrefixLoading}
                                        errors={error}
                                    />
                                )}
                            />

                            <InputText
                                id={"phone"}
                                type={"text"}
                                errors={errors.business_phone}
                                input={{
                                  ...register("business_phone", { required: true }),
                                }}
                                label={t("app.form.phone")}
                            />


                            <InputText
                                id={"address"}
                                type={"text"}
                                label={t("app.customers.importer_address")}
                                errors={errors.address}
                                input={{
                                  ...register("address", {
                                    required: true,
                                  }),
                                }}
                            />



                            <InputText
                                id={"zip_code"}
                                type={"text"}
                                label={t("app.customers.importer_zip_code")}
                                errors={errors.zip_code}
                                input={{
                                  ...register("zip_code", {
                                    required: true,
                                  }),
                                }}
                            />


                            <InputText
                                id={"city"}
                                type={"text"}
                                label={t("app.customers.importer_city")}
                                errors={errors.city}
                                input={{
                                  ...register("city", {
                                    required: true,
                                  }),
                                }}
                            />

                            <Controller
                                name="country"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={t("app.form.country")}
                                        name={name}
                                        options={countries}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        isLoading={isCountriesLoading}
                                        isDisabled={isCountriesLoading}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name="currency"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={t("app.quotations.currency")}
                                        name={name}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        options={currenciesList}
                                        value={value}
                                        isLoading={isCurrenciesListLoading}
                                        isDisabled={isCurrenciesListLoading}
                                        errors={error}
                                    />
                                )}
                            />


                          </div>


                      )}



                        {activeGroup === "company_config" && (

                          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                            <div className="col-span-2">
                                <Controller
                                  name="product_manage_status"
                                  rules={{ required: false }}
                                  control={control}
                                  render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                  }) => (
                                    <InputToggle
                                      label={t("app.company.product_status_title")}
                                      description={t("app.company.product_status_description")}
                                      onChange={(e) => {
                                        onChange(e)
                                      }}
                                      defaultChecked={value}
                                      errors={error}
                                    />
                                  )}
                                />
                              </div>

                            <div className="col-span-2">
                              <Controller
                                name="update_product_code"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                  field: { onChange, value, name },
                                  fieldState: { error },
                                }) => (
                                  <InputToggle
                                    label={t("app.company.product_reference_title")}
                                    description={t("app.company.product_reference_description")}
                                    onChange={(e) => {
                                      onChange(e)
                                    }}
                                    defaultChecked={value}
                                    errors={error}
                                  />
                                )}
                              />
                            </div>
                            </div>
                        )}


                      {activeGroup === "document_detail" && (
                          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                            <div className="col-span-2">
                              <label htmlFor="photo" className="block text-sm font-medium mb-1 text-gray-700">{t("app.company.logo_title")}</label>
                              <div className="flex items-center">
                                <img className="inline-block w-24"
                                     src={companyLogo ? companyLogo : noImage}
                                     alt="" />

                                <div className="ml-4 flex">

                                  <Upload onUpload={r => {
                                    setCompanyLogo(r.reference);
                                    setCompanyNewLogo(r.reference);
                                  }}>

                                    <div
                                        className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                      <label htmlFor="user-photo"
                                             className="relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                                        <span>{t("app.company.change")}</span>
                                        <span className="sr-only"> user photo</span>
                                      </label>
                                    </div>

                                  </Upload>

                                  {
                                      companyLogo && (
                                          <button type="button"
                                                  onClick={() => {
                                                    setRemoveLogo(true);
                                                    setCompanyLogo(false);
                                                  }}
                                                  className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">{t("app.common.remove")}</button>
                                      )
                                  }

                                </div>


                              </div>
                            </div>

                            <div className="col-span-2">
                              <Controller
                                  name="document_description"
                                  rules={{ required: false }}
                                  control={control}
                                  render={({field: { onChange, value, name },
                                             fieldState: { error },
                                           }) => (
                                      <InputEditor
                                          label={t("app.company.document_description")}
                                          errors={error}
                                          defaultValue={value}
                                          name={name}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                      />
                                  )}
                              />
                            </div>

                          </div>
                      )}




                    </div>
                  )}

                  {/* Warning */}
                  {updateException && <Warning message={updateException} />}
                </div>

                {!isLoading && (
                  <EditFooter
                    btnLabel={t("app.form.save")}
                    isLoading={isUpdateLoading}
                  />
                )}
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default CompanyDetail;
