export default function CatalogIcon(props) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.95088 7.24303H1.23088C0.114875 7.24303 -0.318124 6.50103 0.248876 5.51503C0.990876 4.22803 1.73188 2.91503 2.47388 1.63203C3.28088 0.232026 3.10688 0.232026 4.87388 0.232026C8.51688 0.232026 12.1809 0.254026 15.8239 0.232026C16.0997 0.209463 16.3759 0.270101 16.6169 0.406111C16.8579 0.54212 17.0526 0.747255 17.1759 0.995026C18.0049 2.50003 18.8989 3.98303 19.7499 5.48803C20.3169 6.48803 19.9029 7.23303 18.7499 7.23303H17.9869V15.194C18.0188 15.4041 17.9805 15.6189 17.8779 15.805C17.7204 16.0173 17.4852 16.1586 17.2239 16.198C17.0059 16.198 16.7439 15.958 16.5909 15.784C16.5042 15.6085 16.4738 15.4105 16.5039 15.217V7.30903H11.9619V7.78903C11.9619 10.036 11.9619 12.282 11.9399 14.507C11.9399 15.75 11.3949 16.296 10.1509 16.296H3.71488C3.47374 16.326 3.22893 16.3002 2.99936 16.2205C2.76979 16.1409 2.56159 16.0095 2.39083 15.8367C2.22007 15.6638 2.09132 15.454 2.01452 15.2234C1.93772 14.9929 1.91492 14.7478 1.94788 14.507V7.83203C1.95088 7.63603 1.95088 7.46103 1.95088 7.24303ZM18.1149 5.73803C18.0892 5.66217 18.0595 5.58773 18.0259 5.51503C17.3499 4.31503 16.6739 3.11503 15.9759 1.93803C15.9223 1.87344 15.8559 1.8207 15.7808 1.78317C15.7057 1.74564 15.6237 1.72414 15.5399 1.72003C11.8319 1.69803 8.14588 1.72003 4.43988 1.69803C4.34034 1.69007 4.24079 1.71309 4.15486 1.76394C4.06892 1.81478 4.00082 1.89096 3.95988 1.98203C3.41488 2.98203 2.82588 3.96703 2.27988 4.94803C2.14888 5.18803 2.01788 5.42803 1.86588 5.71103C7.31488 5.73803 12.7039 5.73803 18.1149 5.73803ZM10.4349 10.275V7.28703H3.47688V10.275H10.4349ZM3.45488 11.78V14.746H10.4349V11.78H3.45488Z" />
    </svg>
  );
}

CatalogIcon.defaultProps = {
  width: "20",
  height: "20",
};
