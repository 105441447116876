import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getCountries,
    getPhonePrefixes,
    getCurrencies,
    getPorts,
    getDeliveryTypes,
    getTermTypes,
    getShippingLines,
    getDocumentTypes,
    getEventTypes,
    getContainerTypes,
    getNotificationCounter,
    getPackagingTypes,
    getNotificationTypes, getShippingTypes, getLanguages, getVessels, getCities, getRegions, getUnits, getWebhookEvents
} from "../api/config";
import {
    getPortParams,
    getSelectParams,
    getVesselParams,
    getDynamicParams,
    getShippingTypeParams
} from "../utils/converter";


const initialState = {
    countries: [],
    cities: [],
    regions: [],
    languages: [],
    prefix: [],
    vesselList: [],
    portList: [],
    currenciesList: [],
    deliveryTypesList: [],
    termTypesList: [],
    shippingLinesList: [],
    documentTypesList: [],
    shippingTypesList: [],
    eventTypesList: [],
    containerTypesList: [],
    packagingTypesList: [],
    notificationTypesList: [],
    unitList: [],
    webhookList: [],
    notificationCounter: 0,
};



/***************
 * ASYNC THUNK *
 ***************/

export const country = createAsyncThunk("config/country", async (data) => {
    try {
        return {
            countries: await getCountries(data),
        };
    } catch (e) {
        throw e;
    }
});


export const unit = createAsyncThunk("config/unit", async (data) => {
    try {
        return {
            unitList: await getUnits(data),
        };
    } catch (e) {
        throw e;
    }
});
export const webhookEvents = createAsyncThunk("config/webhook_events", async (data) => {
    try {
        return {
            webhookList: await getWebhookEvents(data),
        };
    } catch (e) {
        throw e;
    }
});



export const city = createAsyncThunk("config/city", async () => {
    try {
        return {
            cities: await getCities({params: null, signal: false}),
        };
    } catch (e) {
        throw e;
    }
});


export const region = createAsyncThunk("config/region", async () => {
    try {
        return {
            regions: await getRegions({ params: null, signal: false }),
        };
    } catch (e) {
        throw e;
    }
});

export const language = createAsyncThunk("config/language", async (data) => {
    try {
        return {
            languages: await getLanguages(data),
        };
    } catch (e) {
        throw e;
    }
});

export const phonePrefix = createAsyncThunk("config/phone_prefix", async (data) => {
    try {
        return {
            prefix: await getPhonePrefixes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const currencies = createAsyncThunk("config/currencies", async (data) => {
    try {
        return {
            currenciesList: await getCurrencies(data),
        };
    } catch (e) {
        throw e;
    }
});

export const vessels = createAsyncThunk("config/vessels", async () => {
    try {
        return {
            vesselList: await getVessels({params: null, signal: null}),
        };
    } catch (e) {
        throw e;
    }
});

export const ports = createAsyncThunk("config/ports", async () => {
    try {
        return {
            portList: await getPorts({params: null, signal: null}),
        };
    } catch (e) {
        throw e;
    }
});

export const deliveryTypes = createAsyncThunk("config/deliveryTypes", async (data) => {
    try {
        return {
            deliveryTypesList: await getDeliveryTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const shippingTypes = createAsyncThunk("config/shippingTypes", async (data) => {
    try {
        return {
            shippingTypesList: await getShippingTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const termTypes = createAsyncThunk("config/termTypes", async (data) => {
    try {
        return {
            termTypesList: await getTermTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const shippingLines = createAsyncThunk("config/shippingLines", async (data) => {
    try {
        return {
            shippingLinesList: await getShippingLines(data),
        };
    } catch (e) {
        throw e;
    }
});

export const documentTypes = createAsyncThunk("config/documentTypes", async (data) => {
    try {
        return {
            documentTypesList: await getDocumentTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const eventTypes = createAsyncThunk("config/eventTypes", async (data) => {
    try {
        return {
            eventTypesList: await getEventTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const containerTypes = createAsyncThunk("config/containerTypes", async (data) => {
    try {
        return {
            containerTypesList: await getContainerTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const packagingTypes = createAsyncThunk("config/packagingTypes", async (data) => {
    try {
        return {
            packagingTypesList: await getPackagingTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const notificationTypes = createAsyncThunk("config/notificationTypes", async (data) => {
    try {
        return {
            notificationTypesList: await getNotificationTypes(data),
        };
    } catch (e) {
        throw e;
    }
});

export const notificationUnread = createAsyncThunk("config/notificationUnread", async () => {
    try {
        return {
            notificationCounter: await getNotificationCounter(),
        };
    } catch (e) {
        throw e;
    }
});

/*******************
 * EXPOSED          *
 *******************/

export const fetchCountries = () => (dispatch, getState) => {
    const { countries, isCountriesLoading } = getState().config;
    if (countries.length === 0 && !isCountriesLoading) {
        dispatch(country());
    }
};


export const fetchUnits = () => (dispatch, getState) => {
    const { unitList, isUnitsLoading } = getState().config;
    if (unitList.length === 0 && !isUnitsLoading) {
        dispatch(unit());
    }
};


export const fetchWebhookEvents = () => (dispatch, getState) => {
    const { webhookList, isWebhookLoading } = getState().config;
    if (webhookList.length === 0 && !isWebhookLoading) {
        dispatch(webhookEvents());
    }
};

export const fetchCities = () => (dispatch, getState) => {
    const { cities, isCitiesLoading } = getState().config;
    if (cities.length === 0 && !isCitiesLoading) {
        dispatch(city());
    }
};


export const fetchRegions = () => (dispatch, getState) => {
    const { regions, isRegionsLoading } = getState().config;
    if (regions.length === 0 && !isRegionsLoading) {
        dispatch(region());
    }
};

export const fetchLanguages = () => (dispatch, getState) => {
    const { languages, isLanguagesLoading } = getState().config;
    if (languages.length === 0 && !isLanguagesLoading) {
        dispatch(language());
    }
};

export const fetchPhonePrefix = () => (dispatch, getState) => {
    const { prefix, isPhonePrefixLoading } = getState().config;
    if (prefix.length === 0 && !isPhonePrefixLoading) {
        dispatch(phonePrefix());
    }
};

export const fetchCurrencies = () => (dispatch, getState) => {
    const { currenciesList, isCurrenciesListLoading } = getState().config;
    if (currenciesList.length === 0 && !isCurrenciesListLoading) {
        dispatch(currencies());
    }
};

export const fetchPorts = () => (dispatch, getState) => {
    const { portList, isPortsLoading } = getState().config;
    if (portList.length === 0 && !isPortsLoading) {
        dispatch(ports());
    }
};

export const fetchVessels = () => (dispatch, getState) => {
    const { vesselList, isVesselsLoading } = getState().config;
    if (vesselList.length === 0 && !isVesselsLoading) {
        dispatch(vessels());
    }
};

export const fetchDeliveryTypes = () => (dispatch, getState) => {
    const { deliveryTypesList, isDeliveryTypesListLoading } = getState().config;
    if (deliveryTypesList.length === 0 && !isDeliveryTypesListLoading) {
        dispatch(deliveryTypes());
    }
};


export const fetchShippingTypes = () => (dispatch, getState) => {
    const { shippingTypesList, isShippingTypesListLoading } = getState().config;
    if (shippingTypesList.length === 0 && !isShippingTypesListLoading) {
        dispatch(shippingTypes());
    }
};

export const fetchTermTypes = () => (dispatch, getState) => {
    const { termTypesList, isTermTypesListLoading } = getState().config;
    if (termTypesList.length === 0 && !isTermTypesListLoading) {
        dispatch(termTypes());
    }
};

export const fetchShippingLines = () => (dispatch, getState) => {
    const { shippingLinesList, isShippingLinesListLoading } = getState().config;
    if (shippingLinesList.length === 0 && !isShippingLinesListLoading) {
        dispatch(shippingLines());
    }
};

export const fetchDocumentTypes = () => (dispatch, getState) => {
    const { documentTypesList, isDocumentTypesListLoading } = getState().config;
    if (documentTypesList.length === 0 && !isDocumentTypesListLoading) {
        dispatch(documentTypes());
    }
};


export const fetchEventTypes = () => (dispatch, getState) => {
    const { eventTypesList, isEventTypesListLoading } = getState().config;
    if (eventTypesList.length === 0 && !isEventTypesListLoading) {
        dispatch(eventTypes());
    }
};


export const fetchContainerTypes = () => (dispatch, getState) => {
    const { containerTypesList, isContainerTypesListLoading } = getState().config;
    if (containerTypesList.length === 0 && !isContainerTypesListLoading) {
        dispatch(containerTypes());
    }
};


export const fetchPackagingTypes = () => (dispatch, getState) => {
    const { packagingTypesList, isPackagingTypesListLoading } = getState().config;
    if (packagingTypesList.length === 0 && !isPackagingTypesListLoading) {
        dispatch(packagingTypes());
    }
};


export const fetchNotificationTypes = () => (dispatch, getState) => {
    const { notificationTypesList, isNotificationTypesListLoading } = getState().config;
    if (notificationTypesList.length === 0 && !isNotificationTypesListLoading) {
        dispatch(notificationTypes());
    }
};


/*******************
 * REDUCERS EVENTS *
 *******************/

export const configReducer = createSlice({
  name: "config",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(country.pending, (state) => {
            state.isCountriesLoading = true;
        })
        .addCase(country.fulfilled, (state, action) => {
            state.countries = getSelectParams(action.payload.countries, 'name');
            state.isCountriesLoading = false;
        })
        .addCase(country.rejected, (state, action) => {
            state.isCountriesLoading = false;
            state.countriesException = action.error.message;
        });
    builder
        .addCase(unit.pending, (state) => {
            state.isUnitsLoading = true;
        })
        .addCase(unit.fulfilled, (state, action) => {
            state.unitList = action.payload.unitList;
            state.isUnitsLoading = false;
        })
        .addCase(unit.rejected, (state, action) => {
            state.isUnitsLoading = false;
            state.unitException = action.error.message;
        });
    builder
        .addCase(webhookEvents.pending, (state) => {
            state.isWebhookLoading = true;
        })
        .addCase(webhookEvents.fulfilled, (state, action) => {
            state.webhookList = action.payload.webhookList;
            state.isWebhookLoading = false;
        })
        .addCase(webhookEvents.rejected, (state, action) => {
            state.isWebhookLoading = false;
            state.webhookException = action.error.message;
        });
    builder
        .addCase(city.pending, (state) => {
            state.isCitiesLoading = true;
        })
        .addCase(city.fulfilled, (state, action) => {
            state.cities = getDynamicParams(action.payload.cities);
            state.isCitiesLoading = false;
        })
        .addCase(city.rejected, (state, action) => {
            state.isCitiesLoading = false;
            state.citiesException = action.error.message;
        });
      builder
          .addCase(region.pending, (state) => {
              state.isRegionsLoading = true;
          })
          .addCase(region.fulfilled, (state, action) => {
              state.regions = getDynamicParams(action.payload.regions);
              state.isRegionsLoading = false;
          })
          .addCase(region.rejected, (state, action) => {
              state.isRegionsLoading = false;
              state.regionsException = action.error.message;
          });
    builder
        .addCase(language.pending, (state) => {
            state.isLanguagesLoading = true;
        })
        .addCase(language.fulfilled, (state, action) => {
            state.languages = getSelectParams(action.payload.languages, 'name');
            state.isLanguagesLoading = false;
        })
        .addCase(language.rejected, (state, action) => {
            state.isLanguagesLoading = false;
            state.languageException = action.error.message;
        });
    builder
        .addCase(phonePrefix.pending, (state) => {
            state.isPhonePrefixLoading = true;
        })
        .addCase(phonePrefix.fulfilled, (state, action) => {
            state.prefix = getSelectParams(action.payload.prefix, 'prefix');
            state.isPhonePrefixLoading = false;
        })
        .addCase(phonePrefix.rejected, (state, action) => {
            state.isPhonePrefixLoading = false;
            state.phonePrefixException = action.error.message;
        });
    builder
        .addCase(currencies.pending, (state) => {
            state.isCurrenciesListLoading = true;
        })
        .addCase(currencies.fulfilled, (state, action) => {
            state.currenciesList = getSelectParams(action.payload.currenciesList, 'code');
            state.isCurrenciesListLoading = false;
        })
        .addCase(currencies.rejected, (state, action) => {
            state.isCurrenciesListLoading = false;
            state.currenciesListException = action.error.message;
        });
      builder
          .addCase(ports.pending, (state) => {
              state.isPortsLoading = true;
          })
          .addCase(ports.fulfilled, (state, action) => {
              state.portList = getPortParams(action.payload.portList);
              state.isPortsLoading = false;
          })
          .addCase(ports.rejected, (state, action) => {
              state.isPortsLoading = false;
              state.PortsException = action.error.message;
          });
      builder
          .addCase(vessels.pending, (state) => {
              state.isVesselsLoading = true;
          })
          .addCase(vessels.fulfilled, (state, action) => {
              state.vesselList = getVesselParams(action.payload.vesselList);
              state.isVesselsLoading = false;
          })
          .addCase(vessels.rejected, (state, action) => {
              state.isVesselsLoading = false;
              state.VesselsException = action.error.message;
          });
      builder
          .addCase(deliveryTypes.pending, (state) => {
              state.isDeliveryTypesListLoading = true;
          })
          .addCase(deliveryTypes.fulfilled, (state, action) => {
              state.deliveryTypesList = getShippingTypeParams(action.payload.deliveryTypesList);
              state.isDeliveryTypesListLoading = false;
          })
          .addCase(deliveryTypes.rejected, (state, action) => {
              state.isDeliveryTypesListLoading = false;
              state.deliveryTypesException = action.error.message;
          });
      builder
          .addCase(termTypes.pending, (state) => {
              state.isTermTypesListLoading = true;
          })
          .addCase(termTypes.fulfilled, (state, action) => {
              state.termTypesList = getSelectParams(action.payload.termTypesList, 'name');
              state.isTermTypesListLoading = false;
          })
          .addCase(termTypes.rejected, (state, action) => {
              state.isTermTypesListLoading = false;
              state.termTypesException = action.error.message;
          });
      builder
          .addCase(shippingLines.pending, (state) => {
              state.isShippingLinesListLoading = true;
          })
          .addCase(shippingLines.fulfilled, (state, action) => {
              state.shippingLinesList = getVesselParams(action.payload.shippingLinesList);
              state.isShippingLinesListLoading = false;
          })
          .addCase(shippingLines.rejected, (state, action) => {
              state.isShippingLinesListLoading = false;
              state.shippingLinesException = action.error.message;
          });
      builder
          .addCase(documentTypes.pending, (state) => {
              state.isDocumentTypesListLoading = true;
          })
          .addCase(documentTypes.fulfilled, (state, action) => {
              state.documentTypesList = getSelectParams(action.payload.documentTypesList, 'name');
              state.isDocumentTypesListLoading = false;
          })
          .addCase(documentTypes.rejected, (state, action) => {
              state.isDocumentTypesListLoading = false;
              state.documentTypesException = action.error.message;
          });
      builder
          .addCase(eventTypes.pending, (state) => {
              state.isEventTypesListLoading = true;
          })
          .addCase(eventTypes.fulfilled, (state, action) => {
              state.eventTypesList = getSelectParams(action.payload.eventTypesList, 'name');
              state.isEventTypesListLoading = false;
          })
          .addCase(eventTypes.rejected, (state, action) => {
              state.isEventTypesListLoading = false;
              state.eventTypesException = action.error.message;
          });
      builder
          .addCase(containerTypes.pending, (state) => {
              state.isContainerTypesListLoading = true;
          })
          .addCase(containerTypes.fulfilled, (state, action) => {
              state.containerTypesList = getSelectParams(action.payload.containerTypesList, 'name');
              state.isContainerTypesListLoading = false;
          })
          .addCase(containerTypes.rejected, (state, action) => {
              state.isContainerTypesListLoading = false;
              state.containerTypesException = action.error.message;
          });
      builder
          .addCase(packagingTypes.pending, (state) => {
              state.isPackagingTypesListLoading = true;
          })
          .addCase(packagingTypes.fulfilled, (state, action) => {
              state.packagingTypesList = getSelectParams(action.payload.packagingTypesList, 'name');
              state.isPackagingTypesListLoading = false;
          })
          .addCase(packagingTypes.rejected, (state, action) => {
              state.isPackagingTypesListLoading = false;
              state.packagingTypesException = action.error.message;
          });
      builder
          .addCase(shippingTypes.pending, (state) => {
              state.isShippingTypesListLoading = true;
          })
          .addCase(shippingTypes.fulfilled, (state, action) => {
              state.shippingTypesList = getSelectParams(action.payload.shippingTypesList, 'name');
              state.isShippingTypesListLoading = false;
          })
          .addCase(shippingTypes.rejected, (state, action) => {
              state.isShippingTypesListLoading = false;
              state.shippingTypesException = action.error.message;
          });
      builder
          .addCase(notificationTypes.pending, (state) => {
              state.isNotificationTypesListLoading = true;
          })
          .addCase(notificationTypes.fulfilled, (state, action) => {
              state.notificationTypesList = (action.payload.notificationTypesList ?? []).map((e) => ({
                  id: e.id,
                  value: e.id,
                  label: e.title,
                  notification_detail: (e?.notification_detail ?? [])
              }) );
              state.isNotificationTypesListLoading = false;
          })
          .addCase(notificationTypes.rejected, (state, action) => {
              state.isNotificationTypesListLoading = false;
              state.notificationTypesException = action.error.message;
          });
      builder
          .addCase(notificationUnread.fulfilled, (state, action) => {
              state.notificationCounter = action.payload.notificationCounter.count;
          })
  },
});

export default configReducer.reducer;
