import React from "react";
import { Link } from "react-router-dom";
import { DASHBOARD } from "../../../routes/endpoints";
import CompanyTypeSwitch from "../header/company-type-switch";
import {useTranslation} from "react-i18next";

const ExporterRestricted = () => {

  const { t } = useTranslation();

  return (
    <div className="py-4  h-full flex flex-col before:flex-1 after:flex-1">
      <div className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-16 bg-white shadow overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                {t("app.permissions.exporter_restricted_title")}
              </h2>
              <p className="mt-4 max-w-2xl mx-auto text-center text-md text-gray-500">
                {t("app.permissions.exporter_restricted_description")}
              </p>
            </div>

            <div className="mt-8 flex justify-center">
              <CompanyTypeSwitch />
            </div>

            <div className="mt-3 flex justify-center">
              <Link className="text-xs text-indigo-600 underline cursor-pointer" to={DASHBOARD} >
                {t("app.common.go_to", {label: 'Dashboard'})}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExporterRestricted;
