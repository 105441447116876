import React, {Fragment, useEffect, useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import InputText from "../../common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputDate from "../../common/form/input-date";
import InputSubmit from "../../common/form/input-submit";
import {Warning} from "../../common/alert/banner";
import {getDynamicReport, getReport, getReportTypes, putDynamic, putImport} from "../../../api/report";
import {useParams} from "react-router-dom";
import ReportViewer from "./report-viewer";
import Loader from "../../common/loader/loader";
import {getSelectParam, getSelectSubParams} from "../../../utils/converter";
import FileCard from "../../common/card/file-card";
import {getFormattedDate} from "../../../utils/timeUtils";
import InputSelect from "../../common/form/input-select";
import {getReportType} from "../../../constants/config";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import InputEditor from "../../common/form/input-editor";
import InputRadioSimple from "../../common/form/input-radio-simple";
import InputFormattedNumber from "../../common/form/input-formatted-number";
import AutoSaveSignatureCard from "../upload/autosave-signature-card";
import InputToggle from "../../common/form/input-toggle";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PDF = 'pdf';
const EXCEL = 'excel';

const ReportGenerate = ({
   isOpen,
   setIsOpen,
   reportDetails = false,
   isSpecific = true,
   isDynamic = false,
   params = false,
   reportParams = false,
}) => {

    const { t } = useTranslation();
    let {id}  = useParams();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [reportData, setReportData] = useState(false);
    const [isUpdateExtraData, setUpdateExtraData] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [documentType, setDocumentType] = useState(PDF);

    const [isLoadingTypes, setLoadingTypes] = useState(isSpecific ? false : true);
    const [reportType, setReportType] = useState([]);
    const [reportDetail, setReportDetail] = useState(reportDetails);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm();


    const generatePackingList = (data) => {
        setLoading(true);
        setException(false);

        if(isDynamic){
            putDynamic({ report_type: reportDetail.type, entity: reportDetail.entity, entity_id: id, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(response);
                    setOpenReportView(true);
                    setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }else{
            putImport({ shipping_id: id, report_type: reportDetail.type, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(response);
                    setOpenReportView(true);
                    setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }


    };

    const clean = () =>{
        if(!isSpecific){
            setReportDetail(false);

            if(reportData && reportData.parameters){
                for (const k in reportData.parameters) {
                    setValue(k, null);
                }
            }

            setReportData(false);
            setUpdateExtraData(false);
            setValue("report_type", null);
        }
        setException(false);
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        function fetchReport() {
            if (reportDetail && isOpen && !isLoadingTypes) {
                setLoadingDetails(true);
                setException(false);

                let param = params ? Object.assign({}, params, {file_type: documentType}) : {file_type: documentType};


                (isDynamic ?
                    getDynamicReport({entity: reportDetail.entity, entity_id: id, report_type: reportDetail.type, params: param , signal }) :
                    getReport({ shipping_id: id, report_type: reportDetail.type, params: param , signal }))
                        .then((response) => {
                        if(response.parameters) {
                            for (const n in reportDetail.fields) {
                                let field = reportDetail.fields[n];
                                if (response.parameters[field.name]) {
                                    let value = response.parameters[field.name];

                                    if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                        delete reportDetail.fields[n];
                                    } else {
                                        if (typeof value === "object") {
                                            setValue(field.name, getSelectParam(value, "name"));
                                        } else {
                                            setValue(field.name, value);
                                        }
                                    }
                                }else{
                                    if (response.id && field.hideIfValidate) {
                                        delete reportDetail.fields[n];
                                    }
                                }
                            }
                        }

                        setReportData(response);
                        setLoadingDetails(false);
                    })
                    .catch((e) => {
                        setReportData(false);
                        setLoadingDetails(false);
                    });
            }
        }

        fetchReport();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [reportDetail, id, isOpen, setValue, isLoadingTypes, documentType]);


    useEffect(() => {
        function fetchReportTypes() {
            if (!reportDetail && isOpen && !isSpecific) {
                setLoadingTypes(true);
                setException(false);
                getReportTypes({ params: reportParams })
                    .then((response) => {
                        setReportType(getSelectSubParams(response, "document_type", "name"));
                        setLoadingTypes(false);
                    })
                    .catch((e) => {
                        setReportType([]);
                        setLoadingTypes(true);
                    });
            }
        }

        fetchReportTypes();

    }, [reportDetail, isOpen, isSpecific, reportParams]);




    const onSubmit = async (detail) => {

        let data = detail;
        if(params){
            data = {...data, ...params}
        }
        generatePackingList(data);
    };

    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={reportData?.file ?? false}
                title={reportData?.media_file ?? ''}
                entityId={id}
                type={'report_log'}
                isActiveConfirm={reportDetail?.config?.isConfirmable ?? false}
                reportId={reportData?.id ?? ''}
                isReportConfirmed={reportData?.confirmed ?? false}
                onUpdateData={(r) => {
                    setReportData(r);
                }}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />


            <SlidePanel
                title={t("app.report.generate_report")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bg={'bg-white'}
                setClean={() => {
                    clean();
                }}
            >

                <div className="space-y-6 pt-6 pb-5">

                    {
                        isLoadingDetails ? (
                            <Loader />
                        ) : (

                            <>

                                {
                                    (reportDetail?.config?.isMultiGenerate ?? false) && (
                                        <div className="grid grid-cols-2   mb-6 flex space-x-1 bg-blue-1000 p-1 items-center rounded-md text-center">

                                            <div
                                                onClick={() => {
                                                    setValue('file_type', PDF);
                                                    setDocumentType(PDF);
                                                }}
                                                className={
                                                    classNames(
                                                        'rounded-sm py-1 text-sm font-medium  leading-5 cursor-pointer px-2',
                                                        documentType === PDF
                                                            ? 'bg-white shadow text-blue-1000'
                                                            : 'text-white hover:bg-white hover:text-blue-1000'
                                                    )
                                                }
                                            >
                                                PDF
                                            </div>

                                            <div
                                                onClick={() => {
                                                    setValue('file_type', EXCEL);
                                                    setDocumentType(EXCEL);
                                                }}
                                                className={
                                                    classNames(
                                                        'rounded-sm py-1 text-sm font-medium  leading-5  cursor-pointer px-2',
                                                        documentType === EXCEL
                                                            ? 'bg-white shadow text-blue-1000'
                                                            : 'text-white hover:bg-white hover:text-blue-1000'
                                                    )
                                                }
                                            >
                                                EXCEL
                                            </div>



                                        </div>
                                    )
                                }


                                {
                                    documentType === EXCEL && (reportDetail?.configXlsParams ?? []).map(((field, index) => (
                                        <Fragment key={index}>
                                            <div className="grid grid-cols-1 gap-y-5 gap-x-5 ">
                                                {
                                                    field.type === 'checkbox' && (
                                                        <Controller
                                                            name={field.name}
                                                            rules={{ required: field.required }}
                                                            control={control}
                                                            render={({
                                                                         field: { onChange, value, name },
                                                                         fieldState: { error },
                                                                     }) => (
                                                                <InputToggle
                                                                    label={t(field.label)}
                                                                    description={t(field.description)}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    defaultChecked={false}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Fragment>
                                    )))
                                }


                                {
                                    reportData.file && (
                                        <div>
                                            <label className="block font-medium text-gray-700">{t("app.report.report_detail")}</label>
                                            <FileCard
                                                file={reportData.file}
                                                name={reportData.media_file}
                                                information={getFormattedDate(reportData.upload_date)}
                                                isReportConfirmed={reportData?.confirmed ?? false}
                                                onClick={() => {
                                                    setOpenReportView(true);
                                                }}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    reportDetail ? (
                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            <div className="grid grid-cols-1 gap-y-5 gap-x-5 ">

                                                <h1 className=" font-medium text-gray-700 border-b">{t("app.report.report_information")}</h1>



                                                {
                                                    reportDetail.fields.map(((field, index) => (
                                                        <Fragment key={index}>

                                                            {
                                                                field.type === 'string' && (
                                                                    <InputText
                                                                        id={field.name}
                                                                        type={"text"}
                                                                        errors={errors[field.name]}
                                                                        input={{ ...register(field.name, { required: field.required }) }}
                                                                        label={t(field.translation)}
                                                                    />
                                                                )
                                                            }

                                                            {
                                                                field.type === 'date' && (
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({field: { onChange, value, name }, fieldState: { error },}) => (
                                                                            <InputDate
                                                                                label={t(field.translation)}
                                                                                format={"YYYY-MM-DD HH:mm:ss"}
                                                                                placeholder={"Es. 01/12/2021"}
                                                                                activeMinDate={false}
                                                                                startDate={value}
                                                                                disabled={false}
                                                                                isShowTime={field?.config?.isActiveTime ?? false}
                                                                                errors={error}
                                                                                name={name}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                )
                                                            }


                                                            {
                                                                field.type === 'editor' && (
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({field: { onChange, value, name },
                                                                                     fieldState: { error },
                                                                                 }) => (
                                                                            <InputEditor
                                                                                label={t(field.translation)}
                                                                                errors={error}
                                                                                defaultValue={value}
                                                                                name={name}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                )
                                                            }

                                                            {
                                                                field.type === 'radio-btn' && (
                                                                    <InputRadioSimple
                                                                        name={field.group}
                                                                        errors={errors.default_type}
                                                                        input={{...register(field.group, { required: field.required })}}
                                                                        label={t(field.translation)}
                                                                        description={t(field.description)}
                                                                        value={field.name}
                                                                    />

                                                                )
                                                            }


                                                            {
                                                                field.type === 'number' && (
                                                                    <Controller
                                                                        name={field.name}
                                                                        rules={{ required: field.required }}
                                                                        control={control}
                                                                        render={({
                                                                                 field: { onChange, value, name },
                                                                                 fieldState: { error },
                                                                             }) => (
                                                                            <InputFormattedNumber
                                                                                label={t(field.translation)}
                                                                                name={name}
                                                                                value={value}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                }}
                                                                                prefix={field?.config?.prefix ?? ''}
                                                                                suffix={field?.config?.suffix ?? ''}
                                                                                errors={error}
                                                                            />
                                                                        )}
                                                                    />

                                                                )
                                                            }

                                                            {
                                                                field.type === 'signature' && (
                                                                    <AutoSaveSignatureCard
                                                                        onUpdateData={(r) => {
                                                                            setUpdateExtraData(r);
                                                                        }}
                                                                    />
                                                                )
                                                            }

                                                        </Fragment>
                                                    )))
                                                }

                                            </div>


                                            {/* Warning*/}
                                            {exception && (
                                                <Warning message={exception} />
                                            )}

                                            <div className="flex before:flex-1 items-center justify-between mt-6">
                                                <InputSubmit
                                                    isLoading={isLoading}
                                                    label={t("app.report.generate_file")}
                                                    disabled={isUpdateExtraData}
                                                    isFullWith={true}
                                                />
                                            </div>


                                            {
                                                !isSpecific && (
                                                    <div className="flex after:flex-1 items-center justify-between mt-6">
                                                        <div
                                                            onClick={() => {
                                                                clean();
                                                            }}
                                                            className="cursor-pointer text-sm underline inline-flex  items-center"
                                                        >
                                                            <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                            {t("app.common.back")}
                                                        </div>
                                                    </div>
                                                )
                                            }



                                        </form>
                                    ) : (
                                        <>
                                            <Controller
                                                name="report_type"
                                                rules={{ required: true }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputSelect
                                                        label={t("app.document.document_type")}
                                                        name={name}
                                                        options={reportType}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if(e){
                                                                setReportDetail(getReportType(e.code))
                                                            }
                                                        }}
                                                        isClearable={true}
                                                        isLoading={isLoadingTypes}
                                                        isDisabled={isLoadingTypes}
                                                        errors={error}

                                                    />
                                                )}
                                            />


                                        </>
                                    )
                                }


                            </>

                        )
                    }



                </div>
            </SlidePanel>


        </>

    );
};



export default ReportGenerate;
