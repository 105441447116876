import React, {Fragment, useEffect, useState} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {ChevronDownIcon, MapIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import {useTranslation} from "react-i18next";
import SearchIcon from "../../../resources/icons/SearchIcon";
import {  getPorts } from '../../../api/config';
import { getDynamicParams } from '../../../utils/converter';


function mergeArraysUniqueById(array1, array2) {
    let mergedArray = array1.concat(array2);
    let uniqueArray = mergedArray.reduce((acc, obj) => {
        let found = acc.find(elem => elem.id === obj.id);
        if (!found) {
            acc.push(obj);
        }
        return acc;
    }, []);
    return uniqueArray;
}


export default function InputPortsSelect({ label = '', isRequired = false, onSelect, selectedData = [], multiple = false}) {

    const { t } = useTranslation();



    const [query, setQuery] = useState('');
    const [list, setList] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isLoadingInit, setLoadingInit] = useState(false)
    const [second, setSecond] = useState(0);
    const [startCounter, setStartCounter] = useState(false);


    const filteredData =
        query === ''
            ? list
            : list.filter((item) =>
                item.label
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )



    useEffect(() => {
        if (selectedData.length > 0){
            setLoadingInit(true);
            getPorts({ params: { port_id: selectedData.length > 0 ? selectedData.map(u => u.id).join(',') : null } })
                .then((response) => {
                    if (response.length > 0) {
                        onSelect(getDynamicParams(response));
                        setList(mergeArraysUniqueById(list, getDynamicParams(response)));
                    }
                    setLoadingInit(false);
                })
                .catch((e) => {
                    setLoadingInit(false);
                });
        }
    // eslint-disable-next-line
    }, []);

  
    useEffect(() => {
        const interval = setInterval(() => {
            if (startCounter) {
                setSecond(second + 1);
                if (((query.length >= 1 || query.length === 0) && (query !== '') && second === 1)) {
                    setStartCounter(false);
                    setSecond(0);
                    setLoading(true);
                    getPorts({ params: { name: query, force_search: query.length > 0 } })
                        .then((response) => {
                            if (response.length > 0) {
                                setList(mergeArraysUniqueById(list, getDynamicParams(response)));
                            }
                            setLoading(false);
                        })
                        .catch((e) => {
                            setLoading(false);
                        });
                }
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });


    return (
        <div className="relative w-full">

            <label className="block text-sm font-medium mb-1 text-gray-700">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>

        
            <Combobox value={(list?.filter(obj1 => selectedData.some(obj2 => obj1.id === obj2.id)) ?? [])}
                multiple={multiple}
                onChange={(r) => {
                    onSelect(r);
                }}>

                <div className="relative mt-1 ">



                    <Combobox.Button
                        className={`form-input w-full text-left flex items-center`}
                    >

                        {
                            isLoadingInit ? (
                                <Loader />
                            ) : (
                                    <span className="block truncate">{selectedData.length > 0 ? (selectedData ?? []).map(r => r.label).join(", ") : t("app.common.placeholder", {
                                        field: label.toLowerCase(),
                                    })}</span>
                            )
                        }

                       


                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Combobox.Button>

                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                (isLoading) ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>

                                        <div className={"relative   w-full sm:w-auto"}>
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <SearchIcon className="h-5 w-5 text-blue-1000" aria-hidden="true" />
                                            </div>
                                            <input
                                                type="search"
                                                name="search"
                                                id="search"
                                                autoComplete={"off"}
                                                className="form-search block w-full pl-10"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    setQuery(e.target.value);
                                                    setSecond(0);
                                                    setStartCounter(true);

                                                }}
                                                defaultValue={query}
                                            />
                                        </div>


                                            {filteredData.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                                    filteredData.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${(active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (

                                                        <>
                                                                    <div className="flex items-center">
                                                                        <div>
                                                                            <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                                                                <span className="font-medium leading-none text-white">
                                                                                    <MapIcon className={'h-5 w-5 text-blue-100'} />
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="ml-3">
                                                                            <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{item?.data?.name ?? ''}</b></p>
                                                                            {
                                                                                item.data.code && (
                                                                                    <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700"><b>UN/Locode:</b> {item.data.code} </p>
                                                                                )
                                                                            }
                                                                            <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{item.data?.country?.name + ', ' ?? ''} {item.data?.country?.code ?? ''}</p>
                                                                        </div>
                                                                    </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>


        </div>
    )
}
