import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {getShipping} from "../../api/shipping";

import {CheckIcon} from '@heroicons/react/solid'
import {DocumentSearchIcon, LocationMarkerIcon, TruckIcon} from '@heroicons/react/outline'
import {getCustomFormat, getFormattedDate} from "../../utils/timeUtils";
import FileCell from "../../components/common/table/file-cell";
import NumberFormat from "react-number-format";
import GalleryView from "../../components/common/popup/gallery-view";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import {LOGISTICS, P404, SHIPPINGS} from "../endpoints";
import ReportViewer from "../../components/partials/report/report-viewer";
import {useTranslation} from "react-i18next";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import {ActionButton} from "../../components/layout/page-header";
import ShippingTimeline from "./shipping-timeline";
import {SEA} from "../../constants/config";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import MapMarker from "../../components/partials/map/map-marker";
import {getWebPathParam} from "../../utils/converter";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const overview = 'overview';
const container = 'containers';
const document = 'documents';

const ShippingOverview = () => {

    const { loggedCompany, companyType, isExporter } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    let {id}  = useParams();
    let navigate = useNavigate();
    const { t } = useTranslation();

    const [activeGroup, setActiveGroup] = useState(overview);

    // get Shipping
    const [isShippingDetailLoading, setShippingDetailLoading] = useState(true);
    const [detail, setDetail] = useState(null);
    const [url, setUrl] = useState(null);
    const [isOpenReportView, setOpenReportView] = useState(false);

    const isSeaType = detail?.shipping_type?.code === SEA ?? false;

    const [isOpenDetail, setOpenDetail] = useState(false);

    const tabs = [
        { name: t("app.common.overview"), group: overview },
        { name: isSeaType ? t("app.container.containers") : t("app.container.trucks"), group: container },
        { name: t("app.document.documents"), group: document },
    ];

    useEffect(() => {
        if(!isShippingDetailLoading){
            navigate(getWebPathParam([LOGISTICS, SHIPPINGS]))
        }
        // eslint-disable-next-line
    }, [isExporter]);


    useEffect( () => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchShipping(){
            if(loggedCompany){
                setShippingDetailLoading(true);
                getShipping({company_id: loggedCompany.id, shipping_id: id, signal, params: {company_type: companyType}})
                    .then(response => {
                        setDetail(response);
                        setShippingDetailLoading(false);
                    }).catch(e => {
                        e.code === 404 && navigate(P404);
                        setShippingDetailLoading(false);
                });
            }
        }


        fetchShipping();

        return () => {controller.abort();};
        // eslint-disable-next-line
    }, [dispatch, loggedCompany, id, companyType]);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.VIEW)]}  renderBaseOnFail={true} >

            <ReportViewer
                isOpen={isOpenReportView}
                type={'document'}
                reportDetail={url?.file ?? false}
                title={url?.file_name ?? ''}
                isActiveConfirm={false}
                reportId={url?.id ?? false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}
            />

                <div className="flex h-full">
                    <LoaderWrapper isLoading={isShippingDetailLoading}>


                    <div className="relative  flex flex-1 overflow-hidden">
                        <main className="relative  flex-1  overflow-y-auto focus:outline-none  ">
                            {/* Start main area*/}
                            <div className="absolute inset-0  ">

                                <div className="bg-white">
                                    <div className="flex justify-between items-center flex-wrap gap-4 mb-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-10 ">

                                        <div>

                                            <div className={"flex items-center"}>

                                                <div className="mr-3">
                                                    {
                                                        !isSeaType ? (
                                                            <TruckIcon className={'w-10 h-10 text-blue-1000'} />
                                                        ) : (
                                                            <ShippingIcon className={'w-10 h-10 text-blue-1000'} />
                                                        )
                                                    }
                                                </div>

                                                <h1 className="ml-2 text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                                                    Shipping #{detail?.tracking ?? ''}
                                                </h1>
                                            </div>


                                        </div>
                                        <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2 w-full sm:w-auto">
                                            <div className="xl:hidden">
                                                <ActionButton
                                                    onClick={() => {
                                                        setOpenDetail(true);
                                                    }}
                                                    text={'Timeline & '+t("app.shipping_tracking.title")}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-10 sm:mt-0">
                                        <UnderlineTab
                                            tabs={tabs}
                                            activeGroup={activeGroup}
                                            extraClass={'max-w-7xl mx-auto px-4 sm:px-6 md:px-8'}
                                            setActiveGroup={(r) => {
                                                setActiveGroup(r);
                                            }}
                                        />
                                    </div>

                                </div>


                                <div className={'max-w-7xl mx-auto px-4 sm:px-6 md:px-8'}>


                                    {
                                        activeGroup === overview && (
                                            <section className="mt-5">
                                                <div className="bg-white shadow-md">
                                                    <div className="px-4 py-5 sm:px-6">
                                                        <h2 className="text-lg leading-6 font-medium text-blue-1000">
                                                            {t("app.shippings.shipping_information")}
                                                        </h2>
                                                    </div>
                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">


                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{detail?.customer?.importer_reference ? t("app.shippings.customer"): t("app.supplier.supplier") }</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">{detail?.customer?.business_name ?? ''}</dd>
                                                            </div>

                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{t("app.shippings.terms_type")}</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">{detail?.terms_type?.name ?? '-'}</dd>
                                                            </div>



                                                            {
                                                                isSeaType && (
                                                                    <div className="sm:col-span-1">
                                                                        <dt className="text-sm font-medium text-gray-500">{t("app.shippings.shipping_line")}</dt>
                                                                        <dd className="mt-1 text-sm text-blue-1000">{detail?.shipping_line?.name ?? '-'}</dd>
                                                                    </div>
                                                                )
                                                            }


                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{t("app.shippings.departure_date")}</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">{getFormattedDate(detail?.departure_date ?? false)}</dd>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{t("app.shippings.arrival_date")}</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">{getFormattedDate(detail?.arrival_date ?? false)}</dd>
                                                            </div>

                                                            {
                                                                isSeaType ? (
                                                                    <>
                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.departure_port")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.departure_port?.name ?? '-'}</dd>
                                                                        </div>
                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.arrival_port")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.arrival_port?.name ?? '-'}</dd>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.departure_country")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.departure_country?.name ?? '-'}</dd>
                                                                        </div>
                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.departure_city")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.departure_city?.name ?? '-'}</dd>
                                                                        </div>

                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.arrival_country")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.arrival_country?.name ?? '-'}</dd>
                                                                        </div>
                                                                        <div className="sm:col-span-1">
                                                                            <dt className="text-sm font-medium text-gray-500">{t("app.shippings.arrival_city")}</dt>
                                                                            <dd className="mt-1 text-sm text-blue-1000">{detail?.arrival_city?.name ?? '-'}</dd>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }



                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{t("app.shippings.shipper")}</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">
                                                                    {detail?.shipper ?? '-'}
                                                                </dd>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">{t("app.shippings.consignee")}</dt>
                                                                <dd className="mt-1 text-sm text-blue-1000">
                                                                    {detail?.consignee ?? '-'}
                                                                </dd>
                                                            </div>
                                                        </dl>
                                                    </div>

                                                </div>
                                            </section>
                                        )
                                    }


                                    {
                                        activeGroup === container && (
                                            <div className="pb-20">
                                                {(detail?.containers ?? []).map((container) => (
                                                    <>
                                                    <section className="my-5">
                                                        <div className="bg-white shadow-md">





                                                            <div className="divide-y divide-gray-200">
                                                                <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
                                                                    <h2 className="text-lg font-medium text-gray-900">
                                                                        {
                                                                            isSeaType  ? (
                                                                                <>{container.container_type.name}</>
                                                                            ) : (
                                                                                <>{container.delivery_type.name}</>
                                                                            )
                                                                        }

                                                                    </h2>

                                                                    <p className="mt-1 text-md font-semibold text-blue-1000">{t("app.container.load_date")}: {container.load_date ? getFormattedDate(container.load_date) : '-'}</p>

                                                                </div>
                                                            </div>


                                                            {
                                                                (container?.gallery?.medias?.length ?? 0) > 0 && (
                                                                    <div className="border-t border-gray-200 ">
                                                                        <GalleryView gallery={container?.gallery?.medias ?? []} />
                                                                    </div>
                                                                )
                                                            }


                                                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                <dl className="col-span-2 grid grid-cols-1 md:grid-cols-3 gap-6">



                                                                    {
                                                                        isSeaType  ? (
                                                                            <>
                                                                                <div>
                                                                                    <dt className="text-sm font-medium text-gray-500">{t("app.container.container_number")}</dt>
                                                                                    <dd className="mt-1 text-sm text-blue-1000">{container.container_number ? container.container_number :  t("app.container.container_not_defined")}</dd>
                                                                                </div>

                                                                                <div>
                                                                                    <dt className="text-sm font-medium text-gray-500">{t("app.container.seal_number")}</dt>
                                                                                    <dd className="mt-1 text-sm text-blue-1000">{container.seal_number ? container.seal_number :  t("app.container.container_not_defined")}</dd>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <dt className="text-sm font-medium text-gray-500">{t("app.container.license_plate")}</dt>
                                                                                    <dd className="mt-1 text-sm text-blue-1000">{container.license_plate ? container.license_plate :  t("app.container.container_not_defined")}</dd>
                                                                                </div>

                                                                                <div>
                                                                                    <dt className="text-sm font-medium text-gray-500">{t("app.container.trailer_number")}</dt>
                                                                                    <dd className="mt-1 text-sm text-blue-1000">{container.trailer_number ? container.trailer_number :  t("app.container.container_not_defined")}</dd>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }



                                                                    <div>
                                                                        <dt className="text-sm font-medium text-gray-500">{t("app.container.packaging_number")}</dt>
                                                                        <dd className="mt-1 text-sm text-blue-1000">
                                                                            <NumberFormat
                                                                                value={container?.box ?? 0}
                                                                                displayType={"text"}
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator={","}
                                                                                decimalScale={0}
                                                                            />
                                                                        </dd>
                                                                    </div>


                                                                    <div>
                                                                        <dt className="text-sm font-medium text-gray-500">{t("app.container.volume")}</dt>
                                                                        <dd className="mt-1 text-sm text-blue-1000">
                                                                            <NumberFormat
                                                                                value={container?.volume ?? 0}
                                                                                displayType={"text"}
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator={","}
                                                                                decimalScale={2}
                                                                                suffix={' m³'}
                                                                            />
                                                                        </dd>
                                                                    </div>

                                                                    <div>
                                                                        <dt className="text-sm font-medium text-gray-500">{t("app.container.gross_weight")}</dt>
                                                                        <dd className="mt-1 text-sm text-blue-1000">
                                                                            <NumberFormat
                                                                                value={container?.weight ?? 0}
                                                                                displayType={"text"}
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator={","}
                                                                                decimalScale={2}
                                                                                suffix={' Kg'}
                                                                            />
                                                                        </dd>
                                                                    </div>

                                                                    <div>
                                                                        <dt className="text-sm font-medium text-gray-500">{t("app.container.net_weight")}</dt>
                                                                        <dd className="mt-1 text-sm text-blue-1000">
                                                                            <NumberFormat
                                                                                value={container?.net_weight ?? 0}
                                                                                displayType={"text"}
                                                                                thousandSeparator={"."}
                                                                                decimalSeparator={","}
                                                                                decimalScale={2}
                                                                                suffix={' Kg'}
                                                                            />
                                                                        </dd>
                                                                    </div>

                                                                </dl>
                                                            </div>

                                                        </div>
                                                    </section>


                                                    </>

                                                ))}
                                            </div>
                                        )
                                    }



                                    {
                                        activeGroup === document && (
                                            <div className="pb-20">
                                                {(detail?.documents ?? []).map((document) => (

                                                    <section  key={document.id} className="my-5">
                                                        <div className="bg-white shadow-md">

                                                            <div className="divide-y divide-gray-200">
                                                                <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
                                                                    <div className="w-0 flex-1 flex items-center">
                                                                        <FileCell file={document} />
                                                                    </div>

                                                                    <div className="ml-4 flex-shrink-0 flex items-center justify-between">
                                                                        {
                                                                            document.file && (
                                                                                <>
                                                                                    <div  onClick={() => {
                                                                                        setUrl(document);
                                                                                        setOpenReportView(true);
                                                                                    }} className="text-sm font-semibold flex items-center text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                                                                        <DocumentSearchIcon className={'h-5 w-5 text-blue-1000'} aria-hidden="true"/>
                                                                                    </div>

                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                ))}
                                            </div>
                                        )
                                    }

                                </div>


                            </div>
                            {/* End main area */}
                        </main>
                        <aside className="relative  hidden w-96 flex-shrink-0 overflow-y-auto border-l border-gray-200 xl:flex xl:flex-col">
                            {/* Start secondary column (hidden on smaller screens) */}
                            <div className="absolute inset-0 bg-white">


                                <div className="bg-white px-4 py-5 ">

                                    {
                                        isSeaType && (detail.location || (isShippingDetailLoading && (detail?.location !== false ?? false))) && (
                                            <>
                                                <h2 className="text-lg leading-6 font-medium text-blue-1000 mb-2">
                                                    {t("app.shipping_tracking.title")}
                                                </h2>

                                                {
                                                    (isShippingDetailLoading && (detail?.location !== false ?? false)) ? (
                                                        <div className="border bg-solitude-400 shadow-md rounded-lg p-4 h-32 w-full mx-auto  flex before:flex-1 after:flex-1 flex-col items-center">
                                                            <div className="animate-pulse border border-gray-500 rounded-full p-4">
                                                                <LocationMarkerIcon className="text-gray-500 h-6 w-6 stroke-current stroke-1" />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="block w-full shadow-md aspect-w-10 aspect-h-10 rounded-lg overflow-hidden">
                                                            {
                                                                (detail.location) && (
                                                                    <MapMarker
                                                                        location={detail.location ??  false}
                                                                        zoom={5}
                                                                        height={'200px'}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }






                                    <h2 className="text-lg leading-6 font-medium text-blue-1000 mt-3">
                                        Timeline
                                    </h2>

                                    {/* Activity Feed */}
                                    <div className="mt-6 flow-root">
                                        <ul className="-mb-8">
                                            {(detail?.events ?? []).map((event, itemIdx) => (
                                                <li key={event.id}>
                                                    <div className="relative pb-8">
                                                        {itemIdx !== detail.events.length - 1 ? (
                                                            <span
                                                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                                aria-hidden="true"
                                                            />
                                                        ) : null}
                                                        <div className="relative flex space-x-3">
                                                            <div>
                                                                  <span
                                                                      className={classNames(
                                                                          event.confirmed ? 'bg-green-500' : 'bg-gray-400',
                                                                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                                                      )}
                                                                  ><CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                                  </span>
                                                            </div>
                                                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                <div>
                                                                    <p className="text-sm text-blue-1000">
                                                                        {event.event_type.name}
                                                                    </p>
                                                                    <p className="text-sm text-gray-500">
                                                                        {event.event_note}
                                                                    </p>
                                                                </div>
                                                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                                    {
                                                                        event.confirmed && (
                                                                            <time dateTime={event.event_date}>{getCustomFormat(event.event_date, 'DD/MM')}</time>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End secondary column */}
                        </aside>
                    </div>
                    </LoaderWrapper>
                </div>


            <ShippingTimeline
                isOpen={isOpenDetail}
                events={detail?.events ?? []}
                isSeaType={isSeaType}
                tracking={detail}
                setIsOpen={(r) => {
                    setOpenDetail(r);
                }}
            />


        </BasePermissionWrapper>
    );
};

export default ShippingOverview;
