import React, {Fragment, useEffect, useState} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import {getCities} from "../../../api/config";
import {getDynamicParams} from "../../../utils/converter";


export default function CitiesSearch({
                                          label = '',
                                          placeholder,
                                          isRequired = false,
                                          length = 3,
                                          countryCode = false,
                                          selectedItem = {},
                                          onSelect,
                                          isDisabled = false
                                      }) {


    const [query, setQuery] = useState('');

    const [second, setSecond] = useState(0);
    const [startCounter, setStartCounter] = useState(false);

    const [list, setList] = useState([])
    const [isLoading, setLoading] = useState(false)



    useEffect(() => {
        setSecond(0);
        setStartCounter(true);
    }, [countryCode]);



    async function fetchCities(signal = null) {
        setLoading(true);
        getCities({ params: { name: query, city_id: selectedItem?.id ?? null,  force_search: query.length > 0, country_code: countryCode }, signal })
            .then((response) => {
                setList(response.length > 0 ? getDynamicParams(response) : []);
                setLoading(false);
            }).catch((e) => {
                //setLoading(false);
            });
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        if (selectedItem){
            fetchCities(signal);
        }


        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [selectedItem]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (startCounter) {
                setSecond(second + 1);
                if (((query.length >= length || query.length === 0 ) && ( query !== '') && second === 1)) {
                    setStartCounter(false);
                    setSecond(0);
                    setLoading(true);
                    getCities({ params: { name: query, force_search: query.length > 0, country_code: countryCode } })
                        .then((response) => {
                            setList(response.length > 0 ? getDynamicParams(response) : []);
                            setLoading(false);
                        }).catch((e) => {
                            setLoading(false);
                        });
                }
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });


    return (
        <div className="w-full">
            <Combobox disabled={isDisabled}  value={selectedItem ? list.find(elem => elem.id === selectedItem.id) : null} onChange={(r) => {
                onSelect(r);
            }}>
                <div className="relative ">


                    <label className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm">

                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                            placeholder={isLoading ? "Loading..." : placeholder}
                            autoComplete='no'
                            displayValue={(item) => item?.label ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                                setSecond(0);
                                setStartCounter(true);
                            }}
                        />
                        {
                            !isLoading && (
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Combobox.Button>
                            )
                        }

                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoading ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {list.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            list.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <div >
                                                                <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{item.label}</b></p>
                                                                <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{item.data?.description ?? ''}</p>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
