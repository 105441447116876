import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import FormPopup from "../../../components/common/popup/form-popup";
import InputSubmit from "../../../components/common/form/input-submit";
import {Warning} from "../../../components/common/alert/banner";
import InputSelect from "../../../components/common/form/input-select";
import {useTranslation} from "react-i18next";
import { putQuotationContainer} from "../../../api/quotation";
import {fetchContainerTypes} from "../../../reducers/configReducer";
import {getWarehouses} from "../../../api/config";
import {getSelectParam, getSelectParams} from "../../../utils/converter";

export default function EditQuotationContainer({isOpen, setIsOpen, containerDetail, onSetContainers}) {

    const { loggedCompany } = useSelector((state) => state.user);
    const { handleSubmit, control, setValue } = useForm();
    let {id}  = useParams();

    const { t } = useTranslation();
    const dispatch = useDispatch();


    // containers type list
    const { containerTypesList, isContainerTypesListLoading } = useSelector((state) => state.config);


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);


    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);


    const updateContainer = async (data) => {
        if(loggedCompany){
            setLoading(true);
            putQuotationContainer({quotation_id: id, container_id: containerDetail.id, data})
                .then(response => {
                    onSetContainers(response);
                    setLoading(false);
                    setIsOpen(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };



    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(fetchContainerTypes());

        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [dispatch, loggedCompany]);


    useEffect( () => {
        if(containerDetail !== null){
            for (const k in containerDetail) {
                if(typeof containerDetail[k] === "object"){
                    setValue(k, getSelectParam(containerDetail[k], 'name'));
                }else{
                    setValue(k, containerDetail[k]);
                }
            }
        }
        dispatch(fetchContainerTypes());
    }, [dispatch, containerDetail, setValue]);


    const onSubmit = async (data) => {
        await updateContainer(data);
    };

    return (
        <FormPopup title={t("app.container.edit_container")} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-2 gap-x-2 sm:grid-cols-1">

                        <Controller
                            name="container_type"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputSelect
                                    label={t("app.container.cont_type")}
                                    name={name}
                                    options={containerTypesList}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isContainerTypesListLoading}
                                    isDisabled={isContainerTypesListLoading}
                                    errors={error}
                                />
                            )}
                        />


                        <Controller
                            name="company_warehouse"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputSelect
                                    label={t("app.warehouse.warehouse")}
                                    name={name}
                                    options={warehouseList}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isWarehouseLoading}
                                    isDisabled={isWarehouseLoading}
                                    errors={error}
                                />
                            )}
                        />

                    </div>

                </div>

                {/* Warning */}
                {exception && (
                    <Warning
                        message={exception}
                    />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.form.save")}
                    />
                </div>
            </form>
        </FormPopup>
    )
}
