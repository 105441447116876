import React, {useState} from "react";
import {UploadIcon} from "@heroicons/react/outline";
import Files from "react-butterfiles";
import {getSignedRequests} from "../../../api/config";
import {sendMedia} from "../../../utils/apiUtils";
import LoaderIcon from "../../../resources/icons/LoaderIcon";
import {useTranslation} from "react-i18next";
import Compressor from 'compressorjs';


const UploadImages = ({onUpload, onException, isLoading, isSmall = false}) => {

    const [fileLen, setFileLen] = useState(0);
    const { t } = useTranslation();

    const handleSuccess = async (files)  => {
        setFileLen(files.length);
        // eslint-disable-next-line
        files.map((file, index) => {
            getSignedRequests({params: {ext: files[index].src.file.name.split(".").pop()}})
                .then( signed => {
                    new Compressor(file.src.file, {
                        quality: 0.5,
                        width: 1980,
                        success(result) {
                            sendMedia(signed.action, signed, result)
                                .then( r => {
                                    let url = signed.action + '/' + signed.key;
                                    onUpload({reference: url, media_url: url});
                                    setFileLen(state => state - 1);
                                }).catch(e => {
                                onException(e.message);
                            });
                        },
                        error(err) {
                            onException(err.message);
                        },
                    });

                }).catch(e => {
                    onException(e.message);
            });
        });
    };


    const handleErrors = async (errors) => {
        if(errors.length > 0 ){
             if((errors[0].file && errors[0].type === 'maxSizeExceeded') || (errors[0].type === 'multipleMaxSizeExceeded')){
                 onException(errors[0].type);
             }
        }
    };


    return (
        <Files
            multiple={true} maxSize="20mb" multipleMaxSize="200mb"
            accept={["image/png","image/jpg","image/jpeg"]}
            onSuccess={handleSuccess}
            onError={handleErrors}
        >
            {({ browseFiles, getDropZoneProps }) => {
                return (
                    <div onClick={browseFiles} {...getDropZoneProps()}
                         className={isSmall ?'cursor-pointer relative block h-16 w-full border-2 flex justify-center group items-center border-gray-300 border-dashed rounded-lg p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'cursor-pointer relative block h-full w-full border-2 flex justify-center group items-center border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'}>
                        {
                            fileLen > 0 || isLoading? (
                                <div>
                                    <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0"/>
                                </div>
                            ) : (
                                <div>
                                    <UploadIcon className={isSmall ? 'h-6 w-6 text-gray-400 group-hover:text-gray-700 m-auto mb-2' : 'h-10 w-10 text-gray-400 group-hover:text-gray-700 m-auto mb-2'} />
                                    <p className={'text-gray-400 font-medium text-md group-hover:text-gray-700'}>{t("app.common.add_images")}</p>
                                </div>
                            )
                        }
                    </div>
                );
            }}
        </Files>
    );
};

export default UploadImages;
