import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {fetchContainerTypes, fetchDeliveryTypes} from "../../../../reducers/configReducer";
import FormPopup from "../../../../components/common/popup/form-popup";
import {postContainer, postContainerImporter} from "../../../../api/container";
import {getSelectParams, getWebPathParam} from "../../../../utils/converter";
import {SHIPPING, CONTAINER, CONTAINER_GENERAL_INFORMATION, LOGISTICS} from "../../../endpoints";
import InputSubmit from "../../../../components/common/form/input-submit";
import {Warning} from "../../../../components/common/alert/banner";
import InputSelect from "../../../../components/common/form/input-select";
import InputDate from "../../../../components/common/form/input-date";
import {useTranslation} from "react-i18next";
import {getWarehouses} from "../../../../api/config";
import {getPermission, permissionGroup, permissionType, sp} from "../../../../constants/permissions";
import SubscriptionWrapper from "../../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../../reducers/subscriptionReducer";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import {LAND} from "../../../../constants/config";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";

export default function AddContainer({isOpen, setIsOpen, isSeaType}) {

    const { loggedCompany, isExporter } = useSelector((state) => state.user);
    const { handleSubmit, control } = useForm();
    const dispatch = useDispatch();
    let {id}  = useParams();
    let navigate = useNavigate();

    const { t } = useTranslation();


    // containers type list
    const { containerTypesList, isContainerTypesListLoading,
        deliveryTypesList,
        isDeliveryTypesListLoading,
    } = useSelector((state) => state.config);


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    // get Shipping
    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);

    const storeContainer = async (data) => {
        if(loggedCompany){
            setLoading(true);
            (isExporter ? postContainer({shipping_id: id, data}) :
            postContainerImporter({shipping_id: id, data}))
                .then(response => {
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                    navigate(getWebPathParam([LOGISTICS, SHIPPING, id, CONTAINER, response.id, CONTAINER_GENERAL_INFORMATION ]));
                    setLoading(false);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(fetchContainerTypes());
        dispatch(fetchDeliveryTypes());

        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [dispatch, loggedCompany]);

    const onSubmit = async (data) => {
        await storeContainer(data);
    };

    return (
        <FormPopup title={isSeaType ? t("app.container.new_contaienr") : t("app.container.new_truck")} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>
            <SubscriptionWrapper requiredPermission={sp.SHIPPING_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
                <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.CREATE)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">


                            {
                                isSeaType ? (
                                    <>
                                        <Controller
                                            name="container_type"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                <InputSelect
                                                    label={t("app.container.cont_type")}
                                                    name={name}
                                                    options={containerTypesList}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={isContainerTypesListLoading}
                                                    isDisabled={isContainerTypesListLoading}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="load_date"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                <InputDate
                                                    label={t("app.container.load_date")}
                                                    format={'YYYY-MM-D HH:mm:ss'}
                                                    placeholder={'Es. 01/12/2021'}
                                                    activeMinDate={false}
                                                    startDate={value}
                                                    disabled={false}
                                                    isShowTime={true}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                        {
                                            isExporter && (
                                                <div className="col-span-2">
                                                    <Controller
                                                        name="company_warehouse"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                            <InputSelect
                                                                label={t("app.warehouse.warehouse")}
                                                                name={name}
                                                                options={warehouseList}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={isWarehouseLoading}
                                                                isDisabled={isWarehouseLoading}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                        }
                                    </>

                                ) : (
                                    <>
                                        <Controller
                                            name="delivery_type"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                         field: { onChange, value, name },
                                                         fieldState: { error },
                                                     }) => (
                                                <InputSelect
                                                    label={t("app.shippings.delivery_type")}
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    options={deliveryTypesList.filter(r => r.type_code === LAND)}
                                                    value={value}
                                                    isLoading={isDeliveryTypesListLoading}
                                                    isDisabled={isDeliveryTypesListLoading}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="truck_volume"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                <InputFormattedNumber
                                                    label={t("app.container.truck_volume")}
                                                    name={name}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    prefix={''}
                                                    suffix={' m³'}
                                                    errors={error}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="load_date"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                <InputDate
                                                    label={t("app.container.load_date")}
                                                    format={'YYYY-MM-D HH:mm:ss'}
                                                    placeholder={'Es. 01/12/2021'}
                                                    activeMinDate={false}
                                                    startDate={value}
                                                    disabled={false}
                                                    isShowTime={true}
                                                    errors={error}
                                                    name={name}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                />
                                            )}
                                        />

                                        {
                                            isExporter && (
                                                <Controller
                                                    name="company_warehouse"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputSelect
                                                            label={t("app.warehouse.warehouse")}
                                                            name={name}
                                                            options={warehouseList}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={isWarehouseLoading}
                                                            isDisabled={isWarehouseLoading}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }






                        </div>

                    </div>

                    {/* Warning */}
                    {exception && (
                        <Warning
                            message={exception}
                        />
                    )}

                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.container.add_truck")}
                        />
                    </div>
                </form>
                </BasePermissionWrapper>
            </SubscriptionWrapper>
        </FormPopup>
    )
}
