import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../../routes/endpoints";
import BaseRestricted from "./base-restricted";
import {COMPANY_EXPORTER, COMPANY_IMPORTER} from "../../../constants/config";
import ExporterRestricted from "./exporter-restricted";

// @requiredPermissions is an array of permissions where the type of the permission is optional (e.g. both order.view or order are valid permissions)
// @renderBaseOnFail : bool if true render BaseRestricted component otherwise null
// @adminHasAccess : bool if a user with role admin has automatically access on this resource
// @requireExporter : bool if true require the user connected to be in exporter mode
// @requireImporter : bool if true require the user connected to be in importer mode
// @redirectWrongCompanyType : bool if true redirect user to dashboard if a specific required company mode is not satisfied
const BasePermissionWrapper = ({
  requiredPermissions = null,
  renderBaseOnFail = false,
  adminHasAccess = true,
  requireExporter = false,
  requireImporter = false,
  redirectWrongCompanyType = false,
  redirectTo = false,
  children,
}) => {

  const { permissions } = useSelector((s) => s.userCompany);
  const { companyType } = useSelector((s) => s.user);
  const navigate = useNavigate();
  // if true show children, otherwise show its value
  const [shouldShow, setShouldShow] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line
    if(companyType !== null && (requireExporter && (companyType === COMPANY_IMPORTER)) || (requireImporter && (companyType === COMPANY_EXPORTER)) ){


      if (redirectWrongCompanyType) {
        if(redirectTo){
          navigate(redirectTo);
        }else{
          navigate(DASHBOARD);
        }
      } else {
        setShouldShow(renderBaseOnFail ?  (requireExporter ? <ExporterRestricted /> : <BaseRestricted />) : null);
        return;
      }
    }

    if (requiredPermissions === null) {
      setShouldShow(true);
      return;
    }

    const hasAccess =
        permissions?.isSuperAdmin ||
        (adminHasAccess && permissions?.isAdmin) ||
        (requiredPermissions ?? [])
          .map((rq) => rq.split("."))
          .some(([group, type]) => group in (permissions?.permissionDict ?? {}) && (permissions.permissionDict[group][type] === true));


    const hasSpecificAccess =
        permissions?.isSuperAdmin ||
        (adminHasAccess && permissions?.isAdmin) ||
        (requiredPermissions ?? [])
            .map((rq) => rq.split("."))
            .some(([group, type]) => group in (permissions?.permissionSpecificDict ?? {}) && (permissions.permissionSpecificDict[group][type] === true));




    if (hasAccess || hasSpecificAccess) {
      setShouldShow(true);
    } else {
      setShouldShow(renderBaseOnFail ? <BaseRestricted /> : null);
    }


  }, [
    adminHasAccess,
    companyType,
    navigate,
    permissions?.isAdmin,
    permissions?.isSuperAdmin,
    permissions.permissionDict,
    permissions.permissionSpecificDict,
    redirectWrongCompanyType,
    renderBaseOnFail,
    requireExporter,
    requireImporter,
    requiredPermissions,
    redirectTo
  ]);

  // careful must be exactly equal (===) to true
  return shouldShow === true ? <>{children}</> : shouldShow;
};

export default BasePermissionWrapper;



export const hasPermission = (
    permissions,
    group,
    type,
    companyType = '',
    requireExporter = false,
    requireImporter = false,
    hasAdminAccess = true
) => {
  // eslint-disable-next-line
  if(companyType !== null && (requireExporter && companyType === COMPANY_IMPORTER) || (requireImporter && companyType === COMPANY_EXPORTER)){
    return false;
  }
  return hasPermissionFor(permissions, group, type, hasAdminAccess);

};

export const hasPermissionFor = (
  permissions,
  group,
  type,
  hasAdminAccess = true
) =>
  permissions.isSuperAdmin ||
  (permissions.isAdmin && hasAdminAccess) ||
  (permissions.permissionDict && permissions.permissionDict[group] && permissions.permissionDict[group][type] && permissions.permissionDict[group][type] === true) ||
  (permissions.permissionSpecificDict && permissions.permissionSpecificDict[group] && permissions.permissionSpecificDict[group][type] && permissions.permissionSpecificDict[group][type] === true) ;




