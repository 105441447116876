import React, {useEffect, useState} from "react";
import InputText from "../../../components/common/form/input-text";
import InputSubmit from "../../../components/common/form/input-submit";
import InputSelect from "../../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../components/common/alert/banner";
import {ACCOUNT, COMPANY} from "../../endpoints";
import {  getWebPathParam } from "../../../utils/converter";
import { useNavigate } from "react-router-dom";
import {postCompany} from "../../../api/company";
import {fetchCountries, fetchPhonePrefix} from "../../../reducers/configReducer";


export default function AddCompany({ isOpen, setIsOpen }) {
  const { isCountriesLoading, countries, isPhonePrefixLoading, prefix } = useSelector((state) => state.config);
  const { loggedCompany } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateCompanyLoading, setCreateCompanyLoading] = useState(false);
  const [createCompanyException, setCreateCompanyException] = useState(false);

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchPhonePrefix());
  }, [dispatch]);

  const storeCompany = async (data) => {
    if (loggedCompany) {
      setCreateCompanyLoading(true);
      postCompany({ data })
        .then((response) => {
          setCreateCompanyLoading(false);
          navigate(getWebPathParam([ACCOUNT, COMPANY, response.id]));
        })
        .catch((e) => {
          setCreateCompanyException(e.message);
          setCreateCompanyLoading(false);
        });
    }
  };

  const onSubmit = async (data) => {
    await storeCompany(data);
  };

  return (
    <FormPopup
      title={t("app.company.new_company")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <InputText
              id={'business_name'}
              type={'text'}
              errors={errors.business_name}
              input={{...register("business_name", { required: true })}}
              label={t("app.form.business_name")}
          />

          <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
            <Controller
                name="phone_prefix"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                    <InputSelect
                        label={t("app.form.phone_prefix")}
                        name={name}
                        options={prefix}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        isLoading={isPhonePrefixLoading}
                        isDisabled={isPhonePrefixLoading}
                        errors={error}
                    />
                )}
            />

            <InputText
                id={'phone'}
                type={'text'}
                errors={errors.business_phone}
                input={{...register("business_phone", { required: true })}}
                label={t("app.form.phone")}
            />
          </div>

          <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                  <InputSelect
                      label={t("app.form.country")}
                      name={name}
                      options={countries}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      isLoading={isCountriesLoading}
                      isDisabled={isCountriesLoading}
                      errors={error}
                  />
              )}
          />
        </div>

        {/* Warning */}
        {createCompanyException && <Warning message={createCompanyException} />}

        <div className="flex before:flex-1 items-center justify-between mt-6">
          <InputSubmit
            isLoading={isCreateCompanyLoading}
            label={t("app.company.add_company")}
          />
        </div>
      </form>
    </FormPopup>
  );
}
