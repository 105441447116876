import React from "react";
import {Link} from "react-router-dom";
import logo from "../../resources/images/logo.svg";
import {useTranslation} from "react-i18next";
import {INDEX, LOGIN} from "../../routes/endpoints";
import {safeLogout} from "../../reducers/userReducer";
import {useDispatch} from "react-redux";


const HeaderProfiling = ({isActiveSignin, isActiveLogout}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className="flex-1">
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to={INDEX}>
                    <img src={logo} width={150} alt={'logo'} />
                </Link>
                {
                    isActiveSignin && (
                        <div className="text-sm">
                            {t("oauth.login.question")} <Link className="font-medium text-indigo-500 hover:text-indigo-600" to={LOGIN}>{t("oauth.login.name")}</Link>
                        </div>
                    )
                }

                {
                    isActiveLogout && (
                        <div className="text-sm"><Link
                            className="font-medium text-indigo-500 hover:text-indigo-600"
                            to={'#'}
                            onClick={() => {
                                dispatch(safeLogout());
                            }}
                        >{t("oauth.logout.name")}</Link>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default HeaderProfiling;
