import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormPopup from '../common/popup/form-popup';
import InputSubmit from '../common/form/input-submit';
import DynamicSearch from '../partials/common/dynamic-search';
import { getPathParam } from '../../utils/converter';
import { COMPANY } from '../../api/endpoints';
import { useSelector } from 'react-redux';
import { BOARD, BOARDS, STATUSES } from '../../routes/endpoints';
import UsersToolbar from '../common/card/users-toolbar';
import UserInitial from '../common/table/user-initial';
import { getCustomFormat, getFormattedDate } from '../../utils/timeUtils';
import TooltipItem from '../common/button/tooltip';
import { ChatIcon, ClipboardIcon, ClockIcon, DocumentTextIcon, MenuAlt1Icon, PaperClipIcon } from '@heroicons/react/outline';
import CalendarIcon from '../../resources/icons/CalendarIcon';
import { getFileType } from '../../constants/file-extension';
import TaskManage from '../../routes/board/task-manage';
import CustomUserSelect from '../common/form/custom-user-select';
import moment from 'moment';
import NumberFormat from 'react-number-format';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function addNewLine(stringa, len) {
  let risultato = '';
  let parole = stringa.split(' ');
  let rigaCorrente = '';

  for (let i = 0; i < parole.length; i++) {
    if (rigaCorrente.length + parole[i].length + 1 > len) {
      risultato += rigaCorrente.trim() + '\n';
      rigaCorrente = '';
    }
    rigaCorrente += parole[i] + ' ';
  }

  risultato += rigaCorrente.trim();

  return risultato;
}

function CompanyBoard({ height, data, isEditing, setIsOpen, dashboardWidget, onFinalSubmit, onRefreshed }) {


  const [isOpenTask, setOpenTask] = useState(false);
  const [statusTaskDetail, setStatusTaskDetail] = useState(false);
  const [boardData, setBoardData] = useState(false);
  const [taskDetail, setTaskDetail] = useState(false);


  return (
    <div style={{ height: height + 'px' }} className='overflow-x-hidden overflow-y-scroll'>

      {
        (data?.tasks ?? []).map((task) => (
          <QuoteItem key={task.id} task={task} onOpen={() => {
            setStatusTaskDetail(task.board_status);
            setBoardData(task.virtual_board);
            setTaskDetail(task);
            setOpenTask(true);
          }} />
        ))
      }

      {
        (statusTaskDetail && boardData && taskDetail) && (
          <TaskManage
            isOpen={isOpenTask}
            setIsOpen={setOpenTask}
            boardData={boardData}
            statusDetail={statusTaskDetail}
            taskDetail={taskDetail}
            onRefresh={(r) => {
              onRefreshed();
            }}
            setClean={() => {
              setStatusTaskDetail(false);
              setBoardData(false);
              setTaskDetail(false);
              setOpenTask(false);
            }}
            onOpenReletadTask={(taskData) => {
              setStatusTaskDetail(taskData.board_status);
              setBoardData(taskData.virtual_board);
              setTaskDetail(taskData);
              setOpenTask(true);
            }}
          />
        )
      }
       
      <BoardEdit
        isEditing={isEditing}
        setIsOpen={setIsOpen}
        dashboardWidget={dashboardWidget}
        onFinalSubmit={(r) => {
          onFinalSubmit(r);
        }}
      />
    </div>
  );
}


const QuoteItem = ({ task, onOpen }) => {
  const [pictureAttachment, setPictureAttachment] = useState(false);
  const { t } = useTranslation();
  const today = new Date();
  const { company } = useSelector((state) => state.userCompany);

  const findPirctureAttachment = (attachments) => {

    let flag = true;
    let isStop = false;
    // eslint-disable-next-line
    attachments.map((attachment) => {
      if (attachment.file && !isStop) {
        const ext = attachment.file.reference.split('.').pop();
        const fileType = getFileType(ext);
        if (fileType && fileType.split('/').shift() === 'image') {
          setPictureAttachment(attachment.file.reference);
          flag = false;
          isStop = true;
        }
      }
    });

    if (flag) {
      setPictureAttachment(false);
    }
  }

  useEffect(() => {
    if (task) {
      findPirctureAttachment(task?.task_attachments ?? []);
    }
  }, [task])


  const getCheckListCount = (checklists) => {
    let total = 0;
    let confirmed = 0;
    // eslint-disable-next-line
    (checklists ?? []).map((checklist) => {
      // eslint-disable-next-line
      (checklist?.checklists ?? []).map((item) => {
        // eslint-disable-next-line
        if (item.status) {
          confirmed = confirmed + 1;
        }
        total = total + 1;
      })
    })
    return confirmed + "/" + total;
  }

  const getCheckPercentage = (checklists) => {
    let percentage = 0;
    let confirmed = 0;
    let total = 0;
    // eslint-disable-next-line
    (checklists ?? []).map((checklist) => {
      // eslint-disable-next-line
      (checklist?.checklists ?? []).map((item) => {
        // eslint-disable-next-line
        if (item.status) {
          confirmed = confirmed + 1;
        }
        total = total + 1;
      })
    })

    if (total > 0) {
      percentage = (100 / total) * confirmed;
    }

    return percentage;
  }


  const taskMembers = (task) => {
    let users = [];
    if (task.assigned_users.length > 0) {
      users = task.assigned_users.filter(r => r.id !== task.user.id);
    }
    users.splice(0, 0, task.user);
    return users;
  }



  return (
    <div
      className={` border-b border-gray-200  bg-white hover:bg-gray-100  select-none flex  flex-col cursor-pointer`}
      onClick={() => {
        onOpen(true)
      }}
    >

      {
        task && (
          <>
            {
              pictureAttachment && (
                <div className={' bg-gray-900 '}>
                  <img className="w-full  object-contain     h-[260px] max-h-[260px]" src={pictureAttachment} alt="" />
                </div>
              )
            }
            <div className='p-2'>
              <div className={classNames(
                task.check_list.length > 0 && "mb-2",
                'flex items-center justify-between'
              )}>
                <h1 className="text-xs font-normal text-gray-900" style={{ fontSize: '10px' }}>{task.task_reference}</h1>
                {
                  task.check_list.length > 0 && (
                    <div className="flex items-center text-xs text-gray-600 cursor-default">
                      <TooltipItem position={"left"} tooltipsText={t("app.board.tol_checklist")}>

                        <div className='flex items-center'>
                          <div className="w-[30px] bg-gray-200 rounded">
                            <div className={classNames(
                              getCheckPercentage(task.check_list) >= 50 ? 'shim-green' : '',
                              getCheckPercentage(task.check_list) < 50 ? 'shim-red' : '',
                              "text-xs font-medium rounded text-white text-center p-1 leading-none"
                            )} style={{ width: getCheckPercentage(task.check_list) + '%' }}>
                            </div>
                          </div>
                          <p className='flex ml-1 items-center'>
                            {getCheckListCount(task.check_list)}
                            <ClipboardIcon className=" h-4 w-4 ml-1 flex-shrink-0 text-gray-600" aria-hidden="true" />
                          </p>
                        </div>
                      </TooltipItem>
                    </div>
                  )
                }
              </div>

              <h1 className="text-base font-medium text-gray-900 whitespace-pre-line">
                {addNewLine(task.title, 30)}
              </h1>



              {
                task.task_date && (
                  <div className="mt-1 ">
                    <div className={classNames("mt-2 flex items-center text-xs  mr-2",
                      (moment(task.end_date, "YYYY-M-D HH:mm:ss") <= today) ? "bg-red-500 text-white rounded p-1" : "text-gray-600",
                    )}>

                      {
                        (moment(task.end_date, "YYYY-M-D HH:mm:ss") <= today) ? (
                          <ClockIcon className="w-4 h-4 mr-1 flex-shrink-0 " />
                        ) : (
                          <CalendarIcon className="mr-1 h-4 w-4  flex-shrink-0 " aria-hidden="true" />
                        )
                      }

                      {getCustomFormat(task.task_date, "DD/MM/YYYY")}
                      {
                        task.end_date && (
                          <>
                            -  {getCustomFormat(task.end_date, "DD/MM/YYYY")}
                          </>
                        )
                      }
                    </div>
                  </div>

                )
              }

              {
                (task?.properties ?? []).filter(r => (r?.attach ?? false) === true).length > 0 && (
                  <div className="my-2">
                    {
                      task.properties.filter(r => (r?.attach ?? false) === true).map(r => (
                        <div className={classNames("mt-1 flex items-center text-gray-900 text-xs  mr-2")}>

                          <span>{r.title}:{' '}
                            {
                              r.type === 'date' && (
                                <b> {getFormattedDate(r.value)}</b>
                              )
                            }

                            {
                              r.type === 'text' && (
                                <b> {r.value}</b>
                              )
                            }

                            {
                              r.type === 'number' && (
                                <b>
                                  <NumberFormat
                                    value={r.value}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={""}
                                  />
                                </b>
                              )
                            }


                            {
                              r.type === 'currency' && (
                                <b>
                                  <NumberFormat
                                    value={r.value}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                  />
                                </b>
                              )
                            }

                          </span>
                        </div>
                      ))
                    }
                  </div>
                )
              }





              {
                (task.description || task.related_tasks_count > 0 || task.attachment_count > 0 || task.activities_count > 0) ? (
                  <div className=" flex flex-row flex-wrap mb-3">

                    {
                      task.description && (
                        <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                          <TooltipItem position={"right"} tooltipsText={t("app.board.tol_description")}>
                            <MenuAlt1Icon className="h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                          </TooltipItem>
                        </div>
                      )
                    }
                    {
                      task.related_tasks_count > 0 && (
                        <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                          <TooltipItem position={"right"} tooltipsText={t("app.board.tol_sub_task")}>
                            <DocumentTextIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                            {task.related_tasks_count}
                          </TooltipItem>
                        </div>
                      )
                    }

                    {
                      task.attachment_count > 0 && (
                        <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                          <TooltipItem position={"right"} tooltipsText={t("app.board.tol_attachment")}>
                            <PaperClipIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                            {task.attachment_count}
                          </TooltipItem>
                        </div>
                      )
                    }

                    {
                      task.activities_count > 0 && (
                        <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                          <TooltipItem position={"right"} tooltipsText={t("app.board.tol_comment")}>
                            <ChatIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                            {task.activities_count}
                          </TooltipItem>
                        </div>
                      )
                    }

                  </div>
                ) : (
                    <div className='mb-4' />

                )}


              <div className='flex items-end justify-between relative'>

                <div>
                  {
                    task.created === task.updated ? (
                      <p className="mt-0.5 text-xs text-gray-500" style={{ fontSize: '10px' }}>
                        {t("app.board.created_on", { date: getCustomFormat(task.created, "DD/MM/YYYY") })}
                      </p>
                    ) : (
                      <p className="mt-0.5 text-xs text-gray-500" style={{ fontSize: '10px' }}>
                        {t("app.board.updated_on", { date: getCustomFormat(task.updated, "DD/MM/YYYY") })}
                      </p>
                    )
                  }
                </div>

                {
                  taskMembers(task).length > 0 && (
                    <UsersToolbar users={(taskMembers(task))} isActive={taskMembers(task).length > 0} extraData={getCustomFormat(task.created, "DD/MM/YYYY")} className={'absolute right-0'}>
                      <div className="flex items-center space-x-2 mr-3">
                        <div
                          className={taskMembers(task).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                          {(taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).map((user, index) => (
                            index === 0 ? (
                              <UserInitial user={user} ringClass={" ring-yellow-400 "} />
                            ) : (
                              <UserInitial user={user} />
                            )
                          ))}
                        </div>

                        {taskMembers(task).length > (taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).length ? (
                          <span className="flex-shrink-0 text-xs font-medium leading-5">
                            +{taskMembers(task).length - (taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).length}
                          </span>
                        ) : null}
                      </div>
                    </UsersToolbar>
                  )
                }

              </div>



            </div>


          </>
        )
      }



    </div>
  );
}

function BoardEdit({ isEditing, setIsOpen, dashboardWidget, onFinalSubmit }) {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState();

  const {
    handleSubmit,
    control,
    setValue,
    watch
  } = useForm();

  const onSubmit = (data) => {
   const output = {
      id: dashboardWidget.id,
      configuration: {
        board_id: data.dynamic_board.id,
        status_id: data.dynamic_status.id,
        users: data.users
      }
    };
    onFinalSubmit(output)
  };


  useEffect(() => {

    if (watch('dynamic_board') && watch('dynamic_board').assigned_users.length > 0 && dashboardWidget.users && dashboardWidget.users.length > 0){
      let data = [];

      // eslint-disable-next-line
      dashboardWidget.users.map(d => {
        data.push(watch('dynamic_board').assigned_users.find(n=> n.id === d));
      })
      setSelectedUsers(data);
    }

    // eslint-disable-next-line
  }, [dashboardWidget, watch('dynamic_board') , setValue]);

  

  return (
    <FormPopup
      title={dashboardWidget.dashboard_widget.name}
      isOpen={isEditing}
      setIsOpen={(r) => {
        setIsOpen(r);
      }}
    >

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
          <div className="col-span-2 md:col-span-1 ">
            <Controller
              name="dynamic_board"
              rules={{ required: false }}
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <DynamicSearch
                  selectLabel={t("app.board.title")}
                  endpoint={getPathParam([COMPANY, loggedCompany.id, BOARDS])}
                  responseLabel={'boards'}
                  label={'title'}
                  errors={error}
                  value={value}
                  name={name}
                  onResponse={(r) => {
                    let item = r.find(item => item.id === dashboardWidget?.configuration?.board_id ?? '');
                    if (item) {
                      setValue('dynamic_board', item);
                    }
                  }}
                  onChange={async (r) => {
                    onChange(r);
                  }}
                />
              )}
            />
          </div>


          <div className="col-span-2 md:col-span-1 ">
            <Controller
              name="dynamic_status"
              rules={{ required: false }}
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <DynamicSearch
                  selectLabel={t("app.board.status_select")}
                  endpoint={getPathParam([BOARD, watch('dynamic_board')?.id ?? '', STATUSES])}
                  responseLabel={'statuses'}
                  label={'title'}
                  errors={error}
                  value={value}
                  isDisabled={!watch('dynamic_board')}
                  name={name}
                  onResponse={(r) => {
                    let item = r.find(item => item.id === dashboardWidget?.configuration?.status_id ?? '');
                    if (item) {
                      setValue('dynamic_status', item);
                    }
                  }}
                  onChange={async (r) => {
                    onChange(r);
                  }}
                />
              )}
            />
          </div>


          <div className="col-span-2 md:col-span-2 ">
            <CustomUserSelect
              label={t('app.board.board_user')}
              users={watch('dynamic_board')?.assigned_users ?? []}
              isShowLabel={false}
              placeholder={''}
              multiple={true}
              includeAll={true}
              excludeId={''}
              selectedUsers={selectedUsers}
              onSelect={(r) => {
                setSelectedUsers(r);

             
                let data = [];
                if (r.length > 0) {
                  // eslint-disable-next-line
                  r.map((user) => {
                    data.push(user.id)
                  })
                }

                setValue('users', data);
               
              }}
            />
          </div>



          


        </div>

        <div className="flex before:flex-1 items-center justify-between mt-6">
          <InputSubmit
            isLoading={false}
            label={t("app.form.save")}
          />
        </div>

      </form>

    </FormPopup>
  );
}



export default CompanyBoard;
