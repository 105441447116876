import React from "react";
import BaseList from "../../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getWebPathParam} from "../../../../../utils/converter";
import {
    PRODUCT,
    ALTERNATIVE
} from "../../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../../components/common/table/actions-menu";
import TableDetail from "../../../../../components/common/list/table-detail";
import {useOutletContext, useParams} from "react-router-dom";
import { TemplateIcon} from "@heroicons/react/outline";
import BasePermissionWrapper from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../../../constants/permissions";
import SearchProduct from "./search-product";
import OptionalLink from "../../../../../components/common/table/optional-link";
import { CATALOG, PRODUCT_DETAIL } from "../../../../endpoints";
import ProductCell from "../../../../../components/common/table/product-cell";

const ProductAlternative = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();
    const { setNeedRefresh } = useOutletContext();


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_SUPPLIER, permissionType.LIST)]} renderBaseOnFail={true}>
            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.form.name"),
                            "",
                        ]}
                        endpoint={getPathParam([PRODUCT, id, ALTERNATIVE])}
                        storeEndpoint={getPathParam([PRODUCT, id, ALTERNATIVE])}
                        updateEndpoint={getPathParam([PRODUCT, id, ALTERNATIVE, ':id'])}
                        baseResult={{ total_rows: 0, alternative: []}}
                        resultLabel={'alternative'}
                        title={t("app.form.name")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={TemplateIcon}
                        addFormLabel={t("app.catalog.add", { type: t("app.catalog.alternative")})}
                        btnAddLabel={t("app.catalog.add", { type: t("app.catalog.alternative")})}
                        addPermission={[]}
                        isActiveEdit={true}
                        isCustomAdd={true}
                        onUpdateParent={(r) => {
                            setNeedRefresh(r);
                        }}
                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();

  
    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, ALTERNATIVE, element.id])
    };

    return (
        <li key={element.id} className={'flex items-center gap-4 p-4'}>
            <OptionalLink
                isLinked={true}
                to={getWebPathParam([CATALOG, PRODUCT, element.id, PRODUCT_DETAIL])}
                className="flex-grow"
            >

                <div className="pb-4 items-center flex justify-between">

                    <div className="flex items-center ">
                        <ProductCell product={element.product} isActiveManufacture={true} hideArchiveStatus={false} />
                    </div>


                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>


            </OptionalLink>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, ALTERNATIVE, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail  >
                <OptionalLink
                    isLinked={true}
                    to={getWebPathParam([CATALOG, PRODUCT, element.product.id, PRODUCT_DETAIL])}
                    className="hover:text-gray-600"
                >
                    <ProductCell  product={element.product}  isActiveManufacture={true} hideArchiveStatus={false} />
                </OptionalLink>
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, dataGet, onSubmitData, isOpen, setIsOpen }) =>  {

    const { t } = useTranslation();
    let { id } = useParams();

    return (
        
            <SearchProduct
                title={t("app.catalog.selected_product_title")}
                description={t("app.catalog.product_title_description")}
                isOpen={isOpen}
                exludeId={id}
                isLoadingData={isLoading}
                exception={exception}
                setIsOpen={setIsOpen}
                selectedProductList={dataGet.alternative}
                onSelectedProductList={(products) => {
                    let prod = [];
                    // eslint-disable-next-line
                    products.map(product => {
                        // eslint-disable-next-line
                        prod.push({ product: { id: product .id}})
                    })

                    onSubmitData(prod)
                }}
            />
    );
}



export default ProductAlternative;
