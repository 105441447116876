import {useTranslation} from "react-i18next";
import React from "react";

const InputRadio = ({label, icon, name, errors, warning, defaultChecked = false, input, value}) => {

    const { t } = useTranslation();

    return (
        <label className="relative block cursor-pointer">
            <input type="radio"
                   name={name}
                   className="peer sr-only"
                   defaultChecked={defaultChecked}
                   value={value}
                   {...input}
            />
            <div className="flex items-center bg-white text-sm font-medium text-gray-800 p-4 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                {icon}
                <span>{label}</span>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-800 rounded pointer-events-none" aria-hidden="true" />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label.toLowerCase()})}</p>)}
        </label>
    )
};
export default InputRadio;
