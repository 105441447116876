import React, {useEffect, useState} from "react";
import SlidePanel from "../../../components/layout/slide-panel";
import {
    getExportData,
    getExportFilter,
    getExportFilterCount,
    putExportData
} from "../../../api/import";
import {useSelector} from "react-redux";
import {Warning} from "../../../components/common/alert/banner";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../components/common/form/button-submit";
import {getFormattedDate} from "../../../utils/timeUtils";
import ReportViewer from "../../../components/partials/report/report-viewer";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import ListSection from "../../../components/layout/list-section";
import FileCell from "../../../components/common/table/file-cell";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";

export default function GenericExport({ isOpen, setIsOpen, exportInfo }) {

    const { loggedCompany } = useSelector((state) => state.user);

    // Load Process
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [exportData, setExportData] = useState(false);
    const { t } = useTranslation();

    const [isOpenReportView, setOpenReportView] = useState(false);
    const [isLoadingExports, setLoadingExports] = useState(true);
    const [exportList, setExportList] = useState({ export_log: [], total_rows: 0 });

    //Filter
    const [dynamicFilters, setDynamicFilters] = useState([]);
    const [isLoadingFilters, setLoadingFilters] = useState(false);
    const [activeDynamicFilter, setDynamicDateFilter] = useState([]);

    const [isLoadingCounter, setLoadingCounter] = useState(false);
    const [counters, setCounters] = useState(0);

    const [offset, setOffset] = useState(0);
    const [query, setQuery] = useState("");

    const columns = [
        t("app.common.export_file"),
        t("app.common.export_user")
    ];

    const putExport = async ()  => {
        setLoading(true);
        setException(false);
        if(loggedCompany){
            putExportData({company_id: loggedCompany.id, import_type: exportInfo.type, data: activeDynamicFilter})
                .then(response => {
                    setLoading(false);
                    setExportData(response);
                    setOpenReportView(true);
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };


    useEffect(() => {
        const getFilter = async ()  => {
            setLoadingFilters(true);
            setException(false);
            setDynamicFilters([]);
            if(loggedCompany && isOpen){
                getExportFilter({company_id: loggedCompany.id, import_type: exportInfo.type})
                    .then(response => {
                        setDynamicFilters(response);
                        setLoadingFilters(false);
                    }).catch(e => {
                    setException(e.message);
                    setLoadingFilters(false);
                });
            }
        };

        getFilter();
        // eslint-disable-next-line
    }, [loggedCompany, exportInfo, isOpen]);



    useEffect(() => {

        const getFilterCount = async ()  => {
            if(loggedCompany){
                setLoadingCounter(true);
                setException(false);
                getExportFilterCount({
                    company_id: loggedCompany.id,
                    import_type: exportInfo.type,
                    data: activeDynamicFilter
                })
                    .then(response => {
                        setCounters(response.count);
                        setLoadingCounter(false);
                    }).catch(e => {
                    setException(e.message);
                    setLoadingCounter(false);
                });
            }
        };

        getFilterCount();
        // eslint-disable-next-line
    }, [loggedCompany, exportInfo, activeDynamicFilter]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchExports() {
            if (loggedCompany && isOpen) {
                setLoadingExports(true);
                getExportData({
                    company_id: loggedCompany.id,
                    entity: exportInfo.entity,
                    params: { limit: 15, offset: 0, type: exportInfo?.type ?? null },
                    signal,
                })
                    .then((response) => {
                        setExportList(response);
                        setLoadingExports(false);
                    })
                    .catch((e) => {
                        setExportList({ export_log: [], total_rows: 0 });
                        setLoadingExports(false);
                    });
            }
        }


        fetchExports();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [offset, query, loggedCompany, isOpen, exportInfo, exportData]);

    const clean = async () => {
        setDynamicFilters([]);
        setDynamicDateFilter([]);
        setException(false);
        await setIsOpen(false)
    };


    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={exportData?.file ?? false}
                title={exportData.export_type ? t("app.products."+(exportData.export_type)) : ''}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}
            />

            <SlidePanel
                title={t("app.products.export_title")}
                bg={"bg-white"}
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsOpen(false)
                }}
                size={'max-w-4xl'}
                setClean={() => {
                    clean();
                }}

            >
                <div className="pt-6 pb-5">

                    {
                        dynamicFilters.length > 0 && (
                            <>
                                <label className="block text-sm font-medium mb-1 text-gray-700">{t("app.products.export_filter")}</label>
                                <div className="flex flex-wrap gap-4 ">
                                    <DynamicFilterMenu
                                        isActiveHeaderParams={false}
                                        filters={dynamicFilters}
                                        isLoading={isLoadingFilters}
                                        onChange={(r) => {
                                            setDynamicDateFilter(r);
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }


                    {/* Warning */}
                    {exception && <Warning message={exception} />}


                    <div className="mt-4 mb-3">
                        <ButtonSubmit
                            isLoading={isLoading || isLoadingCounter}
                            label={counters > 0 ? t("app.common.confirm_export", {counter: counters}) : t("app.common.no_export")}
                            isFullWith={true}
                            onClick={() => {
                                putExport();
                            }}
                            disabled={counters === 0}
                        />
                    </div>


                    <label className="border-t pt-6 block text-sm font-medium mt-2 mb-1 text-gray-700">{t("app.products.export_log")}</label>


                    <div className="border">
                        <ListSection
                            title={"Exports"}
                            showHeader={false}
                            columns={columns}
                            totalRows={exportList.total_rows}
                            list={exportList.export_log}
                            onSearch={setQuery}
                            isLoading={isLoadingExports}
                            paginationOnChange={setOffset}
                            mobileRow={MobRow}
                            desktopRow={DeskRow}
                            extras={{
                                onOpenFile: (r) => {
                                    setExportData(r);
                                    setOpenReportView(true);
                                }
                            }}
                        />
                    </div>






                </div>
            </SlidePanel>

        </>
    );
}

const MobRow = ({ element, extras }) => {

    const { t } = useTranslation();

    return (
        <li key={element.id}
            className="flex items-center justify-between gap-4 p-4 cursor-pointer"
        >
            <div className="pb-4 items-center flex justify-between">
                <FileCell file={{file: element.file, file_name: t("app.products.import_"+element.export_type)}} onOpenFile={() => {
                    extras.onOpenFile(element);
                }} />
            </div>

            <div className="flex flex-col">
                <CardDetail title={t("app.common.export_user")}>
                    {element.user}
                </CardDetail>

                <CardDetail title={t("app.common.date")}>
                    {getFormattedDate(element.export_date)}
                </CardDetail>
            </div>

        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();

    return (
        <tr
            key={element.id}
        >
            <TableDetail extraClass={"whitespace-pre-line"} >
                <FileCell file={{file: element.file, file_name: t("app.products."+element.export_type)}} onOpenFile={() => {
                    extras.onOpenFile(element);
                }} />
            </TableDetail>


            <TableDetail>
                <div className="text-sm text-gray-900">
                    {element.user}
                </div>
                <div className="text-xs flex-wrap text-gray-700">
                    {getFormattedDate(element.export_date)}
                </div>
            </TableDetail>
        </tr>
    );
};




