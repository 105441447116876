import {COMPANY, TASKS, TASK, MESSAGES, MESSAGE, NOTIFICATION} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// customer
export const getTasks = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, TASKS] ) + (params ? serialize(params)  : ''), {signal: signal});

export const getTask = ({company_id, task_id, signal}) =>
    API.get(getPathParam([COMPANY, company_id, TASK, task_id ]), {signal: signal});

export const postTask = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, TASK]), data);

export const putTask = ({company_id, task_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, TASK, task_id]), data);

export const putTasks = ({company_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, TASKS]), data);


export const getTaskMessages = ({company_id, task_id, params, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, TASK, task_id, MESSAGES] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const postTaskMessage = ({company_id, task_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, TASK, task_id, MESSAGE]), data);

export const putTaskMessage = ({company_id, task_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, TASK, task_id, NOTIFICATION]), data);
