import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {ChevronDownIcon, CheckIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";


export default function InputSelectSingle({
                                              options,
                                              label,
                                              isRequired,
                                              id,
                                              errors,
                                              warning,
                                              warningRegex,
                                              onChangeData,
                                              translationPattern,
                                              valueData = false
                                          }) {
    const { t } = useTranslation();



    return (
        <div className="relative">
            <label
                htmlFor={id}
                className="block text-sm font-medium mb-1 text-gray-700"
            >
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <Listbox
                value={valueData}
                onChange={(e) => {
                    onChangeData(e);
                }}
            >
                <div className="relative mt-1">


                    <Listbox.Button
                        className={`form-input w-full text-left ${
                            errors
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                : ""
                        }`}
                    >
                        <span className="block truncate">{valueData  ? t(translationPattern+valueData) : t("app.common.placeholder", {
                            field: label.toLowerCase(),
                        })}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                          />
                        </span>
                    </Listbox.Button>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option}
                                    value={option}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                        }`
                                    }
                                >
                                    {({ selected }) => (
                                        <>
                                              <span
                                                  className={`block truncate ${
                                                      selected ? 'font-medium' : 'font-normal'
                                                  }`}
                                              >
                                                {t(translationPattern+option)}
                                              </span>

                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {errors && errors.type === "required" && (
                <p className="mt-2 text-sm text-red-600">
                    {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "validate" && (
                <p className="mt-2 text-sm text-red-600">
                    {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "pattern" && (
                <p className="mt-2 text-sm text-red-600">
                    {warningRegex
                        ? warningRegex
                        : t("app.warning.description_regex", { field: label })}
                </p>
            )}
        </div>
    )
}
