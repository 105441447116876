import {SHIPPING, DOCUMENTS, DOCUMENT, SHIPPING_IMPORTER} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";


// Document
export const getDocuments = ({shipping_id, params}) =>
    API.get(getPathParam([SHIPPING, shipping_id, DOCUMENTS] ) + serialize(params));

// Exporter
export const getDocument = ({shipping_id, document_id, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, DOCUMENT, document_id ] ), {signal: signal});

export const postDocument = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, DOCUMENT]), data);

export const putDocument = ({shipping_id, document_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, DOCUMENT, document_id]), data);

export const deleteDocument = ({shipping_id, document_id}) =>
    API.delete(getPathParam([SHIPPING, shipping_id, DOCUMENT, document_id]));

// Importer

export const getDocumentImporter = ({shipping_id, document_id, signal}) =>
    API.get(getPathParam([SHIPPING_IMPORTER, shipping_id, DOCUMENT, document_id ] ), {signal: signal});

export const postDocumentImporter = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING_IMPORTER, shipping_id, DOCUMENT]), data);

export const putDocumentImporter = ({shipping_id, document_id, data}) =>
    API.put(getPathParam([SHIPPING_IMPORTER, shipping_id, DOCUMENT, document_id]), data);

export const deleteDocumentImporter = ({shipping_id, document_id}) =>
    API.delete(getPathParam([SHIPPING_IMPORTER, shipping_id, DOCUMENT, document_id]));
