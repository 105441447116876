import React, {useEffect, useState} from "react";
import PageHeaderMenu from "../../../components/layout/page-header-menu";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import DocumentIcon from "../../../resources/icons/DocumentIcon";
import IntegrationIcon from "../../../resources/icons/IntegrationIcon";
import {
    CONTAINERS, DASHBOARD,
    DOCUMENTS,
    EVENTS, LOGISTICS, P404,
    SHIPPING,
    SHIPPING_DETAIL,
    SHIPPING_OVERVIEW, TRACKING,
} from "../../endpoints";
import {useParams, useLocation, Outlet, useNavigate} from "react-router-dom";
import {getShipping, putShippingStatus} from "../../../api/shipping";
import {useSelector} from "react-redux";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../../constants/permissions";
import {useTranslation} from "react-i18next";
import {COMPANY_EXPORTER, COMPANY_IMPORTER, SEA} from "../../../constants/config";
import {getPathParam, getWebPathParam} from "../../../utils/converter";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import PanelNavigation from "../../../components/partials/panel/panel-navigation";
import {DocumentTextIcon, MapIcon, TruckIcon} from "@heroicons/react/outline";
import AlertNotification from "../../../components/common/notification/alert-notification";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import {COMPANY, STATUS} from "../../../api/endpoints";
import {ShippingStatusList} from "../../../components/common/alert/shipping-status-list";
import toast from "react-hot-toast";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";

const ShippingEdit = () => {

    let {id}  = useParams();
    const { t } = useTranslation();
    let navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;
    const { loggedCompany, isExporter, companyType } = useSelector((state) => state.user);

    const [isShippingDetailLoading, setShippingDetailLoading] = useState(true);
    const [shippingDetail, setShippingDetail] = useState(null);



    // Change Status
    const [isLoading, setLoading] = useState(false);
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const { permissions } = useSelector((s) => s.userCompany);

    const navigation = [
        { name: t("app.shippings.shipping_detail"), permission: [getPermission(permissionGroup.SHIPPING, permissionType.EDIT)], location: LOGISTICS + SHIPPING + '/'+id + SHIPPING_DETAIL , icon: DocumentTextIcon, current: pathname.includes(SHIPPING_DETAIL), statusEditor: isExporter },
        { name: (shippingDetail?.shipping_type?.code === SEA ?? false) ? t("app.container.containers") : t("app.container.trucks"), permission: [getPermission(permissionGroup.CONTAINER, permissionType.LIST)], location: LOGISTICS + SHIPPING + '/'+id + CONTAINERS, icon: (shippingDetail?.shipping_type?.code === SEA ?? false) ? ContainerIcon : TruckIcon, current: pathname.includes(CONTAINERS), statusEditor: false },
        { name: t("app.document.documents"), permission: [getPermission(permissionGroup.DOCUMENT, permissionType.LIST)], location: LOGISTICS + SHIPPING + '/'+id + DOCUMENTS, icon: DocumentIcon, current: pathname.includes(DOCUMENTS), statusEditor: false },
        { name: t("app.event.events"), permission: [getPermission(permissionGroup.EVENT, permissionType.LIST)], location: LOGISTICS + SHIPPING + '/'+id + EVENTS, icon: IntegrationIcon, current: pathname.includes(EVENTS), statusEditor: false },
        (shippingDetail?.shipping_type?.code === SEA && (shippingDetail && shippingDetail.track_shipping === true)) && { name: t("app.shipping_tracking.title"), permission: [getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_ACTIVE_TRACKING)], location: LOGISTICS + SHIPPING + '/'+id + TRACKING , icon: MapIcon, current: pathname.includes(TRACKING), notification_status: true, statusEditor: false },
    ].filter(Boolean);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchShipping() {
            if (loggedCompany) {
                setShippingDetailLoading(true);
                getShipping({ company_id: loggedCompany.id, shipping_id: id, signal })
                    .then((response) => {

                        if(isExporter && response.creator_type === COMPANY_IMPORTER  && !response.can_edit){
                            navigate(getWebPathParam([LOGISTICS, SHIPPING, response.id, SHIPPING_OVERVIEW]));
                        }

                        if(!isExporter && response.creator_type === COMPANY_EXPORTER  && !response.can_edit){
                            navigate(getWebPathParam([LOGISTICS, SHIPPING, response.id, SHIPPING_OVERVIEW]));
                        }

                        if(companyType !== response.company_type){
                            navigate(getWebPathParam([DASHBOARD]));
                        }

                        setShippingDetail(response);
                        setShippingDetailLoading(false);
                    })
                    .catch((e) => {
                        e.code === 404 && navigate(P404);
                        setShippingDetailLoading(false);
                    });
            }
        }

        fetchShipping();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, id, isExporter]);



    const onChangeStatus = async (status) => {
        if (loggedCompany) {
            setLoading(true);
            putShippingStatus({ company_id: loggedCompany.id, shipping_id: id, status: status })
                .then((response) => {
                    setLoading(false);
                    toast.success(t("app.shippings.update_success"))
                    setShippingDetail(response);
                }).catch((e) => {
                setLoading(false);
            });
        }
    };


    return (
        <LoaderWrapper isLoading={isShippingDetailLoading}>
            <SubscriptionWrapper requiredPermission={sp.SHIPPING_INTERNAL_PACKAGE} renderBaseOnFail={true} redirectOnFail={true}>

                <div className="flex h-full">

                    <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                        <div className="relative z-0 flex flex-1 overflow-hidden">
                            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                                {/* Start main area*/}
                                <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                                    <div className="max-w-7xl mx-auto mb-3">

                                        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_ACTIVE_TRACKING)]}>
                                            {
                                                ((navigation.find(r => r.current === true)?.notification_status ?? false) && (shippingDetail?.tracking_state ?? false)) && (
                                                    <div className="mb-3">
                                                        <AlertNotification color={shippingDetail.tracking_state.error_color} code={shippingDetail.tracking_state.error_code} />
                                                    </div>
                                                )
                                            }


                                            {
                                                ((shippingDetail?.shipping_line?.traceable === false ?? false)) && (
                                                    <div className="mb-3">
                                                        <AlertNotification color={'red'} code={'U01'} />
                                                    </div>
                                                )
                                            }
                                        </BasePermissionWrapper>

                                        <PageHeaderMenu
                                            taskContext={'shipping'}
                                            taskContextId={id}
                                            title={navigation.find(r => r.current === true)?.name ?? ''}
                                            statusEditor={navigation.find(r => r.current === true)?.statusEditor ?? false}
                                            navigation={navigation}
                                            customer={shippingDetail?.customer ?? null}
                                        >

                                            {
                                                isExporter && (
                                                    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_CLOSE)]}>
                                                            <ShippingStatusList
                                                                isLoading={isLoading}
                                                                status={shippingDetail?.status ?? 'draft'}
                                                                isEditable={isExporter}
                                                                onChangeStatus={(r) => {
                                                                    if(r === 'in_progress'){
                                                                        const detailData = {
                                                                            title: t("app.shippings.status_confirm_title"),
                                                                            message: t("app.shippings.status_confirm_description", {status: t("app.shippings.status_"+r)}),
                                                                            endpoint: getPathParam([COMPANY, loggedCompany.id, SHIPPING, shippingDetail.id, STATUS, r ]),
                                                                        };
                                                                        setConfirmDetail(detailData);
                                                                        setOpenConfirm(true);
                                                                    }else{
                                                                        onChangeStatus(r)
                                                                    }
                                                                }}
                                                            />

                                                    </BasePermissionWrapper>
                                                )
                                            }





                                        </PageHeaderMenu>
                                    </div>
                                    <div className="max-w-7xl mx-auto">
                                        <Outlet
                                            context={{
                                                shippingDetail,
                                                isSeaType: shippingDetail?.shipping_type?.code === SEA ?? false,
                                                setShippingDetail,
                                                canEdit: (companyType === (shippingDetail?.creator_type ?? null) && (permissions.isSuperAdmin || permissions.isAdmin)),
                                                isOwner: (companyType === (shippingDetail?.creator_type ?? null)),
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* End main area */}
                            </main>
                            <aside className="relative hidden w-80 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                                {/* Start secondary column (hidden on smaller screens) */}
                                <div className="absolute inset-0 ">
                                    <PanelNavigation
                                        isProfileView={true}
                                        navigation={navigation}
                                        customer={shippingDetail?.customer}
                                        isCenterTitle={true}
                                        notificationStatus={shippingDetail?.tracking_state ?? false}
                                    />
                                </div>
                                {/* End secondary column */}
                            </aside>
                        </div>

                    </div>
                </div>

                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() =>{
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(r) => {
                            setShippingDetail(r);
                        }}
                    />
                )}

            </SubscriptionWrapper>
        </LoaderWrapper>
    );
};

export default ShippingEdit;
