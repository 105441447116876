import React, {useEffect, useState} from "react";
import {DotsVerticalIcon, PlusCircleIcon} from "@heroicons/react/outline";
import {ActionButton} from "../../layout/page-header";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";
import {getElement} from "../../../api/config";
import {getPathParam} from "../../../utils/converter";
import {COMPANY, CONNECTION, LOGS, LOG} from "../../../api/endpoints";
import DynamicForm from "../../common/form/dynamic-form";
import RemovePopup from "../../common/popup/remove-popup";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import ActionsMenu from "../../common/table/actions-menu";
import moment from "moment";
import Nodata from "../../../resources/images/no-data.gif";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function ConnectionLogs() {





    const { t } = useTranslation();
    const [isOpenAdd, setIsOpenAdd] = useState(false);


    const [isLoading, setLoading] = useState(false);
    const [list, setList] = useState({total_rows: 0, logs: []});

    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId, typeActive, removeActive, onRefresh } = useOutletContext();

    useEffect(() => {
        if(typeActive && typeActive === 'log'){
            setIsOpenAdd(true);
            removeActive();
        }
        // eslint-disable-next-line
    }, [typeActive]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, LOGS ]), signal);
                setList(res);
            } catch (e) {
                setList({total_rows: 0, logs: []});
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, connectionId]);



    return (
        <>
            <div className="max-w-5xl mx-auto mb-3 py-6 px-4 sm:px-6 lg:px-8">

                <div className="flex justify-between items-center flex-wrap gap-4 mb-6">
                    <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                        {t("app.leads.activity_logs")}
                    </h1>

                    {/* Right: Actions */}
                    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 w-full sm:w-auto">
                        <ActionButton
                            icon={
                                <PlusCircleIcon
                                    className="w-4 h-4 "
                                />
                            }
                            onClick={() => {
                                setIsOpenAdd(true);
                            }}
                            text={t("app.leads.add_log")}
                        />
                    </div>
                </div>



            </div>


            <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">
                <LoaderWrapper isLoading={isLoading}>



                    {
                        list.logs.length > 0 ? (
                            <>
                                <ul className="space-y-6">
                                    {list.logs.map((activityItem, activityItemIdx) => (
                                        <LogCard
                                            key={activityItem.id}
                                            data={activityItem}
                                            connectionId={connectionId}
                                            activityLength={list.total_rows}
                                            index={activityItemIdx}
                                            onRefresh={(r) => {
                                                setList(r);
                                                onRefresh(true);
                                            }}
                                        />
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <div className="text-center shadow rounded-md flex bg-white justify-center   h-56">
                                <div className={'self-center'}>
                                    <div className="text-center p-10">
                                        <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                        <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                            {t("app.common.not_found")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                </LoaderWrapper>

            </div>


            <DynamicForm
                isOpen={isOpenAdd}
                setIsOpen={setIsOpenAdd}
                title={t("app.leads.activity_logs")}
                fields={[
                    {field: 'log_type', translation: 'app.connection.log_type',  value: '', viewType: '', formType: 'string', required: true},
                    {field: 'description', translation: 'app.connection.description',  value: '', viewType: '', formType: 'textarea', required: true},
                    {field: 'log_date', translation: 'app.connection.log_date',  value: '', viewType: '', formType: 'date', required: false}
                ]}
                isCreate={true}
                endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, LOG ])}
                onResponse={(r) => {
                    setList(r);
                    onRefresh(true);
                }}
            />

        </>
    );
}



const LogCard = ({data, connectionId, onRefresh, index, activityLength}) => {

    const { t } = useTranslation();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const { loggedCompany } = useSelector((state) => state.user);


    const [isOpenRemove, setOpenRemove] = useState(false);




    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, LOG, data.id])
    };


    return (
        <>

            <li key={index.id} className="relative flex gap-x-4">
                <div
                    className={classNames(
                        index === activityLength - 1 ? 'h-6' : '-bottom-6',
                        'absolute left-0 top-0 flex w-6 justify-center'
                    )}
                >
                    <div className="w-px bg-gray-200" />
                </div>
                <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center ">
                        <div className="h-2 w-2 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <div className="flex-auto rounded-md px-3 ">
                        <div className="flex justify-between gap-x-4">
                            <div className="py-0.5 text-xs leading-5 text-gray-500 flex items-center">
                                <span className="font-bold text-blue-1000">{data.log_type}</span>
                            </div>
                            <div className={'flex items-center'}>
                                <time dateTime={data.log_date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">{moment(data.log_date, "YYYY-M-D HH:mm:ss").format("DD MMMM, YYYY")}</time>
                                <div className="relative">
                                    <ActionsMenu icon={DotsVerticalIcon}>
                                        <button className={'text-left'} onClick={() => {setIsOpenEdit(true)}}>
                                            {t("app.common.edit")}
                                        </button>
                                        <button className={'text-left'} onClick={() => {setOpenRemove(true)}}>
                                            {t("app.common.remove")}
                                        </button>
                                    </ActionsMenu>
                                </div>
                            </div>

                        </div>

                        <p className="text-sm max-w-3xl leading-6 mt-1 text-blue-1000[0.8]">{data.description}</p>
                    </div>
                </>
            </li>

            <DynamicForm
                isOpen={isOpenEdit}
                setIsOpen={setIsOpenEdit}
                title={t("app.leads.activity_logs")}
                refId={data.id}
                fields={[
                    {field: 'log_type', translation: 'app.connection.log_type',  value: data.log_type, viewType: 'string', formType: 'string', required: true},
                    {field: 'description', translation: 'app.connection.description',  value: data.description, viewType: 'textarea', formType: 'textarea', required: true},
                    {field: 'log_date', translation: 'app.connection.log_date',  value: data.log_date, viewType: 'date', formType: 'date', required: false}
                ]}
                isCreate={false}
                endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, LOG, data.id ])}
                onResponse={(r) => {
                    onRefresh(r);
                }}
            />


            {(isOpenRemove) && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        onRefresh(r);
                    }}
                />
            )}
        </>

    )
}
