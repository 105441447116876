import React from "react";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const CircleButton = ({extraClass = '', icon, onClick}) => {

    const Icon = icon;

    return (
        <button
            className={classNames(
                extraClass,
                'btn p-2 mr-2 rounded-full',
            )}
            onClick={onClick}>
            <Icon
                className="h-4 w-4"
                aria-hidden="true"
            />
        </button>
    )
};
export default CircleButton;
