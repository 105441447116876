import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getFormattedDate} from "../../utils/timeUtils";
import Initials from "../common/avatar/initials";
import CalendarIcon from "../../resources/icons/CalendarIcon";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {LinkIcon, PaperClipIcon, PencilAltIcon} from "@heroicons/react/outline";
import { createPopper } from '@popperjs/core';
import {getPathParam} from "../../utils/converter";
import {COMPANY} from "../../routes/endpoints";
import {TASK} from "../../api/endpoints";
import ConfirmPopup from "../common/popup/confirm-popup";
import ReportViewer from "../partials/report/report-viewer";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const today = new Date();

function TaskList({data, height}) {

  const { t } = useTranslation();
    const [taskList, setTaskList] = useState(data.list_to_do);

    const getFiltered = () => {
        if(taskList.length > 0){
            let list = taskList,
                result = list.reduce(function (r, a) {

                    let begin = moment(a.task_date, "YYYY-M-D HH:mm:ss").startOf('week').format("YYYY-M-D HH:mm:ss");
                    let end = moment(a.task_date, "YYYY-M-D HH:mm:ss").endOf('week').format("YYYY-M-D HH:mm:ss");
                    let flag = moment(begin, "YYYY-M-D HH:mm:ss").format("D MMMM")+' - '+moment(end, "YYYY-M-D HH:mm:ss").format("D MMMM")


                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }


  return (

      <>
          {
              taskList.length === 0 ? (
                  <div className="text-center p-10">
                      <PencilAltIcon
                          className="h-24 w-24 text-indigo-600 m-auto"
                          aria-hidden="true"
                      />
                      <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                          {t("dashboard.company_task.not_found_title")}
                      </p>
                      <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                          {t("dashboard.company_task.not_found_description")}
                      </p>

                  </div>
              ) : (
                  <nav style={{height: height+'px'}} className="overflow-y-scroll"  aria-label="Directory">
                      {Object.keys(getFiltered()).map((date) => (
                          <div key={date} className="relative">
                              <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                  <h3>{date.charAt(0).toUpperCase() + date.slice(1)}</h3>
                              </div>
                              <ul className="relative z-0 divide-y divide-gray-200">
                                  {getFiltered()[date].map((item) => (
                                      <TaskCard
                                          key={item.id}
                                          data={item}
                                          onChecked={(data) => {
                                              setTaskList(data.list_to_do);
                                              toast.success(t("app.tasks.update_success"))
                                          }}
                                      />
                                  ))}
                              </ul>
                          </div>
                      ))}
                  </nav>
              )
          }
      </>

  );
}

const TaskCard = ({data, onChecked}) => {

    const { t } = useTranslation();

    // Confirm Modal
    const [isChecked, setChecked] = useState(false);

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});
    const { loggedCompany } = useSelector((state) => state.user);
    const [url, setUrl] = useState(null);
    const [isOpenReportView, setOpenReportView] = useState(false);

    return (
        <>

            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={url ? {reference: url} : false}
                title={t("app.document.preview_file")}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />

            <div className={" border border-slate-200 p-4"}>

                <div className="flex justify-between items-center mb-2">

                    <div className="flex items-center">
                        <div className={classNames(
                            moment(data.task_date, "YYYY-M-D HH:mm:ss") < today ? 'text-red-500' : '',
                            moment(data.task_date, "YYYY-M-D HH:mm:ss") > today ? 'text-gray-900' : '',
                            "flex items-center mr-4"
                        )}>
                            <CalendarIcon className="w-4 h-4 shrink-0 fill-current mr-1.5"/>
                            <div className="text-sm font-semibold">{getFormattedDate(data.task_date)}</div>
                        </div>

                        {
                            data.file && (
                                <div  onClick={() => {
                                    setUrl(data.file.reference);
                                    setOpenReportView(true);
                                }} className="text-sm font-semibold flex items-center text-indigo-600 hover:text-indigo-900 cursor-pointer">
                                    <LinkIcon className="w-4 h-4  mr-1.5"/>
                                    {data.file_name}
                                </div>
                            )
                        }


                        {
                            (data.company_task_files && data.company_task_files.length > 0 )&& (
                                <div className="flex items-center text-blue-1000 ">
                                    <PaperClipIcon className={'h-4 w-4 mr-2 '} /> {data.company_task_files.length}
                                </div>
                            )
                        }

                    </div>

                </div>
                <div className="sm:flex sm:justify-between sm:items-center">

                    {/* Left side */}
                    <div className="flex items-center">

                        {/* Checkbox button */}
                        <label className="flex items-center">
                            <input type="checkbox"
                                   onChange={(r) => {
                                       const confirmDetail = {
                                           title: r.target.checked ?  t("app.tasks.update_title") : t("app.tasks.update_r_title") ,
                                           message: r.target.checked ?  t("app.tasks.update_description") : t("app.tasks.update_r_description"),
                                           type: '',
                                           endpointData: {
                                               id: data.id,
                                               is_confirmed: r.target.checked,
                                           },
                                           endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, data.id])
                                       };
                                       setOpenConfirm(true);
                                       setChecked(r.target.checked);
                                       setConfirmDetail(confirmDetail);
                                   }}
                                   checked={isChecked}
                                   className="peer focus:ring-0 focus-visible:ring w-5 h-5 bg-white border border-gray-300 text-indigo-700 rounded-full" />
                            <div className="font-medium text-gray-900 peer-checked:line-through ml-2">

                                <h1 className="mb-2 text-blue-1000 uppercase text-xs">{t("app.quotations.created_by")} {data.user.fullname}</h1>
                                <b className="mb-2">{data.title}</b>
                                {
                                    data.description && (
                                        <p className="text-xs flex-wrap text-gray-500" dangerouslySetInnerHTML={{__html: data.description}} />
                                    )
                                }
                            </div>

                        </label>
                    </div>


                    {/* Right side */}
                    <div className="flex items-center justify-end space-x-3">


                        {/* Date */}
                        {
                            (data.company_task_users && data.company_task_users.length > 0 )&& (
                                <div className="flex shrink-0 -space-x-4 -ml-px">
                                    {
                                        data.company_task_users.map(item => (
                                            <Popover key={item.id} item={item} />
                                        ))
                                    }
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>

            {isOpenConfirm && (
            <ConfirmPopup
                isOpen={isOpenConfirm}
                setIsOpen={() =>{
                    setOpenConfirm(false);
                    setChecked(!isChecked);
                }}
                type={'after-confirm'}
                detail={confirmDetail}
                setDetail={(r) => {
                    onChecked(r);
                }}
            />
        )}</>
    );

};

const Popover = ({ item }) => {
    const [popoverShow, setPopoverShow] = React.useState(false);
    const btnRef = React.createRef();
    const popoverRef = React.createRef();
    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "left"
        });
        setPopoverShow(true);
    };
    const closePopover = () => {
        setPopoverShow(false);
    };
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full text-center">
                    <div className="block cursor-pointer"
                         ref={btnRef}
                         onClick={() => {
                        popoverShow ? closePopover() : openPopover();
                    }}>
                        <Initials
                            iconClass={' h-6 w-6'}
                            color={item.user.color}
                            name={item.user.fullname}
                        />
                    </div>
                    <div
                        className={
                            (popoverShow ? "" : "hidden ") +
                            "border-0 mr-3 block z-50"
                        }
                        ref={popoverRef}
                    >
                        <div className="rounded-md shadow-lg  mr-3">
                            <div className="relative bg-solitude-100 px-4 py-2 font-medium text-gray-900">
                                {item.user.fullname}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default TaskList;
