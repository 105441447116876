import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Menu, Transition} from "@headlessui/react";
import UserInitial from "../table/user-initial";

const UsersToolbar = ({users,isActive, children, className = '', extraData = false}) => {

    const { t } = useTranslation();

    const handleClick = (event) => {
        event.stopPropagation();
    };


    return(
        <Menu as="div" className={className}>
            <Menu.Button onClick={handleClick}>
                {children}
            </Menu.Button>

            {
                isActive && (
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className={"right-0 origin-top-right z-[1000] absolute text-left mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "}>
                            <div className="py-1">

                                {
                                    users.map((user, index) => (
                                        <div key={index} className="flex items-center gap-1 text-sm px-4 py-2 border-b last:border-0">
                                            {
                                                index === 0 ? (
                                                    <UserInitial user={user} ringClass={" ring-yellow-400 "}/>
                                                ) : (
                                                    <UserInitial user={user} />
                                                )
                                            }
                                            <div className="text-left ml-3">
                                                <div className="truncate text-sm text-gray-900">
                                                    {user?.fullname ?? ''}
                                                </div>

                                                {
                                                    (index === 0 && extraData) && (
                                                        <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{fontSize: '11px'}}>
                                                            {t("app.quotations.created_on", {date: extraData})}
                                                        </div>
                                                    )
                                                }



                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </Menu.Items>
                    </Transition>
                )
            }


        </Menu>
    )
}


export default UsersToolbar;
