import React from "react";
import OnboardingIndex from "./index";
import {SuccessBoxLink} from "../../components/common/alert/success-box";
import {DASHBOARD} from "../endpoints";
import {useTranslation} from "react-i18next";

const StepComplete = () => {

    const { t } = useTranslation();

    return (
        <OnboardingIndex>
            <div className="max-w-lg mx-auto px-4 py-8 w-3/4">

                <SuccessBoxLink
                    title={t("onboarding.confirm.title")}
                    message={t("onboarding.confirm.description")}
                    link={DASHBOARD}
                    linkTitle={t('app.common.go_to', {label: ' Dashboard ->'})}

                />
            </div>
        </OnboardingIndex>
    );
};

export default StepComplete;
