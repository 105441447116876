import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { Warning } from "../../components/common/alert/banner";
import { getWebPathParam } from "../../utils/converter";
import {  useNavigate } from "react-router-dom";
import { postCompanyUserConnection } from "../../api/user-connection";
import { UsersIcon, BriefcaseIcon } from "@heroicons/react/outline";
import InputRadio from "../../components/common/form/input-radio";
import {USER, REGISTRY} from "../endpoints";
import {sp} from "../../constants/permissions";
import {useTranslation} from "react-i18next";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import {emailRegex} from "../../constants/regex";
import InputToggle from "../../components/common/form/input-toggle";

export default function AddUser({ isOpen, setIsOpen }) {
  const { loggedCompany } = useSelector((state) => state.user);

  // shipping
  const [isAddLoading, setAddLoading] = useState(false);
  const [exception, setException] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();

  const storeData = async (data) => {
    if (loggedCompany) {
      setAddLoading(true);
      postCompanyUserConnection({ company_id: loggedCompany.id, data })
        .then((response) => {
          dispatch(subscriptionUsageLimits(loggedCompany.id));
          navigate(getWebPathParam([REGISTRY, USER, response.id]));
          setAddLoading(false);
        })
        .catch((e) => {
          setException(e.message);
          setAddLoading(false);
        });
    }
  };

  useEffect(() => {
    if(!watch("connection_type")){
      setValue('connection_type', 'employee');
    }
  }, [setValue, watch]);

  const onSubmit = async (data) => {
    if(watch("connection_type") !== "employee"){
      setValue('connect_all', false);
    }
    await storeData(data);
  };

  return (
      <FormPopup
          title={"Add new user"}
          isOpen={isOpen}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">

            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-2 sm:grid-cols-1 mb-3">

              <label className="col-span-2 block text-sm font-medium text-gray-700">
                {t("app.users.user_type")}
              </label>

              <InputRadio
                  name={"connection_type"}
                  errors={errors.connection_type}
                  input={{ ...register("connection_type", { required: true }) }}
                  label={t("app.users.connection_employee")}
                  value={"employee"}
                  icon={<UsersIcon className="w-6 h-6 text-indigo-700 mr-4" />}
              />

              <InputRadio
                  name={"connection_type"}
                  errors={errors.connection_type}
                  input={{ ...register("connection_type", { required: true }) }}
                  label={t("app.users.connection_agent")}
                  value={"agent"}
                  icon={
                    <BriefcaseIcon className="w-6 h-6 text-indigo-700 mr-4" />
                  }
              />
            </div>

            <SubscriptionWrapper requiredPermission={watch("connection_type") === "employee" ? sp.EMPLOYEE_INTERNAL_PACKAGE : sp.AGENT_INTERNAL_PACKAGE}>
              <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-6 sm:grid-cols-1 mb-3">
                <InputText
                    id={"fullname"}
                    type={"text"}
                    errors={errors.fullname}
                    input={{
                      ...register("fullname", { required: true }),
                    }}
                    label={t("app.users.fullname")}
                />

                <InputText
                    id={"notification_email"}
                    type={"text"}
                    errors={errors.notification_email}
                    input={{
                      ...register("notification_email", { required: true, pattern: emailRegex }),
                    }}
                    label={t("app.form.email")}
                />

                {watch("connection_type") === "employee" && (
                    <div className={"col-span-2 mt-2"}>
                      <Controller
                          name="connect_all"
                          rules={{ required: false }}
                          control={control}
                          render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                   }) => (
                              <InputToggle
                                  label={t("app.users.connect_all_title")}
                                  description={t("app.users.connect_all_description")}
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                  defaultChecked={value}
                                  errors={error}
                              />
                          )}
                      />
                    </div>
                )}
              </div>

              {/* Warning*/}
              {exception && <Warning message={exception} />}

              <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit disabled={!(watch("connection_type") && watch("notification_email"))} isLoading={isAddLoading} label={t("app.users.add")} />
              </div>


            </SubscriptionWrapper>
          </div>
        </form>
      </FormPopup>
  );
}
