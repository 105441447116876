import {COMPANY, ORDERS, ORDER, PRODUCTS, PRODUCT, CATALOG_PRODUCTS} from "./endpoints";
import API from "../utils/apiUtils";
import { getPathParam, serialize } from "../utils/converter";

// customer
export const getOrders = ({ company_id, params, signal }) =>
  API.get(
    getPathParam([COMPANY, company_id, ORDERS]) +
      (params ? serialize(params) : ""),
    { signal: signal }
  );

export const getOrder = ({ company_id, order_id, signal }) =>
  API.get(getPathParam([COMPANY, company_id, ORDER, order_id]), {
    signal: signal,
  });

export const postOrder = ({ company_id, data }) =>
  API.post(getPathParam([COMPANY, company_id, ORDER]), data);

export const putOrder = ({ company_id, order_id, data }) =>
  API.put(getPathParam([COMPANY, company_id, ORDER, order_id]), data);

export const getOrderProducts = ({ order_id, params, signal }) =>
  API.get(
    getPathParam([ORDER, order_id, PRODUCTS]) +
      (params ? serialize(params) : ""),
    { signal: signal }
  );

export const getOrderProduct = ({ order_id, product_id, signal }) =>
  API.get(getPathParam([ORDER, order_id, PRODUCT, product_id]), {
    signal: signal,
  });

export const postOrderProduct = ({ order_id, data }) =>
  API.post(getPathParam([ORDER, order_id, PRODUCT]), data);

export const postOrderProducts = ({ order_id, data }) =>
    API.post(getPathParam([ORDER, order_id, PRODUCTS]), data);



export const putOrderProduct = ({ order_id, product_id, data }) =>
  API.put(getPathParam([ORDER, order_id, PRODUCT, product_id]), data);


export const getOrderCatalogProducts = ({ company_id, order_id, params, signal }) =>
    API.get(
        getPathParam([COMPANY, company_id, ORDER, order_id, CATALOG_PRODUCTS]) +
        (params ? serialize(params) : ""),
        { signal: signal }
    );
