import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import { useTranslation } from "react-i18next";
import ButtonSubmit from "../../components/common/form/button-submit";
import { postElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { BOARD, COMPANY } from "../endpoints";
import { Warning } from "../../components/common/alert/banner";
import BoardPopup from "../../components/board/board-popup";
import UploadFile from "../../components/partials/upload/upload-file";
import { useSelector } from "react-redux";
import InputSelect from "../../components/common/form/input-select";
import BoardReletedEntities from "../../constants/config";
import InputToggle from "../../components/common/form/input-toggle";
import MemberManage from "./member-manage";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const AddBoard = ({ isOpen, setIsOpen, onOpenBoard }) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false)
    const [exception, setException] = useState(false)
    const [cleanData, setCleanData] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        control,
        watch
    } = useForm();
    const { t } = useTranslation();


    useEffect(() => {
        setValue('public', true)
    }, [setValue])




    const onUpdate = async (data) => {

        if (data['users_data'] && data['users_data'].length > 0){
            data['assigned_users'] = { to_add: data['users_data'], to_remove: [] };
        }

        if (data['accosciate_entity']) {
            data['accosciate_entity'] = data['accosciate_entity'].value;
        }


        setLoading(true);
        setException(false);
        setCleanData(false);
        postElement(getPathParam([COMPANY, loggedCompany.id, BOARD]), data)
        .then(response => {
            onOpenBoard(response);
            setCleanData(true);
            setIsOpen(false);
        }).catch(e => {
            setException(e.message);
            setLoading(false);
        });
    };


    return (

        <>

            <BoardPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                canClose={true}
                setClean={() => {
                
                }}
            >
                <div className={classNames(
                    " -mt-4 mb-3 bg-white "
                    )}>

                    <div className="px-4 sm:px-6">
                        
                        <>
                            {/* TITLE */}
                            <div className="flex items-center justify-between mt-3">
                                <div className="flex items-start space-x-5">
                                    <div className="pt-1.5">
                                        <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.add_new")}</h1>
                                    </div>
                                </div>
                            </div>

                            {/* CONTENT */}
                            <form onSubmit={handleSubmit(onUpdate)} className={' border-none pb-5 mt-5'}>
                                <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">


                                    <div className="col-span-4 md:col-span-2 ">
                                        <div className={"col-span-full "}>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">{t("app.board.board_cover")}</label>
                                        </div>
                                        <UploadFile
                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                            fileTypeLabel={'JPG, PNG'}
                                            showPreview={true}
                                            picture={false}
                                            image={true}
                                            cleanData={cleanData}
                                            onUpload={(data) => {
                                                setValue('picture', { media_url: data.media_url });
                                            }}
                                            onCancel={(r) => {
                                                if (r) {
                                                    setValue('picture', null);
                                                }
                                            }}
                                            extraClass={'h-44'}
                                            onException={(e) => { }}
                                            isLoading={false}
                                        />

                                    </div>

                                    <div className="col-span-4 md:col-span-2">
                                        <InputText
                                            id={'title'}
                                            type={"text"}
                                            errors={errors.title}
                                            input={{ ...register('title', { required: true }) }}
                                            label={t('app.board.board_title')}
                                            isRequired={true}
                                        />
                                    </div>


                                    <div className="col-span-4 md:col-span-2">
                                        <InputText
                                            id={'description'}
                                            type={"textarea"}
                                            errors={errors.description}
                                            input={{ ...register('description', { required: false }) }}
                                            label={t('app.board.board_description')}
                                        />
                                    </div>

                                    <div className="col-span-4 md:col-span-2">
                                        <Controller
                                            name="accosciate_entity"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                <InputSelect
                                                    label={t("app.board.accosciate_entity")}
                                                    name={name}
                                                    options={BoardReletedEntities().filter(r => r.accosciateEntity === true)}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={false}
                                                    isDisabled={false}
                                                    errors={error}
                                                />
                                            )}
                                        />
                                    </div>

                                    {watch('public') && (
                                        <div className="col-span-4 md:col-span-2 -mt-1">
                                            <MemberManage
                                                isNew={true}
                                                onChangeData={(r) => {
                                                    setValue('users_data',r);
                                                }}
                                            />
                                        </div>
                                    )}


                                    <div className="col-span-4 md:col-span-2">
                                        <Controller
                                            name={"public"}
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <InputToggle
                                                    label={t("app.board.public")}
                                                    description={t("app.board.public_description")}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                    }}
                                                    defaultChecked={value}
                                                    errors={error}
                                                />
                                            )}
                                        />
                                    </div>


                                    
                                  

                                   



                                    {/* Warning */}
                                    {exception && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Warning message={exception} />
                                        </div>
                                    )}


                                    <div className={classNames(
                                        "col-span-4 md:col-span-2  mt-6",
                                    )}>

                                        <div className="flex flex-col items-center">
                                            <ButtonSubmit
                                                btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                isLoading={isLoading}
                                                isFullWith={true}
                                                label={t("app.board.board_edit_btn")}

                                            />
                                        </div>



                                    </div>

                                </div>
                            </form>
                        </>
                    </div>

                </div>


            </BoardPopup>
        </>


    )
};




export default AddBoard;