import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../../reducers/connectionReducer";
import UserInitial from "../../components/common/table/user-initial";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/outline";
import { Combobox, Transition } from "@headlessui/react";
import InputSelect from "../../components/common/form/input-select";

const MemberManage = ({ boardUsers = [], assignedUsers = [], onChangeData, creatorUser, isNew = false }) => {
  
  const { loggedCompany, detail } = useSelector((state) => state.user);
  const excludeId = isNew ? detail.id : creatorUser?.id ?? '';

  const [selectedUsers, setSelectedUsers] = useState(assignedUsers.filter(n => n.id !== excludeId));
  const [confirmedUser, setConfirmedUser] = useState(boardUsers);

  const { control, setValue } = useForm();
  const { t } = useTranslation();


  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.connection);

  useEffect(() => {
    dispatch(fetchAllUsers({ company_id: loggedCompany.id }));
    // eslint-disable-next-line
  }, [dispatch, loggedCompany, users]);


  useEffect(() => {

    if(boardUsers.length > 0){
      // eslint-disable-next-line
      boardUsers.map((boardUser) => {

        if (boardUser.is_admin && boardUser.can_edit) {
          setValue("permission-" + boardUser.user.id, { label: t("app.board.admin"), value: 'admin' },);
        }
        if (!boardUser.is_admin && boardUser.can_edit) {
          setValue("permission-" + boardUser.user.id, { label: t("app.board.member"), value: 'member' });
        }
        if (!boardUser.is_admin && !boardUser.can_edit) {
          setValue("permission-" + boardUser.user.id, { label: t("app.board.observ"), value: 'observ' });
        }
      })
    }
  
    // eslint-disable-next-line
  }, [boardUsers, setValue])

  
  



  return (
   
    <>
      <label className="block text-sm font-medium mb-1 text-gray-700" >
        {t('app.board.board_user')}
      </label>
      
      {
        users.filter(n => n.id !== excludeId).filter(obj1 => !selectedUsers.some(obj2 => obj1.id === obj2.id)).length > 0 && (
          <CustomUserSelect
            label={t('app.board.board_user')}
            users={users.filter(n => n.id !== excludeId).filter(obj1 => !selectedUsers.some(obj2 => obj1.id === obj2.id))}
            isShowLabel={false}
            placeholder={''}
            multiple={true}
            includeAll={true}
            excludeId={excludeId}
            selectedUsers={selectedUsers}
            onSelect={(r) => {
              if (confirmedUser.filter(r => r.user.id === r.id).length === 0){
                  confirmedUser.push({
                    user: { id: r.id },
                    can_edit: false,
                    is_admin: false
                  });
                  setConfirmedUser(confirmedUser);
                  setSelectedUsers([...selectedUsers, r]);
                  onChangeData(confirmedUser);
              }

              }}
            />
          )
      }

     
      {
        selectedUsers.filter(n => n.id !== excludeId).length > 0 && (
          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 mt-2">
            {
              selectedUsers.filter(n => n.id !== excludeId).map((user, key) => (
                <li className="lg:flex lg:items-center lg:justify-between py-1.5 pl-3 pr-4 text-sm">
                  <div className="flex  flex-1 items-center mt-3 lg:mt-0">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <UserInitial user={user} />
                      </div>
                      <div className="flex-1 ml-3">
                        <p className="text-sm font-medium text-gray-900">{user.fullname}</p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex-shrink-0 flex  items-center mt-3 lg:mt-0">
                    <div
                      onClick={() => {

                      }}
                      className="mr-2 w-[180px]"
                    >
                      <Controller
                        name={"permission-"+user.id}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <InputSelect
                            label={t("app.board.property_type")}
                            isShowLabel={false}
                            name={name}
                            options={[{ label: t("app.board.admin"), value: 'admin' }, { label: t("app.board.member"), value: 'member' }, { label: t("app.board.observ"), value: 'observ' }]}
                            value={value}
                            onChange={(e) => {
                              onChange(e);

                              let filtered = [...confirmedUser];
                              let foundIndex = filtered.findIndex((x) => x.user.id === user.id);

                              if (e.value === 'admin'){
                                filtered[foundIndex]['is_admin'] = true;
                                filtered[foundIndex]['can_edit'] = true;
                              }
                              if (e.value === 'member') {
                                filtered[foundIndex]['is_admin'] = false;
                                filtered[foundIndex]['can_edit'] = true;
                              }
                              if (e.value === 'observ') {
                                filtered[foundIndex]['is_admin'] = false;
                                filtered[foundIndex]['can_edit'] = false;
                              }

                              setConfirmedUser(filtered);
                              onChangeData(filtered);

                            }}
                            isLoading={false}
                            isDisabled={false}
                            errors={error}
                            isRequired={true}
                          />
                        )}
                      />

                    </div>

                    <div
                      onClick={() => {
                        let internal_selectedUsers = [...selectedUsers.filter(r => r.id !== user.id)];
                        setSelectedUsers(internal_selectedUsers);

                        let filtered = [...confirmedUser.filter(r => r.user.id !== user.id)];
                        setConfirmedUser(filtered);
                        onChangeData(filtered);


                      }}
                      className="bg-red-500 hover:bg-red-300 cursor-pointer  disabled:opacity-70 flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                    >
                      <TrashIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    </div>

                  </div>
                </li>
              ))
            }
          </ul>
        )
      }
      
    </>
  );
};



const CustomUserSelect = ({ label = '', excludeId = '', isRequired = false, selectedItem = null, onSelect, users, selectedUsers =[], multiple = false }) => {

  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const filteredData =
    query === ''
      ? users.filter(n => n.id !== excludeId)
      : users.filter(n => n.id !== excludeId).filter((item) =>
        item.fullname.toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )



  return (
    <div className="w-full">
      <Combobox value={null}
        onChange={(r) => {
          onSelect(r);
        }}>

        <div className="relative ">
          <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm ">
            <Combobox.Input
              id={'test'}
              className="form-input w-full border-none  py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize"
              placeholder={t("app.common.placeholder", {
                field: label.toLowerCase(),
              })}
              displayValue={''}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>


          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

              {filteredData.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((item) => (

                  <Combobox.Option
                    key={item.id}
                    className={({ selected, active }) =>
                      `relative cursor-pointer select-none py-2 px-4 ${(active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (

                      <>
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <UserInitial user={item} />
                          </div>
                          <div className="min-w-0 flex-1 ml-3">
                            <div className="focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              <p className="text-sm font-medium text-gray-900">{item.fullname}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}


            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}




export default MemberManage;

