import { Popover, Transition, Combobox } from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {ChevronRightIcon, InformationCircleIcon} from "@heroicons/react/outline";
import NumberFormat from "react-number-format";
import {getFormattedDate} from "../../../utils/timeUtils";
import {useTranslation} from "react-i18next";
import {getProductPurchaseHistory} from "../../../api/catalog";
import {useSelector} from "react-redux";
import LoaderWrapper from "../loader/loader-wrapper";
import Initials from "../avatar/initials";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function PurchaseHistory({product, boxExtraClass = '', boxHeight = 'h-96', position = 'absolute'}) {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(false);
    const [productDetail, setProductDetail] = useState([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        function getPurchaseHistory() {
            if (loggedCompany && isOpen && product) {
                setLoading(true);
                getProductPurchaseHistory({ company_id: loggedCompany.id, product_id: product.id })
                    .then((response) => {
                        setProductDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setLoading(false);
                    });
            }
        }
        getPurchaseHistory();
        // eslint-disable-next-line
    }, [loggedCompany, isOpen, product]);


    return (

        <>
            <Popover>
                {({ open }) => {
                    setOpen(open);
                    return (
                        <>
                            <Popover.Button>
                                <InformationCircleIcon className={"cursor-pointer text-blue-400 h-5 w-5"} />
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className={
                                    classNames(position,
                                        boxExtraClass,
                                        isLoading ? 'w-screen max-w-sm' : '',
                                        " z-10 mt-3  transform px-4 sm:px-0 z-50 "
                                    )}>

                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="relative  bg-white">

                                            <div className=" bg-white  rounded-sm border border-slate-200">
                                                <LoaderWrapper isLoading={isLoading}>
                                                    <PricingList
                                                        product={product}
                                                        loggedCompany={loggedCompany}
                                                        boxHeight={boxHeight} prices={productDetail} label={t('app.quotations.purchase_history')} />
                                                </LoaderWrapper>
                                            </div>

                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )
                }}
            </Popover>

        </>

    )
}


const PricingList = ({prices, label, boxHeight, product}) => {
    const { t } = useTranslation();

    const getDiscountLabel = (discounts) => {
        let discount_string = '';
        // eslint-disable-next-line
        (discounts ?? []).map((discount) => {
            if(discount){
                discount_string = discount_string + discount + '% + ';
            }
        })
        return discount_string.slice(0,-3);
    }



    return (
            prices.length > 0 ? (
                <>

                <Combobox>
                    {({activeOption}) => (
                        <>
                            <Combobox.Options as="div" static hold className="sm:flex relative">

                                {activeOption && (
                                    <div
                                        className={classNames(
                                            "min-h-90",
                                            "sm:hidden flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex"
                                        )}>
                                        <div className="flex-none p-6 text-center">
                                            <h2 className="mt-3 font-semibold text-gray-900">{activeOption?.user?.firstname ?? ''} {activeOption?.user?.lastname ?? ''}</h2>
                                            <p className="text-sm leading-6 text-gray-500">{getFormattedDate(activeOption.date)}</p>
                                        </div>
                                        <div
                                            className="flex flex-auto flex-col justify-between p-6">
                                            <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">

                                                {
                                                    activeOption.list_price && (
                                                        <>
                                                            <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.list_price")}</dt>
                                                            <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                                            <NumberFormat
                                                                value={activeOption.list_price}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={4}
                                                                suffix={' €'}
                                                            /></dd>
                                                        </>
                                                    )
                                                }

                                                {
                                                    activeOption.discount && activeOption.discount.length > 0 && (
                                                        <>
                                                            <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.discounts")}</dt>
                                                            <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                                                {getDiscountLabel(activeOption.discount)}</dd>
                                                        </>
                                                    )
                                                }



                                                <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.purchase_price")}</dt>
                                                <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                                <NumberFormat
                                                    value={activeOption.purchase_price}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' €'}
                                                /></dd>

                                                <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.price_note")}</dt>
                                                <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                                {activeOption.price_note}
                                                </dd>

                                            </dl>


                                        </div>
                                    </div>
                                )}


                                <div
                                    className={classNames(
                                        boxHeight,
                                        'min-w-0 flex-auto scroll-py-4 overflow-y-auto overflow-x-hidden min-w-xl'
                                    )}
                                >
                                    <div className="z-10 sticky top-0 border-b border-gray-200 bg-gray-50 p-2 text-sm font-medium text-gray-900 text-center">
                                        <h3>{label}</h3>
                                    </div>
                                    <div className="-mx-2 text-sm text-gray-700">
                                        {prices.map((price) => (
                                            <Combobox.Option
                                                as="div"
                                                key={price?.id ?? '-'}
                                                value={price}
                                                className={({active}) =>
                                                    classNames(
                                                        'px-4 bg-white ',
                                                        active && 'bg-gray-100 text-gray-900'
                                                    )
                                                }
                                            >
                                                {({active}) => (
                                                    <>
                                                        <div className="py-2 flex items-center justify-between space-x-9">
                                                            <div className="min-w-0 flex-1 flex items-center space-x-9">
                                                                <div className="min-w-md flex-1">
                                                                    {
                                                                        price.user ? (
                                                                            <div className="flex items-center gap-1 text-sm">
                                                                                <Initials
                                                                                    color={price?.user?.color ?? 'red'}
                                                                                    name={price?.user?.firstname ?? ''}
                                                                                />
                                                                                <div className="text-left">
                                                                                    <div className="truncate text-sm text-gray-900">
                                                                                        {price?.user?.firstname ?? ''} {price?.user?.lastname ?? ''}
                                                                                    </div>
                                                                                    <div className="text-xs flex-wrap text-gray-700">
                                                                                        {getFormattedDate(price.date)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <p className="text-sm font-medium text-gray-600 truncate">
                                                                                {getFormattedDate(price.date)}
                                                                            </p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4  text-sm text-gray-900">
                                                                <NumberFormat
                                                                    value={price.purchase_price}
                                                                    displayType={"text"}
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    decimalScale={4}
                                                                    suffix={' €'}
                                                                />

                                                                <ChevronRightIcon
                                                                    className="ml-3 h-5 w-5 flex-none text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>


                                                        </div>
                                                    </>
                                                )}


                                            </Combobox.Option>
                                        ))}
                                    </div>
                                </div>

                                {activeOption && (
                                    <div
                                        className={classNames(
                                            boxHeight,
                                            "hidden    flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex"
                                        )} style={{minWidth: '150px'}}>
                                        <div className="flex-none p-6 text-center">
                                            <Initials
                                                color={activeOption?.user?.color ?? 'red'}
                                                iconClass={' h-16 w-16'}
                                                name={activeOption?.user?.firstname ?? ''}
                                            />

                                            <h2 className="mt-3 font-semibold text-gray-900">{activeOption?.user?.firstname ?? ''} {activeOption?.user?.lastname ?? ''}</h2>
                                            <p className="text-sm leading-6 text-gray-500">{getFormattedDate(activeOption.date)}</p>
                                        </div>
                                        <div
                                            className="flex flex-auto flex-col justify-between p-6">
                                            <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">

                                                {
                                                    activeOption.list_price && (
                                                        <>
                                                            <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.list_price")}</dt>
                                                            <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                                            <NumberFormat
                                                                value={activeOption.list_price}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={4}
                                                                suffix={' €'}
                                                            /></dd>
                                                        </>
                                                    )
                                                }

                                                {
                                                    activeOption.discount && activeOption.discount.length > 0 && (
                                                        <>
                                                            <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.discounts")}</dt>
                                                            <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                                            {getDiscountLabel(activeOption.discount)}</dd>
                                                        </>
                                                    )
                                                }



                                                <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.purchase_price")}</dt>
                                                <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                                    <NumberFormat
                                                    value={activeOption.purchase_price}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' €'}
                                                /></dd>

                                                <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.price_note")}</dt>
                                                <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                                    {activeOption.price_note}
                                                </dd>



                                            </dl>

                                        </div>
                                    </div>
                                )}
                            </Combobox.Options>
                        </>
                    )}



                </Combobox>


                </>


            ) : (
                <div
                    className={classNames("flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex")} style={{minWidth: '150px'}}>
                    <div className="flex-none p-2 text-center">
                        <p className="text-sm text-gray-900">{getFormattedDate(product.last_price_date)}</p>
                    </div>
                    <div
                        className="flex flex-auto flex-col justify-between p-4">
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">

                            {
                                product.list_price && (
                                    <>
                                        <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.list_price")}</dt>
                                        <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                            <NumberFormat
                                                value={product.list_price}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={4}
                                                suffix={' €'}
                                            /></dd>
                                    </>
                                )
                            }

                            {
                                product.discount && product.discount.length > 0 && (
                                    <>
                                        <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.discounts")}</dt>
                                        <dd className={"text-sm font-medium text-gray-900 text-right"}>

                                            {getDiscountLabel(product.discount)}</dd>
                                    </>
                                )
                            }



                            <dt className="col-end-1 font-semibold text-gray-900">{t("app.quotations.purchase_price")}</dt>
                            <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                <NumberFormat
                                    value={product.purchase_price}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    decimalScale={4}
                                    suffix={' €'}
                                /></dd>


                            {
                                product.price_note && (
                                    <>
                                        <dt className="col-end-1 font-semibold text-gray-900">{t("app.products.price_note")}</dt>
                                        <dd className={"text-sm font-medium text-gray-900 text-right"}>
                                            {product.price_note}
                                        </dd>
                                    </>
                                )
                            }

                        </dl>

                    </div>
                </div>
            )

        )
    }


