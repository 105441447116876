import React, {useEffect, useState} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../utils/converter";
import {
    COMPANY,
    CONNECTION_CATEGORIES,
    CONNECTION_CATEGORY, NOTIFICATION
} from "../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {UserGroupIcon} from "@heroicons/react/outline";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import {fetchNotificationTypes} from "../../../../reducers/configReducer";
import InputSelect from "../../../../components/common/form/input-select";
import {postElement} from "../../../../api/config";
import {TrashIcon} from "@heroicons/react/outline/esm";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import Nodata from "../../../../resources/images/no-data.gif";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";

const UserCategory = () => {

    const { t } = useTranslation();

    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_JOB_TYPES)]} renderBaseOnFail={true} >
        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.connection_category.name"),
                            t("app.connection_category.note"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORIES])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY, ':id'])}
                        baseResult={{total_rows: 0, categories: []}}
                        resultLabel={'categories'}
                        title={t("app.connection_category.name")}
                        editTitle={t("app.connection_category.name")}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddCategory}
                        editForm={EditCategory}
                        addFormLabel={t("app.connection_category.add_name")}
                        showHeader={true}
                        icon={UserGroupIcon}
                        btnAddLabel={t("app.connection_category.add_name")}
                        isActiveEdit={true}
                    />
                )
            }
        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.connection_category.remove_title"),
        message: t("app.connection_category.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col gap-2 text-gray-500 text-sm truncate">
                    <div className="flex gap-1 justify-between">
                        {t("app.connection_category.note")}{" "}
                        <span className="font-bold">{element.note}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.connection_category.remove_title"),
        message: t("app.connection_category.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY, element.id])
    };

    return (
        <tr key={element.id}>
            <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.name}
                    </div>
                </div>
            </td>
            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                {element.note}
            </td>
            <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </td>
        </tr>
    );
};

const AddCategory = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.connection_category.name")}
                    />

                    <InputText
                        id={"note"}
                        type={"text"}
                        errors={errors.note}
                        input={{ ...register("note", { required: false }) }}
                        label={t("app.connection_category.note")}
                    />
                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.connection_category.add_name")}
                />
            </div>
        </form>
    );
}


const EditCategory = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);

    // DELETE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // POST
    const [isStoreLoading, setStoreLoading] = useState(false);
    const [storeException, setStoreException] = useState(false);


    const [notifications, setNotifications] = useState(data?.notifications ?? []);


    const storeData = async (id) => {
        setStoreLoading(true);
        setStoreException(false);
        postElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY, data.id, NOTIFICATION, id ]), {})
            .then(response => {
                setNotifications(response.notifications);
                setStoreLoading(false);
                setValue('notification_type', null);
            }).catch(e => {
            setStoreException(e.message);
            setStoreLoading(false);
        });
    };



    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                setValue(k, data[k]);
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    const { notificationTypesList, isNotificationTypesListLoading } = useSelector((state) => state.config);

    useEffect(() => {
        dispatch(fetchNotificationTypes());
    }, [dispatch]);

    const getFiltered = () => {
        if(notifications.length > 0){
            let list = notifications,
                result = list.reduce(function (r, a) {
                    let flag = a.notification_type.group.title;
                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }

    const getFilteredOptions = () => {
        if(notificationTypesList.length > 0){

            let list = notificationTypesList.map(item => ({
                ...item,
                notification_detail: (item?.notification_detail?? []).filter(detail =>
                    !notifications.map(notification => notification.notification_type.id).includes(detail.id)
                )
            })),


            result = list.reduce(function (r, a) {
                let flag = a.label;
                r[flag] = r[flag] || [];
                r[flag] = a.notification_detail.map(e => ({
                    id: e.id,
                    value: e.id,
                    label: e.title
                }))
                return r;
            }, Object.create(null));


            let data = [];
            // eslint-disable-next-line
            Object.keys(result).map((r) => {
                data.push({label: r, options: result[r] });
            });
            console.log(data);

            return data;
        }
        return [];
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-md font-medium text-gray-900">
                        {t("app.connection_category.information")}
                    </h3>

                    <div className="space-y-4 pt-4 pb-5">

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.connection_category.name")}
                        />

                        <InputText
                            id={"note"}
                            type={"text"}
                            errors={errors.note}
                            input={{ ...register("note", { required: false }) }}
                            label={t("app.connection_category.note")}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>
            </form>

            <h3 className="mt-4 mb-3 pt-2 text-md font-medium text-gray-900">
                {t("app.connection_category.notification")}
            </h3>


            {
                notificationTypesList.filter(
                    (c) =>
                        !notifications
                            .map((c) => c.notification_type.id)
                            .includes(c.id)
                ).length > 0 && (
                    <div className="mb-3">
                        <Controller
                            name="notification_type"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputSelect
                                    label={t("app.connection_category.notification_type")}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                        storeData(e.id);

                                    }}
                                    options={getFilteredOptions()}
                                    value={value}
                                    isLoading={isNotificationTypesListLoading || isStoreLoading}
                                    isDisabled={isNotificationTypesListLoading || isStoreLoading}
                                    errors={error}
                                />
                            )}
                        />
                    </div>
                )
            }

            {/* Warning */}
            {storeException && (
                <Warning message={storeException} />
            )}


            {
                Object.keys(getFiltered()).length > 0 ? (
                    <div className=" bg-white  rounded-sm border border-slate-200">
                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                            {Object.keys(getFiltered()).map((group) => (
                                <div key={group} className="relative">
                                    <div className="z-10 sticky top-0 border-b border-gray-200 bg-gray-50 p-4 text-sm font-medium text-gray-500">
                                        <h3>{group}</h3>
                                    </div>
                                    <ul className="relative z-0 divide-y divide-gray-200">
                                        {getFiltered()[group].map((notification) => (
                                            <li key={notification.id} className=" px-2 bg-white hover:bg-gray-50">
                                                <div className="py-2 flex items-center justify-between space-x-3">
                                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                        <div className="min-w-0 flex-1">
                                                            <p className="text-sm font-medium text-gray-900 truncate">
                                                                {notification.notification_type.title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                        <button
                                                            onClick={() => {
                                                                const removeDetail = {
                                                                    title: t("app.connection_category.remove_notification_title"),
                                                                    message: t("app.connection_category.remove_notification_description"),
                                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORY, data.id, NOTIFICATION, notification.notification_type.id ])
                                                                };
                                                                setRemoveDetail(removeDetail);
                                                                setOpenRemove(true);
                                                            }}
                                                            className={
                                                                "w-full btn bg-red-500 hover:bg-red-700 "
                                                            }
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4 text-white"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </nav>
                    </div>
                ) : (
                    <div className="flex flex-col col-span-full bg-white  rounded-sm border border-slate-200">
                        <div className="text-center p-10">
                            <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                            <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                {t("app.common.not_found")}
                            </h1>
                        </div>
                    </div>
                )
            }






            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setNotifications(r.notifications);
                    }}
                />
            )}


        </>
    );
}



export default UserCategory;
