import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment, useState} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";
import InputRemove from "../form/input-remove";
import {deleteElement} from "../../../api/config";
import {useNavigate} from "react-router-dom";
import {Warning} from "../alert/banner";
import {useTranslation} from "react-i18next";

export default function RemovePopup({isOpen, setIsOpen, detail, onRemoveConfirmed, setDetail = false}) {

    const [isLoadingRemove, setLoadingRemove] = useState(false);
    const [exception, setException] = useState(false);
    let navigate = useNavigate();
    const { t } = useTranslation();

    const remove = async () => {
        setLoadingRemove(true);
        deleteElement(detail.endpoint)
            .then((response) => {
                setLoadingRemove(false);
                if(detail.redirect){
                    navigate(detail.redirect);
                }
                if(setDetail){
                    setDetail(response);
                }
                setIsOpen(false);
                onRemoveConfirmed(true);
            })
            .catch((e) => {
                setException(e.message);
                setLoadingRemove(false);
            });
    };


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-white shadow-xl">

                            <div className="flex justify-between border-b p-4 items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">{detail.title}</h3>
                                </div>
                                <div>
                                    <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                </div>
                            </div>

                            <div className="mt-2 p-4">
                                <p className="text-sm text-gray-500">
                                    {detail.message}
                                </p>

                                {/* Warning */}
                                {exception && <Warning message={exception} />}
                            </div>

                            <div className="p-4 flex before:flex-1 items-center justify-between ">
                                <InputRemove
                                    isLoading={isLoadingRemove}
                                    label={t("app.common.remove")}
                                    onClick={(e) => {e.preventDefault(); remove()}}
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
