import React, { useEffect, useState } from "react";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {P404, SALES, C_CONNECTION_OVERVIEW, C_CONNECTION_NOTES, C_CONNECTION_ATTACHMENTS, C_CONNECTION_TASKS, C_CONNECTION_LOGS} from "../endpoints";
import {getPathParam} from "../../utils/converter";
import {getPermission, permissionGroup, permissionSpecific, permissionType} from "../../constants/permissions";
import {COMPANY, LEAD} from "../../api/endpoints";
import {fetchCountries, fetchCurrencies, fetchLanguages} from "../../reducers/configReducer";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import {getLead} from "../../api/lead";
import {ConnectionHeaderNavigation, ConnectionPanelNavigation} from "../../components/partials/panel/connection-panel-navigation";
import GenericEmailSender from "../../components/partials/email/generic-email-sender";
import {loadConnections} from "../../reducers/connectionReducer";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LeadOverview = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  // get customer
  const [isLoading, setLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [typeActive, setTypeActive] = useState(null);

  const { permissions } = useSelector((s) => s.userCompany);

  const canEdit =  hasPermissionFor(permissions, permissionGroup.LEAD, permissionType.EDIT);
  const canSendMail = hasPermissionFor(permissions, permissionGroup.LEAD, permissionSpecific.LEAD_SEND_MAIL);


  // update customer
  const [isSidebarOpen, setOpenSidebar] = useState(false);

  // update customer
  const [isOpenEmailSender, setOpenEmailSender] = useState(false);


  const fetchLead = (signal = null, loading = true) => {
      if (loggedCompany) {
          setLoading(loading);
          getLead({ company_id: loggedCompany.id, lead_id: id, signal })
              .then((response) => {
                  setLead(response);
                  setLoading(false);
              })
              .catch((e) => {
                  setLoading(false);
                  e.code === 404 && navigate(P404);
              });
      }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    fetchLead(signal);

    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchLanguages());

    return () => {
      controller.abort();
    };
      // eslint-disable-next-line
  }, [id, loggedCompany, dispatch]);




  const secondaryNavigation = [
        { name: t("app.leads.overview"), type: 'overview', location: getPathParam([SALES, LEAD, id, C_CONNECTION_OVERVIEW]), current: pathname.includes(getPathParam([SALES, LEAD, id, C_CONNECTION_OVERVIEW])) },
        canEdit && { name: t("app.leads.notes"), type: 'note',location: getPathParam([SALES, LEAD, id, C_CONNECTION_NOTES]), current: pathname.includes(getPathParam([SALES, LEAD, id, C_CONNECTION_NOTES])) },
        canEdit && { name: t("app.leads.attachments"), type: 'attach', location: getPathParam([SALES, LEAD, id, C_CONNECTION_ATTACHMENTS]), current: pathname.includes(getPathParam([SALES, LEAD, id, C_CONNECTION_ATTACHMENTS])) },
        { name: t("app.menu.boards"), type: 'task', location: getPathParam([SALES, LEAD, id, C_CONNECTION_TASKS]), current: pathname.includes(getPathParam([SALES, LEAD, id, C_CONNECTION_TASKS])) },
        canEdit && { name: t("app.leads.logs"), type: 'log', location: getPathParam([SALES, LEAD, id, C_CONNECTION_LOGS]), current: pathname.includes(getPathParam([SALES, LEAD, id, C_CONNECTION_LOGS])) }
  ].filter(Boolean);


  return (
      <BasePermissionWrapper
          requiredPermissions={[getPermission(permissionGroup.LEAD, permissionType.VIEW)]}
          requireExporter={true}
          redirectWrongCompanyType={true}
          renderBaseOnFail={true}
      >

        <LoaderWrapper isLoading={isLoading}>



          <div className="flex h-full">

            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

              <div className="relative z-0 flex flex-1 overflow-hidden">


                  <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">


                  {/* Start main area*/}
                  <div className="absolute inset-0 ">

                      <ConnectionHeaderNavigation canEdit={canEdit} connection={lead} onOpenSidebar={(r) => {setOpenSidebar(r)} } isSidebarOpen={isSidebarOpen} />


                      <div className="bg-white border-b border-gray-200">
                          <nav className=" flex space-x-8 max-w-7xl mx-auto justify-center overflow-x-auto px-4 leading-6   sm:px-6 lg:px-8" aria-label="Tabs">
                              {secondaryNavigation.map((tab) => (
                                  <Link
                                      key={tab.name}
                                      to={'/'+tab.location}
                                      className={classNames(
                                          tab.current
                                              ? 'border-sky-400 text-blue-1000'
                                              : 'border-transparent text-blue-1000/[0.5] hover:text-blue-1000 hover:border-sky-400',
                                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-xs uppercase'
                                      )}
                                  >
                                      {tab.name}
                                  </Link>
                              ))}
                          </nav>
                      </div>

                      <Outlet
                          context={{
                              connectionId: lead.connection_id,
                              entityId: lead.id,
                              overviewData: lead,
                              typeActive: typeActive,
                              canEdit: canEdit,
                              removeActive: () => {
                                  setTypeActive(null);
                              },
                              onUpdateData: (r) => {
                                  setLead(r);
                              },
                              onRefresh: (r) => {
                                  fetchLead(null, false);
                              }
                          }}
                      />


                  </div>
                  {/* End main area */}
                </main>


                  <ConnectionPanelNavigation
                      connection={lead}
                      canEdit={canEdit}
                      onOpenSidebar={(r) => {setOpenSidebar(r)} }
                      isSidebarOpen={isSidebarOpen}
                      onCreate={(r) => {
                          if(r === 'mail'){
                              setOpenEmailSender(true);
                          }else{
                              setTypeActive(r);
                              navigate("/"+secondaryNavigation.find(n => n.type === r).location);

                          }

                      }}
                      onResponse={(response) => {
                          setLead(response);
                          dispatch(loadConnections({company_id: loggedCompany.id, params: {find_all: true}}));
                      }}
                      detailGroups={[
                          {
                              endpoint: getPathParam([COMPANY, loggedCompany?.id ?? '', LEAD, lead?.id ?? '' ]),
                              isCreate: false,
                              refId: lead?.id ?? '' ,
                              defaultOpen: true,
                              title: t("app.leads.general_title"),
                              fields: [
                                  {field: 'last_contact_date', translation: 'app.leads.last_contact_date',   value: lead?.last_contact_date ?? '-',  viewType: 'date', formType: 'date', required: false},
                                  {field: 'estimated_value', translation: 'app.leads.estimated_value',   value: lead?.estimated_value ?? '-',  viewType: 'currency', formType: 'currency', required: false},
                                  {field: 'probability_percentage', translation: 'app.leads.probability_percentage',  value: lead?.probability_percentage ?? '-', viewType: 'percentage', formType: 'number', required: false},
                                  {field: 'internal_note', translation: 'app.leads.internal_note',  value: lead?.internal_note ?? '-', viewType: 'string', formType: 'textarea', required: false},
                                  {field: 'priority', translation: 'app.leads.priority',  value: lead?.priority ?? '-', viewType: 'priority', formType: 'priority', required: false}
                              ]
                          },
                          {
                              endpoint: getPathParam([COMPANY, loggedCompany?.id ?? '', LEAD, lead?.id ?? '' ]),
                              isCreate: false,
                              refId: lead?.id ?? '' ,
                              defaultOpen: false,
                              title: t("app.leads.contact_title"),
                              fields: [
                                  {field: 'contact_first_name', translation: 'app.leads.contact_first_name',   value: lead?.contact_first_name ?? '-',  viewType: 'string', formType: 'string', required: true},
                                  {field: 'contact_last_name', translation: 'app.leads.contact_last_name',  value: lead?.contact_last_name ?? '-', viewType: 'string', formType: 'string', required: true},
                                  {field: 'business_email', translation: 'app.leads.business_email',  value: lead?.business_email ?? '-', viewType: 'string', formType: 'string', required: true},
                                  {field: 'business_phone', translation: 'app.leads.business_phone',  value: lead?.business_phone ?? '-', viewType: 'string', formType: 'string', required: false}
                              ]
                          },
                          {
                              endpoint: getPathParam([COMPANY, loggedCompany?.id ?? '', LEAD, lead?.id ?? ''  ]),
                              defaultOpen: false,
                              isCreate: false,
                              refId: lead?.id ?? '' ,
                              title: t("app.leads.company_title"),
                              fields: [
                                  {field: 'business_name', translation: 'app.leads.business_name',  value: lead?.business_name ?? '-', viewType: 'string', formType: 'string', required: true},
                                  {field: 'company_website', translation: 'app.leads.company_website',  value: lead?.company_website ?? '-', viewType: 'string', formType: 'string', required: false},
                                  {field: 'company_note', translation: 'app.leads.company_note',  value: lead?.company_note ?? '-', viewType: 'string', formType: 'textarea', required: false},
                                  {field: 'business_country', translation: 'app.leads.business_country',  value: lead?.business_country?.name ?? null, object: lead?.business_country ?? null, viewType: 'string', formType: 'country', required: false},
                                  {field: 'company_industry', translation: 'app.leads.company_industry',  value: lead?.company_industry ?? '-', viewType: 'string', formType: 'string', required: false},
                                  {field: 'company_employees', translation: 'app.leads.company_employees',  value: lead?.company_employees ?? '-', viewType: 'number', formType: 'number', required: false},
                                  {field: 'company_revenue', translation: 'app.leads.company_revenue',  value: lead?.company_revenue ?? '-', viewType: 'currency', formType: 'currency', required: false},
                              ]
                          }
                      ]}
                  />
              </div>
            </div>
          </div>

            {
                canSendMail && (
                    <GenericEmailSender
                        isOpen={isOpenEmailSender}
                        baseTitle={t("app.leads.lead")}
                        entityType={'connection'}
                        entityNote={'lead'}
                        entityId={lead?.connection_id ?? ''}
                        baseEmail={[{
                            id: 1,
                            full_name: (lead?.contact_first_name ?? '')+(lead?.contact_last_name ?? ''),
                            email: lead?.business_email
                        }]}
                        baseAttachments={lead?.last_connection_attachment ?? []}
                        setIsOpen={(r) => {
                            setOpenEmailSender(r);
                        }}
                    />
                )
            }



        </LoaderWrapper>

      </BasePermissionWrapper>
  );
};

export default LeadOverview;
