import React, {useEffect, useState} from 'react'
import {getImport, getImports} from "../../../api/import";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import RemovePopup from "../../common/popup/remove-popup";
import {getPathParam} from "../../../utils/converter";
import {COMPANY, IMPORT} from "../../../api/endpoints";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";

export default function ImportProgress({onNeedRefresh, importType, lastImport, importEntityId = false, uploadType = true, extraClass = 'w-1/4'}) {

    const { loggedCompany } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // Import Data details
    const [importDetail, setImportDetail] = useState(lastImport);
    const { t } = useTranslation();

    // Counter details
    const [second, setSecond] = useState(1);
    const [startCounter, setStartCounter] = useState(false);

    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});


    // Check last Import Process
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        function fetchImports() {
            if (loggedCompany) {
                getImports({
                    company_id: loggedCompany.id,
                    params: { limit: 1, offset: 0, type: importType, entity_id: importEntityId ? importEntityId : null },
                    signal,
                }).then((response) => {
                    if(response.imports.length > 0 && response.imports[0].status !== 'done' && response.imports[0].status !== 'error'){
                        setImportDetail(response.imports[0]);
                        setStartCounter(true);
                    }
                }).catch((e) => {
                });
            }
        }

        fetchImports();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, lastImport, importEntityId]);



    useEffect(() => {
        const interval = setInterval( () => {
            if (startCounter) {
                setSecond(second + 1);
                if (second === 1) {
                    if(loggedCompany && importDetail && importDetail.status !== 'done'){
                        getImport({company_id: loggedCompany.id, import_id: importDetail.id})
                            .then(response => {
                                setSecond(0);
                                if(response.status === 'done' || response.status === 'error'){
                                    setStartCounter(false);
                                    setImportDetail(false);
                                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                                    if(response.status === 'error'){
                                        toast.error(t("app.import_log.import_error"));
                                    }
                                    onNeedRefresh(response);
                                }else{
                                    setImportDetail(response);
                                }

                            }).catch(e => {
                                setStartCounter(false);
                                setImportDetail(false);
                            });
                    }

                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });


    if(uploadType !== true && uploadType !== importDetail.import_type ){
        return (<></>);
    }



    return (
        importDetail && (
            <>

                <div className={" mt-2 flex flex-col " + extraClass}>
                    <div className="w-full bg-gray-200 rounded">
                        <div className=" text-xs font-medium rounded text-white text-center p-2 leading-none  shim-green"
                             style={{width: importDetail.percentage+'%'}}> {importDetail.percentage.toFixed(0)}%
                        </div>
                    </div>
                    <p className="text-xs text-gray-800 flex items-center justify-between">
                        <span>{t("app.import_log.import_progress")}</span>
                        <a
                            onClick={() => {
                                const removeDetail = {
                                    title: t("app.import_log.remove_title"),
                                    message: t("app.import_log.remove_description"),
                                    endpoint: getPathParam([COMPANY, loggedCompany.id, IMPORT, importDetail.id])
                                };
                                setRemoveDetail(removeDetail);
                                setOpenRemove(true);
                            }}
                            href={'#0'} className={'text-xs text-indigo-900 underline'}>{t("app.import_log.cancel_import")}</a>
                    </p>
                </div>

                {isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        onRemoveConfirmed={() => {
                            setStartCounter(false);
                            setImportDetail(false);
                        }}
                    />
                )}
            </>

        )
    )
}
