import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import { CalendarIcon, ChatIcon, ClipboardIcon, ClipboardListIcon, ClockIcon, DocumentTextIcon, MenuAlt1Icon, PaperClipIcon, PlusIcon, XIcon } from '@heroicons/react/outline'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import BoardReletedEntities from '../../../constants/config';
import { getElement, postElement } from '../../../api/config';
import { getPathParam } from '../../../utils/converter';
import { BOARD, BOARDS, COMPANY, COMPANY_TASKS, RELETED_ENTITY, STATUSES } from '../../../routes/endpoints';
import LoaderWrapper from '../../common/loader/loader-wrapper';
import { getFileType } from '../../../constants/file-extension';
import { getCustomFormat, getFormattedDate } from '../../../utils/timeUtils';
import TooltipItem from '../../common/button/tooltip';
import UsersToolbar from '../../common/card/users-toolbar';
import UserInitial from '../../common/table/user-initial';
import TaskManage from '../../../routes/board/task-manage';
import { Controller, useForm } from 'react-hook-form';
import BoardPopup from '../../board/board-popup';
import InputText from '../../common/form/input-text';
import { Warning } from '../../common/alert/banner';
import ButtonSubmit from '../../common/form/button-submit';
import DynamicSearch from '../common/dynamic-search';
import { STATUS, TASK } from '../../../api/endpoints';
import InputSearch from '../../common/form/input-search';
import moment from 'moment';
import NumberFormat from 'react-number-format';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function addNewLine(stringa, len) {
    let risultato = '';
    let parole = stringa.split(' ');
    let rigaCorrente = '';

    for (let i = 0; i < parole.length; i++) {
        if (rigaCorrente.length + parole[i].length + 1 > len) {
            risultato += rigaCorrente.trim() + '\n';
            rigaCorrente = '';
        }
        rigaCorrente += parole[i] + ' ';
    }

    risultato += rigaCorrente.trim();

    return risultato;
}

export default function SystemTasks({ taskContext, taskContextId }) {

    const { loggedCompany } = useSelector((state) => state.user);


    const [isLoading, setLoading] = useState(true);
    const [isOpen, setOpen] = useState(false);
    const [list, setList] = useState([]);


    const [isOpenAddTask, setOpenAddTask] = useState(false);
    const [search, setSearch] = useState(false);



    const [isOpenTask, setOpenTask] = useState(false);
    const [statusTaskDetail, setStatusTaskDetail] = useState(false);
    const [boardData, setBoardData] = useState(false);
    const [taskDetail, setTaskDetail] = useState(false);

    const relationEntities = BoardReletedEntities().filter(r => r.accosciateEntity === true);
    const isActiveTask = relationEntities.filter(r => r.value === taskContext).length > 0



    const getRefreshed = async (signal = null) => {
        try {
            const res = await getElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_TASKS]), signal, { entity_id: taskContextId, query: search });
            setList(res.tasks);
        } catch (e) {
            setLoading(false);
            setList([]);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            if (isActiveTask && loggedCompany) {
                getRefreshed(signal)
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isActiveTask, search]);









    return (
        <>
            {isActiveTask && (
               <>
                    <div className="ml-3 relative">
                        <div onClick={() => { setOpen(true) }} className="max-w-xs  flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Tasks</span>
                            <div className="flex items-center truncate w-8 h-8 flex cursor-pointer items-center justify-center group bg-gray-900 hover:bg-gray-700 transition duration-150 rounded-full mr-1">
                                <ClipboardListIcon className="h-4 w-4 text-white " aria-hidden="true" />
                                {
                                    list.length > 0 && (
                                        <span className="absolute top-0 right-0 inline-flex mr-1 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                            {list.length >= 9 ? '9+' : list.length}
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>


                    <TaskPanel 
                        open={isOpen} 
                        setOpen={() => {
                            setOpen(false);
                        }}
                        onAddTask={() => {
                            setOpenAddTask(true);
                        }}
                        onSearch={(r) => {
                            setSearch(r);
                        }}
                    >
                        <LoaderWrapper isLoading={isLoading}>
                            {
                                list.map((task) => (
                                    <QuoteItem key={task.id} task={task} onOpen={() => {
                                        setStatusTaskDetail(task.board_status);
                                        setBoardData(task.virtual_board);
                                        setTaskDetail(task);
                                        setOpenTask(true);
                                    }} />
                                ))
                            }
                            
                            
                            {
                                (statusTaskDetail && boardData && taskDetail) && (
                                    <TaskManage
                                        isOpen={isOpenTask}
                                        setIsOpen={setOpenTask}
                                        boardData={boardData}
                                        statusDetail={statusTaskDetail}
                                        taskDetail={taskDetail}
                                        onRefresh={(r) => {
                                            getRefreshed();
                                        }}
                                        setClean={() => {
                                            setStatusTaskDetail(false);
                                            setBoardData(false);
                                            setTaskDetail(false);
                                            setOpenTask(false);
                                        }}
                                        onOpenReletadTask={(taskData) => {
                                            setStatusTaskDetail(taskData.board_status);
                                            setBoardData(taskData.virtual_board);
                                            setTaskDetail(taskData);
                                            setOpenTask(true);
                                        }}
                                    />
                                )
                            }


                        </LoaderWrapper>
                        
                        
                        
                        {
                            isOpenAddTask && (
                                <AddTask
                                    isOpen={isOpenAddTask}
                                    onOpen={setOpenAddTask}
                                    taskContext={taskContext}
                                    taskContextId={taskContextId}
                                    onAddTaskData={(r) => {
                                        setStatusTaskDetail(r.board_status);
                                        setBoardData(r.virtual_board);
                                        setTaskDetail(r);
                                        setOpenAddTask(false);
                                        setOpenTask(true);
                                        getRefreshed();
                                    }}
                                />
                            )
                        }
                      
                        
                        





                    </TaskPanel>
               </>
            )}






        </>
    )
}



const TaskPanel = ({ open, setOpen, onAddTask, children, onSearch }) => {

    const { t } = useTranslation();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-[300px]">
                                <div className="h-full flex flex-col bg-solitude-100 shadow-xl overflow-y-scroll">
                                    <div className="p-2 bg-white-400">
                                        <div className="flex items-start justify-between ">
                                            <Dialog.Title className="text-md font-medium text-gray-900"> {t("app.board.manage_task")}</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">

                                                <button
                                                    type="button"
                                                    className="text-gray-900 hover:text-gray-900/[0.7] mr-3"
                                                    onClick={() => onAddTask()}
                                                >
                                                    <PlusIcon className="h-4 w-4" aria-hidden="true" />
                                                </button>

                                                <button
                                                    type="button"
                                                    className="text-gray-900 hover:text-gray-900/[0.7]"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-4 w-4" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-2">
                                        <InputSearch onSearch={onSearch} isDynamicSearch={false} hasMargin={false} extraClass={' border border-gray-200 shadow-sm mb-3'} />
                                        {children}
                                    </div>


                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}



const QuoteItem = ({ task, onOpen }) => {
    const [pictureAttachment, setPictureAttachment] = useState(false);
    const { t } = useTranslation();
    const today = new Date();
    const { company } = useSelector((state) => state.userCompany);

    const findPirctureAttachment = (attachments) => {

        let flag = true;
        let isStop = false;
        // eslint-disable-next-line
        attachments.map((attachment) => {
            if (attachment.file && !isStop) {
                const ext = attachment.file.reference.split('.').pop();
                const fileType = getFileType(ext);
                if (fileType && fileType.split('/').shift() === 'image') {
                    setPictureAttachment(attachment.file.reference);
                    flag = false;
                    isStop = true;
                }
            }
        });

        if (flag) {
            setPictureAttachment(false);
        }
    }

    useEffect(() => {
        if (task) {
            findPirctureAttachment(task?.task_attachments ?? []);
        }
    }, [task])


    const getCheckListCount = (checklists) => {
        let total = 0;
        let confirmed = 0;
        // eslint-disable-next-line
        (checklists ?? []).map((checklist) => {
            // eslint-disable-next-line
            (checklist?.checklists ?? []).map((item) => {
                // eslint-disable-next-line
                if (item.status) {
                    confirmed = confirmed + 1;
                }
                total = total + 1;
            })
        })
        return confirmed + "/" + total;
    }

    const getCheckPercentage = (checklists) => {
        let percentage = 0;
        let confirmed = 0;
        let total = 0;
        // eslint-disable-next-line
        (checklists ?? []).map((checklist) => {
            // eslint-disable-next-line
            (checklist?.checklists ?? []).map((item) => {
                // eslint-disable-next-line
                if (item.status) {
                    confirmed = confirmed + 1;
                }
                total = total + 1;
            })
        })

        if (total > 0) {
            percentage = (100 / total) * confirmed;
        }

        return percentage;
    }

    const taskMembers = (task) => {
        let users = [];
        if (task.assigned_users.length > 0) {
            users = task.assigned_users.filter(r => r.id !== task.user.id);
        }
        users.splice(0, 0, task.user);
        return users;
    }



    return (
        <div
            className={` border border-gray-200 hover:ring-2 ring-blue-600 rounded-md shadow-sm bg-white mb-3 select-none flex  flex-col cursor-pointer`}
            onClick={() => {
                onOpen(true)
            }}
        >

            {
                task && (
                    <>
                        {
                            pictureAttachment && (
                                <div className={' bg-gray-900 rounded-t-md'}>
                                    <img className="w-full  object-contain rounded-t-md    h-[260px] max-h-[260px]" src={pictureAttachment} alt="" />
                                </div>
                            )
                        }
                        <div className='p-2'>
                            <div className={classNames(
                                task.check_list.length > 0 && "mb-2",
                                'flex items-center justify-between'
                            )}>
                                <h1 className="text-xs font-normal text-gray-900" style={{ fontSize: '10px' }}>{task.task_reference}</h1>
                                {
                                    task.check_list.length > 0 && (
                                        <div className="flex items-center text-xs text-gray-600 cursor-default">
                                            <TooltipItem position={"right"} tooltipsText={t("app.board.tol_checklist")}>

                                                <div className='flex items-center'>
                                                    <div className="w-[30px] bg-gray-200 rounded">
                                                        <div className={classNames(
                                                            getCheckPercentage(task.check_list) >= 50 ? 'shim-green' : '',
                                                            getCheckPercentage(task.check_list) < 50 ? 'shim-red' : '',
                                                            "text-xs font-medium rounded text-white text-center p-1 leading-none"
                                                        )} style={{ width: getCheckPercentage(task.check_list) + '%' }}>
                                                        </div>
                                                    </div>
                                                    <p className='flex ml-1 items-center'>
                                                        {getCheckListCount(task.check_list)}
                                                        <ClipboardIcon className=" h-4 w-4 ml-1 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                                    </p>
                                                </div>
                                            </TooltipItem>
                                        </div>
                                    )
                                }
                            </div>
                            <h1 className="text-base font-medium text-gray-900 whitespace-pre-line">
                                {addNewLine(task.title, 30)}
                            </h1>



                            {
                                task.task_date && (
                                    <div className="mt-1">
                                        <div className={classNames("mt-2 flex items-center text-xs  mr-2",
                                            (moment(task.end_date, "YYYY-M-D HH:mm:ss") <= today) ? "bg-red-500 text-white rounded p-1" : "text-gray-600",
                                        )}>

                                            {
                                                (moment(task.end_date, "YYYY-M-D HH:mm:ss") <= today) ? (
                                                    <ClockIcon className="w-4 h-4 mr-1 flex-shrink-0 " />
                                                ) : (
                                                    <CalendarIcon className="mr-1 h-4 w-4  flex-shrink-0 " aria-hidden="true" />
                                                )
                                            }

                                            {getCustomFormat(task.task_date, "DD/MM/YYYY")}
                                            {
                                                task.end_date && (
                                                    <>
                                                        -  {getCustomFormat(task.end_date, "DD/MM/YYYY")}
                                                    </>
                                                )
                                            }

                                        </div>
                                    </div>

                                )
                            }

                            {
                                (task?.properties ?? []).filter(r => (r?.attach ?? false) === true).length > 0 && (
                                    <div className="my-2">
                                        {
                                            task.properties.filter(r => (r?.attach ?? false) === true).map(r => (
                                                <div className={classNames("mt-1 flex items-center text-gray-900 text-xs  mr-2")}>

                                                    <span>{r.title}:{' '}
                                                        {
                                                            r.type === 'date' && (
                                                                <b> {getFormattedDate(r.value)}</b>
                                                            )
                                                        }

                                                        {
                                                            r.type === 'text' && (
                                                                <b> {r.value}</b>
                                                            )
                                                        }

                                                        {
                                                            r.type === 'number' && (
                                                                <b>
                                                                    <NumberFormat
                                                                        value={r.value}
                                                                        displayType={"text"}
                                                                        decimalScale={2}
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix={""}
                                                                    />
                                                                </b>
                                                            )
                                                        }


                                                        {
                                                            r.type === 'currency' && (
                                                                <b>
                                                                    <NumberFormat
                                                                        value={r.value}
                                                                        displayType={"text"}
                                                                        decimalScale={2}
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                                    />
                                                                </b>
                                                            )
                                                        }

                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }





                            {
                                (task.description || task.related_tasks_count > 0 || task.attachment_count > 0 || task.activities_count > 0) ? (
                                    <div className=" flex flex-row flex-wrap mb-3">

                                        {
                                            task.description && (
                                                <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                                                    <TooltipItem position={"right"} tooltipsText={t("app.board.tol_description")}>
                                                        <MenuAlt1Icon className="h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                                    </TooltipItem>
                                                </div>
                                            )
                                        }
                                        {
                                            task.related_tasks_count > 0 && (
                                                <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                                                    <TooltipItem position={"right"} tooltipsText={t("app.board.tol_sub_task")}>
                                                        <DocumentTextIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                                        {task.related_tasks_count}
                                                    </TooltipItem>
                                                </div>
                                            )
                                        }

                                        {
                                            task.attachment_count > 0 && (
                                                <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                                                    <TooltipItem position={"right"} tooltipsText={t("app.board.tol_attachment")}>
                                                        <PaperClipIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                                        {task.attachment_count}
                                                    </TooltipItem>
                                                </div>
                                            )
                                        }

                                        {
                                            task.activities_count > 0 && (
                                                <div className="mt-2 flex items-center text-xs text-gray-600 mr-2 cursor-default">
                                                    <TooltipItem position={"right"} tooltipsText={t("app.board.tol_comment")}>
                                                        <ChatIcon className="mr-1 h-4 w-4 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                                        {task.activities_count}
                                                    </TooltipItem>
                                                </div>
                                            )
                                        }

                                    </div>
                                ) : (
                                        <div className='mb-4' />

                                )}


                            <div className='flex items-end justify-between relative'>

                                <div>
                                    {
                                        task.created === task.updated ? (
                                            <p className="mt-0.5 text-xs text-gray-500" style={{ fontSize: '10px' }}>
                                                {t("app.board.created_on", { date: getCustomFormat(task.created, "DD/MM/YYYY") })}
                                            </p>
                                        ) : (
                                            <p className="mt-0.5 text-xs text-gray-500" style={{ fontSize: '10px' }}>
                                                {t("app.board.updated_on", { date: getCustomFormat(task.updated, "DD/MM/YYYY") })}
                                            </p>
                                        )
                                    }
                                </div>

                                {
                                    taskMembers(task).length > 0 && (
                                        <UsersToolbar users={(taskMembers(task))} isActive={taskMembers(task).length > 0} extraData={getCustomFormat(task.created, "DD/MM/YYYY")} className={'absolute right-0'}>
                                            <div className="flex items-center space-x-2 mr-3">
                                                <div
                                                    className={taskMembers(task).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                                                    {(taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).map((user, index) => (
                                                        index === 0 ? (
                                                            <UserInitial user={user} ringClass={" ring-yellow-400 "} />
                                                        ) : (
                                                            <UserInitial user={user} />
                                                        )
                                                    ))}
                                                </div>

                                                {taskMembers(task).length > (taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).length ? (
                                                    <span className="flex-shrink-0 text-xs font-medium leading-5">
                                                        +{taskMembers(task).length - (taskMembers(task).length >= 4 ? taskMembers(task).slice(0, 4) : taskMembers(task)).length}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </UsersToolbar>
                                    )
                                }

                            </div>



                        </div>


                    </>
                )
            }



        </div>
    );
}

    
const AddTask = ({ isOpen, onOpen, taskContext,  taskContextId, onAddTaskData }) => {

    
    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false)
    const [exception, setException] = useState(false)

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm();
    const { t } = useTranslation();


    const onUpdate = async (data) => {
        setLoading(true);
        setException(false);
        postElement(getPathParam([BOARD, data['dynamic_board'].id, STATUS, data['dynamic_status'].id, TASK]), { title: data['title']})
            .then(async response => {
                let sendData = { entity_name: taskContext, entity_id: taskContextId }
                await onSubmitRelation(response, data['dynamic_board'], sendData);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };

    const onSubmitRelation = async (taskData, boardData, data) => {
        postElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY]), data)
            .then(response => {
                onAddTaskData(taskData);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });

    };


    return (
        <BoardPopup
            isOpen={isOpen}
            setIsOpen={onOpen}
            canClose={true}
            setClean={() => {

            }}
        >

            <div className={"-mt-4 mb-3 bg-white"}>

                <div className="px-4 sm:px-6">

                    <>
                        {/* TITLE */}
                        <div className="flex items-center justify-between mt-3">
                            <div className="flex items-start space-x-5">
                                <div className="pt-1.5">
                                    <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.add_task")}</h1>
                                </div>
                            </div>
                        </div>

                        {/* CONTENT */}
                        <form onSubmit={handleSubmit(onUpdate)} className={' border-none pb-5 mt-5'}>
                            <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">


                                <div className="col-span-2 md:col-span-1 ">
                                    <Controller
                                        name="dynamic_board"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <DynamicSearch
                                                selectLabel={t("app.board.title")}
                                                endpoint={getPathParam([COMPANY, loggedCompany.id, BOARDS])}
                                                responseLabel={'boards'}
                                                label={'title'}
                                                errors={error}
                                                value={value}
                                                name={name}
                                                onResponse={(r) => {
                                                    let items = r.filter(item => item.accosciate_entity === taskContext)
                                                    if (items.length > 0){
                                                        setValue('dynamic_board', items[0]);
                                                    }
                                                }}
                                                onChange={async (r) => {
                                                    onChange(r);
                                                }}
                                            />
                                        )}
                                    />
                                </div>



                                <div className="col-span-2 md:col-span-1 ">
                                    <Controller
                                        name="dynamic_status"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <DynamicSearch
                                                selectLabel={t("app.board.status_select")}
                                                endpoint={getPathParam([BOARD, watch('dynamic_board')?.id ?? '', STATUSES])}
                                                responseLabel={'statuses'}
                                                label={'title'}
                                                errors={error}
                                                value={value}
                                                isDisabled={!watch('dynamic_board')}
                                                name={name}
                                                onChange={async (r) => {
                                                    onChange(r);
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="col-span-4 md:col-span-2">
                                    <InputText
                                        id={'title'}
                                        type={"text"}
                                        errors={errors.title}
                                        input={{ ...register('title', { required: true }) }}
                                        label={t('app.board.task_title')}
                                        isRequired={true}
                                    />
                                </div>


                                {/* Warning */}
                                {exception && (
                                    <div className="col-span-4 md:col-span-2">
                                        <Warning message={exception} />
                                    </div>
                                )}


                                <div className={"col-span-4 md:col-span-2  mt-6"}>
                                    <div className="flex flex-col items-center">
                                        <ButtonSubmit
                                            btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                            isLoading={isLoading}
                                            isFullWith={true}
                                            label={t("app.board.board_edit_btn")}
                                        />
                                    </div>
                                </div>

                            </div>
                        </form>
                    </>
                </div>

            </div>

        </BoardPopup>
    );
}

