import axios from "axios";
import {clearToken} from "./tokenUtil";

let store;
export const injectStore = _store => {
    store = _store
};

const defaultBaseUrl = "http://localhost/api/ss";
const version = "0.0.1";
const API = axios.create({
    baseURL: process.env?.REACT_APP_API_BASE_URL ?? defaultBaseUrl,
    responseType: "json",
    timeout: 20000,
    headers: {
        "Content-Type": "application/json",
        "X-Accept-Version": version
    },
});

API.interceptors.request.use(async function (req) {
    if (store.getState().user.token !== null) {
        req.headers.Authorization = 'Bearer '+store.getState().user.token;
    }
    return req;
});


API.interceptors.response.use(
    function (response) {
        //console.log(response);
        return response.data;
    },function (error) {
        if (error.response){



            let status = 0;
            let message = '';
            if (error.response != null && error.response.data != null) {
                status = error.response.data.code;
                message = error.response.data.message;
                if(error.response.data.validation != null){
                    message = error.response.data.validation[0];
                }
            }

            if(store.getState().user.token && status === 401){
                clearToken();
                localStorage.clear();
                window.location.reload();
            }

            return Promise.reject({ code: status, message: message });
        }else if(error.request){
            //console.log(error.request);
        }else if(error.message){
            return Promise.reject({ code: 0, message: 'Request Canceled' });
        }

    }
);

export default API;


export function sendMedia(url, response, uri){
    let formData = new FormData();
    formData.append('Acl', response.acl);
    formData.append('Content-Type', response["content-type"]);
    formData.append('Key', response.key);
    formData.append('X-Amz-Credential', response["X-Amz-Credential"]);
    formData.append('X-Amz-Algorithm', response["X-Amz-Algorithm"]);
    formData.append('X-Amz-Date', response["X-Amz-Date"]);
    formData.append('Policy', response.Policy);
    formData.append('X-Amz-Signature', response["X-Amz-Signature"]);
    formData.append('file', uri);
    return axios.post(url, formData);
};


export function setApi(key, value){
    localStorage.setItem(btoa(key), btoa(unescape(encodeURIComponent(JSON.stringify(value)))));
}
export function getApi(key){
    if(localStorage.getItem(btoa(key))){
        let data = localStorage.getItem(btoa(key));
        return JSON.parse(decodeURIComponent(escape(atob(data))));
    }
    return false;
}
export const clearApi = (key) => localStorage.removeItem(btoa(key));
