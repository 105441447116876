import React, {useEffect, useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import {getAttachmentsParams, getEmailsParams, getSelectParams, getWebPathParam} from "../../../utils/converter";
import {Controller, useForm} from "react-hook-form";
import InputSelect from "../../common/form/input-select";
import InputSelectInsert from "../../common/form/input-select-insert";
import {emailRegex} from "../../../constants/regex";
import {TrashIcon} from "@heroicons/react/outline/esm";
import FileCell from "../../common/table/file-cell";
import ReportViewer from "../report/report-viewer";
import {DocumentSearchIcon} from "@heroicons/react/outline";
import InputText from "../../common/form/input-text";
import {getCompanyMails, getSendCompanyMail} from "../../../api/company";
import {useSelector} from "react-redux";
import ButtonSubmit from "../../common/form/button-submit";
import {getCompanyShippingMails} from "../../../api/shipping";
import {ATTACHMENT} from "../../../constants/config";
import InputEmailEditor from "../../common/form/input-email-editor";
import {Warning} from "../../common/alert/banner";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";
import {
    COMPANY,
    CONFIGURATION,
    EMAIL_INTEGRATION
} from "../../../routes/endpoints";

const EmailSender = ({isOpen, setIsOpen, shippingDetail, documents }) => {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);


    const [isOpenReportView, setOpenReportView] = useState(false);
    const [document, setDocument] = useState(null);

    const [isLoadingEmails, setLoadingEmails] = useState(true);
    const [defaultEmails, setDefaultEmails] = useState([]);
    const [isLoadingCompanyEmails, setLoadingCompanyEmails] = useState(true);
    const [companyEmails, setCompanyEmails] = useState([]);



    const [isSendingMail, setSendingMail] = useState(false);
    const [exception, setException] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const [selectedAttachments, setSelectedAttachments] = useState([]);


    const {
        register,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ mode: "onBlur" });


    const clean = () =>{
        setSelectedAttachments([]);
        setException(false);
        setValue('mail_provider', null);
        setValue('recipients', null);
        setValue('content', null);
        setValue('subject', null);
    };

    useEffect(() => {
        async function fetchEmails() {
            if (shippingDetail && isOpen) {
                setLoadingEmails(true);
                setDefaultEmails([]);
                try {
                    const eMails = await getCompanyShippingMails({company_id: loggedCompany.id, shipping_id: shippingDetail.id});
                    let list = [];
                    let subjects = [];


                    if((eMails?.forwarders ?? []).length > 0){
                        list.push({ label: t("app.forwarder.forwarder"), options: getEmailsParams(eMails.forwarders, "email")});
                        if(shippingDetail.forwarder_reference){
                            subjects.push(t("app.shippings.reference_label", {label: shippingDetail.forwarder_reference}));
                        }
                    }

                    if((eMails?.employees ?? []).length > 0){
                        list.push({ label: 'Staff', options: getEmailsParams(eMails.employees, "email")});
                    }


                    if((eMails?.agents ?? []).length > 0){
                        list.push({ label: t("app.common.agents"), options: getEmailsParams(eMails.agents, "email")});
                    }

                    if((eMails?.supplier ?? []).length > 0){
                        list.push({ label: t("app.common.supplier"), options: getEmailsParams(eMails.supplier, "email")});
                        if(shippingDetail.importer_reference){
                            subjects.push(t("app.shippings.reference_label", {label: shippingDetail.importer_reference}));
                        }
                    }

                    if((eMails?.customer ?? []).length > 0){
                        list.push({ label: t("app.customers.customer"), options: getEmailsParams(eMails.customer, "email")});
                        if(shippingDetail.exporter_reference){
                            subjects.push(t("app.shippings.reference_label", {label: shippingDetail.exporter_reference}));
                        }
                    }


                    if(subjects.length > 0){
                        setValue('subject', subjects.join(' / '));

                    }

                    setDefaultEmails(list);

                } catch (e) {
                    setDefaultEmails([]);
                } finally {
                    setLoadingEmails(false);
                }
            }
        }

        fetchEmails();

        // eslint-disable-next-line
    }, [isOpen, shippingDetail, loggedCompany]);


    useEffect(() => {
        function fetchCompanyEmails() {
            if (loggedCompany && isOpen) {
                setLoadingCompanyEmails(true);
                getCompanyMails({company_id:  loggedCompany.id, params: {filter_by: 'active'}})
                    .then((response) => {
                        setCompanyEmails(getSelectParams(response, "email"));
                        setLoadingCompanyEmails(false);
                    }).catch((e) => {
                        setCompanyEmails([]);
                        setLoadingCompanyEmails(true);
                    });
            }
        }

        fetchCompanyEmails();

    }, [isOpen, loggedCompany]);


    useEffect(() => {
        if (documents.length > 0) {
            setAttachments(getAttachmentsParams(documents));
        }
    }, [documents]);



    const checkIfCanSend =  ()  => {
        if(selectedAttachments.length === 0){
            return false;
        }

        if(watch('recipients') && watch('recipients').length === 0){
            return false;
        }

        if(watch('subject') && watch('subject').length === 0){
            return false;
        }

        return true;
    };

    const sendMail = async ()  => {
        setSendingMail(true);



        let sendAttachments = [];
        // eslint-disable-next-line
        selectedAttachments.map(a => {
            sendAttachments.push({name: a.data.document_type.name +'.'+a.data.file.reference.split('.').pop(), file: a.data.file.reference})
        })

        let recipients = [];
        // eslint-disable-next-line
        watch('recipients').map(a => {
            recipients.push({full_name: a.full_name, email: a.email})
        })



        let data = {
            attachments: sendAttachments,
            recipients: recipients,
            content: watch('content'),
            subject: watch('subject')
        };


       if(loggedCompany){
           setException(false);
           getSendCompanyMail({company_id: loggedCompany.id, email_id: watch('mail_provider').id,  data})
                    .then(response => {
                        setSendingMail(false);
                        toast.success(t("app.provider.send_success"));
                        clean();
                        setIsOpen(false);
             }).catch(e => {
              setException(e.message);
              setSendingMail(false);
          });
      }
    };



    return (
        <>

            <ReportViewer
                isOpen={isOpenReportView}
                type={'document'}
                reportDetail={document?.file ?? false}
                title={document?.file_name ?? ''}
                isActiveConfirm={false}
                reportId={document?.id ?? false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />

            <SlidePanel
                title={t("app.document.send_document")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bg={'bg-white'}
                size={'max-w-2xl'}
                footer={Footer}
                setClean={() => {
                    clean();
                }}
                extras={{
                    isSendingMail,
                    isActiveSendMail: checkIfCanSend(),
                    onSubmit: () => {
                        sendMail();
                    }
                }}
            >
                <div className="space-y-6 pt-6 pb-5 mb-8">

                    <div>
                        <Controller
                            name="mail_provider"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.provider.email_from")}
                                    name={name}
                                    onChange={(e) => {
                                        if(e.email_footer){
                                            setValue('content',  e.email_footer)
                                        }
                                        onChange(e);
                                    }}
                                    options={companyEmails}
                                    value={value}
                                    isLoading={isLoadingCompanyEmails}
                                    isDisabled={isLoadingCompanyEmails}
                                    errors={error}
                                />
                            )}
                        />
                        <div className={"flex justify-between before:flex-1"}>
                            <Link to={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION])} className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer">
                                {t("app.provider.add_name")}
                            </Link>
                        </div>
                    </div>

                    <Controller
                        name="recipients"
                        rules={{ required: true }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputSelectInsert
                                label={t("app.provider.email_recipient")}
                                name={name}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                regex={emailRegex}
                                onCreateOption={(e) => {
                                    let dataGroup = defaultEmails.find(r => r.label === 'Customs');
                                    let foundIndex = defaultEmails.findIndex(x => x.label === 'Customs');
                                    if(dataGroup){
                                        defaultEmails[foundIndex]['options'].push(e);
                                    }else{
                                        setDefaultEmails((prev) => [...prev, { label: 'Customs', options: [e]}]);

                                    }
                                    if(watch('recipients')){
                                        setValue('recipients', [...watch('recipients'), e])
                                    }else{
                                        setValue('recipients', [e])
                                    }

                                }}
                                options={defaultEmails}
                                value={value}
                                isMulti={true}
                                isClearable={true}
                                isLoading={isLoadingEmails}
                                isDisabled={isLoadingEmails}
                                errors={error}
                            />
                        )}
                    />


                    {
                        attachments.filter(
                            (c) =>
                                !selectedAttachments
                                    .map((c) => c.id)
                                    .includes(c.id)
                        ).length > 0 ? (
                            <Controller
                                name="attachments"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                             field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={t("app.provider.attachments")}
                                        customOption={ATTACHMENT}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                            setSelectedAttachments((prev) => [...prev, e]);
                                            setValue('attachments', null)
                                        }}
                                        options={attachments.filter(
                                            (c) =>
                                                !selectedAttachments
                                                    .map((c) => c.id)
                                                    .includes(c.id)
                                        )}
                                        value={value}
                                        isLoading={false}
                                        isDisabled={false}
                                        errors={error}
                                    />
                                )}
                            />
                        ) : (
                            <label className="block text-sm font-medium mb-1 text-gray-700 -mb-3">
                                {t("app.provider.attachments")}
                            </label>
                        )
                    }

                    {
                        selectedAttachments.length > 0 ? (
                            <div className=" bg-white  rounded-sm border border-slate-200">
                                <div className="overflow-y-auto max-h-80" aria-label="Directory">

                                    <ul className="relative z-0 divide-y divide-gray-200">
                                        {(selectedAttachments ?? []).map((attachment) => (
                                            <li key={attachment.data.id} className=" px-2 bg-white hover:bg-gray-50">
                                                <div className="py-2 flex items-center justify-between space-x-3">
                                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                        <div className="min-w-0 flex-1">
                                                            <FileCell file={attachment.data}/>
                                                        </div>
                                                    </div>
                                                    <div className="space-x-3 flex md:mt-0 md:ml-4">
                                                        {
                                                            attachment.data.file && (
                                                                <>
                                                                    <div  onClick={() => {
                                                                        setDocument(attachment.data);
                                                                        setOpenReportView(true);
                                                                    }} className="w-full btn bg-indigo-700 hover:bg-indigo-900 cursor-pointer">
                                                                        <DocumentSearchIcon className={'h-4 w-4 text-white'} aria-hidden="true"/>
                                                                    </div>

                                                                </>
                                                            )
                                                        }
                                                        <button
                                                            onClick={() => {
                                                                setSelectedAttachments(selectedAttachments.filter(function( obj ) {
                                                                    return obj.id !== attachment.id;
                                                                }));
                                                            }}
                                                            className={
                                                                "w-full btn bg-red-500 hover:bg-red-700 "
                                                            }
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4 text-white"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    }


                    <InputText
                        id={'subject'}
                        type={'text'}
                        errors={errors.subject}
                        input={{...register("subject", { required: true })}}
                        label={t("app.provider.subject")}
                    />

                    <Controller
                        name="content"
                        rules={{ required: false }}
                        control={control}
                        render={({field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputEmailEditor
                                label={t("app.provider.message")}
                                errors={error}
                                defaultValue={value}
                                className={"h-64"}
                                name={name}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />








                </div>


                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}
            </SlidePanel>


        </>

    );
};

const Footer = ({extras}) => {
    const { t } = useTranslation();

    return (
        <div className="flex-shrink-0 before:flex-1 px-4 py-4 flex justify-between">
            <ButtonSubmit
                isLoading={extras.isSendingMail}
                label={t("app.provider.send_mail")}
                isFullWith={true}
                onClick={() => {
                    extras.onSubmit()
                }}
                disabled={!extras.isActiveSendMail}
            />
        </div>
    );

};






export default EmailSender;
