import React, {Fragment, useState} from "react";
import Initials from "../../common/avatar/initials";
import {Dialog, Disclosure, Transition} from '@headlessui/react'
import {
    ChevronUpIcon, PencilIcon, XIcon
} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import DynamicForm from "../../common/form/dynamic-form";
import {numberFormat} from "../../../utils/converter";
import {getFormattedDate} from "../../../utils/timeUtils";
import {AcquisitionBar} from "../../common/alert/acquisition-bar";


export const GenericHeaderNavigation = ({ data, onOpenSidebar, isSidebarOpen, canEdit}) => {
    const { t } = useTranslation();
        return (
            <>
                <div className="flex w-full items-center justify-between  bg-white py-3 px-6 lg:hidden" >

                    <span className="flex min-w-0 items-center justify-between space-x-1 mt-2 lg:hidden">
                        <Initials
                            color={data?.color ?? ''}
                            name={data?.name ?? ''}
                            iconClass={' h-16 w-16'}
                            isVerified={false}
                        />
                        <span className="flex min-w-0 flex-1 flex-col">
                            <span className="truncate text-sm font-medium text-gray-900">{data?.name ?? ''}</span>
                        </span>
                    </span>

                    <button
                        onClick={() => { onOpenSidebar(!isSidebarOpen) }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {t("app.leads.general_title")}
                    </button>
                </div>
            </>

        )
}
export const GenericPanelNavigation = ({ entity, data, detailGroups, onResponse, onOpenSidebar, isSidebarOpen, onCreate, canEdit, canSendRequest = false}) => {

    return (
        <>
            <Transition.Root show={isSidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-10 lg:hidden"
                    onClose={onOpenSidebar}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs md:max-w-md w-full pt-5 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => onOpenSidebar(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>

                        
                            <SideBar data={data} entity={entity} detailGroups={detailGroups} onResponse={onResponse} isMobile={true} canSendRequest={canSendRequest} onCreate={onCreate} canEdit={canEdit}  />

                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>


            <aside className="relative hidden w-[25rem]	 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                <div className="absolute inset-0 ">
                    <nav>
                        <SideBar data={data} entity={entity} detailGroups={detailGroups} onResponse={onResponse} onCreate={onCreate} canSendRequest={canSendRequest}  canEdit={canEdit} />
                    </nav>
                </div>
            </aside>
        </>


    );
};


const SideBar = ({ data, detailGroups, onResponse, isMobile = false, onCreate, canEdit, canSendRequest= false}) => {

    return (
        <div>


            <div className="flex flex-col">
                <div className="flex w-full items-center justify-between mb-2  py-2 px-6 z-10">
                    <span className="flex min-w-0 items-center justify-between space-x-1 mt-2">
                        <Initials
                            color={data?.color ?? ''}
                            name={data?.name ?? ''}
                            iconClass={' h-16 w-16'}
                            isVerified={false}
                        />
                        <span className="flex min-w-0 flex-1 flex-col">
                            <span className="truncate text-sm font-medium text-gray-900">{data?.name ?? ''}</span>
                        </span>
                    </span>
                </div>
            </div>

            <div className="min-h-0 flex-1 overflow-y-auto ">

                <div className="mx-auto w-full  bg-white ">
                    {
                        detailGroups.map((data, index) => (
                            <LineDetails
                                key={index}
                                endpoint={data.endpoint}
                                defaultOpen={data.defaultOpen}
                                title={data.title}
                                detailList={data.fields}
                                isCreate={data.isCreate}
                                refId={data.refId}
                                onResponse={onResponse}
                                canEdit={canEdit}
                            />
                        ))
                    }
                </div>




            </div>

        </div>
    )
}



const LineDetails = ({defaultOpen = false, title, detailList, endpoint, isCreate, refId, onResponse, canEdit}) => {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);


    return(
        <>

            <Disclosure defaultOpen={defaultOpen} onResponse={onResponse}>
                {({ open }) => (
                    <>


                        <div className="flex w-full justify-between px-4 py-5 text-left text-sm font-bold border-t  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">

                            <div className="flex justify-between items-center ">
                                {
                                    canEdit && (
                                        <PencilIcon
                                            className="h-4 w-4 mr-2 cursor-pointer text-gray-900 hover:text-indigo-800"
                                            onClick={() => {
                                                setIsOpen(true)
                                            }}
                                            aria-hidden="true"
                                        />
                                    )
                                }
                                <span className={'text-gray-900'}>{title}</span>
                            </div>

                            <Disclosure.Button>
                                <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-indigo-800`}
                                />
                            </Disclosure.Button>

                        </div>



                        <Disclosure.Panel className="  text-sm text-gray-500">

                            <div className="divide-y divide-solitude-400 ">
                                {detailList.map((data, key) => (
                                    <div key={key} className={'py-2 px-4 pb-2'}>
                                        <div className="text-xs text-gray-500 capitalize">{t(data.translation)}</div>


                                        {
                                            data.viewType === 'string' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'number' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'currency' && (
                                                <div className="mt-1 text-sm text-gray-900">{numberFormat(data.value)}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'date' && (
                                                <div className="mt-1 text-sm text-gray-900">{getFormattedDate(data.value)}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'percentage' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}%</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'priority' && (
                                                <div className="mt-1 ">
                                                    <AcquisitionBar status={data.value} />
                                                </div>
                                            )
                                        }


                                        {
                                            data.viewType === 'editor' && (
                                                <div className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html:  data.value}} />
                                            )
                                        }


                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>


            <DynamicForm isOpen={isOpen} setIsOpen={setIsOpen} refId={refId} title={title}  fields={detailList} isCreate={isCreate} endpoint={endpoint} onResponse={onResponse}  />

        </>

    )

}
