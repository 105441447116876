import {useTranslation} from "react-i18next";
import React from "react";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6


export default function InputEditor ({ label, isRequired, id, errors, warning, warningRegex, defaultValue, onChange, isDisabled = false}){

    const { t } = useTranslation();

    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <ReactQuill
                theme="snow"
                value={defaultValue ? defaultValue : '<p></p>'}
                modules={InputEditor.modules}
                bounds={'.app'}
                readOnly={isDisabled}
                onChange={(content) => {
                    onChange(content);
                }}
            />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}

        </div>

    )
};

InputEditor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
