import React from "react";

const TableDetail = ({ extraClass = '', children, colSpan = 1, isWrap = true }) => {
    return (
        <td colSpan={colSpan} className={isWrap ? 'px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 ' + extraClass : ('px-6 py-3 text-sm font-medium text-gray-900 ' + extraClass)}>
            {children}
        </td>
    );
};
export default TableDetail;
