import React, {useEffect} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../../utils/converter";
import {COMPANY, EMAIL_TEMPLATES, EMAIL_TEMPLATE} from "../../../../api/endpoints";
import { useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import TableDetail from "../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import { MailIcon } from "@heroicons/react/outline";
import TemplateEmailEditor from "../../../../components/common/form/template-email-editor";
import InputSelect from "../../../../components/common/form/input-select";
import { getTemplateEntities } from "../../../../constants/config";

const EmailTemplate = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_EMAIL_TEMPLATE)]} renderBaseOnFail={true} >
        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.settings.email_template_name"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATES])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATE])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATE, ':id'])}
                        baseResult={{ total_rows: 0, templates: []}}
                        resultLabel={'templates'}
                        title={t("app.settings.email_template_name")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={MailIcon}
                        addFormLabel={t("app.settings.email_template_add")}
                        btnAddLabel={t("app.settings.email_template_add")}
                        editForm={EditForm}
                        editTitle={t("app.configuration.email_template")}
                        isActiveEdit={true}

                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATE, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.title}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>

            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATE, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.title}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data['category'] = data['categories'].value;
        onSubmitData(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    
                    <Controller
                        name="categories"
                        rules={{ required: true }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <InputSelect
                                label={t("app.common.category")}
                                name={name}
                                options={getTemplateEntities(t)}
                                value={value}
                                isRequired={true}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isLoading={false}
                                isDisabled={false}
                                errors={error}
                            />
                        )}
                    /> 
                    
                    <InputText
                        id={"title"}
                        type={"text"}
                        errors={errors.title}
                        input={{ ...register("title", { required: true }) }}
                        label={t("app.settings.email_template_name")}
                    />

                   
                   <div className="col-span-2">
                        <InputText
                            id={"object"}
                            type={"text"}
                            errors={errors.object}
                            input={{ ...register("object", { required: false }) }}
                            label={t("app.settings.email_template_subject")}
                        />
                   </div>

                    <div className="col-span-2">
                        <Controller
                            name="template"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <TemplateEmailEditor
                                    label={t("app.settings.email_template_messagge")}
                                    errors={error}
                                    category={watch('categories')?.value ?? ''}
                                    defaultValue={false}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                    </div>

                   


                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.settings.email_template_add")}
                />
            </div>
        </form>
    );
};

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch
    } = useForm();

    const { t } = useTranslation();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object" && k !== 'category') {
                    setValue(k, getSelectParam(data[k], "name"));
                } else if (k === 'category') {
                    setValue('categories', getTemplateEntities(t).find(r => r.value === data[k]));
                }else{
                    if (k !== 'category') {
                        setValue(k, data[k]);
                    }
                }
                
            }
        }
        // eslint-disable-next-line
    }, [setValue, data]);

    const onSubmit = async (data) => {
        data['category'] = data['categories'].value;
        onSubmitData(data);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <div className="space-y-4 pt-4 pb-5">



                        <Controller
                            name="categories"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <InputSelect
                                    label={t("app.common.category")}
                                    name={name}
                                    options={getTemplateEntities(t)}
                                    value={value}
                                    isRequired={true}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={false}
                                    isDisabled={false}
                                    errors={error}
                                />
                            )}
                        /> 


                        <InputText
                            id={"title"}
                            type={"text"}
                            errors={errors.title}
                            input={{ ...register("title", { required: true }) }}
                            label={t("app.settings.email_template_name")}
                        />


                        <InputText
                            id={"object"}
                            type={"text"}
                            errors={errors.object}
                            input={{ ...register("object", { required: false }) }}
                            label={t("app.settings.email_template_subject")}
                        />

                        {
                            data.template && (
                                <Controller
                                    name="templates"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({ field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <TemplateEmailEditor
                                            label={t("app.provider.email_footer")}
                                            errors={error}
                                            category={watch('categories')?.value ?? ''}
                                            defaultValue={data.template}
                                            name={name}
                                            onChange={(e) => {
                                                setValue('template', e)
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />

                            )
                        }
                      
                       

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>
            </form>

        </>
    );
}

export default EmailTemplate;
