import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "../../../../utils/timeUtils";
import ListFooter from "../../../../components/partials/footer/list-footer";
import { LOGISTICS, SHIPPINGS } from "../../../endpoints";
import { getEvents } from "../../../../api/event";
import IntegrationIcon from "../../../../resources/icons/IntegrationIcon";
import { StatusBar } from "../../../../components/common/alert/status-bar";
import { ChevronRightIcon } from "@heroicons/react/outline";
import EventDetail from "./event-detail";
import AddEvent from "./add-event";
import { useTranslation } from "react-i18next";
import {getPermission, permissionGroup, permissionType} from "../../../../constants/permissions";
import { ActionButton } from "../../../../components/layout/page-header";
import ListSection from "../../../../components/layout/list-section";
import BasePermissionWrapper, {
  hasPermissionFor
} from "../../../../components/partials/restricted/base-permission-wrapper";

const Events = () => {
  const { loggedCompany, companyType } = useSelector((state) => state.user);
  const [isFetchListLoading, setFetchListLoading] = useState(true);
  const [list, setList] = useState({ events: [], total_rows: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const [detail, setDetail] = useState(null);
  const [isOpenDetail, setOpenDetail] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const {  permissions } = useSelector((s) => s.userCompany);


  const canView =  hasPermissionFor(permissions, permissionGroup.EVENT, permissionType.VIEW);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.EVENT, permissionType.CREATE);


  let { id } = useParams();
  const { t } = useTranslation();
  const columns = [
    t("app.event.event"),
    t("app.event.event_status"),
    t("app.event.event_date"),
  ];

  useEffect(() => {
    function fetchList() {
      if (loggedCompany && !isOpen) {
        setFetchListLoading(true);
        getEvents({ shipping_id: id, params: { query: query, company_type: companyType } })
          .then((response) => {
            setList(response);
            setFetchListLoading(false);
            setNeedRefresh(false);
          })
          .catch((e) => {
            setList({ events: [], total_rows: 0 });
            setFetchListLoading(false);
          });
      }
    }

    fetchList();
  }, [query, id, setFetchListLoading, loggedCompany, needRefresh, isOpen, companyType]);

  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.EVENT, permissionType.LIST)]}  renderBaseOnFail={true} redirectWrongCompanyType={true}>
        <ListSection
          title={t("app.event.events")}
          columns={columns}
          totalRows={list.total_rows}
          list={list.events}
          onSearch={setQuery}
          isLoading={isFetchListLoading}
          mobileRow={EventMobRow}
          desktopRow={EventDeskRow}
          extras={{ setDetail, setOpenDetail, canView }}
        >
          {
            canCreate && (
                  <ActionButton
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      icon={
                        <IntegrationIcon
                            className="w-4 h-4 fill-current flex-shrink-0"
                            aria-hidden="true"
                        />
                      }
                      text={t("app.event.add_event")}
                  />
              )
          }

        </ListSection>
      <ListFooter backLink={LOGISTICS + SHIPPINGS} backLabel={t("app.shippings.back")} />

        {isOpen && canCreate && (
          <AddEvent
            isOpen={isOpen}
            setIsOpen={(r) => {
              setIsOpen(r);
            }}
            setDetail={(r) => {
              setDetail(r);
              setOpenDetail(true);
            }}
          />
        )}

        {
          canView && (
              <EventDetail
                  isOpen={isOpenDetail}
                  detail={detail}
                  onNeedRefresh={(r) => {
                    setNeedRefresh(r);
                  }}
                  setIsOpen={(r) => {
                    setOpenDetail(r);
                  }}
              />
            )
        }

      </BasePermissionWrapper>
  );
};

const EventMobRow = ({ element, extras }) => {
  const { t } = useTranslation();

  return (
    <li
      key={element.id}
      className="flex flex-wrap items-center justify-between cursor-pointer gap-4 p-4"
      onClick={() => {
        if(extras.canView){
          extras.setOpenDetail(true);
          extras.setDetail(element);
        }
      }}
    >
      <div>
        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{element.event_type.name}</p>
        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{element?.event_note ?? '-'}</p>
      </div>
      <StatusBar
        status={element.confirmed ? "success" : "processing"}
        title={element.confirmed ? t("app.event.event_confirmed") : t("app.event.event_upcoming")}
      />
      <div>
        {element.event_date ? getFormattedDate(element.event_date) : "-"}
      </div>
      <ChevronRightIcon
        className="flex-shrink-0 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
    </li>
  );
};

const EventDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();

  return (
    <tr
      key={element.id}
      className={extras.canView ? "hover:bg-gray-100/[0.4] cursor-pointer" : "hover:bg-gray-100/[0.4]"}
      onClick={() => {
        if(extras.canView){
          extras.setOpenDetail(true);
          extras.setDetail(element);
        }
      }}
    >
      <td className="max-w-0 w-full px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{element.event_type.name}</p>
        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{element?.event_note ?? '-'}</p>
      </td>
      <td className="text-left px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        <StatusBar
          status={element.confirmed ? "success" : "processing"}
          title={element.confirmed ? t("app.event.event_confirmed") : t("app.event.event_upcoming")}
        />
      </td>
      <td className="text-right px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        {element.event_date ? getFormattedDate(element.event_date) : "-"}
      </td>
    </tr>
  );
};

export default Events;
