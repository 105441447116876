import React, {Fragment, useState} from "react";
import Initials from "../../common/avatar/initials";
import {Dialog, Disclosure, Transition} from '@headlessui/react'
import {
    ChevronUpIcon,
    ClipboardListIcon,
    DocumentTextIcon,
    MailIcon,
    PencilAltIcon, PencilIcon, XIcon
} from "@heroicons/react/outline";
import FileTextIcon from "../../../resources/icons/FileTextIcon";
import {useTranslation} from "react-i18next";
import DynamicForm from "../../common/form/dynamic-form";
import {numberFormat} from "../../../utils/converter";
import {getFormattedDate} from "../../../utils/timeUtils";
import {AcquisitionBar} from "../../common/alert/acquisition-bar";
import TooltipItem from "../../common/button/tooltip";
import {useSelector} from "react-redux";
import {hasPermissionFor} from "../restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific} from "../../../constants/permissions";
import {ConnectionStatus} from "../../common/alert/connection-status-bar";
import {ConnectionRequestNavigation} from "./connection-request-navigation";



export const ConnectionHeaderNavigation = ({connection, onOpenSidebar, isSidebarOpen, canEdit}) => {
    const { t } = useTranslation();
        return (

            <>
                <div className="flex w-full items-center justify-between  bg-white py-3 px-6 lg:hidden" >

                <span className="flex min-w-0 items-center justify-between space-x-1 mt-2 lg:hidden">
                  <Initials
                      color={connection?.color ?? ''}
                      name={connection?.business_name?? ''}
                      iconClass={' h-16 w-16'}
                      isVerified={connection?.status === 'confirmed' ?? false}
                  />
                  <span className="flex min-w-0 flex-1 flex-col">
                    <span className="truncate text-sm font-medium text-gray-900">{connection?.business_name ?? ''}</span>
                    <span className="truncate text-sm text-gray-500">{connection?.contact_first_name ?? ''} {connection?.contact_last_name ?? ''}</span>
                      <span className="-mt-1 flex items-center text-sm text-blue-1000">
                      {
                          connection.status && (
                              <ConnectionStatus
                                  status={connection.status}
                              />
                          )
                      }
                    </span>
                  </span>
                </span>

                    <button
                        onClick={() => {onOpenSidebar(!isSidebarOpen)}}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {t("app.leads.general_title")}
                    </button>
                </div>
            </>

        )
}
export const ConnectionPanelNavigation = ({ connection, isSendMail, detailGroups, onResponse, onOpenSidebar, isSidebarOpen, onCreate, canEdit, canSendRequest = false}) => {

    return (
        <>



            <Transition.Root show={isSidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-10 lg:hidden"
                    onClose={onOpenSidebar}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs md:max-w-md w-full pt-5 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => onOpenSidebar(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>


                            <SideBar isSendMail={isSendMail} connection={connection} detailGroups={detailGroups} onResponse={onResponse} isMobile={true} canSendRequest={canSendRequest} onCreate={onCreate} canEdit={canEdit}  />

                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>


            <aside className="relative hidden w-[25rem]	 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                <div className="absolute inset-0 ">
                    <nav>
                        <SideBar isSendMail={isSendMail} connection={connection} detailGroups={detailGroups} onResponse={onResponse} onCreate={onCreate} canSendRequest={canSendRequest}  canEdit={canEdit} />
                    </nav>
                </div>
            </aside>
        </>


    );
};


const SideBar = ({ connection, detailGroups, onResponse, isMobile = false, onCreate, canEdit, canSendRequest = false, isSendMail = true}) => {

    const { t } = useTranslation();
    const { permissions } = useSelector((s) => s.userCompany);

    const canSendMail = hasPermissionFor(permissions, permissionGroup.LEAD, permissionSpecific.LEAD_SEND_MAIL);
    const canSendMailCustomer = hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionSpecific.CUSTOMER_SEND_MAIL);

    return (
        <div>


            <div className="flex flex-col">

                <div className="flex w-full items-center justify-between mb-2  py-2 px-6 z-10">
                <span className="flex min-w-0 items-center justify-between space-x-1 mt-2">
                  <Initials
                      color={connection?.color ?? ''}
                      name={connection?.business_name?? ''}
                      iconClass={' h-16 w-16'}
                      isVerified={connection?.status === 'confirmed' ?? false}

                  />
                  <span className="flex min-w-0 flex-1 flex-col">
                    <span className="truncate text-sm font-medium text-gray-900">{connection?.business_name ?? ''}</span>
                    <span className="truncate text-sm text-gray-500">{connection?.contact_first_name ?? ''} {connection?.contact_last_name ?? ''}</span>
                    <span className="-mt-1 flex items-center text-sm text-blue-1000">
                      {
                          connection.status && (
                              <ConnectionStatus
                                  status={connection.status}
                              />
                          )
                      }
                    </span>
                  </span>
                </span>

                    {
                        (canSendRequest && canEdit) && (
                            <ConnectionRequestNavigation connection={connection} canEdit={canEdit} onUpdateData={(r) => {onResponse(r)}} />
                        )
                    }
                </div>


                {
                    // eslint-disable-next-line
                    (canEdit || (canSendMail && !canSendRequest || canSendMailCustomer && canSendRequest)) && (
                        <div className={isMobile ? 'flex w-full   items-center  justify-center space-x-2 mb-4 py-2 px-6 overflow-x-scroll' : 'flex w-full   items-center  justify-center mb-4 py-2 px-6'}>
                            <ActionCreate isMobile={isMobile} onCreate={onCreate} items={[
                                canEdit && {id: 1, onCreate: 'note', name: t('app.leads.note'),   description: t('app.leads.note_description'), icon: PencilAltIcon},
                                // eslint-disable-next-line
                                (canSendMail && !canSendRequest || canSendMailCustomer && canSendRequest && isSendMail) && {id: 2, onCreate: 'mail', name: t('app.leads.email'), description: t('app.leads.email_description'),icon: MailIcon},
                                canEdit && {id: 4, onCreate: 'task',  name: t('app.leads.task'),   description: t('app.leads.task_description'),icon: ClipboardListIcon},
                                canEdit && {id: 5, onCreate: 'attach', name: t('app.leads.attach'), description: t('app.leads.attach_description'),icon: FileTextIcon},
                                canEdit && {id: 6, onCreate: 'log',    name: t('app.leads.log'),    description: t('app.leads.log_description'),icon: DocumentTextIcon},
                            ].filter(Boolean)} />
                        </div>
                    )
                }

            </div>

            <div className="min-h-0 flex-1 overflow-y-auto ">

                <div className="mx-auto w-full  bg-white ">
                    {
                        detailGroups.map((data, index) => (
                            <LineDetails
                                key={index}
                                endpoint={data.endpoint}
                                defaultOpen={data.defaultOpen}
                                title={data.title}
                                detailList={data.fields}
                                isCreate={data.isCreate}
                                refId={data.refId}
                                onResponse={onResponse}
                                canEdit={canEdit}
                            />
                        ))
                    }
                </div>




            </div>

        </div>
    )
}


const ActionCreate = ({items, onCreate}) => {


    return(
        <>
            {items.map((item) => (

                <TooltipItem position="bottom" tooltipsText={item.description}>
                    <div className={"flex flex-col items-center  relative group " } onClick={() => {
                        onCreate(item.onCreate)
                    }}>
                        <div data-tooltip-target="tooltip-default" className="flex items-center truncate w-10 h-10 flex cursor-pointer items-center justify-center group bg-solitude-400 hover:bg-gray-300 transition duration-150 rounded-full">
                            <item.icon className="h-5 w-5 text-blue-1000" aria-hidden="true" />
                        </div>
                        <div className="text-xs text-center text-blue-1000/[.8] mt-1">{item.name}</div>
                    </div>
                </TooltipItem>

            ))}
        </>
    )
}


const LineDetails = ({defaultOpen = false, title, detailList, endpoint, isCreate, refId, onResponse, canEdit}) => {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);


    return(
        <>

            <Disclosure defaultOpen={defaultOpen} onResponse={onResponse}>
                {({ open }) => (
                    <>


                        <div className="flex w-full justify-between px-4 py-5 text-left text-sm font-bold border-t  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">

                            <div className="flex justify-between items-center ">
                                {
                                    canEdit && (
                                        <PencilIcon
                                            className="h-4 w-4 mr-2 cursor-pointer text-gray-900 hover:text-indigo-800"
                                            onClick={() => {
                                                setIsOpen(true)
                                            }}
                                            aria-hidden="true"
                                        />
                                    )
                                }
                                <span className={'text-gray-900'}>{title}</span>
                            </div>

                            <Disclosure.Button>
                                <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-indigo-800`}
                                />
                            </Disclosure.Button>

                        </div>



                        <Disclosure.Panel className="  text-sm text-gray-500">

                            <div className="divide-y divide-solitude-400 ">
                                {detailList.map((data, key) => (
                                    <div key={key} className={'py-2 px-4 pb-2'}>
                                        <div className="text-xs text-gray-500 capitalize">{t(data.translation)}</div>


                                        {
                                            data.viewType === 'string' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'number' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'currency' && (
                                                <div className="mt-1 text-sm text-gray-900">{numberFormat(data.value)}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'date' && (
                                                <div className="mt-1 text-sm text-gray-900">{getFormattedDate(data.value)}</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'percentage' && (
                                                <div className="mt-1 text-sm text-gray-900">{data.value}%</div>
                                            )
                                        }

                                        {
                                            data.viewType === 'priority' && (
                                                <div className="mt-1 ">
                                                    <AcquisitionBar status={data.value} />
                                                </div>
                                            )
                                        }


                                        {
                                            data.viewType === 'editor' && (
                                                <div className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html:  data.value}} />
                                            )
                                        }


                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>


            <DynamicForm isOpen={isOpen} setIsOpen={setIsOpen} refId={refId} title={title}  fields={detailList} isCreate={isCreate} endpoint={endpoint} onResponse={onResponse}  />

        </>

    )

}
