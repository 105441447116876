import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import ListSection from "../../../components/layout/list-section";
import {useSelector} from "react-redux";
import {FilterButton, ImportButton} from "../../../components/layout/page-header";
import {ArrowRightIcon, PlusIcon} from "@heroicons/react/outline";
import ProductCell from "../../../components/common/table/product-cell";
import TableDetail from "../../../components/common/list/table-detail";
import {useTranslation} from "react-i18next";
import {getQuotationCatalogProductsDynamic} from "../../../api/quotation";
import NumberFormat from "react-number-format";
import {generateParamsFormLocation} from "../../../constants/dynamic-filter";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import CardDetail from "../../../components/common/list/card-detail";
import { StatusBarIcon } from '../../../components/common/alert/status-bar-icon';
import { PRODUCT, QUOTATION } from '../../endpoints';
import { ALTERNATIVE, CATALOG_PRODUCT } from '../../../api/endpoints';
import { getElement } from '../../../api/config';
import { getPathParam } from '../../../utils/converter';
import FormPopup from '../../../components/common/popup/form-popup';
import ProductCard from '../../../components/common/card/product-card';
import LoaderWrapper from '../../../components/common/loader/loader-wrapper';

const SearchProduct = ({selectedProductList, onSelectedProductList, onChangeStep, isBack, cachedDetail, setCachedDetail }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const { company } = useSelector((state) => state.userCompany);

    const { t } = useTranslation();
    const location = useLocation();

    const columns = [
        t("app.container.product"),
        t("app.products.manufacture_code"),
        t("app.quotations.purchase_price"),
        (company?.product_manage_status ?? false) && t("app.products.status")
    ].filter(Boolean);

    let {id}  = useParams();
    const [isLoading, setLoading] = useState(true);
    const [detail, setDetail] = useState([]);
    const [detailRows, setDetailRows] = useState(0);

    const checkSearch = () => {
        const querySearch = new URLSearchParams(window.location.search).get(
            "querySearch"
        );
        if(querySearch){
            return querySearch;
        }
        return "";
    }


    const [query, setQuery] = useState(checkSearch());
    const [limit] = useState(25);
    const [offset, setOffset] = useState(0);

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isOpenAlternative, setOpenAlternative] = useState(false);
    const [productDetails, setProductDetails] = useState(false);


    const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
    const [dynamicFilters, setDynamicFilters] = useState(false);


    const handleSelectAll = () => {
        if(detail.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(detail);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let product = detail.find(r => r.id === id);
        setIsCheck([...isCheck, product]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };

    const fetchMoreData = () => {
        setOffset(limit + offset);
    };


    const getProductData =  async (prod_id) => {
        try {
            const res = await getElement(getPathParam([QUOTATION, id, CATALOG_PRODUCT, prod_id]));
            setIsCheck([...isCheck, res]);
        } catch (e) {
            
        }
    };


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchContainerProducts() {
            if (loggedCompany) {
                setLoading(true);
                try {
                    const r = await getQuotationCatalogProductsDynamic({
                        quotation_id: id,
                        params: {
                            limit: limit,
                            offset: query.length > 0  ? 0 : offset,
                            query: query
                        },
                        data: activeDynamicFilter,
                        signal,
                    });

                    if(r.filters){
                        setDynamicFilters(r.filters);
                    }

                    if(query.length > 0 || offset === 0){
                        setDetail(r.products);
                        //setCachedDetail(r.products);
                        setDetailRows(r.total_rows);
                    }else{
                        if(r.total_rows > 0 ){
                            setDetail(prevState => [...prevState, ...r.products]);
                            setDetailRows(r.total_rows);
                        }else{
                            setDetail([])
                        }
                    }
                } catch (e) {
                    setDetail([]);
                    setDetailRows(0);
                    if(signal.aborted){
                        setLoading(true);
                    }
                } finally {
                    if(signal.aborted){
                        setLoading(true);
                    }else{
                        setLoading(false);
                    }
                }
            }
        }



        fetchContainerProducts();
        return () => {
            controller.abort();
        };

        // eslint-disable-next-line
    }, [offset, query, loggedCompany, limit, id, activeDynamicFilter]);


    return (
       <>

           <div className="sm:flex sm:items-center">
               <div className="sm:flex-auto">
                   <h1 className="text-xl font-medium text-gray-700">{t("app.quotations.product_title")}</h1>
                   <p className="mt-2 text-sm text-gray-700">
                       {t("app.quotations.product_title_description")}
                   </p>
               </div>

               <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                   {
                       selectedProductList.length > 0  && (
                           <FilterButton
                               onClick={() => {
                                   onChangeStep();
                               }}
                               isRightIcon={true}
                               icon={
                                   <ArrowRightIcon
                                       className="w-4 h-4 ml-2"
                                       aria-hidden="true"
                                   />
                               }
                               text={t("app.common.back")}
                           />
                       )
                   }

               </div>
           </div>



           <ListSection
               title={t("app.products.products")}
               columns={columns}
               totalRows={detailRows}
               list={detail.filter(item => !selectedProductList.some(e => e.id === item.id))}
               onSearch={setQuery}
               isLoading={(offset > 0 && query.length === 0) ? false : isLoading}
               mobileRow={ProductMobRow}
               desktopRow={ProductDeskRow}
               hasCheckbox={true}
               isDynamicSearch={true}
               selectAll={selectAll}
               onInfiniteScroll={fetchMoreData}
               isPanelScroll={true}
               handleSelectAll={handleSelectAll}
               activeDynamicFilter={true}
               extras={{
                   isCheck,
                   selectedProductList,
                   handleClick,
                   onOpenAlternative: (detail, val) => {
                       setProductDetails(detail);
                       setOpenAlternative(val);
                   }
               }}
           >


               <DynamicFilterMenu
                   filters={dynamicFilters}
                   isLoading={isLoading && offset === 0}
                   onChange={(r) => {
                       setDynamicDateFilter(r);
                       setOffset(0);
                       setQuery('');
                   }}
               />

               {
                   isCheck.length > 0 && (
                       <div className="inline-flex items-center justify-center">
                           <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                           <ImportButton
                               onClick={() => {
                                   let newList = isCheck.map(item => {

                                       if(item['pricing']){
                                           //item['pricing']['discount'] = item['pricing']['discount'];
                                       }else{
                                           item['pricing'] = {
                                               discount: [],
                                               purchase_price: undefined,
                                               list_price: undefined,
                                               price_note: undefined,
                                           }
                                       }
                                       return {...item, quote_discounts: []};
                                   });
                                   onSelectedProductList(newList)
                               }}
                               icon={
                                   <PlusIcon
                                       className="w-4 h-4 fill-current flex-shrink-0"
                                       aria-hidden="true"
                                   />
                               }
                           />
                       </div>
                   )
               }


           </ListSection>

            
            <ShowAlternatives 
                detail={productDetails}
                isOpen={isOpenAlternative}
                setIsOpen={(r) => {
                    setOpenAlternative(r);
                }}
                onSelectProduct={(prod) => {
                    getProductData(prod.id);
                }}
            
            />
       </>
    );
};


const ProductMobRow = ({ element, extras }) => {

    const { t } = useTranslation();
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { company } = useSelector((state) => state.userCompany);

    return (
        <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
            <div className="flex-grow">


                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex justify-between items-center"}>
                        <input type="checkbox"
                               id={element.id}
                               onChange={extras.handleClick}
                               checked={isCheck}
                               className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        <ProductCell product={element} shisActiveImage={true} shotTitle={element.has_alternative ? t('app.catalog.view_alternative_product') : false} isActiveManufacture={false} onShowClick={() => {
                            extras.onOpenAlternative(element, true);
                        }} />
                                
                    </div>
                </div>



                <div className="flex flex-col">
                    <CardDetail title={t("app.products.manufacture_code")}>
                        {element.manufacture_reference}
                    </CardDetail>


                  
                    <CardDetail title={t("app.quotations.purchase_price")}>
                        <NumberFormat
                            value={element?.pricing?.purchase_price ?? 0}
                            displayType={"text"}
                            decimalScale={4}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={" €"}
                        />
                    </CardDetail>
                   
                    {
                        company.product_manage_status && (
                            <CardDetail title={t("app.products.status")}>
                                <StatusBarIcon status={element.status} />
                            </CardDetail>
                        )
                    }

                </div>
            </div>
        </li>
    );
};


const ProductDeskRow = ({ element, extras }) => {
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { company } = useSelector((state) => state.userCompany);
    const { t } = useTranslation();


    return (
        
        <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
            <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                       id={element.id}
                       onChange={extras.handleClick}
                       checked={isCheck}
                       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                />
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line"} >
                <div>
                    <ProductCell product={element} shisActiveImage={true} shotTitle={element.has_alternative ? t('app.catalog.view_alternative_product') : false} isActiveManufacture={false} onShowClick={() => {
                        extras.onOpenAlternative(element, true);
                    }}/>
                </div>
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900">
                    {element.manufacture_reference}
                </div>
                <div className="text-xs flex-wrap text-gray-700">
                    {element?.company_manufacture?.name ?? ''}
                </div>
            </TableDetail>

            <TableDetail >
                <NumberFormat
                    value={element?.pricing?.purchase_price ?? 0}
                    displayType={"text"}
                    decimalScale={4}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={" €"}
                />
            </TableDetail>

            {
                company.product_manage_status && (
                    <TableDetail>
                        <StatusBarIcon status={element.status} />
                    </TableDetail>
                )
            }
           
        </tr>
    );
};



const ShowAlternatives = ({ detail, isOpen, setIsOpen, onSelectProduct }) => {

    const [isLoading, setLoading] = useState(true);
    const [alternatives, setAlternatives] = useState({ total_rows: 0, alternative: [] });
    const { t } = useTranslation();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([PRODUCT, detail.id, ALTERNATIVE]), signal, { query: null, offset: 0, limit: 1000 });
                    setAlternatives(res);
                } catch (e) {
                    setAlternatives({ total_rows: 0, alternative: [] });
                } finally {
                    setLoading(false);
                }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [detail]);

    return(
        <FormPopup
            title={t('app.catalog.alternative')}
            isOpen={isOpen}
            isActiveFormPm={false}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >

                <LoaderWrapper isLoading={isLoading}>
                <div className="grid grid-cols-2 gap-6 ">
                    <div className="col-span-2 flex flex-col gap-2">
                        <ul className="divide-y divide-gray-200 overflow-x-scroll h-96">

                            {alternatives.alternative.map((p) => (
                                <ProductCard
                                    key={p.id}
                                    isList={true}
                                    isSelected={false}
                                    product={p.product}
                                    onSelect={(e) => {
                                        e.preventDefault();
                                        onSelectProduct(p.product);
                                        setIsOpen(false);
                                    }}
                                />
                            ))}
                        </ul>

                    </div>
                </div>
                </LoaderWrapper>
          

        </FormPopup>
    );

};

export default SearchProduct;
