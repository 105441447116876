import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {
    getSubscriptionConfirmCheckout, getSubscriptionConfirmOfflineSecret,
    getSubscriptionConfirmPaymentIntent
} from "../../../api/subscription";
import {onboardingDetails} from "../../../reducers/userReducer";
import {getWebPathParam} from "../../../utils/converter";
import {ACCOUNT, SUBSCRIPTION, USER_SUBSCRIPTION} from "../../endpoints";
import {useDispatch, useSelector} from "react-redux";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import {getUserDetails} from "../../../api/user";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import PrimaryButton from "../../../components/common/button/primary-btn";
import blank from "../../../resources/images/blank.png";
import doklessBg from "../../../resources/images/dokless-bg-2.png";

const PaymentComplete = () => {


    const { t } = useTranslation();
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [isSubscriptionLoading, setSubscriptionLoading] = useState(true);
    const { detail, loggedCompany } = useSelector((state) => state.user );

    let {checkout_session_id}  = useParams();
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [isConfirmed, setConfirmed] = useState(false);



    const sleep = ms => {
       return new Promise(resolve => setTimeout(resolve, ms));
    }

    const updateUserDetail = async () => {
        setUpdateLoading(true);
        await sleep(1000);
        getUserDetails()
            .then(response => {
                dispatch(onboardingDetails(response));
                dispatch(subscriptionUsageLimits(loggedCompany.id));
                if(isConfirmed){
                    navigate(getWebPathParam([ACCOUNT, USER_SUBSCRIPTION]))
                }else{
                    navigate(getWebPathParam([ACCOUNT, SUBSCRIPTION]))
                }
                setUpdateLoading(false);
            }).catch(e => {
            setUpdateLoading(false);
        });
    };





    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchSubscriptionsConfirm() {

            if(checkout_session_id && detail){
                setSubscriptionLoading(true);
                try {
                    const subsConfirm = await getSubscriptionConfirmCheckout({session_id: checkout_session_id,signal});
                    if(subsConfirm.is_active_user){
                        setConfirmed(true);
                    }else{
                        setConfirmed(false);
                    }
                } catch (e) {
                    setSubscriptionLoading(false);
                } finally {
                    setSubscriptionLoading(false);
                }
            }
        }


        fetchSubscriptionsConfirm();
        return () => {
            controller.abort();
        };
    }, [checkout_session_id, detail]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchSubscriptionsConfirm() {

            const clientSecret = new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            );

            const paymentIntent = new URLSearchParams(window.location.search).get(
                "payment_intent"
            );

            const confirmStatus = new URLSearchParams(window.location.search).get(
                "offline_secret"
            );

            if(clientSecret && paymentIntent && detail){
                setSubscriptionLoading(true);
                try {
                    const subsConfirm = await getSubscriptionConfirmPaymentIntent({payment_intent_id: paymentIntent,signal});
                    if(subsConfirm.status === 'OK'){
                        setConfirmed(true);
                    }else{
                        setConfirmed(false);
                    }
                } catch (e) {
                    setSubscriptionLoading(false);
                } finally {
                    setSubscriptionLoading(false);
                }
            }

            if(confirmStatus && detail){
                setSubscriptionLoading(true);
                try {
                    const subsConfirm = await getSubscriptionConfirmOfflineSecret({offline_secret_id: confirmStatus,signal});
                    if(subsConfirm.status === 'OK'){
                        setConfirmed(true);
                    }else{
                        setConfirmed(false);
                    }
                } catch (e) {
                    setSubscriptionLoading(false);
                } finally {
                    setSubscriptionLoading(false);
                }
            }


        }


        fetchSubscriptionsConfirm();
        return () => {
            controller.abort();
        };
    }, [detail]);


    return (

        <LoaderWrapper isLoading={isSubscriptionLoading}>


            <div className="h-full flex flex-col before:flex-1 after:flex-1  ">

                <div className="max-w-7xl mx-auto lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                    <div className=" w-full xl:w-1/2 relative pb-12 lg:pb-0 flex items-center">
                        <div className="relative">

                            {
                                isConfirmed ? (
                                        <div className="absolute">
                                            <div className="">
                                                <div className="my-2 flex justify-between items-center">
                                                    <div className="flex justify-between items-center">
                                                        <svg className="w-16 h-16 fill-current" viewBox="0 0 64 64">
                                                            <circle className="text-green-100" cx="32" cy="32" r="32" />
                                                            <path className="text-green-500" d="m28.5 41-8-8 3-3 5 5 12-12 3 3z" />
                                                        </svg>
                                                        <h1 className="ml-3 text-gray-800 font-bold text-4xl"> {t("app.plan.confirm_title")}</h1>
                                                    </div>
                                                </div>
                                                <p className="my-2 text-gray-800">{t("app.plan.confirm_description")}</p>
                                                <PrimaryButton
                                                    label={t('app.common.go_to', {label: ' '+t("app.settings.plan_e_usage")})}
                                                    isLoading={isUpdateLoading}
                                                    onClick={() => {
                                                        updateUserDetail();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                ) : (

                                    <div className="absolute">
                                        <div className="">
                                            <div className="my-2 flex justify-between items-center">
                                                <div className="flex justify-between items-center">
                                                    <svg className="w-16 h-16 fill-current place-content-center" viewBox="0 0 64 64" fill="none" stroke="currentColor">
                                                        <circle className="text-red-100" cx="32" cy="32" r="32"  />
                                                        <path className="text-red-500" transform="translate(8, 8)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" d="M12 36L36 12M12 12l24 24"/>
                                                    </svg>
                                                    <h1 className="ml-3 text-gray-800 font-bold text-4xl"> {t("app.plan.error_title")}</h1>
                                                </div>
                                            </div>


                                            <p className="my-2 text-gray-800">{t("app.plan.error_description")}</p>
                                            <PrimaryButton
                                                label={t('app.common.go_to', {label: ' '+t("app.settings.subscription")})}
                                                isLoading={isUpdateLoading}
                                                onClick={() => {
                                                    updateUserDetail();
                                                }}
                                            />
                                        </div>
                                    </div>

                                )
                            }

                            <div>
                                <img src={blank} alt="" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={doklessBg} alt="" />
                    </div>
                </div>
            </div>
        </LoaderWrapper>
    );
};


export default PaymentComplete;
