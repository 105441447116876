import React, {useState} from "react";
import Files from "react-butterfiles";
import {getSignedRequests} from "../../../api/config";
import {sendMedia} from "../../../utils/apiUtils";
import LoaderIcon from "../../../resources/icons/LoaderIcon";

const SimpleUpload = ({onUpload, onException, isLoading, label, children}) => {

    const [fileLen, setFileLen] = useState(0);

    const handleSuccess = async (files)  => {
        setFileLen(files.length);
        // eslint-disable-next-line
        files.map((file, index) => {


            getSignedRequests({params: {ctype: files[index].src.file.type, ext: files[index].src.file.name.split(".").pop()}})
                .then( signed => {
                    sendMedia(signed.action, signed, file.src.file)
                        .then( r => {
                            let url = signed.action + '/' + signed.key;
                            onUpload({reference: url, media_url: url, detail: files[index]});
                            setFileLen(state => state - 1);
                        }).catch(e => {
                            onException(e.message);
                    });
                }).catch(e => {
                    onException(e.message);
            });
        });
    };


    const handleErrors = async (errors) => {
        if(errors.length > 0 ){
             if((errors[0].file && errors[0].type === 'maxSizeExceeded') || (errors[0].type === 'multipleMaxSizeExceeded')){
                 onException(errors[0].type);
             }
        }
    };

    return (
        <Files
            multiple={false} maxSize="10mb" multipleMaxSize="10mb"
            accept={["image/png","image/jpg","image/jpeg"]}
            onSuccess={handleSuccess}
            onError={handleErrors}
        >
            {({ browseFiles, getDropZoneProps }) => {
                return (
                    <>
                        <div className={'rounded-md bg-white px-3 py-2 text-sm font-semibold text-grey-900 shadow-sm border border-gray-100 cursor-pointer'}  onClick={browseFiles} {...getDropZoneProps()}>
                            {
                                fileLen > 0 || isLoading? (
                                    <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0"/>
                                ) : (
                                    <>
                                        {label}
                                    </>
                                )
                            }
                        </div>
                        <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF, PNG. 1MB max.</p>

                    </>

                );
            }}
        </Files>
    );
};

export default SimpleUpload;
