import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ActionButton} from "../../../components/layout/page-header";
import {InformationCircleIcon, PencilIcon, PlusCircleIcon, PlusIcon, TrashIcon} from "@heroicons/react/outline";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import AddQuotationContainer from "../container/add-quotation-container";
import {getQuotationContainers} from "../../../api/quotation";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getPathParam} from "../../../utils/converter";
import {CONTAINER, QUOTATION} from "../../../api/endpoints";
import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/solid";
import RemovePopup from "../../../components/common/popup/remove-popup";
import QuotationProducts from "./quotation-products";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import SimpleButton from "../../../components/common/button/simple-btn";
import {permissionGroup, permissionType, sp} from "../../../constants/permissions";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import EditQuotationContainer from "../container/edit-quotation-container";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";
import {hasPermission} from "../../../components/partials/restricted/base-permission-wrapper";

const QuotationContainers = ({quotationDetail, setNeedRefresh, setContainerId, importType, setLastImport, refresh, isHideSensitiveData, onConfirmedPackaging}) => {

    const { t } = useTranslation();
    const { loggedCompany, companyType } = useSelector((state) => state.user);
    let { id } = useParams();

    const [isAddContainer, setAddContainer] = useState(false);

    const [isLoadingContainers, setLoadingContainers] = useState(true);
    const [containers, setContainers] = useState({total_rows: 0, quotation_containers: []});

    const isExporter = quotationDetail?.can_edit ?? false;

    const {  permissions } = useSelector((s) => s.userCompany);
    const canEdit =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.EDIT, companyType, true);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchContainers() {
            if (loggedCompany && quotationDetail !== null) {
                setLoadingContainers(true);
                try {
                    const res = await getQuotationContainers({
                        quotation_id: id,
                        params: null,
                        signal,
                    });
                    setContainers(res);
                } catch (e) {
                    setContainers({ quotation_containers: [], total_rows: 0 });
                } finally {
                    setLoadingContainers(false);
                }
            }
        }

        fetchContainers();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, id]);



    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <LoaderWrapper isLoading={isLoadingContainers}>

            {
                (containers?.quotation_containers ?? []).length === 0 ? (
                    <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>


                        <div className="bg-white shadow-sm overflow-hidden mb-5">
                            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">

                                <div className="text-center">
                                    <ContainerIcon
                                        className="h-24 w-24 text-indigo-600 m-auto"
                                        aria-hidden="true"
                                    />
                                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                        {isExporter ? t("app.quotations.container_title") : t("app.quotations.container_title_importer")}
                                    </p>
                                    <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                                        {isExporter ? t("app.quotations.container_description") : t("app.quotations.container_description_importer")}
                                    </p>
                                    {
                                        !quotationDetail.is_closed && isExporter && (
                                            <ActionButton
                                                onClick={() => {
                                                    setAddContainer(true);
                                                }}
                                                icon={
                                                    <PlusIcon
                                                        className="w-4 h-4 fill-current flex-shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                }
                                                disabled={!canEdit}
                                                text={t("app.quotations.add_container")}
                                            />
                                        )
                                    }

                                </div>

                            </div>
                        </div>
                    </SubscriptionWrapper>
                ) : (
                   <>
                       {
                           (containers?.quotation_containers ?? []).map(quotation_container => (
                               <div key={quotation_container.id}>
                                   <ContainerRow
                                       quotationDetail={quotationDetail}
                                       containerDetail={quotation_container}
                                       onUpdate={(r) => {
                                           setContainers(r);
                                       }}
                                       onConfirmedPackaging={(r) => {onConfirmedPackaging(r)}}
                                       setNeedRefresh={(r) => {setNeedRefresh(r)}}
                                       setContainerId={setContainerId}
                                       importType={importType}
                                       setLastImport={setLastImport}
                                       refresh={refresh}
                                       isHideSensitiveData={isHideSensitiveData}
                                   />
                               </div>
                           ))
                       }

                       {
                           (!quotationDetail.is_closed && isExporter && canEdit) && (
                               <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>
                                   <div className={'mt-10 mx-auto w-1/2 '}>
                                       <div
                                           onClick={() => {
                                               setAddContainer(true);
                                           }}
                                           className="flex p-2 justify-center items-center border border-blue-1000 px-4 text-center text-sm font-medium text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer"
                                       >
                                           <div className="flex items-center ">
                                               <div className={' mr-2  text-blue-1000'}>
                                                   <PlusCircleIcon
                                                       className="h-4 w-4"
                                                       aria-hidden="true"
                                                   />
                                               </div>
                                               <span>{t("app.quotations.add_container")}</span>
                                           </div>
                                       </div>
                                   </div>
                               </SubscriptionWrapper>
                           )
                       }


                   </>
                )
            }

            </LoaderWrapper>


            {(isAddContainer && canEdit) && (
                <AddQuotationContainer
                    isOpen={isAddContainer}
                    setIsOpen={() => {
                        setAddContainer(false);
                    }}
                    onSetContainers={(r) => {
                        setContainers(r);
                        setNeedRefresh(true);
                    }}
                />
            )}



        </div>

    )
};

const ContainerRow = ({quotationDetail, containerDetail, onUpdate, setNeedRefresh, setContainerId, importType, setLastImport, refresh, isHideSensitiveData, onConfirmedPackaging}) => {

    const { t } = useTranslation();
    let { id } = useParams();

    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const [isOpenEdit, setOpenEdit] = useState(false);

    const [isOpenContainerDetail, setOpenContainerDetail] = useState(false);

    const {  permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((state) => state.user);

    const canEdit =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.EDIT, companyType, true);

    const isExporter = quotationDetail?.can_edit ?? false;

    return (
        <>
            <Disclosure defaultOpen={true} as={'div'} className={'mt-3 mx-auto w-full  shadow-sm bg-white'}>
                {({ open }) => (
                    <>
                        <div className="flex w-full items-center justify-between border-b bg-white px-4 py-4 text-left text-sm font-medium text-blue-1000">

                            <div className="flex items-center  ">
                                <div className={containerDetail.company_warehouse ? "flex items-center justify-between border-r pr-3" : "flex items-center justify-between "}>
                                    <InformationCircleIcon
                                        className="w-6 h-6  mr-2 cursor-pointer"
                                        onClick={() => {
                                            setOpenContainerDetail(true);
                                        }}
                                        aria-hidden="true"
                                    />
                                    <span>
                                        {containerDetail?.container_type?.name ?? ''}
                                    </span>
                                </div>

                                {
                                    containerDetail.company_warehouse && (
                                        <div className="flex items-center justify-between ml-3">
                                            <WarehouseIcon
                                                className="w-4 h-4  mr-2"
                                            />
                                            <span>
                                             {containerDetail?.company_warehouse?.name ?? ''}
                                            </span>
                                        </div>
                                    )
                                }

                            </div>




                            <div className="flex items-center justify-between">

                                {
                                    (canEdit && !quotationDetail.is_closed && isExporter) && (
                                        <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>
                                            <div className="flex items-center mr-3">
                                                <div className="border-r flex items-center">
                                                    <SimpleButton
                                                        icon={PencilIcon}
                                                        onClick={() => {
                                                            setOpenEdit(true);
                                                        }}
                                                        extraClass={"text-blue-1000 hover:text-blue-700"}
                                                    />
                                                </div>

                                                <div className="pl-3 flex items-center">
                                                    <SimpleButton
                                                        icon={TrashIcon}
                                                        onClick={() => {
                                                            const removeContainerDetail = {
                                                                title: t("app.quotations.remove_container_title"),
                                                                message: t("app.quotations.remove_container_description"),
                                                                endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id]),
                                                            };
                                                            setRemoveDetail(removeContainerDetail);
                                                            setOpenRemove(true);
                                                        }}
                                                        extraClass={"text-blue-1000 hover:text-red-800"}
                                                    />
                                                </div>


                                            </div>
                                        </SubscriptionWrapper>
                                    )
                                }

                                <Disclosure.Button>
                                    <ChevronUpIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-blue-1000`}
                                    />
                                </Disclosure.Button>
                            </div>



                        </div>
                        <Disclosure.Panel className="text-sm text-gray-500">
                            <QuotationProducts
                                quotationDetail={quotationDetail}
                                containerDetail={containerDetail}
                                setNeedRefresh={(r) => {setNeedRefresh(r)}}
                                setContainerId={setContainerId}
                                importType={importType}
                                setLastImport={setLastImport}
                                refresh={refresh}
                                isOpenContainerDetail={isOpenContainerDetail}
                                onOpenContainerDetail={(r) => {setOpenContainerDetail(r)}}
                                onConfirmedPackaging={(r) => {onConfirmedPackaging(r)}}
                                isHideSensitiveData={isHideSensitiveData}
                            />
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        onUpdate(r);
                        setNeedRefresh(true);
                    }}
                />
            )}


            {isOpenEdit && (
                <EditQuotationContainer
                    isOpen={isOpenEdit}
                    setIsOpen={setOpenEdit}
                    containerDetail={containerDetail}
                    onSetContainers={(r) => {
                        onUpdate(r);
                        setNeedRefresh(true);
                    }}
                />
            )}


        </>
    )

}


export default QuotationContainers;
