import React, {useState} from "react";
import {
    CashIcon,
    CalendarIcon,
    PresentationChartBarIcon
} from "@heroicons/react/outline";
import {LeadStatusList} from "../../common/alert/lead-status-list";
import TaskList from "../../dashboard/task-list";
import {useOutletContext} from "react-router-dom";
import {getFormattedDate} from "../../../utils/timeUtils";
import FileCell from "../../common/table/file-cell";
import ReportViewer from "../report/report-viewer";
import moment from "moment";
import {getPathParam, numberFormat} from "../../../utils/converter";
import {AcquisitionBar} from "../../common/alert/acquisition-bar";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {putElement} from "../../../api/config";
import {COMPANY, LEAD} from "../../../api/endpoints";
import {useSelector} from "react-redux";
import Nodata from "../../../resources/images/no-data.gif";
import {hasPermissionFor} from "../restricted/base-permission-wrapper";
import {permissionGroup, permissionType} from "../../../constants/permissions";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LeadDataOverview() {

    const { overviewData, onUpdateData } = useOutletContext();
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit =  hasPermissionFor(permissions, permissionGroup.LEAD, permissionType.EDIT);


    //File
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [reportDetail, setReportDetail] = useState({});
    const { t } = useTranslation();


    const [isLoading, setLoading] = useState(false);

    const cards = [
        { name: t("app.leads.last_contact_date"), icon: CalendarIcon, amount: getFormattedDate(overviewData.last_contact_date)},
        { name: t("app.leads.estimated_value"),  icon: CashIcon, amount: numberFormat(overviewData?.estimated_value ?? 0) },
        { name: t("app.leads.probability_percentage"), icon: PresentationChartBarIcon, amount: (overviewData?.probability_percentage ?? 0)+'%' }
    ];

    const onChangeStatus = async (status) => {
        if (loggedCompany) {
            setLoading(true);
            putElement(getPathParam([COMPANY, loggedCompany.id, LEAD, overviewData.id ]), { id: overviewData.id, lead_status: status })
                .then((response) => {
                    setLoading(false);
                    onUpdateData(response);
                    toast.success(t("app.quotations.update_success"));
                }).catch((e) => {
                setLoading(false);
            });
        }
    };


    return (
        <>

            <ReportViewer
                isOpen={isOpenReportView}
                type={'document'}
                reportDetail={reportDetail?.file ?? false}
                title={reportDetail?.file_name ?? ''}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}
            />


            <div className="max-w-5xl mx-auto mb-6 pt-6 px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center flex-wrap gap-4 mb-6">

                    <div className="flex  items-center ">
                        <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold mr-3">
                            {t("app.leads.lead")} #{overviewData.lead_reference}
                        </h1>
                        <AcquisitionBar status={overviewData.priority} />
                    </div>

                    <LeadStatusList
                        isLoading={isLoading}
                        status={overviewData.lead_status}
                        isEditable={canEdit !== undefined}
                        onChangeStatus={(r) => {
                            onChangeStatus(r)
                        }}
                    />

                </div>
            </div>


            <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">


                <div className="mt-5 grid grid-cols-1 gap-5  xl:grid-cols-3">
                    {/* Card */}
                    {cards.map((card) => (
                        <div key={card.name} className=" flex justify-between  rounded-lg bg-white px-4  py-5 shadow sm:px-6 sm:py-6">
                            <div>
                                <p className="text-sm text-blue-1000/[0.5]">{card.name}</p>
                                <p className="mt-1 text-xl font-semibold text-blue-1000">{card.amount}</p>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <card.icon className="h-6 w-6 text-blue-1000" aria-hidden="true" />
                            </div>
                        </div>
                    ))}
                </div>




                <SectorCard title={t("app.leads.logs")} hasChild={(overviewData?.last_connection_note ?? []).length > 0}>
                    <div className="grid xl:grid-cols-3 gap-4">

                        {
                            (overviewData?.last_connection_note ?? []).map((data) => (
                                <div key={data.id}
                                     className="bg-white p-4 border border-gray-200  rounded-md ">
                                    <div className="grow flex items-center truncate mb-2">
                                        <div className={"w-4 h-4 shrink-0 flex items-center justify-center  rounded-full mr-2  bg-"+ data.color +"-600"} />
                                        <div className="truncate text-xs">
                                            {getFormattedDate(data.note_date)}
                                        </div>
                                    </div>
                                    <div className="text-sm mb-3">{data.note}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </SectorCard>


                <SectorCard title={t("app.leads.attachments")} hasChild={(overviewData?.last_connection_attachment ?? []).length > 0}>
                    <dd className="mt-1 text-sm  text-blue-1000">
                        <ul  className="divide-y divide-gray-200 rounded-md border border-gray-200">
                            {(overviewData?.last_connection_attachment ?? []).map((attachment) => (
                                <li
                                    key={attachment.id}
                                    className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                >
                                    <div className="flex w-0 flex-1 items-center">
                                        <FileCell file={attachment} canView={false} />
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <div onClick={() => {
                                            setOpenReportView(true);
                                            setReportDetail(attachment);
                                        }} className="cursor-pointer font-medium text-blue-1000">
                                            {t("app.document.view_file")}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </dd>
                </SectorCard>

                <SectorCard title={t("app.leads.tasks")} hasChild={(overviewData?.open_tasks ?? []).length > 0}>
                    <div  className="bg-white   border border-gray-200  rounded-sm ">
                        <TaskList data={{list_to_do: overviewData?.open_tasks ?? []}} height={400} />
                    </div>
                </SectorCard>

                <SectorCard title={t("app.leads.logs")} hasChild={(overviewData?.last_connection_log ?? []).length > 0}>
                    <div  className="bg-white   space-y-6">

                        {
                            (overviewData?.last_connection_log ?? []).map((data, index) => (
                                <li key={data.id} className="relative flex gap-x-4 mt-4">
                                    <div
                                        className={classNames(
                                            index === (overviewData?.last_connection_log ?? []).length - 1 ? 'h-6' : '-bottom-6',
                                            'absolute left-0 top-0 flex w-6 justify-center'
                                        )}
                                    >
                                        <div className="w-px bg-gray-200" />
                                    </div>
                                    <>
                                        <div className="relative flex h-6 w-6 flex-none items-center justify-center ">
                                            <div className="h-2 w-2 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                        </div>
                                        <div className="flex-auto rounded-md px-3 ">
                                            <div className="flex justify-between gap-x-4">
                                                <div className="py-0.5 text-xs leading-5 text-gray-500 flex items-center">
                                                    <span className="font-bold text-blue-1000">{data.log_type}</span>
                                                </div>
                                                <div className={'flex items-center'}>
                                                    <time dateTime={data.log_date} className="flex-none py-0.5 text-xs leading-5 text-gray-500">{moment(data.log_date, "YYYY-M-D HH:mm:ss").format("DD MMMM, YYYY")}</time>
                                                </div>

                                            </div>

                                            <p className="text-sm max-w-3xl leading-6 mt-1 text-blue-1000[0.8]">{data.description}</p>
                                        </div>
                                    </>
                                </li>
                            ))
                        }


                    </div>
                </SectorCard>









            </div>

        </>
    );
}


const SectorCard = ({title, children, hasChild}) => {
    const { t } = useTranslation();

    return (
        <section className={'rounded-sm bg-white shadow my-6 pb-10 px-4 t-5 px-6 pt-6'}>
            <div className="mt-2">
                <h2 className="text-blue-1000 text-sm font-semibold uppercase mb-2">{title}</h2>


                {
                    hasChild ? (
                       <> {children}</>
                    ) : (
                        <div className="text-center  rounded-md flex justify-center   h-24">
                            <div className={'self-center'}>
                                <div className="text-center p-10">
                                    <img src={Nodata} className="-mt-8 w-36 mx-auto" alt="not-found"  />
                                    <h1 className=" text-sm -mt-10  text-gray-400 font-bold">
                                        {t("app.common.not_found")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </section>
    )

}
