import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { register as UserRegister, userOnboardingStatus } from "../../reducers/userReducer";
import InputText from "../../components/common/form/input-text";
import {emailRegex, passwordRegex} from "../../constants/regex";
import InputSubmit from "../../components/common/form/input-submit";
import {Warning} from "../../components/common/alert/banner";
import HeaderProfiling from "../../components/layout/header-profiling";
import InputCheckbox from "../../components/common/form/input-checkbox";
import {useTranslation} from "react-i18next";
import {INDEX, LOGIN, VERIFY_REGISTRATION} from "../endpoints";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const Register = () => {
  const { isAuthenticated, onboardingStatus, isRegisterLoading, registerException } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    const [locale] = i18n.language.split("-");
    data['locale'] = locale;
    dispatch(UserRegister(data));
  };

  if(isAuthenticated){
    return (<Navigate to={'/'} />);
  }

  switch (onboardingStatus) {
    case userOnboardingStatus.NEED_VERIFICATION:
      return <Navigate to={VERIFY_REGISTRATION} />;
    case userOnboardingStatus.CONFIRMED:
      return <Navigate to={INDEX} />;
    default:
      return (
          <main className="bg-white">

            <div className="relative md:flex">

              {/* Content */}
              <div className="md:w-1/2">
                <div className="min-h-screen h-full flex flex-col after:flex-1">

                  {/* Header */}
                  <HeaderProfiling />

                  <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
                    <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("oauth.register.title")}</h1>
                    {/* Form */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="space-y-4">

                        <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
                          <InputText
                              id={'firstname'}
                              type={'text'}
                              errors={errors.firstname}
                              input={{...register("firstname", { required: true })}}
                              label={t("app.form.firstname")}
                          />

                          <InputText
                              id={'lastname'}
                              type={'text'}
                              errors={errors.lastname}
                              input={{...register("lastname", { required: true })}}
                              label={t("app.form.lastname")}
                          />
                        </div>

                        <InputText
                            id={'email'}
                            type={'email'}
                            errors={errors.email}
                            input={{...register("email", { required: true, pattern: emailRegex })}}
                            label={t("app.form.email")}
                        />

                        <InputText
                            id={'password'}
                            type={'Password'}
                            errors={errors.password}
                            input={{...register("password", { required: true, pattern: passwordRegex })}}
                            label={t("app.form.password")}
                            warningRegex={t("app.warning.password_regex")}
                        />

                        <InputCheckbox
                            label={t("oauth.register.privacy_checkbox.title")}
                            description={(<a href="https://www.dokless.com/privacy" target="_blank" rel="noreferrer" className="underline">{t("oauth.register.privacy_checkbox.description")}</a> )}
                            id={'privacy'}
                            errors={errors.privacy}
                            input={{...register("privacy", { required: true })}}
                        />

                        <InputCheckbox
                            label={t("oauth.register.terms_checkbox.title")}
                            description={(<a href="https://www.dokless.com/terms-condition" target="_blank" rel="noreferrer" className="underline">{t("oauth.register.terms_checkbox.description")}</a> )}
                            id={'terms'}
                            errors={errors.terms}
                            input={{...register("terms", { required: true })}}
                        />

                        <p className="text-xs mt-2">{t("oauth.register.indication")}</p>
                      </div>

                      {/* Warning */}
                      {registerException && (
                          <Warning
                              color={'red'}
                              message={registerException}
                          />
                      )}

                      <div className="flex items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isRegisterLoading}
                            label={t("oauth.register.name")}
                            isFullWith={true}
                        />
                      </div>


                    </form>
                    {/* Footer */}
                    <div className="pt-5 mt-6 border-t border-gray-200">
                      <div className="text-sm">
                        {t("oauth.login.question")} <Link className="font-medium text-indigo-500 hover:text-indigo-600" to={LOGIN}>{t("oauth.login.name")}</Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* Image */}
              <SidebarProfiling />


            </div>

          </main>
      );
  }
};

export default Register;
