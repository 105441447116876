import React, {Fragment, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const AcquisitionList = ({status, onChangeStatus}) => {
    const { t } = useTranslation();

    const [statusType, setStatusType] = useState(status);


    let statusStyles = {
        low: {
            style: 'border-green-800 text-green-800 hover:bg-green-800 hover:text-white',
            title: t("app.quotations.probability_low")
        },
        medium: {
            style: 'border-yellow-700 text-yellow-700 hover:bg-yellow-700 hover:text-white',
            title: t("app.quotations.probability_medium")
        },
        high: {
            style: 'border-red-800 text-red-800 hover:bg-red-800 hover:text-white',
            title: t("app.quotations.probability_high")
        }
    };


    const getStatus = () => {
        return ['low', 'medium', 'high'];
    }

    return (
        <Menu as="div" className="relative inline-block text-left w-full">
            <div>



                <Menu.Button className={classNames(
                    "btn rounded-0 w-full items-center justify-between",
                    statusStyles[statusType].style,
                )}
                >
                    {statusStyles[statusType].title}
                    <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-left bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">


                    {
                        getStatus().map((item) => (
                            <Menu.Item key={item}>
                                {({ active }) => (
                                    <div
                                        onClick={(() => {
                                            setStatusType(item)
                                            onChangeStatus(item)
                                        })}
                                        className={
                                            classNames(
                                                active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                statusStyles[item].style,
                                                'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                            )}
                                    >
                                        {statusStyles[item].title}
                                    </div>
                                )}
                            </Menu.Item>
                        ))
                    }




                </Menu.Items>
            </Transition>


        </Menu>
    );
};
