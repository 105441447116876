import {useTranslation} from "react-i18next";
import React from "react";

const InputRadioSquare = ({label,description, icon, name, errors, warning, defaultChecked = false, input, value, onCheck = null}) => {

    const { t } = useTranslation();

    return (
        <label className="flex-1 relative block cursor-pointer">
            <input type="radio"
                   name={name}
                   className="peer sr-only"
                   defaultChecked={defaultChecked}
                   onClick={() => {
                       if(onCheck){
                           onCheck(true);
                       }
                   }}
                   value={value}
                   {...input}
            />
            <div className="h-full text-center bg-white px-4 py-6 rounded border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out">
                {icon}
                <div className="font-medium text-gray-800 mb-1">{label}</div>
                <div className="text-sm">{description}</div>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true" />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label.toLowerCase()})}</p>)}
        </label>
    )
};
export default InputRadioSquare;
