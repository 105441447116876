import React, {Fragment, useEffect, useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import InputText from "../../common/form/input-text";
import {Controller, useForm} from "react-hook-form";
import InputDate from "../../common/form/input-date";
import InputSubmit from "../../common/form/input-submit";
import {Warning} from "../../common/alert/banner";
import {
    getCompanyReportType,
    getDynamicReport,
    putDynamic,
    putImport
} from "../../../api/report";
import {useParams} from "react-router-dom";
import ReportViewer from "./report-viewer";
import {getSelectParam, getSelectParams} from "../../../utils/converter";
import FileCard from "../../common/card/file-card";
import {getFormattedDate} from "../../../utils/timeUtils";
import InputSelect from "../../common/form/input-select";
import InputEditor from "../../common/form/input-editor";
import InputRadioSimple from "../../common/form/input-radio-simple";
import InputFormattedNumber from "../../common/form/input-formatted-number";
import AutoSaveSignatureCard from "../upload/autosave-signature-card";
import InputToggle from "../../common/form/input-toggle";
import LoaderWrapper from "../../common/loader/loader-wrapper";
import {useDispatch, useSelector} from "react-redux";
import {fetchLanguages} from "../../../reducers/configReducer";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PDF = 'pdf';
const EXCEL = 'excel';

const SingleReport = ({
   isOpen,
   setIsOpen,
   reportDetails = false,
   isSpecific = true,
   isDynamic = false,
   params = false,
   isActiveForm = true,
}) => {

    const { t } = useTranslation();
    let {id}  = useParams();
    const dispatch = useDispatch();

    const { loggedCompany } = useSelector((state) => state.user);
    const { isLanguagesLoading, languages } = useSelector((state) => state.config);

    // company report Types
    const [isLoadingDetail, setLoadingDetail] = useState(true );
    const [reportType, setReportType] = useState(false);

    // config
    const [documentType, setDocumentType] = useState(PDF);

    // report data
    const [reportData, setReportData] = useState(false);
    const [isUpdateExtraData, setUpdateExtraData] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [reportDetail, setReportDetail] = useState(reportDetails);


    // Counter details
    const [second, setSecond] = useState(1);
    const [startCounter, setStartCounter] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm();


    const generateReport = (data) => {
        setLoading(true);
        setException(false);

        if(isDynamic){
            putDynamic({ report_type: reportDetail.type, entity: reportDetail.entity, entity_id: id, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }

                    setReportData(response);
                    setStartCounter(true);
                    //setOpenReportView(true);
                    //setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }else{
            putImport({ shipping_id: id, report_type: reportDetail.type, data})
                .then((response) => {

                    if(response.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (response.parameters[field.name]) {
                                let value = response.parameters[field.name];
                                if ((value === 'hide_' + field.name) || (response.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(response);
                    setStartCounter(true);
                    //setOpenReportView(true);
                    //setLoading(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }


    };


    useEffect(() => {
        const interval = setInterval( async () => {
            if (startCounter) {
                setSecond(second + 1);
                if (second === 1) {
                    if (loggedCompany && reportData && reportData.status !== 'done') {
                        try {

                            let param = params ? Object.assign({}, params, {file_type: documentType}) : {file_type: documentType};

                            const dynamicReport = await getDynamicReport({
                                entity: reportDetail.entity,
                                entity_id: id,
                                report_type: reportDetail.type,
                                params: param
                            });


                            if(dynamicReport.parameters) {
                                for (const n in reportDetail.fields) {
                                    let field = reportDetail.fields[n];
                                    if (dynamicReport.parameters[field.name]) {
                                        let value = dynamicReport.parameters[field.name];
                                        if ((value === 'hide_' + field.name) || (dynamicReport.id && field.hideIfValidate)) {
                                            delete reportDetail.fields[n];
                                        }
                                    }
                                }
                            }

                            setReportData(dynamicReport);
                            if(dynamicReport.status === 'error'){
                                setException(dynamicReport?.error ?? '');
                                setLoading(false);
                                setStartCounter(false);
                            }
                            if(dynamicReport.status === 'done' && dynamicReport.file){
                                setOpenReportView(true);
                                setLoading(false);
                                setStartCounter(false);
                            }

                            if(dynamicReport.status === 'done' && !dynamicReport.file){
                                setException('error');
                                setStartCounter(false);
                                setLoading(false);
                            }

                        } catch (e) {
                            setException(e.message);
                            setLoading(false);
                            setStartCounter(false);
                        } finally {
                            setSecond(0);
                        }
                    }
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });


    useEffect(() => {

        async function fetchDetails() {
            if (loggedCompany && isOpen) {
                setLoadingDetail(true);
                try {

                    let param = params ? Object.assign({}, params, {file_type: documentType}) : {file_type: documentType};


                    if(reportType === false){
                        const response = await getCompanyReportType({company_id: loggedCompany.id, code: reportDetail.code, params: false });
                        setReportType(response);
                    }

                    const dynamicReport = await getDynamicReport({entity: reportDetail.entity, entity_id: id, report_type: reportDetail.type, params: param });
                    if(dynamicReport.parameters) {
                        for (const n in reportDetail.fields) {
                            let field = reportDetail.fields[n];
                            if (dynamicReport.parameters[field.name]) {
                                let value = dynamicReport.parameters[field.name];

                                if ((value === 'hide_' + field.name) || (dynamicReport.id && field.hideIfValidate)) {
                                    delete reportDetail.fields[n];
                                } else {
                                    if (typeof value === "object") {
                                        setValue(field.name, getSelectParam(value, "name"));
                                    } else {
                                        setValue(field.name, value);
                                    }
                                }
                                setValue('file_lang', languages.find(r => r.code === 'en'))
                            }else{
                                if (dynamicReport.id && field.hideIfValidate) {
                                    delete reportDetail.fields[n];
                                }
                            }
                        }
                    }


                    setReportData(dynamicReport);

                } catch (e) {
                    setReportType(false);
                    setLoadingDetail(false);
                } finally {
                    setLoadingDetail(false);
                }
            }else{
                setReportType(false);
                setLoadingDetail(false);
            }
        }

        fetchDetails();

        dispatch(fetchLanguages());

        // eslint-disable-next-line
    }, [reportDetail, isOpen, loggedCompany, id, setValue, documentType, dispatch]);



    const clean = () =>{
        if(!isSpecific){
            setReportDetail(false);

            if(reportData && reportData.parameters){
                for (const k in reportData.parameters) {
                    setValue(k, null);
                }
            }

            setReportData(false);
            setUpdateExtraData(false);
            setValue("report_type", null);
        }
        setException(false);
    };

    const onSubmit = async (detail) => {

        let data = detail;
        if(params){
            data = {...data, ...params}
        }
        generateReport(data);
    };

    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={reportData?.file ?? false}
                title={reportData?.media_file ?? ''}
                entityId={id}
                type={'report_log'}
                isActiveConfirm={reportDetail?.config?.isConfirmable ?? false}
                reportId={reportData?.id ?? ''}
                isReportConfirmed={reportData?.confirmed ?? false}
                onUpdateData={(r) => {
                    setReportData(r);
                }}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />


            <SlidePanel
                title={isActiveForm ? t("app.report.generate_report") : t("app.report.view_file")}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bg={'bg-white'}
                size={'max-w-2xl'}
                setClean={() => {
                    clean();
                }}
            >

                <div className="space-y-6 pt-6 pb-5">
                    <LoaderWrapper isLoading={isLoadingDetail}>



                        {
                            (reportType && isActiveForm) && (
                                <div>
                                    <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.report_config")}</h1>



                                    {
                                        (reportType.generation_type && reportType.generation_type.split("|").length > 1) && (
                                            <div className="grid grid-cols-2  mb-6 flex space-x-1 bg-solitude-400 p-1 items-center text-center">

                                                {
                                                    reportType.generation_type.split("|").map((data, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => {
                                                                setValue('file_type', data);
                                                                setDocumentType(data);
                                                            }}
                                                            className={
                                                                classNames(
                                                                    'rounded-sm py-1 text-sm font-medium  leading-5 cursor-pointer px-2 uppercase',
                                                                    documentType === data
                                                                        ? 'bg-white shadow text-blue-1000'
                                                                        : 'text-blue-1000 hover:bg-white hover:text-blue-1000'
                                                                )
                                                            }
                                                        >
                                                            {data}
                                                        </div>
                                                    ))
                                                }

                                            </div>

                                        )
                                    }


                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                        {
                                            (reportType.available_language && isActiveForm) && (
                                                <div className={reportType.company_templates.filter(r => r.file_type === documentType).length > 0 ? "col-span-4 md:col-span-2" : "col-span-4"}>
                                                    <Controller
                                                        name="file_lang"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.common.language")}
                                                                name={name}
                                                                options={languages.filter(elemento => (reportType.available_language.split("|")).includes(elemento.code))}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    setValue('lang', e.code);
                                                                }}
                                                                isLoading={isLanguagesLoading}
                                                                isDisabled={isLanguagesLoading}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                        }


                                        {
                                            (reportType.company_templates.filter(r => r.file_type === documentType).length > 0 && isActiveForm) && (
                                                <div className="col-span-4 md:col-span-2">
                                                    <Controller
                                                        name="company_template"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.report.template")}
                                                                name={name}
                                                                options={getSelectParams(reportType.company_templates.filter(r => r.file_type === documentType), 'name')}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={false}
                                                                isDisabled={false}
                                                                isClearable={true}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                        }


                                    </div>








                                    {
                                        documentType === EXCEL && isActiveForm && (reportDetail?.configXlsParams ?? []).map(((field, index) => (
                                            <Fragment key={index}>
                                                <div className="grid grid-cols-1 gap-y-5 gap-x-5 mt-3">
                                                    {
                                                        field.type === 'checkbox' && (
                                                            <Controller
                                                                name={field.name}
                                                                rules={{ required: field.required }}
                                                                control={control}
                                                                render={({
                                                                             field: { onChange, value, name },
                                                                             fieldState: { error },
                                                                         }) => (
                                                                    <InputToggle
                                                                        label={t(field.label)}
                                                                        description={t(field.description)}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                        }}
                                                                        defaultChecked={false}
                                                                        errors={error}
                                                                    />
                                                                )}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </Fragment>
                                        )))
                                    }
                                </div>

                            )
                        }




                        {
                            reportData.file && (
                                <div>
                                    <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.generated_report")}</h1>
                                    <FileCard
                                        file={reportData.file}
                                        name={reportData.media_file}
                                        information={getFormattedDate(reportData.upload_date)}
                                        isReportConfirmed={reportData?.confirmed ?? false}
                                        onClick={() => {
                                            setOpenReportView(true);
                                        }}
                                    />
                                </div>
                            )
                        }


                        {
                            isActiveForm && (
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">

                                        <div className="col-span-4">
                                            <h1 className="font-medium text-gray-900 border-b mb-3 border-dashed">{t("app.report.report_information")}</h1>
                                        </div>

                                        {
                                            reportDetail.fields.map(((field, index) => (
                                                <Fragment key={index}>


                                                    {
                                                        field.type === 'string' && (
                                                            <div className="col-span-4 md:col-span-2">
                                                                <InputText
                                                                    id={field.name}
                                                                    type={"text"}
                                                                    errors={errors[field.name]}
                                                                    input={{ ...register(field.name, { required: field.required }) }}
                                                                    label={t(field.translation)}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        field.type === 'date' && (
                                                            <div className="col-span-4 md:col-span-2">
                                                                <Controller
                                                                    name={field.name}
                                                                    rules={{ required: field.required }}
                                                                    control={control}
                                                                    render={({field: { onChange, value, name }, fieldState: { error },}) => (
                                                                        <InputDate
                                                                            label={t(field.translation)}
                                                                            format={"YYYY-MM-DD HH:mm:ss"}
                                                                            placeholder={"Es. 01/12/2021"}
                                                                            activeMinDate={false}
                                                                            startDate={value}
                                                                            disabled={false}
                                                                            isShowTime={field?.config?.isActiveTime ?? false}
                                                                            errors={error}
                                                                            name={name}
                                                                            onChange={(e) => {
                                                                                onChange(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )
                                                    }


                                                    {
                                                        field.type === 'editor' && (
                                                            <div className="col-span-4">
                                                                <Controller
                                                                    name={field.name}
                                                                    rules={{ required: field.required }}
                                                                    control={control}
                                                                    render={({field: { onChange, value, name },
                                                                                 fieldState: { error },
                                                                             }) => (
                                                                        <InputEditor
                                                                            label={t(field.translation)}
                                                                            errors={error}
                                                                            defaultValue={value}
                                                                            name={name}
                                                                            onChange={(e) => {
                                                                                onChange(e);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        field.type === 'radio-btn' && (
                                                            <div className="col-span-4 md:col-span-2">
                                                                <InputRadioSimple
                                                                    name={field.group}
                                                                    errors={errors.default_type}
                                                                    input={{...register(field.group, { required: field.required })}}
                                                                    label={t(field.translation)}
                                                                    description={t(field.description)}
                                                                    value={field.name}
                                                                />
                                                            </div>

                                                        )
                                                    }


                                                    {
                                                        field.type === 'number' && (
                                                            <div className="col-span-4 md:col-span-2">
                                                                <Controller
                                                                    name={field.name}
                                                                    rules={{ required: field.required }}
                                                                    control={control}
                                                                    render={({
                                                                                 field: { onChange, value, name },
                                                                                 fieldState: { error },
                                                                             }) => (
                                                                        <InputFormattedNumber
                                                                            label={t(field.translation)}
                                                                            name={name}
                                                                            value={value}
                                                                            onChange={(e) => {
                                                                                onChange(e);
                                                                            }}
                                                                            prefix={field?.config?.prefix ?? ''}
                                                                            suffix={field?.config?.suffix ?? ''}
                                                                            errors={error}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>

                                                        )
                                                    }

                                                    {
                                                        field.type === 'signature' && (
                                                            <div className="col-span-4 md:col-span-2">
                                                                <AutoSaveSignatureCard
                                                                    onUpdateData={(r) => {
                                                                        setUpdateExtraData(r);
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                </Fragment>
                                            )))
                                        }

                                    </div>


                                    {/* Warning*/}
                                    {exception && (
                                        <Warning message={exception} />
                                    )}

                                    <div className="flex before:flex-1 items-center justify-between mt-6">
                                        <InputSubmit
                                            isLoading={isLoading}
                                            label={t("app.report.generate_file")}
                                            disabled={isUpdateExtraData}
                                            isFullWith={true}
                                        />
                                    </div>


                                </form>
                            )
                        }

                    </LoaderWrapper>

                </div>
            </SlidePanel>


        </>

    );
};



export default SingleReport;
