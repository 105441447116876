import React from "react";

const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
    blocked: 'bg-red-100 text-red-800',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const StatusBar = ({status, title, padding = "px-2.5 py-0.5"}) => (
    <span
        className={classNames(
            statusStyles[status],
            padding,
            'inline-flex items-center rounded-full text-xs font-medium'
        )}
    >
        {title}
    </span>
);
