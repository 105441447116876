import React from "react";
import { Link } from "react-router-dom";
import {ACCOUNT, USER_SUBSCRIPTION} from "../../../routes/endpoints";
import {getWebPathParam} from "../../../utils/converter";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const UsageRestricted = () => {

  const { permissions } = useSelector((s) => s.userCompany);
  const { t } = useTranslation();

  return (
    <div className="py-4 h-full flex flex-col before:flex-1 after:flex-1">
      <div className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-16 bg-white overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                {t("app.plan.usage_limit_title")}
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                {t("app.plan.usage_limit_description")}
              </p>
            </div>
            {
              permissions?.isSuperAdmin && (
                    <div className="mt-8 flex justify-center">
                      <div className="ml-3 inline-flex">
                        <Link
                            className="btn bg-indigo-800 hover:bg-indigo-900 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                            to={getWebPathParam([ACCOUNT, USER_SUBSCRIPTION])}
                        >
                          {t('app.common.go_to', {label: ' '+t("app.settings.plan_e_usage")})}
                        </Link>
                      </div>
                    </div>
                )
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageRestricted;
