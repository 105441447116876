import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {BellIcon} from "@heroicons/react/outline/esm";
import {getNotifications, postNotificationRead} from "../../../api/user";
import Loader from "../../common/loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {InfiniteScroller} from "../common/infinite-scroller";
import EmptyState from "../../common/states/empty-state";
import noNotifications from "../../../resources/illustration/emptystate_notifications.svg";
import InitialsStates from "../../common/states/initials-states";


const DEFAULT_NOTIFICATION = 'default';
const ARCHIVED_NOTIFICATION = 'archived';

export default function Notification() {

    const {notificationCounter} = useSelector((state) => state.config);
    const { loggedCompany } = useSelector((state) => state.user);

    const [open, setOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(notificationCounter);
    const [notificationType, setNotificationType] = useState(DEFAULT_NOTIFICATION);
    const [limit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [offsetArchived, setOffsetArchived] = useState(0);
    const [detail, setDetail] = useState([]);
    const [detailArchived, setDetailArchived] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [isLoadingArchived, setLoadingArchived] = useState(true);


    const [hasMore, setHasMore] = useState(true);
    const [hasMoreArchived, setHasMoreArchived] = useState(true);

    const { t } = useTranslation();


    const dispatch = useDispatch();


    const fetchNotificationsLists = (offsetNumber = null) => {
        if(open && hasMore){
            setLoading(true);
            getNotifications({params: {type: DEFAULT_NOTIFICATION, limit: limit, offset: offsetNumber !== null ? offsetNumber : offset }})
                .then((response) => {
                    if(response.notifications.length > 0 ){
                        setDetail(prevState => [...prevState, ...response.notifications]);
                    }else {
                        setHasMore(false);
                    }
                    setLoading(false);
                    postNotificationRead();
                    setNotificationCount(0);
                }).catch((e) => {
                    setDetail([]);
            });

        }
    }

    const fetchNotificationArchivedLists = (offsetNumber = null) => {
        if(open && hasMoreArchived){
            setLoadingArchived(true);
            getNotifications({params: {type: ARCHIVED_NOTIFICATION, limit: limit, offset: offsetNumber !== null ? offsetNumber : offsetArchived}})
                .then((response) => {
                    if(response.notifications.length > 0 ){
                        setDetailArchived(prevState => [...prevState, ...response.notifications]);
                    }else{
                        setHasMoreArchived(false);
                    }
                    setLoadingArchived(false);
                }).catch((e) => {
                    setDetailArchived([]);
            });
        }
    }


    useEffect(() => {
        fetchNotificationArchivedLists();
        // eslint-disable-next-line
    }, [offsetArchived]);


    useEffect(() => {
        fetchNotificationsLists();
        // eslint-disable-next-line
    }, [offset]);

    useEffect(() => {

        if(open){
            setLoadingArchived(true);
            setOffsetArchived(0);
            setDetailArchived([]);
            setHasMoreArchived(true);
            fetchNotificationArchivedLists(0);
        }

        // eslint-disable-next-line
    }, [open, dispatch, loggedCompany]);


    useEffect(() => {

        if(open){
            setLoading(true);
            setOffset(0);
            setDetail([]);
            setHasMore(true);
            fetchNotificationsLists(0);
        }
        // eslint-disable-next-line
    }, [open, dispatch, loggedCompany]);


    return (
        <>
            <div className="ml-3 relative">
                <div onClick={() => {setOpen(true)}} className="max-w-xs bg-white flex items-center text-sm rounded-full ">
                    <span className="sr-only">Notifications</span>
                    <div className="flex items-center truncate w-8 h-8 flex cursor-pointer items-center justify-center group bg-solitude-400 hover:bg-gray-300 transition duration-150 rounded-full mr-1">
                            <BellIcon className="h-4 w-4 text-blue-1000 " aria-hidden="true" />
                            {
                                notificationCount > 0 && (
                                    <span className="absolute top-0 right-0 inline-flex mr-1 items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                        {notificationCount >= 9 ? '9+' : notificationCount}
                                    </span>
                                )
                            }
                    </div>
                </div>
            </div>


            <NotificationPanel open={open} setOpen={() => {
                setOpen(false);
            }}>


                <NotificationType notificationType={notificationType} setNotificationType={setNotificationType} />
                {
                    (( notificationType === DEFAULT_NOTIFICATION &&  ((detail ?? []).length === 0) && !isLoading) || ( notificationType === ARCHIVED_NOTIFICATION &&  ((detailArchived ?? []).length === 0) && !isLoadingArchived)) && (
                        <EmptyState image={noNotifications} title={t("app.no_data.notification_title")} description={t("app.no_data.notification_description")} />
                    )
                }
                {
                    notificationType === DEFAULT_NOTIFICATION && (
                        <ul  className="flex-1 divide-y divide-gray-200 overflow-y-auto ">
                            <InfiniteScroller
                                fetchNextPage={() => {
                                    setOffset(prevOffset => prevOffset + limit);
                                }}
                                isLoading={isLoading}
                                loadingMessage={<div className={'mt-3'}><Loader /></div>}
                                className=""
                            >
                                {
                                    (detail ?? []).map((data) => (
                                        <li key={data.id} className="relative bg-white py-5 px-4 hover:bg-gray-50">

                                            <div className="flex items-center">
                                                {
                                                    data.group_type && (
                                                        <InitialsStates
                                                            type={data.group_type}
                                                        />
                                                    )
                                                }


                                                <div className={'w-full'}>
                                                    <div className="flex justify-between space-x-3">
                                                        <div className="min-w-0 flex-1">
                                                            <a href={data.call_to_action} target={'_blank'} rel="noreferrer" onClick={() => setOpen(false)} className="block focus:outline-none">
                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                <p className="text-sm truncate text-gray-900">{data.title}</p>
                                                            </a>
                                                        </div>
                                                        <div className="flex-shrink-0 whitespace-nowrap text-xs text-gray-700">
                                                            {data.elapsed_time}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <p className="line-clamp-2 text-xs  text-gray-500">{data.text}</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </li>

                                    ))
                                }
                            </InfiniteScroller>
                        </ul>
                    )
                }

                {
                    notificationType === ARCHIVED_NOTIFICATION && (
                        <ul  className="flex-1 divide-y divide-gray-200 overflow-y-auto ">
                            <InfiniteScroller
                                fetchNextPage={() => {
                                    setOffsetArchived(prevOffset => prevOffset + limit);
                                }}
                                isLoading={isLoadingArchived}
                                loadingMessage={<div className={'mt-3'}><Loader /></div>}
                                className=""
                            >
                                {
                                    (detailArchived ?? []).map((data) => (
                                        <li key={data.id} className="relative bg-white py-5 px-4 hover:bg-gray-50">

                                            <div className="flex items-center">
                                                {
                                                    data.group_type && (
                                                        <InitialsStates
                                                            type={data.group_type}
                                                        />
                                                    )
                                                }


                                                <div className={'w-full'}>
                                                    <div className="flex justify-between space-x-3">
                                                        <div className="min-w-0 flex-1">
                                                            <a href={data.call_to_action} target={'_blank'} rel="noreferrer" onClick={() => setOpen(false)} className="block focus:outline-none">
                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                <p className="text-sm truncate text-gray-900">{data.title}</p>
                                                            </a>
                                                        </div>
                                                        <div className="flex-shrink-0 whitespace-nowrap text-xs text-gray-700">
                                                            {data.elapsed_time}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <p className="line-clamp-2 text-xs  text-gray-500">{data.text}</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </li>

                                    ))
                                }
                            </InfiniteScroller>
                        </ul>
                    )
                }




            </NotificationPanel>


        </>
    )
}

const NotificationType = ({notificationType, setNotificationType}) => {

    const { t } = useTranslation();

    return (
        <div className="flex justify-center  ">
            <div className="relative flex w-full p-1 bg-solitude-400 rounded">
                <span className="absolute inset-0  pointer-events-none" aria-hidden="true">
                  <span className={`absolute inset-0 w-1/2 bg-blue-1000 transform transition duration-150 ease-in-out ${
                          notificationType === DEFAULT_NOTIFICATION
                              ? "translate-x-0"
                              : "translate-x-full"
                      }`}
                  />
                </span>
                <button className={`relative ${
                        notificationType === DEFAULT_NOTIFICATION ? "text-white " : "text-blue-1000"
                    } flex-1 text-sm font-medium p-1 duration-150 ease-in-out`} onClick={(e) => {
                        e.preventDefault();
                        setNotificationType(DEFAULT_NOTIFICATION);
                    }}
                >
                    {t("app.common.notification_default")}
                </button>
                <button
                    className={`relative ${
                        notificationType === ARCHIVED_NOTIFICATION ? "text-white " : "text-blue-1000"
                    } flex-1 text-sm font-medium p-1 duration-150 ease-in-out`}
                    onClick={(e) => {
                        e.preventDefault();
                        setNotificationType(ARCHIVED_NOTIFICATION);
                    }}
                >
                    {t("app.common.notification_archived")}
                </button>
            </div>
        </div>
    )
}


const NotificationPanel = ({open, setOpen, children}) => {

    const { t } = useTranslation();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="p-4">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-gray-900"> {t("app.common.notification_center")}</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-200" />


                                    {children}


                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


