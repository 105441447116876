import React, {useEffect, useState} from "react";
import {useParams, Outlet, useNavigate} from "react-router-dom";
import { useSelector} from "react-redux";
import LoaderWrapper from "../../../../../components/common/loader/loader-wrapper";
import {getContainer} from "../../../../../api/container";
import {
    CONTAINER_GALLERY,
    CONTAINER_GENERAL_INFORMATION,
    CONTAINER_LOAD_CONFIGURATION, LOGISTICS, P404, SHIPPING, SHIPPING_OVERVIEW,
} from "../../../../endpoints";
import {useTranslation} from "react-i18next";
import {hasSubscriptionPermission} from "../../../../../components/partials/restricted/subscription-wrapper";
import {permissionGroup, permissionSpecific, permissionType, sp} from "../../../../../constants/permissions";
import {COMPANY_EXPORTER, COMPANY_IMPORTER, SEA} from "../../../../../constants/config";
import {hasPermission, hasPermissionFor} from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getWebPathParam} from "../../../../../utils/converter";

const ContainerEdit = () => {

    let {id, container_id}  = useParams();
    const { t } = useTranslation();
    let navigate = useNavigate();

    const { loggedCompany, isExporter, companyType } = useSelector((state) => state.user);
    const { subscriptions, permissions } = useSelector((s) => s.userCompany);
    let isPackingListActive = hasSubscriptionPermission(subscriptions, sp.PACKING_LIST_MARKETPLACE_PACKAGE);

    const canView =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.VIEW);
    const canEdit =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.EDIT);
    const requireProductList =  hasPermission(permissions, permissionGroup.PRODUCT, permissionType.LIST, companyType, true);
    const isActivePackingPermission =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_LOAD_CONFIGURATION, companyType, true);

    const tabsNavigation = [
        canView && { name: t("app.container.general-information"),  group: CONTAINER_GENERAL_INFORMATION },
        canView && { name: t("app.container.gallery"), group: CONTAINER_GALLERY },
        (isExporter && isPackingListActive && canEdit && isActivePackingPermission && requireProductList) && { name: t("app.container.load_configuration"), group: CONTAINER_LOAD_CONFIGURATION }
    ].filter(Boolean);


    // get container
    const [isLoading, setLoading] = useState(true);
    const [forceReloadDetails, setForceReloadDetails] = useState(null);
    const [containerDetail, setContainerDetail] = useState(null);


    useEffect( () => {

        const controller = new AbortController();
        const signal = controller.signal;

        function fetchContainer(){
            if(loggedCompany){
                if(!forceReloadDetails){
                    setLoading(true);
                }
                getContainer({shipping_id: id, container_id: container_id, signal: !forceReloadDetails ? signal : false})
                    .then(response => {

                        if(isExporter && response.creator_type === COMPANY_IMPORTER  && !response.can_edit){
                            navigate(getWebPathParam([LOGISTICS, SHIPPING, id, SHIPPING_OVERVIEW]));
                        }

                        if(!isExporter && response.creator_type === COMPANY_EXPORTER  && !response.can_edit){
                            navigate(getWebPathParam([LOGISTICS, SHIPPING, id, SHIPPING_OVERVIEW]));
                        }



                        setForceReloadDetails(false);
                        setContainerDetail(response);
                        setLoading(false);

                    }).catch(e => {
                        e.code === 404 && navigate(P404);
                        setLoading(false);
                });
            }
        }
        fetchContainer();
        return () => {controller.abort();};

        // eslint-disable-next-line
    }, [id, loggedCompany, container_id, companyType, forceReloadDetails]);


    return (

        <LoaderWrapper isLoading={isLoading}>
            <div className="py-6">
                <Outlet context={{
                    isSeaType: containerDetail?.shipping_type === SEA ?? false,
                    containerDetail, tabsNavigation, setContainerDetail, setForceReloadDetails}}  />
            </div>
        </LoaderWrapper>
    );
};

export default ContainerEdit;
