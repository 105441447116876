import React, {useEffect, useState} from 'react'
import ListSection from "../../../components/layout/list-section";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import ProductCell from "../../../components/common/table/product-cell";
import TableDetail from "../../../components/common/list/table-detail";
import {ArrowLeftIcon, PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {
    FilterButton,
    TrashButton
} from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../components/common/form/button-submit";
import NumberFormat from "react-number-format";
import PriceHistory from "../../../components/common/card/price-history";
import CardDetail from "../../../components/common/list/card-detail";
import PurchaseHistory from "../../../components/common/card/purchase-history";
import {permissionGroup, permissionSpecific, permissionType} from "../../../constants/permissions";
import InputDiscountNumber from "../../../components/common/form/input-discount-number";
import {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {useSelector} from "react-redux";



const ProductConfiguration = ({
  onChangeStep,
  selectedProductList,
  onDelete,
  onSetQuantity,
  onChangeValue,
  onAddDiscount,
  onUpdateDiscount,
  quotationDetail,
  onUpdateProduct,
  onUpdateDiscountRate,
  onAddDiscountRate,
  onSave,
  isLoading,
  isHideSensitiveData
}) => {

    const { t } = useTranslation();
    const columns = [
        t("app.quotations.product"),
        t("app.quotations.quantity"),
        t("app.quotations.price_discount"),
        t("app.quotations.net_price"),
        "",
    ];

    const {  permissions } = useSelector((s) => s.userCompany);


    const isActiveProductPrice =  hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.VIEW);
    const isActivePurchaseHistory = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_PURCHASE_HISTORY);

    const isActiveSellingHistory = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_SELLING_HISTORY);



    return (
        <>
            <div className="sm:flex sm:items-center flex justify-between items-center">
                <div className=" flex justify-between items-center">

                    <FilterButton
                        onClick={() => {
                            onChangeStep();
                        }}
                        icon={
                            <ArrowLeftIcon
                                className="w-4 h-4"
                                aria-hidden="true"
                            />
                        }
                        text={t("app.common.back")}
                    />

                    <h1 className="text-xl font-medium text-gray-700 ml-3">{t("app.quotations.selected_product_title")}</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">


                    <div className="ml-2">
                        <ButtonSubmit
                            onClick={() => {
                                onSave();
                            }}
                            isFullWith={true}
                            isLoading={isLoading}
                            label={t("app.quotations.add_products")}
                        />
                    </div>
                </div>
            </div>


            <ListSection
                columns={columns}
                totalRows={selectedProductList.length}
                list={selectedProductList}
                showHeader={false}
                isLoading={false}
                mobileRow={MobRow}
                desktopRow={DeskRow}
                extras={{
                    isHideSensitiveData,
                    quotationDetail,
                    selectedProductList,
                    onDeleteProduct: (id) => {
                        onDelete(id)
                    },
                    onSetQuantity: (id, item, value) => {
                        onSetQuantity(id, item, value)
                    },
                    onChangeValue: (id, value, type, p  = false) =>{
                        onChangeValue(id, value, type, p);
                    },
                    onUpdateDiscount: (id, value, index) =>{
                        onUpdateDiscount(id, value, index);
                    },
                    onAddDiscount: (id) =>{
                        onAddDiscount(id);
                    },
                    onUpdateDiscountRate: (id, value, index) =>{
                        onUpdateDiscountRate(id, value, index);
                    },
                    onAddDiscountRate: (id) =>{
                        onAddDiscountRate(id);
                    },
                    onUpdateProduct: (r) => {
                        onUpdateProduct(r);
                    },
                    isActiveProductPrice,
                    isActivePurchaseHistory,
                    isActiveSellingHistory
                }}
            />

        </>
    );
};



const MobRow = ({ element, extras }) => {

    const { t } = useTranslation();


    const {
        watch,
        control,
        setValue,
        clearErrors, setError,
    } = useForm({ mode: "onBlur" });

    const [productDetail, setProductDetail] = useState(element);



    useEffect(() => {

        if(productDetail.quote_base_price && productDetail.quote_base_price > 0){
            setValue('quote_base_price', productDetail.quote_base_price)
        }

        if(productDetail.quote_quantity && productDetail.quote_quantity > 0){
            setValue('quote_quantity', productDetail.quote_quantity)
        }

        if(productDetail.pricing){
            setValue('purchase_price', productDetail.pricing?.purchase_price);
            setValue('list_price', productDetail?.pricing?.list_price);
        }

        // eslint-disable-next-line
    }, [setValue, productDetail])

    useEffect(() => {
        setProductDetail(element);
        // eslint-disable-next-line
    }, [element]);




    const validateQuantity = (quantity = null, qty_per_packaging = productDetail?.default_dimension?.qty_per_packaging ?? 0) => {

        let closedBoxes = qty_per_packaging;
        if(closedBoxes > 0){

            let desiredQuantity = quantity;
            if(!desiredQuantity){
                desiredQuantity = watch('quote_quantity');
            }

            if(closedBoxes === desiredQuantity){
                clearErrors('quote_quantity')
            }

            if(isNaN(desiredQuantity)){
                clearErrors('quote_quantity')
            }else{
                if (desiredQuantity === 0) {
                    setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                }else{
                    if(closedBoxes > desiredQuantity){
                        setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                    }else{
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if((closedBoxes * suggestedQuantityPerBox) === desiredQuantity){
                            clearErrors('quote_quantity')
                        }else{
                            setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+(closedBoxes * suggestedQuantityPerBox)});
                        }
                    }
                }
            }



        }else {
            clearErrors('quote_quantity')
        }
    }

    return (
        <li key={element.id} className={'flex items-center gap-4 p-4'}>

            <div className="flex-grow">

                <div className={"flex items-center"}>
                    <ProductCell product={productDetail} />
                </div>


                <div className="flex flex-col mt-3">


                    <div className="mt-3 mb-3 py-1">


                        <div className="flex items-center">
                            {
                                productDetail.id && extras.isActiveProductPrice && extras.isActivePurchaseHistory && (
                                    <div className={'mr-1'}>
                                        <PurchaseHistory product={productDetail}  />
                                    </div>
                                )
                            }
                            <label  className="block text-sm font-medium text-gray-700">
                                {t("app.quotations.purchase_price")}
                            </label>
                        </div>

                        <Controller
                            name="list_price"
                            rules={{ required: false }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputDiscountNumber
                                    label={t("app.quotations.purchase_price")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    decimalScale={4}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(productDetail.id, e, 'list_price', true);
                                    }}
                                    onChangeValues={(e) => {
                                        extras.onChangeValue(productDetail.id, e.discount_string, 'discount_string', true);
                                        extras.onChangeValue(productDetail.id, e.discount, 'discount', true);
                                        extras.onChangeValue(productDetail.id, e.final_price, 'purchase_price', true);
                                        extras.onChangeValue(productDetail.id, true, 'edited_rate');
                                        setValue('purchase_price', e.final_price);
                                    }}
                                    suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    errors={error}
                                    isShowFinalPrice={true}
                                    discountList={(productDetail?.pricing?.discount ?? [])}
                                />
                            )}
                        />

                    </div>


                    <div className="mt-3 mb-3 py-1">
                        <div className="flex items-center">
                            {
                                (productDetail.last_prices && extras.isActiveSellingHistory) && (
                                    <div className="mr-3">
                                        <PriceHistory prices={productDetail.last_prices}  />
                                    </div>
                                )
                            }
                            <label  className="block text-sm font-medium mb-1 text-gray-700">
                                {t("app.container.price")}
                            </label>
                        </div>


                        <Controller
                            name="quote_base_price"
                            rules={{ required: false }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputDiscountNumber
                                    label={t("app.container.price")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    decimalScale={4}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(productDetail.id, e, 'quote_base_price');
                                    }}
                                    boxRangeExtraClass={''}
                                    onChangeValues={(e) => {
                                        extras.onChangeValue(productDetail.id, e.discount, 'quote_discounts');
                                        extras.onChangeValue(productDetail.id, e.discount_string, 'discount_string');
                                        setValue('final_price', e.final_price);
                                    }}
                                    suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    errors={error}
                                    isShowFinalPrice={true}
                                    isShowPriceRange={extras.isHideSensitiveData}
                                    discountList={(productDetail?.quote_discounts ?? [])}
                                    symbol={extras?.quotationDetail?.currency?.currency_symbol ?? ''}
                                    purchasePrice={watch('purchase_price')}
                                />
                            )}
                        />
                    </div>


                    <div className="py-1">
                        <Controller
                            name="quote_quantity"
                            rules={{ required: true }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        validateQuantity(e);
                                        extras.onChangeValue(productDetail.id, e, 'quote_quantity');
                                    }}
                                    prefix={""}
                                    suffix={" Pz"}
                                    errors={error}
                                />
                            )}
                        />
                    </div>

                    <CardDetail title={t("app.quotations.net_price")}>
                        <div className="text-sm text-gray-900">
                            <NumberFormat
                                value={(watch('final_price') ?? 0)  * (productDetail.quote_quantity ?? 0)}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={4}
                                suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                            />
                        </div>
                    </CardDetail>

                    <div className="w-full mt-3">
                        <TrashButton
                            isFull={true}
                            onClick={() => {
                                extras.onDeleteProduct(productDetail.id)
                            }}
                            icon={
                                <TrashIcon
                                    className="w-4 h-4  "
                                    aria-hidden="true"
                                />
                            }
                        />
                    </div>

                </div>

            </div>
        </li>
    );
};


const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();


    const {
        watch,
        control,
        setValue,
        clearErrors, setError,
    } = useForm({ mode: "onBlur" });

    const [productDetail, setProductDetail] = useState(element);
    const [isOpen, setOpen] = useState(false);



    useEffect(() => {

        if(productDetail.quote_base_price && productDetail.quote_base_price > 0){
            setValue('quote_base_price', productDetail.quote_base_price)
        }

        if(productDetail.quote_quantity && productDetail.quote_quantity > 0){
            setValue('quote_quantity', productDetail.quote_quantity)
        }

        if(productDetail.pricing){
            setValue('purchase_price', productDetail.pricing?.purchase_price);
            setValue('list_price', productDetail?.pricing?.list_price);
        }

        // eslint-disable-next-line
    }, [setValue, productDetail])

    useEffect(() => {
        setProductDetail(element);
        // eslint-disable-next-line
    }, [element]);




    const validateQuantity = (quantity = null, qty_per_packaging = productDetail?.default_dimension?.qty_per_packaging ?? 0) => {

        let closedBoxes = qty_per_packaging;
        if(closedBoxes > 0){

            let desiredQuantity = quantity;
            if(!desiredQuantity){
                desiredQuantity = watch('quote_quantity');
            }

            if(closedBoxes === desiredQuantity){
                clearErrors('quote_quantity')
            }

            if(isNaN(desiredQuantity)){
                clearErrors('quote_quantity')
            }else{
                if (desiredQuantity === 0) {
                    setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                }else{
                    if(closedBoxes > desiredQuantity){
                        setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+ closedBoxes});
                    }else{
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if((closedBoxes * suggestedQuantityPerBox) === desiredQuantity){
                            clearErrors('quote_quantity')
                        }else{
                            setError('quote_quantity', { type: 'custom', message: t("app.container.product_predict")+(closedBoxes * suggestedQuantityPerBox)});
                        }
                    }
                }
            }



        }else {
            clearErrors('quote_quantity')
        }
    }

    return (
        <>
            <tr className="border-t border-gray-200">
                <TableDetail colSpan={1} extraClass={"whitespace-pre-line"} >

                    <div className={"flex items-center"}>

                        <div
                            onClick={() => {
                                setOpen(!isOpen);
                            }}
                            className={"btn p-2 mr-2 rounded-full bg-white text-gray-800 border border-gray-200 shadow-md  hover:bg-gray-200 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none "}>
                            <PencilIcon
                                className="w-4 h-4 flex-shrink-0"
                                aria-hidden="true"
                            />
                        </div>

                        <ProductCell product={productDetail} />
                    </div>
                </TableDetail>

                <TableDetail extraClass="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    <Controller
                        name="quote_quantity"
                        rules={{ required: true }}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputFormattedNumber
                                label={t("app.container.quantity")}
                                isShowLabel={false}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    validateQuantity(e);
                                    extras.onChangeValue(productDetail.id, e, 'quote_quantity');
                                }}
                                prefix={""}
                                suffix={" Pz"}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>


                <TableDetail  extraClass="px-6 py-3 whitespace-nowrap  whitespace-pre-line text-sm font-medium text-gray-900">

                    <div className="flex items-center">

                        {
                            productDetail.last_prices && extras.isActiveSellingHistory && (
                                <div className="mr-3">
                                    <PriceHistory prices={productDetail.last_prices}  />
                                </div>
                            )
                        }


                        <Controller
                            name="quote_base_price"
                            rules={{ required: false }}
                            control={control}
                            render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                <InputDiscountNumber
                                    label={t("app.container.price")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    decimalScale={4}
                                    onChange={(e) => {
                                        onChange(e);
                                        extras.onChangeValue(productDetail.id, e, 'quote_base_price');
                                    }}
                                    boxRangeExtraClass={''}
                                    onChangeValues={(e) => {
                                        extras.onChangeValue(productDetail.id, e.discount, 'quote_discounts');
                                        extras.onChangeValue(productDetail.id, e.discount_string, 'discount_string');
                                        setValue('final_price', e.final_price);
                                    }}
                                    suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    errors={error}
                                    isShowFinalPrice={true}
                                    isShowPriceRange={extras.isHideSensitiveData}
                                    discountList={(productDetail?.quote_discounts ?? [])}
                                    symbol={extras?.quotationDetail?.currency?.currency_symbol ?? ''}
                                    purchasePrice={watch('purchase_price')}
                                />
                            )}
                        />



                    </div>

                </TableDetail>

                <TableDetail>
                    <div className="text-sm text-gray-900">
                        <NumberFormat
                            value={(watch('final_price') ?? 0)  * (productDetail.quote_quantity ?? 0)}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={4}
                            suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                        />
                    </div>

                </TableDetail>

                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">

                    <TrashButton
                        onClick={() => {
                            extras.onDeleteProduct(productDetail.id)
                        }}
                        icon={
                            <TrashIcon
                                className="w-4 h-4  "
                                aria-hidden="true"
                            />
                        }
                    />

                </td>
            </tr>


            {
                isOpen && (
                    <>
                        <tr className="border-t border-gray-200 bg-solitude-100">
                            <TableDetail colSpan={5} extraClass={"whitespace-pre-line"}>

                                <div className="flex items-center">
                                    {
                                        (productDetail.id && extras.isActiveProductPrice && extras.isActivePurchaseHistory) && (
                                            <div className={'mr-1'}>
                                                <PurchaseHistory product={productDetail}  />
                                            </div>
                                        )
                                    }
                                    <label  className="block text-sm font-medium text-gray-700">
                                        {t("app.quotations.purchase_price")}
                                    </label>
                                </div>

                                <Controller
                                    name="list_price"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                        <InputDiscountNumber
                                            label={t("app.quotations.purchase_price")}
                                            isShowLabel={false}
                                            name={name}
                                            value={value}
                                            decimalScale={4}
                                            onChange={(e) => {
                                                onChange(e);
                                                extras.onChangeValue(productDetail.id, e, 'list_price', true);
                                            }}
                                            onChangeValues={(e) => {
                                                extras.onChangeValue(productDetail.id, e.discount_string, 'discount_string', true);
                                                extras.onChangeValue(productDetail.id, e.discount, 'discount', true);
                                                extras.onChangeValue(productDetail.id, e.final_price, 'purchase_price', true);
                                                extras.onChangeValue(productDetail.id, true, 'edited_rate');
                                                setValue('purchase_price', e.final_price);
                                            }}
                                            suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                            errors={error}
                                            isShowFinalPrice={true}
                                            discountList={(productDetail?.pricing?.discount ?? [])}
                                        />
                                    )}
                                />

                            </TableDetail>

                        </tr>
                    </>
                )
            }
        </>
    );
};


export default ProductConfiguration;
