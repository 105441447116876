import React, {useEffect, useState} from "react";
import InputSubmit from "../../components/common/form/input-submit";
import FormPopup from "../../components/common/popup/form-popup";
import {useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import { useForm } from "react-hook-form";
import {getWidgets, postDashboardWidget} from "../../api/dashboard";
import { Combobox } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  ChartPieIcon,
  ClipboardListIcon,
  ExclamationCircleIcon, MapIcon, TableIcon, ViewGridIcon,
} from '@heroicons/react/outline'
import LoaderWrapper from "../../components/common/loader/loader-wrapper";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function AddWidget({ isOpen, setIsOpen, position, onUpdateData, widgetDetails = [] }) {


  const { loggedCompany, companyType } = useSelector(
      (state) => state.user
  );

  const {
    handleSubmit,
    setValue,
    watch
  } = useForm();

  const { t } = useTranslation();

  // customer
  const [isCreateLoading, setCreateLoading] = useState(false);
  const [exception, setException] = useState(false);


  const [isFetchLoading, setFetchLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);


  const storeWidget = async (data) => {
    if (loggedCompany) {
      setCreateLoading(true);
      postDashboardWidget({ company_id: loggedCompany.id, data })
        .then((response) => {
          setCreateLoading(false);
          onUpdateData(response);
          setIsOpen(false);
        }).catch((e) => {
          setException(e.message);
          setCreateLoading(false);
      });
    }
  };


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchWidgets() {
      if (loggedCompany) {
        setFetchLoading(true);
        try {
          const res = await getWidgets({
            company_id: loggedCompany.id,
            params: {company_type: companyType},
            signal,
          });
          setWidgets(res);
        } catch (e) {
          setWidgets([]);
        } finally {
          if(signal.aborted){
            setFetchLoading(true);
          }else{
            setFetchLoading(false);
          }
        }
      }
    }

    fetchWidgets();
    return () => {
      controller.abort();
    };
  }, [loggedCompany, companyType]);








  const WidgetType = (type) => {
    if(type === 'list'){
      return { icon: <ClipboardListIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-red-500'};
    }
    if(type === 'table'){
      return { icon:  <TableIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-yellow-500'};
    }
    if(type === 'chart-pie'){
      return { icon:  <ChartPieIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-green-500'};
    }
    if(type === 'chart-bar'){
      return { icon:  <ChartBarIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-blue-500'};
    }
    if(type === 'calendar'){
      return { icon:  <CalendarIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-purple-500'};
    }
    if(type === 'map'){
      return { icon:  <MapIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-indigo-500'};
    }
    return { icon:  <ViewGridIcon className="h-6 w-6 text-white" aria-hidden="true"  />, color: 'bg-indigo-500'};
  };



  const onSubmit = async (data) => {
    data['company_type'] = companyType;
    data['position'] = "{x: "+position.x+", y:"+position.y+", w:"+position.w+", h:"+position.h+"}";
    await storeWidget(data);
  };

  const filteredWidgets = widgetDetails.length > 0 ? widgets.filter(w => !widgetDetails.some(aw => aw.dashboard_widget && aw.dashboard_widget.id === w.id && aw.dashboard_widget.code !== 'COMPANY_BOARD')) : widgets;

  return (
      <FormPopup
          title={t("dashboard.select_widget")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >

        <form onSubmit={handleSubmit(onSubmit)}>

          <LoaderWrapper isLoading={isFetchLoading}>

          <Combobox onChange={(item) => {setValue('dashboard_widget', {id: item.id})}}>

            {filteredWidgets.length > 0 && (
                <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto ">
                  {filteredWidgets.map((item) => (
                      <Combobox.Option
                          key={item.id}
                          value={item}
                          className={({ active }) =>
                              classNames('flex cursor-default select-none rounded-xl p-2',
                                  ((watch('dashboard_widget')?.id === item.id ?? false) || active) && 'bg-gray-100'
                              )
                          }
                      >
                        {({ active }) => (
                            <>
                              <div
                                  className={classNames(
                                      'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
                                      WidgetType(item.type).color
                                  )}
                              >
                                {WidgetType(item.type).icon}
                              </div>
                              <div className="ml-4 flex-auto">
                                <p
                                    className={classNames(
                                        'text-sm font-medium',
                                        active ? 'text-gray-900' : 'text-gray-700'
                                    )}
                                >
                                  {item.name}
                                </p>
                                <p className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
                                  {item.description}
                                </p>
                              </div>
                            </>
                        )}
                      </Combobox.Option>
                  ))}
                </Combobox.Options>
            )}

            {filteredWidgets.length === 0 && (
                <div className="px-6 py-14 text-center text-sm sm:px-14">
                  <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                  />
                  <p className="mt-4 font-semibold text-gray-900">{t("dashboard.no_result_title")}</p>
                  <p className="mt-2 text-gray-500">{t("dashboard.no_result_description")}</p>
                </div>
            )}
          </Combobox>


          {/* Warning */}
          {exception && <Warning message={exception} />}

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                disabled={!watch('dashboard_widget')}
                isLoading={isCreateLoading}
                label={t("dashboard.add_widget")}
            />
          </div>
          </LoaderWrapper>
        </form>
      </FormPopup>
  );
}
