import React from "react";
import InputFormattedNumber from "./input-formatted-number";
import {RefreshIcon} from "@heroicons/react/outline";

export default function ExchangeRateBtn({
                                            label,
                                            name,
                                            value,
                                            onChange,
                                    symbol,
                                            error,
                                            isRequired,
                                            isLoading,
                                            onClick
                                  }) {

  return (
      <div className="relative ">
          <InputFormattedNumber
                  label={label}
                  isShowLabel={false}
                  isFullW={true}
                  name={name}
                  value={value}
                  onChange={onChange}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={symbol}
                  errors={error}
                  decimalScale={6}
                  isRequired={isRequired}
              />

          <div className="absolute top-1.5 right-2">

              {
                  isLoading ? (
                      <button
                          className={"py-1 px-4 text-white rounded disabled:border-gray-200  disabled:text-gray-400 disabled:cursor-not-allowed"}
                          disabled
                      >
                          <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0"
                              viewBox="0 0 16 16"
                          >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                      </button>
                  ) : (
                      <div  onClick={onClick}
                               className={"py-1 cursor-pointer px-4 text-blue-1000 rounded  disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed "}>
                          <RefreshIcon className="h-5 w-5 transform rotate-90" />
                      </div>
                  )
              }
          </div>
      </div>
  );
}
