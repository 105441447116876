import React from "react";
import {DuplicateIcon, PencilIcon, PlusIcon} from "@heroicons/react/outline";
import {getPathParam} from "../../../utils/converter";
import {CONTAINER, SHIPPING} from "../../../routes/endpoints";
import {CLONE, GROUP, LOAD_ALL, SUB_GROUP} from "../../../api/endpoints";
import ContainerLoadIcon from "../../../resources/icons/ContainerLoadIcon";
import {TrashIcon} from "@heroicons/react/outline/esm";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import CircleBtn from "../button/circle-btn";
import BoxIcon from "../../../resources/icons/BoxIcon";

const ContainerGroupCard = ({dragHandle, groupDetail, onEditGroup, onLoadAll, onAdd,onAddSubCategory, onRemove, children, isSub = false, isGrouped = true, baseGroupDetail = null, hasChildren = false}) => {
    const { t } = useTranslation();
    let {id, container_id}  = useParams();

    return (
        isGrouped ? (
            <div className={(isSub ? 'border border-indigo-800 ' : 'shadow ' ) + "bg-white  overflow-hidden mb-5"}>
                <header className={(isSub ? 'bg-indigo-800 border-b border-indigo-800 ' : '' ) + "relative  flex items-center justify-between py-4 px-6 flex-none"}>

                    <div>
                        <h1 className={(isSub ? 'text-white  ' : 'text-gray-900 ' ) +  "text-lg font-semibold"}>
                            <button className="cursor-move mr-3" {...dragHandle}>
                                <svg className="w-3 h-3 fill-slate-500" viexbox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill="#CBD5E1" fillRule="evenodd" />
                                </svg>
                            </button>
                            <CircleBtn
                                icon={PencilIcon}
                                onClick={() => {
                                    onEditGroup();
                                }}
                                extraClass={"bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"}
                            />

                            {groupDetail.group_name}
                        </h1>


                    </div>

                    <div className="flex items-center">
                        {
                            hasChildren && (
                                <CircleBtn
                                    icon={ContainerLoadIcon}
                                    onClick={() => {
                                        const confirmDetail = {
                                            title: t("app.container.load_all_title"),
                                            type: isSub ? 'sub_group' : 'group',
                                            group_id: isSub ? baseGroupDetail.id : groupDetail.id,
                                            message: t("app.container.load_all_description"),
                                            endpoint: isSub ? getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id, LOAD_ALL]) :  getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, groupDetail.id, LOAD_ALL]),
                                        };
                                        onLoadAll(confirmDetail);
                                    }}
                                    extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                                />
                            )
                        }

                        {
                            isSub && (
                                <CircleBtn
                                    icon={DuplicateIcon}
                                    onClick={() => {
                                        const confirmDetail = {
                                            title: t("app.container.clone_title", {type: groupDetail.pallet ? t("app.container.pallet") : t("app.container.group") }),
                                            type: isSub ? 'sub_group' : 'group',
                                            group_id: baseGroupDetail.id,
                                            message: t("app.container.clone_description"),
                                            endpoint: isSub ? getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id, CLONE]) :  getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, groupDetail.id, CLONE]),
                                        };
                                        onLoadAll(confirmDetail);
                                    }}
                                    extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                                />
                            )
                        }


                        <CircleBtn
                            icon={PlusIcon}
                            onClick={() => {
                                onAdd();
                            }}
                            extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                        />

                        {
                            !isSub && (
                                <CircleBtn
                                    icon={BoxIcon}
                                    onClick={() => {
                                        onAddSubCategory();
                                    }}
                                    extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                                />
                            )
                        }


                        <CircleBtn
                            icon={TrashIcon}
                            onClick={() => {
                                const removeDetail = {
                                    title: t("app.container.remove_group"),
                                    type: isSub ? 'sub' : '',
                                    group_id: isSub ? baseGroupDetail.id : '',
                                    message: t("app.container.remove_group_description"),
                                    endpoint: isSub ? getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id]) : getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, groupDetail.id]),
                                };
                                onRemove(removeDetail);
                            }}
                            extraClass="text-red-800 bg-red-100  hover:bg-red-800 hover:text-white"
                        />
                    </div>

                </header>

                {children}

            </div>
        ) : (
            <>{children}</>
        )
    );
};



export default ContainerGroupCard;
