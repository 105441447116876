import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import InputDate from "../../components/common/form/input-date";
import {getPathParam, getSelectParams} from "../../utils/converter";
import InputSelect from "../../components/common/form/input-select";
import {putTask} from "../../api/task";
import {Warning} from "../../components/common/alert/banner";
import {getCompanyUsers} from "../../api/company";
import InputEditor from "../../components/common/form/input-editor";
import {getFormattedDate} from "../../utils/timeUtils";
import {createPopper} from "@popperjs/core";
import Initials from "../../components/common/avatar/initials";
import UploadMultifile from "../../components/partials/upload/upload-multifile";
import {COMPANY, FILE, TASK} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";

export default function EditTask({
  isOpen,
  setIsOpen,
  isEditable = false,
  onAddTask,
    data
}) {
  const { loggedCompany } = useSelector((state) => state.user);
  const { register, handleSubmit, formState: { errors }, control, setValue } = useForm();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);


  const [isLoadingUsers, setLoadingUsers] = useState(false);
  const [userList, setUserList] = useState([]);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [oldFiles, setOldFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const updateTask = async (data) => {
      if (loggedCompany) {
          setLoading(true);
          putTask({ company_id: loggedCompany.id, task_id: data.id, data })
              .then((response) => {
                  onAddTask(response);
                  setLoading(false);
                  setIsOpen(false);
              }).catch((e) => {
                  setException(e.message);
                  setLoading(false);
              });
        }
  };


    useEffect(() => {
        if(data){
            setValue('id', data.id);
            setValue('title', data.title);
            setValue('task_date', data.task_date);
            setValue('description', data.description);
            setValue('users', getSelectParams( (data?.company_task_users ?? []).map((c) => c.user), "fullname"));
            setValue('public', data.public);


            const newFiles = data.company_task_files.map(item => ({
                id: item.id,
                reference: item.file.reference,
                name: item.file_name,
            }));

            setOldFiles(newFiles);
        }
    }, [data, setValue]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchUsers() {
            if (loggedCompany && isOpen === true) {
                setLoadingUsers(true);
                getCompanyUsers({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setUserList(getSelectParams(response, "fullname"));
                        setLoadingUsers(false);
                    }).catch((e) => {
                    setLoadingUsers(false);
                });
            }
        }
        fetchUsers();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpen]);

  const onSubmit = async (data) => {

      if(files.length > 0){
          data['company_task_files'] = [];

          // eslint-disable-next-line
          files.map(item => {
              data['company_task_files'].push({file: {media_url : item.reference }, file_name: item.name});
          });
      }

      updateTask(data);
  };

  return (
      <FormPopup
          title={isEditable ? t("app.tasks.edit_task")  : t("app.tasks.view_task")}
          isOpen={isOpen}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >
          {
              isEditable ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="space-y-4">

                          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
                              <InputText
                                  id={"title"}
                                  isRequired={true}
                                  isDisabled={!isEditable}
                                  type={"text"}
                                  errors={errors.title}
                                  input={{ ...register("title", { required: true }) }}
                                  label={t("app.tasks.title")}
                              />

                              <Controller
                                  name="task_date"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({
                                               field: { onChange, value, name },
                                               fieldState: { error },
                                           }) => (
                                      <InputDate
                                          label={t("app.tasks.task_date")}
                                          format={"YYYY-MM-D HH:mm:ss"}
                                          placeholder={"Es. 01/12/2021"}
                                          disabled={!isEditable}
                                          isRequired={true}
                                          activeMinDate={false}
                                          startDate={value}
                                          isShowTime={false}
                                          errors={error}
                                          name={name}
                                          onChange={(e) => {
                                              onChange(e);
                                          }}
                                      />
                                  )}
                              />

                              <div className="col-span-2">
                                  <Controller
                                      name="users"
                                      rules={{ required: false }}
                                      control={control}
                                      render={({
                                                   field: { onChange, value, name },
                                                   fieldState: { error },
                                               }) => (
                                          <InputSelect
                                              label={t("app.tasks.users")}
                                              name={name}
                                              options={userList.filter(obj1 => !(data?.company_task_users ?? []).some(obj2 => obj2.user.id === obj1.id))}
                                              value={value}
                                              onChange={(e) => {
                                                  onChange(e);
                                              }}
                                              isMulti={true}
                                              isLoading={isLoadingUsers}
                                              isDisabled={isLoadingUsers || !isEditable}
                                              errors={error}
                                          />
                                      )}
                                  />
                              </div>

                              <div className="col-span-2">
                                  <Controller
                                      name="description"
                                      rules={{ required: false }}
                                      control={control}
                                      render={({field: { onChange, value, name },
                                                   fieldState: { error },
                                               }) => (
                                          <InputEditor
                                              label={t("app.tasks.description")}
                                              errors={error}
                                              defaultValue={value}
                                              name={name}
                                              onChange={(e) => {
                                                  onChange(e);
                                              }}
                                          />
                                      )}
                                  />
                              </div>

                              <div className={'col-span-2'}>
                                  <UploadMultifile
                                      onUpload={(data) => {
                                          setFiles(data);
                                      }}
                                      oldFiles={oldFiles}
                                      onRemove={(r) => {
                                          const removeDetail = {
                                              title: t("app.tasks.remove_file_title"),
                                              message: t("app.tasks.remove_file_description"),
                                              endpoint: getPathParam([COMPANY, loggedCompany.id, TASK, data.id, FILE, r]),
                                          };
                                          setRemoveDetail(removeDetail);
                                          setOpenRemove(true);
                                      }}
                                      onException={(e) => setException(e)}
                                      isLoading={false}
                                      label={t("app.document.upload_document")}
                                  />
                              </div>

                          </div>

                          {/* Warning */}
                          {exception && (
                              <Warning message={exception} />
                          )}


                      </div>

                      <div className="flex before:flex-1 items-center justify-between mt-6">
                          <InputSubmit
                              isLoading={isLoading}
                              label={t("app.form.save")}
                          />
                      </div>

                  </form>
              ) : (
                  <div className="space-y-4">

                      <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">

                          <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">{t("app.tasks.title")}</dt>
                              <dd className="mt-1 text-sm text-blue-1000">{data?.title ?? ''}</dd>
                          </div>

                          <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">{t("app.tasks.task_date")}</dt>
                              <dd className="mt-1 text-sm text-blue-1000">{getFormattedDate(data?.task_date ?? false)}</dd>
                          </div>

                          <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">{t("app.tasks.detail_users")}</dt>
                              <dd className="mt-1 text-sm text-blue-1000">
                                  {
                                      (data.company_task_users && data.company_task_users.length > 0 ) && (
                                          <div className="flex shrink-0 -space-x-4 -ml-px">

                                              {
                                                  data.company_task_users.map(item => (
                                                      <Popover key={item.id} item={item} />
                                                  ))
                                              }

                                          </div>
                                      )
                                  }
                              </dd>
                          </div>

                          <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">{t("app.tasks.description")}</dt>
                              <dd className="mt-1 text-sm text-blue-1000" dangerouslySetInnerHTML={{__html: data.description}} />
                          </div>

                          <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">File</dt>
                              <UploadMultifile
                                  oldFiles={oldFiles}
                                  isViewMode={true}
                              />
                          </div>

                      </div>
                  </div>

              )
          }

          {isOpenRemove && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  setDetail={(r) => {
                      const newFiles = r.company_task_files.map(item => ({
                          id: item.id,
                          reference: item.file.reference,
                          name: item.file_name,
                      }));

                      setOldFiles(newFiles);
                  }}
              />
          )}

      </FormPopup>
  );
}

const Popover = ({ item }) => {
    const [popoverShow, setPopoverShow] = React.useState(false);
    const btnRef = React.createRef();
    const popoverRef = React.createRef();
    const openPopover = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "left"
        });
        setPopoverShow(true);
    };
    const closePopover = () => {
        setPopoverShow(false);
    };
    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full text-center">
                    <div className="block cursor-pointer"
                         ref={btnRef}
                         onClick={() => {
                             popoverShow ? closePopover() : openPopover();
                         }}>
                        <Initials
                            iconClass={' h-6 w-6'}
                            color={item.user.color}
                            name={item.user.fullname}
                        />
                    </div>
                    <div
                        className={
                            (popoverShow ? "" : "hidden ") +
                            "border-0 mr-3 block z-50"
                        }
                        ref={popoverRef}
                    >
                        <div className="rounded-md shadow-lg  mr-3">
                            <div className="relative bg-solitude-100 px-4 py-2 font-medium text-gray-900">
                                {item.user.fullname}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
