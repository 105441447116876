import React from "react";
import {useTranslation} from "react-i18next";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const AcquisitionBar = ({status}) => {


    const { t } = useTranslation();

    let statusStyles = {
        low: {
            style: 'bg-green-100 text-green-800',
            title: t("app.quotations.probability_low")
        },
        medium: {
            style: 'bg-yellow-100 text-yellow-800',
            title: t("app.quotations.probability_medium")
        },
        high: {
            style: 'bg-red-100 text-red-800',
            title: t("app.quotations.probability_high")
        }
    };

    return (
        statusStyles[status] ? (
            <span
                className={classNames(
                    "inline-flex  items-center rounded-full text-xs font-medium px-2.5 py-1 ",
                    statusStyles[status].style,
                )}
            >


                {statusStyles[status].title}
            </span>
        ) : (
            <div>-</div>
        )

    )
};
