import React from "react";
import {DuplicateIcon, PencilIcon} from "@heroicons/react/outline";
import ContainerLoadIcon from "../../../resources/icons/ContainerLoadIcon";
import {getPathParam} from "../../../utils/converter";
import {CONTAINER, SHIPPING} from "../../../routes/endpoints";
import {CLONE, GROUP, PACKING, SUB_GROUP} from "../../../api/endpoints";
import {TrashIcon} from "@heroicons/react/outline/esm";
import {useTranslation} from "react-i18next";
import ProductCell from "../table/product-cell";
import NumberFormat from "react-number-format";
import {useParams} from "react-router-dom";
import CircleBtn from "../button/circle-btn";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const groupContainerProduct = (array) => {
    let result = [];
    array.reduce(function(res, value) {
        if (!res[value.container_product.product.product_reference]) {
            res[value.container_product.product.product_reference] = {
                product_reference: value.container_product.product.product_reference,
                manufacture_reference: value.container_product?.product?.manufacture_reference ?? false,
                name: value.container_product.product.name,
                quantity: 0
            };
            result.push(res[value.container_product.product.product_reference])
        }
        res[value.container_product.product.product_reference].quantity += value.quantity;
        return res;
    }, {});

    return result;
};


const ContainerPackagingCard = ({box, groupDetail,baseGroupDetail, onEditDetailBox, onEditBox, onRemove, isGrouped = false, onClone}) => {
    const { t } = useTranslation();
    let {id, container_id}  = useParams();

    return (
        <li
            className={classNames(
                box.box === box.loaded_box ? 'border border-green-500' : '',
                box.box > box.loaded_box && box.loaded_box > 0  ? 'border border-yellow-500' : '',
                isGrouped  ? 'border mb-1 border-solitude-400' : 'shadow',
                'col-span-1  flex flex-col text-left bg-white divide-y divide-gray-200'
            )}
        >

            <div className="flex-1 flex flex-col">

                <div className={classNames(
                    box.box === box.loaded_box ? 'bg-green-500 text-white' : '',
                    box.box > box.loaded_box && box.loaded_box > 0  ? 'bg-yellow-500  text-white' : '',
                    box.loaded_box === 0 ? 'bg-solitude-100 text-gray-900' : '',
                    'p-4 justify-between flex items-center'
                )}>


                    <div className="justify-between flex items-center">
                        <CircleBtn
                            icon={PencilIcon}
                            onClick={() => {
                                onEditDetailBox();
                            }}
                            extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                        />

                        <div className="flex flex-col text-sm ">
                            <div className="text-left">
                                <div className={classNames(
                                    box.loaded_box === 0 ? 'text-gray-900' : '' ,
                                    "text-sm font-semibold"
                                )}>
                                    {box.loaded_box}/{box.box} {t("app.container.loaded")}
                                </div>
                                <div className={classNames(
                                    box.loaded_box === 0 ? 'text-gray-500' : '' ,
                                    "text-xs flex-wrap"
                                )}>
                                    {box.length} x {box.width} x {box.height} cm
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="justify-between flex items-center">



                        <CircleBtn
                            icon={DuplicateIcon}
                            onClick={() => {
                                const confirmDetail = {
                                    title: t("app.container.clone_title", {type: t("app.container.packaging") }),
                                    type: isGrouped ? 'clone_box' : 'sub_group',
                                    sub_group_id: groupDetail.id,
                                    group_id: baseGroupDetail.id,
                                    message: t("app.container.clone_description"),
                                    endpoint: isGrouped ? getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id,  PACKING, box.id, CLONE]) : getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id, CLONE]),
                                };
                                onClone(confirmDetail);
                            }}
                            extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                        />




                        <CircleBtn
                            icon={ContainerLoadIcon}
                            onClick={() => {
                                onEditBox();
                            }}
                            extraClass="bg-white text-gray-800 border border-gray-200  hover:bg-gray-200"
                        />

                        <button className="flex btn p-2 rounded-full text-red-800 bg-red-100  hover:bg-red-800 hover:text-white" onClick={() => {
                            const removeDetail = {
                                title: t("app.container.packaging_remove"),
                                type: isGrouped ? 'box' : 'external',
                                sub_group_id: groupDetail.id,
                                group_id: baseGroupDetail.id,
                                message: t("app.container.packaging_remove_description"),
                                endpoint: getPathParam([SHIPPING, id, CONTAINER, container_id, GROUP, baseGroupDetail.id, SUB_GROUP, groupDetail.id,  PACKING, box.id]),
                            };
                            onRemove(removeDetail);
                        }}>
                            <TrashIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                            />
                        </button>
                    </div>


                </div>

                <ul className="divide-y divide-gray-200">
                    {
                        groupContainerProduct(box.product_packages).map((item, index) => (
                            <li key={index} className="p-4 flex-grow flex flex-col justify-between">
                                <AccordionListDetail
                                    item={item}
                                    box={box}/>
                            </li>
                        ))
                    }
                </ul>

            </div>






        </li>
    );
};


function AccordionListDetail({item, box}) {
    const { t } = useTranslation();

    return (
        <div className="grid grid-cols-2 gap-2 items-center">
            <div>
                <ProductCell isActiveImage={false} product={item}/>
            </div>
            <div className=" space-x-4 text-right">
                <div className={(item.quantity / box.box)  % 1 !== 0 ? "text-sm text-red-900": "text-sm text-gray-900"}>
                    <NumberFormat
                        value={item.quantity / box.box}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalScale={0}
                        decimalSeparator={","}
                        suffix={" "+t("app.container.per_box")}
                    />
                </div>
                <div className="text-xs text-gray-500">
                    <NumberFormat
                        value={item.quantity}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        suffix={' pz'}
                    />
                </div>
            </div>
        </div>
    );
}


export default ContainerPackagingCard;
