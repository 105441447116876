import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import XIcon from "@heroicons/react/solid/XIcon";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function  FormPopup({showHeader = true, title, isOpen, setIsOpen, children, isActiveCloseBack= true, defaultWith = 'max-w-2xl', isActiveFormPm = true, isRemovePm = false}) {

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => {isActiveCloseBack && setIsOpen(false)}}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay style={{zIndex: -1}} className="fixed inset-0 bg-gray-900 bg-opacity-30 z-01 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                          &#8203;
                        </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={"inline-block w-full  my-8 text-left align-middle transition-all  bg-white shadow-xl " + defaultWith}>

                            {
                                showHeader && (
                                    <div className="flex justify-between border-b p-4 items-center">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-blue-1000">{title}</h3>
                                        </div>
                                        <div>
                                            <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                        </div>
                                    </div>
                                )
                            }



                            <div className={
                                classNames(
                                    isRemovePm && "",
                                    isActiveFormPm && "mt-2 p-4",
                                    !showHeader && 'p-4'
                                )

                                }>
                                {
                                    !showHeader && (
                                        <div className="flex before:flex-1 justify-between items-center">
                                            <div>
                                                <button className="bg-white border-0" onClick={() => {setIsOpen(false)}}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                            </div>
                                        </div>
                                    )
                                }





                                {children}
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
