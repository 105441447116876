import React, {useEffect, useMemo, useState} from "react";
import SlidePanel from "../../../../components/layout/slide-panel";
import {useSelector} from "react-redux";
import {getImports} from "../../../../api/import";
import ListSection from "../../../../components/layout/list-section";
import {filterByPermission} from "../../../../components/partials/restricted/permission-wrapper";
import {getPermission, pg, pt} from "../../../../constants/permissions";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {StatusBar} from "../../../../components/common/alert/status-bar";
import {getCustomFormat} from "../../../../utils/timeUtils";
import {useTranslation} from "react-i18next";

export default function ImportLog({ isOpen, setIsOpen, type }) {


    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);
    const { t } = useTranslation();

    // Load Process
    const [isLoading ,setLoading] = useState(false);


    const [detail , setDetail] = useState({ imports: [], total_rows: 0 });

    const [offset, setOffset] = useState(0);
    const [query, setQuery] = useState("");

    const columns = [
        t("app.import_log.file_name"),
        t("app.import_log.status"),
        t("app.import_log.imported"),
        t("app.import_log.not_imported"),
        t("app.import_log.date")
    ];

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchImports() {
            if (loggedCompany && isOpen) {
                setLoading(true);
                getImports({
                    company_id: loggedCompany.id,
                    params: { limit: 15, offset: offset, type: type },
                    signal,
                })
                    .then((response) => {
                        setDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setDetail({ imports: [], total_rows: 0 });
                        setLoading(false);
                    });
            }
        }

        fetchImports();
        return () => {
            controller.abort();
        };
    }, [offset, query, loggedCompany, type, isOpen]);


    const allowedImports = useMemo(
        () =>
            (detail.imports ?? []).filter(
                filterByPermission(getPermission(pg.ORDER, pt.LIST), permissions)
            ),
        [permissions, detail.imports]
    );

    return (
        <SlidePanel
            title={t("app.import_log.import_title")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            size={'max-w-4xl'}
            bg={"bg-white"}
            setClean={() => {
            }}
        >

            <div className="space-y-6 pt-6 pb-5">

                <div className="border">
                    <ListSection
                        title={"Imports"}
                        showHeader={false}
                        columns={columns}
                        totalRows={detail.total_rows}
                        list={allowedImports}
                        onSearch={setQuery}
                        isLoading={isLoading}
                        paginationOnChange={setOffset}
                        mobileRow={OrderMobRow}
                        desktopRow={OrderDeskRow}
                    />
                </div>

            </div>

        </SlidePanel>
    );
}


const OrderMobRow = ({ element }) => {
    const { t } = useTranslation();

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.file_name}
                </div>

                <div className="flex flex-col">
                    <CardDetail title={t("app.import_log.status")}>
                        <StatusBar
                            status={element.status === 'done' ? "success" : "processing"}
                            title={element.status === 'done' ? t("app.import_log.import_success") : t("app.import_log.import_processing")}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.import_log.imported")}>
                        {element.total_processed}
                    </CardDetail>

                    <CardDetail title={t("app.import_log.not_imported")}>
                        {element.total_error}
                    </CardDetail>

                    <CardDetail title={t("app.import_log.date")}>
                        {getCustomFormat(element.upload_date, 'DD/MM/YYYY HH:mm')}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const OrderDeskRow = ({ element }) => {
    const { t } = useTranslation();

    return (
        <tr key={element.id}>
            <TableDetail>
                {element.file_name}
            </TableDetail>
            <TableDetail>
                <StatusBar
                    status={element.status === 'done' ? "success" : "processing"}
                    title={element.status === 'done' ? t("app.import_log.import_success") : t("app.import_log.import_processing")}
                />
            </TableDetail>

            <TableDetail>
                {element.total_processed}
            </TableDetail>

            <TableDetail>
                {element.total_error}
            </TableDetail>

            <TableDetail>
                {getCustomFormat(element.upload_date, 'DD/MM/YYYY HH:mm')}
            </TableDetail>
        </tr>
    );
};

