import React from "react";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import CustomerIcon from "../../../resources/icons/CustomerIcon";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";

const getInitialsName = (name) => {
  return name?.charAt(0)?.toUpperCase() ?? '';
};

const Initials = ({ color, name, isVerified, registryType = false, ringClass= ' ', iconClass = ' h-8 w-8', verifiedIcon = 'h-3 w-3' , isDisabled = false, fontSize = ''}) => {
  let extraClassName = "";
  switch (color) {
    case "gray":
      extraClassName = "bg-gray-500";
      break;
    case "red":
      extraClassName = "bg-red-500";
      break;
    case "yellow":
      extraClassName = "bg-yellow-500";
      break;
    case "green":
      extraClassName = "bg-green-500";
      break;
    case "blue":
      extraClassName = "bg-blue-500";
      break;
    case "indigo":
      extraClassName = "bg-indigo-500";
      break;
    case "purple":
      extraClassName = "bg-purple-500";
      break;
    case "pink":
      extraClassName = "bg-pink-500";
      break;
    default:
      extraClassName = "bg-gray-500";
      break;
  }

  return (
    <span
      className={
        "flex-shrink-0 inline-flex relative items-center justify-center mr-2 rounded-full " +ringClass+
        extraClassName + iconClass
      }
    >

      {
        registryType ? (
          <span className={fontSize + "  font-medium leading-none text-white"}>
            {
              (registryType === 'customer') && (
                <CustomerIcon className={'w-4 h-4 text-white'} />
              )
            }

            {
              (registryType === 'supplier') && (
                <WarehouseIcon className={'w-4 h-4 text-white'} />
              )
            }
          </span>
        ) : (
          <>

              <span className={fontSize + "  font-medium leading-none text-white"}>
                {getInitialsName(name)}
              </span>
              {
                isVerified && (
                  <CheckIcon className={"absolute bottom-0 right-0 block rounded-full ring-2 ring-white bg-green-400 text-white " + verifiedIcon} />
                )
              }
              {
                isDisabled && (
                  <XIcon className={"absolute bottom-0 right-0 block rounded-full ring-2 ring-white bg-red-400 text-white " + verifiedIcon} />
                )
              }
          </>
        )
      }




      
    </span>
  );
};
export default Initials;
