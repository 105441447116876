import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router";
import {loadUserDataIfMissing} from "../../reducers/userReducer";
import {LOGIN} from "../../routes/endpoints";

const RequireAuthOnboarding = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserDataIfMissing());
  }, [dispatch]);


  if(!isAuthenticated){
      return (<Navigate to={LOGIN} state={{ from: location }} />);
  }


  return (
      <div className="min-h-screen">
          <Outlet />
      </div>
  );


};

export default RequireAuthOnboarding;
