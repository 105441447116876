import firebase from 'firebase/app';
import 'firebase/messaging';
import {putUser} from "../api/user";
import {getData, setData} from "../utils/cacheUtil";

const firebaseConfig = {
    apiKey: "AIzaSyAAaeOxwq7lUxrmIRYcoW32CDyGRRL8Uwo",
    authDomain: "dokless-f6f83.firebaseapp.com",
    databaseURL: "https://dokless-f6f83-default-rtdb.firebaseio.com",
    projectId: "dokless-f6f83",
    storageBucket: "dokless-f6f83.appspot.com",
    messagingSenderId: "476668235069",
    appId: "1:476668235069:web:2b916cd824750be7ff8954",
    measurementId: "G-LWRS9FHW5X"
};
const publicKey = 'BItrAuB_dVnTeAUQsJyc2ZIvi1oSA1W2KoEmjyg0lIgvbEFAdI5mxvgukTXEZwg_Je8IVmrpfkolsRDTXG1wt-4';
const audio = new Audio('https://freesound.org/data/previews/91/91926_7037-lq.mp3');

let messaging = null;
if (firebase.messaging.isSupported()) {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }else {
        firebase.app(); // if already initialized, use that one
    }
    messaging = firebase.messaging()
} else {
    console.log('no-support :(')
}

export const isActiveFirebase  = () => messaging !== null;

export const requestForToken = (detail) => {
    return messaging?.getToken({ vapidKey: publicKey })
        .then((currentToken) => {
            if (currentToken && (!getData('is_web_token_granted') || getData('is_web_token_granted') !== currentToken)) {
                if(detail){
                    putUser({user_id: detail.id, data: {id: detail.id, push_token_web: currentToken}});
                    setData('is_web_token_granted', currentToken);
                }
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};


export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage( (payload) => {
           // console.log("payload", payload);
            audio.play();
            resolve(payload);
        });
    });
