import React, {Fragment, useEffect, useState} from "react";
import {getSelectParams} from "../../../utils/converter";
import { useSelector } from "react-redux";
import {getElement} from "../../../api/config";
import {Combobox, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/outline";
import Loader from "../../common/loader/loader";
import Initials from "../../common/avatar/initials";
import {useTranslation} from "react-i18next";


function getWithExpiryDate(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    let item;
    try {
        item = JSON.parse(itemStr);
    } catch (error) {
        // If there was an error parsing the JSON, remove the item and return null
        localStorage.removeItem(key);
        return null;
    }

    const now = new Date();

    // Convert the expiry string back to a Date object
    const expiryDate = new Date(item.expiry);

    // Check if the expiryDate is valid
    if (isNaN(expiryDate)) {
        localStorage.removeItem(key);
        return null;
    }

    if (now.getTime() > expiryDate.getTime()) {
        localStorage.removeItem(key);
        return null;
    }

    return item.details;
}



const DynamicSearchList = ({endpoint, responseLabelList = false, responseLabel, label, selectLabel, selectedItem, onSelect, isRequired = false, onSelected, setLoaded}) => {
    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [query, setQuery] = useState('');





    useEffect(() => {
        async function fetchData() {
            if (loggedCompany) {
                setLoading(true);
                setLoaded(true);


                const endpointData = await getWithExpiryDate(endpoint);
                if(endpointData){
                    setList(endpointData);
                    if(selectedItem){
                        onSelected(selectedItem ? endpointData.find(elem => elem.id === selectedItem) : null);
                    }

                    setLoading(false);
                    setLoaded(false);
                }else{
                    const now = new Date();
                    const futureDate = new Date(now.getTime() + 10 * 60 * 1000);
                    getElement(endpoint).then((response) => {


                        let data = false;
                        if (responseLabelList) {
                            data = getSelectParams(response[responseLabelList][responseLabel], label);
                        } else {
                            data = getSelectParams(response[responseLabel], label);
                        }

                        let objectData = {expiry: futureDate.toISOString(), details: data};
                        localStorage.setItem(endpoint, JSON.stringify(objectData));

                        setList(data);
                        onSelected(selectedItem ? data.find(elem => elem.id === selectedItem) : null)


                        setLoading(false);
                        setLoaded(false);
                    }).catch((e) => {
                        setLoading(false);
                        setLoaded(false);
                    });
                }




            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [loggedCompany, endpoint, responseLabelList, responseLabel, label]);


    const filteredData =
        query === ''
            ? list
            : list.filter((item) =>
                item.label.toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div className="w-full">
            <Combobox value={selectedItem ? list.find(elem => elem.id === selectedItem) : null} onChange={(r) => {
                onSelect(r);
            }}>

                <div className="relative ">


                    <label className="block text-sm font-medium mb-1 text-gray-700">
                        {selectLabel} {isRequired && <span className="text-red-500">*</span>}
                    </label>

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm ">
                        <Combobox.Input
                            id={'test'}
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize"
                            placeholder={t("app.common.placeholder", {
                                field: label.toLowerCase(),
                            })}
                            displayValue={(item) => item?.label ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoading ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {filteredData.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredData.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (

                                                        <>
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    <Initials
                                                                        color={'indigo'}
                                                                        name={item.label}
                                                                        isVerified={false}
                                                                    />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <div className="focus:outline-none">
                                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                                        <p className="text-sm font-medium text-gray-900">{item.label}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
};

export default DynamicSearchList;
