import {useTranslation} from "react-i18next";
import React from "react";

const InputRadioSimple = ({label, id, name, errors, warning, defaultChecked = false, input, value, description}) => {

    const { t } = useTranslation();

    return (
        <div className="relative flex items-start mb-3">

            <div className="flex items-center h-5">
                <input
                    id={id}
                    aria-describedby={`${id}-description`}
                    name={name}
                    type="radio"
                    defaultChecked={defaultChecked}
                    {...input}
                    value={value}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={id} className="font-medium text-gray-700">
                    {label}
                </label>
                <p id={`${id}-description`} className="text-gray-500">
                    {description}
                </p>
            </div>
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label.toLowerCase()})}</p>)}
        </div>
    )
};
export default InputRadioSimple;
