import React, {useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import FormPopup from "../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import { useForm } from "react-hook-form";
import InputCheckbox from "../../components/common/form/input-checkbox";
import {getWebPathParam} from "../../utils/converter";
import {REGISTRY, SUPPLIER} from "../endpoints";
import {useNavigate} from "react-router-dom";
import {postSupplier} from "../../api/supplier";
import {emailRegex} from "../../constants/regex";
import {loadSuppliers} from "../../reducers/connectionReducer";

export default function AddSupplier({ isOpen, setIsOpen }) {
  const { user } = useSelector((state) => state);
  const { loggedCompany } = user;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let navigate = useNavigate();

  // supplier
  const [isCreateLoading, setCreateLoading] = useState(false);
  const [exception, setException] = useState(false);

  const storeSupplier = async (data) => {
    if (loggedCompany) {
      setCreateLoading(true);
      postSupplier({ company_id: loggedCompany.id, data })
        .then((response) => {
          setCreateLoading(false);
          dispatch(loadSuppliers(loggedCompany.id));
          navigate(getWebPathParam([REGISTRY, SUPPLIER, response.id]));
        }).catch((e) => {
          setException(e.message);
          setCreateLoading(false);
      });
    }
  };




  const onSubmit = async (data) => {
    await storeSupplier(data);
  };



  return (
      <FormPopup
          title={t("app.supplier.new_supplier")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
              <InputText
                  id={"business_name"}
                  type={"text"}
                  errors={errors.business_name}
                  input={{ ...register("business_name", { required: true }) }}
                  label={t("app.form.business_name")}
              />

              <InputText
                  id={"exporter_reference"}
                  type={"text"}
                  errors={errors.exporter_reference}
                  input={{
                    ...register("exporter_reference", { required: true }),
                  }}
                  label={t("app.supplier.reference")}
              />
            </div>

            <div className={"col-span-2 mt-2"}>
              <InputCheckbox
                  label={t("app.supplier.connection_title")}
                  description={t("app.supplier.exporter_connection_description")}
                  id={"send_connection"}
                  input={{ ...register("send_connection", { required: false }) }}
              />
            </div>

            <div className={"col-span-2 mt-2"}>
              {watch("send_connection") && (
                  <InputText
                      id={"exporter_notification_email"}
                      type={"text"}
                      errors={errors.exporter_notification_email}
                      input={{
                        ...register("exporter_notification_email", { required: true, pattern: emailRegex }),
                      }}
                      label={"E-Mail"}
                  />
              )}
            </div>
          </div>

          {/* Warning */}
          {exception && <Warning message={exception} />}

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                isLoading={isCreateLoading}
                label={t("app.supplier.add_supplier")}
            />
          </div>
        </form>
      </FormPopup>
  );
}
