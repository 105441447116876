import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, { ActionButton } from "../../components/layout/page-header";
import {useDispatch, useSelector} from "react-redux";
import AddCustomer from "./add-customer";
import ActionsMenu from "../../components/common/table/actions-menu";
import {CONNECTION_OVERVIEW, CUSTOMER, REGISTRY} from "../endpoints";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import { useTranslation } from "react-i18next";
import CustomerCell from "../../components/common/table/customer-cell";
import {getPermission, permissionGroup, permissionSpecific, permissionType} from "../../constants/permissions";
import ListSection from "../../components/layout/list-section";
import OptionalLink from "../../components/common/table/optional-link";
import { getCustomersDynamic} from "../../api/customer";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import RemovePopup from "../../components/common/popup/remove-popup";
import {ARCHIVE, COMPANY} from "../../api/endpoints";
import ConfirmPopup from "../../components/common/popup/confirm-popup";
import {loadCustomers} from "../../reducers/connectionReducer";
import {STATUS_CONFIRMED, STATUS_PENDING} from "../../constants/config";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import {generateParamsFormLocation} from "../../constants/dynamic-filter";
import DynamicFilterMenu from "../../components/common/table/dynamic-filter-menu";
import {getFormattedDate} from "../../utils/timeUtils";
import { PlusIcon } from "@heroicons/react/outline";

const Customers = () => {
  const { loggedCompany } = useSelector((state) => state.user);

  const location = useLocation();

  const [isFetchLoading, setFetchLoading] = useState(true);
  const [activeCustomers, setActiveCustomers] = useState({customers: [], total_rows: 0});

  const [limit] = useState(30);

  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmDetail, setConfirmDetail] = useState({});

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [needRefresh, setNeedRefresh] = useState(false);
  const { permissions } = useSelector((s) => s.userCompany);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canView =  hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionType.VIEW);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionType.CREATE);

  const canArchive = hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionSpecific.CUSTOMER_ARCHIVE);
  const canRevoke = hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionSpecific.CUSTOMER_REVOKE);

  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [defaultParams, setDefaultParams] = useState(false);
  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});


  const columns = [
    {
      header:  t("app.customers.customers"),
      id: 'business_name',
      accessor: "business_name"
    },
    {
      header: t("app.customers.reference"),
      id: 'importer_reference',
      accessor: 'importer_reference',
    },
    {
      header: t("app.leads.last_contact_date"),
      id: 'importer_last_contact',
      accessor: r => new Date(r.importer_last_contact).getTime()
    },
    {
      header: "",
      accessor: null
    }
  ];




  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchCustomers() {
      if (loggedCompany) {
        setFetchLoading(true);
        try {
          const res = await getCustomersDynamic({
            company_id: loggedCompany.id,
            params: {
              limit: limit,
              offset: query.length > 0 ? 0 : offset,
              remove_default: !defaultParamsUsed,
              query: query
            },
            data: activeDynamicFilter,
            signal
          });

          setActiveCustomers(res);

          if(res.filters){
            setDynamicFilters(res.filters);
          }
          if(res.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
            setDefaultParams('?'+res.default_filter);
          }


          setNeedRefresh(false);
        } catch (e) {
          setActiveCustomers({ customers: [], total_rows: 0 });
          if(signal.aborted){
            setFetchLoading(true);
          }
        } finally {
          if(signal.aborted){
            setFetchLoading(true);
          }else{
            setFetchLoading(false);
          }
        }
      }
    }

    fetchCustomers();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [offset, query, loggedCompany, needRefresh, activeDynamicFilter, limit]);


  return (
      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CUSTOMER, permissionType.LIST)]} >

      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.customers.customers")}>
            {
              canCreate && (
                    <ActionButton
                        icon={
                          <PlusIcon
                              className="w-4 h-4 "
                          />
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        text={t("app.customers.add_customer")}
                    />
                )
            }
          </PageHeader>
        </div>


        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


          <ListSection
            title={t("app.customers.customers")}
            columns={columns}
            totalRows={activeCustomers.total_rows}
            list={activeCustomers.customers}
            onSearch={setQuery}
            isLoading={isFetchLoading}
            paginationOnChange={setOffset}
            mobileRow={CustomerMobRow}
            desktopRow={CustomerDeskRow}
            activeDynamicFilter={true}
            isTableSort={true}
            sortBy={sortBy}
            onSortBy={(n) => {
              setSortBy(n);
            }}
            extras={{
              canView,
              canRevoke,
              canArchive,
              onArchive: (r, detail) => {
                setConfirmDetail(detail);
                setOpenConfirm(r);
              },
              onRevoke: (r, detail) => {
                setRemoveDetail(detail);
                setOpenRemove(r);
              }
            }}
          >
            <DynamicFilterMenu
                filters={dynamicFilters}
                defaultParams={defaultParams}
                isLoading={isFetchLoading && offset === 0}
                filterType={'customer'}
                onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);
                }}
            />
          </ListSection>
        </div>
      </div>
      {(isOpen && canCreate) && <AddCustomer isOpen={isOpen} setIsOpen={setIsOpen} />}

      {isOpenConfirm && (
          <ConfirmPopup
              isOpen={isOpenConfirm}
              setIsOpen={setOpenConfirm}
              detail={confirmDetail}
              setDetail={() => {
                dispatch(loadCustomers(loggedCompany.id));
                setNeedRefresh(!needRefresh);
              }}
          />
      )}




      {isOpenRemove && (
          <RemovePopup
              isOpen={isOpenRemove}
              setIsOpen={setOpenRemove}
              detail={removeDetail}
              setDetail={(response) => {
                dispatch(loadCustomers(loggedCompany.id));
                setNeedRefresh(!needRefresh);
              }}
          />
      )}

      </BasePermissionWrapper>
  );
};

const CustomerMobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);

  const archiveDetail = {
    title: t("app.customers.archive_title"),
    message: t("app.customers.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, element.id, ARCHIVE])
  };

  const revokeDetail = {
    title: t("app.customers.remove_title"),
    message: t("app.customers.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, element.id])
  };


  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={extras.canView}
        to={getWebPathParam([REGISTRY, CUSTOMER, element.id, CONNECTION_OVERVIEW])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
          <CustomerCell customer={element} />

          {
            (extras.canArchive || extras.canRevoke || extras.canView) && (
                  <ActionsMenu>
                    {extras.canView && (
                  <Link to={getWebPathParam([REGISTRY, CUSTOMER, element.id, CONNECTION_OVERVIEW])}>
                          {t("app.common.edit")}
                        </Link>
                    )}

                    {extras.canArchive && ((element.status && element.status === STATUS_PENDING) || (!element.status)) && (
                        <button className={'text-left'} onClick={() => {
                          extras.onArchive(true, archiveDetail);
                        }}
                        >
                          {t("app.customers.archive")}
                        </button>
                    )}

                    {extras.canRevoke && (element.status && element.status === STATUS_CONFIRMED) && (
                        <button className={'text-left text-gray-700'} onClick={() => {
                          extras.onRevoke(true, revokeDetail);
                        }}
                        >
                          {t("app.customers.remove")}
                        </button>
                    )}

                  </ActionsMenu>
            )
          }
        </div>
        <div className="flex flex-col">

          <CardDetail title={t("app.customers.reference")}>
            {element.importer_reference}
          </CardDetail>

          <CardDetail title={t("app.leads.last_contact_date")}>
            {getFormattedDate(element.importer_last_contact)}
          </CardDetail>


        </div>
      </OptionalLink>
    </li>
  );
};

const CustomerDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();

  const { loggedCompany } = useSelector((s) => s.user);

  const archiveDetail = {
    title: t("app.customers.archive_title"),
    message: t("app.customers.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, element.id, ARCHIVE])
  };

  const revokeDetail = {
    title: t("app.customers.remove_title"),
    message: t("app.customers.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, element.id])
  };

  return (
    <tr key={element.id}>
      <TableDetail extraClass="max-w-sm">
        <OptionalLink
          isLinked={extras.canView}
          to={getWebPathParam([REGISTRY, CUSTOMER, element.id, CONNECTION_OVERVIEW])}
          className="truncate hover:text-gray-600"
        >
          <CustomerCell customer={element} />
        </OptionalLink>
      </TableDetail>

      <TableDetail>
        {element.importer_reference}
      </TableDetail>

      <TableDetail>
        {getFormattedDate(element.importer_last_contact)}
      </TableDetail>

      <TableDetail>
        {
            (extras.canArchive || extras.canRevoke || extras.canView) && (
                <ActionsMenu>
                  {extras.canView && (
                <Link to={getWebPathParam([REGISTRY, CUSTOMER, element.id, CONNECTION_OVERVIEW])}>
                        {t("app.common.edit")}
                      </Link>
                  )}

                  {extras.canArchive && ((element.status && element.status === STATUS_PENDING) || (!element.status)) && (
                      <button className={'text-left'} onClick={() => {
                        extras.onArchive(true, archiveDetail);
                      }}
                      >
                        {t("app.customers.archive")}
                      </button>
                  )}

                  {extras.canRevoke && (element.status && element.status === STATUS_CONFIRMED) && (
                      <button className={'text-left text-gray-700'} onClick={() => {
                        extras.onRevoke(true, revokeDetail);
                      }}
                      >
                        {t("app.customers.remove")}
                      </button>
                  )}

                </ActionsMenu>
            )
        }
      </TableDetail>
    </tr>
  );
};

export default Customers;
