import React, {useEffect, useState} from "react";
import FormPopup from "../../components/common/popup/form-popup";
import {useTranslation} from "react-i18next";
import InputTextBtn from "../../components/common/form/input-text-btn";
import {emailRegex} from "../../constants/regex";
import {PaperAirplaneIcon} from "@heroicons/react/outline";
import {useForm} from "react-hook-form";
import {putCustomerConnection} from "../../api/customer";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

export default function SendRequest({ isOpen, setIsOpen, isDisabled, onConfirm, email, isExporter = true }) {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [isSendingMail, setSendingMail] = useState(false);
    let { id } = useParams();

    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ mode: "onBlur" });



    useEffect(() => {
        if(email){
            setValue('notification_email', email);
        }
    }, [setValue, email]);



    const sendMail = async () => {
        if (loggedCompany) {
            setSendingMail(true);
            putCustomerConnection({
                company_id: loggedCompany.id,
                customer_id: id,
                data: { id: id, notification_email: watch("notification_email"), resend_mail: true },
            }).then((response) => {
                setSendingMail(false);
                toast.success(t("app.customers.send_success"));
                onConfirm(response);
                setIsOpen(false);
            }).catch((e) => {
                setSendingMail(false);
            });
        }
    };

  return (
      <FormPopup
          title={t("app.form.email")}
          isOpen={isOpen}
          showHeader={false}
          setIsOpen={(r) => {
              setIsOpen(r);
          }}
      >
          <form className='pb-16'>
              <div className="space-y-4">
                  <div className="grid  gap-y-5 gap-x-5 grid-cols-1 text-center">
                        <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                          {t("app.customers.connection_title")}
                      </h2>
                        <p className="mt-1 max-w-2xl mx-auto text-center text-md text-gray-500">
                            {
                                isExporter ? (
                                    t("app.customers.connection_description")
                                ) : (
                                    t("app.supplier.exporter_connection_description")
                                )
                            }
                      </p>


                      <div className="relative mx-8 md:mx-24 ">
                          <InputTextBtn
                              id={"notification_email"}
                              type={"text"}
                              errors={errors.notification_email}
                              input={{
                                  ...register("notification_email", {
                                      required: false,
                                      pattern: emailRegex
                                  }),
                              }}
                              isDisabled={isDisabled}
                              label={t("app.form.email")}
                              btnLabel={''}
                              isActive={watch("notification_email")?.length  > 0 ?? false}
                              isLoading={isSendingMail}
                              onClick={() => {
                                  if (watch('notification_email').match(emailRegex)) {
                                      sendMail();
                                  }
                              }}
                              icon={PaperAirplaneIcon}
                          />
                      </div>

                  </div>
              </div>
          </form>
      </FormPopup>
  );
}
