import React, {useEffect, useState} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../utils/converter";
import {COMPANY, PREFIX, PREFIXES} from "../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {QrcodeIcon} from "@heroicons/react/outline";
import CustomFormatter from "../../../../components/common/form/custom-formatter";
import ConfirmTextPopup from "../../../../components/common/popup/confirm-text-popup";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";


const getCurrentYear = () => new Date().getFullYear().toString().slice(-2);

const CompanyPreference = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_DOCUMENT_NUMBERING)]} renderBaseOnFail={true} >

        <>

            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.company_preference.type"),
                            t("app.company_preference.incrementer"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, PREFIXES])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, PREFIX, ':id'])}
                        baseResult={[]}
                        title={t("app.company_preference.type")}
                        showHeader={false}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        icon={QrcodeIcon}
                        editForm={EditCode}
                        editTitle={t("app.company_preference.numbering")}
                        isActiveEdit={true}
                        isActiveAdd={false}
                        SlideDefaultWith={'max-w-2xl'}

                    />
                )
            }

        </>
        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();


    const formatTagString = (tagString) => {
        const formattedString = tagString
            .replaceAll('%first_prefix%', element.first_prefix)
            .replaceAll('%second_prefix%', element.second_prefix)
            .replaceAll('%year%', getCurrentYear())
            .replaceAll('%incrementer%', 'X'.repeat(element?.number_digit ?? 5));
        return formattedString;
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.company_preference.type")}>
                        {t("app.company_preference."+element.type)}
                    </CardDetail>

                    <CardDetail title={t("app.company_preference.incrementer")}>
                        {formatTagString(element.full_prefix)}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();


    const formatTagString = (tagString) => {
        const formattedString = tagString
            .replaceAll('%first_prefix%', element.first_prefix)
            .replaceAll('%second_prefix%', element.second_prefix)
            .replaceAll('%year%', getCurrentYear())
            .replaceAll('%incrementer%', 'X'.repeat(element?.number_digit ?? 5));
        return formattedString;
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {t("app.company_preference."+element.type)}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {formatTagString(element.full_prefix)}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};


const EditCode = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm();


    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [sendData, setSendData] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                setValue(k, data[k]);
            }
        }

        if(!watch('number_digit')){
            setValue('number_digit', 5);
        }
    // eslint-disable-next-line
    }, [setValue, data]);




    useEffect(() => {
        if(watch('first_prefix')){
            setValue('first_prefix', watch('first_prefix').replace(/%|first_prefix|second_prefix|year|incrementer/g, ''));
        }
        if(watch('second_prefix')){
            setValue('second_prefix', watch('second_prefix').replace(/%|first_prefix|second_prefix|year|incrementer/g, ''));
        }
        if(watch('number_digit') &&  watch('number_digit') > 10){
            setValue('number_digit', 10);
        }

        if((watch('number_digit') &&  watch('number_digit') < 0) || !watch('number_digit')){
            setValue('number_digit', 0);
        }

    // eslint-disable-next-line
    }, [setValue, watch('first_prefix'), watch('second_prefix'), watch('number_digit')]);

    const onSubmit = async (data) => {
        setSendData(data);
        setOpenConfirm(true);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-md font-medium text-gray-900">
                        {t("app.company_preference.numbering_title")}
                    </h3>

                    <div className="space-y-4 pt-4 pb-5">

                        <InputText
                            id={"first_prefix"}
                            type={"text"}
                            isDisabled={data.closed}
                            errors={errors.first_prefix}
                            input={{ ...register("first_prefix", { required: false }) }}
                            label={t("app.company_preference.prefix_1")}
                        />

                        <InputText
                            id={"second_prefix"}
                            type={"text"}
                            isDisabled={data.closed}
                            errors={errors.second_prefix}
                            input={{ ...register("second_prefix", { required: false }) }}
                            label={t("app.company_preference.prefix_2")}
                        />

                        <InputText
                            id={"number_digit"}
                            type={"text"}
                            isDisabled={data.closed}
                            errors={errors.number_digit}
                            input={{ ...register("number_digit", { required: false }) }}
                            label={t("app.company_preference.sequential_number")}
                        />


                        {
                            !data.closed && (
                                <InputText
                                    id={"start_number"}
                                    type={"text"}
                                    errors={errors.start_number}
                                    input={{ ...register("start_number", { required: false }) }}
                                    label={t("app.company_preference.next_sequence")}
                                />
                            )
                        }



                        <div>

                            <label className="block text-sm font-medium mb-1 text-gray-700">
                                {t("app.company_preference.sort_data")}
                            </label>

                            <CustomFormatter
                                isClosed={data.closed}
                                fullPrefix={data.full_prefix}
                                firstPrefix={watch('first_prefix') ?? ''}
                                numberDigit={watch('number_digit') ?? 5}
                                secondPrefix={watch('second_prefix') ?? ''}
                                previewTitle={t("app.company_preference.preview")}
                                onChangePrefix={(r) => {
                                    setValue('full_prefix', r);
                                }}
                            />
                        </div>

                    </div>
                </div>

                {
                    !data.closed && (
                        <>

                            {/* Warning */}
                            {exception && (
                                <Warning message={exception} />
                            )}

                            <div className="mt-3">
                                <InputSubmit
                                    isLoading={isEditLoading}
                                    isFullWith={true}
                                    label={t("app.form.save")}
                                />
                            </div>
                        </>
                    )
                }

            </form>



            {isOpenConfirm && (
                <ConfirmTextPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={setOpenConfirm}
                    detail={{title: t("app.company_preference.reset_title"), message: t("app.company_preference.reset_description")}}
                    onConfirmed={(r) => {
                        if(r){
                            onSubmitData(sendData);
                            setSendData(false);
                        }else{
                            setSendData(false);
                        }
                        setOpenConfirm(false);
                    }}
                />
            )}

        </>
    );
}

export default CompanyPreference;
