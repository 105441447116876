import React, {useState, useRef, useCallback} from 'react'
import FormPopup from "../../../components/common/popup/form-popup";
import SignatureCanvas from "react-signature-canvas";
import StepsTabs from "../../common/steps/steps-tabs";
import UploadFile from "./upload-file";
import {useTranslation} from "react-i18next";
import {getSignedRequests} from "../../../api/config";
import {sendMedia} from "../../../utils/apiUtils";
import SimpleButtonSubmit from "../../common/form/simple-button-submit";


export default function GenerateSignature({isOpen, setIsOpen, onSave, widthRatio = 500}) {

    const { t } = useTranslation();

    const DRAW = t("app.common.draw");
    const UPLOAD = t("app.common.upload");

    const [activeCategory, setActiveCategory] = useState(UPLOAD);
    const [loading, setLoading] = useState(false);


    const [fileUrl, setFileUrl] = useState(false);
    const sigCanvas = useRef({});
    const sigPad = useRef({});

    const uploadFile = async (file)  => {
        setLoading(true);
        getSignedRequests({params: {ctype: file.type ,ext: file.name.split(".").pop()}})
            .then( signed => {
                sendMedia(signed.action, signed, file)
                    .then( r => {
                        let url = signed.action + '/' + signed.key;
                        setFileUrl(url);
                        onSave(url);
                        setLoading(false);
                        setIsOpen(false);
                    }).catch(e => {
                    setLoading(false);
                });
            }).catch(e => {
            setLoading(false);
        });
    };


    const saveInput = () => {
        const dataURI = sigCanvas.current.toDataURL();

        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const file = new File([new Blob([ab], {type: mimeString})], 'signature.png', {type:"image/png", lastModified:new Date()});
        uploadFile(file);
    }

    const clearInput = () => sigPad.current.clear()

   /* const sleep = ms => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }*/


    const measuredRef = useCallback(async node => {

        //await sleep(50);
        const resizeCanvas = (signaturePad, canvas) => {
            canvas.width = canvas.parentElement.clientWidth // width of the .canvasWrapper
            canvas.height = widthRatio / 2
            signaturePad.clear()
        }

        if (node !== null) {
            sigCanvas.current = node.getCanvas()
            sigPad.current = node.getSignaturePad()
            resizeCanvas(node.getSignaturePad(), node.getCanvas())
        }
    }, [widthRatio]);


    return (

        <FormPopup title={'Import File'} isOpen={isOpen} setIsOpen={(r) => {setIsOpen(r)}}>

            <StepsTabs
                onActiveCategories={(r) => {
                    setFileUrl(false);
                    setActiveCategory(r);
                }}
                activeCategory={activeCategory}
                categories={[{name: UPLOAD}, {name: DRAW}]}

            >

                {
                    activeCategory === UPLOAD && (
                        <>
                            <UploadFile
                                accept={["image/png","image/jpg","image/jpeg"]}
                                size={'10mb'}
                                fileTypeLabel={'JPG, PNG'}
                                onUpload={(data) => {
                                    setFileUrl(data.media_url);
                                }}
                                onCancel={(r) => {
                                    if(r){
                                        setFileUrl(false);
                                    }
                                }}
                                cancel={false}
                                onException={(e) => {}}
                                isLoading={false}
                                label={''}
                            />

                            {
                                fileUrl && (
                                    <div className="flex before:flex-1 justify-between mt-2">
                                        <div
                                            className="btn cursor-pointer bg-indigo-800 hover:bg-indigo-700 text-white"
                                            onClick={() =>{
                                                onSave(fileUrl);
                                                setIsOpen(false);
                                            }}
                                        >
                                            <span>{t("app.common.confirm")}</span>
                                        </div>

                                    </div>
                                )
                            }




                        </>
                    )
                }

                {
                    activeCategory === DRAW && (
                        <div className="space-y-4">

                            {
                                measuredRef  && (
                                    <>
                                        <div className="canvasWrapper w-full">
                                            <SignatureCanvas canvasProps={{style: { borderRadius: "10px", border: "3px dotted #d1d5db" }}} ref={measuredRef} />
                                        </div>

                                        <div className="flex before:flex-1 items-center justify-between mt-2">
                                            <div>
                                                {!loading && (
                                                    <div
                                                        className="btn cursor-pointer bg-red-400 hover:bg-red-500 text-white mr-2"
                                                        onClick={clearInput}
                                                    >
                                                        <span>{t("app.common.remove")}</span>
                                                    </div>
                                                )}

                                                <SimpleButtonSubmit
                                                    onClick={saveInput}
                                                    label={t("app.common.confirm")}
                                                    isLoading={loading}
                                                />

                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    )
                }

            </StepsTabs>

        </FormPopup>
    )
}
