import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {CONNECTION, DASHBOARD, P404} from "../../endpoints";
import Loader from "../../../components/common/loader/loader";
import { StatusBar } from "../../../components/common/alert/status-bar";
import { getConnection } from "../../../api/connection";
import InputRemove from "../../../components/common/form/input-remove";
import {getPathParam} from "../../../utils/converter";
import {COMPANY} from "../../../api/endpoints";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {clearData} from "../../../utils/cacheUtil";

const ConnectionDetail = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get container
  const [isLoading, setLoading] = useState(true);
  const [connectionDetail, setConnectionDetail] = useState("");

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchConnection() {
      if (loggedCompany) {
        setLoading(true);
        getConnection({ company_id: loggedCompany.id, signal })
          .then((response) => {
            setConnectionDetail(response);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            e.code === 404 && navigate(P404);
          });
      }else{
        navigate(DASHBOARD);
      }
    }
    fetchConnection();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, loggedCompany]);

  return (
      <div className="shadow  sm:overflow-hidden">
        <div>
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("app.connection.information")}
              </h3>
            </div>

            <fieldset>
              <div className="mt-2 space-y-4">
                {isLoading ? (
                    <div className="mt-6">
                      <div className="w-1/2 m-auto px-4 py-3">
                        <Loader />
                      </div>
                    </div>
                ) : (
                    <>


                      <dl className="mt-2 border-t  divide-y divide-gray-200">
                        <div className="py-3 flex justify-between text-sm font-medium items-center">
                          <dt className="text-gray-500">{t("app.connection.business_name")}</dt>
                          <dd className="text-gray-900">
                            {connectionDetail.company.business_name}
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium items-center">
                          <dt className="text-gray-500">{t("app.connection.connection_status")}</dt>
                          <dd className="text-gray-900">
                            <StatusBar
                                status={connectionDetail?.status === "confirmed" ? "success" : "processing"}
                                title={connectionDetail?.status === "confirmed"  ? t("app.users.confirmed_connection") : t("app.users.waiting_connection")}
                            />
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium items-center">
                          <dt className="text-gray-500">{t("app.connection.type")}</dt>
                          <dd className="text-gray-900">
                            {connectionDetail.connection_type === 'agent' ? t("app.users.connection_agent") : t("app.users.connection_employee")}
                          </dd>
                        </div>
                      </dl>


                    </>
                )}
              </div>
            </fieldset>

          </div>
          <div className={"px-4 py-3 bg-solitude-100 text-right sm:px-6 flex items-center justify-between before:flex-1"}>
            <InputRemove isLoading={false} label={t("app.connection.remove_title")} onClick={() => {
              const removeDetail = {
                title: t("app.connection.remove_title"),
                message: t("app.connection.remove_description"),
                endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION])
              };

              setRemoveDetail(removeDetail);
              setOpenRemove(true);
            }} />
          </div>
        </div>

        {isOpenRemove && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                onRemoveConfirmed={  () => {
                  clearData('loggedCompany');
                  clearData('loggedConnection');
                  clearData('companyType');
                  window.location.reload();
                }}
            />
        )}
      </div>
  );
};

export default ConnectionDetail;
