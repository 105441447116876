import React, {useEffect, useState} from "react";
import SlidePanel from "../../../../../components/layout/slide-panel";
import {
    getExportFilter,
    getExportFilterCount
} from "../../../../../api/import";
import {useSelector} from "react-redux";
import {Warning} from "../../../../../components/common/alert/banner";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../../../components/common/form/button-submit";
import DynamicFilterMenu from "../../../../../components/common/table/dynamic-filter-menu";
import {putElement, getElement} from "../../../../../api/config";
import {getPathParam} from "../../../../../utils/converter";
import {COMPANY, PERMISSION, PERMISSION_GROUP} from "../../../../../api/endpoints";
import toast from "react-hot-toast";

export default function PermissionFilter({ isOpen, setIsOpen, filterType, groupDetail }) {

    const { loggedCompany } = useSelector((state) => state.user);

    // Load Process
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [loadingSavedFilter, setLoadingSavedFilter] = useState(true);
    const { t } = useTranslation();

    //Filter
    const [dynamicFilters, setDynamicFilters] = useState([]);
    const [isLoadingFilters, setLoadingFilters] = useState(false);
    const [urlDataFields, setUrlDataFields] = useState(null);
    const [activeDynamicFilter, setDynamicDateFilter] = useState([]);
    const [finaURL, setFinaURL] = useState(false);

    const [isLoadingCounter, setLoadingCounter] = useState(true);
    const [counters, setCounters] = useState(0);



    const filtersTypes = [
        {
            value: 'catalog.product.list',
            type: 'product_detail'
        }
    ];





    const putFilter = async ()  => {
        setLoading(true);
        putElement(getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, groupDetail.id, PERMISSION, filterType.id]), {id:filterType.id, filter: activeDynamicFilter, final_url: finaURL}).then(async (response) => {
            // setPermissions(response);
            toast.success(t("app.permissions.permission_update"));
            setLoading(false);

            await clean();
        }).catch((e) => {
            setLoading(false);
        });
    };


    useEffect(() => {
        const getSavedFilter = async ()  => {
            setLoadingSavedFilter(true);
            if(loggedCompany && isOpen){
                setLoading(true);
                getElement(getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUP, groupDetail.id, PERMISSION, filterType.id])).then((response) => {
                    setUrlDataFields(response.final_url)
                    setDynamicDateFilter(response.filters)
                    setLoadingSavedFilter(false);
                }).catch((e) => {
                    setLoadingSavedFilter(false);
                });
            }
        };

        getSavedFilter();
        // eslint-disable-next-line
    }, [loggedCompany, isOpen]);


    useEffect(() => {
        const getFilter = async ()  => {
            setLoadingFilters(true);
            setException(false);
            setDynamicFilters([]);
            if(loggedCompany && isOpen && loadingSavedFilter === false){
                getExportFilter({company_id: loggedCompany.id, import_type: filtersTypes.find(r => r.value === filterType.name).type})
                    .then(response => {
                        setDynamicFilters(response);
                        setLoadingFilters(false);
                    }).catch(e => {
                    setException(e.message);
                    setLoadingFilters(false);
                });
            }
        };

        getFilter();
        // eslint-disable-next-line
    }, [loggedCompany, isOpen, loadingSavedFilter]);



    useEffect(() => {

        const getFilterCount = async ()  => {
            if(loggedCompany &&  isOpen && loadingSavedFilter === false){
                setLoadingCounter(true);
                setException(false);
                getExportFilterCount({
                    company_id: loggedCompany.id,
                    import_type: filtersTypes.find(r => r.value === filterType.name).type,
                    data: activeDynamicFilter
                })
                    .then(response => {
                        setCounters(response.count);
                        setLoadingCounter(false);
                        setLoading(false);
                    }).catch(e => {
                    setException(e.message);
                    setLoadingCounter(false);
                });
            }
        };

        getFilterCount();
        // eslint-disable-next-line
    }, [loggedCompany, activeDynamicFilter, isOpen, loadingSavedFilter]);


    const clean = async () => {
        setDynamicFilters([]);
        setDynamicDateFilter([]);
        setException(false);
        setLoadingCounter(true);
        await setIsOpen(false)
    };


    return (
        <>

            <SlidePanel
                title={t("app.products.filter_title")}
                bg={"bg-white"}
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsOpen(false)
                }}
                size={'max-w-4xl'}
                setClean={() => {
                    clean();
                }}

            >
                <div className="pt-6 pb-5">

                    {
                        dynamicFilters.length > 0 && (
                            <>
                                <label className="block text-sm font-medium mb-1 text-gray-700">{t("app.products.filter_sub_title")}</label>
                                <div className="flex flex-wrap gap-4 ">
                                    <DynamicFilterMenu
                                        isActiveHeaderParams={false}
                                        isActiveUrlParams={true}
                                        filters={dynamicFilters}
                                        isLoading={isLoadingFilters}
                                        urlDataFields={urlDataFields}
                                        onChange={(r) => {
                                            setDynamicDateFilter(r);
                                        }}
                                        onFinalUrl={(r) => {
                                            setFinaURL(r);
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }


                    {/* Warning */}
                    {exception && <Warning message={exception} />}


                    <div className="mt-4 mb-3">
                        <ButtonSubmit
                            isLoading={(isLoadingCounter || isLoading)}
                            label={counters > 0 ? t("app.common.confirm_filter", {counter: counters}) : t("app.common.no_export")}
                            isFullWith={true}
                            onClick={() => {
                                putFilter();
                            }}
                            disabled={counters === 0}
                        />
                    </div>

                </div>
            </SlidePanel>

        </>
    );
}




