import {
    SUBSCRIPTIONS,
    SUBSCRIPTION_CONFIRM,
    CHECKOUT,
    PAYMENT_INTENT,
    SUBSCRIPTION_CHANGE,
    SUBSCRIPTION_PAYMENT,
    SUBSCRIPTION_BILLING,
    SUBSCRIPTION_CANCEL,
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_USAGE, PROPOSAL, SUBSCRIPTION_PAY, COMPANY, OFFLINE_SECRET, SUBSCRIPTION_DOWNGRADE_CHECK
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam} from "../utils/converter";

// customer
export const getSubscriptions = ({signal}) =>
    API.get(getPathParam([SUBSCRIPTIONS]), {signal: signal});

export const getSubscriptionConfirmCheckout = ({session_id, signal}) =>
    API.get(getPathParam([SUBSCRIPTION_CONFIRM, CHECKOUT, session_id]), {signal: signal});

export const getSubscriptionConfirmPaymentIntent = ({payment_intent_id, signal}) =>
    API.get(getPathParam([SUBSCRIPTION_CONFIRM, PAYMENT_INTENT, payment_intent_id]), {signal: signal});

export const getSubscriptionConfirmOfflineSecret = ({offline_secret_id, signal}) =>
    API.get(getPathParam([SUBSCRIPTION_CONFIRM, OFFLINE_SECRET, offline_secret_id]), {signal: signal});

export const putSubscriptionPayments = ({subscription_id}) =>
    API.put(getPathParam([SUBSCRIPTION_PAYMENT, subscription_id]), {});

export const getSubscriptionDowngradeCheck = ({subscription_id}) =>
    API.get(getPathParam([SUBSCRIPTION_DOWNGRADE_CHECK, subscription_id]));

export const putSubscriptionChange = ({subscription_id}) =>
    API.put(getPathParam([SUBSCRIPTION_CHANGE, subscription_id]), {});

export const getSubscriptionBilling = () =>
    API.get(getPathParam([SUBSCRIPTION_BILLING]));

export const putCancelSubscription = () =>
    API.put(getPathParam([SUBSCRIPTION_CANCEL]), {});

export const putActiveSubscription = () =>
    API.put(getPathParam([SUBSCRIPTION_ACTIVE]), {});

export const postProposalFrom = ({data}) =>
    API.post(getPathParam([PROPOSAL]), data);

export const getSubscriptionUsage = ({company_id}) =>
    API.get(getPathParam([COMPANY, company_id, SUBSCRIPTION_USAGE]));

export const putPaySubscription = ({subscription_id}) =>
    API.put(getPathParam([SUBSCRIPTION_PAY, subscription_id]), {});
