import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BaseRestricted from "./subscription-restricted";
import UsageRestricted from "./usage-restricted";
import {ACCOUNT, SUBSCRIPTION} from "../../../routes/endpoints";
import {getWebPathParam} from "../../../utils/converter";
import LoaderWrapper from "../../common/loader/loader-wrapper";

// @requiredPermissions is an array of permissions where the type of the permission is optional (e.g. both order.view or order are valid permissions)
// @renderBaseOnFail : bool if true render BaseRestricted component otherwise null
// @adminHasAccess : bool if a user with role admin has automatically access on this resource
// @requireExporter : bool if true require the user connected to be in exporter mode
// @requireImporter : bool if true require the user connected to be in importer mode
// @redirectWrongCompanyType : bool if true redirect user to dashboard if a specific required company mode is not satisfied
const SubscriptionWrapper = ({
  requiredPermission = null,
  renderBaseOnFail = false,
  redirectOnFail = false,
  checkUsage = false,
  children,
}) => {
  const { subscriptions, permissions } = useSelector((s) => s.userCompany);
  const { subscriptionUsage, isLoadingUsage } = useSelector((state) => state.subscription );
  const navigate = useNavigate();
  // if true show children, otherwise show its value
  const [shouldShow, setShouldShow] = useState(null);
  const [showUsage, setShowUsage] = useState(false);

  useEffect(() => {

    setShouldShow(null);
    setShowUsage(false);

    if (requiredPermission === null) {
      setShouldShow(true);
      return;
    }

    const hasAccess =
        (subscriptions ?? []).filter(r => r === requiredPermission).length > 0
    ;

    if(checkUsage && hasAccess){
      if(subscriptionUsage && subscriptionUsage.length > 0){
        let dataUsage = subscriptionUsage.find(r => r.package.code === requiredPermission);

        if(dataUsage){
          if(dataUsage.unlimited){
            setShouldShow(true);
            return;
          }else{
            if(dataUsage.used_limit >= dataUsage.usage_limit){
              setShowUsage(true);
            }else{
              setShowUsage(false);
            }
          }
        }
      }
    }


    if (hasAccess) {
      setShouldShow(true);
    } else {
      if (redirectOnFail && (permissions?.isSuperAdmin ?? false)) {
        navigate(getWebPathParam([ACCOUNT, SUBSCRIPTION]));
      }
      setShouldShow(renderBaseOnFail ? <BaseRestricted /> : null);
    }
  }, [
    navigate,
    renderBaseOnFail,
    redirectOnFail,
    requiredPermission,
    subscriptions,
    subscriptionUsage,
    checkUsage,
    permissions
  ]);

  if(checkUsage && isLoadingUsage){
    return <LoaderWrapper isLoading={isLoadingUsage} />;
  }

  if(showUsage){
    return renderBaseOnFail ? <UsageRestricted /> : <></>;
  }

  // careful must be exactly equal (===) to true
  return shouldShow === true ? <>{children}</> : shouldShow;
};


// generate function to filter array according to some permissions
// @field : string | function field is used to get the id from the element of the array to field
export const hasSubscriptionPermission = (
    subscriptions,
    requiredPermission = null,
) => {
  // eslint-disable-next-line
  return  (subscriptions ?? []).filter(r => r == requiredPermission).length > 0;
};


export default SubscriptionWrapper;
