import React, { useEffect, useState } from "react";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    P404,
    REGISTRY,
    FORWARDER,
    C_FORWARDER_CONTACT,
    C_FORWARDER_LINES
} from "../endpoints";
import {getPathParam} from "../../utils/converter";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import {COMPANY} from "../../api/endpoints";
import {fetchCountries, fetchCurrencies, fetchLanguages} from "../../reducers/configReducer";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import { GenericHeaderNavigation, GenericPanelNavigation } from "../../components/partials/panel/generic-panel-navigation";
import { getForwarder } from "../../api/forwarder";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ForwarderOverview = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  // get details
  const [isLoading, setLoading] = useState(true);
  const [forwarderDetail, setForwarderDetail] = useState(false);
  const [typeActive, setTypeActive] = useState(null);

  // update customer
  const [isSidebarOpen, setOpenSidebar] = useState(false);


  const fetchForworder = (signal = null, loading = true) => {
      if (loggedCompany) {
          setLoading(true);
          getForwarder({ company_id: loggedCompany.id, forwarder_id: id, signal })
              .then((response) => {
                  setForwarderDetail(response);
                  setLoading(false);
              })
              .catch((e) => {
                  e.code === 404 && navigate(P404);
                  setLoading(false);
              });
      }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchForworder(signal);

    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchLanguages());

    return () => {
      controller.abort();
    };
      // eslint-disable-next-line
  }, [id, loggedCompany, dispatch]);

    const secondaryNavigation = [
        { name: t("app.forwarder.contacts"), type: 'contacts', location: getPathParam([REGISTRY, FORWARDER, id, C_FORWARDER_CONTACT]), current: pathname.includes(getPathParam([REGISTRY, FORWARDER, id, C_FORWARDER_CONTACT])) },
        { name: t("app.forwarder.traffic_line"), type: 'note', location: getPathParam([REGISTRY, FORWARDER, id, C_FORWARDER_LINES]), current: pathname.includes(getPathParam([REGISTRY, FORWARDER, id, C_FORWARDER_LINES])) }
    ].filter(Boolean);


  return (
      <BasePermissionWrapper
          requiredPermissions={[getPermission(permissionGroup.COMPANY_FORWARDER, permissionType.VIEW)]}
      >

        <LoaderWrapper isLoading={isLoading}>



          <div className="flex h-full">

            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

              <div className="relative z-0 flex flex-1 overflow-hidden">


                  <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">


                  {/* Start main area*/}
                  <div className="absolute inset-0 ">

                    <GenericHeaderNavigation canEdit={true} data={forwarderDetail} canSendRequest={true} onOpenSidebar={(r) => {setOpenSidebar(r)} } isSidebarOpen={isSidebarOpen} />
                    
                    
                    <div className="bg-white border-b border-gray-200">
                          <nav className=" flex space-x-8 max-w-7xl mx-auto justify-center overflow-x-auto px-4 leading-6   sm:px-6 lg:px-8" aria-label="Tabs">
                              {secondaryNavigation.map((tab) => (
                                  <Link
                                      key={tab.name}
                                      to={'/'+tab.location}
                                      className={classNames(
                                          tab.current
                                              ? 'border-sky-400 text-blue-1000'
                                              : 'border-transparent text-blue-1000/[0.5] hover:text-blue-1000 hover:border-sky-400',
                                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-xs uppercase'
                                      )}
                                  >
                                      {tab.name}
                                  </Link>
                              ))}
                          </nav>
                      </div>

                     <Outlet
                          context={{
                              data: forwarderDetail,
                              entityId: forwarderDetail.id,
                              typeActive: typeActive,
                              canEdit: true,
                              removeActive: () => {
                                  setTypeActive(null);
                              },
                              onUpdateData: (r) => {
                                  setForwarderDetail(forwarderDetail);
                              },
                              onRefresh: (r) => {
                                  fetchForworder(null, false);
                              }
                          }}
                      />
                  


                  </div>
                  {/* End main area */}
                </main>


                <GenericPanelNavigation
                    entity={forwarderDetail}
                    data={forwarderDetail}
                    canEdit={true}
                    onOpenSidebar={(r) => { setOpenSidebar(r) }}
                    isSidebarOpen={isSidebarOpen}
                    onResponse={(response) => {
                        setForwarderDetail(response);
                    }}
                    detailGroups={[
                        {
                            endpoint: getPathParam([COMPANY, loggedCompany?.id ?? '', FORWARDER, forwarderDetail?.id ?? '']),
                            isCreate: false,
                            refId: forwarderDetail?.id ?? '',
                            defaultOpen: true,
                            title: t("app.customers.customer_info"),
                            fields: [
                                { field: 'business_name', translation: 'app.customers.business_name', value: forwarderDetail?.name ?? '-', viewType: 'string', formType: 'string', required: true },
                                { field: 'code', translation: 'app.customers.reference', value: forwarderDetail?.code ?? '-', viewType: 'string', formType: 'string', required: false },
                            ]
                        }
                    ]}
                />
              </div>
            </div>
          </div>

        </LoaderWrapper>

      </BasePermissionWrapper>
  );
};

export default ForwarderOverview;
