import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import { useTranslation } from "react-i18next";
import ButtonSubmit from "../../components/common/form/button-submit";
import { putElement } from "../../api/config";
import { getPathParam, makeid } from "../../utils/converter";
import { BOARD, COMPANY } from "../endpoints";
import { Warning } from "../../components/common/alert/banner";
import BoardPopup from "../../components/board/board-popup";
import UploadFile from "../../components/partials/upload/upload-file";
import { useSelector } from "react-redux";
import InputSelect from "../../components/common/form/input-select";
import BoardReletedEntities from "../../constants/config";
import InputToggle from "../../components/common/form/input-toggle";
import MemberManage from "./member-manage";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import Nodata from "../../resources/images/no-data.gif";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')

const BoardManage = ({ isOpen, setIsOpen, boardData, onUpdateBoard  }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const [dynamicProperties, setDynamicProperties] = useState(boardData?.properties ?? []);

    const relationEntities = BoardReletedEntities();

    const [isLoading, setLoading] = useState(false)
    const [exception, setException] = useState(false)
    const [cleanData, setCleanData] = useState(false);
    const [activeGroup, setActiveGroup] = useState("general-information");
    const { t } = useTranslation();
    const [checkList, setCheckList] = useState(boardData?.check_list ?? []);


    const tabs = [
        { name: t("app.shippings.general_information"), group: "general-information" },
        (dynamicProperties?.length  ?? 0) > 0 && { name: t("app.board.property_t"), group: "properties" },
        { name: t("app.board.check_list"), group: "checklists" },
    ].filter(Boolean);


    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        control,
        watch
    } = useForm();




    const onUpdate = async (data) => {

        let toRemove = (boardData.board_users ?? []);


        if (data['users_data']){
            data['assigned_users'] = { to_add: [], to_remove: [] };
            if (data['users_data'].length > 0) {
                data['assigned_users']['to_add'] = data['users_data'];
                toRemove = (boardData.board_users ?? []).filter(obj1 => !data['users_data'].some(obj2 => obj1.user.id === obj2.user.id));
            }
            data['assigned_users']['to_remove'] = toRemove;
        }

        


        if (data['accosciate_entity']){
            data['accosciate_entity'] = data['accosciate_entity'].value;
        }

        if (dynamicProperties.length > 0) {
            data['properties'] = dynamicProperties;
        }else{
            data['properties'] = [];
        }


        if (checkList.length > 0) {
            data['check_list'] = checkList;
        }else{
            data['check_list'] = [];
        }


        setLoading(true);
        setException(false);
        setCleanData(false);
        putElement(getPathParam([COMPANY, loggedCompany.id, BOARD, boardData.id]), data)
        .then(response => {
            setCleanData(true);
            onUpdateBoard(response);
            setIsOpen(false);
            cleanForm();
        }).catch(e => {
            setException(e.message);
            setLoading(false);
        });
    };


    useEffect(() => {
        if (boardData) {
            for (const k in boardData) {
                if (k !== 'picture' && k !== 'assigned_users' && k  !== 'accosciate_entity'){
                    setValue(k, boardData[k]);
                }

                if (k === 'accosciate_entity') {
                    setValue(k, relationEntities.find(r => r.value === boardData[k]));
                }
            }
        }
        // eslint-disable-next-line
    }, [boardData, setValue]);

    const cleanForm = () => {
       if (boardData) {
            for (const k in boardData) {
                setValue(k, null);
            }
        }
    }

    return (

        <>

            <BoardPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                canClose={true}
                setClean={() => {
                
                }}
            >
                <div className={classNames(
                    " -mt-4 mb-3 bg-white"
                    )}>

                    <div className="px-4 sm:px-6">
                        
                        <>
                            {/* TITLE */}
                            <div className="flex items-center justify-between mt-3 mb-6">
                                <div className="flex items-start space-x-5">
                                    <div className="pt-1.5">
                                        <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.board_edit")}</h1>
                                    </div>
                                </div>
                            </div>

                            <UnderlineTab
                                marginTop={''}
                                tabs={tabs}
                                activeGroup={activeGroup}
                                setActiveGroup={setActiveGroup}
                            />

                            {/* CONTENT */}
                            <form onSubmit={handleSubmit(onUpdate)} className={' border-none pb-5 mt-3'}>
                                <div className="mt-3 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                                 
                                    {
                                        activeGroup === 'general-information' && (
                                            <>
                                                <div className="col-span-4 md:col-span-2 ">
                                                    <UploadFile
                                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                                        fileTypeLabel={'JPG, PNG'}
                                                        showPreview={true}
                                                        label={t("app.board.board_cover")}
                                                        isActiveRemove={true}
                                                        picture={boardData?.picture?.reference ?? false}
                                                        image={true}
                                                        cleanData={cleanData}
                                                        onUpload={(data) => {
                                                            if (data === null) {
                                                                setValue('picture', null);
                                                            } else {
                                                                setValue('picture', { media_url: data.media_url });
                                                            }
                                                        }}
                                                        onCancel={(r) => {
                                                            if (r) {
                                                                setValue('picture', null);
                                                            }
                                                        }}
                                                        extraClass={'h-44'}
                                                        onException={(e) => { }}
                                                        isLoading={false}
                                                    />

                                                </div>

                                                <div className="col-span-4 md:col-span-2">
                                                    <InputText
                                                        id={'title'}
                                                        type={"text"}
                                                        errors={errors.title}
                                                        input={{ ...register('title', { required: true }) }}
                                                        label={t('app.board.board_title')}
                                                        isRequired={true}
                                                    />
                                                </div>


                                                <div className="col-span-4 md:col-span-2">
                                                    <InputText
                                                        id={'description'}
                                                        type={"textarea"}
                                                        errors={errors.description}
                                                        input={{ ...register('description', { required: false }) }}
                                                        label={t('app.board.board_description')}
                                                    />
                                                </div>

                                                <div className="col-span-4 md:col-span-2">
                                                    <Controller
                                                        name="accosciate_entity"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                            <InputSelect
                                                                label={t("app.board.accosciate_entity")}
                                                                name={name}
                                                                options={relationEntities.filter(r => r.accosciateEntity === true)}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={false}
                                                                isDisabled={false}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>


                                                {watch('public') && (
                                                    <div className="col-span-4 md:col-span-2 -mt-1">
                                                        <MemberManage
                                                            creatorUser={boardData.user}
                                                            assignedUsers={boardData.assigned_users ?? []}
                                                            boardUsers={boardData.board_users ?? []}
                                                            onChangeData={(r) => {
                                                                setValue('users_data', r);
                                                            }}
                                                        />
                                                    </div>
                                                )}


                                                <div className="col-span-4 md:col-span-2">
                                                    <Controller
                                                        name={"public"}
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, name },
                                                            fieldState: { error },
                                                        }) => (
                                                            <InputToggle
                                                                label={t("app.board.public")}
                                                                description={t("app.board.public_description")}
                                                                onChange={(e) => {
                                                                    onChange(e)
                                                                }}
                                                                defaultChecked={value}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }


                                    {
                                        activeGroup === 'properties' && (
                                            dynamicProperties.length > 0 ? (
                                                <div className="col-span-4 md:col-span-2">
                                                    <PropertyManage
                                                        dynamicProperties={dynamicProperties}
                                                        setDynamicProperties={setDynamicProperties}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="col-span-4 md:col-span-2">

                                                    <div className="lg:w-1/2 mx-auto px-4 py-3 text-center">
                                                        <div className="text-center p-10">
                                                            <img src={Nodata} className="-mt-8 mx-auto" alt="not-found" />
                                                            <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                                                {t("app.common.not_found")}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }

                                
                                    {
                                        activeGroup === 'checklists' && (
                                            <div className="col-span-4 md:col-span-2  ">
                                                <CheckListCard
                                                    baseChecklists={checkList}
                                                    onUpdateCheckList={(r) => {
                                                        console.log(r);
                                                        setCheckList(r)
                                                    }}
                                                />
                                            </div>
                                        )
                                    }


                                    {/* Warning */}
                                    {exception && (
                                        <div className="col-span-4 md:col-span-2">
                                            <Warning message={exception} />
                                        </div>
                                    )}


                                    <div className={classNames(
                                        "col-span-4 md:col-span-2  mt-6",
                                    )}>

                                        <div className="flex flex-col items-center">
                                            <ButtonSubmit
                                                btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                isLoading={isLoading}
                                                isFullWith={true}
                                                label={t("app.common.update")}

                                            />
                                        </div>
                                    </div>
                                 

                                </div>
                            </form>
                        </>
                    </div>

                </div>


            </BoardPopup>
        </>


    )
};



const PropertyManage = ({ dynamicProperties, setDynamicProperties }) => {

    return(
        <>
            <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 mt-2">
                {
                    dynamicProperties.map((property, key) => (
                        <li key={key} className="lg:flex lg:items-center lg:justify-between py-1.5 pl-3 pr-4 text-sm">
                            <div className="flex  flex-1 items-center mt-3 lg:mt-0">
                                <div className="flex items-center">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-gray-900">{property.title}</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex-shrink-0 flex  items-center mt-3 lg:mt-0">
                                <div
                                    onClick={() => {
                                        let tmp = [...dynamicProperties.filter(r => r.field !== property.field)];
                                        setDynamicProperties(tmp);
                                    }}
                                    className="text-red-500  cursor-pointer  disabled:opacity-70 flex justify-center  text-sm font-medium "
                                >
                                    <TrashIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                    />
                                </div>

                            </div>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}


// check-List
const CheckListCard = ({ baseChecklists, onUpdateCheckList }) => {



    const [addChecklist, setAddChecklist] = useState(false);
    const [editChecklist, setEditChecklist] = useState(false);
    const [checklists, setCheckLists] = useState(baseChecklists ?? []);


    const {
        register,
        formState: { errors },
        setValue,
        watch
    } = useForm();
    const { t } = useTranslation();



    return (
        <>

          

                
            {
                (checklists ?? []).length > 0 && (
                    (checklists ?? []).map(((checklist, index) => (
                        <div className="" key={checklist.field}>

                            <div className="flex items-center mt-3 mb-2 ">


                                <div onClick={() => {
                                    let data = checklists.filter(r => r.field !== checklist.field);
                                    onUpdateCheckList(data);
                                    setCheckLists(data);
                                }}
                                    className="cursor-pointer mr-2"
                                >
                                    <TrashIcon
                                        className="h-4 w-4 text-red-500 hover:text-red-300"
                                        aria-hidden="true"
                                    />
                                </div>


                                <h1 className="text-xl font-bold text-blue-1000 ">{checklist.title}</h1>
                            </div>

                            {
                                (checklist?.checklists ?? []).map((item, indexInternal) => (
                                    <div className=" w-full mt-3" key={item.id}>
                                        {
                                            editChecklist !== item.id && (
                                                <div className="flex items-center h-5">
                                                    <span className="font-medium text-gray-700  text-sm ">{indexInternal + 1}- </span>
                                                    <label className="font-medium text-gray-700 cursor-pointer text-sm ml-1" onClick={() => {
                                                        setEditChecklist(item.id);
                                                        setValue(item.id, item.description);
                                                    }}>
                                                        {item.description}
                                                    </label>

                                                </div>
                                            )
                                        }


                                        {
                                            editChecklist === item.id && (
                                                <div className="flex flex-col w-full">
                                                    <InputText
                                                        id={item.id}
                                                        type={"textarea-2"}
                                                        errors={errors[item.id]}
                                                        input={{ ...register(item.id, { required: false }) }}
                                                        label={''}
                                                        isShowLabel={false}
                                                    />

                                                    <div className="flex items-center  mt-2">
                                                        <ButtonSubmit
                                                            btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                                            isLoading={false}
                                                            isFullWith={false}
                                                            disabled={!watch(item.id)}
                                                            label={t("app.board.update_check_list_element")}
                                                            onClick={() => {
                                                                checklists[index]['checklists'][indexInternal]['description'] = watch(item.id);
                                                                onUpdateCheckList(checklists)
                                                                setCheckLists(checklists);
                                                                setEditChecklist(false);
                                                            }}
                                                        />


                                                        <div onClick={() => {
                                                            let internal_checklists = checklists[index]['checklists'].filter(r => r.id !== item.id);
                                                            checklists[index]['checklists'] = internal_checklists;
                                                            onUpdateCheckList(checklists)
                                                            setCheckLists(checklists);
                                                            setEditChecklist(false);
                                                        }}
                                                            className="bg-red-500 ml-3 hover:bg-red-300 cursor-pointer mr-2 disabled:opacity-70 flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4"
                                                                aria-hidden="true"
                                                            />
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                setValue(item.id, checklist.description);
                                                                setEditChecklist(false);
                                                            }}
                                                            className="text-sm ml-3 underline inline-flex  items-center cursor-pointer"
                                                        >
                                                            {t("app.board.cancel")}
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }



                                    </div>
                                ))
                            }

                            {
                                addChecklist === checklist.field ? (
                                    <div className="flex flex-col w-full mt-6">
                                        <InputText
                                            id={'task_description_' + checklist.field}
                                            type={"textarea-2"}
                                            errors={errors['task_description_' + checklist.field]}
                                            input={{ ...register('task_description_' + checklist.field, { required: false }) }}
                                            label={''}
                                            isShowLabel={false}
                                        />

                                        <div className="flex items-center  mt-2">
                                            <ButtonSubmit
                                                btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                                isLoading={false}
                                                isFullWith={false}
                                                disabled={!watch('task_description_' + checklist.field)}
                                                label={t("app.board.add_check_list")}
                                                onClick={() => {
                                                    let filtered = checklist.checklists;
                                                    filtered = [...checklist.checklists, {
                                                        description: watch('task_description_' + checklist.field),
                                                        status: false,
                                                        id: makeid(30)
                                                    }];

                                                    checklists[index]['checklists'] = filtered;
                                                    onUpdateCheckList(checklists)
                                                    setCheckLists(checklists);
                                                    setValue('task_description_' + checklist.field, null);
                                                    setAddChecklist(false);
                                                }}
                                            />

                                            <div
                                                onClick={() => {
                                                    setValue('task_description_' + checklist.field, null);
                                                    setAddChecklist(false);
                                                }}
                                                className="text-sm ml-3 underline inline-flex  items-center cursor-pointer"
                                            >
                                                {t("app.board.cancel")}
                                            </div>

                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex w-full flex-1 items-center cursor-pointer group mt-3" onClick={() => {
                                        setAddChecklist(checklist.field);
                                    }}>
                                        <PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />
                                        <div className="ml-1 flex min-w-0 flex-1 gap-2">
                                            <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_check_list_element")}</span>
                                        </div>
                                    </div>
                                )
                            }






                        </div>
                    )))
                ) 
            }


            
            <div className={classNames(
                " border-2 rounded-md border-dashed space-y-4 flex flex-col    -mb-4   p-3  transition duration-150 ease-in-out ",
                (checklists ?? []).length > 0 ? "mt-6" : "mt-1",
            )}>
                <InputText
                    id={'checklist_title'}
                    type={"text"}
                    errors={errors.checklist_title}
                    input={{ ...register('checklist_title', { required: true }) }}
                    label={t('app.board.check_list_title')}
                    isRequired={true}
                />


                <div
                    className={classNames(
                        "w-full bg-green-900 cursor-pointer hover:bg-green-800  flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white",
                        !watch('checklist_title') && "opacity-70 cursor-not-allowed"
                    )}
                    onClick={async () => {
                        setCheckLists([...checklists, {
                            title: watch('checklist_title'),
                            field: slugify(watch('checklist_title')) + makeid(20),
                            checklists: [],
                        }]);

                        onUpdateCheckList([...checklists, {
                            title: watch('checklist_title'),
                            field: slugify(watch('checklist_title')) + makeid(20),
                            checklists: [],
                        }]);

                        setValue('checklist_title', null)
                    }}

                >{t("app.board.add_check_list")}</div>
            </div>



          






        </>
    )
}


export default BoardManage;