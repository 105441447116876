import React, {useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import FormPopup from "../../components/common/popup/form-popup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import {loadConnections, loadCustomers} from "../../reducers/connectionReducer";
import { useForm } from "react-hook-form";
import InputCheckbox from "../../components/common/form/input-checkbox";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import {getWebPathParam} from "../../utils/converter";
import {CUSTOMER, REGISTRY} from "../endpoints";
import {postCustomer} from "../../api/customer";
import {useNavigate} from "react-router-dom";
import {emailRegex} from "../../constants/regex";

export default function AddCustomer({ isOpen, setIsOpen }) {
  const { user } = useSelector((state) => state);
  const { loggedCompany } = user;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let navigate = useNavigate();

  // customer
  const [isCreateLoading, setCreateLoading] = useState(false);
  const [exception, setException] = useState(false);

  const storeCustomer = async (data) => {
    if (loggedCompany) {
      setCreateLoading(true);
      postCustomer({ company_id: loggedCompany.id, data })
        .then((response) => {
          setCreateLoading(false);
          dispatch(loadCustomers(loggedCompany.id));
          dispatch(loadConnections({company_id: loggedCompany.id, params: {find_all: true}}));
          navigate(getWebPathParam([REGISTRY, CUSTOMER, response.id]));
        }).catch((e) => {
          setException(e.message);
          setCreateLoading(false);
      });
    }
  };


  const onSubmit = async (data) => {
    await storeCustomer(data);
  };



  return (
    <BasePermissionWrapper
      requiredPermissions={[getPermission(permissionGroup.CUSTOMER, permissionType.CREATE)]}
    >
      <FormPopup
        title={t("app.customers.new_customer")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >

          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-1 gap-x-2 sm:grid-cols-1">
              <InputText
                id={"business_name"}
                type={"text"}
                errors={errors.business_name}
                input={{ ...register("business_name", { required: true }) }}
                label={t("app.form.business_name")}
              />

              <InputText
                id={"importer_reference"}
                type={"text"}
                errors={errors.importer_reference}
                input={{
                  ...register("importer_reference", { required: true }),
                }}
                label={t("app.customers.reference")}
              />
            </div>

            <div className={"col-span-2 mt-2"}>
              <InputCheckbox
                label={t("app.customers.connection_title")}
                description={t("app.customers.connection_description")}
                id={"send_connection"}
                input={{ ...register("send_connection", { required: false }) }}
              />
            </div>

            <div className={"col-span-2 mt-2"}>
              {watch("send_connection") && (
                <InputText
                  id={"notification_email"}
                  type={"text"}
                  errors={errors.notification_email}
                  input={{
                    ...register("notification_email", { required: watch("send_connection"), pattern: emailRegex }),
                  }}
                  label={"E-Mail"}
                />
              )}
            </div>
          </div>

          {/* Warning */}
          {exception && <Warning message={exception} />}

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
              isLoading={isCreateLoading}
              label={t("app.customers.add_customer")}
            />
          </div>
        </form>
      </FormPopup>
    </BasePermissionWrapper>
  );
}
