import React from "react";
import PdfIcon from "../../../resources/icons/PdfIcon";
import DocIcon from "../../../resources/icons/DocIcon";
import XlsIcon from "../../../resources/icons/XlsIcon";
import AttentionIcon from "../../../resources/icons/AttentionIcon";
import FileTextIcon from "../../../resources/icons/FileTextIcon";
import {DocumentSearchIcon} from "@heroicons/react/outline";
import CheckIcon from "@heroicons/react/outline/CheckIcon";

const FileCard = ({ file, name, information, onClick, isReportConfirmed = false, isActiveShow = true }) => {


    const getFileType = () => {
        if(file){
            let fileType = file.reference.split('.').pop();

            if(fileType.toLowerCase() === 'pdf'){
                return { icon: <PdfIcon className={'h-8 w-8 text-red-800'} />, color: 'bg-red-100'};
            }
            if(fileType.toLowerCase() === 'docx'){
                return { icon: <DocIcon className={'h-8 w-8 text-blue-800'} />, color: 'bg-blue-100'};
            }
            if(fileType.toLowerCase() === 'xls' || fileType === 'xlsx'){
                return { icon: <XlsIcon className={'h-8 w-8 text-green-800'} />, color: 'bg-green-100'};
            }

            return { icon: <FileTextIcon className={'h-8 w-8 text-indigo-800'} />, color: 'bg-indigo-100'};
        }
        return { icon: <AttentionIcon className={'h-8 w-8 text-yellow-800'} />, color: 'bg-yellow-100'};
    };

  return (

      <div className={"mt-1 px-2 py-2  border border-gray-300  rounded-md" }>
          <div className="space-y-1 text-center flex items-center justify-between text-sm">
              <div className="flex items-center w-0 flex-1 flex items-center">
                  <div>
                        <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-16 w-16 rounded-md '+getFileType().color}>
                            <span className="font-medium leading-none text-white">{getFileType().icon}</span>
                            {
                                isReportConfirmed &&  (
                                    <CheckIcon className={"absolute bottom-0 right-0 block rounded-full ring-2 ring-white bg-green-400 text-white h-4 w-4"} />
                                )
                            }
                        </span>

                  </div>
                  <div className="ml-3 text-left">
                      <p className="text-sm  font-medium text-gray-700 group-hover:text-gray-900">{name}</p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{information}</p>
                  </div>
              </div>

              {
                  isActiveShow && (
                      <div className="ml-4 flex-shrink-0 flex items-center justify-between">
                          <div className={"mr-2 cursor-pointer"} onClick={onClick}>
                              <DocumentSearchIcon className={'h-6 w-6 text-blue-1000'} aria-hidden="true"/>
                          </div>
                      </div>
                  )
              }


          </div>
      </div>


  );
};

export default FileCard;
