import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";

export default function BoardPopup({ isOpen, setIsOpen, children, setClean, canClose }){

    return (

        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="absolute overflow-x-auto inset-0 z-50 "
                onClose={() => {
                    if (canClose){
                        setIsOpen(false);
                        setClean();
                    }
                }}
            >
                <div className="min-h-screen px-4 text-center"  >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay style={{ zIndex: -1 }} className="fixed inset-0 bg-gray-900 bg-opacity-70 z-01 transition-opacity" />
                    </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block rounded-md w-full max-w-xl my-8 text-left align-middle transition-all transform bg-white shadow-xl">
                            {
                                    canClose && (
                                        <div className="flex justify-end  p-2 items-center">
                                            <button className="bg-white border-0" onClick={() => { setIsOpen(false); setClean(); }}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-blue-1000" /></button>
                                        </div>
                                    )      
                            }
                            <div>
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
        
        </>
    )
};
