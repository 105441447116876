import React, { useEffect, useState } from "react";
import {getCustomSelectParams, getSelectParams} from "../../../utils/converter";
import { useSelector } from "react-redux";
import {getElement} from "../../../api/config";
import InputSelect from "../../../components/common/form/input-select";
import { getSelectSubParams } from "../../../constants/config";

const DynamicSearch = ({ endpoint, isDisabled = false,  subParams = false, escludeId = '', esludeIds = [], responseLabelList = false, isClearable = false, onResponse = false, responseLabel, label, error, value, name, onChange, selectLabel, isRequired = false, isInternalLoading = false, isShowLabel  = true}) => {
    const { loggedCompany } = useSelector((state) => state.user);

    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(true);


    useEffect(() => {
        function fetchData() {
            if (loggedCompany && !isDisabled) {
                setLoading(true);
                getElement(endpoint).then((response) => {
                    if (subParams) {
                        setList(getSelectSubParams(response[responseLabel], label, subParams));
                        if (onResponse) {
                            onResponse(getSelectSubParams(response[responseLabel], label, subParams))
                        }
                    }else if(responseLabelList){
                        setList(getSelectParams(response[responseLabelList][responseLabel], label));
                        if(onResponse){
                            onResponse(getSelectParams(response[responseLabelList][responseLabel], label))
                        }
                    }else{
                        if(responseLabel){
                            setList(getSelectParams(response[responseLabel], label));
                            if(onResponse){
                                onResponse(getSelectParams(response[responseLabel], label))
                            }
                        }else{
                            setList(getCustomSelectParams(response, label));
                            if(onResponse){
                                onResponse(getCustomSelectParams(response, label))
                            }
                        }
                    }
                    setLoading(false);
                }).catch((e) => {
                    setLoading(false);
                });
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [loggedCompany, endpoint, responseLabelList, responseLabel, label, isDisabled]);


    const escludeIdsSet = new Set(esludeIds.map(item => item.id));


    return (
        <InputSelect
            label={selectLabel}
            isShowLabel={isShowLabel}
            name={name}
            options={list.filter(r => r.id !== escludeId).filter(item => !escludeIdsSet.has(item.id))}
            value={value}
            onChange={onChange}
            isLoading={isLoading || isInternalLoading}
            isDisabled={isLoading || isDisabled || isInternalLoading}
            isClearable={isClearable}
            errors={error}
            isMulti={false}
            isRequired={isRequired}
        />
    );
};

export default DynamicSearch;
