import { ArrowLeftIcon,  PencilIcon,  PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import { useTranslation } from "react-i18next";
import InputSelectColors from "../../components/common/form/input-select-colors";
import ButtonSubmit from "../../components/common/form/button-submit";
import { postElement, putElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { BOARD, STATUS } from "../endpoints";
import { Warning } from "../../components/common/alert/banner";
import { Link } from "react-router-dom";
import BoardPopup from "../../components/board/board-popup";
import RemovePopup from "../../components/common/popup/remove-popup";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const StatusManage = ({ isOpen, setIsOpen, boardData, statusDetail = false, statuses, onForceClose, onUpdateList }) => {


    
    const [isOpenList, setOpenList] = useState(statuses.length > 0)
    const [isOpenAdd, setOpenAdd] = useState(false)



    const [isCreateLoading, setCreateLoading] = useState(false)
    const [exception, setException] = useState(false)

    const [isOpenEdit, setOpeEdit] = useState(false)
    const [statusData, setStatusData] = useState(statusDetail)


    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});



    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();
    const { t } = useTranslation();


    const onSubmit = async (data) => {
        setCreateLoading(true);
        setException(false);
        postElement(getPathParam([BOARD, boardData.id, STATUS]), data)
            .then(response => {
                cleanForm();
                setOpenAdd(false);
                setOpenList(true);
                setCreateLoading(false);
                onUpdateList(response.statuses);
            }).catch(e => {
                setException(e.message);
                setCreateLoading(false);
            });

    };


    const onUpdate = async (data) => {
        setCreateLoading(true);
        setException(false);
        putElement(getPathParam([BOARD, boardData.id, STATUS, data.id]), data)
            .then(response => {
                cleanForm();
                setOpenAdd(false);
                setOpenList(true);
                setCreateLoading(false);
                onUpdateList(response.statuses);
            }).catch(e => {
                setException(e.message);
                setCreateLoading(false);
            });

    };


    useEffect(() => {
        if (statusData) {
            for (const k in statusData) {
                setValue(k, statusData[k]);
            }
        }
    }, [statusData, setValue]);

    const cleanData = () => {
        cleanForm();
        setOpenList(false);
        setOpenAdd(false);
        setOpeEdit(false);
        setStatusData(false);
    }


    const cleanForm = () => {
        setValue('id', null);
        setValue('title', null);
        setValue('description', null);
        setValue('color', null);
    }

    return (

        <>

            <BoardPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                canClose={statuses.length > 0}
                setClean={() => {
                    setOpenList(false);
                    setOpenAdd(false);
                    setOpeEdit(false);
                    setStatusData(false);
                }}
            >
                <div className={classNames(
                    (isOpenAdd || isOpenEdit || isOpenList) ? '-mt-6' : '',
                    " mb-3 bg-white"
                    )}>

                    <div className="px-4 sm:px-6">
                        
                        {
                            ((!statusData || isOpenAdd || isOpenEdit) && !isOpenList) ? (
                                <>
                                    {/* TITLE */}
                                    <div className="flex items-center justify-between mt-3">
                                        <div className="flex items-start space-x-5">
                                            <div className="pt-1.5">
                                                <h1 className="text-2xl font-bold text-blue-1000">{isOpenAdd ? t("app.board.edit_status") : t("app.board.add_status")}</h1>
                                                <p className="text-sm font-medium text-gray-500 mt-1">
                                                    {isOpenAdd ? t("app.board.edit_status_description") : t("app.board.add_status_description")}
                                                    
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* CONTENT */}
                                    <form onSubmit={isOpenEdit ? handleSubmit(onUpdate) : handleSubmit(onSubmit)} className={' border-none pb-5 mt-5'}>
                                        <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={'title'}
                                                    type={"text"}
                                                    errors={errors.title}
                                                    input={{ ...register('title', { required: true }) }}
                                                    label={t('app.board.status_title')}
                                                    isRequired={true}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-2">
                                                <InputText
                                                    id={'description'}
                                                    type={"textarea"}
                                                    errors={errors.description}
                                                    input={{ ...register('description', { required: false }) }}
                                                    label={t('app.board.status_description')}
                                                />
                                            </div>


                                            <div className="col-span-4 md:col-span-2 -mt-1">
                                                <Controller
                                                    name={'color'}
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, name },
                                                        fieldState: { error },
                                                    }) => (

                                                        <InputSelectColors
                                                            label={t("app.board.status_color")}
                                                            name={name}
                                                            translationPattern={'filter.general.'}
                                                            onChangeData={(e) => {
                                                                onChange(e);
                                                            }}
                                                            valueData={value}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>



                                            {/* Warning */}
                                            {exception && (
                                                <div className="col-span-4 md:col-span-2">
                                                    <Warning message={exception} />
                                                </div>
                                            )}


                                            {
                                                isOpenEdit ? (
                                                    <div className={"col-span-4 md:col-span-2 flex  items-center justify-between mt-6"}>

                                                        <div>
                                                            <Link
                                                                to={'#'}
                                                                onClick={() => {
                                                                    cleanForm()
                                                                    setStatusData(false);
                                                                    setOpeEdit(false);
                                                                    setOpenList(true);
                                                                }}
                                                                className="text-sm  inline-flex  items-center"
                                                            >
                                                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                                {t("app.common.back")}
                                                            </Link>
                                                        </div>

                                                        <ButtonSubmit
                                                            btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                            isLoading={isCreateLoading}
                                                            label={t("app.board.status_add")}
                                                            
                                                        />
                                                    </div>
                                                ) : (
                                                        <div className={classNames(
                                                            "col-span-4 md:col-span-2  mt-6",
                                                            isOpenAdd ? "flex  items-center justify-between" : ''
                                                        )}>
                                                           
                                                                {
                                                                    isOpenAdd  && (
                                                                        <div>
                                                                            <Link
                                                                                to={'#'}
                                                                                onClick={() => {
                                                                                    cleanForm();
                                                                                    setOpenAdd(false);
                                                                                    setOpenList(true);
                                                                                }}
                                                                                className="text-sm  inline-flex  items-center"
                                                                            >
                                                                                <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                                                                {t("app.common.back")}
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                }
                                                            
                                                       


                                                            <div className="flex flex-col items-center">

                                                                <ButtonSubmit
                                                                    btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                                    isLoading={isCreateLoading}
                                                                    isFullWith={!isOpenAdd && !statusData}
                                                                    label={t("app.board.status_add")}

                                                                />

                                                            {
                                                                    !isOpenAdd && (
                                                                        <Link
                                                                            to={'#'}
                                                                            onClick={() => {
                                                                                cleanData();
                                                                                setIsOpen(false);
                                                                                onForceClose(true);
                                                                            }}
                                                                            className="text-sm  mt-3 underline inline-flex  items-center"
                                                                        >
                                                                            {t("app.board.cancel")}
                                                                        </Link>
                                                                    )
                                                                }
                                                            </div>
                                                           


                                                    </div>
                                                )
                                            }

                                        </div>
                                    </form>
                                </>
                            ) : (
                                <>

                                    {/* TITLE */}
                                    <div className="flex items-center justify-between mt-3">
                                        <div className="flex items-start space-x-5">
                                            <div className="pt-1.5">
                                                <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.manage_status")}</h1>
                                                <p className="text-sm font-medium text-gray-500 mt-1">
                                                    {t("app.board.manage_status_description")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                        <div className=" bg-white  rounded-sm border border-slate-200 mt-6">
                                            <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                                                <div  className="relative">
                                                    <ul className="relative z-0 divide-y divide-gray-200">
                                                        {statuses.map((status) => (
                                                            <li key={status.id} className=" px-2 bg-white hover:bg-gray-50">
                                                                <div className="py-3 flex items-center justify-between space-x-3">
                                                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                                        <div className="min-w-0 flex-1">
                                                                            <p className="text-sm font-medium text-gray-900 truncate">
                                                                                {status.title}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">

                                                                        <button
                                                                            onClick={() => {
                                                                                setStatusData(status);
                                                                                setOpeEdit(true);
                                                                                setOpenList(false);
                                                                            }}
                                                                        >
                                                                            <PencilIcon
                                                                                className="h-4 w-4 text-gray-500 hover:text-gray-700"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>


                                                                        <button
                                                                            onClick={() => {
                                                                                const removeDetail = {
                                                                                    title: t("app.board.remove_status_title"),
                                                                                    message: t("app.board.remove_status_description"),
                                                                                    endpoint: getPathParam([BOARD, boardData.id, STATUS, status.id])
                                                                                };
                                                                                setRemoveDetail(removeDetail);
                                                                                setOpenRemove(true);
                                                                            }}
                                                                        >
                                                                            <TrashIcon
                                                                                className="h-4 w-4 text-red-500 hover:text-red-700"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}



                                                        <li className=" px-2 bg-gray-50 hover:bg-gray-100 cursor-pointer" onClick={() => {
                                                            setOpenAdd(true);
                                                            setOpenList(false);
                                                        }}>
                                                            <div className="py-3 w-full text-center flex items-center justify-center space-x-3">
                                                                <div className="flex items-center space-x-1">
                                                                    <PlusIcon className="h-4 w-4 flex-shrink-0 ext-gray-900" aria-hidden="true" />
                                                                    <div className=" flex min-w-0 flex-1 gap-2">
                                                                        <span className="text-sm font-medium text-gray-900">{t("app.board.status_add")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>

                                </>
                            )
                        }
                        
                    </div>

                </div>



                {(isOpenRemove) && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(r) => {
                            onUpdateList(r.statuses);
                            if (r.statuses.length === 0){
                                setOpenList(false);
                                setOpenAdd(false);
                                setOpeEdit(false);
                                setStatusData(false);
                            }
                        }}
                    />
                )}


            </BoardPopup>
        </>


    )
};




export default StatusManage;