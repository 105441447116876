import React, {useEffect, useState} from "react";
import SlidePanel from "../../../../../../../components/layout/slide-panel";
import CSVReader from "react-csv-reader";
import CsvTable from "../../../../../../../components/partials/csv/csv-table";
import {CONTAINER_PRODUCT_IMPORT} from "../../../../../../../constants/import";
import ButtonSubmit from "../../../../../../../components/common/form/button-submit";
import {getImportModels, postImport} from "../../../../../../../api/import";
import {getSignedRequests} from "../../../../../../../api/config";
import {sendMedia} from "../../../../../../../utils/apiUtils";
import {useSelector} from "react-redux";
import lodash from "lodash";
import {Warning} from "../../../../../../../components/common/alert/banner";
import exampleOrderFile from "../../../../../../../resources/import/container_load_exemple.csv";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {getSelectParams} from "../../../../../../../utils/converter";
import InputSelect from "../../../../../../../components/common/form/input-select";
import ImportModelPopup from "../../../../../../../components/common/popup/import-model-popup";
import MD5 from 'crypto-js/md5';

export default function ImportProduct({ isOpen, setIsOpen, onImportComplete, importGroup }) {

    const { loggedCompany } = useSelector((state) => state.user);

    // Dynamic Table
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [confirmedHeader, setConfirmedHeader] = useState([]);
    const [originalFile, setOriginalFile] = useState(false);

    let {container_id}  = useParams();

    // Form
    const {setValue, control} = useForm();
    const [isOpenModel, setOpenModel] = useState(false);
    const [columnsCode, setColumnsCode] = useState(false);
    const [importModels, setImportModels] = useState([]);
    const [isLoadingModels, setLoadingModels] = useState([]);
    const [isFoundModel, setFoundModel] = useState(false);


    // Load Process
    const [isLoading ,setLoading] = useState(false);
    const [exception ,setException] = useState(false);
    const { t } = useTranslation();

    const options = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true
    };



    const uploadCsv = async (file)  => {
        setLoading(true);
        getSignedRequests({params: {ctype: file.type ,ext: file.name.split(".").pop()}})
            .then( signed => {
                sendMedia(signed.action, signed, file)
                    .then( r => {
                        let url = signed.action + '/' + signed.key;
                        let data = {};
                        data['path'] = url;
                        data['file_name'] = file.name;
                        data['params'] = confirmedHeader;
                        data['entity_id'] = container_id;
                        data['import_type'] = 'container_product';
                        storeImport(data);
                    }).catch(e => {
                    setException(e.message);
                    setLoading(false);
                });
            }).catch(e => {
            setException(e.message);
            setLoading(false);
        });
    };

    const storeImport = async (data)  => {
        setLoading(true);
        if(loggedCompany){
            postImport({company_id: loggedCompany.id, import_group: importGroup,  data})
                .then(response => {
                    setLoading(false);
                    onImportComplete(response);
                    clean();
                    setIsOpen(false)
                }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
        }
    };

    const handleForce = (data, fileInfo, originalFile) =>{
        if(data.length > 0){
            let column = [];
            for (const k in data[0]) {
                column.push(k);
            }
            setColumns(column);
            setColumnsCode(MD5(column.join('-')).toString());
            setRows(data.length > 10 ? data.slice(0, 10) : data);
            setOriginalFile(originalFile);
        }
    };

    const clean = () =>{
        setRows([]);
        setColumns([]);
        setConfirmedHeader([]);
        setImportModels([]);
        setColumnsCode(false);
        setOriginalFile(false);
    };

    useEffect(() => {
        if(importModels.length > 0 && columnsCode !== false){
            let data = importModels.find(r => r.code === columnsCode);
            if(data){
                setValue('import_model', data);
                setFoundModel(true);
                setConfirmedHeader(importModels?.find(r => r.code === columnsCode)?.params ?? []);
            }
        }

        // eslint-disable-next-line
    }, [setValue, importModels, columnsCode]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchModels() {
            if (loggedCompany && isOpen) {
                setLoadingModels(true);
                try {
                    const res = await getImportModels({
                        company_id: loggedCompany.id,
                        params: { import_type: 'container_product'},
                        signal,
                    });
                    setValue('import_model', null)
                    setFoundModel(false);
                    setImportModels(getSelectParams(res.import_models, "name"));
                } catch (e) {
                    setImportModels([]);
                } finally {
                    setLoadingModels(false);
                }
            }
        }
        fetchModels();
        return () => {
            controller.abort();
        };
    }, [setValue, isOpen, loggedCompany]);



    return (
        <SlidePanel
            title={t("app.container.import_title")}
            bg={"bg-white"}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            size={'max-w-7xl'}
            footer={Footer}
            setClean={() => {
                clean();
            }}
            extras={{
                isLoading,
                confirmedHeader,
                onStartLoad: () => {
                    if(isFoundModel){
                        uploadCsv(originalFile);
                    }else{
                        setOpenModel(true);
                    }
                }
            }}
        >

            <div className="space-y-6 pt-6 pb-5">

                <div className="grid grid-cols-2 gap-2 mb-6">
                    <div className="col-span-2">
                        <label className="block text-sm font-medium mb-1 text-gray-900">
                            {t("app.container.import_sub_title")}
                        </label>
                        <p className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer">
                            <a href={exampleOrderFile} target="_blank" rel="noreferrer">{t("app.orders.import_example_file")}</a>
                        </p>
                    </div>

                    <div className="col-span-2 mt-3">
                        <label className="block text-sm font-medium mb-1 text-gray-900">{t("app.orders.import_file_title")}</label>
                        <CSVReader
                            cssClass={"p-2 border-2 mt-1 border-gray-300 border-dashed rounded-md"}
                            cssInputClass={'text-sm font-medium text-indigo-600'}
                            cssLabelClass={''}
                            label=""
                            onFileLoaded={handleForce}
                            parserOptions={options}
                        />
                    </div>


                    {
                        columns.length > 0 && (
                            <>
                                {
                                    importModels.length > 0 && (
                                        <div className="col-span-2 mt-3">
                                            <Controller
                                                name="import_model"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                             field: { onChange, value, name },
                                                             fieldState: { error },
                                                         }) => (
                                                    <InputSelect
                                                        label={t("app.import_model.select_model")}
                                                        name={name}
                                                        options={importModels}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if(e){
                                                                setFoundModel(true);
                                                                setConfirmedHeader(e.params);
                                                            }
                                                        }}
                                                        isLoading={isLoadingModels}
                                                        isDisabled={isLoadingModels}
                                                        errors={error}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                <div className="col-span-2 mt-3">
                                    <label className="block text-sm font-medium text-gray-900">{t("app.orders.import_configuration_title")}</label>
                                    <CsvTable
                                        params={CONTAINER_PRODUCT_IMPORT}
                                        columns={columns}
                                        rows={rows}
                                        onSetConfirmedHeader={(value, data) => {
                                            let items = confirmedHeader;
                                            lodash.remove(confirmedHeader, function (e) {
                                                return e.file_param === data;
                                            });
                                            setConfirmedHeader([...items, {file_param: data, confirmed_param: value}])
                                        }}
                                        confirmedHeader={confirmedHeader}
                                    />
                                </div>
                            </>

                        )
                    }

                </div>

                {/* Warning */}
                {exception && <Warning message={exception} />}


            </div>

            {isOpenModel && (
                <ImportModelPopup
                    isOpen={isOpenModel}
                    setIsOpen={setOpenModel}
                    importType={'container_product'}
                    columnsCode={columnsCode}
                    confirmedHeader={confirmedHeader}
                    onConfirmed={() => {
                        uploadCsv(originalFile);
                    }}
                />
            )}

        </SlidePanel>
    );
}

const Footer = ({extras}) => {
    const { t } = useTranslation();

    return (
        <div className="flex-shrink-0 before:flex-1 px-4 py-4 flex justify-between">
            <ButtonSubmit
                isLoading={extras.isLoading}
                label={t("app.common.confirm_import")}
                isFullWith={false}
                onClick={() => {
                    extras.onStartLoad()
                }}
                disabled={extras.confirmedHeader.length === 0}
            />
        </div>
    );
};
