import React, { useEffect, useState } from "react";
import {useParams, useOutletContext} from "react-router-dom";
import { useSelector } from "react-redux";
import {  Warning } from "../../../../../components/common/alert/banner";
import {CATALOG, PRODUCTS} from "../../../../endpoints";
import { getWebPathParam } from "../../../../../utils/converter";
import {deleteProductImageGallery, putProduct} from "../../../../../api/catalog";
import { useTranslation } from "react-i18next";
import {EyeIcon, TrashIcon} from "@heroicons/react/outline";
import LoaderIcon from "../../../../../resources/icons/LoaderIcon";
import UploadImages from "../../../../../components/partials/gallery/upload-images";
import {getPermission, permissionGroup, permissionType} from "../../../../../constants/permissions";
import toast from "react-hot-toast";
import BackLink from "../../../../../components/common/form/back-link";
import FsLightbox from "fslightbox-react";
import BasePermissionWrapper, {
    hasPermissionFor
} from "../../../../../components/partials/restricted/base-permission-wrapper";

const ProductGallery = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { permissions } = useSelector((s) => s.userCompany);

  const { productDetail, setProductDetail } = useOutletContext();
  const [gallery, setGallery] = useState([]);

  let { id } = useParams();
  const { t } = useTranslation();


  // put product
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);


    const [isRemoveLoading, setRemoveLoading] = useState(false);
    const [removeFiles, setRemoveFiles] = useState( []);
    const [toggleSingle, setToggleSingle] = useState(false);
    const [singleImage, setSingleImage] = useState(false);


    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.CREATE);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.DELETE);

  const updateProduct = async (data) => {
    setUpdateLoading(true);
    putProduct({ company_id: loggedCompany.id, product_id: id, data })
      .then((response) => {
          setProductDetail(response);
          setGallery(response?.gallery?.medias ?? []);
          setUpdateLoading(false);
          setUpdateException(false);
          toast.success(t("app.products.update_success"))
      })
      .catch((e) => {
        setUpdateException(e.message);
        setUpdateLoading(false);
      });
  };

   const removeImage = async (media_id) => {
       if(!isRemoveLoading){
           setRemoveLoading(true);
           setRemoveFiles(res => [...res, {id: media_id}]);
           deleteProductImageGallery({company_id: loggedCompany.id, product_id: id, media_id: media_id})
               .then(response => {
                   setProductDetail(response);
                   setGallery(gallery.filter(item => item.id !== media_id));
                   setRemoveFiles(removeFiles.filter(item => item.id !== media_id));
                   setRemoveLoading(false);
                   toast.success(t("app.products.update_success"))
               }).catch(e => {
               setUpdateException(e.message);
               setRemoveLoading(false);
           });
       }
    };

  useEffect(() => {
    if(productDetail !== null){
        setGallery(productDetail?.gallery?.medias ?? []);
    }
  }, [productDetail]);

  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT, permissionType.LIST)]} renderBaseOnFail={true}>
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT, permissionType.VIEW)]} renderBaseOnFail={true}>


        <section className={"mt-3 bg-white shadow py-6 px-4 sm:p-6"}>
            <ul
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
            >
                    {gallery.map((file, index) => (
                        <li key={index} className="relative cursor-pointer" >
                            <div className={'group block w-full aspect-w-10 aspect-h-7  bg-solitude-100 overflow-hidden'} >

                                <div className={'absolute right-10 bg-blue-1000 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'}
                                     onClick={() => { setSingleImage(file.reference); setToggleSingle(!toggleSingle);  }}>
                                    <EyeIcon className={'h-5 w-5 text-white'} />
                                </div>

                                { canDelete && (
                                    <>
                                        {
                                            removeFiles.filter(e => e.id === file.id).length > 0  ? (
                                                <div className={'absolute right-2 bg-red-400 rounded-full p-1 top-2 z-10 block cursor-pointer'}>
                                                    <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0 text-white"/>
                                                </div>

                                            ) : (
                                                <div className={'absolute right-2 bg-red-400 rounded-full p-1 top-2 hidden z-10 group-hover:block cursor-pointer'} onClick={() => {
                                                    removeImage(file.id);
                                                }}>
                                                    <TrashIcon className={'h-5 w-5 text-white'} />
                                                </div>
                                            )
                                        }
                                    </>
                                )

                                }

                                <img
                                    src={file?.reference ?? ''}
                                    alt=""
                                    className={'object-contain h-48 w-full pointer-events-none group-hover:opacity-75'}
                                />
                                <button type="button" className="absolute inset-0 focus:outline-none">
                                    <span className="sr-only">View details for </span>
                                </button>
                            </div>
                        </li>
                    ))}

                {
                    canCreate && (
                        <li className="relative">
                            <UploadImages
                                isLoading={isUpdateLoading}
                                onException={(r) => {setUpdateException(r)}}
                                onUpload={(r) => {
                                    let data = {};
                                    data['id'] = id;
                                    data['gallery'] = {medias: [r] };
                                    updateProduct(data);
                                }}
                            />
                        </li>
                    )
                }

            </ul>

            {/* Warning */}
            {updateException && <Warning message={updateException} />}
        </section>

        <FsLightbox
            toggler={ toggleSingle }
            disableLocalStorage={true}
            sources={ [singleImage]}
            type="image"
        />
        <div className={"mt-4"}>
            <BackLink backLink={getWebPathParam([CATALOG, PRODUCTS])} backLabel={t("app.products.back")} />
        </div>
        </BasePermissionWrapper>

    </BasePermissionWrapper>
  );
};

export default ProductGallery;
