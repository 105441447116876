export default function BoardIcon(props) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="transparent" xmlns="http://www.w3.org/2000/svg"   {...props}>
            <path d="M10 8.5H14M5 7.83333L5.75 8.5L8 6.5M10 11.5H14M5 11.5L5.75 12.5L8 9.5M6.6 17.5H11.4C15.4 17.5 17 15.9 17 11.9V7.1C17 3.1 15.4 1.5 11.4 1.5H6.6C2.6 1.5 1 3.1 1 7.1V11.9C1 15.9 2.6 17.5 6.6 17.5Z" strokeWidth="1.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}


