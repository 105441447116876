import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import InputDate from "../../components/common/form/input-date";
import {getSelectParams} from "../../utils/converter";
import InputSelect from "../../components/common/form/input-select";
import {postTask} from "../../api/task";
import {Warning} from "../../components/common/alert/banner";
import {getCompanyUsers} from "../../api/company";
import InputEditor from "../../components/common/form/input-editor";
import UploadMultifile from "../../components/partials/upload/upload-multifile";

export default function AddTask({
  isOpen,
  setIsOpen,
  onAddTask,
  entityType = null,
  entityId = null
}) {
  const { loggedCompany } = useSelector((state) => state.user);
  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);


  const [isLoadingUsers, setLoadingUsers] = useState(false);
  const [userList, setUserList] = useState([]);

  const [files, setFiles] = useState([]);


  const storeTask = async (data) => {
        if (loggedCompany) {
            setLoading(true);
            postTask({ company_id: loggedCompany.id, data })
                .then((response) => {
                    onAddTask(response);
                    setLoading(false);
                    setIsOpen(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });
        }
    };

  useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchUsers() {
            if (loggedCompany && isOpen === true) {
                setLoadingUsers(true);
                getCompanyUsers({ company_id: loggedCompany.id, params: null, signal })
                .then((response) => {
                    setUserList(getSelectParams(response, "fullname"));
                    setLoadingUsers(false);
                }).catch((e) => {
                    setLoadingUsers(false);
                });
            }
        }
        fetchUsers();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, isOpen]);


  const onSubmit = async (data) => {

      if(data['users'] && data['users'].length > 0){
          data['company_task_users'] = [];
          // eslint-disable-next-line
          data['users'].map(item => {
              data['company_task_users'].push({user: item })
          })

          delete data['users'];
      }


      if(files.length > 0){
          data['company_task_files'] = [];

          // eslint-disable-next-line
          files.map(item => {
              data['company_task_files'].push({file: {media_url : item.reference }, file_name: item.name});
          });
      }



      if(entityType && entityId){
          data['entity_type'] = entityType
          data['entity_id'] = entityId
      }


      storeTask(data);
  };

  return (
      <FormPopup
          title={t("app.tasks.new_task")}
          isOpen={isOpen}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">

            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
              <InputText
                  id={"title"}
                  isRequired={true}
                  type={"text"}
                  errors={errors.title}
                  input={{ ...register("title", { required: true }) }}
                  label={t("app.tasks.title")}
              />

              <Controller
                  name="task_date"
                  rules={{ required: true }}
                  control={control}
                  render={({
                             field: { onChange, value, name },
                             fieldState: { error },
                           }) => (
                      <InputDate
                          label={t("app.tasks.task_date")}
                          format={"YYYY-MM-D HH:mm:ss"}
                          placeholder={"Es. 01/12/2021"}
                          isRequired={true}
                          activeMinDate={false}
                          startDate={value}
                          disabled={false}
                          isShowTime={false}
                          errors={error}
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                          }}
                      />
                  )}
              />

                <div className="col-span-2">
                    <Controller
                        name="users"
                        rules={{ required: false }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <InputSelect
                                label={t("app.tasks.users")}
                                name={name}
                                options={userList}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isMulti={true}
                                isLoading={isLoadingUsers}
                                isDisabled={isLoadingUsers}
                                errors={error}
                            />
                        )}
                    />
                </div>

              <div className="col-span-2">
                  <Controller
                      name="description"
                      rules={{ required: false }}
                      control={control}
                      render={({field: { onChange, value, name },
                                   fieldState: { error },
                               }) => (
                          <InputEditor
                              label={t("app.tasks.description")}
                              errors={error}
                              defaultValue={value}
                              name={name}
                              onChange={(e) => {
                                  onChange(e);
                              }}
                          />
                      )}
                  />
              </div>

                <div className={'col-span-2'}>
                    <UploadMultifile
                        onUpload={(data) => {
                            setFiles(data);
                        }}
                        onException={(e) => setException(e)}
                        isLoading={false}
                        label={t("app.document.upload_document")}
                    />
                </div>

            </div>

              {/* Warning */}
              {exception && (
                  <Warning message={exception} />
              )}


          </div>

          <div className="flex before:flex-1 items-center justify-between mt-6">
            <InputSubmit
                isLoading={isLoading}
                label={t("app.tasks.add_task")}
            />
          </div>
        </form>
      </FormPopup>
  );
}
