import { Dialog, Transition } from "@headlessui/react";
import { ArchiveIcon, BellIcon, CollectionIcon, DotsHorizontalIcon, FilterIcon, LockClosedIcon, LockOpenIcon, PencilIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import UsersToolbar from "../common/card/users-toolbar";
import UserInitial from "../common/table/user-initial";
import BoardActionsMenu from "./styles/board-actions-menu";
import { useTranslation } from "react-i18next";
import InputSearch from "../common/form/input-search";
import { Controller, useForm } from "react-hook-form";
import InputDate from "../common/form/input-date";
import moment from "moment";
import CustomUserSelect from "../common/form/custom-user-select";
import ButtonSubmit from "../common/form/button-submit";
import { WhiteButton } from "../layout/page-header";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



const BoardSlidePanel = ({ isOpen, setIsOpen, children, setClean, boardData, onManageTask, onManageBoard, onShowArchived, isAdmin = false, isMember = false, onRunSearch, onManageNotification, onRemove }) => {

    
    const { t } = useTranslation();

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={"fixed inset-0 overflow-hidden z-10"} onClose={() => {

            }}>
                <div className="absolute inset-0 overflow-hidden">

                    <div className="fixed inset-y-0  max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                        >
                            <div className={"w-screen max-w-full"}>


                                
                                <div className={classNames(
                                    "h-full divide-y divide-gray-200 flex flex-col  shadow-xl",
                                    (boardData && !boardData.picture) ? "bg-gray-900" : "bg-solitude-100"
                                )}>

                                    <div className={" relative"}>
                                        {
                                            (boardData && boardData.picture) && (
                                                <>
                                                    <img
                                                        className="absolute inset-0 w-full h-screen object-cover"
                                                        src={boardData.picture.reference}
                                                        alt=""
                                                    />
                                                    <div className="absolute inset-0 bg-black h-screen bg-opacity-20 transition-opacity" />

                                                </>
                                            )
                                        }

                                        <div className={"relative py-3 px-4 sm:px-6 md:px-8 z-10 backdrop-blur-sm bg-black/[0.10] supports-backdrop-blur:bg-black/[0.10] border-b border-white/[0.2]"}>
                                            <div className="flex items-center justify-between">

                                                <div className="flex items-center">
                                                    <div className="flex items-center justify-between">
                                                        <div className="ml-3 h-7 flex items-center">
                                                            <button
                                                                type="button"
                                                                className="text-white hover:text-white  mr-2"
                                                                onClick={() => { setIsOpen(false); setClean(); }}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                        <Dialog.Title className="text-xl  text-white uppercase font-bold ">{boardData?.title ?? ''}</Dialog.Title>
                                                    </div>
                                                   
                                                    {
                                                        isAdmin && (
                                                            <div className="hidden md:block">
                                                                <div className="flex  items-center justify-between ml-4 cursor-pointer" onClick={() => {
                                                                    onManageTask()
                                                                }}>
                                                                    <div className=" flex items-center">
                                                                        <CollectionIcon className="h-4 w-4 text-white" />
                                                                    </div>
                                                                    <div className="text-sm text-white ml-2">
                                                                        {t("app.board.manage_status")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                        )
                                                    }
                                                   

                                                    <div className="hidden md:block">
                                                        <div className="flex items-center justify-between ml-4 cursor-pointer" onClick={() => {
                                                            onShowArchived()
                                                        }}>
                                                            <div className=" flex items-center">
                                                                <ArchiveIcon className="h-4 w-4 text-white" />
                                                            </div>
                                                            <div className="text-sm text-white ml-2">
                                                                {t("app.board.manage_archived")}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="flex justify-end items-center md:ml-4 md:mt-0 ">

                                                    <BoardActionsMenu
                                                        btnClass="flex items-center justify-between mr-4 border-r border-white border-opacity-40"
                                                        classExtra={'w-[300px] '}
                                                        extraClass={'-right-16 '}
                                                        icon={
                                                            (<div className=" flex items-center">
                                                                <FilterIcon className="h-4 w-4 text-white" />
                                                            </div>)
                                                        }
                                                        title={(
                                                            <div className="text-sm text-white ml-1 pr-2"> {t("app.board.filter")}</div>
                                                        )}
                                                    >

                                                        <Filters
                                                            boardData={boardData}
                                                            onRunSearch={onRunSearch}
                                                        />
                                                        
                                                        
                                                    </BoardActionsMenu>



                                                        
                                                    {
                                                        isAdmin && (
                                                            <div className="hidden md:block">
                                                            <div className="flex items-center justify-between mr-2 -mt-1">
                                                                <div className=" flex items-center">
                                                                    {
                                                                        boardData.public ? (
                                                                            <LockOpenIcon className="h-6 w-6 text-white" />
                                                                        ) : (
                                                                            <LockClosedIcon className="h-6 w-6 text-white" />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            </div>
                                                        )
                                                    }
                                                  

                                                    {boardData && (
                                                        <UsersToolbar users={boardData.public ? (boardData?.assigned_users ?? []) : (boardData?.assigned_users ?? []).slice(0, 1)} isActive={(boardData?.assigned_users ?? []).length > 1} className={'relative'}>
                                                            <div className="flex items-center space-x-2 md:mr-3">
                                            

                                                                <div className="block md:hidden">
                                                                    <div className={(boardData?.assigned_users ?? []).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                                                                        {(boardData.assigned_users.length >= 2 ? boardData.assigned_users.slice(0, 2) : boardData.assigned_users).map((user, index) => (
                                                                            index === 0 ? (
                                                                                <UserInitial user={user} ringClass={" ring-yellow-400 "} />
                                                                            ) : (
                                                                                <>
                                                                                    {boardData.public ? <UserInitial user={user} /> : <></>}
                                                                                </>
                                                                            )
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                <div className="hidden md:block">
                                                                    <div className={(boardData?.assigned_users ?? []).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                                                                        {(boardData.assigned_users.length >= 4 ? boardData.assigned_users.slice(0, 4) : boardData.assigned_users).map((user, index) => (
                                                                            index === 0 ? (
                                                                                <UserInitial user={user} ringClass={" ring-yellow-400 "} />
                                                                            ) : (
                                                                                <>
                                                                                    {boardData.public ? <UserInitial user={user} /> : <></>}
                                                                                </>
                                                                            )
                                                                        ))}

                                                                    </div>
                                                                </div>



                                                                {
                                                                    boardData.public && (
                                                                        <>
                                                                            <div className="block md:hidden">
                                                                                {boardData.assigned_users.length > (boardData.assigned_users.length >= 2 ? boardData.assigned_users.slice(0, 2) : boardData.assigned_users).length ? (
                                                                                    <span className="flex-shrink-0  text-white text-xs font-medium leading-5">
                                                                                        +{boardData.assigned_users.length - (boardData.assigned_users.length >= 2 ? boardData.assigned_users.slice(0, 2) : boardData.assigned_users).length}
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>

                                                                            <div className="hidden md:block">
                                                                                {boardData.assigned_users.length > (boardData.assigned_users.length >= 4 ? boardData.assigned_users.slice(0, 4) : boardData.assigned_users).length ? (
                                                                                    <span className="flex-shrink-0 text-white  text-xs font-medium leading-5">
                                                                                        +{boardData.assigned_users.length - (boardData.assigned_users.length >= 4 ? boardData.assigned_users.slice(0, 4) : boardData.assigned_users).length}
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>



                                                                          
                                                                        </>
                                                                    )
                                                                }
                                                               
                                                            </div>
                                                        </UsersToolbar>
                                                    )}
                                                   
                                                    <BoardActionsMenu
                                                        btnClass="flex items-center ml-4"
                                                        icon={
                                                            (<DotsHorizontalIcon className="h-5 w-5 text-white" />)
                                                        }
                                                    >

                                                        {
                                                            isAdmin && (
                                                                <div className="block md:hidden">
                                                                    <div
                                                                        className="cursor-pointer -m-3  flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                                                        onClick={() => {
                                                                            onManageTask(true);
                                                                        }}
                                                                    >
                                                                        <div className="flex h-5 w-5  shrink-0 items-center justify-center text-gray-900">
                                                                            <CollectionIcon aria-hidden="true" />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <p className="text-sm font-medium text-gray-900">
                                                                                {t("app.board.manage_status")}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        }




                                                        <div className="block md:hidden">
                                                            <div
                                                                className="cursor-pointer -m-3  flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                                                onClick={() => {
                                                                    onShowArchived(true);
                                                                }}
                                                            >
                                                                <div className="flex h-5 w-5  shrink-0 items-center justify-center text-gray-900">
                                                                    <ArchiveIcon aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-4">
                                                                    <p className="text-sm font-medium text-gray-900">
                                                                        {t("app.board.manage_archived")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="cursor-pointer -m-3  flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                                            onClick={() => {
                                                                onManageNotification();
                                                            }}
                                                        >
                                                            <div className="flex h-5 w-5  shrink-0 items-center justify-center text-gray-900">
                                                                <BellIcon aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-sm font-medium text-gray-900">
                                                                    {t("app.board.edit_notification")}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        {
                                                            isAdmin && (
                                                                <>
                                                                    <div
                                                                        className="cursor-pointer -m-3  flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                                                        onClick={() => {
                                                                            onManageBoard(true);
                                                                        }}
                                                                    >
                                                                        <div className="flex h-5 w-5  shrink-0 items-center justify-center text-gray-900">
                                                                            <PencilIcon aria-hidden="true" />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <p className="text-sm font-medium text-gray-900">
                                                                                {t("app.board.edit_board")}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="cursor-pointer -m-3  flex items-center rounded-lg px-2 py-3 transition duration-150 ease-in-out hover:bg-gray-50"
                                                                        onClick={() => {
                                                                            onRemove()
                                                                        }}
                                                                    >
                                                                        <div className="flex h-5 w-5  shrink-0 items-center justify-center text-red-900">
                                                                            <TrashIcon aria-hidden="true" />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <p className="text-sm font-medium text-red-900">
                                                                                {t("app.board.remove_board")}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }


                                                    </BoardActionsMenu>




                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-1 flex flex-col justify-between" >
                                            <div id={'board-inner'} className={"  divide-y divide-gray-200 "}>
                                                {children}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};



const Filters = ({ boardData, onRunSearch }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState(false);
    const [isCleansearch, setCleanSearch] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const {
        control,
        setValue,
        watch
    } = useForm();





    return (
        <div className="rounded-md">

            <div class="text-blue-1000 font-semibold mb-1" style={{ fontSize: "14px" }}>{t("app.board.filter_key")}</div>
            <InputSearch onSearch={setSearch} length={1} cleanSearch={isCleansearch} isDynamicSearch={false} hasMargin={false} extraClass={' border border-gray-200 shadow-sm mb-3'} />


            <div class="text-blue-1000 font-semibold mt-3 mb-1" style={{ fontSize: "14px" }}>{t("app.board.filter_date")}</div>
            <Controller
                name={'date_from'}
                rules={{ required: true }}
                control={control}
                render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                }) => (
                    <InputDate
                        label={t("filter.general.date_from")}
                        isShowLabel={false}
                        cleanSearch={isCleansearch}
                        extraClass={'filter'}
                        format={"YYYY-MM-D HH:mm:ss"}
                        placeholder={"Es. 01/12/2021"}
                        activeMinDate={false}
                        startDate={value}
                        disabled={false}
                        isShowTime={false}
                        errors={error}
                        name={name}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    />
                )}
            />

            <div class="mt-1" />
            <Controller
                name={'date_to'}
                rules={{ required: true }}
                control={control}
                render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                }) => (
                    <InputDate
                        label={t("filter.general.date_to")}
                        extraClass={'filter'}
                        isShowLabel={false}
                        cleanSearch={isCleansearch}
                        format={"YYYY-MM-D HH:mm:ss"}
                        placeholder={"Es. 01/12/2021"}
                        activeMinDate={true}
                        selectsEnd={true}
                        minDate={new Date(moment(watch('date_from')).format('MM/DD/YYYY'))}
                        startDate={value}
                        disabled={!watch('date_from')}
                        isShowTime={false}
                        errors={error}
                        name={name}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    />
                )}
            />
            <div class="text-blue-1000 font-semibold mt-3 mb-1" style={{ fontSize: "14px" }}>{t("app.board.board_user")}</div>
            <div className="z-50">
                <CustomUserSelect
                    label={''}
                    users={boardData?.assigned_users ?? []}
                    isShowLabel={false}
                    placeholder={''}
                    multiple={true}
                    includeAll={true}
                    excludeId={''}
                    selectedUsers={selectedUsers}
                    onSelect={(r) => {
                        setSelectedUsers(r);
                    }}
                />
            </div>

            <div className="flex items-center">

                <WhiteButton
                    extraClassName={'w-full mt-3 mr-2'}
                    isLoading={false}
                    isFullWith={true}
                    text={t("app.board.cancel")}
                    onClick={() => {
                        setSearch(false)
                        setCleanSearch(!isCleansearch)
                        setValue('date_from', null);
                        setValue('date_to', null);
                        setSelectedUsers([]);
                        onRunSearch({});

                    }}
                />
                <ButtonSubmit
                    btnColor={'bg-blue-1000 hover:bg-blue-900 mt-3 '}
                    isLoading={false}
                    isFullWith={true}
                    disabled={!(search || (watch('date_from') && watch('date_to')) || selectedUsers.length > 0)}
                    label={t("app.board.confirm_coment")}
                    onClick={() => {
                        let dataParams = {};
                        if ((watch('date_from') && watch('date_to'))){
                            dataParams['date'] = moment(watch('date_from')).format("YYYY-MM-D") + "|" + moment(watch('date_to')).format("YYYY-MM-D");
                        }
                        if (selectedUsers.length > 0) {
                            dataParams['user'] = selectedUsers.map(a => a.id).toString();
                        }
                        if (search) {
                            dataParams['query'] = search;
                        }

                        onRunSearch(dataParams);


                    }}
                />
               
            </div>
           

           


        </div>
    )
}




export default BoardSlidePanel;