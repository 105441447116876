import React, {useEffect, useState} from "react";
import Files from "react-butterfiles";
import {getSignedRequests} from "../../../api/config";
import {sendMedia} from "../../../utils/apiUtils";
import LoaderIcon from "../../../resources/icons/LoaderIcon";
import DocumentAddIcon from "../../../resources/icons/DocumentAddIcon";
import {LinkIcon} from "@heroicons/react/solid/esm";
import {TrashIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import ReportViewer from "../report/report-viewer";
import {PaperClipIcon} from "@heroicons/react/outline";
import {makeid} from "../../../utils/Utils";


const UploadMultifile = ({onUpload, onException, isLoading, label, cancel, onRemove,  onRemoveGeneric,  accept = [], fileTypeLabel = 'DOC, XLS, PDF', size = '10mb', oldFiles = [], genericDocuments = [], isViewMode = false}) => {

    const [fileLen, setFileLen] = useState(0);
    const [url, setUrl] = useState(null);
    const [name, setName] = useState(null);
    const { t } = useTranslation();
    const [isOpenReportView, setOpenReportView] = useState(false);

    const [datafiles, setDataFiles] = useState([]);

    const handleSuccess = async (files)  => {
        setFileLen(files.length);
        // eslint-disable-next-line
        files.map((file, index) => {
            getSignedRequests({params: {ctype: files[index].src.file.type, ext: files[index].src.file.name.split(".").pop()}})
                .then( signed => {
                    sendMedia(signed.action, signed, file.src.file)
                        .then( r => {
                            let url = signed.action + '/' + signed.key;
                            setDataFiles(datafiles => [...datafiles, {rif: makeid(10), reference: url, name: files[index].src.file.name, media_url: url, detail: files[index]}]);
                            setFileLen(state => state - 1);


                        }).catch(e => {
                            onException(e.message);
                    });
                }).catch(e => {
                    onException(e.message);
            });
        });
    };


    useEffect(() => {
        if(datafiles.length > 0 && onUpload){
            onUpload(datafiles);
        }
        // eslint-disable-next-line
    }, [datafiles]);



    const handleErrors = async (errors) => {
        if(errors.length > 0 ){
             if((errors[0].file && errors[0].type === 'maxSizeExceeded') || (errors[0].type === 'multipleMaxSizeExceeded')){
                 onException(errors[0].type);
             }
        }
    };

    useEffect( () => {
        if(cancel){
            setUrl(null);
            setFileLen(0);
        }

    }, [cancel]);

    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={url ? {reference: url} : false}
                title={name ? name : t("app.document.preview_file")}
                isActiveConfirm={false}
                zIndex={' z-50 '}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                    if(!r){
                        setUrl(null);
                    }
                }}

            />


            {
                !isViewMode && (
                    <Files
                        multiple={true} maxSize={size} multipleMaxSize={size}
                        onSuccess={handleSuccess}
                        onError={handleErrors}
                        accept={accept}
                    >
                        {({ browseFiles, getDropZoneProps }) => {
                            return (


                                <div>

                                    {
                                        label && (
                                            <label className="block text-sm font-medium text-gray-700">{label}</label>
                                        )
                                    }

                                    <a href={'#0'}  onClick={browseFiles}  className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" {...getDropZoneProps()}>
                                        <div className="space-y-1 text-center">
                                            {
                                                fileLen > 0 || isLoading? (
                                                    <div>
                                                        <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0"/>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                            >
                                                                <span>{t("app.document.upload_file")}</span>
                                                            </label>
                                                            <p className="pl-1">{t("app.document.drag_file")}</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">{t("app.document.file_type", {types: fileTypeLabel, size: size})}</p>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </a>
                                </div>
                            );
                        }}
                    </Files>
                )
            }




            {
                (datafiles.length > 0 || oldFiles.length > 0 || genericDocuments.length > 0) && (
                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 mt-3">
                        {
                            datafiles.map((data, index) => (
                                <FileRow
                                    key={index}
                                    data={data}
                                    onView={() => {
                                        setUrl(data.reference);
                                        setName(data.name);
                                        setOpenReportView(true);
                                    }}
                                    isActiveDelete={!isViewMode}
                                    onRemove={() => {
                                        setDataFiles(datafiles.filter(r => r.rif !== data.rif));
                                    }}
                                />
                            ))
                        }

                        {
                            oldFiles.map((data, index) => (
                                <FileRow
                                    key={index}
                                    data={data}
                                    onView={() => {
                                        setUrl(data.reference);
                                        setName(data.name);
                                        setOpenReportView(true);
                                    }}
                                    isActiveDelete={!isViewMode}
                                    onRemove={() => {
                                        onRemove(data.id);
                                    }}
                                />
                            ))
                        }

                        {
                            genericDocuments.map((data, index) => (
                                <FileRow
                                    key={index}
                                    data={data}
                                    onView={() => {
                                        setUrl(data.data.file.reference);
                                        setName(data.data.file_name);
                                        setOpenReportView(true);
                                    }}
                                    isActiveDelete={!isViewMode}
                                    onRemove={() => {
                                        onRemoveGeneric(data.id);
                                    }}
                                />
                            ))
                        }

                    </ul>
                )
            }







        </>
    );
};


const FileRow = ({data, onView, onRemove, isActiveDelete = true}) => {
    const { t } = useTranslation();

    return (
        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="ml-2 w-0 flex-1 truncate">{data.name}</span>
            </div>
            <div className="ml-4 flex-shrink-0 flex text-sm">
                <div
                    onClick={() => {
                        onView();
                    }}
                    className="font-medium text-indigo-500 hover:text-indigo-900 cursor-pointer flex text-sm"
                >
                    <LinkIcon
                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900 mr-2"
                        aria-hidden="true"
                    /> {t("app.document.view_file")}
                </div>

                {
                    isActiveDelete && (
                        <div
                            onClick={() => {
                                onRemove();
                            }}
                            className="ml-3 font-medium text-red-500 hover:text-red-900 cursor-pointer flex text-sm"
                        >
                            <TrashIcon
                                className="h-5 w-5 text-red-500 group-hover:text-red-900"
                                aria-hidden="true"
                            /> {t("app.document.remove_file")}
                        </div>
                    )
                }

            </div>
        </li>
    )
}



export default UploadMultifile;
