import React, {useEffect, useState} from 'react';
// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';
import RealTimeChart from "../charts/RealtimeChart";

function PriceHistoryChart({data, height, width}) {


  const [pricesData, setPricesData] = useState([]);
  const [dateData, setDateData] = useState([]);




  const [chartData, setChartData] = useState({
    labels: dateData,
    datasets: [
      // Indigo line
      {
        data: pricesData,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[100])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        clip: 20,
      },
    ],
  });



  useEffect(() => {

    let prices = [];
    let dates = [];

    data.forEach(item => {
      prices.push(item.purchase_price);
      dates.push(new Date(item.purchase_date));
    });

    setPricesData(prices);
    setDateData(dates);


    setChartData({
      labels: dates,
      datasets: [
        // Indigo line
        {
          data: prices,
          fill: true,
          backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          pointHoverBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          pointBorderWidth: 0,
          pointHoverBorderWidth: 0,
          clip: 20,
        },
      ],
    })
    // eslint-disable-next-line
  }, [data]);




  return (
      <>
        <RealTimeChart data={chartData} width={width} height={height} />
      </>
  );
}


export default PriceHistoryChart;
