export function parseQueryString(queryString, focusKey) {
    if (!queryString) {
        return null;
    }
    const paramsArray = queryString.substring(1).split("&");

    for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i];
        const equalsIndex = param.indexOf("=");
        if (equalsIndex > 0) {
            const key = decodeURIComponent(param.substring(0, equalsIndex));
            const value = decodeURIComponent(param.substring(equalsIndex + 1));
            if (focusKey === key) {
                return value;
            }
        }
    }
    return null;
}

export function getActiveKeys(queryString) {
    const filters = [];
    if (!queryString) {
        return filters;
    }
    const paramsArray = queryString.substring(1).split("&");

    for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i];
        const equalsIndex = param.indexOf("=");
        if (equalsIndex > 0) {
            const key = decodeURIComponent(param.substring(0, equalsIndex));
            if (!key.endsWith("_id") && !key.endsWith("_condition") && key !== 'page') {
                filters.push(key);
            }
        }
    }
    return filters;
}

export function generateParams(activeFilters, location) {
    const filters = [];
    for (let i = 0; i < activeFilters.length; i++) {
        let key = getActiveKeys(location).includes(activeFilters[i].filter_name) ? activeFilters[i].filter_name : null;
        if(key && key !== 'page'){
            let condition = parseQueryString(location, key+'_condition');
            let id = parseQueryString(location, key+'_id');
            let value = parseQueryString(location, key);
            let second_value = parseQueryString(location, key+'Second');
            if(id && value){
                let data = {};
                if(condition){
                    data[key+'_condition'] = condition;
                }
                data[key+'_id'] = id;
                data[key] = value;
                if(second_value){
                    data[key+'Second'] = second_value;
                }
                filters.push(data);
            }
        }
    }
    return filters;
}

export function getFinalParams(params) {
    const filters = [];
    for (let i = 0; i < params.length; i++) {
        const key = Object.entries(params[i]).find(([k, value]) => !k.includes('_'))?.[0] ?? false;
        if(key !== false && key !== 'page'){
            let data = {};
            data['filter_name'] = params[i][key+'_id'];
            data['condition'] = params[i][key+'_condition'];
            data['value'] = params[i][key];
            if(params[i][key+'Second']){
                data['second_value'] = params[i][key+'Second'];
            }
            filters.push(data);
        }
    }
    return filters;
}

export function findObjectWithIdKey(obj) {
    const keys = Object.keys(obj);
    return keys.find(key => key.endsWith("_id"));
}


export function generateParamsFormLocation(queryString) {
    const filters = [];
    if (!queryString) {
        return filters;
    }
    const paramsArray = queryString.substring(1).split("&");

    const keys = [];
    for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i];
        const equalsIndex = param.indexOf("=");
        if (equalsIndex > 0) {
            const key = decodeURIComponent(param.substring(0, equalsIndex));
            if (!key.endsWith("_id") && !key.endsWith("_condition") && key !== 'page') {
                keys.push(key);
            }
        }
    }

    if(keys.length > 0){
        for (let i = 0; i < keys.length; i++) {
            let data = {};
            let filter_name = parseQueryString(queryString, keys[i]+'_id');
            let condition = parseQueryString(queryString, keys[i]+'_condition');
            let value = parseQueryString(queryString, keys[i]);
            let valueSecond = parseQueryString(queryString, keys[i]+'Second');

            if(filter_name){
                data['filter_name'] = filter_name;
            }

            if(condition){
                data['condition'] = condition;
            }

            if(value){
                data['value'] = value;
            }

            if(valueSecond){
                data['second_value'] = valueSecond;
            }

            filters.push(data);
        }
    }
    return filters;
}

