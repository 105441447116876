import React from "react";
import placeholder from "../../../resources/images/placeholder.jpeg";
import NumberFormat from "react-number-format";

const ProductCard = ({product, extraDetail = false, onSelect, isSelected, isList, stock= -1}) => {

    return (
        <List isList={isList}>
            <div className={isSelected ? "block  cursor-pointer" : "block hover:bg-indigo-50/[0.5] cursor-pointer"} onClick={onSelect}>
                <div className="flex items-center  py-3 ">
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="ml-2">
                            <img className="object-contain h-12 w-12 rounded-md" src={product?.picture?.reference ?? placeholder} alt="" />
                        </div>

                        <div className="min-w-md flex-1 px-4 ">
                            <div>
                                <p className="text-sm font-medium text-indigo-600">
                                    {product?.product_reference ?? ''}
                                    {
                                        stock >= 0 && (
                                            <b className="text-xs font-medium text-gray-800">
                                                <NumberFormat
                                                    value={stock}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={' - '}
                                                    suffix={' pz in stock'}
                                                />
                                            </b>
                                        )
                                    }
                                </p>
                                {
                                    extraDetail && (
                                        <b className="text-xs font-medium text-gray-800">
                                            {extraDetail}
                                        </b>
                                    )
                                }
                                <p className="text-xs flex-wrap text-gray-500">
                                    {product?.name ?? ''}
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </List>
    );
};

const List = ({isList, children}) => {
    if(isList){
        return ( <li>{children} </li>);
    }
    return children
};




export default ProductCard;
