import React, {useEffect, useState} from "react";

import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import { getPermission, permissionGroup, permissionType } from "../../constants/permissions";

import BoardList from "../../components/board/board-list";
import BoardSlidePanel from "../../components/board/board-slide-panel";
import TaskManage from "./task-manage";
import StatusManage from "./status-manage";
import { getElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { BOARD, COMPANY, STATUSES, TASKS } from "../endpoints";
import BoardManage from "./board-manage";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import { useSelector } from "react-redux";
import ArchivedTasks from "./archived-task";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RemovePopup from "../../components/common/popup/remove-popup";
import BoardManageNotification from "./board-manage-notification";


const BoardDetail = ({ isOpenPanel, setOpenPanel, boardData, onUpdateBoard }) => {

    const { loggedCompany, detail } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useState({});


    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // Manage Notification
    const [isOpenManageNotification, setOpenManageNotification] = useState(false);


    // Board Manage
    const [isLoadingBoard, setLoadingBoard] = useState(true);
    const [isOpenManageBoard, setOpenManageBoard] = useState(false);
    const [boardDetails, setBoardDetails] = useState(boardData);
    const [isOpenArchivedTask, setOpenArchivedTask] = useState(false);


    // Status Manage
    const [isLoadingStatuses, setLoadingStatuses] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [isOpenManageStatus, setOpenManageStatus] = useState(false);
    const [statusDetail, setStatusDetail] = useState(false);


    // Task Manage
    const [isOpenAddTask, setOpenAddTask] = useState(false);
    const [taskDetail, setTaskDetail] = useState(false);
    const [statusTaskDetail, setStatusTaskDetail] = useState(false);
    const [isLoadingTaskes, setLoadingTaskes] = useState(true);
    const [taskes, setTaskes] = useState([]);
    const [backgroundRefresh, setBackgroundRefresh] = useState(false);


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setBoardDetails(boardData);
    }, [boardData]);



    const fetchData = async (signal = null, loader = true) => {
        if (loader) {
            setLoadingTaskes(true);
        }
        if (boardData && isOpenPanel) {
            try {
                const res = await getElement(getPathParam([BOARD, boardData.id, TASKS]), signal, searchParams);
                setTaskes(res.tasks);
                setBackgroundRefresh(false);
            } catch (e) {
                setTaskes([]);
                setBackgroundRefresh(false);
            } finally {
                setLoadingTaskes(false);
                setBackgroundRefresh(false);
            }
        }
    }

    const fetchBoardDetails = async (signal = null, loader = true) => {
        if (loader) {
            setLoadingBoard(true);
        }
        if (boardData && isOpenPanel && loggedCompany) {
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, BOARD, boardData.id]), signal);
                setBoardDetails(res);
            } catch (e) {
                setLoadingBoard(false);
            } finally {
                setLoadingBoard(false);
            }
        }
    }

    const fetchBoardStatuses = async (signal = null, loader = true) => {
        if (loader) {
            setLoadingStatuses(true);
        }
        if (boardData && isOpenPanel) {
            try {
                const res = await getElement(getPathParam([BOARD, boardData.id, STATUSES]), signal);
                setStatuses(res.statuses);
                if (res.statuses.length === 0) {
                    setOpenManageStatus(true);
                }
            } catch (e) {
                setStatuses([]);
            } finally {
                setLoadingStatuses(false);
            }
        }
    }
    
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchBoardDetails(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [boardData, isOpenPanel, loggedCompany]);
    

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchBoardStatuses(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [boardData, isOpenPanel]);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchData(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [boardData, isOpenPanel, backgroundRefresh, searchParams]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (boardData && isOpenPanel && loggedCompany && !isOpenAddTask) {
                fetchBoardStatuses(null, false);
                fetchBoardDetails(null, false);
                fetchData(null, false);
            }
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        if (taskes.length > 0){
            const taskId = query.get('task_id');
            if (taskId) {
                let taskData = taskes.find(r => r.id === taskId);
                if (taskData) {
                    setStatusTaskDetail(taskData.board_status);
                    setTaskDetail(taskData);
                    setOpenAddTask(true);
                }
                navigate(location.pathname);
            }
        }
        
    // eslint-disable-next-line
    }, [taskes]);


 


    const isAdmin = (boardDetails) => {
        let admin = false;
        if (boardDetails){
            if (boardDetails.user.id === detail.id) {
                admin = true;
            }

            if (boardDetails.board_users && boardDetails.board_users.length > 0 && boardDetails.board_users.filter(r => (r.user.id === detail.id && r.is_admin === true)).length > 0) {
                admin = true;
            }
        }
       

        return admin;
    }


    const isMember = (boardDetails) => {
        let admin = false;
        if (boardDetails) {
            if (boardDetails.user.id === detail.id) {
                admin = true;
            }

            if (boardDetails.board_users && boardDetails.board_users.length > 0 && boardDetails.board_users.filter(r => (r.user.id === detail.id && r.can_edit === true)).length > 0) {
                admin = true;
            }
        }


        return admin;
    }


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_TASK_PERMISSION, permissionType.MENU)]} renderBaseOnFail={true}>
            
                <BoardSlidePanel
                    boardData={boardDetails}
                    isOpen={isOpenPanel}
                    onRunSearch={(searchParams) => {
                        setSearchParams(searchParams);
                    }}
                    setIsOpen={setOpenPanel}
                    isAdmin={isAdmin(boardDetails)}
                    isMember={isMember(boardDetails)}
                    setClean={() => {
                        setStatuses([])
                        setBoardDetails(false);
                        setStatusDetail(false);
                        setTaskDetail(false);
                        setTaskes([]);
                    }}
                    onManageTask={() => {
                        setStatusDetail(null);
                        setOpenManageStatus(true);
                    }}
                    onManageBoard={() => {
                        setOpenManageBoard(true);
                    }}
                    onShowArchived={() => {
                        setOpenArchivedTask(true)
                    }}
                    onManageNotification={() => {
                        setOpenManageNotification(true);
                    }}
                    onRemove={() => {
                        const removeDetail = {
                            title: t("app.board.remove_board_title"),
                            message: t("app.board.remove_board_description"),
                            endpoint: getPathParam([COMPANY, loggedCompany.id, BOARD, boardDetails?.id ?? ''])
                        };
                        setRemoveDetail(removeDetail);
                        setOpenRemove(true);
                    }}
                >
                <LoaderWrapper isLoading={isLoadingBoard}>

                    {
                        boardDetails && (
                            <div class={"grow relative mt-3 h-[93vh]"}>
                                <BoardList
                                    isAdmin={isAdmin(boardDetails)}
                                    isMember={isMember(boardDetails)}
                                    withScrollableColumns={true}
                                    //BOARD MANAGE
                                    boardData={boardDetails}
                                    //STAUS MANAGE
                                    statuses={statuses}
                                    isStatusLoading={isLoadingStatuses}
                                    // TASK MANAGE
                                    onAddTask={(status) => {
                                        setStatusTaskDetail(status);
                                        setTaskDetail(false);
                                        setOpenAddTask(true);
                                    }}
                                    isLoadingTaskes={isLoadingTaskes}
                                    taskes={taskes}
                                    onEditTask={(status, taskData) => {
                                        setStatusTaskDetail(status);
                                        setTaskDetail(taskData);
                                        setOpenAddTask(true);
                                    }}

                                />
                            </div>
                        )
                    }


                    {
                        isOpenManageStatus && isAdmin(boardDetails) && (
                            <StatusManage
                                isOpen={isOpenManageStatus}
                                setIsOpen={setOpenManageStatus}
                                boardData={boardDetails}
                                statusDetail={statusDetail}
                                statuses={statuses ?? []}
                                onForceClose={() => {
                                    setOpenPanel(false);
                                    setStatuses([]);
                                }}
                                onUpdateList={(r) => {
                                    setStatuses(r)
                                    setBackgroundRefresh(true);
                                }}
                            />
                        )
                    }


                    {
                        (statusTaskDetail && boardData) && (
                            <TaskManage
                                canEdit={isAdmin(boardDetails) || isMember(boardDetails)}
                                isAdmin={isAdmin(boardDetails)}
                                isMember={isMember(boardDetails)}
                                isOpen={isOpenAddTask}
                                setIsOpen={setOpenAddTask}
                                boardData={boardDetails}
                                statusDetail={statusTaskDetail}
                                taskDetail={taskDetail}
                                onRefresh={(r) => {
                                    fetchData(null, false);
                                    fetchBoardDetails(null, false);
                                }}
                                setClean={() => {
                                    setStatusTaskDetail(false);
                                    setTaskDetail(false);
                                }}
                                onOpenReletadTask={(taskData) => {
                                    setTaskDetail(taskData);                                    
                                }}
                            />
                        )
                    }



                    {
                        isOpenManageBoard && isAdmin(boardDetails) && (
                            <BoardManage
                                isOpen={isOpenManageBoard}
                                setIsOpen={setOpenManageBoard}
                                boardData={boardDetails}
                                onUpdateBoard={(r) => {
                                    setBoardDetails(r);
                                }}

                            />
                        )
                    }


                    {
                        isOpenManageNotification && (
                            <BoardManageNotification
                                isOpen={isOpenManageNotification}
                                setIsOpen={setOpenManageNotification}
                                boardData={boardDetails}
                                onUpdateBoard={(r) => {
                                    setBoardDetails(r);
                                }}

                            />
                        )
                    }


                    {
                        isOpenArchivedTask && (
                            <ArchivedTasks
                                isOpen={isOpenArchivedTask}
                                isAdmin={isAdmin(boardDetails)}
                                setIsOpen={() => {
                                    setOpenArchivedTask(false);
                                }}
                                onRefreshArchive={() => {
                                    fetchData(null, false);
                                }}
                                boardData={boardDetails}
                                onOpenReletedTask={(status, taskData) => {
                                    setStatusTaskDetail(status);
                                    setTaskDetail(taskData);
                                    setOpenAddTask(true);
                                }}
                            />
                        )
                       
                    }

                    {isOpenRemove && (
                        <RemovePopup
                            isOpen={isOpenRemove}
                            setIsOpen={setOpenRemove}
                            detail={removeDetail}
                            setDetail={(res) => {
                                setOpenPanel(false);
                                onUpdateBoard(res);
                            }}
                        />
                    )}




                </LoaderWrapper>

                </BoardSlidePanel>

        </BasePermissionWrapper>
    );
};







export default BoardDetail;
