import React from "react";
import {useTranslation} from "react-i18next";
import { useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";
import BaseList from "../common/base-list";
import {getPathParam} from "../../../utils/converter";
import {ADDRESS, ADDRESSES, COMPANY, CONNECTION} from "../../../api/endpoints";
import {PaperClipIcon} from "@heroicons/react/outline";
import ActionsMenu from "../../common/table/actions-menu";
import CardDetail from "../../common/list/card-detail";
import TableDetail from "../../common/list/table-detail";
import AddressAutocomplete from "../../common/form/addressAutocomplete";

export default function ConnectionAddresses() {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const { connectionId, typeActive, removeActive, onRefresh } = useOutletContext();

    return (
        <>
            <div className="max-w-5xl mx-auto  py-6 px-4 sm:px-6 lg:px-8">

                <div className="flex justify-between items-center flex-wrap gap-4 ">
                    <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">
                        {t("app.connection.addresses")}
                    </h1>
                </div>

            </div>

            <div className="max-w-5xl mx-auto  px-4 sm:px-6 lg:px-8">

                <BaseList
                    columns={[
                        t("app.connection.address"),
                        t("app.connection.city"),
                        "",
                    ]}
                    endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ADDRESSES])}
                    storeEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ADDRESS])}
                    updateEndpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ADDRESS, ':id'])}
                    baseResult={{ total_rows: 0, addresses: [] }}
                    resultLabel={'addresses'}
                    title={t("app.connection.addresses")}
                    showHeader={true}
                    mobRow={MobRow}
                    deskRow={DeskRow}
                    addForm={AddForm}
                    icon={PaperClipIcon}
                    addFormLabel={t("app.catalog.add", { type: t("app.connection.address") })}
                    btnAddLabel={t("app.catalog.add", { type: t("app.connection.address") })}
                    editForm={EditForm}
                    editTitle={t("app.connection.address")}
                    isActiveEdit={true}
                    isActiveFileViewer={true}
                    typeActive={typeActive}
                    removeActive={removeActive}
                    onUpdateParent={() => {
                        onRefresh(true);
                    }}
                />
            </div>
        </>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ADDRESS, element.id])
    };



    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element?.address ?? '-'}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.connection.city")}>
                        {element?.city?.name ?? '-'}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { connectionId } = useOutletContext();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, CONNECTION, connectionId, ADDRESS, element.id])
    };


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    {element?.address ?? '-'}
                </div>
            </TableDetail>

            <TableDetail>
                {element?.city?.name ?? '-'}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {
    return (
        <AddressAutocomplete exception={exception} isLoading={isLoading} onSubmitData={onSubmitData} />
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    return (
        <div className="mt-4">
            <AddressAutocomplete exception={exception} isLoading={isEditLoading} data={data} onSubmitData={onSubmitData} />
        </div>
    );
}
