import {
    COMPANY,
    IMPORT,
    IMPORT_MODEL,
    IMPORTS,
    IMPORT_MODELS,
    EXPORT,
    FILTERS,
    COUNT,
    PRODUCT, EXPORTS
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// file
export const postImport = ({company_id, import_group, data}) =>
    API.post(getPathParam([COMPANY, company_id, IMPORT, import_group]), data);

export const getImport = ({company_id, import_id}) =>
    API.get(getPathParam([COMPANY, company_id, IMPORT, import_id]));

export const getImports = ({company_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, IMPORTS]) + serialize(params) );

export const getProductExports = ({company_id, params}) =>
    API.get(getPathParam([COMPANY, company_id, EXPORTS, PRODUCT])  + serialize(params));

export const getExportFilter = ({company_id, import_type}) =>
    API.get(getPathParam([COMPANY, company_id, EXPORT, import_type, FILTERS]));

export const putExportData = ({company_id, import_type, data}) =>
    API.put(getPathParam([COMPANY, company_id, EXPORT, import_type]), data);

export const getExportFilterCount = ({company_id, import_type, data}) =>
    API.put(getPathParam([COMPANY, company_id, EXPORT, import_type, COUNT]), data);

export const getExportData = ({company_id, entity, params}) =>
    API.get(getPathParam([COMPANY, company_id, EXPORTS, entity])  + serialize(params));


// import models
export const postImportModel = ({company_id, import_type, data}) =>
    API.post(getPathParam([COMPANY, company_id, IMPORT_MODEL, import_type]), data);

export const getImportModel = ({company_id, import_id}) =>
    API.get(getPathParam([COMPANY, company_id, IMPORT_MODEL, import_id]));

export const getImportModels = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, IMPORT_MODELS]) + serialize(params), {signal: signal} );
