import React from "react";
import {useTranslation} from "react-i18next";

const statusStyles = {
    confirmed: 'bg-green-100 text-green-800',
    pending: 'bg-yellow-100 text-yellow-800',
    disconnected: 'bg-red-100 text-red-800',
};

const statusStylesV2 = {
    confirmed: 'text-green-800',
    pending: 'text-yellow-800',
    disconnected: 'text-red-800',
};


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ConnectionStatusBar = ({status}) => {
    const { t } = useTranslation();


    const statusTitles = {
        pending: t("app.customers.waiting_connection"),
        confirmed: t("app.customers.confirmed_connection"),
        disconnected: t("app.customers.canceled_connection")
    };

    return (
        <span
            className={classNames(
                statusStyles[status],
                'px-6 py-1 inline-flex items-center text-xs font-medium'
            )}
        >
        {statusTitles[status]}
    </span>
    )
};

export const ConnectionStatus = ({status}) => {
    const { t } = useTranslation();


    const statusTitles = {
        pending: t("app.customers.waiting_connection"),
        confirmed: t("app.customers.confirmed_connection"),
        disconnected: t("app.customers.canceled_connection")
    };

    return (
        <span
            className={classNames(
                statusStylesV2[status],
                'py-1 inline-flex items-center text-xs font-medium'
            )}
        >
        {statusTitles[status]}
    </span>
    )
};
