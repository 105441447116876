import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../../../../../../../../components/common/popup/form-popup";
import {Warning} from "../../../../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../../../../components/common/form/input-submit";
import {putContainerPacking} from "../../../../../../../../api/container";
import { useParams } from "react-router-dom";
import InputFormattedNumber from "../../../../../../../../components/common/form/input-formatted-number";
import InputSelect from "../../../../../../../../components/common/form/input-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchPackagingTypes} from "../../../../../../../../reducers/configReducer";
import {getSelectParam} from "../../../../../../../../utils/converter";
import {useTranslation} from "react-i18next";

export default function AddBoxToContainer(
    {
        isOpen,
        setIsOpen,
        boxDetail,
        groupId,
        setDetail,
    }) {
    const {
        handleSubmit,
        setValue,
        control
    } = useForm();

    let {id, container_id}  = useParams();
    const { t } = useTranslation();

    const { packagingTypesList, isPackagingTypesListLoading } = useSelector((state) => state.config);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPackagingTypes());
    }, [dispatch])


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    const updateBox = async (data) => {
        setLoading(true);
        putContainerPacking({ shipping_id: id, container_id: container_id, group_id: groupId.group_id, sub_group_id: groupId.sub_group_id, packing_id: boxDetail.id, data })
            .then((response) => {
                setLoading(false);
                setDetail(response);
                setIsOpen(false)
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        setValue('length', boxDetail.length)
        setValue('width', boxDetail.width)
        setValue('height', boxDetail.height)
        setValue('packaging_type', getSelectParam(boxDetail.packaging_type, "name"))
    }, [boxDetail, setValue]);




    const onSubmit = async (data) => {
        data['id'] = boxDetail.id;
        await updateBox(data);
    };


    return (
        <FormPopup
            title={t("app.container.update_packagings")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">

                    <Controller
                        name="packaging_type"
                        rules={{required: false}}
                        control={control}
                        render={({field: { onChange, value, name },
                                     fieldState: { error }}) => (
                            <InputSelect
                                label={t("app.container.packaging_type")}
                                isShowLabel={false}
                                name={name}
                                options={packagingTypesList}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isLoading={isPackagingTypesListLoading}
                                isDisabled={isPackagingTypesListLoading}
                                errors={error}
                            />
                        )}
                    />

                    <div className=" grid md:grid-cols-3 lg:grid-cols-3 gap-y-5 gap-x-5 sm:grid-cols-3">

                        <Controller
                            name="length"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.length")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="width"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.width")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="height"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                <InputFormattedNumber
                                    label={t("app.container.height")}
                                    isShowLabel={false}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={' cm'}
                                    errors={error}
                                />
                            )}
                        />

                    </div>



                </div>

                {/* Warning*/}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.container.update_packagings")}
                    />
                </div>
            </form>



        </FormPopup>
    );
}
