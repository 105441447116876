import React from "react";
import Initials from "../avatar/initials";

const CompanyCell = ({ color, name }) => {
  return (
    <div className="flex items-center gap-1 text-sm truncate">
      <Initials color={color} name={name} isVerified={false} />
        {name}
    </div>
  );
};

export default CompanyCell;
