import React, {useEffect, useState} from "react";
import OnboardingIndex from "./index";
import {useForm} from "react-hook-form";
import InputSubmit from "../../components/common/form/input-submit";
import {useNavigate} from "react-router-dom";
import {ONBOARDING, ONBOARDING_ADD_COMPANY, ONBOARDING_DONE} from "../endpoints";
import {onboardingDetails, userOnboardingStatus} from "../../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {Warning} from "../../components/common/alert/banner";
import InputRadioSquare from "../../components/common/form/input-radio-square";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import IntegrationIcon from "../../resources/icons/IntegrationIcon";
import {putUser} from "../../api/user";
import {useTranslation} from "react-i18next";

const StepCompanyType = () => {

    const { detail } = useSelector((state) => state.user);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [exception, setException] = useState(false);
    const { t } = useTranslation();

    let navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(detail){
            setValue('id', detail.id);
            if(detail.company_type){
                setValue('company_type', detail.company_type);
            }
        }

    }, [detail, setValue]);


    const updateUserDetail = async (data) => {
        if(detail){
            setUpdateLoading(true);
            putUser({user_id: detail.id, data})
                .then(response => {
                    dispatch(onboardingDetails(response));
                    if( response.onboarding_status === userOnboardingStatus.CONFIRMED){
                        navigate(ONBOARDING + ONBOARDING_DONE);
                    }else{
                        navigate(ONBOARDING + ONBOARDING_ADD_COMPANY);
                    }
                    setUpdateLoading(false);
                }).catch(e => {
                setException(e.message);
                setUpdateLoading(false);
            });
        }
    };


    const onSubmit = async (data) => {
        updateUserDetail(data)
    };



    return (
        <OnboardingIndex>
            <div className="max-w-lg mx-auto px-4 py-8 w-3/4">

                <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("onboarding.company_type.title")}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:flex space-y-3 sm:space-y-0 sm:space-x-4 mb-8">
                        <InputRadioSquare
                            name={'company_type'}
                            errors={errors.company_type}
                            input={{...register("company_type", { required: true })}}
                            label={t("onboarding.company_type.exporter")}
                            description={t("onboarding.company_type.exporter_description")}
                            defaultChecked={true}
                            value={'exporter'}
                            icon={(<ShippingIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2"/>)}
                        />

                        <InputRadioSquare
                            name={'company_type'}
                            errors={errors.company_type}
                            input={{...register("company_type", { required: true })}}
                            label={t("onboarding.company_type.importer")}
                            description={t("onboarding.company_type.importer_description")}
                            value={'importer'}
                            icon={(<IntegrationIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2"/>)}
                        />


                    </div>

                    {/* Warning */}
                    {exception && (
                        <Warning
                            color={'red'}
                            message={exception}
                        />
                    )}

                    <div className="before:flex-1 flex items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isUpdateLoading}
                            label={t("onboarding.company_type.confirm")}
                        />
                    </div>
                </form>


            </div>
        </OnboardingIndex>
    );
};

export default StepCompanyType;
