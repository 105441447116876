import React, {  useState } from "react";
import {  useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { WhiteButton } from "../../../../components/layout/page-header";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import ListSection from "../../../../components/layout/list-section";
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import { DELIVERY } from "../../../../constants/config";
import { hasSubscriptionPermission } from "../../../../components/partials/restricted/subscription-wrapper";
import { permissionGroup, permissionType, sp } from "../../../../constants/permissions";
import { hasPermissionFor } from "../../../../components/partials/restricted/base-permission-wrapper";
import { FulfillmentDeskRow, FulfillmentMobRow } from "../../fulfillment/fulfillment";
import { MailIcon } from "@heroicons/react/outline";


// FORWARDER DETAILS
export const FulfillmentCard = ({ responseData, onRefresh, onUpdateData, canEdit }) => {

    const { t } = useTranslation();
    const { permissions, subscriptions } = useSelector((s) => s.userCompany);

    // DETAILS
    // eslint-disable-next-line
    const [query, setQuery] = useState("");
    // eslint-disable-next-line
    const [isFetchLoading, setFetchLoading] = useState(false);
    // eslint-disable-next-line
    const [fulfillments, seFulfillments] = useState(responseData?.fulfillments ?? []);

    // eslint-disable-next-line
    const [isOpenManage, setIsOpenManage] = useState(false);
    // eslint-disable-next-line
    const [manageDetails, setManageDetails] = useState(false);


    // REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    //Confirm
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    // eslint-disable-next-line
    const [confirmDetail, setConfirmDetail] = useState({});


    // TABLE
    const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const handleSelectAll = () => {
      
    };


    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let fList = fulfillments.find(r => r.id === id);
        setIsCheck([...isCheck, fList]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };


    const columns = [
        {
            header: t("app.common.customer") + "/" + t("app.common.supplier"),
            id: 'business_name',
            accessor: r => r.customer.business_name,
        },
        {
            header: responseData.type === DELIVERY ? t("app.fulfillment.delivery") : t("app.fulfillment.pickup"),
            id: 'destination',
            accessor: r => r?.destination?.name ?? '-',
        },
        {
            header: t("app.fulfillment.reference"),
            id: 'reference',
            accessor: "reference"
        },
        {
            header: responseData.type === DELIVERY ? t("app.fulfillment.delivery_date") : t("app.fulfillment.pickup_date"),
            id: 'expected_date',
            accessor: r => new Date(r.expected_date).getTime()
        },
        {
            header: t("app.shippings.status"),
            id: 'status',
            accessor: "status"
        },
        {
            header: t("app.common.user"),
            id: 'user',
            accessor: r => r?.user?.fullname ?? '-',
        }
    ];

    return (
        <>
            <div className="mt-6 ">

                <div className="border border-gray-100 relative">
                    <ListSection
                        pageLimit={1000}
                        title={t("app.common.customer") + "/" + t("app.common.supplier")}
                        columns={columns}
                        totalRows={fulfillments.length}
                        list={fulfillments}
                        onSearch={setQuery}
                        isLoading={isFetchLoading}
                        mobileRow={FulfillmentMobRow}
                        hasCheckbox={true}
                        isActiveSelectAll={false}
                        selectAll={selectAll}
                        handleSelectAll={handleSelectAll}
                        desktopRow={FulfillmentDeskRow}
                        isTableSort={true}
                        sortBy={sortBy}
                        onSortBy={(n) => {
                            setSortBy(n);
                        }}
                        activeDynamicFilter={false}
                        extras={{
                            onRemove: (r, detail) => {
                                setOpenRemove(r);
                                setRemoveDetail(detail);
                            },
                            onManage: (r, element) => {
                                setManageDetails(element);
                                setIsOpenManage(r);
                            },
                            isCustomConfig: true,
                            isCheck,
                            handleClick,
                            isSubscriptionActive: hasSubscriptionPermission(subscriptions, sp.FULFILLMENT_INTERNAL_PACKAGE),
                            isLinked: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.EDIT),
                            canView: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.VIEW),
                            canDelete: hasPermissionFor(permissions, permissionGroup.FULFILLMENT, permissionType.DELETE),
                        }}
                    >



                        {
                            (isCheck.length > 0) && (
                                <div className="inline-flex items-center justify-center">
                                    <div className={"ml-3"}>
                                        <WhiteButton
                                            onClick={() => {

                                            }}
                                            icon={
                                                <MailIcon
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t('app.booking.replay_mail')}
                                        />
                                    </div>

                                </div>
                            )
                        }

                    </ListSection>
                </div>
            </div>

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        //setNegotiations(r.negotiations);
                    }}
                />
            )}



            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() => {
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        //setNegotiations(r.negotiations);
                    }}
                />
            )}


        </>
    )
}



