import React, { useEffect, useState } from "react";
import { ActionButton } from "../../../../components/layout/page-header";
import { useSelector } from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import { COMPANY } from "../../../endpoints";
import { getPathParam } from "../../../../utils/converter";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper, { hasPermissionFor } from "../../../../components/partials/restricted/base-permission-wrapper";
import ListSection from "../../../../components/layout/list-section";
import { getPermission, permissionGroup, permissionType } from "../../../../constants/permissions";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import { PlusIcon } from "@heroicons/react/outline";
import { getElement, postElement, putElement } from "../../../../api/config";
import { PRODUCT_ENCCODE, PRODUCT_ENCCODES } from "../../../../api/endpoints";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../../components/common/popup/form-popup";
import InputText from "../../../../components/common/form/input-text";
import { Warning } from "postcss";
import InputSubmit from "../../../../components/common/form/input-submit";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import TableDetail from "../../../../components/common/list/table-detail";
import ManageEncoding from "./manage-encoding";
import CardDetail from "../../../../components/common/list/card-detail";

const Encodings = () => {
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);
    const { t } = useTranslation();


    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.DELETE);

    const [isLoading, setLoading] = useState(true);
    const [encodings, setEncodings] = useState({ total_rows: 0, product_encodes: [] });
    const [query, setQuery] = useState("");
    
    
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});
    const [needRefresh, setNeedRefresh] = useState(false);

    const [isOpenManage, setOpenManage] = useState(false);
    const [encodingData, setEncodingData] = useState({});


    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editDetail, setEditDetail] = useState({});

    
    


    const columns = [
        t("app.catalog.code"),
        t("app.catalog.name"),
        t("app.catalog.number_limit"),
        "",
    ];

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (loggedCompany){
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODES]), signal, { query: (query ? query : null) });
                    setEncodings(res);
                } catch (e) {
                    setEncodings({ total_rows: 0, product_encodes: [] });
                } finally {
                    setLoading(false);
                }
            }
           
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [query, loggedCompany, needRefresh]);


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.LIST)]} renderBaseOnFail={true}>
        
            <ListSection
                title={t("app.catalog.encoding")}
                columns={columns}
                totalRows={encodings.total_rows}
                list={encodings?.product_encodes ?? []}
                onSearch={setQuery}
                isLoading={isLoading}
                mobileRow={MobRow}
                desktopRow={DeskRow}
                extras={{
                    canView,
                    canDelete,
                    canCreate,
                    canEdit,
                    onRemove: (r, detail) => {
                        setOpenRemove(r);
                        setRemoveDetail(detail);
                    },
                    onEdit: (r, detail) => {
                        setEditDetail(detail);
                        setOpenEdit(r);
                    },
                    onManage: (detail) => {
                        setEncodingData(detail);
                        setOpenManage(true);
                    },
                }}
            >
                {
                    canCreate && (
                        <ActionButton
                            icon={
                                <PlusIcon
                                    className="w-4 h-4"
                                />
                            }
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            text={t("app.catalog.add_encoding")}
                        />
                    )
                }


            </ListSection>


            {
            (isOpen && canCreate) && 
                <AddEncoding 
                    isOpen={isOpen} 
                    setIsOpen={setIsOpen} 
                    onOpenEcodingDetail={(r) => {
                        setEncodingData(r);
                        setOpenManage(true);
                    }}
                    onNeedRefresh={() => {
                        setNeedRefresh(!needRefresh);
                    }}
                />
            }


            {isOpenRemove && canDelete && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    onRemoveConfirmed={() => {
                        setNeedRefresh(!needRefresh);
                    }}
                />
            )}


            {isOpenEdit && (canEdit || canView) && (
                <EditEncoding
                    isOpen={isOpenEdit}
                    setIsOpen={setOpenEdit}
                    editDetail={editDetail}
                    onUpdateDetails={(r) => {
                        setNeedRefresh(!needRefresh);
                    }}
                />
            )}

            {canEdit && (
                <ManageEncoding
                    isOpenPanel={isOpenManage}
                    setOpenPanel={setOpenManage}
                    encodingData={encodingData}
                />

            )}

           
        </BasePermissionWrapper>
    );
};

const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODE, element.id])
    };


    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="items-center flex justify-between text-gray-600">
                    {element.title}
                    {
                        (extras.canView || extras.canEdit || extras.canDelete) && (
                            <ActionsMenu>
                                {(extras.canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onManage(element);
                                    }}
                                    >
                                        {t("app.catalog.manage_encoding")}
                                    </button>
                                )}

                                {(extras.canEdit || extras.canView) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )}

                                {extras.canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )}
                            </ActionsMenu>
                        )
                    }
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.code")}>
                        {element.code}
                    </CardDetail>
                    <CardDetail title={t("app.catalog.number_limit")}>
                        {element.number_limit}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODE, element.id])
    };

    return (
        
        <tr key={element.id}>
            <TableDetail>
                {element?.code ?? '-'}
            </TableDetail>
            <TableDetail extraClass="max-w-lg">
                <div onClick={() => {
                    if ((extras.canEdit)) {
                        extras.onManage(element);
                    }
                }} className="flex items-center space-x-3 cursor-pointer">
                    <div className="truncate hover:text-gray-600">
                        {element.title}
                    </div>
                </div>
            </TableDetail>

         

            <TableDetail>
                {element?.number_limit ?? '-'}
            </TableDetail>
            
            <TableDetail extraClass={'flex justify-end'}>
                <ActionsMenu>

                    {(extras.canEdit) && (
                        <button className={'text-left'} onClick={() => {
                            extras.onManage(element);
                        }}
                        >
                            {t("app.catalog.manage_encoding")}
                        </button>
                    )}

                    {(extras.canEdit || extras.canView) && (
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                    )}

                    {extras.canDelete && (
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    )}
                </ActionsMenu>
            </TableDetail>

        </tr>
    );
};

const AddEncoding = ({ isOpen, setIsOpen, onNeedRefresh, onOpenEcodingDetail }) => {

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const { loggedCompany } = useSelector((state) => state.user);

    const { t } = useTranslation();
    const [isStoreLoading, setStoreLoading] = useState(false);
    const [exception, setException] = useState(false);


    const onSubmit = async (data) => {
        setStoreLoading(true);
        setException(false);
        postElement(getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODE]), data)
            .then(response => {
                onOpenEcodingDetail(response);
                onNeedRefresh(true);
                setIsOpen(false);
                setStoreLoading(false);
            }).catch(e => {
                setException(e.message);
                setStoreLoading(false);
            });
    };

    useEffect(() => {
        if (watch('code')) {
            setValue('generetor_charaters', watch('code').length);

        }
        // eslint-disable-next-line
    }, [watch('code')]);





    return (
        <FormPopup
            title={t("app.catalog.add_encoding")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                        
                        <InputText
                            id={"title"}
                            type={"text"}
                            errors={errors.title}
                            input={{ ...register("title", { required: true }) }}
                            label={t("app.catalog.name")}
                            isRequired={true}
                        />

                        <Controller
                            name="number_limit"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.catalog.number_limit")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    isRequired={true}
                                    errors={error}
                                />
                            )}
                        />

                        <InputText
                            id={"code"}
                            type={"text"}
                            errors={errors.code}
                            input={{
                                ...register("code", { required: true }),
                            }}
                            label={t("app.catalog.code")}
                            isRequired={true}
                        />

                        <Controller
                            name="generetor_charaters"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.catalog.generetor_charaters")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    isDisabled={true}
                                    errors={error}
                                />
                            )}
                        />

                      

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isStoreLoading}
                        label={t("app.form.add")}
                    />
                </div>
            </form>
        </FormPopup>
    );
}

const EditEncoding = ({ isOpen, setIsOpen, onUpdateDetails, editDetail }) => {

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const { t } = useTranslation();
    const [isStoreLoading, setStoreLoading] = useState(false);
    const [exception, setException] = useState(false);



    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ENCODING, permissionType.EDIT);


    const onSubmit = async (data) => {
        setStoreLoading(true);
        setException(false);
        putElement(getPathParam([COMPANY, loggedCompany.id, PRODUCT_ENCCODE, editDetail.id]), data)
            .then(response => {
                onUpdateDetails(response);
                setIsOpen(false);
                setStoreLoading(false);
            }).catch(e => {
                setException(e.message);
                setStoreLoading(false);
            });
    };

    useEffect(() => {

        if (editDetail){
            setValue('id', editDetail.id);
            setValue('title', editDetail.title);
            setValue('code', editDetail.code);
            setValue('generetor_charaters', editDetail.code.length);
            setValue('number_limit', editDetail.number_limit);
        }
        
        // eslint-disable-next-line
    }, [editDetail, setValue]);

    useEffect(() => {
        if (watch('code')) {
            setValue('generetor_charaters', watch('code').length);
        }
        // eslint-disable-next-line
    }, [watch('code')]);





    return (
        <FormPopup
            title={t("app.catalog.update_encoding")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">

                        <InputText
                            id={"title"}
                            type={"text"}
                            errors={errors.title}
                            input={{ ...register("title", { required: true }) }}
                            label={t("app.catalog.name")}
                            isRequired={true}
                            isDisabled={!canEdit}
                        />


                        <Controller
                            name="number_limit"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.catalog.number_limit")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    isRequired={true}
                                    isDisabled={!canEdit}
                                    errors={error}
                                />
                            )}
                        />

                        <InputText
                            id={"code"}
                            type={"text"}
                            errors={errors.code}
                            input={{
                                ...register("code", { required: true }),
                            }}
                            label={t("app.catalog.code")}
                            isDisabled={!canEdit}
                            isRequired={true}
                        />

                        <Controller
                            name="generetor_charaters"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.catalog.generetor_charaters")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    isDisabled={true}
                                    errors={error}
                                />
                            )}
                        />



                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                   

                    {
                        canEdit && (
                            <InputSubmit
                                isLoading={isStoreLoading}
                                label={t("app.form.save")}
                            />
                        )
                    }
                </div>
            </form>
        </FormPopup>
    );
}


export default Encodings;
