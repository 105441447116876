import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Navigate } from "react-router";
import {resendMail, userOnboardingStatus} from "../../reducers/userReducer";
import HeaderProfiling from "../../components/layout/header-profiling";
import {SuccessBoxBtn} from "../../components/common/alert/success-box";
import {useTranslation} from "react-i18next";
import {INDEX} from "../endpoints";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const VerifyRegistration = () => {
  const { detail, onboardingStatus, isResendMailLoading, resendMailException } = useSelector((state) => state.user);
  const isVerificationNeeded = onboardingStatus === userOnboardingStatus.NEED_VERIFICATION;
  const dispatch = useDispatch();
  const { t } = useTranslation();


  if(!isVerificationNeeded){
      return (<Navigate to={INDEX} />)
  }

  return (
     <main className="bg-white">

       <div className="relative flex">

         {/* Content */}
         <div className="w-full md:w-1/2">

           <div className="min-h-screen h-full flex flex-col after:flex-1">
             <div className="flex-1">

               {/* Header */}
               <HeaderProfiling
                   isActiveSignin={true}
               />

             </div>

             <div className="max-w-lg mx-auto px-4 py-8 w-3/4">

                <SuccessBoxBtn
                    title={t('oauth.register.success_verify_title', {firstname: detail.firstname, lastname:detail.lastname })}
                    message={t('oauth.register.success_verify_message')}
                    label={t('oauth.register.btn_verify_name')}
                    isLoading={isResendMailLoading}
                    loadingLabel={t('app.common.loading')}
                    exception={resendMailException}
                    onClick={() => {dispatch(resendMail(detail.id))}}

                />
             </div>

           </div>

         </div>

         {/* Image */}
           <SidebarProfiling />


       </div>
     </main>
  );
};

export default VerifyRegistration;
