import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import XIcon from "@heroicons/react/solid/XIcon";

export default function RequiredPopup({isOpen, setIsOpen, requireds, title, description}) {




    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >



                    {
                            requireds.length > 0 ? (
                                <div className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-red-50 shadow-xl">

                                    <div className="flex justify-between   py-2 px-4 items-center">
                                        <h2 className=" text-xl leading-8 font-extrabold tracking-tight text-red-800">
                                            {title}
                                        </h2>
                                        <div>
                                            <button className=" border-0" onClick={() => { setIsOpen(false); }}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-red-800" /></button>
                                        </div>

                                    </div>

                                    <p className="mb-2 px-4  text-sm text-red-800">
                                        {description}
                                    </p>


                                    <div className="mb-6 p-4">
                                        <p className="text-sm ">
                                            <div>
                                                <div className="rounded-md bg-red-50 ">
                                                    <div className="flex">
                                                        <div className=" text-sm text-red-700">
                                                            <ul className="list-disc space-y-1 pl-5">
                                                                {requireds.map((value, idx) => (
                                                                    <li key={value.title}>{value.title}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>



                                </div>
                            ) : (
                                    <div className="inline-block w-full max-w-md my-8 text-left align-middle transition-all transform bg-red-50 shadow-xl ">

                                        <div className="flex justify-between   py-2 px-4 items-center">
                                            <h2 className=" text-xl leading-8 font-extrabold tracking-tight text-red-800">
                                                {title}
                                            </h2>
                                            <div>
                                                <button className=" border-0" onClick={() => { setIsOpen(false); }}><XIcon className="w-4 h-4 fill-current flex-shrink-0 text-red-800" /></button>
                                            </div>

                                        </div>
                                        
                                        <div className="mt-2 p-4 pb-10">
                                            <p className="text-sm text-red-800">
                                                {description}
                                            </p>
                                        </div>
                                    </div>
                            )
                    }
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
