import React, {useEffect, useState} from "react";
import Files from "react-butterfiles";
import {getSignedRequests} from "../../../api/config";
import {sendMedia} from "../../../utils/apiUtils";
import LoaderIcon from "../../../resources/icons/LoaderIcon";
import DocumentAddIcon from "../../../resources/icons/DocumentAddIcon";
import {LinkIcon} from "@heroicons/react/solid/esm";
import {Link} from "react-router-dom";
import {TrashIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import ReportViewer from "../report/report-viewer";
import Compressor from "compressorjs";


const UploadFile = ({ onUpload, reference = null, canViewFile = true, cleanData = false, extraClass = '', image = false, onException, isLoading, label = false, cancel, onCancel, file, accept = [], fileTypeLabel = 'DOC, XLS, PDF', size = '10mb', isDisabled = false, showPreview = false, picture = false, isActiveRemove = false }) => {

    const [fileLen, setFileLen] = useState(0);
    const [url, setUrl] = useState(reference);
    const { t } = useTranslation();
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [isRemovedPicture, setRemovedPicture] = useState(false);


    const handleSuccess = async (files)  => {
        setFileLen(files.length);
        setUrl(null);
        // eslint-disable-next-line
        files.map((file, index) => {
            getSignedRequests({params: {ctype: files[index].src.file.type, ext: files[index].src.file.name.split(".").pop()}})
                .then( signed => {

                    if(image){
                        new Compressor(file.src.file, {
                            quality: 0.5,
                            width: 1980,
                            success(result) {
                                sendMedia(signed.action, signed, file.src.file)
                                    .then( r => {
                                        let url = signed.action + '/' + signed.key;
                                        setUrl(url);
                                        onUpload({reference: url, media_url: url, detail: files[index]});
                                        setFileLen(state => state - 1);
                                    }).catch(e => {
                                    onException(e.message);
                                });
                            },
                            error(err) {
                                onException(err.message);
                            },
                        });

                    }else{
                        sendMedia(signed.action, signed, file.src.file)
                            .then( r => {
                                let url = signed.action + '/' + signed.key;
                                setUrl(url);
                                onUpload({reference: url, media_url: url, detail: files[index]});
                                setFileLen(state => state - 1);
                            }).catch(e => {
                            onException(e.message);
                        });
                    }



                }).catch(e => {
                    onException(e.message);
            });
        });
    };


    const handleErrors = async (errors) => {
        if(errors.length > 0 ){
             if((errors[0].file && errors[0].type === 'maxSizeExceeded') || (errors[0].type === 'multipleMaxSizeExceeded')){
                 onException(errors[0].type);
             }
        }
    };

    useEffect( () => {
        if(cancel || cleanData){
            setUrl(null);
            setFileLen(0);
        }

    }, [cancel, cleanData]);


    return (
        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={url ? {reference: url} : false}
                title={t("app.document.preview_file")}
                isActiveConfirm={false}
                zIndex={' z-50'}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />


            {
                url ? (
                    <div className={"pt-4 pb-6 " + extraClass}>
                        {
                            label && (
                                <label className="block text-sm font-medium text-gray-700 mb-4">{label}</label>
                            )
                        }
                          <div className="flex text-sm">

                              {
                                  canViewFile && (
                                      <div
                                          rel="noreferrer"
                                          onClick={() => {
                                              setOpenReportView(true);
                                          }}
                                          className="cursor-pointer group inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer mr-5 "
                                      >
                                          <LinkIcon
                                              className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900"
                                              aria-hidden="true"
                                          />
                                          <span className="ml-2">{t("app.document.view_file")}</span>
                                      </div>
                                  )
                              }


                                <Link
                                    to={'#'}
                                    onClick={() => {onCancel(true); setUrl(null); }}
                                    className="group  inline-flex items-center font-medium text-red-600 hover:text-red-900 cursor-pointer"
                                >
                                    <TrashIcon
                                        className="h-5 w-5 text-red-500 group-hover:text-red-900"
                                        aria-hidden="true"
                                    />
                                    <span className="ml-2">{t("app.document.replace_file")}</span>
                                </Link>
                          </div>
                    </div>
                ) : (

                    !isDisabled && (
                        <Files
                            multiple={false} maxSize={size} multipleMaxSize={size}
                            onSuccess={handleSuccess}
                            onError={handleErrors}
                            accept={accept}
                        >
                            {({ browseFiles, getDropZoneProps }) => {
                                return (

                                        

                                    <div>
                                        {
                                            label && (
                                                <div className="flex items-center">

                                                    {picture && isActiveRemove && !isRemovedPicture && (
                                                        <div
                                                    onClick={(e) => { e.preventDefault(); onUpload(null); setRemovedPicture(true); }}
                                                            className="cursor-pointer"
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4 text-red-500  mr-2 hover:text-red-900"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    )}
                                                    <label className="block text-sm font-medium text-gray-700">{label}</label>
                                                </div>
                                            )
                                        }
                                        <a 
                                            href={'#0'}  
                                            onClick={browseFiles}  
                                            className={"mt-1 flex  justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md "+extraClass} {...getDropZoneProps()}
                                        >
                                            <div className="space-y-1 text-center">
                                                
                                                {
                                                    fileLen > 0 || isLoading? (
                                                        <div>
                                                            <LoaderIcon className="animate-spin w-4 h-4 fill-current shrink-0"/>
                                                        </div>
                                                    ) : (
                                                        <>

                                                            {
                                                                    (showPreview && picture && !isRemovedPicture) ? (
                                                                        <div>
                                                                            <img className="mx-auto object-cover h-16 rounded-md" src={picture} alt="" />
                                                                        </div>
                                                                ) : (
                                                                    <DocumentAddIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                                )
                                                            }
                                                                
                                                            <div className="flex text-sm text-gray-600">
                                                                <label
                                                                    htmlFor="file-upload"
                                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                >
                                                                    <span>{t("app.document.upload_file")}</span>
                                                                </label>
                                                                <p className="pl-1">{t("app.document.drag_file")}</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">{t("app.document.file_type", {types: fileTypeLabel, size: size})}</p>
                                                               
                                                        </>
                                                    )
                                                }
                                            </div>

                                           
                                        </a>
                                    </div>
                                );
                            }}
                        </Files>
                    )


                )
            }
        </>
    );
};

export default UploadFile;
