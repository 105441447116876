import {
    COMPANY,
    DASHBOARD,
    WIDGET,
    WIDGET_POSITION,
    WIDGET_TYPES,
    WIDGETS
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// customer
export const getDashboard = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, DASHBOARD] ) + (params ? serialize(params)  : ''), {signal: signal});



export const getDashboardWidgets = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, WIDGETS] ) + (params ? serialize(params)  : ''), {signal: signal});


export const getWidgets = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, WIDGET_TYPES] ) + (params ? serialize(params)  : ''), {signal: signal});


export const getDashboardWidget = ({company_id, widget_id, params, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, WIDGET, widget_id] ) + (params ? serialize(params)  : ''), signal && {signal: signal});

export const postDashboardWidget = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, WIDGET]), data);

export const putDashboardWidgetPosition = ({company_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, WIDGET_POSITION]), data);



