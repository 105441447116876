import React, {useEffect, useState} from "react";
import BaseList from "../../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../../utils/converter";
import {
    PRODUCT, MEDIAS, MEDIA, COMPANY, LABEL_GENERATE, DOCUMENT_TEMPLATES,
    TRANSLATIONS
} from "../../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../../components/common/table/actions-menu";
import CardDetail from "../../../../../components/common/list/card-detail";
import TableDetail from "../../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../../components/common/form/input-text";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import {fetchCountries} from "../../../../../reducers/configReducer";
import {useParams} from "react-router-dom";
import {PaperClipIcon} from "@heroicons/react/outline";
import UploadFile from "../../../../../components/partials/upload/upload-file";
import FileCell from "../../../../../components/common/table/file-cell";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionSpecific, permissionType} from "../../../../../constants/permissions";
import {putElement} from "../../../../../api/config";
import ReportViewer from "../../../../../components/partials/report/report-viewer";
import SlidePanel from "../../../../../components/layout/slide-panel";
import DynamicSearch from "../../../../../components/partials/common/dynamic-search";

const ProductMedia = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();

    const { permissions } = useSelector((s) => s.userCompany);

    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.VIEW);
    const canGenerateFile = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_GENERATE_FILE);
    const [isOpenGenerate, setOpenGenerate] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_ATTACHMENT, permissionType.LIST)]} renderBaseOnFail={true}>
            {
                loggedCompany && (
                    <>


                        <GenerateDocument
                            productId={id}
                            isOpen={isOpenGenerate}
                            setOpen={(r) => {
                                setNeedRefresh(!needRefresh)
                                setOpenGenerate(r);
                            }}
                        />

                        <BaseList
                            columns={[
                                t("app.catalog.attachment"),
                                t("app.catalog.note"),
                                "",
                            ]}
                            endpoint={getPathParam([PRODUCT, id, MEDIAS])}
                            storeEndpoint={getPathParam([PRODUCT, id, MEDIA])}
                            updateEndpoint={getPathParam([PRODUCT, id, MEDIA, ':id'])}
                            baseResult={{total_rows: 0, medias: []}}
                            resultLabel={'medias'}
                            title={t("app.catalog.attachments")}
                            showHeader={true}
                            mobRow={MobRow}
                            deskRow={DeskRow}
                            addForm={AddForm}
                            icon={PaperClipIcon}
                            addFormLabel={t("app.catalog.add", {type: t("app.catalog.attachment")})}
                            btnAddLabel={canCreate ? t("app.catalog.add", {type: t("app.catalog.attachment")}) : false}
                            addPermission={[getPermission(permissionGroup.PRODUCT_ATTACHMENT, permissionType.CREATE)]}
                            editForm={EditForm}
                            editTitle={t("app.catalog.attachment")}
                            isActiveEdit={canView || canEdit}
                            isActiveFileViewer={canView}
                            needRefresh={needRefresh}
                            onTemplateGenerate={() => {
                                setOpenGenerate(true)
                            }}
                            btnTemplateGenerateLabel={canGenerateFile ? t("app.container.generate_file") : false}
                        />
                    </>

                )
            }
        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, MEDIA, element.id])
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.VIEW);


    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <FileCell file={element} canView={canView ? true : false} onOpenFile={() => {
                        extras.onOpenFile(element);
                    }} />

                    {
                        (canView || canEdit || canDelete) && (
                            <ActionsMenu>
                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onEdit(true, element);
                                        }}
                                        >
                                            {t("app.common.edit")}
                                        </button>
                                    )
                                }
                                {
                                    canDelete && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>
                                    )
                                }
                            </ActionsMenu>
                        )
                    }


                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.note")}>
                        {element.file_note}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, MEDIA, element.id])
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.VIEW);


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        <FileCell file={element} canView={canView ? true : false} onOpenFile={() => {
                            extras.onOpenFile(element);
                        }} />
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.file_note}
            </TableDetail>

            <TableDetail>
                {
                    (canView || canEdit || canDelete) && (
                        <ActionsMenu>
                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )
                            }
                            {
                                canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )
                            }
                        </ActionsMenu>
                    )
                }
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-1">
                    <UploadFile
                        onUpload={(data) => {
                            setValue('file', {media_url: data.media_url});
                            setValue('file_name', data.detail.name);
                        }}
                        onCancel={(r) => {
                            if(r){
                                setValue('file', undefined);
                                setValue('file_name', undefined);
                            }
                        }}
                        onException={(e) => {}}
                        isLoading={false}
                        label={t("app.catalog.upload_attachment")}
                    />

                    <InputText
                        id={'file_note'}
                        type={'text'}
                        errors={errors.file_note}
                        input={{...register("file_note", { required: false })}}
                        label={t("app.catalog.note")}
                    />
                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            {
                watch('file') && (
                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.catalog.add")}
                        />
                    </div>
                )
            }


        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.EDIT);



    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="space-y-4 pt-4 pb-5">

                        {
                            canEdit && (
                                <UploadFile
                                    onUpload={(data) => {
                                        setValue('file', {media_url: data.media_url});
                                        setValue('file_name', data.detail.name);
                                    }}
                                    onCancel={(r) => {
                                        if(r){
                                            setValue('file', undefined);
                                            setValue('file_name', undefined);
                                        }
                                    }}
                                    onException={(e) => {}}
                                    isLoading={false}
                                    label={t("app.catalog.upload_attachment")}
                                />
                            )
                        }

                        <InputText
                            id={'file_note'}
                            type={'text'}
                            errors={errors.file_note}
                            input={{...register("file_note", { required: false })}}
                            label={t("app.catalog.note")}
                            isDisabled={!canEdit}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                {
                    canEdit && (
                        <div className="mt-3">
                            <InputSubmit
                                isLoading={isEditLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        </div>
                    )
                }

            </form>

        </>
    );
}



const GenerateDocument = ({ productId, isOpen, setOpen }) =>  {

    const { loggedCompany } = useSelector((state) => state.user);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();


    //Templates
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);


    //File
    const [isOpenReportView, setOpenReportView] = useState(false);
    const [reportDetail, setReportDetail] = useState({});


    const { t } = useTranslation();

    const onSubmit = async (data) => {

        setLoading(true);
        try {
            const res = await putElement(getPathParam([COMPANY, loggedCompany.id, PRODUCT, productId, LABEL_GENERATE]), data);
            setReportDetail(res);
            setOpenReportView(true);
        } catch (e) {
            setReportDetail({});
            setException(e.message);
        } finally {
            setLoading(false);
        }

    };

    return (
        <>


            <ReportViewer
                isOpen={isOpenReportView}
                type={'document'}
                reportDetail={reportDetail?.file ?? false}
                title={reportDetail?.file_name ?? ''}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}

            />

            <SlidePanel
                title={t("app.container.generate_file")}
                isOpen={isOpen}
                setIsOpen={setOpen}
                bg={'bg-white'}
                size={'max-w-2xl'}
                hasDivider={false}
                setClean={() => {

                }}
            >


                <div className={""}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-4 pt-4 pb-5">



                            <Controller
                                name="document_template"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                             field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (
                                    <DynamicSearch
                                        selectLabel={t("app.company_preference.template")}
                                        endpoint={getPathParam([COMPANY, loggedCompany.id, DOCUMENT_TEMPLATES])}
                                        responseLabel={'templates'}
                                        label={'template_name'}
                                        errors={error}
                                        value={value}
                                        name={name}
                                        onChange={(r) => {
                                            setValue('file_name', r.label);
                                            onChange(r);
                                        } }
                                        isClearable={false}
                                        isRequired={true}
                                        isDisabled={false}

                                    />
                                )}
                            />



                            <InputText
                                id={'file_name'}
                                type={'text'}
                                errors={errors.file_name}
                                input={{ ...register("file_name", { required: false }) }}
                                label={t("app.document.file_name")}
                                isDisabled={false}
                            />

                            <InputText
                                id={'note'}
                                type={'text'}
                                errors={errors.note}
                                input={{ ...register("note", { required: false }) }}
                                label={t("app.catalog.note")}
                                isDisabled={false}
                            />


                            <Controller
                                name="product_translation"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <DynamicSearch
                                        selectLabel={t("app.products.translation")}
                                        endpoint={getPathParam([PRODUCT, productId, TRANSLATIONS])}
                                        responseLabel={'translations'}
                                        label={'locale'}
                                        subParams={'name'}
                                        errors={error}
                                        value={value}
                                        name={name}
                                        onChange={(r) => {
                                            onChange(r);
                                        }}
                                        isClearable={false}
                                        isRequired={true}
                                        isDisabled={false}

                                    />
                                )}
                            />

                        </div>

                        {/* Warning */}
                        {exception && (
                            <Warning message={exception} />
                        )}

                        <div className="mt-3">
                            <InputSubmit
                                isLoading={isLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        </div>
                    </form>
                </div>


            </SlidePanel>






        </>
    );
}

export default ProductMedia;
