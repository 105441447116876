import React  from "react";
import { useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import { useEffect } from "react";
import CatalogIcon from "../../../resources/icons/CatalogIcon";
import InputRadioSquare from "../../../components/common/form/input-radio-square";
import {useTranslation} from "react-i18next";
import {
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../../constants/permissions";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import {CloudUploadIcon, PlusIcon} from "@heroicons/react/outline";
import {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {useSelector} from "react-redux";

export default function AddProduct(
    {
        isOpen,
        setIsOpen,
        onOpenCatalog,
        onOpenUpload,
        onOpenAddProduct
    }) {
    const {
        register,
        setValue,
        formState: { errors },
    } = useForm();

    const { t } = useTranslation();

    const {permissions } = useSelector((s) => s.userCompany);

    const isActiveImportProduct = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_IMPORT_PRODUCT);
    const isActiveCreateProduct = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_CREATE_PRODUCT);
    const isProductList = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.LIST);

    useEffect(() => {
        setValue("insert_type", '')
    }, [setValue]);

    return (
        <FormPopup
            title={t("app.quotations.add_inside")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >

                <div className="grid grid-cols-3 gap-6 mb-6">

                    {
                        isActiveCreateProduct && (
                            <InputRadioSquare
                                name={"insert_type"}
                                errors={errors.insert_type}
                                input={{ ...register("insert_type", { required: false }) }}
                                label={t("app.quotations.generic_title")}
                                value={"product"}
                                onCheck={() => {
                                    onOpenAddProduct(true);
                                    setIsOpen(false);
                                }}
                                description={t("app.quotations.generic_title_description")}
                                icon={<PlusIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                            />
                        )
                    }


                    {
                        isProductList && (
                            <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={false}>
                                <InputRadioSquare
                                    name={"insert_type"}
                                    errors={errors.insert_type}
                                    input={{ ...register("insert_type", { required: false }) }}
                                    value={"catalog"}
                                    onCheck={() => {
                                        onOpenCatalog(true);
                                        setIsOpen(false);
                                    }}
                                    label={t("app.quotations.from_catalog")}
                                    description={t("app.quotations.from_catalog_description")}
                                    icon={<CatalogIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                />
                            </SubscriptionWrapper>
                        )
                    }


                    {
                        isActiveImportProduct && (
                            <InputRadioSquare
                                name={"insert_type"}
                                errors={errors.insert_type}
                                input={{ ...register("insert_type", { required: false }) }}
                                value={"import"}
                                onCheck={() => {
                                    setIsOpen(false);
                                    onOpenUpload(true);
                                }}
                                label={t("app.container.from_import")}
                                description={t("app.container.from_import_description")}
                                icon={<CloudUploadIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                            />
                        )
                    }

                </div>

        </FormPopup>
    );
}
