import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormPopup from "../../../../../../../../components/common/popup/form-popup";
import InputText from "../../../../../../../../components/common/form/input-text";
import {Warning} from "../../../../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../../../../components/common/form/input-submit";
import {postContainerSubGroup} from "../../../../../../../../api/container";
import {Link, useParams} from "react-router-dom";
import InputCheckbox from "../../../../../../../../components/common/form/input-checkbox";
import InputSelect from "../../../../../../../../components/common/form/input-select";
import InputFormattedNumber from "../../../../../../../../components/common/form/input-formatted-number";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {useSelector} from "react-redux";
import {getPallets} from "../../../../../../../../api/pallet";
import {cleanData, getSelectParams} from "../../../../../../../../utils/converter";
import {useTranslation} from "react-i18next";

export default function AddSubGroup(
    {
        isOpen,
        setIsOpen,
        setDetail,
        groupDetail
    }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        control,
        setValue
    } = useForm();

    let {id, container_id}  = useParams();
    const { t } = useTranslation();

    const { loggedCompany } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [isAddPallet, setAddPallet] = useState(false);

    const [pallets, setPallets] = useState(false);
    const [isLoadingPallet, setLoadingPallet] = useState(false);


    const storeContainerGroup = async (data) => {
        if(!watch("pallet")){
            delete data['pallet_type']
        }
        setLoading(true);
        postContainerSubGroup({ shipping_id: id, container_id: container_id, group_id: groupDetail.id, data })
            .then((response) => {
                setLoading(false);
                setDetail(response);
                setIsOpen(false)
            })
            .catch((e) => {
                setException(e.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchPallets() {
            if(loggedCompany){
                setLoadingPallet(true);
                getPallets({ company_id: loggedCompany.id, params: {}, signal })
                    .then((response) => {
                        setPallets(getSelectParams(response.pallets, "name"));
                        setLoadingPallet(false);
                    })
                    .catch((e) => {
                        setPallets({ pallets: [], total_rows: 0 });
                        setLoadingPallet(false);
                    });
            }
        }

        fetchPallets();
        return () => {
            controller.abort();
        };
    }, [loggedCompany]);



    const onSubmit = async (data) => {
        cleanData(data)
        await storeContainerGroup(data);
    };

    return (
        <FormPopup
            title={t("app.container.add_sub_group")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-5 gap-x-5 sm:grid-cols-1">
                        <InputText
                            id={"group_name"}
                            type={"text"}
                            errors={errors.group_name}
                            input={{ ...register("group_name", { required: true }) }}
                            label={t("app.container.group_name")}
                        />


                        {
                            (!(groupDetail?.is_specific ?? false) && !(groupDetail?.pallet ?? false)) && (
                                <>
                                    <div className={"col-span-2 mt-2"}>
                                        <InputCheckbox
                                            label={t("app.container.use_pallet")}
                                            description={t("app.container.use_pallet_description")}
                                            id={"is_pallet"}
                                            input={{ ...register("pallet", { required: false }) }}
                                        />
                                    </div>

                                    { (!isAddPallet && watch("pallet")) && (
                                        <div className={"col-span-2 mt-2"}>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className={"col-span-2"}>
                                                    <Controller
                                                        name="pallet_type"
                                                        rules={{required: false}}
                                                        control={control}
                                                        render={({field: { onChange, value, name },
                                                                     fieldState: { error }}) => (
                                                            <InputSelect
                                                                label={t("app.container.pallets")}
                                                                name={name}
                                                                options={pallets}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={isLoadingPallet}
                                                                isDisabled={isLoadingPallet}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex justify-between items-end">
                                                    <Link
                                                        to={"#"}
                                                        onClick={() => {
                                                            setAddPallet(true);
                                                            setValue('pallet_type', undefined);
                                                        }}
                                                        className={
                                                            "w-full btn bg-green-500 hover:bg-green-700 text-white"
                                                        }
                                                    >

                                                        {t("app.container.add_pallet")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {
                                        isAddPallet && watch("pallet") && (
                                            <div className="border p-2 grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">
                                                <InputText
                                                    id={"name"}
                                                    type={"text"}
                                                    errors={errors.name}
                                                    input={{ ...register("name", { required: watch("pallet") }) }}
                                                    label={t("app.container.pallet_name")}
                                                />

                                                <Controller
                                                    name="max_weight"
                                                    rules={{ required: watch("pallet") }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.container.max_weight")}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' Kg'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                                <div className={"col-span-2 mt-2"}>
                                                    <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-y-5 gap-x-5 sm:grid-cols-1">
                                                        <Controller
                                                            name="length"
                                                            rules={{ required: watch("pallet") }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.container.length")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="width"
                                                            rules={{ required: watch("pallet") }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.container.width")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="height"
                                                            rules={{ required: watch("pallet") }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.container.height")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' cm'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />


                                                    </div>
                                                </div>

                                                <a href={'#0'}
                                                   onClick={() => {
                                                       setValue('name', undefined);
                                                       setValue('max_weight', undefined);
                                                       setValue('length', undefined);
                                                       setValue('width', undefined);
                                                       setValue('height', undefined);
                                                       setAddPallet(false)
                                                   }}
                                                   className="text-sm underline inline-flex items-center"
                                                >
                                                    <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                                                    {t("app.common.back")}
                                                </a>
                                            </div>

                                        )
                                    }
                                </>
                            )
                        }





                    </div>







                </div>

                {/* Warning*/}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.container.add_group")}
                    />
                </div>
            </form>



        </FormPopup>
    );
}
