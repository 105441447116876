import React, {useState} from 'react'
import SlidePanel from "../../../components/layout/slide-panel";
import {Warning} from "../../../components/common/alert/banner";
import {useTranslation} from "react-i18next";
import SearchProduct from "./search-product";
import ProductConfiguration from "./product-configuration";
import {useParams} from "react-router-dom";
import {postOrderProducts} from "../../../api/order";
import {makeid} from "../../../utils/converter";

const searchProduct = 'search-product';
const productConfiguration  = 'product-configuration';


const AddProduct = ({isOpen, detail, setIsOpen, setDetail}) => {

    const { t } = useTranslation();
    let { id } = useParams();

    const [step, setStep] = useState(searchProduct);
    const [isBack, setBack] = useState(false);
    const [cachedDetail, setCachedDetail] = useState([]);
    const [exception, setException] = useState(false);
    const [isLoading, setLoading] = useState(false);


    const [selectedProductList, setSelectedProductList] = useState([]);



    const clean = () => {
        setStep(searchProduct);
        setSelectedProductList([]);
        setException(false)
    };


    const storeProduct = () => {

        let products = [];
        // eslint-disable-next-line
        selectedProductList.map((item) => {
            let details = {};
            let discounts = (item?.discounts ?? []);
            let final_price = item?.base_price ?? 0;
            let discount_string = '';

            details['quantity'] = item.quantity;
            details['base_price'] = item.base_price;



            // eslint-disable-next-line
            discounts.map((discount, index) => {
                final_price = final_price - (final_price * (discount.price / 100));
                discount_string = discount_string + discount.price + '|';
            })


            details['discount_price'] = final_price;
            details['discount'] = discount_string.slice(0, -1);
            details['product'] = {id: item.id} ;
            products.push(details);
        });



      if (products.length > 0) {
            setLoading(true);
            setException(false);
            postOrderProducts({ order_id: id,  data: products })
                .then((response) => {
                    setDetail(response);
                    setLoading(false);
                    clean();
                    setIsOpen(false);
                })
                .catch((e) => {
                    setException(e.message);
                    setLoading(false);
                });

        }
    };




    return (
        <SlidePanel title={t("app.quotations.catalog")}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    size='max-w-7xl'
                    setClean={() => {
                        clean();
                    }}
        >
            <div className="space-y-6 pt-6 pb-5">

                {
                    step === searchProduct && (
                        <SearchProduct
                            selectedProductList={selectedProductList}
                            isBack={isBack}
                            onSelectedProductList={(products) =>{
                                setSelectedProductList([...selectedProductList, ...products]);
                                setBack(false);
                                setStep(productConfiguration);
                            }}
                            cachedDetail={cachedDetail}
                            setCachedDetail={(r) =>{
                                setCachedDetail(r);
                            }}
                            onChangeStep={() =>{
                                setStep(productConfiguration);
                            }}

                        />
                    )
                }


                {
                    step === productConfiguration && (
                        <ProductConfiguration
                            onChangeStep={() => {
                                setBack(true);
                                setStep(searchProduct);
                            }}
                            onSave={() => {
                                storeProduct();
                            }}
                            isLoading={isLoading}
                            selectedProductList={selectedProductList}
                            onChangeValue={(id, value, type) => {
                                let filtered = [...selectedProductList];

                                let foundIndex = filtered.findIndex((x) => x.id === id);
                                filtered[foundIndex][type] = value

                                setSelectedProductList(filtered)

                            }}
                            onAddDiscount={(id) => {
                                let filtered = [...selectedProductList];
                                let foundIndex = filtered.findIndex((x) => x.id === id);
                                filtered[foundIndex]['discounts'] = [...filtered[foundIndex]['discounts'], {id: makeid(20), price: null}];
                                setSelectedProductList(filtered)
                            }}
                            onUpdateDiscount={(id, value, index) => {
                                let filtered = [...selectedProductList];
                                let foundIndex = filtered.findIndex((x) => x.id === id);
                                filtered[foundIndex]['discounts'][index]['price'] = value !== false ? value : null;
                                filtered[foundIndex]['discounts'] = filtered[foundIndex]['discounts'].filter(r => r.price !== null);
                                setSelectedProductList(filtered);
                            }}
                            onDelete={(id) => {
                                let filtered = [...selectedProductList];

                                // remove internal product
                                filtered = filtered.filter(function( obj ) {
                                    return obj.id !== id;
                                });

                                setSelectedProductList(filtered);
                            }}
                            onSetQuantity={(id, item, value) => {
                                let filtered = [...selectedProductList];

                                let foundIndex = filtered.findIndex((x) => x.id === id);

                                let prodIndex = filtered[foundIndex]['products'].findIndex((x) => x.id === item.id);
                                filtered[foundIndex]['products'][prodIndex]['quantity'] = value

                                setSelectedProductList(filtered)

                            }}
                        />
                    )
                }



                {/* Warning */}
                {exception && <Warning message={exception} />}


            </div>
        </SlidePanel>

    );
};


export default AddProduct;
