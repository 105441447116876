import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyUserConnections } from "../../api/user-connection";
import PageHeader, { ActionButton } from "../../components/layout/page-header";
import { StatusBar } from "../../components/common/alert/status-bar";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import AddUser from "./add-user";
import {COMPANY, REGISTRY, USER} from "../endpoints";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ActionsMenu from "../../components/common/table/actions-menu";
import ListSection from "../../components/layout/list-section";
import OptionalLink from "../../components/common/table/optional-link";
import UserCell from "../../components/common/table/user-cell";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import {USER_CONNECTION} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import { PlusIcon } from "@heroicons/react/outline";

const Users = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const columns = [
    t("app.users.user"),
    t("app.users.user_type"),
    t("app.users.connection_status"),
    "",
  ];

  const tabs = [
    { name: t("app.users.connection_all"), group: null },
    { name: t("app.users.connection_employees"), group: "employee" },
    { name: t("app.users.connection_agents"), group: "agent" },
  ];

  const [isFetchLoading, setFetchLoading] = useState(true);
  const [list, setList] = useState({ connections: [], total_rows: 0 });

  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [activeGroup, setActiveGroup] = useState(null);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchList() {
      if (loggedCompany) {
        setFetchLoading(true);
        getCompanyUserConnections({
          company_id: loggedCompany.id,
          params: { limit: 10, offset: offset, filter: activeGroup, query: query },
          signal,
        })
          .then((response) => {
            if (response) {
              setList(response);
              setFetchLoading(false);
            } else {
              setList({ connections: [], total_rows: 0 });
            }
          })
          .catch((e) => {
            if(signal.aborted){
              setFetchLoading(true);
            }else{
              setList({ connections: [], total_rows: 0 });
              setFetchLoading(false);
            }
          });
      }
    }
    fetchList();
    return () => {
      controller.abort();
    };
  }, [dispatch, offset, query, loggedCompany, activeGroup]);


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.USER, permissionType.LIST)]}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.users.users")}>
            <ActionButton
              icon={
                <PlusIcon
                  className="w-4 h-4"
                />
              }
              onClick={() => {
                setIsOpen(true);
              }}
              text={t("app.users.add")}
            />
          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <UnderlineTab
            tabs={tabs}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />

          <ListSection
            title={t("app.users.users")}
            columns={columns}
            totalRows={list.total_rows}
            list={list.connections}
            onSearch={setQuery}
            isLoading={isFetchLoading}
            paginationOnChange={setOffset}
            mobileRow={UserMobRow}
            desktopRow={UserDeskRow}
            extras={{
              onRemove: (r, detail) => {
                setOpenRemove(r);
                setRemoveDetail(detail);
              },
            }}
          />
        </div>

        {isOpenRemove && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                setDetail={(r) => {
                  dispatch(subscriptionUsageLimits(loggedCompany.id));
                  setList(r);
                }}
            />
        )}

        {isOpen && <AddUser isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
    </BasePermissionWrapper>
  );
};

const UserMobRow = ({ element,extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);

  const removeDetail = {
    title: t("app.users.remove_title"),
    message: t("app.users.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, USER_CONNECTION, element.id])
  };


  let user_status = {
    status: element?.status === "confirmed" ? "success" : "processing",
    title: element?.status === "confirmed" ? t("app.users.confirmed_connection") : t("app.users.waiting_connection"),
  };

  if(!element.is_active){
    user_status = {
      status: "blocked",
      title: t("app.users.blocked_connection"),
    };
  }

  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={true}
        to={getWebPathParam([REGISTRY, USER, element.id])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">
          <UserCell user={element} />
          <ActionsMenu>
            <Link to={getWebPathParam([REGISTRY, USER, element.id])}>
              {t("app.common.edit")}
            </Link>
            <button
                className={"text-left"}
                onClick={() => {
                  extras.onRemove(true, removeDetail);
                }}
            >
              {t("app.common.remove")}
            </button>
          </ActionsMenu>
        </div>

        <div className="flex flex-col">
          <CardDetail title={t("app.users.user_type")}>
            {element.connection_type === 'agent' ? t("app.users.connection_agent") : t("app.users.connection_employee")}
          </CardDetail>

          <CardDetail title={t("app.users.connection_status")}>
            <StatusBar
                status={user_status.status}
                title={user_status.title}
            />
          </CardDetail>
        </div>



      </OptionalLink>
    </li>
  );
};

const UserDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);

  const removeDetail = {
    title: t("app.users.remove_title"),
    message: t("app.users.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, USER_CONNECTION, element.id])
  };

  let user_status = {
    status: element?.status === "confirmed" ? "success" : "processing",
    title: element?.status === "confirmed" ? t("app.users.confirmed_connection") : t("app.users.waiting_connection"),
  };

  if(!element.is_active){
    user_status = {
      status: "blocked",
      title: t("app.users.blocked_connection"),
    };
  }


  return (
    <tr key={element.id}>

      <TableDetail extraClass="max-w-sm">
        <OptionalLink
          isLinked={true}
          to={getWebPathParam([REGISTRY, USER, element.id])}
          className="flex items-center space-x-3 lg:pl-2 hover:text-gray-600"
        >
          <UserCell user={element} />
        </OptionalLink>
      </TableDetail>


      <TableDetail>
        {element.connection_type === 'agent' ? t("app.users.connection_agent") : t("app.users.connection_employee")}
      </TableDetail>

      <TableDetail>
        <StatusBar
            status={user_status.status}
            title={user_status.title}
        />
      </TableDetail>

      <TableDetail>
        <ActionsMenu>
          <Link to={getWebPathParam([REGISTRY, USER, element.id])}>
            {t("app.common.edit")}
          </Link>
          <button
              className={"text-left"}
              onClick={() => {
                extras.onRemove(true, removeDetail);
              }}
          >
            {t("app.common.remove")}
          </button>
        </ActionsMenu>
      </TableDetail>
    </tr>
  );
};

export default Users;
