import React, { useEffect, useState } from "react";
import {useParams, useNavigate} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {  Warning } from "../../../../../components/common/alert/banner";
import {P404, TRANSLATION} from "../../../../endpoints";
import {getPathParam, getSelectParam} from "../../../../../utils/converter";
import {
  getProductTranslations,
  putProductTranslation
} from "../../../../../api/catalog";
import { useTranslation } from "react-i18next";
import {getPermission, permissionGroup, permissionType} from "../../../../../constants/permissions";
import toast from "react-hot-toast";
import { Disclosure } from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/solid'
import InputSubmit from "../../../../../components/common/form/input-submit";
import {PlusCircleIcon} from "@heroicons/react/outline";
import CircleBtn from "../../../../../components/common/button/circle-btn";
import {TrashIcon} from "@heroicons/react/outline/esm";
import InputText from "../../../../../components/common/form/input-text";
import {PRODUCT} from "../../../../../api/endpoints";
import RemovePopup from "../../../../../components/common/popup/remove-popup";
import LoaderWrapper from "../../../../../components/common/loader/loader-wrapper";
import {useDispatch, useSelector} from "react-redux";
import {fetchLanguages} from "../../../../../reducers/configReducer";
import InputSelect from "../../../../../components/common/form/input-select";
import AddTranslation from "./add-translation";
import InputEditor from "../../../../../components/common/form/input-editor";
import BasePermissionWrapper, {
  hasPermissionFor
} from "../../../../../components/partials/restricted/base-permission-wrapper";
import Nodata from "../../../../../resources/images/no-data.gif";
import BaseRestricted from "../../../../../components/partials/restricted/base-restricted";

const ProductTranslation = () => {

  let { id } = useParams();
  let navigate = useNavigate();
  const { permissions } = useSelector((s) => s.userCompany);

  // get product translation
  const [isLoading, setLoading] = useState(true);
  const [translations, setTranslations] = useState([]);

  // put product translation
  const [isAddTranslation, setAddTranslation] = useState(false);


  // remove product translation
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});


  const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_TRANSLATION, permissionType.CREATE);
  const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_TRANSLATION, permissionType.EDIT);
  const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_TRANSLATION, permissionType.VIEW);
  const canRemove = hasPermissionFor(permissions, permissionGroup.PRODUCT_TRANSLATION, permissionType.DELETE);



  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchProductTranslations() {
      setLoading(true);
      getProductTranslations({ product_id: id, signal })
        .then((response) => {
          setTranslations(response.translations);
          setLoading(false);
        })
        .catch((e) => {
          e.code === 404 && navigate(P404);
          setLoading(false);
        });
    }



    fetchProductTranslations();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [id]);

  const { t } = useTranslation();


  return (
    <BasePermissionWrapper requiredPermission={getPermission(permissionGroup.PRODUCT_TRANSLATION, permissionType.LIST)} requireExporter={true} redirectWrongCompanyType={true}>

      <section>
        <LoaderWrapper isLoading={isLoading}>

          {
            canView ? (
              translations.map( (translation, index) => (
                  <TranslationDetail
                      key={translation.id}
                      defaultOpen={index === 0}
                      translationDetail={translation}
                      canEdit={canEdit}
                      canRemove={canRemove}
                      onSetTranslation={(r) => {
                        setTranslations(r);
                      }}
                      onRemove={(detail) => {
                        setRemoveDetail(detail);
                        setOpenRemove(true);
                      }}
                  />
              ))
            ) : (
              <BaseRestricted />
            )
          }

          {
              (canView && translations.length === 0) && (
                  <div className="mt-3 mx-auto w-full  bg-white">
                    <div className="text-center p-10">
                      <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                      <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                        {t("app.common.not_found")}
                      </h1>
                    </div>
                  </div>
            )
          }




          {
             canCreate && (
                  <div className={'mt-10 mx-auto w-1/2 '}>
                    <div
                        onClick={() => {
                          setAddTranslation(true);
                        }}
                        className="flex p-2 justify-center items-center   border border-blue-1000 px-4 text-center text-sm font-medium   text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer">
                      <div className="flex items-center ">
                        <div className={' mr-2  text-blue-1000'}>
                          <PlusCircleIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                          />
                        </div>
                        <span>{t("app.products.add_translation")}</span>
                      </div>
                    </div>
                  </div>
              )
          }



        </LoaderWrapper>
      </section>

      {(isAddTranslation && canCreate) &&
          <AddTranslation
              isOpen={isAddTranslation}
              setIsOpen={setAddTranslation}
              onAdded={(response) => {
                setTranslations(response.translations);
              }}
          />
      }


      {(isOpenRemove && canRemove) && (
          <RemovePopup
              isOpen={isOpenRemove}
              setIsOpen={setOpenRemove}
              detail={removeDetail}
              setDetail={(response) => {
                setTranslations(response.translations);
              }}
          />
      )}

    </BasePermissionWrapper>
  );
};



const TranslationDetail = ({translationDetail, defaultOpen, onSetTranslation, onRemove, canEdit, canRemove}) => {

  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // put product
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [updateException, setUpdateException] = useState(false);
  const { isLanguagesLoading, languages } = useSelector((state) => state.config);


  const updateTranslation = async (data) => {
    setUpdateLoading(true);
    putProductTranslation({ product_id: id, translation_id: data.id, data })
        .then((response) => {
          onSetTranslation(response.translations);
          setUpdateLoading(false);
          toast.success(t("app.products.update_success"))
          setUpdateException(false);
        })
        .catch((e) => {
          setUpdateLoading(false);
          setUpdateException(e.message);
        });
  };

  const removeDetail = {
    title: t("app.products.translation_remove_title"),
    message: t("app.products.translation_remove_description"),
    endpoint: getPathParam([PRODUCT, id, TRANSLATION, translationDetail.id])
  };


  useEffect(() => {
    if(translationDetail !== null){
      for (const k in translationDetail) {
        if (typeof translationDetail[k] !== "object") {
          setValue(k, translationDetail[k]);
        }else{
          setValue(k, getSelectParam(translationDetail[k], "name"));
        }
      }
    }

    dispatch(fetchLanguages());
  }, [translationDetail, setValue, dispatch]);

  const onSubmit = async (data) => {
    updateTranslation(data)
  };

  return (
      <Disclosure defaultOpen={defaultOpen} as={'div'} className={'mt-3 mx-auto w-full shadow bg-white'}>
          {({ open }) => (
              <>
                  <div className="flex w-full items-center justify-between bg-solitude-100 px-4 py-4 text-left text-sm font-medium text-blue-1000 hover:bg-solitude-400/[0.8]">
                      <div className="flex items-center justify-between">
                        {
                          canRemove && (
                                <CircleBtn
                                    icon={TrashIcon}
                                    onClick={() => {
                                      onRemove(removeDetail);
                                    }}
                                    extraClass={"text-red-800 bg-red-100  hover:bg-red-800 hover:text-white"}
                                />
                            )
                        }

                        <span>{translationDetail?.locale?.name}</span>
                      </div>

                    <Disclosure.Button>
                      <ChevronUpIcon
                          className={`${
                              open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-blue-1000`}
                      />
                    </Disclosure.Button>
                  </div>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="grid grid-cols-2 gap-6">


                            <Controller
                                name="locale"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputSelect
                                        label={t("app.common.language")}
                                        name={name}
                                        options={languages}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        isLoading={isLanguagesLoading}
                                        isDisabled={isLanguagesLoading || !canEdit}
                                        errors={error}
                                    />
                                )}
                            />

                            <InputText
                                id={"name"}
                                type={"text"}
                                errors={errors.name}
                                input={{ ...register("name", { required: true }) }}
                                label={t("app.form.name")}
                                isDisabled={!canEdit}
                            />



                            <div className="col-span-2">
                              <Controller
                                  name="description"
                                  rules={{ required: false }}
                                  control={control}
                                  render={({field: { onChange, value, name },
                                             fieldState: { error },
                                           }) => (
                                      <InputEditor
                                          label={t("app.products.description")}
                                          errors={error}
                                          defaultValue={value}
                                          name={name}
                                          isDisabled={!canEdit}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                      />
                                  )}
                              />
                            </div>

                          </div>

                        {/* Warning */}
                        {updateException && <Warning message={updateException} />}


                                <div className={"mt-5  flex items-center justify-between "}>
                                  {
                                      canEdit && (
                                          <>
                                            <div className="mr-3" />
                                            <InputSubmit isLoading={isUpdateLoading} label={t("app.form.save")} />

                                          </>
                                      )
                                  }
                                </div>




                      </form>
                  </Disclosure.Panel>
              </>
          )}
      </Disclosure>

  )
}


export default ProductTranslation;
