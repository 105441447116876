import React, {useEffect, useState, useRef} from "react";
import TurnoverChart from "../../components/dashboard/turnover-chart";
import WelcomeBanner from "../../components/dashboard/welcome-banner";
import {getDashboardWidget, getDashboardWidgets, putDashboardWidgetPosition} from "../../api/dashboard";
import {useSelector} from "react-redux";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import CalendarLoad from "../../components/dashboard/calendar-load";
import OpenedShipping from "../../components/dashboard/opened-shipping";
import {useTranslation} from "react-i18next";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {getPathParam} from "../../utils/converter";
import AddWidget from "./add-widget";
import ActionsMenu from "../../components/common/table/actions-menu";
import Loader from "../../components/common/loader/loader";
import {COMPANY, WIDGET} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import toast from "react-hot-toast";
import ConfirmPopup from "../../components/common/popup/confirm-popup";
import * as Widgets from "../../constants/dashboard-widget";
import TaskList from "../../components/dashboard/task-list";
import UnconfirmedShipping from "../../components/dashboard/unconfirmed-shipping";
import ShippingStatistics from "../../components/dashboard/shipping-statistics";
import {PlusCircleIcon, RefreshIcon} from "@heroicons/react/outline";
import dashBg from "../../resources/illustration/dashboard.svg";
import ShippingTrackingLog from "../../components/dashboard/shipping-tracking-log";
import ShippingTrackingVessel from "../../components/dashboard/shipping-tracking-vessel";
import ContainerSearchTracking from "../../components/dashboard/container-search-tracking";
import ActiveDocument from "../../components/dashboard/active-document";
import CompanyBoard from "../../components/dashboard/company-board";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {

    const { loggedCompany, companyType } = useSelector(
        (state) => state.user
    );
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);


    // Remove details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});


    //Add new Widget
    const [isOpenAdd, setIsOpenAdd] = useState(false);

    //Edit Widget
    const [isEditing, setEditing] = useState(false);


    const [widgets, setWidgets] = useState([]);
    const [lastWidgets, setLastWidgets] = useState([]);

    const [widgetDetails, setWidgetDetails] = useState([]);



    const getNewPosition = () => {
        const newWidgetWidth = 6;
        const newWidgetHeight = 3;

        // Trova l'ultima riga (posizione y massima) tra i widget esistenti
        let lastRow = 0;
        widgets.forEach((widget) => {
            const bottom = widget.y + widget.h;
            if (bottom > lastRow) {
                lastRow = bottom;
            }
        });

        // Imposta la posizione del nuovo widget all'inizio dell'ultima riga
        const newX = 0;
        const newY = lastRow;

        return { x: newX, y: newY, w: newWidgetWidth, h: newWidgetHeight };
    };


    let deviceIsMobile = false; //At the beginning we set this flag as false. If we can detect the device is a mobile device in the next line, then we set it as true.
// eslint-disable-next-line
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        // eslint-disable-next-line
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        deviceIsMobile = true;
    }



    function convertPositionsToWidgets(data) {
        return data.map((list) => {
            return {
                i: list.position.id,
                x: parseInt(list.position.x),
                y: parseInt(list.position.y),
                w: parseInt(list.position.w),
                h: parseInt(list.position.h),
            };
        });
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchDashboard() {
            if (loggedCompany) {
                setLoading(true);
                try {
                    const res = await getDashboardWidgets({
                        company_id: loggedCompany.id,
                        params: {company_type: companyType},
                        signal,
                    });
                    setWidgets(convertPositionsToWidgets(res));
                    setWidgetDetails(res);
                } catch (e) {
                    setWidgets([]);
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }else{
                setWidgets([]);
                setLoading(false);
            }
        }

        fetchDashboard();
        return () => {
            controller.abort();
        };
    }, [loggedCompany, companyType]);



    const updatePosition = async (data) => {
        putDashboardWidgetPosition({company_id: loggedCompany.id, data}).then(response => {
            toast.success(t("dashboard.update_success"))
        }).catch(e => {
            toast.error(t("dashboard.update_error"))
        });
    };



    return (
        <>
        <div className="py-6 mb-3">

            {
                widgets.length > 0 && (
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                        <WelcomeBanner
                            onAddWidget={() => {
                                setIsOpenAdd(true);
                            }}
                            onCancel={() => {
                                setEditing(false);
                                setWidgets(lastWidgets);
                            }}
                            showEditing={widgets.length > 0}
                            isEditing={isEditing}
                            setEditing={() => {
                                setLastWidgets(widgets);
                                if(isEditing){
                                    const formattedWidgets = widgets.map(list => ({
                                        id: list.i,
                                        position: `{x: ${list.x}, y: ${list.y}, w: ${list.w}, h: ${list.h}}`
                                    }));
                                    updatePosition(formattedWidgets)
                                }

                                setEditing(!isEditing);
                            }}
                        />
                    </div>
                )
            }


            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                <LoaderWrapper isLoading={isLoading}>

                    {
                        widgets.length === 0 ? (
                            <div className="px-6 py-14 text-center text-sm ">
                                <img src={dashBg} alt={''} className="w-64 sm:w-1/3 mx-auto" />
                                <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                                    {t("dashboard.no_widget_title")}
                                </h2>
                                <p className="mt-2 mb-6 max-w-2xl mx-auto text-center text-md text-gray-500">
                                    {t("dashboard.no_widget_description")}
                                </p>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsOpenAdd(true);
                                    }}
                                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <PlusCircleIcon className="mr-3 h-4 w-4" />
                                    {t("dashboard.add_widget")}
                                </button>
                            </div>
                        ) : (
                            <ResponsiveGridLayout
                                className="layout"
                                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                layouts={{ lg: widgets }}
                                isResizable={isEditing}
                                isDraggable={isEditing}
                                onLayoutChange={(newLayout) => {
                                    if(isEditing){
                                        setWidgets(newLayout);
                                    }
                                }}
                                draggableHandle=".grid-item__title"
                            >
                                {widgets.map((widget) => (
                                    <div  key={widget.i} data-grid={{ x: widget.x, y: widget.y, w: widget.w, h: widget.h }}>
                                        <Widget
                                            id={widget?.i ?? false}
                                            x={widget.w}
                                            y={widget.h}
                                            h={widget.h}
                                            w={widget.w}
                                            deviceIsMobile={deviceIsMobile}
                                            isEditing={isEditing}
                                            onRemove={(detail) => {
                                                setRemoveDetail(detail);
                                                setOpenRemove(true);
                                            }}
                                        />
                                    </div>
                                ))}
                            </ResponsiveGridLayout>
                        )
                    }




                </LoaderWrapper>

            </div>
        </div>

        {isOpenAdd &&
            <AddWidget
                widgetDetails={widgetDetails}
                position={getNewPosition()}
                isOpen={isOpenAdd}
                setIsOpen={setIsOpenAdd}
                onUpdateData={(r) => {
                    setWidgets(convertPositionsToWidgets(r));
                    setWidgetDetails(r);

                }}
            />
        }

        {isOpenRemove && (
            <RemovePopup
                isOpen={isOpenRemove}
                setIsOpen={setOpenRemove}
                detail={removeDetail}
                setDetail={(r) => {
                    setWidgets(convertPositionsToWidgets(r));
                    setWidgetDetails(r);
                }}
            />
        )}

        </>


    );
};


const Widget = ({ id, x, y, h, w, onRemove, isEditing, deviceIsMobile }) => {

    // Aggiungi qui eventuali funzioni e stati specifici del widget
    const [isLoading, setLoading] = useState(true);
    const [isRefreshing, setRefreshing] = useState(null);
    const [detail, setDetail] = useState({});
    const { loggedCompany, companyType } = useSelector((state) => state.user);

    const { t } = useTranslation();
    const ref = useRef(null)

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const [isEditingWidget, setEditingWidget] = useState(false);

    const containerWidth = 1200; // Larghezza del contenitore (in pixel)
    const numColumns = 12; // Numero di colonne nel layout
    const margin = [10, 10]; // Margini tra le celle (in pixel)
    const boxWidth = (containerWidth - (numColumns + 1) * margin[0]) / numColumns;
    const elementWidth = boxWidth * w + margin[0] * 5;
    const boxHeight = 160;



    const getDashboard = async () => {
        try {
            const res = await getDashboardWidget({
                company_id: loggedCompany.id,
                widget_id: id,
                params: {company_type: companyType},
                signal: null,
            });

            setDetail(res);
        } catch (e) {
            setDetail({});
            setLoading(false);
            setRefreshing(false);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchDashboard() {
            if (loggedCompany) {
                setLoading(true);
                try {
                    const res = await getDashboardWidget({
                        company_id: loggedCompany.id,
                        widget_id: id,
                        params: {company_type: companyType},
                        signal,
                    });

                    setDetail(res);
                } catch (e) {
                    setDetail({});
                    setLoading(false);
                    setRefreshing(false);
                } finally {
                    setLoading(false);
                    setRefreshing(false);
                }
            }else{
                setDetail(false);
                setLoading(false);
                setRefreshing(false);
            }
        }

        fetchDashboard();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, companyType]);


    const height =  (boxHeight * h) - (ref?.current?.offsetHeight ?? 57) - 11;



    return (
        <>

            <div className={`col-span-${x} row-span-${y} h-full bg-white shadow-lg rounded-sm border border-slate-200`}>

                {
                    isLoading ? (
                        <div className="animate-pulse flex space-x-4">
                            <div className="flex-1 space-y-4 py-1">
                                <header className="px-5 py-4 border-b border-slate-100 flex justify-between">
                                    <div className="h-6 bg-gray-100 w-3/6 rounded"/>
                                    <div className="h-6 bg-gray-100 w-10 rounded" />
                                </header>
                                <div className="w-full py-4 flex justify-center items-center">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <header className="px-5 py-4 border-b border-slate-100 flex justify-between" ref={ref}>
                                <div>
                                    <div className={"flex items-center"}>
                                        {
                                            isEditing && (
                                                <div className="cursor-move mr-3 grid-item__title">
                                                    <svg className="w-3 h-3 fill-slate-500" viexbox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill="#CBD5E1" fillRule="evenodd" />
                                                    </svg>
                                                </div>
                                            )
                                        }

                                            <h2 className="font-semibold text-blue-1000 ">{detail.dashboard_widget_title ? detail.dashboard_widget_title : (detail?.dashboard_widget?.name ?? '')}</h2>
                                    </div>
                                </div>

                                <div className={"flex items-center"}>


                                    <div className="cursor-pointer mr-2" onClick={() => {
                                        setRefreshing(true)
                                        getDashboard();
                                    }}>
                                        {
                                            isRefreshing ? (
                                                <Loader />
                                            ) : (
                                                <RefreshIcon className={'h-4 w-4  text-gray-400 hover:text-gray-500'} />
                                            )
                                        }
                                    </div>


                                    <ActionsMenu classExtra={'z-50'}>

                                        {
                                            detail?.can_edit && (
                                                <button className={'text-left'}
                                                        onClick={() => {
                                                            setEditingWidget(true);
                                                        }}
                                                >

                                                    {t("app.common.edit")}
                                                </button>
                                            )
                                        }



                                        <button className={'text-left'} onClick={() => {
                                            const removeDetail = {
                                                title: t("dashboard.remove_title"),
                                                message: t("dashboard.remove_description"),
                                                endpoint: getPathParam([COMPANY, loggedCompany.id, WIDGET, detail.id])
                                            };
                                            onRemove(removeDetail)
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>

                                    </ActionsMenu>
                                </div>
                            </header>

                            <div>
                                {
                                    (detail?.dashboard_widget?.code === Widgets.SHIPPING_TOURNOVER) && (
                                        <TurnoverChart
                                            data={detail.data}
                                            dashboardWidget={detail}
                                            height={height - 70}
                                            width={elementWidth}
                                            isEditing={isEditingWidget}
                                            setIsOpen={(r) => {
                                                setEditingWidget(r);
                                            }}
                                            onFinalSubmit={(r) => {
                                                const editDetail = {
                                                    title: t("dashboard.edit_title"),
                                                    message: t("dashboard.edit_description"),
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, WIDGET, detail.id]),
                                                    endpointData: r
                                                };
                                                setConfirmDetail(editDetail);
                                                setEditingWidget(false);
                                                setOpenConfirm(true);
                                            }}
                                        />
                                    )
                                }


                                {
                                    (detail?.dashboard_widget?.code === Widgets.ACTIVE_SHIPPING) && (
                                        <OpenedShipping data={detail.data}  height={height} showType={!deviceIsMobile ? elementWidth > 768 ? 'lg' : 'sm' : false} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.SCHEDULED_CONTAINER_TRUCK) && (
                                        <CalendarLoad data={detail.data}  height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.COMPANY_TASK) && (
                                        <TaskList data={detail.data} height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.UNCONFIRMED_SHIPPING) && (
                                        <UnconfirmedShipping data={detail.data} height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.SHIPPING_TRACKING_LOG) && (
                                        <ShippingTrackingLog data={detail.data} height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.ACTIVE_DOCUMENT) && (
                                        <ActiveDocument data={detail.data} height={height} />
                                    )
                                }


                                {
                                    (detail?.dashboard_widget?.code === Widgets.SHIPPING_TRACKING_VESSELS) && (
                                        <ShippingTrackingVessel data={detail.data} height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.CONTAINER_MAP_IFRAME) && (
                                        <ContainerSearchTracking height={height} />
                                    )
                                }

                                {
                                    (detail?.dashboard_widget?.code === Widgets.SHIPPING_STATISTICS) && (
                                        <ShippingStatistics
                                            data={detail.data}
                                             dashboardWidget={detail}
                                             height={height - 70}
                                             width={elementWidth}
                                             isEditing={isEditingWidget}
                                             setIsOpen={(r) => {
                                                 setEditingWidget(r);
                                             }}
                                             onFinalSubmit={(r) => {
                                                 const editDetail = {
                                                     title: t("dashboard.edit_title"),
                                                     message: t("dashboard.edit_description"),
                                                     endpoint: getPathParam([COMPANY, loggedCompany.id, WIDGET, detail.id]),
                                                     endpointData: r
                                                 };
                                                 setConfirmDetail(editDetail);
                                                 setEditingWidget(false);
                                                 setOpenConfirm(true);
                                             }}
                                        />
                                    )
                                }

                                    {
                                        (detail?.dashboard_widget?.code === Widgets.COMPANY_BOARD) && (
                                            <CompanyBoard 
                                                height={height} 
                                                dashboardWidget={detail}
                                                data={detail.data}
                                                onRefreshed={() => {
                                                    setRefreshing(true)
                                                    getDashboard();
                                                }}
                                                isEditing={isEditingWidget}
                                                setIsOpen={(r) => {
                                                    setEditingWidget(r);
                                                }}
                                                onFinalSubmit={(r) => {
                                                    const editDetail = {
                                                        title: t("dashboard.edit_title"),
                                                        message: t("dashboard.edit_description"),
                                                        endpoint: getPathParam([COMPANY, loggedCompany.id, WIDGET, detail.id]),
                                                        endpointData: r
                                                    };
                                                    setConfirmDetail(editDetail);
                                                    setEditingWidget(false);
                                                    setOpenConfirm(true);
                                                }}
                                            />
                                        )
                                    }

                                

                            </div>
                        </>
                    )
                }




            </div>


            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() =>{
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        setDetail(r);
                    }}
                />
            )}
        </>

    );
};

export default Dashboard;
