import {SHIPPING, EVENTS, EVENT, EVENT_TYPE, SHIPPING_IMPORTER} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";


// Event
export const getEvents = ({shipping_id, params}) =>
    API.get(getPathParam([SHIPPING, shipping_id, EVENTS] ) + serialize(params));

export const getEvent = ({shipping_id, event_id, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, EVENT, event_id ] ), {signal: signal});

export const getEventType = ({shipping_id}) =>
    API.get(getPathParam([SHIPPING, shipping_id, EVENT_TYPE ]));

// Exporter
export const postEvent = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, EVENT]), data);

export const putEvent = ({shipping_id, event_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, EVENT, event_id]), data);

export const deleteEvent = ({shipping_id, event_id}) =>
    API.delete(getPathParam([SHIPPING, shipping_id, EVENT, event_id]));

// Importer
export const postEventImporter = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING_IMPORTER, shipping_id, EVENT]), data);

export const putEventImporter = ({shipping_id, event_id, data}) =>
    API.put(getPathParam([SHIPPING_IMPORTER, shipping_id, EVENT, event_id]), data);

export const deleteEventImporter = ({shipping_id, event_id}) =>
    API.delete(getPathParam([SHIPPING_IMPORTER, shipping_id, EVENT, event_id]));
