import React from "react";
import { useTranslation } from "react-i18next";
import img404 from "../resources/images/404.png";
import doklessBg from "../resources/images/dokless-bg-2.png";
import {Link} from "react-router-dom";
import {DASHBOARD} from "./endpoints";

const Page404 = () => {
  const { t } = useTranslation();
  return (
      <div className="h-full flex flex-col before:flex-1 after:flex-1  ">

      <div className="max-w-7xl mx-auto lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className=" w-full xl:w-1/2 relative pb-12 lg:pb-0 flex items-center">
              <div className="relative">
                  <div className="absolute">
                      <div className="">
                          <h1 className="my-2 text-gray-800 font-bold text-2xl">{t("app.common.not_found_title")}</h1>
                          <p className="my-2 text-gray-800">{t("app.common.not_found_description")}</p>
                          <Link className="btn mt-2 bg-indigo-800 hover:bg-indigo-900 text-white" to={DASHBOARD}>{t('app.common.go_to', {label: ' Dashboard ->'})}</Link>
                      </div>
                  </div>
                  <div>
                      <img src={img404} alt="" />
                  </div>
              </div>
          </div>
          <div>
              <img src={doklessBg} alt="" />
          </div>
      </div>
      </div>

  );
};

export default Page404;
