import React, {useState} from 'react'
import { useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import {getFormattedDate} from "../../../utils/timeUtils";

import ReportViewer from "../../../components/partials/report/report-viewer";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const UserSubscription = () =>{

    const { detail } = useSelector((state) => state.user );
    const { subscriptionUsage, isLoadingUsage } = useSelector((state) => state.subscription );

  //  const [isLoadingBilling, setLoadingBilling] = useState(false);
  //  const [isLoadingCancel, setLoadingCancel] = useState(false);
  //  const [isLoadingActive, setLoadingActive] = useState(false);
  //  const [isLoadingPayment, setLoadingPayment] = useState(false);
  //  let dispatch = useDispatch();

 //   const [isOpenUpdateChange, setOpenUpdateChange] = useState(false);
  //  const [paymentDetail, setPaymentDetail] = useState(false);
   // const [changeDetail, setChangeDetail] = useState(false);
    const [url] = useState(null);
    const [isOpenReportView, setOpenReportView] = useState(false);

    const { t } = useTranslation();

    /*const activeSubscription = () => {
       setLoadingActive(true);
       putActiveSubscription().then((response) => {
           dispatch(onboardingDetails(response));
       }).catch((e) => {
           setLoadingActive(false);
       });
   };

  const cancelSubscription = () => {
       setLoadingCancel(true);
       putCancelSubscription().then((response) => {
           dispatch(onboardingDetails(response));
       }).catch((e) => {
           setLoadingCancel(false);
       });
   };


   const paySubscription = (id) => {
       setLoadingPayment(true);
       putPaySubscription({subscription_id: id}).then((response) => {
           setChangeDetail({
               title: t("app.settings.pay_subscription")
           });
           setPaymentDetail(response)
           setOpenUpdateChange(true)
           setLoadingPayment(true);
           //dispatch(onboardingDetails(response));
       }).catch((e) => {
           setLoadingPayment(false);
       });
   };
*/
    /*
    const subscriptionBilling = () => {
        setLoadingBilling(true);
        getSubscriptionBilling().then((response) => {
            window.location.href  = response.url;
        }).catch((e) => {
            setLoadingBilling(false);
        });
    };*/

    const getFiltered = () => {
        if(subscriptionUsage.length > 0){
            let list = subscriptionUsage,
                result = list.reduce(function (r, a) {
                    let flag = a.package.type;
                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }

    /*
    const getSubscriptionStatus = (subDetail, checkStatus = true, statusType = '') => {

        let isTrial = subDetail.status === 'trailing';
        let isDeadline = subDetail.deadline > 0;
        let isTolerance = subDetail.tolerance_days > 0;
        let isCanceled = subDetail.is_canceled;
        let dayLabel = subDetail.deadline > 1 ? t("app.plan.days") :  t("app.plan.day");
        let taxFreExpired = detail?.tax_free_expired ?? false;

        let type = '';
        let status = true;
        let title = '';
        let description = '';



        if(checkStatus){

            if(!taxFreExpired){
                if(!isCanceled && isTrial && isDeadline && !isTolerance){
                    type = 'in_trial';
                    title = t("app.plan.in_trial_title");
                    description = t("app.plan.in_trial_description_html", {days: subDetail.deadline, day_label: dayLabel});
                }

                if(!isCanceled && !isTrial && !isDeadline && isTolerance){
                    status = false;
                    type = 'missing_update'; // MANCATO RINNOVO
                    title = t("app.plan.missing_update_title");
                    description = t("app.plan.missing_update_description_html", {date: getFormattedDate(subDetail.date_end), days: subDetail.deadline, day_label: dayLabel});

                }
                if(!isCanceled && isTrial && !isDeadline && !isTolerance){
                    status = false;
                    type = 'trial_end'; // PERIODO DI PROVA TERMINATO
                    title = t("app.plan.trial_end_title");
                    description = t("app.plan.trial_end_description_html");
                }

                if(!isCanceled && !isTrial && isDeadline && !isTolerance){
                    type = 'subscription_active'; // ABBONAMENTO ATTIVO
                    title = t("app.plan.subscription_active_title");
                    if(subDetail.renewal_price === 0 ){
                        description = t("app.plan.subscription_credit_description_html", {date: getFormattedDate(subDetail.date_end), price: numberFormat(Math.abs(subDetail.amount_balance))});
                    }else{
                        description = t("app.plan.subscription_active_description_html", {date: getFormattedDate(subDetail.date_end), price: numberFormat(subDetail.renewal_price)});
                    }
                }

                if(isCanceled && isDeadline){
                    type = 'subscription_disactive'; // ABBONAMENTO DISATTIVATO
                    title = t("app.plan.subscription_disactive_title");
                    description = t("app.plan.subscription_disactive_description_html", {date: getFormattedDate(subDetail.date_end)});
                }

                if(!isCanceled && !isTrial && !isDeadline && !isTolerance){
                    status = false;
                    type = 'subscription_expire';  // ABBONAMENTO SCADUTO
                    title = t("app.plan.subscription_expire_title");
                    description = t("app.plan.subscription_expire_description_html");
                }

            }else{
                type = 'subscription_disactive'; // ABBONAMENTO DISATTIVATO
                title = t("app.plan.subscription_disactive_title");
                description = t("app.plan.subscription_expire_tax_description_html");
            }

            return {status: status, type: type, title: title, description: description}
        }else{
            if(statusType === 'disactive' && !isCanceled && isDeadline){
                return true;
            }

            if(statusType === 'active' && isCanceled && isDeadline){
                return true;
            }

            if(statusType === 'active_label' && !isCanceled && isDeadline && !isTolerance){
                return true;
            }

            return false;
        }

    }
    */


    return (

        <>
            <ReportViewer
                isOpen={isOpenReportView}
                reportDetail={url ? {reference: url} : false}
                title={t("app.document.preview_file")}
                isActiveConfirm={false}
                setIsOpen={(r) => {
                    setOpenReportView(r);
                }}
            />

            <LoaderWrapper isLoading={isLoadingUsage} >
                <div className="mx-auto">


                    {
                        (detail?.last_subscription ?? false) && (
                            <>
                                {/*
                                <div className="bg-white shadow-sm rounded-sm relative mb-5">
                                    <div className="lg:col-span-9 shadow">
                                        <div className="py-6 px-4 sm:p-6 lg:pb-8">

                                            <div>
                                                <h2 className="text-lg leading-6 font-medium text-gray-900">{t("app.plan.active_plan")}</h2>
                                                <p className="mt-1 text-sm text-gray-500">{t("app.plan.active_plan_description")}</p>

                                                <div className="mt-5 relative ">
                                                    <div className="mx-auto max-w-lg border border-solitude-100 overflow-hidden rounded-md shadow lg:flex lg:max-w-none" style={{borderColor: detail.last_subscription.subscription?.color ?? '#1E3A8A'}}>

                                                        <div className={"py-8 px-6 text-center lg:flex lg:flex-col lg:justify-center lg:p-12"} style={{backgroundColor: detail.last_subscription.subscription?.color ?? '#1E3A8A'}}>
                                                            <div className="flex items-center justify-center text-4xl font-bold tracking-tight text-white">
                                                                <span>{detail.last_subscription.subscription.name}</span>
                                                            </div>

                                                            {
                                                                !detail.tax_free_expired && getSubscriptionStatus(detail.last_subscription, false, 'disactive') && (
                                                                    <p className="text-xs text-white  underline lowercase mt-2 mr-2 cursor-pointer " onClick={() => {
                                                                        cancelSubscription()
                                                                    }}>
                                                                        {
                                                                            isLoadingCancel ? (
                                                                                <Loader />
                                                                            ) : (
                                                                                <span>{t("app.plan.disactive_subscription")}</span>

                                                                            )
                                                                        }
                                                                    </p>
                                                                )
                                                            }


                                                        </div>

                                                        <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                                            <div className="flex min-w-0 items-center justify-between">

                                                                <div className="flex justify-between items-center ">
                                                                    <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight mr-2">
                                                                        {getSubscriptionStatus(detail.last_subscription).title}
                                                                    </h3>
                                                                    {
                                                                        getSubscriptionStatus(detail.last_subscription).status ? (
                                                                            <p className="ml-3 whitespace-nowrap rounded-lg bg-green-100 py-0.5 px-2 text-xs font-semibold leading-6 text-green-700">
                                                                                {t("app.plan.status_active")}
                                                                            </p>
                                                                        ) : (
                                                                            <p className="ml-3 whitespace-nowrap rounded-lg bg-red-100 py-0.5 px-2 text-xs font-semibold leading-6 text-red-700">
                                                                                {t("app.plan.status_inactive")}
                                                                            </p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <p className="mt-2 text-base text-gray-900" dangerouslySetInnerHTML={{__html: getSubscriptionStatus(detail.last_subscription).description}} />

                                                            <div className="mt-8">
                                                                <div className="flex-1 border-t-2 border-gray-200" />
                                                                <div  className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                    <div>
                                                                        <div className="flex min-w-0 items-center justify-between">

                                                                            <div className="flex justify-between items-center ">
                                                                                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                                                                                    {t("app.plan.billing_details")}
                                                                                </h3>
                                                                                {
                                                                                    (detail.last_subscription?.file ?? false ) && (
                                                                                        <div
                                                                                            rel="noreferrer"
                                                                                            onClick={() => {
                                                                                                setUrl(detail.last_subscription.file.reference);
                                                                                                setOpenReportView(true);
                                                                                            }}
                                                                                            className="ml-3 text-sm text-indigo-900 underline font-semibold lowercase cursor-pointer mr-2"
                                                                                        >
                                                                                            <span>{t("app.plan.download_document")}</span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                        <p className="mt-2 text-gray-900 text-sm">
                                                                            <b>{detail.last_subscription.company_name}</b><br />
                                                                            <span>{detail.last_subscription?.address ?? ''} - {detail.last_subscription?.zip_code ?? ''}, {detail.last_subscription?.city ?? ''} {detail.last_subscription?.country?.name ?? ''}</span><br />
                                                                            <span>{detail.last_subscription?.vat_code ?? ''}</span><br />
                                                                            <span>{detail.last_subscription?.billing_note_detail ?? ''}</span>
                                                                        </p>



                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="flex min-w-0 items-center justify-between before:flex-1 mt-10">

                                                                <div className="flex items-center justify-between">

                                                                    {
                                                                        getSubscriptionStatus(detail.last_subscription, false, 'active')  && (
                                                                            <div className="mr-2">
                                                                                <WhiteButton
                                                                                    isLoading={isLoadingActive}
                                                                                    label={t("app.plan.active_subscription")}
                                                                                    onClick={() => {
                                                                                        activeSubscription()
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }





                                                                    {
                                                                        !getSubscriptionStatus(detail.last_subscription).status && (
                                                                            <div className="mr-2">
                                                                                <WhiteButton
                                                                                    isLoading={isLoadingPayment}
                                                                                    label={t("app.plan.active_subscription")}
                                                                                    onClick={() => {
                                                                                        paySubscription(detail.last_subscription.id)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div>
                                                                        <PrimaryButton
                                                                            isLoading={isLoadingBilling}
                                                                            label={t("app.plan.edit_payment_method")}
                                                                            onClick={() => {
                                                                                subscriptionBilling();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>






                                                    </div>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>

                                */}

                                {
                                    ((subscriptionUsage ?? []).length > 0) && (

                                        <div className="bg-white shadow-sm rounded-sm relative mb-5">
                                            <div className="lg:col-span-9 shadow">
                                                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                                        {t("app.plan.plan_usage")}
                                                    </h3>
                                                    <p className="text-sm mb-5">{t("app.plan.plan_reset", {date: getFormattedDate(detail.last_subscription.date_end)})}</p>


                                                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-6 sm:grid-cols-1">

                                                        {Object.keys(getFiltered()).map((group) => (
                                                            <div key={group} className="mt-3">
                                                                <h3 className="text-md font-semibold border-b leading-6 text-gray-900">
                                                                    {t("app.plan.package_"+group)}
                                                                </h3>
                                                                {getFiltered()[group].sort((a, b) => b.usage_limit > a.usage_limit ? 1 : -1).map((data, index) => (
                                                                    <UsageProgress
                                                                        key={index}
                                                                        title={data.package.name}
                                                                        maxValue={data.usage_limit}
                                                                        minValue={data.used_limit}
                                                                        unlimited={data.unlimited}
                                                                    />

                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )


                                }

                            </>
                        )
                    }

                </div>


                {  /* isOpenUpdateChange && (
                    <PaymentPopup
                        isOpen={isOpenUpdateChange}
                        setIsOpen={(r) => {
                            setOpenUpdateChange(r);
                            if(r === false){
                                setLoadingPayment(false);
                            }
                        }}
                        detail={changeDetail}
                        changeDetailData={paymentDetail}
                    />
                )
                 */}

            </LoaderWrapper>
        </>



    );
};


const UsageProgress = ({title, maxValue, minValue, unlimited}) => {

    let minV = minValue ?? 0;
    const percentage = unlimited ? 100 : ((100 / maxValue ) * minV);
    const { t } = useTranslation();

    return (
        <div>
            <div className="flex  items-center justify-between mt-2 mb-2">
                <h2 className="text-sm leading-6 font-medium text-gray-900">{title}</h2>
                <h2 className="text-sm leading-6 font-medium text-gray-900 "> {unlimited ? t('app.plan.unlimited') : minV+"/"+maxValue}</h2>
            </div>
            <div className="w-full bg-gray-200 rounded">
                <div className={classNames(
                    ((percentage >= 50) && !unlimited) ? 'bg-green-600' : '',
                    ((percentage < 50) && !unlimited)  ? 'bg-green-600' : '',
                    unlimited ? 'bg-green-600' : '',
                    "text-xs font-medium rounded text-white text-center p-2 leading-none"
                )} style={{width: (percentage > 100 ? 100  : percentage)+'%'}}>
                </div>
            </div>
        </div>
    );
}



export default UserSubscription;
