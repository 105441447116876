import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  oauthLogin,
  oauthLogout,
  oauthRegister,
  getUserDetails,
  resendVerificationMail,
  confirmVerificationMail,
  sendResetPassword,
  confirmResetPassword,
  checkConfirmResetPassword,
  putUser,
} from "../api/user";

import {
  setToken,
  clearToken,
  isAuthenticated,
  getToken,
} from "../utils/tokenUtil";
import {
  COMPANY_EXPORTER,
  USER_AGENT,
  USER_COMPANY,
  USER_EMPLOYEE,
} from "../constants/config";
import {clearData, getData, setData} from "../utils/cacheUtil";

export const userStatus = {
  FAILED: "failed",
  SUCCESS: "success",
  PENDING: "pending",
};

export const userOnboardingStatus = {
  NEED_VERIFICATION: "need_verification",
  COMPANY_DEFAULT_TYPE: "company_default_type",
  MISSING_COMPANY: "missing_company",
  CONFIRMED: "confirmed",
};

const initialState = {
  isAuthenticated: isAuthenticated(),
  token: getToken(),
  loggedCompany: getData("loggedCompany"),
  companyType: getData("companyType") ? getData("companyType") : null,
  isExporter: getData("companyType") ? getData("companyType") === COMPANY_EXPORTER : null,
  detail: null,
  permissions: getData("permissions") ? getData("permissions") : [],
};

/***************
 * ASYNC THUNK *
 ***************/

export const login = createAsyncThunk("oauth/login", async (data) => {
  try {
    const response = await oauthLogin(data);
    await setToken(response.token);
    return {
      isAuthenticated: true,
      token: await getToken(),
      detail: null,
    };
  } catch (e) {
    throw e;
  }
});

export const register = createAsyncThunk("oauth/register", async (data) => {
  try {
    return {
      detail: await oauthRegister(data),
    };
  } catch (e) {
    throw e;
  }
});

const logout = createAsyncThunk("oauth/logout", async () => {
  try {
    await oauthLogout();
    clearToken();
    localStorage.clear();
    window.location.reload();
    return;
  } catch (e) {
    throw e;
  }
});

export const resendMail = createAsyncThunk(
  "verify/resend_mail",
  async (user_id) => {
    try {
      await resendVerificationMail(user_id);
      return;
    } catch (e) {
      throw e;
    }
  }
);

export const checkConfirmMail = createAsyncThunk(
  "verify/confirm_mail/check",
  async (confirmation_code) => {
    try {
      const response = await confirmVerificationMail(
        confirmation_code + "/check"
      );
      return {
        isCheckConfirmed: response.status,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const confirmMail = createAsyncThunk(
  "verify/confirm_mail",
  async (response) => {
    try {
      await setToken(response.token);
      return {
        isAuthenticated: true,
        token: await getToken(),
        detail: response,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const getDetails = createAsyncThunk("user/details", async () => {
  try {
    const response = isAuthenticated() ? await getUserDetails() : null;
    return {
      detail: response,
    };
  } catch (e) {
    throw e;
  }
});

export const resetPasswordRequest = createAsyncThunk(
  "user/reset_password_request",
  async (data) => {
    try {
      const response = await sendResetPassword(data);
      return {
        isRequestSend: response.status === "ok",
      };
    } catch (e) {
      throw e;
    }
  }
);

export const checkResetPassword = createAsyncThunk(
  "user/check_reset_password",
  async (confirmation_code) => {
    try {
      const response = await checkConfirmResetPassword(confirmation_code);
      return {
        isCheckConfirmed: response.status === "ok",
      };
    } catch (e) {
      throw e;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/reset_password",
  async (data) => {
    try {
      const response = await confirmResetPassword(
        data.confirmation_code,
        data.data
      );
      return {
        isResetConfirmed: response.status === "ok",
      };
    } catch (e) {
      throw e;
    }
  }
);

export const updateCompanyType = createAsyncThunk(
  "user/company_type",
  async (data) => {
    try {
      await setData("companyType", data);
      return {
        companyType: data,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const updateCompanyDetails = createAsyncThunk(
    "user/update_company_details", async (data) => {
      try {
        return {company_data: data};
      } catch (e) {
        throw e;
      }
    }
);

export const updateSignature = createAsyncThunk(
    "user/update_signature", async (data) => {
      try {
        return {detail: data};
      } catch (e) {
        throw e;
      }
    }
);

export const updateDetails = createAsyncThunk(
  "user/update_details",
  async ({ user_id, data }) => {
    try {
      return {
        detail: await putUser({ user_id, data }),
      };
    } catch (e) {
      throw e;
    }
  }
);

export const onboardingDetails = createAsyncThunk(
  "user/onboarding_details",
  async (detail) => {
    try {
      return {
        detail: detail,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const updateLoggedCompany = createAsyncThunk(
  "user/update_logged_company",
  async (detail) => {
    try {
      await setData("loggedCompany", detail.company);
      await setData("loggedConnection", true);
      await setData("companyType", COMPANY_EXPORTER);
      return {
        loggedCompany: detail.company,
        companyType: COMPANY_EXPORTER,
        isExporter: true,
      };
    } catch (e) {
      throw e;
    }
  }
);

export const updateOwnerLoggedCompany = createAsyncThunk(
    "user/update_owner_logged_company",
    async (company) => {
      try {
        await setData("loggedCompany", company);
        await setData("companyType", company.company_type);
        await clearData("loggedConnection");

        return {
          loggedCompany: company,
          companyType: company.company_type,
          isExporter: company.company_type === COMPANY_EXPORTER,
        };
      } catch (e) {
        throw e;
      }
    }
);

export const getRefreshdDetails = createAsyncThunk("user/refresh_details", async () => {
  try {
    const response = isAuthenticated() ? await getUserDetails() : null;
    return {
      detail: response,
    };
  } catch (e) {
    throw e;
  }
});

/*******************
 * EXPOSED          *
 *******************/

export const loadUserDataIfMissing = () => (dispatch, getState) => {
  const { detail, isAuthenticated } = getState().user;
  if (detail === null && isAuthenticated) {
    dispatch(getDetails());
  }
};

export const safeLogout = () => (dispatch, getState) => {
  const { isAuthenticated } = getState().user;
  if (isAuthenticated) {
    dispatch(logout());
  }
};

export const safeChangeCompany = (data) => (dispatch, getState) => {
  const { companyType } = getState().user;
  if (companyType !== data) {
    dispatch(updateCompanyType(data));
  }
};


const getCompanyType = ({detail, loggedConnection}) => {

  let companyType = null;
  if(detail.detail.company_type){
    companyType = detail.detail.company_type;
  }else{
    if(loggedConnection && loggedConnection.permission_type){
      companyType = loggedConnection.permission_type;
    }else{
      if(loggedConnection && loggedConnection.company && loggedConnection.company.company_type){
        companyType = loggedConnection.company.company_type;
      }
    }
  }
  return companyType;
}

const getIsExporter = ({detail, loggedConnection}) => {
  return (getCompanyType({detail, loggedConnection}) === COMPANY_EXPORTER);
}


/*******************
 * REDUCERS EVENTS *
 *******************/

export const userReducer = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        for (const k in action.payload) {
          state[k] = action.payload[k];
        }
        state.isLoading = false;
        state.onboardingStatus = action.payload.detail.onboarding_status;

        const loggedConnection = (action.payload.detail && action.payload.detail.connections.length > 0) ? action.payload.detail.connections[0] : false ;
        const loggedCompany =  action.payload.detail?.company ??
            (action.payload.detail?.connections ?? [{}])[0]?.company;

        if (!getData("loggedCompany")) {
          state.loggedCompany = loggedCompany;
        }

        if (!getData("loggedConnection")) {
          if (!getData("companyType")) {
            state.companyType = getCompanyType({detail: action.payload, loggedConnection});
            state.isExporter = getIsExporter({detail: action.payload, loggedConnection});
          }
        }else{
          if (getData("companyType")) {
            state.isExporter =  getData("companyType") ? getData("companyType") === COMPANY_EXPORTER : null
          }else{
            state.companyType = null;
            state.isExporter = null;
          }


        }

        state.defaultType = action.payload.detail?.default_type;
        state.isEmployee =
          action.payload.detail?.default_type === USER_EMPLOYEE ?? false;
        state.isAgent =
          action.payload.detail?.default_type === USER_AGENT ?? false;
        state.isCompany =
          action.payload.detail?.default_type === USER_COMPANY ?? false;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.exception = action.error.message;
        state.isLoading = false;
        state.status = userStatus.FAILED;
      });
    builder.addCase(onboardingDetails.fulfilled, (state, action) => {
      for (const k in action.payload) {
        state[k] = action.payload[k];
      }
      state.isUpdateLoading = false;
      state.onboardingStatus = action.payload.detail.onboarding_status;

      const loggedConnection = (action.payload.detail && action.payload.detail.connections.length > 0) ? action.payload.detail.connections[0] : false;
      const loggedCompany =  action.payload.detail?.company ??
          (action.payload.detail?.connections ?? [{}])[0]?.company;

      if (!getData("loggedCompany")) {
        state.loggedCompany = loggedCompany;
      }


      if (!getData("loggedConnection")) {
        if (!getData("companyType")) {
          state.companyType = getCompanyType({detail: action.payload, loggedConnection});
          state.isExporter = getIsExporter({detail: action.payload, loggedConnection});
        }
      }else{
        if (getData("companyType")) {
          state.isExporter =  getData("companyType") ? getData("companyType") === COMPANY_EXPORTER : null
        }else{
          state.companyType = null;
          state.isExporter = null;
        }
      }


      state.defaultType = action.payload.detail?.default_type;
      state.isEmployee =
        action.payload.detail?.default_type === USER_EMPLOYEE ?? false;
      state.isAgent =
        action.payload.detail?.default_type === USER_AGENT ?? false;
      state.isCompany =
        action.payload.detail?.default_type === USER_COMPANY ?? false;
    });
    builder
      .addCase(login.pending, (state) => {
        state.isLoginLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        for (const k in action.payload) {
          state[k] = action.payload[k];
        }
        state.isLoginLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginLoading = false;
        state.loginException = action.error.message;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.isLogoutLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
        state.isAuthenticated = false;
        state.isLogoutLoading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLogoutLoading = false;
        state.logoutexception = action.error.message;
      });
    builder
      .addCase(register.pending, (state) => {
        state.isRegisterLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isRegisterLoading = false;
        state.detail = action.payload.detail;
        state.onboardingStatus = action.payload.detail.onboarding_status;
      })
      .addCase(register.rejected, (state, action) => {
        state.isRegisterLoading = false;
        state.registerException = action.error.message;
      });
    builder
      .addCase(resendMail.pending, (state) => {
        state.isResendMailLoading = true;
      })
      .addCase(resendMail.fulfilled, (state, action) => {
        state.isResendMailLoading = false;
      })
      .addCase(resendMail.rejected, (state, action) => {
        state.isResendMailLoading = false;
        state.resendMailException = action.error.message;
      });
    builder
      .addCase(confirmMail.pending, (state) => {
        state.isConfirmMailLoading = true;
      })
      .addCase(confirmMail.fulfilled, (state, action) => {
        for (const k in action.payload) {
          state[k] = action.payload[k];
        }
        state.isLoading = false;
        state.onboardingStatus = action.payload.detail.onboarding_status;

        const loggedConnection = (action.payload.detail && action.payload.detail.connections.length > 0) ? action.payload.detail.connections[0] : false;
        const loggedCompany =  action.payload.detail?.company ??
            (action.payload.detail?.connections ?? [{}])[0]?.company;

        if (!getData("loggedCompany")) {
          state.loggedCompany = loggedCompany;
        }

        if (!getData("loggedConnection")) {
          state.companyType = getCompanyType({detail: action.payload, loggedConnection});
          state.isExporter = getIsExporter({detail: action.payload, loggedConnection});
        }else{
          state.companyType = null;
          state.isExporter = null;
        }

        state.defaultType = action.payload.detail?.default_type;
        state.isEmployee =
            action.payload.detail?.default_type === USER_EMPLOYEE ?? false;
        state.isAgent =
            action.payload.detail?.default_type === USER_AGENT ?? false;
        state.isCompany =
            action.payload.detail?.default_type === USER_COMPANY ?? false;
      })
      .addCase(confirmMail.rejected, (state, action) => {
        state.isConfirmMailLoading = false;
        state.confirmMailException = action.error.message;
      });
    builder
      .addCase(checkConfirmMail.pending, (state) => {
        state.isCheckMailLoading = true;
      })
      .addCase(checkConfirmMail.fulfilled, (state, action) => {
        state.checkstatus = action.payload.status;
        state.isCheckMailLoading = false;
      })
      .addCase(checkConfirmMail.rejected, (state, action) => {
        state.isCheckMailLoading = false;
        state.checkMailException = action.error.message;
      });
    builder
      .addCase(resetPasswordRequest.pending, (state) => {
        state.isResetRequestLoading = true;
      })
      .addCase(resetPasswordRequest.fulfilled, (state, action) => {
        state.isRequestSend = action.payload.isRequestSend;
        state.isResetRequestLoading = false;
      })
      .addCase(resetPasswordRequest.rejected, (state, action) => {
        state.isResetRequestLoading = false;
        state.resetRequestException = action.error.message;
      });
    builder
      .addCase(checkResetPassword.pending, (state) => {
        state.isCheckResetLoading = true;
      })
      .addCase(checkResetPassword.fulfilled, (state, action) => {
        state.isCheckConfirmed = action.payload.isCheckConfirmed;
        state.isCheckResetLoading = false;
      })
      .addCase(checkResetPassword.rejected, (state, action) => {
        state.isCheckResetLoading = false;
        state.checkResetException = action.error.message;
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isResetPasswordLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isResetConfirmed = action.payload.isResetConfirmed;
        state.isResetPasswordLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isResetPasswordLoading = false;
        state.resetException = action.error.message;
      });
    builder.addCase(updateCompanyType.fulfilled, (state, action) => {
      state.companyType = action.payload.companyType;
      state.isExporter = action.payload.companyType === COMPANY_EXPORTER;
    });
    builder.addCase(updateLoggedCompany.fulfilled, (state, action) => {
      state.loggedCompany = action.payload.loggedCompany;
      state.companyType = action.payload.companyType;
      state.isExporter = action.payload.isExporter;
      state.isSwitched = state.isSwitched !== undefined ? !state.isSwitched : true;
    });
    builder.addCase(updateOwnerLoggedCompany.fulfilled, (state, action) => {
      state.loggedCompany = action.payload.loggedCompany;
      state.companyType = action.payload.companyType;
      state.isExporter = action.payload.isExporter;
      state.isSwitched = state.isSwitched !== undefined ? !state.isSwitched : true;
    });
    builder.addCase(getRefreshdDetails.fulfilled, (state, action) => {
      for (const k in action.payload) {
        state[k] = action.payload[k];
      }
      state.isLoading = false;
      state.onboardingStatus = action.payload.detail.onboarding_status;

      const loggedConnection = (action.payload.detail && action.payload.detail.connections.length > 0) ? action.payload.detail.connections[0] : false ;


      const loggedCompany =  action.payload.detail?.company ??
          (action.payload.detail?.connections ?? [{}])[0]?.company;

      if (!getData("loggedCompany")) {
        state.loggedCompany = loggedCompany;
      }

      if (!getData("loggedConnection")) {
        state.companyType = getCompanyType({detail: action.payload, loggedConnection});
        state.isExporter = getIsExporter({detail: action.payload, loggedConnection});
      }else{
        state.companyType = null;
        state.isExporter = null;
      }

      state.defaultType = action.payload.detail?.default_type;
      state.isEmployee =
          action.payload.detail?.default_type === USER_EMPLOYEE ?? false;
      state.isAgent =
          action.payload.detail?.default_type === USER_AGENT ?? false;
      state.isCompany =
          action.payload.detail?.default_type === USER_COMPANY ?? false;

    });
    builder
        .addCase(updateCompanyDetails.pending, (state) => {
          state.isLoading = false;
        })
        .addCase(updateCompanyDetails.fulfilled, (state, action) => {
          let compData = action.payload.company_data;
          state.detail['billing_company_name'] = compData?.billing_company_name ?? null;
          state.detail['city'] = compData?.city ?? null;
          state.detail['address'] = compData?.address ?? null;
          state.detail['zip_code'] = compData?.zip_code ?? null;
          state.detail['country'] = compData?.country ?? null;
          state.detail['vat_code'] = compData?.vat_code ?? null;
          state.detail['billing_note_detail'] = compData?.billing_note_detail ?? null;
        });
    builder
        .addCase(updateSignature.pending, (state) => {
          state.isLoading = false;
        })
        .addCase(updateSignature.fulfilled, (state, action) => {
          let detail = action.payload.detail;
          state.detail['signature'] = detail?.signature ?? null;
        });
  },
});

export default userReducer.reducer;
