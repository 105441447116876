import React, {useState} from 'react';
import CustomerCell from "../common/table/customer-cell";
import { getFormattedDate} from "../../utils/timeUtils";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import {useTranslation} from "react-i18next";
import { EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {hasPermission} from "../partials/restricted/permission-wrapper";
import {pg, pt} from "../../constants/permissions";
import {useSelector} from "react-redux";
import {getWebPathParam} from "../../utils/converter";
import {LOGISTICS, SHIPPING, SHIPPING_DETAIL} from "../../routes/endpoints";
import FileCell from "../common/table/file-cell";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



function ActiveDocument({data, height}) {

  const { t } = useTranslation();


  return (
    <>
        {
            data.total_rows === 0 ? (
                <div className="text-center p-10">
                    <ShippingIcon
                        className="h-24 w-24 text-indigo-600 m-auto"
                        aria-hidden="true"
                    />
                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                        {t("dashboard.unconfirmed_shipping.not_found_title")}
                    </p>
                    <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                        {t("dashboard.unconfirmed_shipping.not_found_description")}
                    </p>

                </div>
            ) : (
                <nav style={{height: height+'px'}} className="overflow-y-scroll" aria-label="Directory">
                    <ul className="relative z-0 divide-y divide-gray-200">
                        {data.shippings.map((item) => (
                            <li key={item.id}>
                                <Row item={item} />
                            </li>
                        ))}
                    </ul>
                </nav>

            )
        }

    </>
  );
}

const Row = ({ item }) => {

    const { permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((s) => s.user);
    const { t } = useTranslation();
    const [isActive, setActive] = useState(false);
    const percentage =  (item.document_counter.uploaded * (100 / item.document_counter.total));

    let url = '#0';

    const isLinked = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.EDIT,
        item.customer.id,
        companyType,
        true
    );
    if(isLinked){
        url = getWebPathParam([LOGISTICS,SHIPPING, item.id, SHIPPING_DETAIL]);
    }


    return (
        <>
            <div className="flex items-center justify-between py-3 px-4">
                <div>
                    <a rel="noreferrer" href={url} target="_blank" >
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            <CustomerCell customer={item.customer} secondExtra={item.tracking} extra={item?.description ?? false}  />
                        </h2>
                    </a>
                </div>



                <div className="w-40">
                    <div className={"flex items-center justify-between"}>
                        <div className="text-xs text-blue-1000 font-semibold">

                            <div className="flex items-center">
                                <ShippingIcon className={'w-4 h-4 text-blue-1000 mr-1'} />
                                {getFormattedDate(item.departure_date)}
                            </div>
                        </div>
                        <div className="text-xs font-semibold text-blue-1000">
                            {item.document_counter.uploaded} / {item.document_counter.total}
                        </div>
                    </div>

                    <div className="w-full bg-gray-200 rounded">
                        <div className={classNames(
                            percentage >= 50 ? 'shim-green' : '',
                            percentage < 50  ? 'shim-red' : '',
                            "text-xs font-medium rounded text-white text-center p-2 leading-none"
                        )} style={{width: percentage+'%'}}>
                        </div>
                    </div>

                    {
                        item.documents.length > 0 && (
                            <div className="text-xs text-indigo-900 underline  cursor-pointer items-center text-right mt-2" onClick={() => {setActive(!isActive)}}>
                                {isActive ?  <div className="flex justify-end items-center"> <EyeOffIcon className="h-4 w-4 mr-2"/>  {t("dashboard.hide_documents")}</div> :
                                    <div className="flex items-center justify-end"> <EyeIcon className="h-4 w-4 mr-2"/>  {t("dashboard.view_documents")}</div>}
                            </div>
                        )
                    }

                </div>
            </div>

            {
                isActive && (
                    <div className="shadow-inner divide-y divide-gray-200">
                        {
                            item.documents.map((data) => (
                                <div className="py-3 px-4 bg-gray-100/[0.5] flex justify-between text-sm font-medium">
                                    <FileCell file={data} />
                                </div>
                            ))
                        }
                    </div>
                )
            }

        </>
    )
}


export default ActiveDocument;
