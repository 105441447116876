export default function WarehouseIcon(props) {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="transparent" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path d="M5.41667 17.25V8.63332C5.41667 8.11989 5.41667 7.86322 5.51657 7.66715C5.60446 7.49463 5.7447 7.35438 5.91718 7.26657C6.11326 7.16661 6.36995 7.16661 6.88333 7.16661H13.1167C13.6301 7.16661 13.8868 7.16661 14.0828 7.26657C14.2554 7.35438 14.3955 7.49463 14.4834 7.66715C14.5833 7.86322 14.5833 8.11989 14.5833 8.63332V17.25M8.16667 10.8333H11.8333M8.16667 14.5H11.8333M1.75 7.61407V15.7833C1.75 16.2966 1.75 16.5533 1.84991 16.7495C1.9378 16.9219 2.07803 17.0622 2.25051 17.1501C2.44659 17.25 2.70329 17.25 3.21667 17.25H16.7833C17.2968 17.25 17.5534 17.25 17.7495 17.1501C17.922 17.0622 18.0622 16.9219 18.1501 16.7495C18.25 16.5533 18.25 16.2966 18.25 15.7833V7.61407C18.25 6.62981 18.25 6.13766 18.1004 5.70288C17.9681 5.31838 17.7523 4.96801 17.4683 4.67697C17.1472 4.34788 16.7076 4.12659 15.8284 3.684L11.9784 1.74589C11.2532 1.38082 10.8906 1.19828 10.51 1.12645C10.173 1.06283 9.82702 1.06283 9.48997 1.12645C9.10937 1.19828 8.74683 1.38081 8.02157 1.74589L4.17157 3.684C3.29238 4.12659 2.85279 4.34788 2.53169 4.67697C2.24773 4.96801 2.03184 5.31838 1.89957 5.70288C1.75 6.13766 1.75 6.62982 1.75 7.61407Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}





