import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {ChevronDownIcon, CheckIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";


export default function InputSelectColors({     label,
                                              isRequired,
                                              id,
                                              errors,
                                              warning,
                                              warningRegex,
                                              onChangeData,
                                              translationPattern,
                                              valueData = false
                                          }) {
    const { t } = useTranslation();



    const options = [
        {
            color: "gray",
            bg: "bg-gray-600",
        },
        {
            color: "red",
            bg: "bg-red-600",
        },
        {
            color: "yellow",
            bg: "bg-yellow-600",
        },
        {
            color: "green",
            bg: "bg-green-600",
        },
        {
            color: "blue",
            bg: "bg-blue-600",
        },
        {
            color: "indigo",
            bg: "bg-indigo-600",
        },
        {
            color: "purple",
            bg: "bg-purple-600",
        },
        {
            color: "pink",
            bg: "bg-pink-600",
        }];


    return (
        <div className="relative">
            <label
                htmlFor={id}
                className="block text-sm font-medium mb-1 text-gray-700"
            >
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <Listbox
                value={valueData}
                onChange={(e) => {
                    onChangeData(e);
                }}
            >
                <div className="relative mt-1">


                    <Listbox.Button
                        className={`form-input w-full text-left ${
                            errors
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                : ""
                        }`}
                    >
                        <span className="block truncate">
                            {valueData ? <>
                            
                                <span className={`block truncate flex items-center`}>
                                    <div className={"h-4 w-4 rounded-full mr-3 " + (options?.find(r => r.color === valueData )?.bg ?? '')} />
                                    {t(translationPattern + valueData)}
                                </span>
                            
                            </> : t("app.common.placeholder", {
                            field: label.toLowerCase(),
                        })}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                          />
                        </span>
                    </Listbox.Button>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option.color}
                                    value={option.color}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                        }`
                                    }
                                >
                                    {({ selected }) => (
                                        <>


                                              <span className={`block truncate flex items-center ${selected ? 'font-medium' : 'font-normal'}`}>
                                                   <div className={"h-4 w-4 rounded-full mr-3 "+ option.bg} />
                                                    {t(translationPattern+option.color)}
                                              </span>

                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {errors && errors.type === "required" && (
                <p className="mt-2 text-sm text-red-600">
                    {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "validate" && (
                <p className="mt-2 text-sm text-red-600">
                    {warning ? warning : t("app.warning.description", { field: label })}
                </p>
            )}
            {errors && errors.type === "pattern" && (
                <p className="mt-2 text-sm text-red-600">
                    {warningRegex
                        ? warningRegex
                        : t("app.warning.description_regex", { field: label })}
                </p>
            )}
        </div>
    )
}
