import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



const EncodingSlidePanel = ({ isOpen, setIsOpen, children, setClean, title, codeHeader = null, encodeLimit = false}) => {

    const { t } = useTranslation();

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={"fixed inset-0 overflow-hidden z-50"} onClose={() => {

            }}>
                <div className="absolute inset-0 overflow-hidden">

                    <div className="fixed inset-y-0  max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-y-full"
                            enterTo="translate-y-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-y-0"
                            leaveTo="translate-y-full"
                        >
                            <div className={"w-screen max-w-full"}>


                                
                                <div className={classNames(
                                    "h-full divide-y divide-gray-200 flex flex-col  shadow-xl",
                                    "bg-gray-800"
                                )}>

                                    <div className={" relative"}>
                                        <div className={"relative py-3 px-4 z-10 backdrop-blur-sm bg-black/[0.10] supports-backdrop-blur:bg-black/[0.10] border-b border-white/[0.2]"}>
                                            <div className="flex items-center justify-between">

                                                <div className="flex items-center">
                                                   
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center justify-between">
                                                            <div className="h-7 flex items-center">
                                                                <button
                                                                    type="button"
                                                                    className="text-white hover:text-white  mr-2"
                                                                    onClick={() => { setIsOpen(false); setClean(); }}
                                                                >
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                            <Dialog.Title className="text-xl  text-white uppercase font-bold ">{title}</Dialog.Title>
                                                        </div>

                                                        {codeHeader && (
                                                            <div className="ml-3  flex items-center">
                                                                <div className="py-0.5 px-2 font-semibold rounded-md text-white border-2 border-dashed ">
                                                                    {codeHeader}
                                                                </div>
                                                                {
                                                                    (encodeLimit && codeHeader.length > encodeLimit)  ? (
                                                                        <span className="bg-red-800 py-0.5 px-2 ml-2 rounded-md font-semibold text-white">{codeHeader.length} {t("app.catalog.generetor_charaters")}</span>
                                                                    ) : (
                                                                        <span className="bg-white py-0.5 px-2 ml-2 rounded-md font-semibold text-gray-900">{codeHeader.length} {t("app.catalog.generetor_charaters")}</span>
                                                                    )
                                                                }
                                                            </div>
                                                         
                                                        )}
                                                        
                                                    </div>
                                                   
                                                </div>

                                                <div className="flex justify-end items-center md:ml-4 md:mt-0 ">
                                                   
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-1 flex flex-col justify-between" >
                                            <div id={'board-inner'} className={"  divide-y divide-gray-200 "}>
                                                {children}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};




export default EncodingSlidePanel;