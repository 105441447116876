import React from 'react'
import {useTranslation} from "react-i18next";

export default function CsvTable({params, columns, rows, onSetConfirmedHeader, confirmedHeader}) {

    const { t } = useTranslation();


    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="overflow-hidden border border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-solitude-400">
                            <tr>
                                {
                                    columns.map((data, columnsIndex) => (
                                        <th
                                            key={'select'+columnsIndex}
                                            className="tracking-wider"
                                        >
                                            <select
                                                name={data}
                                                onChange={(event) => {
                                                    if(event.target.value){
                                                        onSetConfirmedHeader(event.target.value, data)
                                                    }
                                                }}
                                                value={confirmedHeader?.find(e => e.file_param === data)?.confirmed_param ?? ''}
                                                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option>{t("app.common.select_field")}</option>
                                                {
                                                    params.map((param, paramsIndex) => (
                                                        <option
                                                            key={'option'+paramsIndex+'select'+columnsIndex}
                                                            disabled={confirmedHeader.filter(e => e.confirmed_param === param).length > 0}
                                                            value={param}>{param}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <thead className="bg-solitude-400">
                            <tr>
                                {
                                    columns.map((data, columnsIndex) => (
                                        <th
                                            key={'header'+columnsIndex}
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-1000 uppercase tracking-wider"
                                        >
                                            {data}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((row, index) => (
                                <tr key={'rows'+index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    {
                                        columns.map((data, id) => (
                                            <td key={'column'+id+'rows'+index} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {row[data]}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
