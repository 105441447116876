import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import InputSelect from "../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import {getWebPathParam} from "../../utils/converter";
import {Link, useNavigate} from "react-router-dom";
import {sp} from "../../constants/permissions";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {SALES, LEAD_OVERVIEW, LEAD} from "../endpoints";
import {
    fetchCountries,
    fetchCurrencies
} from "../../reducers/configReducer";
import SlidePanel from "../../components/layout/slide-panel";
import {emailRegex} from "../../constants/regex";
import InputFormattedNumber from "../../components/common/form/input-formatted-number";
import InputTextarea from "../../components/common/form/input-textarea";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {AcquisitionList} from "../../components/common/alert/acquisition-list";
import {postLeads} from "../../api/lead";
import {loadConnections} from "../../reducers/connectionReducer";

export default function AddLead({ isOpen, setIsOpen }) {
  const { loggedCompany } = useSelector((state) => state.user);
  const { isCountriesLoading, countries } = useSelector((state) => state.config);

  const {
    register,
    handleSubmit,
    control,
      watch,
    formState: { errors },
    setValue
  } = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();


  const STEP1 = 'contact';
  const STEP2 = 'company';
  const STEP3 = 'general';

  const tabsNavigation = [
      { name: t("app.leads.contact_info_title"),  group: STEP1 },
      { name: t("app.leads.company_info_title"), group: STEP2 },
      { name: t("app.leads.general_info_title"), group: STEP3 }
  ];


  const [isCreateLoading, setCreateLoading] = useState(false);
  const [createException, setCreateException] = useState(false);
  const [tab, setTab] = useState(STEP1);



  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
  }, [dispatch, loggedCompany]);



  useEffect(() => {
      if(watch('probability_percentage') > 100){
          setValue('probability_percentage', 100)
      }

      // eslint-disable-next-line
  }, [watch('probability_percentage')]);




  const storeQuotation = async (data) => {
    if (loggedCompany) {
      setCreateLoading(true);
      postLeads({ company_id: loggedCompany.id, data })
        .then((response) => {
          setCreateLoading(false);
            dispatch(loadConnections({company_id: loggedCompany.id, params: {find_all: true}}));
            navigate(getWebPathParam([SALES, LEAD, response.id, LEAD_OVERVIEW]));
        })
        .catch((e) => {
          setCreateException(e.message);
          setCreateLoading(false);
        });
    }
  };


  const onSubmit = async (data) => {

      if(data.contact_first_name === '' || data.contact_last_name === ''){
          setTab(STEP1);
      }else if(data.business_name === ''){
          setTab(STEP2);
      }else{
          if(tab === STEP1){
              setTab(STEP2);
          }
          if(tab === STEP2){
              setTab(STEP3);
          }
          if(tab === STEP3){
              await storeQuotation(data);
          }
      }

  };




  return (
      <SlidePanel
          title={t("app.leads.new_lead")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          bg={'bg-white'}
          size='max-w-3xl'
          setClean={() => {}}
      >
          <SubscriptionWrapper requiredPermission={sp.LEAD_PACKAGE} renderBaseOnFail={true} checkUsage={true}>

              <div className="-mt-3 ">
                  <UnderlineTab
                      tabs={tabsNavigation}
                      activeGroup={tab}
                      setActiveGroup={(r) => {
                          setTab(r);
                      }}
                  />
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className={' border-none pb-5 mt-5'}>
                  <div className="">



                      {
                          tab === STEP1 && (
                              <>
                                  <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                                      <InputText
                                          id={"contact_first_name"}
                                          type={"text"}
                                          errors={errors.contact_first_name}
                                          input={{ ...register("contact_first_name", { required: true }) }}
                                          label={t("app.leads.contact_first_name")}
                                          isRequired={true}
                                      />

                                      <InputText
                                          id={"contact_last_name"}
                                          type={"text"}
                                          errors={errors.contact_last_name}
                                          input={{ ...register("contact_last_name", { required: true }) }}
                                          label={t("app.leads.contact_last_name")}
                                          isRequired={true}
                                      />

                                      <InputText
                                          id={"business_email"}
                                          type={"text"}
                                          errors={errors.business_email}
                                          input={{ ...register("business_email", { required: true, pattern: emailRegex }) }}
                                          label={t("app.leads.business_email")}
                                          isRequired={true}
                                      />

                                      <InputText
                                          id={"business_phone"}
                                          type={"text"}
                                          errors={errors.business_phone}
                                          input={{ ...register("business_phone", { required: false }) }}
                                          label={t("app.leads.business_phone")}
                                      />
                                  </div>

                                  <div className="flex  items-center justify-between mt-6">

                                      <div>
                                      </div>
                                      <InputSubmit
                                          isLoading={false}
                                          label={t("app.common.next")}
                                      />
                                  </div>
                              </>

                          )
                      }


                      {
                          tab === STEP2 && (
                              <>
                                  <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">


                                      <InputText
                                          id={"business_name"}
                                          type={"text"}
                                          errors={errors.business_name}
                                          input={{ ...register("business_name", { required: true }) }}
                                          label={t("app.leads.business_name")}
                                          isRequired={true}
                                      />

                                      <InputText
                                          id={"company_website"}
                                          type={"text"}
                                          errors={errors.company_website}
                                          input={{ ...register("company_website", { required: false }) }}
                                          label={t("app.leads.company_website")}
                                      />


                                      <div className={'col-span-2'}>
                                          <InputTextarea
                                              id={"company_note"}
                                              errors={errors.company_note}
                                              input={{...register('company_note', { required: false })}}
                                              label={t("app.leads.company_note")}
                                          />
                                      </div>

                                      <Controller
                                          name="business_country"
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                   }) => (
                                              <InputSelect
                                                  label={t("app.leads.business_country")}
                                                  name={name}
                                                  options={countries}
                                                  value={value}
                                                  onChange={(e) => {
                                                      onChange(e);
                                                  }}
                                                  isLoading={isCountriesLoading}
                                                  isDisabled={isCountriesLoading}
                                                  errors={error}
                                              />
                                          )}
                                      />

                                      <InputText
                                          id={"company_industry"}
                                          type={"text"}
                                          errors={errors.company_industry}
                                          input={{ ...register("company_industry", { required: false }) }}
                                          label={t("app.leads.company_industry")}
                                      />


                                      <Controller
                                          name="company_employees"
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                   }) => (
                                              <InputFormattedNumber
                                                  label={t("app.leads.company_employees")}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                      onChange(e);
                                                  }}
                                                  prefix={""}
                                                  suffix={""}
                                                  errors={error}
                                              />
                                          )}
                                      />

                                      <Controller
                                          name="company_revenue"
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                   }) => (
                                              <InputFormattedNumber
                                                  label={t("app.leads.company_revenue")}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                      onChange(e);
                                                  }}
                                                  prefix={"€ "}
                                                  suffix={""}
                                                  errors={error}
                                              />
                                          )}
                                      />
                                  </div>



                                  <div className="flex  items-center justify-between mt-6">

                                      <div>
                                          <Link
                                              to={'#'}
                                              onClick={() => {
                                                  setTab(STEP1);
                                              }}
                                              className="text-sm  inline-flex  items-center"
                                          >
                                              <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                              {t("app.common.back")}
                                          </Link>
                                      </div>
                                      <InputSubmit
                                          isLoading={false}
                                          label={t("app.common.next")}
                                      />
                                  </div>
                              </>
                          )
                      }


                      {
                          tab === STEP3 && (
                              <>
                                  <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                                      <Controller
                                          name="estimated_value"
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                   }) => (
                                              <InputFormattedNumber
                                                  label={t("app.leads.estimated_value")}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                      onChange(e);
                                                  }}
                                                  prefix={"€ "}
                                                  suffix={""}
                                                  errors={error}
                                              />
                                          )}
                                      />

                                      <Controller
                                          name="probability_percentage"
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                   }) => (
                                              <InputFormattedNumber
                                                  label={t("app.leads.probability_percentage")}
                                                  name={name}
                                                  value={value}
                                                  onChange={(e) => {
                                                      onChange(e);
                                                  }}
                                                  prefix={""}
                                                  suffix={" %"}
                                                  errors={error}
                                              />
                                          )}
                                      />


                                      <div className={'col-span-2'}>
                                          <InputTextarea
                                              id={"internal_note"}
                                              errors={errors.internal_note}
                                              input={{...register('internal_note', { required: false })}}
                                              label={t("app.leads.internal_note")}
                                          />
                                      </div>


                                      <div>
                                          <label className="block text-sm font-medium mb-1 text-gray-700">
                                              {t("app.leads.priority")}
                                          </label>
                                          <AcquisitionList
                                              status={'low'}
                                              onChangeStatus={(r) => {
                                                  setValue('priority', r);
                                              }}
                                          />
                                      </div>
                                  </div>

                                  {/* Warning */}
                                  {createException && <Warning message={createException} />}

                                  <div className="flex  items-center justify-between mt-6">

                                      <div>
                                          <Link
                                              to={'#'}
                                              onClick={() => {
                                                  setTab(STEP2);
                                              }}
                                              className="text-sm  inline-flex  items-center"
                                          >
                                              <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                                              {t("app.common.back")}
                                          </Link>
                                      </div>

                                      <InputSubmit
                                          isLoading={isCreateLoading}
                                          label={t("app.leads.add_lead")}
                                      />
                                  </div>

                              </>

                          )}



                  </div>


              </form>
          </SubscriptionWrapper>

      </SlidePanel>
  );
}
