import React from 'react'
import SlidePanel from "../../../../../../../components/layout/slide-panel";
import Container20Hc from "../../../../../../../resources/loader/container-20-hc";
import NumberFormat from "react-number-format";
import {useTranslation} from "react-i18next";

const LoadDetail = ({
   isOpen,
   setIsOpen,
   containerLoadDetails,
   containerDetail
}) => {

    const { t } = useTranslation();

    return (
        <SlidePanel title={t("app.container.container_status")}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    bg={'bg-white'}
                    setClean={() => {

                    }}
        >

            <div className="space-y-6 pt-6 pb-5">
                <>
                    <div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                            <Container20Hc percentage={containerLoadDetails.percentage} />
                        </div>
                    </div>

                    {
                        containerDetail.container_note && (
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">{t("app.shippings.Note")}</h3>
                                <p className="text-gray-900">{containerDetail.container_note}</p>
                            </div>
                        )
                    }
                    
                    <div>
                        <h3 className="font-medium text-gray-900">{t("app.container.information")}</h3>
                        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">{t("app.container.volume")}</dt>
                                <dd className="text-gray-900">
                                    <NumberFormat
                                        value={containerLoadDetails.total_volume}
                                        displayType={"text"}
                                        decimalScale={2}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={' m³'}
                                    />
                                </dd>
                            </div>
                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">{t("app.container.gross_weight")}</dt>
                                <dd className="text-gray-900">
                                    <NumberFormat
                                        value={containerLoadDetails.total_gross_weight}
                                        displayType={"text"}
                                        decimalScale={2}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={' Kg'}
                                    />
                                </dd>
                            </div>

                            <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">{t("app.container.net_weight")}</dt>
                                <dd className="text-gray-900">
                                    <NumberFormat
                                        value={containerLoadDetails.total_net_weight}
                                        displayType={"text"}
                                        decimalScale={2}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={' Kg'}
                                    />
                                </dd>
                            </div>


                        </dl>
                    </div>


                    {
                        containerDetail.load_description && (
                            <div>
                                <h3 className="font-medium text-gray-900 mb-2">{t("app.container.load_information")}</h3>
                                <div dangerouslySetInnerHTML={{__html: containerDetail.load_description}} />
                            </div>
                        )
                    }
                </>

            </div>

        </SlidePanel>
    );
};

export default LoadDetail;
