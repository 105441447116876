import {
    COMPANY,
    PRODUCTS,
    PRODUCT,
    DIMENSION,
    IMAGE,
    PRICE_HISTORY,
    TRANSLATION,
    TRANSLATIONS,
    SELLING_HISTORY,
    CLONE
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// products
export const getProducts = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, PRODUCTS] ) + serialize(params), {signal: signal});

export const getProductsFilter = ({company_id, params, data, signal}) =>
    API.post(getPathParam([COMPANY, company_id, PRODUCTS] ) + serialize(params), data, {signal: signal});

export const getProductsSellingFilter = ({product_id, params, data, signal}) =>
    API.post(getPathParam([PRODUCT, product_id, SELLING_HISTORY]) + serialize(params), data, signal && { signal: signal });


export const getProduct = ({company_id, product_id, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, PRODUCT, product_id ] ), signal && {signal: signal});


export const getProductPurchaseHistory = ({company_id, product_id, signal = null}) =>
    API.get(getPathParam([COMPANY, company_id, PRODUCT, product_id, PRICE_HISTORY ] ), signal && {signal: signal});


export const postProduct = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, PRODUCT]), data);

export const putProduct = ({company_id, product_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, PRODUCT, product_id]), data);

export const putProductClone = ({ company_id, product_id, data }) =>
    API.put(getPathParam([COMPANY, company_id, PRODUCT, product_id, CLONE]), data);


export const deleteProduct = ({company_id, product_id}) =>
    API.delete(getPathParam([COMPANY, company_id, PRODUCT, product_id]));

export const deleteProductImage = ({company_id, product_id}) =>
    API.delete(getPathParam([COMPANY, company_id, PRODUCT, product_id, IMAGE]));

export const deleteProductImageGallery = ({company_id, product_id, media_id}) =>
    API.delete(getPathParam([COMPANY, company_id, PRODUCT, product_id, IMAGE, media_id]));

export const putProductDimension = ({product_id, dimension_id, data}) =>
    API.put(getPathParam([PRODUCT, product_id, DIMENSION, dimension_id]), data);

export const postProductDimension = ({product_id, data}) =>
    API.post(getPathParam([PRODUCT, product_id, DIMENSION]), data);

export const getProductTranslations = ({product_id, params, signal}) =>
    API.get(getPathParam([PRODUCT, product_id, TRANSLATIONS] ) + serialize(params), {signal: signal});

export const putProductTranslation = ({product_id, translation_id, data}) =>
    API.put(getPathParam([PRODUCT, product_id, TRANSLATION, translation_id]), data);

export const postProductTranslation = ({product_id, data}) =>
    API.post(getPathParam([PRODUCT, product_id, TRANSLATION]), data);
