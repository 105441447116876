import React from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../utils/converter";
import {COMPANY, IMPORT_MODEL, IMPORT_MODELS} from "../../../../api/endpoints";
import {useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";

const ImportModel = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_IMPORT_MODEL)]} renderBaseOnFail={true} >
            <>
                {
                    loggedCompany && (
                        <BaseList
                            columns={[
                                t("app.configuration.model_name"),
                                t("app.configuration.type"),
                                "",
                            ]}
                            endpoint={getPathParam([COMPANY, loggedCompany.id, IMPORT_MODELS])}
                            baseResult={{total_rows: 0, import_models: []}}
                            resultLabel={'import_models'}
                            title={t("app.configuration.model_name")}
                            mobRow={MobRow}
                            deskRow={DeskRow}
                        />
                    )
                }

            </>
        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.configuration.model_remove_title"),
        message: t("app.configuration.model_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, IMPORT_MODEL, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col gap-2 text-gray-500 text-sm truncate">
                    <div className="flex gap-1 justify-between">
                        {t("app.configuration.type")}{" "}
                        <span className="font-bold"> {t("app.configuration."+element.import_type)}</span>
                    </div>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.configuration.model_remove_title"),
        message: t("app.configuration.model_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, IMPORT_MODEL, element.id])
    };

    return (
        <tr key={element.id}>
            <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600"
                    >
                        {element.name}
                    </div>
                </div>
            </td>
            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                {t("app.configuration."+element.import_type)}
            </td>
            <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </td>
        </tr>
    );
};

export default ImportModel;
