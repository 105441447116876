import React, { useEffect, useState } from "react";

import PageHeader from "../../components/layout/page-header";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/outline";
import RemovePopup from "../../components/common/popup/remove-popup";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import { getPermission, permissionGroup, permissionType } from "../../constants/permissions";
import BoardDetail from "./board-details";
import { getElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { COMPANY } from "../../api/endpoints";
import { BOARD, BOARDS } from "../endpoints";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import AddBoard from "./add-board";
import { useLocation, useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Boards = () => {

  
  const { loggedCompany, detail } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [boards, setBoards] = useState({ total_rows: 0, boards: [] });

  const [isOpenAdd, setOpenAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);


  // Panel Details
  const [isOpenPanel, setOpenPanel] = useState(false);
  const [boardData, setBoardData] = useState(false);

  // Remove Details
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      setLoading(true);
      if (loggedCompany){
        try {
          const res = await getElement(getPathParam([COMPANY, loggedCompany.id, BOARDS]), signal);
          setBoards(res);

          const boardId = query.get('board_id');
          const taskId = query.get('task_id');

          if (boardId && res.boards.find(r => r.id === boardId)){
            setBoardData(res.boards.find(r => r.id === boardId));
            setOpenPanel(true);
            if (!taskId){
              navigate(location.pathname);
            }
          }

        } catch (e) {
          setBoards({ total_rows: 0, boards: [] });
        } finally {
          setLoading(false);
        }
      }
      }
      

    fetchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [loggedCompany, refresh]);







  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_TASK_PERMISSION, permissionType.MENU)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.board.title")}>
            
          </PageHeader>
        </div>
        <div className="max-w-5xl  mx-auto px-4 sm:px-6 md:px-8 relative">

          <LoaderWrapper isLoading={isLoading}>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

              <li className="col-span-1 divide-y divide-gray-200    ">
                <div className="rounded-lg  min-h-[100px] shadow bg-center cursor-pointer bg-gray-100 hover:bg-gray-300 text-white leading-5 relative no-underline p-2" 
                  onClick={() => {
                    setOpenAdd(true);
                  }}
                >

                  <div className="flex flex-col h-20 justify-center ">
                    <div className="  text-center w-full" >
                      <h3 className=" text font-semibold text-gray-900">{t("app.board.add_new")}</h3>
                    </div>
                  </div>
                </div>
              </li>

              {boards.boards.map((board) => (
                <li key={board.id} className="col-span-1 divide-y divide-gray-200 ">
                  <div
                    style={board.picture ? { backgroundImage: "url('" + board.picture.reference + "')" } : {}}
                    className={classNames(
                      "rounded-lg  min-h-[100px] shadow bg-center bg-cover text-white leading-5 relative no-underline p-2",
                      !board.picture && "bg-gray-900",
                    )} >

                    { 
                      board.picture && (
                        <div className="rounded-lg absolute min-h-[100px] inset-0 bg-black  bg-opacity-50 transition-opacity" />
                      )
                    
                    }

                    <div className="flex flex-col h-20 justify-between relative">

                      <div className="relative inline-block flex-auto text-base font-bold max-h-[50px] overflow-hidden w-full cursor-pointer" onClick={() => {
                        setBoardData(board);
                        setOpenPanel(true);
                      }}>
                        <h3 className="truncate text font-bold text-white">{board.title}</h3>
                      </div>

                      <div className="items-baseline flex flex-none justify-end">

                        {
                          detail.id === board.user.id && (
                            <div className="cursor-pointer" onClick={() => {
                              const removeDetail = {
                                title: t("app.board.remove_board_title"),
                                message: t("app.board.remove_board_description"),
                                endpoint: getPathParam([COMPANY, loggedCompany.id, BOARD, board.id])
                              };
                              setRemoveDetail(removeDetail);
                              setOpenRemove(true);
                            }}>
                              <TrashIcon className="h-4 w-4 text-red-400 hover:text-red-300 mr-2" />
                            </div>
                          )
                        }

                       
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </LoaderWrapper>

          

        </div>
      </div>

      {
        isOpenAdd && (
          <AddBoard
            isOpen={isOpenAdd}
            setIsOpen={setOpenAdd}
            onOpenBoard={(r) => {
              setRefresh(!refresh);
              setBoardData(r);
              setOpenPanel(true);

            }}
          />
        )
      }


      {isOpenRemove && (
        <RemovePopup
          isOpen={isOpenRemove}
          setIsOpen={setOpenRemove}
          detail={removeDetail}
          setDetail={(res) => {
            setBoards(res);
          }}
        />
      )}



      <BoardDetail
          isOpenPanel={isOpenPanel}
          setOpenPanel={setOpenPanel}
          boardData={boardData}
          onUpdateBoard={(res) => {
            setBoards(res);
          }}
      />


    </BasePermissionWrapper>
  );
};

export default Boards;
