import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {CalendarIcon, DocumentSearchIcon, PencilAltIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import {COMPLETED, DRAFT, DELIVERY} from "../../../constants/config";
import Loader from "../loader/loader";
import { CONFIRMED, NEGOTIATION } from "../../../api/endpoints";
import BoxIcon from "../../../resources/icons/BoxIcon";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const FulfillmentStatusList = ({ isLoading, fulfillmentType, status, onChangeStatus, isEditable = true}) => {
    const { t } = useTranslation();

    let statusStyles = {
        draft: {
            style: isEditable ? 'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white' : 'border-gray-800 text-gray-800',
            title: fulfillmentType === DELIVERY ? t("app.fulfillment.d_status_draft") : t("app.fulfillment.p_status_draft"),
            icon: PencilAltIcon
        },
        negotiation: {
            style: isEditable ? 'border-yellow-700 text-yellow-700 hover:bg-yellow-700 hover:text-white' : 'border-yellow-700 text-yellow-700',
            title: fulfillmentType === DELIVERY ? t("app.fulfillment.d_status_negotiation") : t("app.fulfillment.p_status_negotiation"),
            icon: DocumentSearchIcon
        },
        confirmed: {
            style: isEditable ? 'border-green-800 text-blue-800 hover:bg-blue-800 hover:text-white' : 'border-blue-800 text-blue-800',
            title: fulfillmentType === DELIVERY ? t("app.fulfillment.d_status_confirmed") : t("app.fulfillment.p_status_confirmed"),
            icon: CalendarIcon
        },
        completed: {
            style: isEditable ? 'border-green-800 text-green-800 hover:bg-green-800 hover:text-white' : 'border-green-800 text-green-800',
            title: fulfillmentType === DELIVERY ? t("app.fulfillment.d_status_completed") : t("app.fulfillment.p_status_completed"),
            icon: BoxIcon
        }
    };

    const getStatus = () => {
        let list = [];

        if(status === DRAFT){
            list = [NEGOTIATION, CONFIRMED, COMPLETED]
        }

        if (status === NEGOTIATION){
            list = [CONFIRMED, COMPLETED]
        }
        if (status === CONFIRMED){
            list = [COMPLETED]
        }
        return list;
    }

    const IconList = ({status, className}) => {

        let IconType = statusStyles[status].icon;
        return (
            <IconType
                className={className}
                aria-hidden="true"
            />
        )
    }

    return (
        <Menu as="div" className="relative inline-block text-left z-50">
            <div>
                <Menu.Button className={classNames(
                    "btn rounded-0",
                    statusStyles[status].style,
                )}
                >

                    {
                        isLoading ? (
                            <div className="mr-6">
                                <Loader />
                            </div>
                        ) : (
                            <IconList
                                status={status}
                                className={classNames(
                                    "w-4 h-4 mr-1 flex-shrink-0"
                                )}
                            />
                        )
                    }



                    {statusStyles[status].title}

                    {
                        isEditable && (
                            <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                        )
                    }
                </Menu.Button>
            </div>

            {
                isEditable && (
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">


                            {
                                getStatus().map((item) => (
                                    <Menu.Item key={item}>
                                        {({ active }) => (
                                            <div
                                                onClick={(() => {
                                                    onChangeStatus(item)
                                                })}
                                                className={
                                                    classNames(
                                                        active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                        statusStyles[item].style,
                                                        'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                                    )}
                                            >
                                                <IconList
                                                    status={item}
                                                    className={classNames(
                                                        'w-5 h-5 mr-2'
                                                    )}
                                                />
                                                {statusStyles[item].title}
                                            </div>
                                        )}
                                    </Menu.Item>
                                ))
                            }




                        </Menu.Items>
                    </Transition>
                )
            }


        </Menu>
    );
};
