import React, {useState} from 'react';
import CustomerCell from "../common/table/customer-cell";
import {getCustomFormat, getFormattedDate} from "../../utils/timeUtils";
import {getWebPathParam} from "../../utils/converter";
import moment from "moment";
import {
    C_CONTAINER_GENERAL_INFORMATION,
    CONTAINER,
    CONTAINERS,
    DOCUMENTS,
    EVENTS,
    LOGISTICS,
    SHIPPING,
    SHIPPING_DETAIL
} from "../../routes/endpoints";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {hasPermission} from "../partials/restricted/permission-wrapper";
import {pg, pt} from "../../constants/permissions";
import {SEA} from "../../constants/config";

function UnconfirmedShipping({data, height}) {

  const { t } = useTranslation();

  const getFiltered = () => {
    if(data.total_rows > 0){
      let list = data.shippings,
          result = list.reduce(function (r, a) {
              let begin = moment(a.departure_date, "YYYY-M-D HH:mm:ss").startOf('week').format("YYYY-M-D HH:mm:ss");
              let end = moment(a.departure_date, "YYYY-M-D HH:mm:ss").endOf('week').format("YYYY-M-D HH:mm:ss");
              let flag = moment(begin, "YYYY-M-D HH:mm:ss").format("D MMMM YYYY")+' - '+moment(end, "YYYY-M-D HH:mm:ss").format("D MMMM YYYY")

              r[flag] = r[flag] || [];
              r[flag].push(a);
              return r;
          }, Object.create(null));
      return result;
    }
    return [];
  }

  return (
    <>
        {
            data.total_rows === 0 ? (
                <div className="text-center p-10">
                    <ShippingIcon
                        className="h-24 w-24 text-indigo-600 m-auto"
                        aria-hidden="true"
                    />
                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                        {t("dashboard.unconfirmed_shipping.not_found_title")}
                    </p>
                    <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                        {t("dashboard.unconfirmed_shipping.not_found_description")}
                    </p>

                </div>
            ) : (
                <nav style={{height: height+'px'}} className="overflow-y-scroll" aria-label="Directory">
                    {Object.keys(getFiltered()).map((date) => (
                        <div key={date} className="relative">
                            <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                <h3>{date.charAt(0).toUpperCase() + date.slice(1)}</h3>
                            </div>
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {getFiltered()[date].map((item) => (
                                    <li key={item.id}>
                                        <Row item={item} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </nav>

            )
        }

    </>
  );
}

const Row = ({ item }) => {
    const { permissions } = useSelector((s) => s.userCompany);
    const { companyType } = useSelector((s) => s.user);
    const [isActive, setActive] = useState(false);
    const { t } = useTranslation();

    let url = '#0';

    const isLinked = hasPermission(
        permissions,
        pg.SHIPPING,
        pt.EDIT,
        item.customer.id,
        companyType,
        true
    );

    const isLinkedContainer = hasPermission(
        permissions,
        pg.CONTAINER,
        pt.EDIT,
        item.customer.id,
        companyType,
        true
    );
    const isLinkedDocument = hasPermission(
        permissions,
        pg.DOCUMENT,
        pt.EDIT,
        item.customer.id,
        companyType,
        true
    );
    const isLinkedEvent = hasPermission(
        permissions,
        pg.EVENT,
        pt.EDIT,
        item.customer.id,
        companyType,
        true
    );



    if(isLinked){
        url = getWebPathParam([LOGISTICS, SHIPPING, item.id, SHIPPING_DETAIL]);
    }else{
        if(isLinkedContainer){
            url = getWebPathParam([LOGISTICS, SHIPPING, item.id, CONTAINERS]);
        }else{
            if(isLinkedDocument){
                url = getWebPathParam([LOGISTICS, SHIPPING, item.id, DOCUMENTS]);
            }else{
                if(isLinkedEvent){
                    url = getWebPathParam([LOGISTICS, SHIPPING, item.id, EVENTS]);
                }
            }
        }
    }


    return (
        <>
            <div className={(isActive ? 'bg-gray-50 ' : '' ) + 'py-3 px-4 flex justify-between text-sm font-medium bg-white hover:bg-gray-50'}>
                <a rel="noreferrer" href={url} target="_blank" >
                    <dt className="max-w-xs">
                        <CustomerCell customer={item.customer} extra={item?.description ?? false} />
                    </dt>
                </a>
                <dd className="flex items-center ">
                    <div className="text-right">


                        <div className="text-sm text-gray-900">
                            {getFormattedDate(item.departure_date)}
                        </div>
                        {
                            item.containers && (
                                <div className="text-xs text-indigo-900 underline cursor-pointer" onClick={() => {setActive(!isActive)}}>
                                    {item.containers.length}{' '}
                                    {
                                        (item.shipping_type && (item.shipping_type.code === SEA)) ? (
                                            <>{t("dashboard.unconfirmed_shipping.flag_containers")}</>
                                        ) : (
                                            <>{t("dashboard.unconfirmed_shipping.flag_trucks")}</>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </dd>
            </div>

            {
                isActive && (
                    <div className="shadow-inner divide-y divide-gray-200">
                        {
                            item.containers.map((data) => (
                                <a key={data.id} rel="noreferrer" href={getWebPathParam(LOGISTICS, [SHIPPING, item.id, CONTAINER,data.id, C_CONTAINER_GENERAL_INFORMATION ])} target="_blank" className="py-3 px-4 hover:bg-gray-100 flex justify-between text-sm font-medium">
                                    <dt className="max-w-xs">
                                        <div className="text-left">
                                            {
                                                (item.shipping_type && (item.shipping_type.code === SEA)) ? (
                                                    <div className="text-sm text-gray-900">
                                                        {data.container_number ? data.container_number : data.container_type.name }
                                                    </div>
                                                ) : (
                                                    <div className="text-sm text-gray-900">
                                                        {data.license_plate ? data.license_plate : data.delivery_type.name }
                                                    </div>
                                                )
                                            }

                                            <div className="text-xs text-gray-500">
                                                {data?.container_note ?? ''}
                                            </div>
                                        </div>
                                    </dt>
                                    <dd className="flex items-center ">
                                        <div className="text-right">
                                            <div className="text-xs text-gray-500">
                                                {data.load_date ? getCustomFormat(data.load_date, 'DD/MM/YYYY HH:mm') : "-"}
                                            </div>
                                        </div>
                                    </dd>
                                </a>
                            ))
                        }
                    </div>
                )
            }

        </>
    )
}

export default UnconfirmedShipping;
