import React, {useEffect, useState} from "react";
import {getPermission, permissionGroup, permissionType, sp} from "../../../constants/permissions";
import FormPopup from "../../../components/common/popup/form-popup";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import {Controller, useForm} from "react-hook-form";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import InputText from "../../../components/common/form/input-text";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import {useTranslation} from "react-i18next";
import InputDate from "../../../components/common/form/input-date";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {putOrder} from "../../../api/order";

export default function EditOrder({ isOpen, setIsOpen, detail, setDetail }) {

  const { t } = useTranslation();
  const { loggedCompany } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm();

  let { id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [exception, setException] = useState(false);

  useEffect(() => {
    if(detail){
      setValue('id', detail.id);
      setValue('order_reference', detail.order_reference);
      setValue('order_date', detail.order_date);
    }
  }, [setValue, detail]);


  const updateOrder = async (data) => {
    if (loggedCompany) {
      setLoading(true);
        putOrder({ company_id: loggedCompany.id, order_id: id, data })
          .then((response) => {
            setLoading(false);
            toast.success(t("app.orders.update_success"))
            setException(false);
            setDetail(response);
            setIsOpen(false);
          })
          .catch((e) => {
            setException(e.message);
            setLoading(false);
          });
    }
  };

  const onSubmit = async (data) => {
     await updateOrder(data);
  };


  return (
      <BasePermissionWrapper
          requireExporter={true}
          requiredPermissions={[getPermission(permissionGroup.ORDER, permissionType.CREATE)]}
      >
        <FormPopup
            title={t("app.orders.order")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
          <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">



                  <InputText
                      id={"order_reference"}
                      type={"text"}
                      errors={errors.order_reference}
                      input={{ ...register("order_reference", { required: true }) }}
                      label={t("app.orders.order_reference")}
                  />

                  <Controller
                      name="order_date"
                      rules={{ required: true }}
                      control={control}
                      render={({
                                 field: { onChange, value, name },
                                 fieldState: { error },
                               }) => (
                          <InputDate
                              label={t("app.orders.order_date")}
                              format={"YYYY-MM-D HH:mm:ss"}
                              placeholder={"Es. 01/12/2023"}
                              activeMinDate={false}
                              startDate={value}
                              disabled={false}
                              isShowTime={false}
                              errors={error}
                              name={name}
                              onChange={(e) => {
                                onChange(e);
                              }}
                          />
                      )}
                  />




                </div>
              </div>

              {/* Warning */}
              {exception && <Warning message={exception} />}

              <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.form.save")}
                />
              </div>
            </form>
          </SubscriptionWrapper>
        </FormPopup>
      </BasePermissionWrapper>
  );
}
