import React  from "react";
import { useForm } from "react-hook-form";
import FormPopup from "../../../../../../../components/common/popup/form-popup";
import { useEffect } from "react";
import OrderIcon from "../../../../../../../resources/icons/OrderIcon";
import CatalogIcon from "../../../../../../../resources/icons/CatalogIcon";
import InputRadioSquare from "../../../../../../../components/common/form/input-radio-square";
import FromOrder from "./from-order";
import FromCatalog from "./from-catalog";
import {useTranslation} from "react-i18next";
import {CloudUploadIcon} from "@heroicons/react/outline";
import {
    permissionGroup,
    permissionSpecific,
    permissionType,
    sp
} from "../../../../../../../constants/permissions";
import SubscriptionWrapper from "../../../../../../../components/partials/restricted/subscription-wrapper";
import {
    hasPermissionFor
} from "../../../../../../../components/partials/restricted/base-permission-wrapper";
import {useSelector} from "react-redux";

export default function AddProduct(
    {
        isOpen,
        setIsOpen,
        onOpenUpload,
        containerDetail,
        setDetail,
    }) {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const { t } = useTranslation();


    const { permissions } = useSelector((s) => s.userCompany);

    const requireProductList =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.LIST);
    const requireOrderList =  hasPermissionFor(permissions, permissionGroup.ORDER, permissionType.LIST);
    const requireImportProduct =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_IMPORT_PRODUCT);


    useEffect(() => {
        setValue("insert_type", '')
    }, [setValue]);

    return (
        <FormPopup
            title={t("app.container.add_product")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >


            {
                watch('insert_type') === ''  ? (
                    <div className="grid grid-cols-3 gap-6 mb-6">

                        <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={false}>
                            {
                                (requireProductList && requireOrderList) && (
                                    <InputRadioSquare
                                        name={"insert_type"}
                                        errors={errors.insert_type}
                                        input={{ ...register("insert_type", { required: false }) }}
                                        label={t("app.container.from_order")}
                                        value={"order"}
                                        description={t("app.container.from_order_description")}
                                        icon={<OrderIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                    />
                                )
                            }
                        </SubscriptionWrapper>


                            <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={false}>
                                {
                                    requireProductList && (
                                        <InputRadioSquare
                                            name={"insert_type"}
                                            errors={errors.insert_type}
                                            input={{ ...register("insert_type", { required: false }) }}
                                            value={"product"}
                                            label={t("app.container.from_catalog")}
                                            description={t("app.container.from_catalog_description")}
                                            icon={<CatalogIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                        />
                                    )
                                }
                            </SubscriptionWrapper>


                        {
                            (requireImportProduct && requireProductList) && (
                                <InputRadioSquare
                                    name={"insert_type"}
                                    errors={errors.insert_type}
                                    input={{ ...register("insert_type", { required: false }) }}
                                    value={"import"}
                                    onCheck={() => {
                                        setIsOpen(false);
                                        onOpenUpload(true);
                                    }}
                                    label={t("app.container.from_import")}
                                    description={t("app.container.from_import_description")}
                                    icon={<CloudUploadIcon className="inline-flex w-10 h-10 text-indigo-700 mb-2" />}
                                />
                            )
                        }


                    </div>
                ) : (
                    <>
                        {
                            watch('insert_type') === 'order' && (
                                <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={false}>
                                    <FromOrder
                                        containerDetail={containerDetail}
                                        setIsOpen={(r) => {setIsOpen(r)}}
                                        setDetail={(r) => {
                                            setDetail(r);
                                        }}
                                        onBack={() => {setValue('insert_type', '')}} />
                                </SubscriptionWrapper>
                            )
                        }

                        {
                            watch('insert_type') === 'product' && (
                                <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={false}>
                                    <FromCatalog
                                        containerDetail={containerDetail}
                                        setIsOpen={(r) => {setIsOpen(r)}}
                                        setDetail={(r) => {
                                            setDetail(r);
                                        }}
                                        onBack={() => {setValue('insert_type', '')}}
                                    />
                                </SubscriptionWrapper>
                            )
                        }

                    </>
                )
            }

        </FormPopup>
    );
}
