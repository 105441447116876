import React, { Fragment } from 'react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import {useNavigate} from "react-router-dom";
import BasePermissionWrapper from "../partials/restricted/base-permission-wrapper";
import SystemTasks from '../partials/header/system-task';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PageHeaderMenu({ title, navigation, onChangeNavigation = null, isActiveSaveCheck = false, customer = null, taskContext = false, taskContextId = false, subtitle = false, description = false, statusEditor = false, children, onSubtitleClick}) {


    let activeMenu = navigation.find(o => o.current === true);
    let navigate = useNavigate();

    if(!activeMenu){
        return <></>
    }

    return (
        <div className="flex justify-between items-center mb-8 ">

            {/* Left: Title */}

            <div>

                <div className='flex items-center'>

                    {
                        taskContext && (
                            <div className='mr-2'>
                                <SystemTasks taskContext={taskContext} taskContextId={taskContextId} />
                            </div>
                        )   
                    }

                    <div>
                        <h1 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">{title}</h1>
                        {
                            description && (
                                <p className="mt-1 flex items-center text-sm text-gray-500">
                                    {description}
                                </p>
                            )
                        }
                        {
                            subtitle && (
                                <p className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer">
                                    <span onClick={() => { onSubtitleClick() }}>{subtitle}</span>
                                </p>
                            )
                        }
                    </div>
                  
                </div>

                

                
            </div>

            {
                statusEditor && (
                    <div className={"grid grid-flow-col z-10 sm:auto-cols-max justify-start sm:justify-end gap-2 hidden lg:block"}>
                        {children}
                    </div>

                )
            }



            {/* Right: Actions */}
            <div className="grid grid-flow-col z-10 sm:auto-cols-max justify-start sm:justify-end gap-2 lg:hidden">
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="btn border-indigo-800 text-indigo-800 hover:bg-indigo-900 hover:text-white" >
                            <activeMenu.icon className={classNames(
                                activeMenu.extraClass ? activeMenu.extraClass : '',
                                "w-4 h-4 mr-1 flex-shrink-0"
                            )}  aria-hidden="true" />
                            {activeMenu.name}
                            <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                            {navigation.map((item) => (
                                <BasePermissionWrapper key={item.name} requiredPermissions={item?.permission ?? null}>
                                    <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    onClick={() => {
                                                        if (isActiveSaveCheck) {
                                                            onChangeNavigation(item.location)
                                                        } else{
                                                            navigate(item.location)
                                                        }
                                                    }}
                                                    className={`${
                                                        active || item.current ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200'
                                                    } cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            active || item.current
                                                                ? 'text-solitude-200'
                                                                : 'text-blue-1000 group-hover:text-solitude-200',
                                                            'w-5 h-5 mr-2',
                                                            item.extraClass ? item.extraClass : ''
                                                        )}
                                                        aria-hidden="true" />
                                                    {item.name}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </BasePermissionWrapper>
                            ))}



                        </Menu.Items>
                    </Transition>
                </Menu>
                {
                    statusEditor && (
                        <>
                            {children}
                        </>
                    )
                }
            </div>
        </div>
    )
}
