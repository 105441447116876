import React, { useRef, useEffect } from 'react';

import {
  LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip
} from 'chart.js';



import Chart from 'chart.js/auto'

import 'chartjs-adapter-moment';

// Import utilities
import {tailwindConfig, formatValue} from '../../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function RealTimeChart({
  data,
  width,
  height,
  isNumber= false
}) {

  const canvas = useRef(null);
  const chartDeviation = useRef(null);


  useEffect(() => {

    canvas.current.width = width;
    canvas.current.height = height;

  }, [height, width])


  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            border: {
              display: false,
            },
            suggestedMin: Math.min(...data?.datasets[0]?.data ?? [0]),
            suggestedMax: Math.max(...data?.datasets[0]?.data ?? [100]),
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => formatValue(value),
              color: tailwindConfig().theme.colors.gray[800],
            },
            grid: {
              color: tailwindConfig().theme.colors.gray[100],
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD',  // Formato data
              unit: 'day',  // Unità di tempo impostata su 'day'
              tooltipFormat: 'MMM DD, YYYY',  // Formato data nel tooltip
              displayFormats: {
                day: 'MMM DD, YYYY',  // Formato data mostrato nell'asse x
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              color: tailwindConfig().theme.colors.gray[800],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleFont: {
              weight: '600',
            },
            callbacks: {
              label: (context) => formatValue(context.parsed.y),
            },
            titleColor: tailwindConfig().theme.colors.gray[500],
            bodyColor: tailwindConfig().theme.colors.gray[500],
            backgroundColor: tailwindConfig().theme.colors.white,
            borderColor: tailwindConfig().theme.colors.gray[500],
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: false,
        maintainAspectRatio: false,
      },
    });
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <React.Fragment>
      <div className="px-5 py-3">
        <div className="flex items-start">
          <div ref={chartDeviation} className="text-sm font-semibold text-white px-1.5 rounded-full" />
        </div>
      </div>
      <div className="grow">
        <canvas ref={canvas} width={width} height={height} />
      </div>
    </React.Fragment>
  );
}

export default RealTimeChart;
