import React, {  useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import InputText from "./input-text";
import { Warning } from "postcss";
import InputSubmit from "./input-submit";
import { getSelectParam } from "../../../utils/converter";
import CitiesSearch from "./cities-search";
import RegionsSearch from "./regions-search";
import InputSelect from "./input-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../../../reducers/configReducer";
import MunicipalitySearch from "./municipality-search";

const AddressAutocomplete = ({ exception, isLoading, onSubmitData, data }) => {

    const { t } = useTranslation();
    const { isCountriesLoading, countries } = useSelector((state) => state.config);
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        control
    } = useForm();

    useEffect(() => {
        if (data !== null) {
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }else{
                    setValue(k, getSelectParam(data[k], "name"));

                }
            }
        }
    }, [setValue, data]);


    const onSubmit = async (data) => {


        if (data.country) {
            data['country'] = { id: data.country.id };
        }

        if (data.city) {
            data['city'] = { id: data.city.id };
        }

        if (data.region) {
            data['region'] = { id: data.region.id };
        }

        if (data.municipality) {
            data['municipality'] = { id: data.municipality.id };
        }

        onSubmitData(data);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-2">
                <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1 ">


                    <InputText
                        id={'address'}
                        type={'text'}
                        errors={errors.address}
                        input={{ ...register("address", { required: true }) }}
                        label={t("app.connection.address")}
                        isRequired={true}
                    />


                    <MunicipalitySearch
                        label={t("app.common.municipality")}
                        placeholder={t("app.common.municipality")}
                        isRequired={true}
                        selectedItem={watch('municipality') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('municipality', e)
                        }}
                    />

                    <CitiesSearch
                        label={t("app.common.city")}
                        placeholder={t("app.common.city")}
                        isRequired={true}
                        selectedItem={watch('city') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('city', e)
                        }}
                    />

                    <InputText
                        id={'zip_code'}
                        type={'text'}
                        errors={errors.zip_code}
                        input={{ ...register("zip_code", { required: true }) }}
                        label={t("app.connection.zip_code")}
                        isRequired={true}
                    />

                    <RegionsSearch
                        label={t("app.common.region")}
                        placeholder={t("app.common.region")}
                        isRequired={false}
                        selectedItem={watch('region') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('region', e)
                        }}
                    />

                    <Controller
                        name="country"
                        rules={{ required: true }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <InputSelect
                                label={t("app.forwarder.country")}
                                name={name}
                                options={countries}
                                value={value}
                                isRequired={true}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isLoading={isCountriesLoading}
                                isDisabled={isCountriesLoading}
                                errors={error}
                            />
                        )}
                    />



                </div>
            </div>



            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    disabled={!watch('city') && !watch('region')}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
};

export default AddressAutocomplete;
