import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XIcon} from "@heroicons/react/outline/esm";

const SlidePanel = ({isOpen, setIsOpen, title, children, activeOverflow = true, hasDivider= true,footer = null, extras = {}, setClean, bg= "bg-solitude-100", size = 'max-w-md', paddingStyle ='px-4 sm:px-6'}) => {

    const Footer = footer;

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-hidden" onClose={() => {

            }}>
                <div className="absolute inset-0 overflow-hidden">

                    {
                        isOpen && (
                            <>
                                <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />


                                <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <div className={"w-screen " + size}>
                                            <div className={"h-full divide-y divide-gray-200 flex flex-col shadow " + bg}   id={"slideId"}>


                                                <div className={activeOverflow ? "flex-1 h-0 overflow-y-auto" : "flex-1 h-0"}>


                                                    {
                                                        title ? (
                                                            <div className="py-4 px-4 bg-blue-1000 sm:px-6 shadow">
                                                                <div className="flex items-center justify-between">
                                                                    <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
                                                                    <div className="ml-3 h-7 flex items-center">
                                                                        <button
                                                                            type="button"
                                                                            className="bg-blue-1000 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                            onClick={async () => {
                                                                                await setClean();
                                                                                await setIsOpen(false);
                                                                            }}
                                                                        >
                                                                            <span className="sr-only">Close panel</span>
                                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="py-6 px-4  sm:px-6 ">
                                                                <div className="flex before:flex-1 items-center justify-between">
                                                                    <div className="ml-3 h-7 flex items-center">
                                                                        <button
                                                                            type="button"
                                                                            className="text-indigo-700 hover:text-indigo-200 "
                                                                            onClick={async () => {
                                                                                await setClean();
                                                                                await setIsOpen(false);
                                                                            }}
                                                                        >
                                                                            <span className="sr-only">Close panel</span>
                                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }




                                                    <div className="flex-1 flex flex-col justify-between">
                                                        <div className={hasDivider ? 'divide-y divide-gray-200 px-4 sm:px-6' : paddingStyle}>
                                                            {children}
                                                        </div>

                                                    </div>
                                                </div>
                                                {
                                                    footer  && (
                                                        <Footer extras={extras} />
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>

                            </>
                        )
                    }

                </div>
            </Dialog>
        </Transition.Root>
    )
};

export default SlidePanel;
