import { useTranslation } from "react-i18next";
import React from "react";

export default function InputBaseSelect({
  options,
  label,
  isRequired,
  id,
  errors,
  warning,
  warningRegex,
  input,
  defaultValue,
  className = "",
}) {
  const { t } = useTranslation();
  return (
      <div className={className}>
          <label
              htmlFor={id}
              className="block text-sm font-medium mb-1 text-gray-700"
          >
              {label} {isRequired && <span className="text-red-500">*</span>}
          </label>
          <select
              id={id}
              {...input}
              placeholder={t("app.common.placeholder", {
                field: label.toLowerCase(),
              })}
              className={`form-input w-full ${
                  errors
                      ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      : ""
                  }`}
              defaultValue={defaultValue}
          >
            {
              options.map((data, index) => (<option key={data.value} value={data.value}>{data.name}</option>))
            }
          </select>
          {errors && errors.type === "required" && (
            <p className="mt-2 text-sm text-red-600">
              {warning ? warning : t("app.warning.description", { field: label })}
            </p>
          )}
          {errors && errors.type === "validate" && (
            <p className="mt-2 text-sm text-red-600">
              {warning ? warning : t("app.warning.description", { field: label })}
            </p>
          )}
          {errors && errors.type === "pattern" && (
            <p className="mt-2 text-sm text-red-600">
              {warningRegex
                ? warningRegex
                : t("app.warning.description_regex", { field: label })}
            </p>
          )}
    </div>
  );
}
