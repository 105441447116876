import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import InputSelect from "../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import {getPathParam, getSelectParam} from "../../utils/converter";
import {sp} from "../../constants/permissions";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {putQuotation} from "../../api/quotation";
import {
  fetchCurrencies,
  fetchDeliveryTypes,
  fetchShippingTypes,
  fetchTermTypes
} from "../../reducers/configReducer";
import toast from "react-hot-toast";
import InputDate from "../../components/common/form/input-date";
import NumberFormat from "react-number-format";
import InputFormattedNumber from "../../components/common/form/input-formatted-number";
import InputToggle from "../../components/common/form/input-toggle";
import SlidePanel from "../../components/layout/slide-panel";
import InputUserSelect from "../../components/common/form/input-user-select";
import {AcquisitionList} from "../../components/common/alert/acquisition-list";
import ExchangeRateBtn from "../../components/common/form/exchage-rate-btn";
import {getElement} from "../../api/config";
import {EXCHANGE} from "../../api/endpoints";

export default function EditQuotation({ isOpen, setIsOpen, detail, setDetail }) {
  const { loggedCompany } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.userCompany);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
      watch
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCreateLoading, setCreateLoading] = useState(false);
  const [createException, setCreateException] = useState(false);
  const [isLoadingExchange, setLoadingExchange] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(detail.users);
  const [allUsers, setAllUsers] = useState(detail.users);

  const {
    isShippingTypesListLoading,
    shippingTypesList,
    isTermTypesListLoading,
    termTypesList,
    isDeliveryTypesListLoading,
    deliveryTypesList,
    isCurrenciesListLoading,
    currenciesList
  } = useSelector((state) => state.config);


  useEffect(() => {
    dispatch(fetchShippingTypes());
    dispatch(fetchTermTypes());
    dispatch(fetchDeliveryTypes());
    dispatch(fetchCurrencies());

    if(detail){
        setValue('id', detail.id);
        setValue('quotation_reference', detail.quotation_reference);
        setValue('carrier_name', detail.carrier_name);
        setValue('expire_date', detail.expire_date);
        setValue('discount', detail.discount);
        setValue('internal_note', detail.internal_note);
        setValue('delivery_date', detail.delivery_date);
        setValue('last_contact', detail.last_contact);
        setValue('customer_note', detail.customer_note);
        setValue('change_rate', detail.change_rate ?? 0);
        setValue('can_view', detail?.can_view ?? false);
        setValue('shipping_type', detail.shipping_type ? getSelectParam(detail.shipping_type, "name") : null);
        setValue('terms_type', detail.terms_type ? getSelectParam(detail.terms_type, "name") : null);
        setValue('delivery_type', detail.delivery_type ? getSelectParam(detail.delivery_type, "name") : null);
        setValue('currency', detail.currency ? getSelectParam(detail.currency, "code") : null);
        setSelectedUsers(detail.users);
        setAllUsers(detail.users);

    }

  }, [dispatch, setValue, detail]);


    const getExchangeRate = async (from, to) => {
        setLoadingExchange(true);
        try {
            const res = await getElement(getPathParam([EXCHANGE, from, to ]));
            setValue('change_rate', res.rate);
        } catch (e) {

        } finally {
            setLoadingExchange(false);
        }
    }


  const updateQuotation = async (data) => {
    if (loggedCompany) {

        let toAddUsers = [];
        let toRemoveUsers = [];
        let toRemove = allUsers;

        if(selectedUsers.length > 0){
            // eslint-disable-next-line
            selectedUsers.map((user) => {
                toAddUsers.push({user: {id: user.id}})
            })
            data['quotation_users'] = toAddUsers;
            toRemove = (allUsers ?? []).filter(obj1 => !selectedUsers.some(obj2 => obj1.id === obj2.id));

        }

        if(toRemove.length > 0){
            // eslint-disable-next-line
            toRemove.map((user) => {
                toRemoveUsers.push({user: {id: user.id}})
            })
            data['users_to_remove'] = toRemoveUsers;
        }

      setCreateLoading(true);
        putQuotation({ company_id: loggedCompany.id, quotation_id: data.id, data })
        .then((response) => {
          dispatch(subscriptionUsageLimits(loggedCompany.id));
          setCreateLoading(false);
          toast.success(t("app.quotations.update_success"))
          setDetail(response);
          setIsOpen(false);
        })
        .catch((e) => {
          setCreateException(e.message);
          setCreateLoading(false);
        });
    }
  };

  const onSubmit = async (data) => {
        await updateQuotation(data);
  };

  return (
      <SlidePanel
          title={t("app.quotations.edit_quotation")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          bg={'bg-white'}
          size='max-w-3xl'
          setClean={() => {}}
      >
          <SubscriptionWrapper requiredPermission={sp.QUOTATION_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 pb-5 mt-5">
                  <div className="space-y-4">
                      <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                          <Controller
                              name="shipping_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.quotations.shipping_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={shippingTypesList}
                                      value={value}
                                      isRequired={true}
                                      isLoading={isShippingTypesListLoading}
                                      isDisabled={isShippingTypesListLoading}
                                      errors={error}
                                  />
                              )}
                          />
                          <Controller
                              name="expire_date"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.quotations.expire_date")}
                                      format={"YYYY-MM-D HH:mm:ss"}
                                      placeholder={"Es. 01/12/2023"}
                                      activeMinDate={false}
                                      startDate={value}
                                      disabled={false}
                                      isShowTime={false}
                                      isRequired={true}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                  />
                              )}
                          />
                          <InputText
                              id={"carrier_name"}
                              type={"text"}
                              errors={errors.carrier_name}
                              input={{ ...register("carrier_name", { required: false }) }}
                              label={t("app.quotations.carrier_name")}
                          />




                          <Controller
                              name="terms_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.shippings.terms_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={termTypesList}
                                      value={value}
                                      isRequired={true}
                                      isLoading={isTermTypesListLoading}
                                      isDisabled={isTermTypesListLoading}
                                      errors={error}
                                  />
                              )}
                          />

                          <Controller
                              name="delivery_type"
                              rules={{ required: false }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.shippings.delivery_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={deliveryTypesList}
                                      value={value}
                                      isLoading={isDeliveryTypesListLoading}
                                      isDisabled={isDeliveryTypesListLoading}
                                      errors={error}
                                  />
                              )}
                          />

                          <Controller
                              name="currency"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.quotations.currency")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={currenciesList}
                                      value={value}
                                      isLoading={isCurrenciesListLoading}
                                      isDisabled={isCurrenciesListLoading}
                                      errors={error}
                                      isRequired={true}
                                  />
                              )}
                          />

                          {
                              (watch('currency') && (company.currency.code !== watch('currency').code)) && (
                                  <>
                                  <div className="col-span-2">
                                      <label className="block text-sm font-medium mb-1 text-gray-700">
                                          {t("app.quotations.change_rate_title", {currency_1: '1 '+watch('currency').code, currency_2:  '1'+company.currency.code})}
                                      </label>
                                      <Controller
                                          name="change_rate"
                                          rules={{ required: true }}
                                          control={control}
                                          render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                              <ExchangeRateBtn
                                                  label={t("app.quotations.change_rate_label")}
                                                  isShowLabel={false}
                                                  name={name}
                                                  value={value}
                                                  onChange={onChange}
                                                  thousandSeparator={"."}
                                                  decimalSeparator={","}
                                                  suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                  errors={error}
                                                  decimalScale={6}
                                                  isRequired={true}
                                                  isLoading={isLoadingExchange}
                                                  onClick={() => {
                                                      getExchangeRate(watch('currency').code, company.currency.code)
                                                  }}
                                              />
                                          )}
                                      />
                                  </div>
                                  </>
                              )
                          }





                          <Controller
                              name="delivery_date"
                              rules={{ required: false }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.quotations.delivery_date")}
                                      format={"YYYY-MM-D HH:mm:ss"}
                                      placeholder={"Es. 01/12/2021"}
                                      activeMinDate={false}
                                      startDate={value}
                                      disabled={false}
                                      isShowTime={false}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                  />
                              )}
                          />

                          <InputText
                              id={"internal_note"}
                              type={"text"}
                              errors={errors.internal_note}
                              input={{ ...register("internal_note", { required: false }) }}
                              label={t("app.quotations.price_note")}
                          />



                          <div className="col-span-2">
                              <InputUserSelect
                                  label={t("app.users.users")}
                                  placeholder={''}
                                  multiple={true}
                                  includeAll={false}
                                  excludeId={(detail.users && detail.users.length > 0) ? detail.users[0].id : ''}
                                  selectedUsers={selectedUsers}
                                  onSelect={(r) => {
                                      setSelectedUsers(r);
                                  } }
                              />
                          </div>



                          <div>
                              <label className="block text-sm font-medium mb-1 text-gray-700">
                                  {t("app.quotations.acquisition_status")}
                              </label>
                              <AcquisitionList
                                  status={detail?.acquisition_status ?? 'low'}
                                  onChangeStatus={(r) => {
                                        setValue('acquisition_status', r);
                                  }}
                              />
                          </div>



                              <Controller
                                  name="last_contact"
                                  rules={{ required: false }}
                                  control={control}
                                  render={({
                                               field: { onChange, value, name },
                                               fieldState: { error },
                                           }) => (
                                      <InputDate
                                          label={t("app.quotations.last_contact")}
                                          format={"YYYY-MM-D HH:mm:ss"}
                                          placeholder={"Es. 01/12/2023"}
                                          activeMinDate={false}
                                          startDate={value}
                                          disabled={false}
                                          isShowTime={false}
                                          isRequired={false}
                                          errors={error}
                                          name={name}
                                          onChange={(e) => {
                                              onChange(e);
                                          }}
                                      />
                                  )}
                              />


                          <div className="col-span-2">

                              <InputText
                                  id={"customer_note"}
                                  type={"text"}
                                  errors={errors.customer_note}
                                  input={{ ...register("customer_note", { required: false }) }}
                                  label={t("app.quotations.customer_note")}
                              />
                          </div>


                          <div className="col-span-2">
                              <Controller
                                  name="can_view"
                                  rules={{ required: false }}
                                  control={control}
                                  render={({
                                               field: { onChange, value, name },
                                               fieldState: { error },
                                           }) => (
                                      <InputToggle
                                          label={t("app.quotations.can_edit_title")}
                                          description={t("app.quotations.can_edit_description")}
                                          onChange={(e) => {
                                              onChange(e)
                                          }}
                                          defaultChecked={value}
                                          errors={error}
                                      />
                                  )}
                              />
                          </div>


                      </div>
                  </div>


                  <div className="flex before:flex-1  items-center justify-between mt-6">

                      <div className="mt-5 w-full sm:w-1/2">
                          <dl className="divide-y divide-gray-200">

                              <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">{t("app.quotations.net_price")}</dt>
                                  <dd className="flex text-right text-sm text-gray-500">
                                        <span className="flex-grow">
                                            <NumberFormat
                                                value={detail?.totals?.total_price ?? 0}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={3}
                                                suffix={" " + (detail?.currency?.currency_symbol ?? '')}
                                            />
                                        </span>
                                  </dd>
                              </div>

                              <div className="py-3 flex justify-between text-sm font-medium items-center">
                                  <dt className="text-gray-500">{t("app.quotations.discount_label")}</dt>

                                  <dd className="flex text-right  text-sm text-gray-500">
                                        <span className="flex-grow">
                                           <Controller
                                               name="discount"
                                               rules={{ required: true }}
                                               control={control}
                                               render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                                   <InputFormattedNumber
                                                       label={t("app.quotations.discount_label")}
                                                       isShowLabel={false}
                                                       name={name}
                                                       customClass={"text-right form-input "}
                                                       value={value}
                                                       onChange={(e) => {
                                                           let per = e;
                                                           if(e > 100){
                                                               per = 100
                                                           }
                                                           onChange(per);
                                                       }}
                                                       prefix={''}
                                                       suffix={' %'}
                                                       errors={error}
                                                   />
                                               )}
                                           />
                                        </span>
                                  </dd>
                              </div>

                              <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-900">{t("app.quotations.total_price")}</dt>
                                  <dd className="flex text-right text-sm text-gray-900">
                                        <span className="flex-grow">
                                              <NumberFormat
                                                  value={watch('discount') > 0 ? detail.totals.total_price - ((watch('discount') / 100) * (detail.totals.total_price))  : (detail.totals.total_price) }
                                                  displayType={"text"}
                                                  thousandSeparator={"."}
                                                  decimalSeparator={","}
                                                  decimalScale={2}
                                                  suffix={" " + (detail?.currency?.currency_symbol ?? '')}
                                              />
                                        </span>
                                  </dd>
                              </div>

                          </dl>
                      </div>
                  </div>

                  {/* Warning */}
                  {createException && <Warning message={createException} />}

                  <div className="flex before:flex-1 items-center justify-between mt-6">
                      <InputSubmit
                          isLoading={isCreateLoading}
                          label={t("app.quotations.save_quotation")}
                      />
                  </div>
              </form>
          </SubscriptionWrapper>
      </SlidePanel>
  );
}
