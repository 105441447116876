import React, {useEffect, useState} from "react";
import {useParams, useNavigate, useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../../../components/common/form/input-text";
import InputSelect from "../../../../../../components/common/form/input-select";
import Loader from "../../../../../../components/common/loader/loader";
import {Warning} from "../../../../../../components/common/alert/banner";
import {
    SHIPPING,
    CONTAINER,
    CONTAINER_GENERAL_INFORMATION, CONTAINERS,
    LOGISTICS
} from "../../../../../endpoints";
import EditFooter from "../../../../../../components/partials/footer/edit-footer";
import {
    cleanData,
    getPathParam,
    getSelectParam,
    getSelectParams, getShippingTypeParam
} from "../../../../../../utils/converter";
import {putContainer, putContainerImporter} from "../../../../../../api/container";
import InputDate from "../../../../../../components/common/form/input-date";
import {fetchContainerTypes, fetchDeliveryTypes} from "../../../../../../reducers/configReducer";
import UnderlineTab from "../../../../../../components/partials/Tabs/underline-tab";
import InputFormattedNumber from "../../../../../../components/common/form/input-formatted-number";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../../../components/partials/restricted/base-permission-wrapper";
import {
    getPermission,
    permissionGroup,
    permissionSpecific,
    permissionType
} from "../../../../../../constants/permissions";
import PageHeader from "../../../../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import InputEditor from "../../../../../../components/common/form/input-editor";
import {getWarehouses} from "../../../../../../api/config";
import InputToggle from "../../../../../../components/common/form/input-toggle";
import ConfirmPopup from "../../../../../../components/common/popup/confirm-popup";
import {SHIPPING_IMPORTER} from "../../../../../../api/endpoints";
import {LAND} from "../../../../../../constants/config";

const ContainerDetail = () => {

    const { loggedCompany, isExporter } = useSelector((state) => state.user);
    const { containerTypesList, isContainerTypesListLoading,deliveryTypesList, isDeliveryTypesListLoading } = useSelector((state) => state.config);
    const { permissions } = useSelector((s) => s.userCompany);


    const canEdit =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.EDIT);
    const canLoadPermission =  hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionSpecific.CONTAINER_CONFIRM_LOADING);


    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({ mode: 'onBlur' });
    let {id, container_id}  = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { t } = useTranslation();

    const { containerDetail, tabsNavigation, setContainerDetail, isSeaType } = useOutletContext();

    // get container
    const [isLoading] = useState(false);


    // put container
    const [isUpdateLoading, setUpdateLoading] = useState(false);
    const [updateException, setUpdateException] = useState(false);

    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);

    // Confirm Modal
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const updateContainer = async (data) => {
       if(loggedCompany){
           data['id'] = container_id;
           setUpdateLoading(true);
           (isExporter ? putContainer({shipping_id: id, container_id: container_id, data}) :
               putContainerImporter({shipping_id: id, container_id: container_id, data}))
               .then(response => {
                   setContainerDetail(response);
                   setUpdateLoading(false);
                   setUpdateException(false);
                   toast.success(t("app.container.update_success"))
               }).catch(e => {
               setUpdateException(e.message);
               setUpdateLoading(false);
           });
       }
    };

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;
        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [loggedCompany]);


    useEffect( () => {
        if(containerDetail !== null){
            for (const k in containerDetail) {
                if(typeof containerDetail[k] === "object" &&  k !== 'delivery_type'){
                    setValue(k, getSelectParam(containerDetail[k], 'name'));
                }else if(typeof containerDetail[k] === "object" &&  k === 'delivery_type'){
                    setValue('delivery_type', getShippingTypeParam(containerDetail[k]));
                }else{
                    setValue(k, containerDetail[k]);
                }
            }
        }
        dispatch(fetchContainerTypes());
        dispatch(fetchDeliveryTypes());

    }, [dispatch, setValue, containerDetail]);

    const onSubmit = async (data) => {
        cleanData(data);
        await updateContainer(data);
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <PageHeader
                    title={isSeaType ? t("app.container.edit_title") : t("app.container.edit_tuck_title")}
                    taskContext={'shipping_container'}
                    taskContextId={container_id}
                >
                    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.EDIT)]}>
                        {
                            canLoadPermission && (
                                <Controller
                                    name={"loaded"}
                                    rules={{ required: false }}
                                    control={control}
                                    render={({
                                                 field: { onChange, value, name },
                                                 fieldState: { error },
                                             }) => (
                                        <InputToggle
                                            label={t("app.container.confirm_title")}
                                            onChange={(e) => {
                                                onChange(e)
                                                const confirmDetail = {
                                                    title: e ? t("app.container.confirm_title") : t("app.container.unconfirm_title"),
                                                    type: 'loaded',
                                                    message: e ? t("app.container.confirm_description") : t("app.container.unconfirm_description"),
                                                    endpoint: isExporter ? getPathParam([SHIPPING, id, CONTAINER, container_id]) : getPathParam([SHIPPING_IMPORTER, id, CONTAINER, container_id]),
                                                    endpointData: {id: container_id, loaded: e}
                                                };
                                                setConfirmDetail(confirmDetail);
                                                setOpenConfirm(true);

                                            }}
                                            defaultChecked={value}
                                            errors={error}
                                        />
                                    )}
                                />
                            )
                        }
                    </BasePermissionWrapper>

                </PageHeader>
                <UnderlineTab tabs={tabsNavigation} activeGroup={CONTAINER_GENERAL_INFORMATION} setActiveGroup={(r) => {
                    navigate(LOGISTICS + SHIPPING + '/'+ id + CONTAINER +  '/' + container_id + r);
                }} />
            </div>
            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.CONTAINER, permissionType.VIEW)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>
            <div className="max-w-7xl mt-4 mx-auto px-4 sm:px-6 md:px-8">
                <section className={'mt-3'}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="shadow ">
                            <div className="bg-white py-6 px-4 sm:p-6">
                                {
                                    isLoading ? (
                                        <div className="mt-6">
                                            <div className="w-1/2 m-auto px-4 py-3">
                                                <Loader />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-2 gap-6">

                                            {
                                                isSeaType ? (
                                                    <Controller
                                                        name="container_type"
                                                        rules={{ required: true }}
                                                        control={control}
                                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                            <InputSelect
                                                                label={t("app.container.cont_type")}
                                                                name={name}
                                                                options={containerTypesList}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={isContainerTypesListLoading}
                                                                isDisabled={true}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    <Controller
                                                        name="delivery_type"
                                                        rules={{ required: true }}
                                                        control={control}
                                                        render={({
                                                                     field: { onChange, value, name },
                                                                     fieldState: { error },
                                                                 }) => (
                                                            <InputSelect
                                                                label={t("app.shippings.delivery_type")}
                                                                name={name}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                options={deliveryTypesList.filter(r => r.type_code === LAND)}
                                                                value={value}
                                                                isLoading={isDeliveryTypesListLoading}
                                                                isDisabled={isDeliveryTypesListLoading || !canEdit}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                )
                                            }


                                            {
                                                isExporter && (
                                                    <Controller
                                                        name="company_warehouse"
                                                        rules={{ required: false }}
                                                        control={control}
                                                        render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                            <InputSelect
                                                                label={t("app.warehouse.warehouse")}
                                                                name={name}
                                                                options={warehouseList}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                }}
                                                                isLoading={isWarehouseLoading}
                                                                isDisabled={isWarehouseLoading || !canEdit}
                                                                errors={error}
                                                            />
                                                        )}
                                                    />
                                                )
                                            }



                                            <Controller
                                                name="load_date"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputDate
                                                        label={t("app.container.load_date")}
                                                        format={'YYYY-MM-D HH:mm:ss'}
                                                        placeholder={'Es. 01/12/2021'}
                                                        activeMinDate={false}
                                                        startDate={value}
                                                        disabled={!canEdit}
                                                        isShowTime={true}
                                                        errors={error}
                                                        name={name}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />

                                            {
                                                isSeaType ? (
                                                    <>
                                                        <InputText
                                                            id={'container_number'}
                                                            type={'text'}
                                                            errors={errors.container_number}
                                                            input={{...register("container_number", { required: false })}}
                                                            label={t("app.container.container_number")}
                                                            isDisabled={!canEdit}
                                                        />

                                                        <InputText
                                                            id={'seal_number'}
                                                            type={'text'}
                                                            errors={errors.seal_number}
                                                            input={{...register("seal_number", { required: false })}}
                                                            label={t("app.container.seal_number")}
                                                            isDisabled={!canEdit}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <InputText
                                                            id={'license_plate'}
                                                            type={'text'}
                                                            errors={errors.license_plate}
                                                            input={{...register("license_plate", { required: false })}}
                                                            label={t("app.container.license_plate")}
                                                            isDisabled={!canEdit}
                                                        />

                                                        <InputText
                                                            id={'trailer_number'}
                                                            type={'text'}
                                                            errors={errors.trailer_number}
                                                            input={{...register("trailer_number", { required: false })}}
                                                            label={t("app.container.trailer_number")}
                                                            isDisabled={!canEdit}
                                                        />

                                                        <Controller
                                                            name="truck_volume"
                                                            rules={{ required: false }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                <InputFormattedNumber
                                                                    label={t("app.container.truck_volume")}
                                                                    name={name}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' m³'}
                                                                    errors={error}
                                                                    isDisabled={!canEdit}
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                )
                                            }




                                            <Controller
                                                name="box"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputFormattedNumber
                                                        label={t("app.container.packaging_number")}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={''}
                                                        suffix={''}
                                                        errors={error}
                                                        isDisabled={!canEdit}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="volume"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputFormattedNumber
                                                        label={t("app.container.volume")}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={''}
                                                        suffix={' m³'}
                                                        errors={error}
                                                        isDisabled={!canEdit}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="weight"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputFormattedNumber
                                                        label={t("app.container.gross_weight")}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={''}
                                                        suffix={' Kg'}
                                                        errors={error}
                                                        isDisabled={!canEdit}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="net_weight"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                    <InputFormattedNumber
                                                        label={t("app.container.net_weight")}
                                                        name={name}
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        prefix={''}
                                                        suffix={' Kg'}
                                                        errors={error}
                                                        isDisabled={!canEdit}
                                                    />
                                                )}
                                            />

                                            {
                                                isExporter && (
                                                    <>
                                                        <div className={'col-span-2'}>
                                                            <InputText
                                                                id={'container_note'}
                                                                type={'text'}
                                                                errors={errors.seal_number}
                                                                input={{...register("container_note", { required: false })}}
                                                                label={t("app.shippings.Note")}
                                                                isDisabled={!canEdit}
                                                            />
                                                        </div>

                                                        <div className={'col-span-2'}>
                                                            <Controller
                                                                name="load_description"
                                                                rules={{ required: false }}
                                                                control={control}
                                                                render={({field: { onChange, value, name },
                                                                             fieldState: { error },
                                                                         }) => (
                                                                    <InputEditor
                                                                        label={t("app.container.load_information")}
                                                                        errors={error}
                                                                        defaultValue={value}
                                                                        name={name}
                                                                        isDisabled={!canEdit}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }



                                        </div>
                                    )

                                }

                                {/* Warning */}
                                {updateException && (
                                    <Warning
                                        message={updateException}
                                    />
                                )}


                            </div>

                            {
                                !isLoading && (
                                    <EditFooter
                                        backLink={LOGISTICS + SHIPPING + '/'+ id + CONTAINERS}
                                        backLabel={t('app.container.back')}
                                        btnLabel={t('app.form.save')}
                                        isLoading={isUpdateLoading}
                                        isDisabled={!canEdit}
                                    />
                                )
                            }

                        </div>
                    </form>
                </section>
            </div>

                {isOpenConfirm && canEdit && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={ (r, c) => {

                            if(confirmDetail?.type === 'loaded' && !c){
                                setValue("loaded",false);
                            }
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(response) => {
                            setContainerDetail(response);
                            toast.success(t("app.container.update_success"))
                        }}
                    />
                )}



        </BasePermissionWrapper>
        </>
    );
};

export default ContainerDetail;
