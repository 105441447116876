import React, { useEffect, useState } from "react";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    P404,
    C_CONNECTION_NOTES,
    C_CONNECTION_ATTACHMENTS,
    C_CONNECTION_TASKS,
    C_CONNECTION_LOGS,
    REGISTRY,
    SUPPLIER,
    C_CONNECTION_ADDRESSES
} from "../endpoints";
import {getPathParam} from "../../utils/converter";
import {getPermission, permissionGroup, permissionSpecific, permissionType} from "../../constants/permissions";
import {COMPANY} from "../../api/endpoints";
import {fetchCountries, fetchCurrencies, fetchLanguages} from "../../reducers/configReducer";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import {ConnectionHeaderNavigation, ConnectionPanelNavigation} from "../../components/partials/panel/connection-panel-navigation";
import GenericEmailSender from "../../components/partials/email/generic-email-sender";
import {loadCustomers} from "../../reducers/connectionReducer";
import { getSupplier } from "../../api/supplier";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SupplierOverview = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  // get customer
  const [isLoading, setLoading] = useState(true);
  const [connection, setConnection] = useState({});
  const [typeActive, setTypeActive] = useState(null);

  const { permissions } = useSelector((s) => s.userCompany);

  const canEdit =  hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionType.EDIT);
    const canSendMail = hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_SEND_MAIL);


  // update customer
  const [isSidebarOpen, setOpenSidebar] = useState(false);

  // update customer
  const [isOpenEmailSender, setOpenEmailSender] = useState(false);


  const fetchConnection = (signal = null, loading = true) => {
      if (loggedCompany) {
          setLoading(loading);
          getSupplier({ company_id: loggedCompany.id, supplier_id: id, signal })
              .then((response) => {
                  setConnection(response);
                  setLoading(false);
              })
              .catch((e) => {
                  setLoading(false);
                  e.code === 404 && navigate(P404);
              });
      }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchConnection(signal);
    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchLanguages());

    return () => {
      controller.abort();
    };
      // eslint-disable-next-line
  }, [id, loggedCompany, dispatch]);

    const secondaryNavigation = [
        //canViewBi && { name: t("app.leads.overview"), type: 'overview', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_OVERVIEW]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_OVERVIEW])) },
        canEdit && { name: t("app.connection.addresses"), type: 'addresses', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_ADDRESSES]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_ADDRESSES])) },
        canEdit && { name: t("app.leads.notes"), type: 'note', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_NOTES]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_NOTES])) },
        canEdit && { name: t("app.leads.attachments"), type: 'attach', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_ATTACHMENTS]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_ATTACHMENTS])) },
        { name: t("app.menu.boards"), type: 'task', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_TASKS]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_TASKS])) },
        canEdit && { name: t("app.leads.logs"), type: 'log', location: getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_LOGS]), current: pathname.includes(getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_LOGS])) }
    ].filter(Boolean);


    useEffect(() => {
        if(permissions){
            if (!hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_BI) && !hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionType.EDIT)){
                navigate("/" + getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_TASKS]));
            } else if (!hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_BI) && hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionType.EDIT)){
                navigate("/" + getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_NOTES]));
            }else{
                if (hasPermissionFor(permissions, permissionGroup.SUPPLIER, permissionSpecific.SUPPLIER_BI)){
                    navigate("/" + getPathParam([REGISTRY, SUPPLIER, id, C_CONNECTION_ADDRESSES]));
                }
            }
        }
// eslint-disable-next-line
    }, [permissions]);







  return (
      <BasePermissionWrapper
          requiredPermissions={[getPermission(permissionGroup.SUPPLIER, permissionType.VIEW)]}
      >

        <LoaderWrapper isLoading={isLoading}>

          <div className="flex h-full">
            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
              <div className="relative z-0 flex flex-1 overflow-hidden">
                  <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">


                  {/* Start main area*/}
                  <div className="absolute inset-0 ">

                      <ConnectionHeaderNavigation canEdit={canEdit} connection={connection} canSendRequest={true} onOpenSidebar={(r) => {setOpenSidebar(r)} } isSidebarOpen={isSidebarOpen} />


                      <div className="bg-white border-b border-gray-200">
                          <nav className=" flex space-x-8 max-w-7xl mx-auto justify-center overflow-x-auto px-4 leading-6   sm:px-6 lg:px-8" aria-label="Tabs">
                              {secondaryNavigation.map((tab) => (
                                  <Link
                                      key={tab.name}
                                      to={'/'+tab.location}
                                      className={classNames(
                                          tab.current
                                              ? 'border-sky-400 text-blue-1000'
                                              : 'border-transparent text-blue-1000/[0.5] hover:text-blue-1000 hover:border-sky-400',
                                          'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-xs uppercase'
                                      )}
                                  >
                                      {tab.name}
                                  </Link>
                              ))}
                          </nav>
                      </div>

                      <Outlet
                          context={{
                              connectionId: connection.id,
                              entityId: connection.id,
                              overviewData: connection,
                              typeActive: typeActive,
                              canEdit: canEdit,
                              removeActive: () => {
                                  setTypeActive(null);
                              },
                              onUpdateData: (r) => {
                                  setConnection(r);
                              },
                              onRefresh: (r) => {
                                  fetchConnection(null, false);
                              }
                          }}
                      />



                  </div>
                  {/* End main area */}
                </main>


                  <ConnectionPanelNavigation
                      connection={connection}
                      canEdit={canEdit}
                      onOpenSidebar={(r) => {setOpenSidebar(r)} }
                      isSidebarOpen={isSidebarOpen}
                      isSendMail={false}
                      canSendRequest={true}
                      onCreate={(r) => {
                          if(r === 'mail'){
                              setOpenEmailSender(true);
                          }else{
                              setTypeActive(r);
                              navigate("/"+secondaryNavigation.find(n => n.type === r).location);
                          }
                      }}
                      onResponse={(response) => {
                          setConnection(response);
                          dispatch(loadCustomers(loggedCompany.id));
                      }}
                      detailGroups={[
                          {
                              endpoint: getPathParam([COMPANY, loggedCompany?.id ?? '', SUPPLIER, connection?.id ?? '' ]),
                              isCreate: false,
                              refId: connection?.id ?? '' ,
                              defaultOpen: true,
                              title: t("app.customers.customer_info"),
                              fields: [
                                  {field: 'business_name', translation: 'app.customers.business_name',   value: connection?.business_name ?? '-',  viewType: 'string', formType: 'string', required: false},
                                  {field: 'exporter_reference', translation: 'app.customers.reference',   value: connection?.exporter_reference ?? '-',  viewType: 'string', formType: 'string', required: false},
                                  //{field: 'exporter_email', translation: 'app.customers.importer_email', value: connection?.exporter_email ?? '-', viewType: 'string', formType: 'string', required: false}
                              ]
                          }
                      ]}
                  />
              </div>
            </div>
          </div>

            {
                canSendMail && (
                    <GenericEmailSender
                        isOpen={isOpenEmailSender}
                        baseTitle={t("app.leads.lead")}
                        entityType={'connection'}
                        entityNote={'lead'}
                        entityId={connection?.id ?? ''}
                        baseEmail={[{
                            id: 1,
                            full_name: connection?.business_name ?? '',
                            email: connection?.exporter_email ?? ''
                        }]}
                        baseAttachments={connection?.last_connection_attachment ?? []}
                        setIsOpen={(r) => {
                            setOpenEmailSender(r);
                        }}
                    />
                )
            }



        </LoaderWrapper>

      </BasePermissionWrapper>
  );
};

export default SupplierOverview;
