import React, {useEffect, useState} from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import InputSelect from "../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import {getPathParam, getSelectParam, getSelectParams, getWebPathParam} from "../../utils/converter";
import { useNavigate } from "react-router-dom";
import {sp} from "../../constants/permissions";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {cloneQuotation} from "../../api/quotation";
import {SALES, QUOTATION_OVERVIEW, COMPANY} from "../endpoints";
import {CLONE, QUOTATION} from "../../api/endpoints";
import {
  fetchCurrencies,
  fetchDeliveryTypes,
  fetchShippingTypes,
  fetchTermTypes
} from "../../reducers/configReducer";
import InputDate from "../../components/common/form/input-date";
import { fetchConnections } from "../../reducers/connectionReducer";
import { getElement } from "../../api/config";

export default function CloneQuotation({ isOpen, setIsOpen, detail }) {
  const { loggedCompany } = useSelector((state) => state.user);
    const { connections, isLoadingConnections } = useSelector((state) => state.connection);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
      setValue
  } = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateLoading, setCreateLoading] = useState(false);
  const [createException, setCreateException] = useState(false);

  const [customer, setCustomer] = useState(false);


// Counter details
  const [second, setSecond] = useState(1);
  const [startCounter, setStartCounter] = useState(false);
  const [newQuotationId, setQuotationId] = useState(false);


  const {
    isShippingTypesListLoading,
    shippingTypesList,
    isTermTypesListLoading,
    termTypesList,
    isDeliveryTypesListLoading,
    deliveryTypesList,
    isCurrenciesListLoading,
    currenciesList
  } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(fetchShippingTypes());
    dispatch(fetchTermTypes());
    dispatch(fetchDeliveryTypes());
    dispatch(fetchCurrencies());
    dispatch(fetchConnections({ company_id: loggedCompany.id, params: { find_all: true } }));

      if(detail){
          setValue('customer', getSelectParam(detail.customer, "business_name"));
          setValue('carrier_name', detail.carrier_name);
          setValue('shipping_type', detail.shipping_type ? getSelectParam(detail.shipping_type, "name") : null);
          setValue('terms_type', detail.terms_type ? getSelectParam(detail.terms_type, "name") : null);
          setValue('delivery_type', detail.delivery_type ? getSelectParam(detail.delivery_type, "name") : null);
          setValue('currency', detail.currency ? getSelectParam(detail.currency, "code") : null);
      }

  }, [dispatch, setValue, detail, loggedCompany]);


    useEffect(() => {
        let c = (connections ?? []).find(r => r.id === customer.id);
        if(c){
            setValue('currency', c.importer_currency ? getSelectParam(c.importer_currency, "code") : null);
        }
        // eslint-disable-next-line
    }, [customer, setValue]);


    const storeQuotation = async (data) => {
    if (loggedCompany) {
      delete data.customer.value;
      delete data.customer.label;
      setCreateLoading(true);
      cloneQuotation({ company_id: loggedCompany.id, quotation_id: detail.id,  data })
        .then((response) => {
            setQuotationId(response.id);
            setStartCounter(true);
        })
        .catch((e) => {
          setCreateException(e.message);
          setCreateLoading(false);
        });
    }
  };

    useEffect(() => {
        const interval = setInterval(async () => {
            if (startCounter) {
                setSecond(second + 1);
                if (second === 1) {



                    if (loggedCompany && newQuotationId) {
                        try {

                            const asyncData = await getElement(getPathParam([COMPANY, loggedCompany.id, QUOTATION, detail.id, CLONE, newQuotationId ]));
                            console.log(asyncData);

                            if (asyncData.status === 'error') {
                                setCreateException(asyncData?.error ?? '');
                                setCreateLoading(false);
                                setStartCounter(false);
                            }
                            if (asyncData.status === 'done') {
                                dispatch(subscriptionUsageLimits(loggedCompany.id));
                                setCreateLoading(false);
                                navigate(getWebPathParam([SALES, QUOTATION, newQuotationId, QUOTATION_OVERVIEW]));
                                setStartCounter(false);
                            }

                            if (asyncData.status === 'in-progress') {
                                setSecond(0);
                            }
                            
                         
                        } catch (e) {
                            setCreateException(e.message);
                            setCreateLoading(false);
                            setStartCounter(false);
                        } finally {
                            setSecond(0);
                        }
                    }
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });





  const onSubmit = async (data) => {
    await storeQuotation(data);
  };

  return (
      <FormPopup
          title={t("app.quotations.clone_title")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >
          <SubscriptionWrapper requiredPermission={sp.QUOTATION_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-4">
                      <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                          <Controller
                              name="customer"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.orders.company")}
                                      name={name}
                                      options={getSelectParams(connections, "business_name")}
                                      value={value}
                                      onChange={(r) => {
                                          setCustomer(r);
                                          onChange(r);
                                      }}
                                      isLoading={isLoadingConnections}
                                      isDisabled={isLoadingConnections}
                                      isRequired={true}
                                      errors={error}
                                  />

                              )}
                          />


                          <Controller
                              name="expire_date"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.quotations.expire_date")}
                                      format={"YYYY-MM-D HH:mm:ss"}
                                      placeholder={"Es. 01/12/2023"}
                                      activeMinDate={false}
                                      startDate={value}
                                      disabled={false}
                                      isShowTime={false}
                                      isRequired={true}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                  />
                              )}
                          />



                          <Controller
                              name="shipping_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.quotations.shipping_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={shippingTypesList}
                                      value={value}
                                      isLoading={isShippingTypesListLoading}
                                      isDisabled={isShippingTypesListLoading}
                                      isRequired={true}
                                      errors={error}
                                  />
                              )}
                          />



                          <InputText
                              id={"carrier_name"}
                              type={"text"}
                              errors={errors.carrier_name}
                              input={{ ...register("carrier_name", { required: false }) }}
                              label={t("app.quotations.carrier_name")}
                          />




                          <Controller
                              name="terms_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.shippings.terms_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={termTypesList}
                                      value={value}
                                      isLoading={isTermTypesListLoading}
                                      isDisabled={isTermTypesListLoading}
                                      isRequired={true}
                                      errors={error}
                                  />
                              )}
                          />

                          <Controller
                              name="delivery_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.shippings.delivery_type")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={deliveryTypesList}
                                      value={value}
                                      isLoading={isDeliveryTypesListLoading}
                                      isDisabled={isDeliveryTypesListLoading}
                                      errors={error}
                                  />
                              )}
                          />

                          <Controller
                              name="delivery_date"
                              rules={{ required: false }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.quotations.delivery_date")}
                                      format={"YYYY-MM-D HH:mm:ss"}
                                      placeholder={"Es. 01/12/2021"}
                                      activeMinDate={false}
                                      startDate={value}
                                      disabled={false}
                                      isShowTime={false}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                  />
                              )}
                          />




                          <Controller
                              name="currency"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.quotations.currency")}
                                      name={name}
                                      onChange={(e) => {
                                          onChange(e);
                                      }}
                                      options={currenciesList}
                                      value={value}
                                      isLoading={isCurrenciesListLoading}
                                      isDisabled={isCurrenciesListLoading}
                                      errors={error}
                                      isRequired={true}
                                  />
                              )}
                          />


                      </div>
                  </div>

                  {/* Warning */}
                  {createException && <Warning message={createException} />}

                  <div className="flex before:flex-1 items-center justify-between mt-6">
                      <InputSubmit
                          isLoading={isCreateLoading}
                          label={t("app.common.clone")}
                      />
                  </div>
              </form>
          </SubscriptionWrapper>
      </FormPopup>
  );
}
