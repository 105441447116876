import React, { useState } from "react";
import UserIcon from "../../resources/icons/UserIcon";
import {COMPANY, CONFIGURATION, CONNECTION_CATEGORIES as connCategories, USERS, COMPANY_PERMISSION, REGISTRY,
} from "../endpoints";
import {useParams, Link} from "react-router-dom";
import { useEffect } from "react";
import {
  getCompanyUserConnection,
   putCompanyUserConnection,
} from "../../api/user-connection";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import {getPathParam, getSelectParam, getSelectParams, getWebPathParam} from "../../utils/converter";
import {getElement} from "../../api/config";
import {CONNECTION_CATEGORIES, PERMISSION_GROUPS} from "../../api/endpoints";
import userBg from "../../resources/images/user-bg.svg";
import {Controller, useForm} from "react-hook-form";
import {StatusBar} from "../../components/common/alert/status-bar";
import {BriefcaseIcon, PaperAirplaneIcon} from "@heroicons/react/outline";
import InputText from "../../components/common/form/input-text";
import InputSelect from "../../components/common/form/input-select";
import InputTextBtn from "../../components/common/form/input-text-btn";
import {emailRegex} from "../../constants/regex";
import InputToggle from "../../components/common/form/input-toggle";
import UnderlineTab from "../../components/partials/Tabs/underline-tab";
import EditFooter from "../../components/partials/footer/edit-footer";
import {Warning} from "../../components/common/alert/banner";
import toast from "react-hot-toast";
import Initials from "../../components/common/avatar/initials";

const UserDetail = () => {
  const { loggedCompany } = useSelector((s) => s.user);
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control
  } = useForm({ mode: "onBlur" });

  //Update
  const [isEditLoading, setEditLoading] = useState(false);
  const [exception, setException] = useState(false);

  //detail
  const [detail, setDetail] = useState(true);
  const [isLoading, setLoading] = useState(true);

  //Categories
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setLoadingCategories] = useState(true);

  //Permissions Groups
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isLoadingPermissionGroup, setLoadingPermissionGroup] = useState(true);

  const [isSendingMail, setSendingMail] = useState(false);




  const [activeGroup, setActiveGroup] = useState("user_information");

  const tabs = [
    { name: t("app.users.user_information"), group: "user_information" }
  ];



  useEffect(() => {

    function fetchDetail() {
      if (loggedCompany) {
        setLoading(true);
        getCompanyUserConnection({company_id: loggedCompany.id, connection_id: id,})
            .then((response) => {
              setDetail(response);
              if(response.connection_category){
                setValue("connection_category", getSelectParam(response.connection_category, "name"));
              }
              if(response.company_user_group){
                setValue("company_user_group", getSelectParam(response.company_user_group, "group_name"));
              }
              if (response.notification_email) {
                setValue("notification_email", response.notification_email);
                setValue("fullname", response.fullname);
                setValue("connect_all", response.connect_all);
              }
              setLoading(false);
            })
            .catch((e) => {
              setLoading(false);
            });
      }
    }

    function fetchCategories() {
      if (loggedCompany) {
        setLoadingCategories(true);
        getElement(getPathParam([COMPANY, loggedCompany.id, CONNECTION_CATEGORIES])).then((response) => {
          setCategories(getSelectParams(response.categories, "name"));
          setLoadingCategories(false);
        }).catch((e) => {
          setLoadingCategories(false);
        });
      }
    }



    function fetchPermissions() {
      if (loggedCompany) {
        setLoadingPermissionGroup(true);
        getElement(getPathParam([COMPANY, loggedCompany.id, PERMISSION_GROUPS])).then((response) => {
          setPermissionGroups(getSelectParams(response.user_groups, "group_name"));
          setLoadingPermissionGroup(false);
        }).catch((e) => {
          setLoadingPermissionGroup(false);
        });
      }
    }

    fetchDetail();
    fetchCategories();
    fetchPermissions();
    // eslint-disable-next-line
  }, [loggedCompany, id, setValue]);


  let user_status = {
    status: detail?.status === "confirmed" ? "success" : "processing",
    title: detail?.status === "confirmed" ? t("app.users.confirmed_connection") : t("app.users.waiting_connection"),
  };

  if(!detail.is_active){
    user_status = {
      status: "blocked",
      title: t("app.users.blocked_connection"),
    };
  }


  const updateUser = async (data) => {
    if (loggedCompany) {
      data["id"] = id;
      delete  data["resend_mail"];
      setEditLoading(true);
      putCompanyUserConnection({
        company_id: loggedCompany.id,
        connection_id: id,
        data: data,
      }).then((response) => {
            toast.success(t("app.users.update_success"));
            setDetail(response);
            setEditLoading(false);
      }).catch((e) => {
            setException(e.message);
            setEditLoading(false);
      });
    }
  };


  const sendMail = async () => {
    if (loggedCompany) {
      setSendingMail(true);
      putCompanyUserConnection({
        company_id: loggedCompany.id,
        connection_id: id,
        data: { id: id, notification_email: watch("notification_email"), resend_mail: true },
      }).then((response) => {
            toast.success(t("app.users.send_success"));
            setDetail(response);
            setSendingMail(false);
      }).catch((e) => {
            setException(e.message);
            setSendingMail(false);
      });
    }
  };

  const onSubmit = async (data) => {
    updateUser(data);

  };


  return (
    <BasePermissionWrapper
        requiredPermissions={[getPermission(permissionGroup.USER, permissionType.EDIT)]}
        renderBaseOnFail={true}
        adminHasAccess={false}
    >
      <LoaderWrapper isLoading={isLoading}>

        <div className="mb-4">
          <div>
            <img className="h-32 w-full object-cover lg:h-48 " src={userBg} alt="" />
            {/*<div className="h-32 w-full bg-blue-1000 lg:h-48 " /> */}
          </div>
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div className="flex">
                {
                  detail.picture ? (
                      <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover" src={detail.picture.reference} alt="" />
                  ) : (
                      <Initials
                          color={detail?.color ?? ''}
                          name={detail?.fullname?? ''}
                          iconClass={' h-24 w-24 sm:h-32 sm:w-32 ring-4 ring-white '}
                          verifiedIcon={' h-8 w-8'}
                          fontSize={'text-2xl'}
                          isVerified={detail?.status === 'confirmed' ?? false}
                      />
                  )
                }
              </div>
              <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">



                  <h1 className="truncate text-2xl font-bold text-blue-1000 capitalize">{detail.fullname}</h1>
                  <p className="mt-1 flex items-center text-sm text-blue-1000">
                    {
                      detail.connection_type === 'agent' ? (
                          <BriefcaseIcon className="h-4 w-4" aria-hidden="true" />
                      ) : (
                          <UserIcon className="h-4 w-4" aria-hidden="true" />
                      )
                    } <span className="ml-2">{detail.connection_type === 'agent' ? t("app.users.connection_agent") : t("app.users.connection_employee")}</span>
                  </p>

                </div>
                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <StatusBar
                      status={user_status.status}
                      title={user_status.title}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
              <h1 className="truncate text-2xl font-bold text-gray-900">{detail.fullname}</h1>
            </div>
          </div>
        </div>






        <div className="max-w-5xl mx-auto px-4 sm:px-6 md:px-8 mt-8">

          <UnderlineTab
              marginTop={''}
              tabs={tabs}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
          />


          <form onSubmit={handleSubmit(onSubmit)} className="shadow  sm:overflow-hidden">

          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">


              <div className={detail.is_active ? '' : 'col-span-2'}>
                <InputText
                    id={"fullname"}
                    type={"text"}
                    label={t("app.users.fullname")}
                    errors={errors.fullname}
                    input={{
                      ...register("fullname", {
                        required: false,
                      }),
                    }}
                    placeholder={t("app.users.fullname")}
                />
              </div>


              {
                  detail.is_active && (
                      <div>
                        <Controller
                            name="connection_category"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                       field: { onChange, value, name },
                                       fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.connection_category.name")}
                                    name={name}
                                    options={categories}
                                    value={value}
                                    onChange={onChange}
                                    isLoading={isLoadingCategories}
                                    isDisabled={isLoadingCategories}
                                    isClearable={true}
                                    errors={error}
                                    isMulti={false}
                                />
                            )}
                        />
                        <div className={"flex justify-between before:flex-1"}>
                          <Link to={getWebPathParam([COMPANY, CONFIGURATION, connCategories])} className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer">
                            {t("app.connection_category.add_name")}
                          </Link>
                        </div>

                      </div>
                  )
              }




              <div className={(detail.is_active && !watch("connect_all")) ? '' : 'col-span-2'}>
                <label className="block text-sm font-medium mb-1 text-gray-700">
                  {t("app.form.email")}
                </label>

                <div className="relative">
                  <InputTextBtn
                      id={"notification_email"}
                      labelTitle={t("app.form.email")}
                      type={"text"}
                      errors={errors.notification_email}
                      input={{
                        ...register("notification_email", {
                          required: false,
                          pattern: emailRegex
                        }),
                      }}
                      isDisabled={detail.status === "confirmed"}
                      hideBtn={detail.status === "confirmed"}
                      label={t("app.form.email")}
                      isActive={watch("notification_email")?.length  > 0 ?? false}
                      isLoading={isSendingMail}
                      onClick={() => {
                        if (watch('notification_email').match(emailRegex)) {
                          sendMail();
                        }
                      }}
                      icon={PaperAirplaneIcon}
                  />
                </div>
              </div>



              {
                  (detail.is_active && !watch("connect_all")) && (
                      <div>
                        <Controller
                            name="company_user_group"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                       field: { onChange, value, name },
                                       fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.permissions.group_title")}
                                    name={name}
                                    options={permissionGroups}
                                    value={value}
                                    onChange={onChange}
                                    isLoading={isLoadingPermissionGroup}
                                    isDisabled={isLoadingPermissionGroup}
                                    isClearable={true}
                                    errors={error}
                                    isMulti={false}
                                />
                            )}
                        />
                        <div className={"flex justify-between before:flex-1"}>
                          <Link to={getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PERMISSION])} className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer">
                            {t("app.permissions.add_name")}
                          </Link>
                        </div>

                      </div>
                  )
              }









              {(detail.connection_type === "employee" && detail.is_active) && (
                  <div className="col-span-2 mt-3">
                    <Controller
                        name="connect_all"
                        rules={{ required: false }}
                        control={control}
                        render={({
                                   field: { onChange, value, name },
                                   fieldState: { error },
                                 }) => (
                            <InputToggle
                                label={t("app.users.connect_all_title")}
                                description={t("app.users.connect_all_description")}
                                onChange={(e) => {
                                  onChange(e)
                                }}
                                defaultChecked={value}
                                errors={error}
                            />
                        )}
                    />

                  </div>
              )}




            </div>


            {/* Warning */}
            {exception && <Warning message={exception} />}
          </div>


            {!isLoading &&
                <EditFooter
                    backLink={REGISTRY + USERS}
                    backLabel={t("app.users.back")}
                    btnLabel={t("app.form.save")}
                    isLoading={isEditLoading}
                />}
          </form>


        </div>

      </LoaderWrapper>

    </BasePermissionWrapper>
  );
};

export default UserDetail;
