import React  from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import {login} from "../../reducers/userReducer";

import HeaderProfiling from "../../components/layout/header-profiling";
import {useForm} from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import {emailRegex} from "../../constants/regex";
import {Warning} from "../../components/common/alert/banner";
import InputSubmit from "../../components/common/form/input-submit";
import {useTranslation} from "react-i18next";

import {INDEX, REGISTER, RESET_PASSWORD} from "../endpoints";
import SidebarProfiling from "../../components/layout/sidebar-profiling";

const Login = () => {
  const { isAuthenticated, isLoginLoading, loginException } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const from = location.state?.from?.pathname || INDEX;

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    dispatch(login(data));
  };


  if(isAuthenticated){
    return (<Navigate to={from} />);
  }

  return(
      <main className="bg-white">

        <div className="relative md:flex">

          {/* Content */}
          <div className="md:w-1/2">
            <div className="min-h-screen h-full flex flex-col after:flex-1">

              {/* Header */}
              <HeaderProfiling />


              <div className="max-w-lg mx-auto px-4 py-8 w-3/4">
                <h1 className="text-3xl text-gray-800 font-bold mb-6">{t("oauth.login.title")}</h1>
                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="space-y-4">

                    <InputText
                        id={'email'}
                        type={'email'}
                        errors={errors.username}
                        input={{...register("username", { required: true, pattern: emailRegex })}}
                        label={t("app.form.email")}
                    />

                    <InputText
                        id={'password'}
                        type={'Password'}
                        errors={errors.password}
                        input={{...register("password", { required: true })}}
                        label={t("app.form.password")}
                    />

                  </div>

                  {/* Warning */}
                  {loginException && (
                      <Warning
                          color={'red'}
                          message={loginException}
                      />
                  )}

                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">
                      <Link className="text-sm underline hover:no-underline" to={RESET_PASSWORD}>{t("oauth.forgot_password.question")}</Link>
                    </div>
                    <InputSubmit
                        isLoading={isLoginLoading}
                        label={t("oauth.login.name")}
                    />
                  </div>
                </form>

                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-gray-200">
                  <div className="text-sm">
                    {t("oauth.register.question")} <Link className="font-medium text-indigo-500 hover:text-indigo-600" to={REGISTER}>{t("oauth.register.name")}</Link>
                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* Image */}
          <SidebarProfiling />

        </div>

      </main>
  );
};

export default Login;
