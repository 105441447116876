import PageHeader from "../../../components/layout/page-header";
import React, {useState} from "react";
import EditOrder from "./edit-order";
import {useTranslation} from "react-i18next";
import Initials from "../../../components/common/avatar/initials";
import NumberFormat from "react-number-format";
import {getCustomFormat} from "../../../utils/timeUtils";
import SubscriptionWrapper, {
    hasSubscriptionPermission
} from "../../../components/partials/restricted/subscription-wrapper";
import {permissionGroup, permissionType, sp} from "../../../constants/permissions";
import {useSelector} from "react-redux";
import {hasPermission} from "../../../components/partials/restricted/base-permission-wrapper";

const OrderHeader = ({detail, setDetail}) => {

    const { t } = useTranslation();
    const [isEdit, setEdit] = useState(false);

    const { companyType } = useSelector((state) => state.user );


    const { permissions, subscriptions } = useSelector((s) => s.userCompany);
    const isSubActive = hasSubscriptionPermission(subscriptions, sp.ORDER_INTERNAL_PACKAGE);
    const canEditOrder =  hasPermission(permissions, permissionGroup.ORDER, permissionType.EDIT, companyType, true);

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <PageHeader
                    isActiveEdit={isSubActive && canEditOrder}
                    onClickEdit={() => {
                        setEdit(true);
                    }}
                    title={t("app.orders.orders") + (detail ? ' #'+detail?.order_reference ?? '' : '') }>

                </PageHeader>
            </div>


            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-5">
                <div className="flex flex-col col-span-full bg-white shadow-sm rounded-sm">
                    <div className="px-5 py-6">

                        <div className="md:flex md:justify-between md:items-center">
                            <div className="flex items-center mb-4 md:mb-0">
                                <div className="flex items-center mb-4 md:mb-0">
                                    <div className="mr-4">
                                        <Initials
                                            color={detail?.customer?.color ?? ''}
                                            name={detail?.customer?.business_name?? ''}
                                            iconClass={' h-16 w-16'}
                                            verifiedIcon={' h-6 w-6'}
                                            isVerified={detail?.customer?.status === 'confirmed' ?? false}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-left">
                                            <div className="text-md font-semibold text-gray-900">
                                                {detail?.customer?.business_name ?? ''}
                                            </div>

                                            <div className="text-2xl font-bold text-indigo-800">
                                                <NumberFormat
                                                    value={detail?.total_order ?? 0}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    suffix={" €"}
                                                />
                                            </div>
                                            <div className="text-xs font-semibold text-gray-500">
                                                {getCustomFormat(detail?.order_date ?? null, 'DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {
                canEditOrder && (
                    <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={false} redirectOnFail={false}>
                        {isEdit && (
                            <EditOrder
                                isOpen={isEdit}
                                setIsOpen={() => {
                                    setEdit(false);
                                }}
                                detail={detail}
                                setDetail={(r) => {
                                    setDetail(r);
                                }}
                            />
                        )}
                    </SubscriptionWrapper>
                )
            }


        </>



    )
};
export default OrderHeader;
