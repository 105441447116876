import { useTranslation } from "react-i18next";
import React from "react";
import Select, { components } from "react-select";
import { SelectorIcon } from "@heroicons/react/solid";
import Initials from "../avatar/initials";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="field__icon">
        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </components.DropdownIndicator>
  );
};

const indicatorSeparatorStyle = {
  display: "none",
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);


const Option = props => {

  return (
      <div className="items-center flex justify-between">
        <components.Option  {...props}>
          <div  className={`flex items-center`}>
            <Initials
              color={'red'}
              name={props?.data?.full_name ?? props.data.email}
              isVerified={false}
            />
            <div className="text-left">
            <p className={`text-sm font-medium ${props.isSelected ? 'text-white' : 'text-gray-900'}`}>{props.data.full_name}</p>
              <p className={`truncate text-sm ${props.isSelected ? 'text-white' : 'text-gray-500'}`}>{props.data.email}</p>
            </div>
          </div>
        </components.Option>
      </div>
  );
};


const EmailSelect = ({
  label,
  isShowLabel  = true,
  isRequired,
  name,
  options = [],
  defaultValue = "",
  value,
  onChange,
  isLoading,
  isDisabled,
  errors,
  warning,
  warningRegex,
  isMulti,
  isClearable = false
}) => {
  const { t } = useTranslation();


  return (
    <div>
      {
        isShowLabel && (
              <label
                  htmlFor={name}
                  className="block text-sm font-medium mb-1 text-gray-700"
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
          )
      }
      <Select
        classNamePrefix="react-select"
        className={`react-select-container ${
          errors ? "react-select-container-error" : ""
        }`}
        components={{ IndicatorSeparator, DropdownIndicator, Option }}
        formatGroupLabel={formatGroupLabel}
        isLoading={isLoading}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onChange={(selectedOption) => {
          onChange(selectedOption);
        }}
        isClearable={isClearable}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        name={name}
        options={options}
        placeholder={t("app.common.placeholder", {
          field: label.toLowerCase(),
        })}
        defaultValue={defaultValue}
        value={value}
      />
      {errors && errors.type === "required" && (
        <p className="mt-2 text-sm text-red-600">
          {warning ? warning : t("app.warning.description", { field: label })}
        </p>
      )}
      {errors && errors.type === "validate" && (
        <p className="mt-2 text-sm text-red-600">
          {warning ? warning : t("app.warning.description", { field: label })}
        </p>
      )}
      {errors && errors.type === "pattern" && (
        <p className="mt-2 text-sm text-red-600">
          {warningRegex
            ? warningRegex
            : t("app.warning.description_regex", { field: label })}
        </p>
      )}
    </div>
  );
};

EmailSelect.defaultProps = {
  isMulti: false,
};

export default EmailSelect;
