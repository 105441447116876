import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./resources/style/index.css";
import Main from "./routes/main";
import { store } from "./reducers/rootReducer";

import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import "./i18n";
import Loading from "./components/layout/loading";
import {injectStore} from "./utils/apiUtils";
injectStore(store);

if(process.env.REACT_APP_ENV === 'prod'){
    Sentry.init({
        dsn: 'https://1f48df8fff7f4d158e21458fc543e1a2@o1078816.ingest.sentry.io/6376394',
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Main />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
