import {COMPANY, PALLET, PALLETS} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";

// products
export const getPallets = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, PALLETS] ) + serialize(params), {signal: signal});

export const getPallet = ({company_id, pallet_id, signal}) =>
    API.get(getPathParam([COMPANY, company_id, PALLET, pallet_id ] ), {signal: signal});

export const postPallet = ({company_id, data}) =>
    API.post(getPathParam([COMPANY, company_id, PALLET]), data);

export const putPallet = ({company_id, pallet_id, data}) =>
    API.put(getPathParam([COMPANY, company_id, PALLET, pallet_id]), data);

export const deletePallet = ({company_id, pallet_id}) =>
    API.delete(getPathParam([COMPANY, company_id, PALLET, pallet_id]));
