import React from "react";
import {useNavigate} from "react-router-dom";
import BasePermissionWrapper from "../restricted/base-permission-wrapper";
import AnimatedPing from "../../common/progress/animated-ping";
import Initials from "../../common/avatar/initials";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PanelNavigation = ({ navigation, onChangeNavigation = null, isActiveSaveCheck = false, isProfileView = false, customer = null, title = false, isCenterTitle = false, notificationStatus = false}) => {

    let navigate = useNavigate();

    return (
        <>
            <nav>
                {
                    isProfileView && (
                        <div className="flex items-center mb-4 md:mb-0 py-2 px-6">
                            <div className="mr-4">
                                <Initials
                                    color={customer?.color ?? ''}
                                    name={customer?.business_name?? ''}
                                    iconClass={' h-16 w-16'}
                                    verifiedIcon={' h-6 w-6'}
                                    isVerified={customer?.status === 'confirmed' ?? false}
                                />
                            </div>
                            <div>
                                <div className="text-left flex items-center">
                                    <div>
                                        <div className="text-md font-semibold text-gray-900">
                                            {customer?.business_name ?? ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


                {
                    title && (
                        <div className={"flex  py-4 flex-shrink-0 items-center border-b border-blue-gray-200 px-6 "+ (isCenterTitle ? 'justify-center' : '')}>
                            <p className={"text-lg text-blue-1000 " + (isCenterTitle ? 'font-semibold text-center' : 'font-medium')}>{title}</p>
                        </div>
                    )
                }


                <div className="min-h-0 flex-1 overflow-y-auto">
                    {navigation.map((item) => (
                        <BasePermissionWrapper
                            key={item.name}
                            requiredPermissions={item?.permission ?? null}
                            adminHasAccess={item?.hasAdminAccess ?? true}
                        >
                            <div
                                key={item.name}
                                onClick={() => {
                                    if (isActiveSaveCheck) {
                                        onChangeNavigation(item.location)
                                    } else {
                                        navigate(item.location)
                                    }
                                }}
                                className={classNames(
                                    item.current ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                                    'cursor-pointer flex p-6 border-b border-blue-gray-200 relative'
                                )}
                            >


                                {
                                    item.notification_status && notificationStatus && (
                                        <AnimatedPing color={notificationStatus.error_color} extraClass={'mt-1 -ml-2'} />
                                    )
                                }

                                <item.icon className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-1000" aria-hidden="true" />
                                <div className="ml-3 text-sm">
                                    <p className="font-medium text-blue-1000">{item.name}</p>
                                    {
                                        item.description && (
                                            <p className="mt-1 text-gray-500">{item?.description ?? ''}</p>
                                        )
                                    }
                                </div>
                            </div>
                        </BasePermissionWrapper>
                    ))}
                </div>
            </nav>

        </>

    );
};

export default PanelNavigation;
