import React from 'react';
import {useTranslation} from "react-i18next";
import {getWebPathParam} from "../../utils/converter";
import {CONTAINER, C_CONTAINER_GENERAL_INFORMATION, LOGISTICS, SHIPPING} from "../../routes/endpoints";
import {getCustomFormat} from "../../utils/timeUtils";
import moment from "moment";
import ContainerIcon from "../../resources/icons/ContainerIcon";
import Initials from "../common/avatar/initials";
import {SEA} from "../../constants/config";


function CalendarLoad({data, height}) {

  const { t } = useTranslation();

    const getFiltered = () => {
        if(data.containers.length > 0){
            let list = data.containers,
                result = list.reduce(function (r, a) {



                    let begin = moment(a.load_date, "YYYY-M-D HH:mm:ss").startOf('week').format("YYYY-M-D HH:mm:ss");
                    let end = moment(a.load_date, "YYYY-M-D HH:mm:ss").endOf('week').format("YYYY-M-D HH:mm:ss");
                    let flag = moment(begin, "YYYY-M-D HH:mm:ss").format("D MMMM YYYY")+' - '+moment(end, "YYYY-M-D HH:mm:ss").format("D MMMM YYYY")

                    r[flag] = r[flag] || [];
                    r[flag].push(a);
                    return r;
                }, Object.create(null));
            return result;
        }
        return [];
    }

  return (
    <>

        {data.containers.length === 0 ? (
            <div className="text-center p-10">
                <ContainerIcon
                    className="h-24 w-24 text-indigo-600 m-auto"
                    aria-hidden="true"
                />
                <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                    {t("dashboard.calendar_load_dates.not_found_title")}
                </p>
                <p className="max-w-xl mt-2 mb-5 mx-auto text-md text-gray-500">
                    {t("dashboard.calendar_load_dates.not_found_description")}
                </p>

            </div>
        ) : (
            <nav style={{height: height+'px'}} className="overflow-y-scroll" >
                {Object.keys(getFiltered()).map((date) => (
                    <div key={date} className="relative">
                        <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                            <h3>{date.charAt(0).toUpperCase() + date.slice(1)}</h3>
                        </div>
                        <ul className="relative z-0 divide-y divide-gray-200">
                            {getFiltered()[date].map((item) => (
                                <li key={item.id} className="bg-white hover:bg-gray-50">
                                    <a  rel="noreferrer" href={getWebPathParam([LOGISTICS, SHIPPING, item.shipping.id, CONTAINER,item.id, C_CONTAINER_GENERAL_INFORMATION ])} target="_blank" className="py-3 px-4 flex justify-between text-sm font-medium">
                                        <dt className="max-w-xs">


                                            <div className="flex items-center">
                                                <Initials
                                                    color={item.customer.color}
                                                    name={item.customer.business_name}
                                                    isVerified={item.customer?.status === 'confirmed' ?? false}
                                                />
                                                <div className="text-left">
                                                    {
                                                        item.shipping.tracking && (
                                                            <div className="text-xs text-gray-700">
                                                                {t("app.shippings.tracking")}:  {item.shipping.tracking}
                                                            </div>
                                                        )
                                                    }
                                                    <div className="text-sm whitespace-pre-line text-gray-900">
                                                        {item.customer.business_name}
                                                    </div>
                                                    {
                                                        item.container_note && (
                                                            <div className="text-xs whitespace-pre-line text-gray-500">
                                                                {item.container_note}
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        </dt>
                                        <dd className="flex items-center ">


                                            {
                                                item.shipping_type === SEA ? (
                                                    <div className="text-right">
                                                        {
                                                            item.container_number && (
                                                                <div className="text-xs text-gray-700">
                                                                    {item.container_number}
                                                                </div>
                                                            )
                                                        }
                                                        <div className="text-sm text-gray-900">
                                                            {item.container_type.name }
                                                        </div>
                                                        <div className="text-xs text-gray-500">
                                                            {item.load_date ? getCustomFormat(item.load_date, 'DD/MM/YYYY HH:mm') : "-"}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="text-right">
                                                        {
                                                            item.license_plate && (
                                                                <div className="text-xs text-gray-700">
                                                                    {item.license_plate}
                                                                </div>
                                                            )
                                                        }
                                                        <div className="text-sm text-gray-900">
                                                            {item.delivery_type.name }
                                                        </div>
                                                        <div className="text-xs text-gray-500">
                                                            {item.load_date ? getCustomFormat(item.load_date, 'DD/MM/YYYY HH:mm') : "-"}
                                                        </div>
                                                    </div>
                                                )
                                            }



                                        </dd>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </nav>
        )
        }
    </>
  );
}

export default CalendarLoad;
