import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

export default function BoardActionsMenu({ onClose = null, children, btnClass = '', extraClass = 'right-0 ', classExtra = 'w-56', baseClass='inline-block', icon, title = '' }) {


  return (
    <div className={baseClass}>
      <Popover className="relative inline-block">
        {({ open }) => (
          <>
            <Popover.Button>
              <div className={btnClass}>
                {icon}
                {title ? <>{title}</> : <></>}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={(extraClass) + "origin-top-right z-10 absolute text-left mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none " + (classExtra)}>
                {({ close }) => {

                  if (onClose){
                    close();
                  }


                  return (
                    <div className="  shadow-md">
                      <div className="relative rounded-md bg-white p-4">
                        {children}
                      </div>
                    </div>
                  )  
                }}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
