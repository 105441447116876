import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getOrder } from "../../api/order";
import {ORDERS, P404, SALES} from "../endpoints";
import {getPermission, permissionGroup, permissionType} from "../../constants/permissions";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import OrderHeader from "./overview/order-header";
import OrderProducts from "./overview/order-products";
import BasePermissionWrapper from "../../components/partials/restricted/base-permission-wrapper";
import {getWebPathParam} from "../../utils/converter";

const OrderOverview = () => {
  const navigate = useNavigate();

  const { loggedCompany, companyType } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let { id } = useParams();

  // get Order
  const [isOrderDetailLoading, setOrderDetailLoading] = useState(true);
  const [detail, setDetail] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(null);

  useEffect(() => {
    if(detail && detail.connection_type !== companyType){
      navigate(getWebPathParam([SALES, ORDERS]))
    }
    // eslint-disable-next-line
  }, [companyType]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchOrder() {
      if (loggedCompany) {

        if(needRefresh === null){
          setOrderDetailLoading(true);
        }

        getOrder({
          company_id: loggedCompany.id,
          order_id: id,
          signal,
          params: { company_type: companyType },
        })
          .then((response) => {
            setDetail(response);
            setOrderDetailLoading(false);
          })
          .catch((e) => {
            e.code === 404 && navigate(P404);
            setOrderDetailLoading(false);
          });
      }
    }

    fetchOrder();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, loggedCompany, id, companyType, needRefresh]);


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.ORDER, permissionType.VIEW)]} renderBaseOnFail={true}>
      <LoaderWrapper isLoading={isOrderDetailLoading}>
        <div className="py-6">

          {/* Page Header */}
          <OrderHeader detail={detail} setDetail={setDetail} />

          {/* container */}
          <OrderProducts
              detail={detail}
              setNeedRefresh={(r) => {setNeedRefresh(needRefresh === null ? r : !needRefresh)}}
          />


        </div>
      </LoaderWrapper>

    </BasePermissionWrapper>
  );
};


export default OrderOverview;
