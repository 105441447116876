import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const ListFooter = ({ backLink, backLabel }) => {
  return (
    <div className=" py-3 bg-solitude-100 text-right  flex">
      <Link
        to={backLink}
        className="text-sm underline inline-flex  items-center"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
        {backLabel}
      </Link>
    </div>
  );
};

export default ListFooter;
