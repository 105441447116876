import React, {useEffect, useState} from "react";
import InputSubmit from "../../components/common/form/input-submit";
import InputSelect from "../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../components/common/alert/banner";
import {ORDER, QUOTATION_OVERVIEW, SALES} from "../endpoints";
import { getSelectParams, getWebPathParam } from "../../utils/converter";
import { useNavigate } from "react-router-dom";
import { postOrder } from "../../api/order";
import {sp} from "../../constants/permissions";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {fetchCustomers} from "../../reducers/connectionReducer";
import InputText from "../../components/common/form/input-text";
import InputDate from "../../components/common/form/input-date";
import {fetchCurrencies} from "../../reducers/configReducer";

export default function AddOrder({ isOpen, setIsOpen }) {
  const { loggedCompany } = useSelector((state) => state.user);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm();

   const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateOrderLoading, setCreateOrderLoading] = useState(false);
  const [createOrderException, setCreateOrderException] = useState(false);

    const {
        isCurrenciesListLoading,
        currenciesList
    } = useSelector((state) => state.config);


    const {customers, isLoadingCustomers} = useSelector((state) => state.connection);

  const storeOrder = async (data) => {
    if (loggedCompany) {
      delete data.customer.value;
      delete data.customer.label;

      /*if(data.order_reference === ''){
        delete data.order_reference;
      }*/

      //console.log(data);
      setCreateOrderLoading(true);
      postOrder({ company_id: loggedCompany.id, data })
        .then((response) => {
          //console.log(response);
          dispatch(subscriptionUsageLimits(loggedCompany.id));
          setCreateOrderLoading(false);
          navigate(getWebPathParam([SALES, ORDER, response.id, QUOTATION_OVERVIEW]));
        })
        .catch((e) => {
          setCreateOrderException(e.message);
          setCreateOrderLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(fetchCustomers({company_id: loggedCompany.id}));
    dispatch(fetchCurrencies());
  }, [dispatch, loggedCompany]);


  const onSubmit = async (data) => {
    await storeOrder(data);
  };

  return (
      <FormPopup
          title={t("app.orders.new_order")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >
        <SubscriptionWrapper requiredPermission={sp.ORDER_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                  <Controller
                      name="customer"
                      rules={{ required: true }}
                      control={control}
                      render={({
                                 field: { onChange, value, name },
                                 fieldState: { error },
                               }) => (
                          <InputSelect
                              label={t("app.orders.company")}
                              name={name}
                              options={getSelectParams(customers, "business_name")}
                              value={value}
                              onChange={onChange}
                              isLoading={isLoadingCustomers}
                              isDisabled={isLoadingCustomers}
                              errors={error}
                          />
                      )}
                  />


                  <InputText
                      id={"order_reference"}
                      type={"text"}
                      errors={errors.order_reference}
                      input={{ ...register("order_reference", { required: false }) }}
                      label={t("app.orders.order_reference")}
                  />


                  <Controller
                      name="order_date"
                      rules={{ required: true }}
                      control={control}
                      render={({
                                   field: { onChange, value, name },
                                   fieldState: { error },
                               }) => (
                          <InputDate
                              label={t("app.orders.order_date")}
                              format={"YYYY-MM-D HH:mm:ss"}
                              placeholder={"Es. 01/12/2023"}
                              activeMinDate={false}
                              startDate={value}
                              disabled={false}
                              isShowTime={false}
                              isRequired={true}
                              errors={error}
                              name={name}
                              onChange={(e) => {
                                  onChange(e);
                              }}
                          />
                      )}
                  />

                  <Controller
                      name="currency"
                      rules={{ required: true }}
                      control={control}
                      render={({
                                   field: { onChange, value, name },
                                   fieldState: { error },
                               }) => (
                          <InputSelect
                              label={t("app.quotations.currency")}
                              name={name}
                              onChange={(e) => {
                                  onChange(e);
                              }}
                              options={currenciesList}
                              value={value}
                              isLoading={isCurrenciesListLoading}
                              isDisabled={isCurrenciesListLoading}
                              errors={error}
                              isRequired={false}
                          />
                      )}
                  />


              </div>
            </div>

            {/* Warning */}
            {createOrderException && <Warning message={createOrderException} />}

            <div className="flex before:flex-1 items-center justify-between mt-6">
              <InputSubmit
                  isLoading={isCreateOrderLoading}
                  label={t("app.orders.add_order")}
              />
            </div>
          </form>
        </SubscriptionWrapper>
      </FormPopup>
  );
}
