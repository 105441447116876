import {
    SHIPPING,
    CONTAINERS,
    CONTAINER,
    MEDIA,
    PRODUCTS,
    PRODUCT,
    PACKING,
    GROUPS,
    GROUP,
    GENERATE_PACKING_LIST, SUB_GROUP, SHIPPING_IMPORTER, GENERATE_PACKING_LIST_SIMULATION, ARCHIVE,
} from "./endpoints";
import API from '../utils/apiUtils';
import {getPathParam, serialize} from "../utils/converter";


// container
export const getContainers = ({shipping_id, params, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, CONTAINERS] ) + serialize(params), {signal: signal});

export const getContainer = ({shipping_id, container_id, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id ] ), signal && {signal: signal});

// Container Exporter
export const postContainer = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER]), data);

export const putContainer = ({shipping_id, container_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id]), data);

export const putContainerGalleryArchive = ({shipping_id, container_id}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, ARCHIVE]), {});


export const deleteContainer = ({shipping_id, container_id}) =>
    API.delete(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id]));

export const deleteContainerImage = ({shipping_id, container_id, media_id}) =>
    API.delete(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, MEDIA, media_id ]));

export const putGeneratePackingList = ({shipping_id, container_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GENERATE_PACKING_LIST]), data);

export const putGeneratePackingListSimulation = ({shipping_id, container_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GENERATE_PACKING_LIST_SIMULATION]), data);


// Container Importer
export const postContainerImporter = ({shipping_id, data}) =>
    API.post(getPathParam([SHIPPING_IMPORTER, shipping_id, CONTAINER]), data);

export const putContainerImporter = ({shipping_id, container_id, data}) =>
    API.put(getPathParam([SHIPPING_IMPORTER, shipping_id, CONTAINER, container_id]), data);

export const deleteContainerImageImporter = ({shipping_id, container_id, media_id}) =>
    API.delete(getPathParam([SHIPPING_IMPORTER, shipping_id, CONTAINER, container_id, MEDIA, media_id ]));



// Load Configuration
export const getContainerProducts = ({shipping_id, container_id, params, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, PRODUCTS] ) + serialize(params), signal && {signal: signal});

export const putContainerProduct = ({shipping_id, container_id, container_product_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, PRODUCT, container_product_id]), data);

export const postContainerProducts = ({shipping_id, container_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, PRODUCTS]), data);

export const deleteContainerProducts = ({shipping_id, container_id, params}) =>
    API.delete(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, PRODUCTS]) + serialize(params));


// Groups
export const getContainerGroups = ({shipping_id, container_id, params, signal}) =>
    API.get(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUPS] ) + serialize(params), signal ? {signal: signal} : {} );

export const postContainerGroup = ({shipping_id, container_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP]), data);

export const putContainerGroup = ({shipping_id, container_id, group_id,  data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id]), data);

export const putContainerGroups = ({shipping_id, container_id,  data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUPS]), data);


// Sub Groups
export const postContainerSubGroup = ({shipping_id, container_id, group_id,  data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id, SUB_GROUP]), data);

export const putContainerSubGroup = ({shipping_id, container_id, group_id, sub_group_id,  data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id, SUB_GROUP, sub_group_id]), data);

// Load
export const putContainerPacking = ({shipping_id, container_id, group_id, packing_id, sub_group_id, data}) =>
    API.put(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id, SUB_GROUP, sub_group_id, PACKING, packing_id]), data);

export const postContainerSubPacking = ({shipping_id, container_id, group_id, sub_group_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id, SUB_GROUP, sub_group_id ,PACKING]), data);

export const postContainerPacking = ({shipping_id, container_id, group_id, data}) =>
    API.post(getPathParam([SHIPPING, shipping_id, CONTAINER, container_id, GROUP, group_id, PACKING]), data);
