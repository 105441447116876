import {
  GET_COUNTRIES,
  GET_PHONE_PREFIXES,
  GET_CURRENCIES,
  GET_PORTS,
  GET_DELIVERY_TYPES,
  GET_TERM_TYPES,
  GET_SHIPPING_LINES,
  GET_DOCUMENT_TYPES,
  GET_EVENT_TYPES,
  GET_CONTAINER_TYPES,
  GET_SIGNED_REQUESTS,
  NOTIFICATION_UNREAD,
  GET_PERMISSIONS,
  GET_PACKAGING_TYPES,
  GET_NOTIFICATION_TYPES,
  COMPANY,
  WAREHOUSES,
  GET_EMAIL_PROVIDERS,
  GET_VESSELS,
  GET_SHIPPING_TYPES, GET_LANGUAGES, GET_CITIES, GET_UNITS, GET_WEBHOOK_EVENTS, GET_PERMISSION_SPECIFIC,
  GET_REGIONS,
  GET_MUNICIPALITY,
} from "./endpoints";
import API, { getApi, setApi } from "../utils/apiUtils";
import {getPathParam, serialize} from "../utils/converter";

// CONFIG
export const getCountries = async () => {
  let data = await getApi(GET_COUNTRIES);
  if (!data) {
    data = await API.get(GET_COUNTRIES);
    await setApi(GET_COUNTRIES, data);
  }
  return data;
};


export const getUnits = async () => {
  let data = false;//await getApi(GET_UNITS);
  if (!data) {
    data = await API.get(GET_UNITS);
    await setApi(GET_UNITS, data);
  }
  return data;
};

export const getWebhookEvents = async () => {
  let data = false;//await getApi(GET_UNITS);
  if (!data) {
    data = await API.get(GET_WEBHOOK_EVENTS);
    await setApi(GET_WEBHOOK_EVENTS, data);
  }
  return data;
};


export const getCities = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_CITIES] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});


export const getRegions = ({ params = false, signal = false }) =>
  API.get(getPathParam([GET_REGIONS]) + (params ? serialize(params) : ''), signal ? { signal: signal } : {});

export const getMunicipalities = ({ params = false, signal = false }) =>
  API.get(getPathParam([GET_MUNICIPALITY]) + (params ? serialize(params) : ''), signal ? { signal: signal } : {});


export const getLanguages = async () => {
  let data = await getApi(GET_LANGUAGES);
  if (!data) {
    data = await API.get(GET_LANGUAGES);
    await setApi(GET_LANGUAGES, data);
  }
  return data;
};


export const getPhonePrefixes = async () => {
  let data = await getApi(GET_PHONE_PREFIXES);
  if (!data) {
    data = await API.get(GET_PHONE_PREFIXES);
    await setApi(GET_PHONE_PREFIXES, data);
  }
  return data;
};

export const getCurrencies = async () => {
  let data = await getApi(GET_CURRENCIES);
  if (!data) {
    data = await API.get(GET_CURRENCIES);
    await setApi(GET_CURRENCIES, data);
  }
  return data;
};

export const getPorts = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_PORTS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const getPortData = ({params, signal = false}) =>
    API.get(getPathParam([GET_PORTS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});

export const getVessels = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_VESSELS] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});


export const getDeliveryTypes = async () => {
  return await API.get(GET_DELIVERY_TYPES);
};


export const getShippingTypes = async () => {
  let data = await getApi(GET_SHIPPING_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_SHIPPING_TYPES);
    await setApi(GET_SHIPPING_TYPES, data);
  }
  return data;
};

export const getTermTypes = async () => {
  return await API.get(GET_TERM_TYPES);
};

export const getShippingLines = async () => {
  let data = false; // await getApi(GET_SHIPPING_LINES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_SHIPPING_LINES);
    await setApi(GET_SHIPPING_LINES, data);
  }
  return data;
};

export const getShippingLinesData = ({params = false, signal = false}) =>
    API.get(getPathParam([GET_SHIPPING_LINES] ) + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});


export const getDocumentTypes = async () => {
  let data = await getApi(GET_DOCUMENT_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_DOCUMENT_TYPES);
    await setApi(GET_DOCUMENT_TYPES, data);
  }
  return data;
};

export const getEventTypes = async () => {
  return await API.get(GET_EVENT_TYPES);
};

export const getContainerTypes = async () => {
  return await API.get(GET_CONTAINER_TYPES);
};


export const getPackagingTypes = async () => {
  let data = await getApi(GET_PACKAGING_TYPES);
  if (!data || (data.length === 0)) {
    data = await API.get(GET_PACKAGING_TYPES);
    if(data.length > 0){
      await setApi(GET_PACKAGING_TYPES, data);
    }
  }
  return data;
};

export const getNotificationTypes = async () => {
  return await API.get(GET_NOTIFICATION_TYPES);
};


export const getWarehouses = ({company_id, params, signal}) =>
    API.get(getPathParam([COMPANY, company_id, WAREHOUSES] ) + (params ? serialize(params)  : ''), {signal: signal});

export const getEmailProvider = ({signal}) =>
    API.get(getPathParam([GET_EMAIL_PROVIDERS] ), {signal: signal});

export const getPermissionTypes = async () => API.get(GET_PERMISSIONS);
export const getPermissionSpecific = async () => API.get(GET_PERMISSION_SPECIFIC);

export const getNotificationCounter = async () => API.get(NOTIFICATION_UNREAD);

export const getSignedRequests = ({ params }) =>
  API.post(GET_SIGNED_REQUESTS + (params ? serialize(params) : ""));

export const deleteElement = (endpoint) => API.delete(endpoint);
export const putElement = (endpoint, data = false) => API.put(endpoint, data ? data : {});
export const postElement = (endpoint, data = false) => API.post(endpoint, data ? data : {});
export const getElement = (endpoint, signal = null, params = null) => API.get(endpoint + (params ? serialize(params)  : ''), signal ? {signal: signal} : {});
export const getElementBody = (endpoint, signal = null, params = null, data = false) => API.post(endpoint + (params ? serialize(params)  : ''), data ? data : {},signal ? {signal: signal} : {});
