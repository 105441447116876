import React from "react";
import {
    ACCOUNT, BILLING_INFORMATION, PROFILE_CONNECTION,
    PROFILE_SETTINGS,
    USER_SUBSCRIPTION
} from "../../endpoints";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PageHeader from "../../../components/layout/page-header";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";


const SettingEdit = () => {

    const location = useLocation();
    const { pathname } = location;
    const { t } = useTranslation();
    const { detail, loggedCompany } = useSelector((state) => state.user );
    const { permissions } = useSelector((s) => s.userCompany);

    let navigate = useNavigate();



    const navigationLine = [
        { name: t("app.settings.account"), group: ACCOUNT + PROFILE_SETTINGS, current: pathname.includes(ACCOUNT + PROFILE_SETTINGS) },
        (!permissions.isSuperAdmin && loggedCompany) && { name: t("app.connection.connection"), group: ACCOUNT + PROFILE_CONNECTION, current: pathname.includes(ACCOUNT + PROFILE_CONNECTION) },
        (permissions.isSuperAdmin) && { name: t("app.company.billing_information"), group: ACCOUNT + BILLING_INFORMATION, current: pathname.includes(ACCOUNT + BILLING_INFORMATION) },
        (permissions.isSuperAdmin && detail && detail.last_subscription) && { name: t("app.settings.plan_e_usage"), group: ACCOUNT + USER_SUBSCRIPTION, current: pathname.includes(ACCOUNT + USER_SUBSCRIPTION) }
    ].filter(Boolean);

    return (

        detail && (
            <>
                <div className="py-6">

                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                        <PageHeader
                            title={navigationLine.find(r => r.current === true)?.name ?? ''}
                        >
                        </PageHeader>
                        <UnderlineTab tabs={navigationLine} activeGroup={navigationLine.find(r => r.current === true)?.group ?? ''} setActiveGroup={(r) => {
                            navigate(r);
                        }} />
                    </div>

                    <div className="max-w-7xl mt-4 mx-auto px-4 sm:px-6 md:px-8">
                        <Outlet context={{}}/>
                    </div>
                </div>

                {/*

                <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <PageHeaderMenu
                        title={navigation.find(r => r.current === true)?.name ?? ''}
                        navigation={navigation}
                    />
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <aside className="hidden lg:block lg:py-0 lg:px-0 lg:col-span-3">
                            <PanelList  navigation={navigation} />
                        </aside>

                        <div className="space-y-6 mt-5 lg:mt-0 lg:px-0 lg:col-span-9">
                            <Outlet context={{}}/>
                        </div>
                    </div>

                </div>
            </div>

                */}

            </>









        )


    );
};

export default SettingEdit;
