import React from "react";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const SimpleButton = ({extraClass = '', icon, onClick}) => {

    const Icon = icon;

    return (
        <button
            className={classNames(
                'mr-2 shadow-none',
            )}
            onClick={onClick}>
            <Icon
                className={classNames(extraClass, "h-4 w-4" )}
                aria-hidden="true"
            />
        </button>
    )
};
export default SimpleButton;
