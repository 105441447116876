import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const SubscriptionRestricted = () => {

  const { permissions } = useSelector((s) => s.userCompany);

  const { t } = useTranslation();

  return (
    <div className="py-4 h-full flex flex-col before:flex-1 after:flex-1">
      <div className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-16 bg-white overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                {t("app.plan.restricted_feature_title")}
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                {t("app.plan.restricted_feature_description")}
              </p>
            </div>
            {
              permissions?.isSuperAdmin && (
                  <div className="mt-8 flex justify-center">
                    <div className="ml-3 inline-flex">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn bg-blue-1000 hover:bg-blue-900 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                        href={'https://www.dokless.com/try-dokless'}
                      >
                       Contact Us
                      </a>
                    </div>
                  </div>
                )
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRestricted;
