import React, {useState} from 'react'
import {Warning} from "../alert/banner";
import InputText from "../form/input-text";
import InputSubmit from "../form/input-submit";
import FormPopup from "./form-popup";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {postImportModel} from "../../../api/import";
import InputButton from "../form/input-button";

export default function ImportModelPopup({isOpen, setIsOpen, importType, columnsCode, confirmedHeader, onConfirmed}) {

    const { user } = useSelector((state) => state);
    const { loggedCompany } = user;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { t } = useTranslation();

    const [isCreateLoading, setCreateLoading] = useState(false);
    const [exception, setException] = useState(false);

    const storeData = async (data) => {
        if (loggedCompany) {
            setCreateLoading(true);
            postImportModel({ company_id: loggedCompany.id, import_type: importType , data })
            .then((response) => {
                setCreateLoading(false);
                setIsOpen(false);
                onConfirmed();
            }).catch((e) => {
                setException(e.message);
                setCreateLoading(false);
            });
        }
    };

    const onSubmit = async (data) => {
        data['code'] = columnsCode;
        data['params'] = confirmedHeader;
        await storeData(data);
    };

    return (
        <FormPopup
            title={t("app.import_model.add_title")}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-1">
                    <div className={"col-span-2"}>
                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.import_model.name")}
                        />
                    </div>
                </div>

                {/* Warning */}
                { exception && <Warning message={exception} /> }

                <div className="flex before:flex-1 items-center justify-between mt-6">

                    <InputButton
                        isLoading={false}
                        extraClass={'btn border-indigo-800 text-indigo-800 hover:bg-indigo-800 hover:text-white mr-2'}
                        onClick={() => {
                            setIsOpen(false);
                            onConfirmed();
                        }}
                        label={t("app.import_model.later_btn")}
                    />

                    <InputSubmit
                        isLoading={isCreateLoading}
                        label={t("app.import_model.add_btn")}
                    />
                </div>

            </form>
        </FormPopup>
    )
}
