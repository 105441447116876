import React from 'react'
import { MenuAlt4Icon} from '@heroicons/react/outline';
//import UserMenu from "./../header/user-menu";
import Notification from "./../header/notification";
import {Link} from "react-router-dom";
import {INDEX} from "../../../routes/endpoints";
import logo from "../../../resources/images/logo.svg";
//import CompanyTypeSwitch from "./company-type-switch";


function Header({setSidebarOpen }) {

  return (
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow-sm">
        <button
            type="button"
            className="px-4  text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt4Icon className="h-4 w-4" aria-hidden="true" />
        </button>
        <div className="md:before:flex-1 flex-1 px-4 flex justify-between">

           <div className={'md:hidden flex-1 flex items-center justify-center'}>
               <Link className="block" to={INDEX}>
                   <img src={logo} width={150} alt={'logo'} />
               </Link>
           </div>

          <div className="ml-4 flex items-center md:ml-6">

            {/* search modal 
            <CompanyTypeSwitch />

            {/* search modal
            <SearchModal />*/}
            
            {/* Notification */}
            <Notification />

            {/* Profile dropdown
            <UserMenu />*/}

          </div>
        </div>
      </div>
  );
}

export default Header;
