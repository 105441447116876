import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getSubscriptionUsage
} from "../api/subscription";

const initialState = {
    subscriptionUsage: null,
};



/***************
 * ASYNC THUNK *
 ***************/

export const subscriptionUsageLimits = createAsyncThunk("subscription/usage", async (data) => {
    try {
        return {
            subscriptionUsage: await getSubscriptionUsage({company_id: data}),
        };
    } catch (e) {
        throw e;
    }
});

/*******************
 * EXPOSED          *
 *******************/




/*******************
 * REDUCERS EVENTS *
 *******************/

export const subscriptionReducer = createSlice({
  name: "subscription",
  initialState,
  extraReducers: (builder) => {
    builder
        .addCase(subscriptionUsageLimits.pending, (state) => {
            state.isLoadingUsage = true;
        })
        .addCase(subscriptionUsageLimits.fulfilled, (state, action) => {
            for (const k in action.payload) {
                state[k] = action.payload[k];
            }
            state.isLoadingUsage = false;
        })
        .addCase(subscriptionUsageLimits.rejected, (state, action) => {
            state.isLoadingUsage = false;
            state.usageException = action.error.message;
        });
  },
});

export default subscriptionReducer.reducer;
