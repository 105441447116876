import React from "react";
import {
    CalendarIcon,
    CheckIcon, ClockIcon,
    DocumentSearchIcon, DocumentTextIcon,
    IdentificationIcon,
    UserIcon,
    XIcon
} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const LeadsBarIcon = ({status}) => {


    const { t } = useTranslation();

    let statusStyles = {
        new_lead: {
            style: 'bg-gray-100 text-gray-700',
            title: t("app.leads.new_lead"),
            icon: UserIcon
        },
        qualification: {
            style: 'bg-yellow-100 text-yellow-900',
            title: t("app.leads.qualification"),
            icon: IdentificationIcon
        },
        proposal: {
            style: 'bg-blue-100 text-blue-900',
            title: t("app.leads.proposal"),
            icon: DocumentTextIcon
        },
        negotiation: {
            style: 'bg-yellow-100 text-yellow-700',
            title: t("app.leads.negotiation"),
            icon: DocumentSearchIcon
        },
        closed: {
            style: 'bg-green-100 text-green-900',
            title: t("app.leads.closed"),
            icon: CheckIcon
        },
        lost: {
            style: 'bg-red-100 text-red-800',
            title: t("app.leads.lost"),
            icon: XIcon
        },
        bad_timing: {
            style: 'bg-gray-100 text-gray-900',
            title: t("app.leads.bad_timing"),
            icon: ClockIcon
        },
        attempted_to_contact: {
            style: 'bg-purple-100 text-purple-900',
            title: t("app.leads.attempted_to_contact"),
            icon: CalendarIcon
        }
    };


    const IconList = ({status, className}) => {

        let IconType = statusStyles[status].icon;
        return (
            <IconType
                className={className}
                aria-hidden="true"
            />
        )
    }

    return (
        <span
            className={classNames(
                "inline-flex items-center rounded-full text-xs font-medium px-2.5 py-1",
                statusStyles[status].style,
            )}
        >
                <IconList
                    status={status}
                    className={classNames(
                        "w-4 h-4 mr-1 flex-shrink-0"
                    )}
                />

            {statusStyles[status].title}
        </span>
    )
};
