import React, {useEffect} from "react";
import SlidePanel from "../../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import {fetchUnits} from "../../../../reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";

export default function ImportDetail({ isOpen, setIsOpen }) {

    const { t } = useTranslation();
    const { unitList, isUnitsLoading } = useSelector((state) => state.config);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUnits());
    }, [dispatch]);


    return (
        <SlidePanel
            bg={"bg-white"}
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false)
            }}
            size={'max-w-xl'}
            setClean={() => {

            }}
        >

            <LoaderWrapper isLoading={isUnitsLoading}>

                <div className="px-2 py-3">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">{t("app.products.unit_value")}</h3>
                </div>

                <div className="border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">

                        {
                            unitList.map((data) => (
                                data.unit_values.map((item) => (
                                    <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={item.id}>
                                        <dt className="text-sm font-medium text-gray-900"> {item.name + " ("+item.symbol+")"}</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {t("app.catalog.catalog_code")}: {item.code}
                                        </dd>
                                    </div>
                                ))
                            ))
                        }




                    </dl>
                </div>
            </LoaderWrapper>

        </SlidePanel>
    );
}

