import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {
    getSubscriptions,
    putSubscriptionPayments
} from "../../../api/subscription";
import {CheckIcon} from "@heroicons/react/outline";
import PrimaryButton from "../../../components/common/button/primary-btn";
import {useStripe, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import PaymentPopup from "../../../components/partials/payment/payment-popup";
import {DURATION_1_M, DURATION_1_Y, getPeriodType} from "../../../constants/config";
import BillingPopup from "../../../components/partials/payment/billing-popup";
import SalesProposal from "./sales-proposal";
import WhiteButton from "../../../components/common/button/white-btn";
import GenericBtn from "../../../components/common/button/generic-btn";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {numberFormat} from "../../../utils/converter";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();

    const { detail } = useSelector((state) => state.user );

    const [isSubscriptionLoading, setSubscriptionLoading] = useState(true);
    const [subscriptionList, setSubscriptionList] = useState([]);

    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [paymentId, setPaymentId] = useState(false);

    const checkIsMonthDuration = () => {
        if(detail.last_subscription){
            if(detail.last_subscription.subscription && detail.last_subscription.subscription.duration === DURATION_1_Y){
                return false
            }
        }
        return true
    }

    const [isOpenUpdateChange, setOpenUpdateChange] = useState(false);
    const [isOpenBilling, setOpenBilling] = useState(false);
    const [changeDetail, setChangeDetail] = useState(false);
    const [isMonthly, setMonthly] = useState(checkIsMonthDuration());

    const [isContactSales, setContactSales] = useState(false);

    const { t } = useTranslation();



    const subscriptionPayment = (id) => {

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setPaymentLoading(true);
        putSubscriptionPayments({ subscription_id: id })
            .then((response) => {
                //toast.success(t("app.company.update_success"));

                const {error} =  stripe.redirectToCheckout({
                    sessionId: response.session_id
                });

                if (error) {
                    setPaymentLoading(false);
                    setPaymentId(false);
                }
            })
            .catch((e) => {
                toast.error(e.message);
                setPaymentLoading(false);
                setPaymentId(false);
            });
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchSubscriptions() {
            setSubscriptionLoading(true);
            try {
                const res = await getSubscriptions({signal});
                setSubscriptionList(res);
            } catch (e) {
                setSubscriptionList([]);
            } finally {
                setSubscriptionLoading(false);
            }
        }


        fetchSubscriptions();
        return () => {
            controller.abort();
        };
    }, []);



    return (

            <BasePermissionWrapper
                adminHasAccess={false}
                renderBaseOnFail={true}
                requiredPermissions={[]}
            >
                <LoaderWrapper isLoading={isSubscriptionLoading} >
                    <div className="mx-auto">

                        <div className="bg-white shadow-sm rounded-sm relative">

                            <div className="lg:col-span-9 shadow">

                                {/* Profile section */}
                                <div className="py-6 px-4 sm:p-6 lg:pb-8">

                                    <div className="sm:align-center sm:flex sm:justify-between mb-10">

                                    <div>
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">{t("app.settings.plans")}</h2>
                                        <p className="mt-1 text-sm text-gray-500">{t("app.settings.plan_description")}</p>
                                    </div>

                                    <div className="relative  flex self-center rounded-lg bg-gray-100 p-0.5">
                                        <button
                                            type="button"
                                            onClick={() => {setMonthly(!isMonthly)}}
                                            className={classNames(
                                                isMonthly ? "border-gray-200 bg-white text-gray-900 shadow-sm" : "border border-transparent text-gray-700",
                                                "relative w-1/2 capitalize whitespace-nowrap rounded-md py-2 text-sm font-medium sm:w-auto sm:px-8"
                                            )}


                                        >
                                            {t("app.plan.monthly")}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {setMonthly(!isMonthly)}}
                                            className={classNames(
                                                !isMonthly ? "border-gray-200 bg-white text-gray-900 shadow-sm" : "border border-transparent text-gray-700",
                                                "relative w-1/2 capitalize whitespace-nowrap rounded-md py-2 text-sm font-medium  sm:w-auto sm:px-8"
                                            )}                                    >
                                            {t("app.plan.yearly")}
                                        </button>
                                    </div>
                                    </div>


                                    <div className="mt-5 space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                                        {
                                            (subscriptionList?.internal ?? []).sort( (a, b) =>  a.position > b.position ? 1 : -1).filter(r => (isMonthly ? r.duration === DURATION_1_M  :  r.duration === DURATION_1_Y) || r.tb_determined ).map(subscription =>(
                                                <SubscriptionCard
                                                    subscription={subscription}
                                                    isLoading={isPaymentLoading}
                                                    paymentId={paymentId}
                                                    onClick={(details) => {
                                                        setChangeDetail(details);
                                                        setOpenBilling(true);
                                                    }}
                                                    onContact={() => {
                                                        setContactSales(true);
                                                    }}
                                                    key={subscription.id}
                                                />
                                            ))
                                        }

                                        {
                                            (subscriptionList?.customized) && (
                                                <SubscriptionCard
                                                    subscription={subscriptionList?.customized}
                                                    isLoading={isPaymentLoading}
                                                    paymentId={paymentId}
                                                    onClick={(details) => {
                                                        setChangeDetail(details);
                                                        setOpenBilling(true);
                                                    }}

                                                />
                                            )
                                        }

                                    </div>

                                    <section className="mt-5">
                                        <div
                                            className="px-5 py-3 bg-indigo-50 border border-indigo-100 rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center">
                                            <div className="text-slate-800 font-semibold mb-2 xl:mb-0">{t("app.plan.contact_sales_desc")}</div>
                                            <PrimaryButton
                                                label={t("app.plan.contact_sales")}
                                                isLoading={false}
                                                isFullWith={false}
                                                onClick={() => {
                                                  setContactSales(true);
                                                }}
                                            />
                                        </div>
                                    </section>

                                    <section className="mt-5">
                                        <div className="my-8">
                                            <h2 className="text-2xl text-slate-800 font-bold">FAQ</h2>
                                        </div>
                                        <ul className="space-y-5">
                                            <li>
                                                <div className="font-semibold text-blue-1000 mb-1">
                                                    {t("app.plan.faq.q1_title")}
                                                </div>
                                                <div className="text-sm">
                                                    {t("app.plan.faq.q1_description")}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="font-semibold text-blue-1000 mb-1">
                                                    {t("app.plan.faq.q2_title")}
                                                </div>
                                                <div className="text-sm">
                                                    {t("app.plan.faq.q2_description")}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="font-semibold text-blue-1000 mb-1">
                                                    {t("app.plan.faq.q3_title")}
                                                </div>
                                                <div className="text-sm">
                                                    {t("app.plan.faq.q3_description")}
                                                </div>
                                            </li>
                                        </ul>
                                    </section>


                                </div>

                            </div>

                        </div>
                    </div>



                    { isOpenUpdateChange && (
                        <PaymentPopup
                            isOpen={isOpenUpdateChange}
                            setIsOpen={(r) => {setOpenUpdateChange(r)}}
                            detail={changeDetail}
                        />
                    )
                    }

                    { isOpenBilling && (
                        <BillingPopup
                            isOpen={isOpenBilling}
                            setIsOpen={(r) => {setOpenBilling(r)}}
                            detail={changeDetail}
                            onConfirm={() => {
                                if (changeDetail.type === 'change') {
                                    setOpenBilling(false);
                                    setOpenUpdateChange(true);
                                } else {
                                    setOpenBilling(false);
                                    setPaymentId(changeDetail.subscription_id);
                                    subscriptionPayment(changeDetail.subscription_id);
                                }
                            }}
                        />
                    )
                    }



                </LoaderWrapper>

                <SalesProposal
                    isOpen={isContactSales}
                    setIsOpen={setContactSales}
                />
            </BasePermissionWrapper>

    );
};

const SubscriptionCard = ({subscription, isLoading, paymentId, onClick, onContact}) => {
    const { t } = useTranslation();

    const { detail } = useSelector((state) => state.user );

    const checkActiveSubscription = (id) => {
        let status = false;
        if(id) {
            if (detail.is_active_user && (detail?.last_subscription?.subscription?.id === id ?? false)) {
                status = true;
            }
        }
        return status;
    }

    const checkHasSubscription = () => {
        let status = false;
        if (detail.is_active_user && (detail?.last_subscription ?? false)) {
            status = true;
        }
        return status;
    }


    return (
        <div className={"relative bg-white shadow-sm rounded-sm border border-slate-200"}>
            <div className="absolute top-0 left-0 right-0 h-1" style={{backgroundColor: subscription?.color ?? '#1E3A8A'}} aria-hidden="true" />

            <div className="px-5 flex flex-col pt-5 pb-6 border-b border-slate-200 text-center">
                <h3 className="text-lg  mb-3 uppercase font-bold text-blue-1000">{subscription.name}</h3>

                <div className={subscription.duration === DURATION_1_Y ? "flex-shrink h-32 mb-8" : "flex-shrink h-32 mb-2"}>
                    <div className="text-sm mb-3">{subscription.small_description}</div>
                    <div className="text-sm text-center font-bold text-blue-1000 mb-3" >{subscription?.subscription_details?.find(r => r.focus === true)?.description ?? ''}</div>

                    {
                        (!subscription.tb_determined && subscription.duration === DURATION_1_Y) && (
                            <h3 className="inline-flex  text-center  text-left bg-red-100 px-4 py-1 text-sm font-semibold text-red-600">
                                {t("app.plan.discount")}
                            </h3>
                        )
                    }


                </div>




                <div className="font-bold mb-5 h-10">
                    {
                        !subscription.tb_determined && (
                            <div>
                                <span className="text-3xl text-blue-1000">{numberFormat(subscription.price / (subscription.duration === DURATION_1_Y ? 12 : 1))}</span>
                                <span className="text-slate-800 text-slate-500 font-medium text-sm">/ {" "}{t("app.plan."+getPeriodType(DURATION_1_M))}</span>
                            </div>
                        )
                    }

                </div>



                {
                    checkHasSubscription() ? (
                        <>
                            {
                                checkActiveSubscription(subscription.id) ? (
                                    <WhiteButton
                                        label={(<><CheckIcon className="h-5 w-5 mr-2" /> {t("app.settings.active_subscription")}</>)}
                                        isLoading={false}
                                        isFullWith={true}
                                        onClick={false}
                                        disabled={true}
                                    />
                                ) : (
                                    subscription.tb_determined ? (
                                        <GenericBtn
                                            label={t("app.plan.contact_sales")}
                                            isLoading={isLoading && paymentId === subscription.id}
                                            isFullWith={true}
                                            classBtn={'bg-white text-indigo-800 border border-indigo-800  hover:bg-indigo-800 hover:text-white'}
                                            onClick={() => {
                                                onContact();
                                            }}
                                            disabled={isLoading && paymentId !== subscription.id}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label={t("app.settings.change_plan")}
                                            isLoading={isLoading && paymentId === subscription.id}
                                            isFullWith={true}
                                            onClick={() => {
                                                if(detail.last_subscription.status === 'trailing'){
                                                    let detail = {
                                                        subscription_id: subscription.id,
                                                        type: 'default'
                                                    }
                                                    onClick(detail);
                                                }else{
                                                    let detail = {
                                                        subscription_id: subscription.id,
                                                        type: 'change',
                                                        title: t("app.settings.change_plan"),
                                                        description: t("app.settings.change_plan_desc", {new_subscription: subscription.name})
                                                    }
                                                    onClick(detail);
                                                }
                                            }}
                                            disabled={isLoading && paymentId !== subscription.id}
                                        />
                                    )
                                )
                            }
                        </>
                    ) : (

                        subscription.tb_determined ? (
                            <GenericBtn
                                label={t("app.plan.contact_sales")}
                                isLoading={isLoading && paymentId === subscription.id}
                                isFullWith={true}
                                classBtn={'bg-white text-indigo-800 border border-indigo-800  hover:bg-indigo-800 hover:text-white'}
                                onClick={() => {
                                    onContact();
                                }}
                                disabled={isLoading && paymentId !== subscription.id}
                            />
                        ) : (
                            <PrimaryButton
                                label={subscription.triable ? t("app.plan.trial_label", {days: subscription.trial_duration}): t("app.settings.select_subscription")}
                                isLoading={isLoading && paymentId === subscription.id}
                                isFullWith={true}
                                onClick={() => {
                                    let detail = {
                                        subscription_id: subscription.id,
                                        type: 'default'
                                    }
                                    onClick(detail);
                                }}
                                disabled={isLoading && paymentId !== subscription.id}
                            />
                        )


                    )
                }




            </div>

            {
                subscription.subscription_details.length > 0 && (
                    <div className="px-5 pt-4 pb-5">
                        <div className="text-xs text-blue-1000 font-semibold uppercase mb-4">{t("app.settings.included")}</div>
                        {/* List */}
                        <ul>
                            {
                                subscription.subscription_details.filter(r => r.focus === false).map(subscription_detail => (
                                    <li key={subscription_detail.id} className="flex items-center py-1">
                                        <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-500 mr-3" aria-hidden="true" />
                                        <div className="text-sm">{subscription_detail.description}</div>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                )
            }
        </div>

    );
}


export default function Subscription(){
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};
