import React, {useEffect, useState} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../../utils/converter";
import {COMPANY, LOGS, WEBHOOK, WEBHOOKS} from "../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {InformationCircleIcon, TerminalIcon} from "@heroicons/react/outline";
import {getElement} from "../../../../api/config";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import Nodata from "../../../../resources/images/no-data.gif";
import CodePopup from "../../../../components/common/popup/code-popup";
import {fetchWebhookEvents} from "../../../../reducers/configReducer";
import {getFormattedDate} from "../../../../utils/timeUtils";
import JsCodePopup from "../../../../components/common/popup/jscode-popup";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Webhooks = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_WEBHOOK)]} renderBaseOnFail={true} >
        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.company_preference.event"),
                            t("app.company_preference.end_point"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, WEBHOOKS])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, WEBHOOK])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, WEBHOOK, ':id'])}
                        baseResult={[]}
                        title={"Webhook"}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        icon={TerminalIcon}
                        editForm={EditForm}
                        editTitle={"Webhooks"}
                        addForm={AddForm}
                        addFormLabel={t("app.catalog.add", {type: "Endpoint"})}
                        btnAddLabel={t("app.catalog.add", {type: "Endpoint"})}
                        isActiveEdit={true}
                        SlideDefaultWith={'max-w-7xl'}

                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, WEBHOOK, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <div>
                        <div className="text-sm text-gray-900 lowercase">
                            {element?.webhook_event?.code  ?? '-'}
                        </div>
                        <div className="text-xs text-gray-500 lowercase">
                            {element?.end_point  ?? '-'}
                        </div>
                    </div>
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.company_preference.event")}>
                        {element?.webhook_event?.name ?? '-'}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, WEBHOOK, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="text-sm text-gray-900 capitalize">
                    {element?.webhook_event?.name ?? '-'}
                </div>
            </TableDetail>

            <TableDetail>
                <div className="text-sm text-gray-900 lowercase">
                    {element?.webhook_event?.code  ?? '-'}
                </div>
                <div className="text-xs text-gray-500 lowercase">
                    {element?.end_point  ?? '-'}
                </div>
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData, dataGet }) =>  {

    const { webhookList, isWebhookLoading } = useSelector((state) => state.config);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();


    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    const order = {
        "create": 1,
        "edit": 2,
        "delete": 3
    };


    useEffect(() => {
        dispatch(fetchWebhookEvents());
    }, [dispatch]);

    const getFilteredOptions = () => {
        if(webhookList.length > 0) {
            let grouped = webhookList.reduce((accumulator, current) => {
                let splitCode = current.code.split(".");
                let mainGroup = current.event_type;
                let subGroup = splitCode.length === 3 ? splitCode[1] : splitCode[0];

                if (!accumulator[mainGroup]) {
                    accumulator[mainGroup] = {}
                }

                if (!accumulator[mainGroup][subGroup]) {
                    accumulator[mainGroup][subGroup] = []
                }

                accumulator[mainGroup][subGroup].push(current);

                return accumulator;
            }, {});

            for(let mainGroup in grouped) {
                for(let subGroup in grouped[mainGroup]) {
                    grouped[mainGroup][subGroup].sort((a, b) => {
                        let aOrder = order[a.code.split(".").pop()] || 4;
                        let bOrder = order[b.code.split(".").pop()] || 4;
                        return aOrder - bOrder;
                    });
                }
            }

            return grouped;
        }
        return [];
    }

    const showList = () => {
        if(webhookList.length > 0) {
            return webhookList.filter(r => !dataGet.some(aw => aw.webhook_event.id === r.id)).length > 0;
        }
        return false;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"end_point"}
                        type={"text"}
                        errors={errors.end_point}
                        input={{ ...register("end_point", { required: true }) }}
                        label={t("app.company_preference.end_point")}
                        isRequired={true}
                    />



                    <label
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t("app.company_preference.event")} <span className="text-red-500">*</span>
                    </label>


                    <nav className="max-h-96	 overflow-y-auto border border-grey-500" aria-label="Directory">

                        <LoaderWrapper isLoading={isWebhookLoading}>

                            {
                                showList() ? (
                                    <>
                                        {Object.keys(getFilteredOptions()).map((type) => (
                                            <div key={type} className="relative">
                                                <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                                                    <h3>{type}</h3>
                                                </div>
                                                <ul className="divide-y divide-gray-100">
                                                    {Object.keys(getFilteredOptions()[type]).map((subgroup) => (
                                                        getFilteredOptions()[type][subgroup].filter(r => !dataGet.some(aw => aw.webhook_event.id === r.id)).map((item) => (
                                                            <li key={item.id} className={classNames(
                                                                "flex gap-x-4 px-3 py-2 hover:bg-gray-100 cursor-pointer",
                                                                (watch("webhook_event") && watch("webhook_event").id === item.id) && 'bg-gray-100'
                                                            )} onClick={() => {
                                                                setValue('webhook_event', {id: item.id})
                                                            }}>
                                                                <div className="min-w-0">
                                                                    <p className="text-sm font-semibold leading-6 text-gray-900">{item.name}</p>
                                                                    <p className="text-xs  leading-6 text-gray-900">{item.code}</p>

                                                                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">{item.description}</p>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-col col-span-full bg-white  rounded-sm border border-slate-200">
                                            <div className="text-center p-10">
                                                <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                                <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                                    {t("app.common.not_found")}
                                                </h1>
                                            </div>
                                        </div>
                                    </>
                                )
                            }


                        </LoaderWrapper>


                    </nav>



                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    disabled={!watch('webhook_event') || !watch('end_point')}
                    isLoading={isLoading}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const { loggedCompany } = useSelector((state) => state.user);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();


    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);


    const [isOpenCode, setOpenCode] = useState(false);
    const [request, setRequest] = useState(false);
    const [response, setResponse] = useState(false);

    const [isOpenJsCode, setOpenJsCode] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(data !== null){
            setValue('id', data.id);
            setValue('end_point', data.end_point);
        }
    // eslint-disable-next-line
    }, [setValue, data]);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElement(getPathParam([COMPANY, loggedCompany.id, WEBHOOK, data.id, LOGS]), signal, {});
                setList(res);
            } catch (e) {
                setList([]);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, data]);


    const getColorByStatusCode = (statusCode) => {
        // Convert the status code to a string
        const strStatusCode = String(statusCode);

        const colorMap = {
            '1': 'text-gray-700 bg-gray-50 ring-gray-600/20', // 1xx: Informational
            '2': 'text-green-700 bg-green-50 ring-green-600/20', // 2xx: Success
            '3': 'text-blue-700 bg-blue-50 ring-blue-600/20', // 3xx: Redirection
            '4': 'text-yellow-700 bg-yellow-50 ring-yellow-600/20', // 4xx: Client errors
            '5': 'text-red-700 bg-red-50 ring-red-600/20' // 5xx: Server errors
        };

        // Return the color based on the first digit of the status code
        return colorMap[strStatusCode[0]];
    }


    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-md font-semibold text-gray-900">
                        {data?.webhook_event?.name ?? '-'}
                    </h3>
                    <p className="text-sm text-gray-500 lowercase">
                        {data?.webhook_event?.description ?? '-'}
                    </p>
                </div>


                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}


                <div className="flex items-end mt-3">

                    <div className="w-full mr-2 ">
                        <InputText
                            id={"end_point"}
                            type={"text"}
                            errors={errors.end_point}
                            input={{ ...register("end_point", { required: true }) }}
                            label={t("app.company_preference.end_point")}
                        />
                    </div>

                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={false}
                        label={t("app.form.save")}
                    />
                </div>


                <div className="flex items-center mt-1">
                    <InformationCircleIcon className={"cursor-pointer text-blue-400 h-5 w-5 cursor-pointer"} onClick={() => {
                        setOpenJsCode(true);
                    }} />
                    <p className="text-sm text-gray-900   ml-1" dangerouslySetInnerHTML={{__html: t("app.company_preference.decrypt_key", {key: data.code})}} />

                </div>

            </form>



            <h3 className="mt-4 text-md font-semibold text-gray-900">
                {t("app.company_preference.webhook_log_title")}
            </h3>
            <p className="text-sm text-gray-500 lowercase">
                {t("app.company_preference.webhook_log_description")}
            </p>


            <LoaderWrapper isLoading={isLoading}>


                {
                    list.length > 0 ? (
                        <ul className="divide-y divide-gray-100 mt-3">
                            {list.map((item) => (
                                <li key={item.id}
                                    className="flex items-center justify-between gap-x-6 py-2">
                                    <div className="min-w-0">

                                        <div className="flex items-center">

                                            <p
                                                className={classNames(
                                                    item.response_code ? getColorByStatusCode(item.response_code) : 'text-gray-600 bg-gray-50 ring-gray-500/10',
                                                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset mr-2'
                                                )}
                                            >
                                                {item.response_code ? item.response_code : 'Waiting'}
                                            </p>

                                            <div className="flex items-center">
                                                 <p>POST /</p><p className="ml-3 text-sm font-semibold leading-6 text-gray-900">{item.end_point}</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">

                                        <p className="ml-3 text-sm font-semibold leading-6 text-gray-900">
                                            {getFormattedDate(item.date)}
                                        </p>

                                        <div onClick={() => {
                                            setOpenCode(true);
                                            setRequest(item.request)
                                            setResponse(item?.response ?? false)
                                        }} className=" cursor-pointer hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                            {t("dashboard.view_log")}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col col-span-full bg-white  rounded-sm border border-slate-200">
                            <div className="text-center p-10">
                                <img src={Nodata} className="-mt-8 mx-auto" alt="not-found"  />
                                <h1 className="text-md -mt-10 md:text-xl text-gray-400 font-bold">
                                    {t("app.common.not_found")}
                                </h1>
                            </div>
                        </div>
                    )
                }



            </LoaderWrapper>



            {
                isOpenCode && (
                    <CodePopup
                        isOpen={isOpenCode}
                        setIsOpen={setOpenCode}
                        request={request}
                        response={response}
                        title={'Log'}
                    />
                )
            }



            {
                isOpenJsCode && (
                    <JsCodePopup
                        isOpen={isOpenJsCode}
                        setIsOpen={setOpenJsCode}
                    />
                )
            }


        </>
    );
}

export default Webhooks;
