import React from "react";
import placeholder from "../../../resources/images/placeholder.jpeg";
import {useTranslation} from "react-i18next";
import AnimatedPing from "../progress/animated-ping";
import { StatusBarIcon } from "../alert/status-bar-icon";

const ProductCell = ({ product, isActiveImage = true, isActiveManufacture = true, hideArchiveStatus = false, hasError = false, errorMessage = false, isShowStatus = false, isWrap = false, onShowClick = false, shotTitle = false }) => {

    const { t } = useTranslation();

    return (
    <div className="flex items-center ">



        {
            isActiveImage && (
                <div className="relative flex-shrink-0 mr-3 ">
                    <img className="object-contain h-12 w-12 rounded-md " src={product?.picture?.reference ?? placeholder} alt="" />

                    {
                        ((product.archived && hideArchiveStatus === false) || hasError) && (
                            <span className="absolute bottom-0 right-0 block translate-x-1/2 translate-y-1/2 transform rounded-full border-2 border-white">
                                <AnimatedPing color={'red'} extraClass={'-mt-2 -ml-2'} />
                            </span>
                        )
                    }

                </div>
            )
        }
        <div className="text-left">
            <div className="text-sm text-gray-900">
                {product?.product_reference ?? ''}
            </div>
                <div className={isWrap ? "text-xs flex-wrap text-gray-700 whitespace-normal w-56": "text-xs flex-wrap text-gray-700"}>
                {product?.name ?? ''}
            </div>

            {
                (isActiveManufacture) && (
                        <div className={isWrap ? "text-xs flex-wrap text-gray-500 whitespace-pre-wrap" : "text-xs flex-wrap text-gray-500"}>
                        {t("app.products.manufacture_code")}: {product?.manufacture_reference ?? '-'}
                    </div>
                )
            }

            {
                (product.archived && hideArchiveStatus === false) && (
                    <div className="text-xs flex-wrap text-red-500" style={{fontSize: '0.60rem'}}>
                        {t("app.products.archived_product")}
                    </div>
                )
            }


                {
                    isShowStatus && product.status && (
                        <div className="text-xs flex-wrap text-gray-500">
                            {t("app.products.status")}: <StatusBarIcon status={product.status} isText={true} />
                        </div>
                    )
                }

            {
                (hasError && errorMessage) && (
                    <div className="text-xs flex-wrap text-red-500" style={{fontSize: '0.60rem'}}>
                        {errorMessage}
                    </div>
                )
            }


                {
                    (onShowClick && shotTitle) && (
                        <div className="flex items-center text-xs text-indigo-600 underline cursor-pointer" onClick={() => {
                            onShowClick(true)
                        }}>
                            {shotTitle}
                        </div>
                    )
                }


        </div>


    </div>
  );
};

export default ProductCell;
