import React, {useEffect} from 'react'
import ListSection from "../../../components/layout/list-section";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import ProductCell from "../../../components/common/table/product-cell";
import TableDetail from "../../../components/common/list/table-detail";
import {ArrowLeftIcon, TrashIcon} from "@heroicons/react/outline";
import {
    FilterButton,
    TrashButton
} from "../../../components/layout/page-header";
import {useTranslation} from "react-i18next";
import ButtonSubmit from "../../../components/common/form/button-submit";
import NumberFormat from "react-number-format";
import DiscountEditor from "../../../components/common/card/discount-editor";
import PriceHistory from "../../../components/common/card/price-history";



const ProductConfiguration = ({
  onChangeStep,
  selectedProductList,
  onDelete,
  onSetQuantity,
  onChangeValue,
  onAddDiscount,
  onUpdateDiscount,
  quotationDetail,
  onSave,
  isLoading
}) => {

    const { t } = useTranslation();
    const columns = [
        t("app.quotations.product"),
        t("app.quotations.quantity"),
        t("app.quotations.price_discount"),
        t("app.quotations.net_price"),
        "",
    ];

    return (
        <>
            <div className="sm:flex sm:items-center flex justify-between items-center">
                <div className=" flex justify-between items-center">

                    <FilterButton
                        onClick={() => {
                            onChangeStep();
                        }}
                        icon={
                            <ArrowLeftIcon
                                className="w-4 h-4"
                                aria-hidden="true"
                            />
                        }
                        text={t("app.common.back")}
                    />

                    <h1 className="text-xl font-medium text-gray-700 ml-3">{t("app.quotations.selected_product_title")}</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">


                    <div className="ml-2">
                        <ButtonSubmit
                            onClick={() => {
                                onSave();
                            }}
                            isFullWith={true}
                            isLoading={isLoading}
                            label={t("app.quotations.add_products")}
                        />
                    </div>
                </div>
            </div>


            <ListSection
                columns={columns}
                totalRows={selectedProductList.length}
                list={selectedProductList}
                showHeader={false}
                isLoading={false}
                showMobile={false}
                desktopRow={DeskRow}
                extras={{
                    quotationDetail,
                    onDeleteProduct: (id) => {
                        onDelete(id)
                    },
                    onSetQuantity: (id, item, value) => {
                        onSetQuantity(id, item, value)
                    },
                    onChangeValue: (id, value, type) =>{
                        onChangeValue(id, value, type);
                    },
                    onUpdateDiscount: (id, value, index) =>{
                        onUpdateDiscount(id, value, index);
                    },
                    onAddDiscount: (id) =>{
                        onAddDiscount(id);
                    },
                }}
            />

        </>
    );
};


const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();

    const {control, setValue} = useForm();

    let discounts = (element?.discounts ?? []);

    const getFinalPrice = () => {

        let final_price = element.base_price ?? 0;
        // eslint-disable-next-line
        discounts.map((item, index) => {
           final_price = final_price - (final_price * (item.price / 100));
        })

        return final_price;

    }


    useEffect(() => {

        if(element.base_price && element.base_price > 0){
            setValue('base_price', element.base_price)
        }

        if(element.quantity && element.quantity > 0){
            setValue('quantity', element.quantity)
        }

        // eslint-disable-next-line
    }, [setValue])


    return (
        <>
            <tr className="border-t border-gray-200">
                <TableDetail colSpan={1} extraClass={"whitespace-pre-line"} >
                    <ProductCell product={element} />
                </TableDetail>

                <TableDetail extraClass="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    <Controller
                        name="quantity"
                        rules={{ required: true }}
                        control={control}
                        render={({field: { onChange, value, name }, fieldState: { error }}) => (
                            <InputFormattedNumber
                                label={t("app.container.quantity")}
                                isShowLabel={false}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    extras.onChangeValue(element.id, e, 'quantity');
                                }}
                                prefix={""}
                                suffix={" Pz"}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>


                <TableDetail  extraClass="px-6 py-3 whitespace-nowrap  whitespace-pre-line text-sm font-medium text-gray-900">

                    <div className="flex items-center ">

                        <div className="flex items-center ">

                            {
                                element.last_prices && (
                                    <div className="mr-3">
                                        <PriceHistory prices={element.last_prices}  />
                                    </div>
                                )
                            }


                            <Controller
                                name="base_price"
                                rules={{ required: true }}
                                control={control}
                                render={({field: { onChange, value, name }, fieldState: { error }}) => (
                                    <InputFormattedNumber
                                        label={t("app.container.price")}
                                        isShowLabel={false}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            extras.onChangeValue(element.id, e, 'base_price');
                                        }}
                                        suffix={" €"}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                    <DiscountEditor
                        discounts={discounts}
                        element={element}
                        onUpdateDiscount={(id,e,index) => {
                            extras.onUpdateDiscount(id, e,index);
                        }}
                        onAddDiscount={(id) => {
                            extras.onAddDiscount(id);
                        }}

                    />
                    </div>
                </TableDetail>

                <TableDetail>
                    <div className="text-sm text-gray-900">
                        <NumberFormat
                            value={getFinalPrice()  * (element.quantity ?? 0)}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            suffix={" €"}
                        />
                    </div>
                    <div className="text-xs text-gray-500">
                        <NumberFormat
                            value={getFinalPrice()}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={3}
                            suffix={" €"}
                        />
                    </div>
                </TableDetail>




                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">

                    <TrashButton
                        onClick={() => {
                            extras.onDeleteProduct(element.id)
                        }}
                        icon={
                            <TrashIcon
                                className="w-4 h-4  "
                                aria-hidden="true"
                            />
                        }
                    />

                </td>
            </tr>
        </>
    );
};


export default ProductConfiguration;
