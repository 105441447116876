import React, { useCallback, useEffect, useState } from "react";
import InputSubmit from "../../../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../../components/common/popup/form-popup";
import {  useSelector } from "react-redux";
import { Warning } from "../../../../components/common/alert/banner";
import { BOOKING, COMPANY, CONFIGURATION, EMAIL_TEMPLATE, FORWARDER, FORWARDERS, STATUS } from "../../../endpoints";
import { getPathParam, getEmailsParams, getEmailsParam, serialize, getWebPathParam } from "../../../../utils/converter";
import { useTranslation } from "react-i18next";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import { EMAIL, EMAIL_PREVIEW, EMAIL_TEMPLATES, FORWARDER_CONTACTS, NEGOTIATION, READ_EMAIL, REPLAY_EMAIL } from "../../../../api/endpoints";
import DynamicSearch from "../../../../components/partials/common/dynamic-search";
import { getElement, postElement, putElement } from "../../../../api/config";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import Initials from "../../../../components/common/avatar/initials";
import toast from "react-hot-toast";
import { PlusIcon, MailIcon, TrashIcon, CalendarIcon } from "@heroicons/react/outline";
import BoxIcon from "../../../../resources/icons/BoxIcon";
import { ActionButton, TrashButton, WhiteButton } from "../../../../components/layout/page-header";
import { Disclosure } from '@headlessui/react'
import RemovePopup from "../../../../components/common/popup/remove-popup";
import ListSection from "../../../../components/layout/list-section";
import TableDetail from "../../../../components/common/list/table-detail";
import OptionalLink from "../../../../components/common/table/optional-link";
import { BookingStatusList } from "../../../../components/common/alert/booking-status-list";
import { getFormattedDate } from "../../../../utils/timeUtils";
import { debounce } from 'lodash';
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import EmailSelect from "../../../../components/common/form/email-select";
import { emailRegex } from "../../../../constants/regex";
import ReactQuill from "react-quill";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


// FORWARDER DETAILS
export const NegotiationCard = ({ responseData, onRefresh, onUpdateData, canEdit }) => {

    const { t } = useTranslation();

    // DETAILS
    // eslint-disable-next-line
    const [query, setQuery] = useState("");
    // eslint-disable-next-line
    const [isFetchLoading, setFetchLoading] = useState(false);
    const [negotiations, setNegotiations] = useState(responseData?.negotiations ?? []);


    // REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    // eslint-disable-next-line
    const [removeDetail, setRemoveDetail] = useState({});

    //ADD NEW
    const [isAddNegotiation, setAddNegotiation] = useState(false);

    // VIEW LOG
    const [isOpenLog, setOpenLog] = useState(false);
    const [logDetail, setLogDetail] = useState({});

    //Confirm
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});

    //Confirm
    const [isOpenSendMail, setOpenSendMail] = useState(false);


    // TABLE
    const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const handleSelectAll = () => {
        if (responseData.negotiations.length > 0) {
            setSelectAll(!selectAll);
            setIsCheck(responseData.negotiations);
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };
    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let list = responseData.negotiations.find(r => r.id === id);
        setIsCheck([...isCheck, list]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };

    const onUpdate = async (data, id) => {
        if (responseData) {
            data['id'] = id;
            putElement(getPathParam([BOOKING, responseData.id, NEGOTIATION, id]), data)
                .then(response => {
                    setNegotiations(response.negotiations);
                    toast.success(t("app.tasks.update_success"));
                }).catch(e => {
                    toast.error(e.message);
                });
        }
    };

    const columns = [
        {
            header: t("app.fulfillment.forwarder"),
            id: 'business_name',
            accessor: r => r.business_name,
        },
        {
            header: t("app.fulfillment.primary_contact"),
            id: 'reference',
            accessor: r => r?.primary_contact?.email ?? '',
        },
        {
            header: t("app.booking.price"),
            id: 'price',
            accessor: "price"
        },
        {
            header: t("app.booking.transit_time"),
            id: 'transit_time',
            accessor: "transit_time"
        },
        {
            header: t("app.shippings.status"),
            id: 'status',
            accessor: "status"
        },
    ];

    return (
        <>
            <div className="mt-6 ">

                <div className="border border-gray-100 relative">
                    <ListSection
                        pageLimit={1000}
                        isAbsolute={true}
                        title={t("app.booking.page_title")}
                        columns={columns}
                        totalRows={negotiations.length}
                        list={negotiations}
                        onSearch={setQuery}
                        isLoading={isFetchLoading}
                        mobileRow={MobRow}
                        hasCheckbox={true}
                        selectAll={selectAll}
                        handleSelectAll={handleSelectAll}
                        desktopRow={DeskRow}
                        isTableSort={true}
                        sortBy={sortBy}
                        onSortBy={(n) => {
                            setSortBy(n);
                        }}
                        activeDynamicFilter={false}
                        extras={{
                            isCheck,
                            handleClick,
                            responseData,
                            onViewLog: (detail) => {
                                setLogDetail(detail);
                                setOpenLog(true);
                            },
                            onRemove: (r, detail) => {
                            },
                            onUpdate: async (fild, value, id) => {
                                await onUpdate({ [fild]: value }, id);
                            },
                            onChangeStatus: async (detail) => {
                                setConfirmDetail(detail);
                                setOpenConfirm(true);
                            }
                        }}
                    >

                        {
                            (isCheck.length > 0) && (
                                <div className="inline-flex items-center justify-center">
                                    <div className={"ml-3"}>
                                        <TrashButton
                                            onClick={() => {

                                            }}
                                            icon={
                                                <TrashIcon
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={''}
                                        />
                                    </div>

                                    <div className={"ml-3"}>
                                        <WhiteButton
                                            onClick={() => {
                                                setOpenSendMail(true);
                                            }}
                                            icon={
                                                <MailIcon
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t('app.booking.replay_mail')}
                                        />
                                    </div>
                                </div>
                            )
                        }


                    </ListSection>
                </div>








                {
                    negotiations.length > 0 ? (
                        <>
                            <div className={'mt-5 mx-auto w-1/2 '}>
                                <div
                                    onClick={() => {
                                        setAddNegotiation(true);
                                    }}
                                    className="flex p-2 justify-center items-center  rounded-full border border-gray-200 px-4 text-center text-sm font-medium   text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer">
                                    <div className="flex items-center ">
                                        <div className={' mr-2  text-blue-1000'}>
                                            <PlusIcon
                                                className="h-4 w-4"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <span>{t("app.booking.add_forwarder")}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="bg-white border-2 border-gray-300 border-dashed rounded-md px-4">
                            <div className="max-w-7xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:px-8">
                                <div className="text-center">
                                    <BoxIcon
                                        className="h-20 w-20 text-blue-1000 m-auto"
                                    />
                                    <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                        {t("app.container.packaging_title")}
                                    </p>
                                    <p className="max-w-xl -mt-1 mb-5 mx-auto text-md text-gray-500">
                                        {t("app.container.packaging_description")}
                                    </p>

                                    <ActionButton
                                        extraClass={' md:ml-0 ml-0'}
                                        onClick={() => {
                                            setAddNegotiation(true);
                                        }}
                                        text={t("app.fulfillment.add_configuration")}
                                    />

                                </div>
                            </div>
                        </div>
                    )
                }



            </div>


            {
                isAddNegotiation && (
                    <AddConfiguration
                        isOpen={isAddNegotiation}
                        setIsOpen={setAddNegotiation}
                        responseData={responseData}
                        onAdded={(r) => {
                            setNegotiations(r.negotiations);
                        }}
                    />
                )
            }

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setNegotiations(r.negotiations);
                    }}
                />
            )}



            {isOpenLog && (
                <ViewEmailLog
                    isOpen={isOpenLog}
                    bookingDetail={responseData}
                    setIsOpen={setOpenLog}
                    logDetail={logDetail}
                />
            )}


            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() => {
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        setNegotiations(r.negotiations);
                    }}
                />
            )}

            {isOpenSendMail && (
                <SendEmail
                    isOpenSendMail={isOpenSendMail}
                    setOpenSendMail={(r) => {
                        setOpenSendMail(r)
                    }}
                    selectedForwarders={isCheck}
                    responseData={responseData}
                />
            )}

            
            


        </>
    )
}

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;

    const {
        control,
        setValue,
        watch
    } = useForm();


    useEffect(() => {
        if (element.price) {
            setValue('price', element.price);
        }

        if (element.transit_time) {
            setValue('transit_time', element.transit_time);
        }

    }, [element, setValue]);

    // eslint-disable-next-line
    const debouncedPrice = useCallback(debounce((reference) => {
        extras.onUpdate('price', reference, element.id);
    }, 1000), []);

    // eslint-disable-next-line
    const debouncedTransitTime = useCallback(debounce((reference) => {
        extras.onUpdate('transit_time', reference, element.id);
    }, 1000), []);

    useEffect(() => {
        if (watch('price') !== element.price && watch('price')) {
            debouncedPrice(watch('price'));
        }
        // eslint-disable-next-line
    }, [watch('price')]);

    useEffect(() => {
        if (watch('transit_time') !== element.transit_time && watch('transit_time')) {
            debouncedTransitTime(watch('transit_time'));
        }
        // eslint-disable-next-line
    }, [watch('transit_time')]);


    return (
        <>
            <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>
                <TableDetail extraClass="relative w-12 px-6 sm:w-16 sm:px-8">
                    {
                        isCheck && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )
                    }
                    <input type="checkbox"
                        id={element.id}
                        onChange={extras.handleClick}
                        checked={isCheck}
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    />
                </TableDetail>
                <TableDetail extraClass={classNames(
                    "max-w-sm"
                )}>
                    <span className="flex items-center">
                        <Initials
                            color={element?.forwarder?.color ?? ''}
                            name={element?.forwarder?.name ?? ''}
                            isVerified={false}
                        />
                        <span >
                            <span className="flex min-w-0 flex-1 flex-col">
                                <span className="truncate text-sm font-medium text-gray-900">{element?.forwarder?.name ?? ''}</span>
                            </span>
                            <p className="flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                <span onClick={() => { extras.onViewLog(element) }}>{'visualizza log'}</span>
                            </p>
                        </span>
                    </span>
                </TableDetail>

                <TableDetail>
                    <div className="text-sm text-gray-900">
                        {element.forwarder_contact.full_name}
                    </div>
                    <div className="text-xs flex-wrap text-gray-700">
                        {element.forwarder_contact.email}
                    </div>
                </TableDetail>

                <TableDetail>
                    <Controller
                        name="price"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <InputFormattedNumber
                                label={t("app.booking.price")}
                                isShowLabel={false}
                                name={name}
                                customClass={"form-input "}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                prefix={''}
                                suffix={" " + (company.currency?.currency_symbol ?? '')}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>

                <TableDetail>
                    <Controller
                        name="transit_time"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <InputFormattedNumber
                                label={t("app.booking.transit_time")}
                                isShowLabel={false}
                                name={name}
                                customClass={"form-input "}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                prefix={''}
                                suffix={" " + (watch('transit_time') ? (watch('transit_time') === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                                errors={error}
                            />
                        )}
                    />
                </TableDetail>


                <TableDetail>
                    <BookingStatusList
                        isLoading={false}
                        status={element.status}
                        isEditable={true}
                        onChangeStatus={(r) => {
                            const detailData = {
                                title: t("app.fulfillment.status_confirm_title"),
                                message: t("app.fulfillment.status_confirm_description", { status: t("app.booking." + r) }),
                                endpoint: getPathParam([BOOKING, extras.responseData.id, NEGOTIATION, element.id, STATUS, r]),
                            };

                            extras.onChangeStatus(detailData);
                        }}
                    />
                </TableDetail>

            </tr>

        </>
    );
};

const MobRow = ({ element, extras }) => {
    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <OptionalLink
                isLinked={false}
                to={''}
                className="flex-grow"
            >
                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex items-center"}>

                    </div>

                </div>

                <div className="flex flex-col">




                </div>
            </OptionalLink>
        </li>
    );
};

const AddConfiguration = ({ isOpen, setIsOpen, responseData, onAdded }) => {

    const { loggedCompany } = useSelector((state) => state.user);

    const {
        handleSubmit,
        control,
        setValue
    } = useForm({ mode: "onBlur" });

    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [defaultEmails, setDefaultEmails] = useState([]);
    const [CcEmails, setCcEmails] = useState([]);


    const [isLoadingSubmit, setLoadingSubmit] = useState(false);
    const [exception, setException] = useState(false);



    async function fetchData(forwarder) {
        setLoading(true);
        try {
            const res = await getElement(getPathParam([FORWARDER, forwarder.id, FORWARDER_CONTACTS]));
            setDefaultEmails(getEmailsParams(res.contacts.filter(r => r.is_default), "email"));
            setCcEmails(getEmailsParams(res.contacts.filter(r => r.is_default === false), "email"));

            if (forwarder.primary_contacts && forwarder.primary_contacts.length > 0) {
                setValue('primary_recipient', forwarder.primary_contacts);
            } else {
                if (forwarder.primary_contact) {
                    setValue('primary_recipient', getEmailsParam(res.contacts.find(r => r.id === forwarder.primary_contact.id), 'email'));
                }
            }

            if (forwarder.cc_recipients && forwarder.cc_recipients.length > 0) {
                setValue('cc_recipient', forwarder.cc_recipients);
            }

            if (forwarder.create_line) {
                setValue('create_line', forwarder.create_line);
            }

        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }


    const onSubmit = async (data) => {
        if (loggedCompany) {

            let forworderData = [];
            forworderData.push({ forwarder: { id: data['forwarder'].id }, first_contact: { id: data['primary_recipient'].id }, cc_contact: data['cc_recipient'], create_line: false })

            let dataSend = {
                forwarders: forworderData,
                company_email_template: { id: data['email_template'].id },
                company_email: { id: data['mail_provider'].id },
                send: true
            }

            setLoadingSubmit(true);
            setException(false);
            postElement(getPathParam([BOOKING, responseData.id, NEGOTIATION]), dataSend)
                .then(response => {
                    onAdded(response);
                    setIsOpen(false);
                }).catch(e => {
                    setException(e.message);
                    setLoadingSubmit(false);
                });
        }
    };

    return (
        <FormPopup
            title={t("app.booking.add_forwarder")}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">

                        <div className="col-span-2">
                            <Controller
                                name="mail_provider"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <DynamicSearch
                                        selectLabel={t("app.provider.email_from")}
                                        endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL]) + serialize({ filter_by: 'active' })}
                                        label={'email'}
                                        errors={error}
                                        value={value}
                                        name={name}
                                        onChange={(r) => {
                                            onChange(r);
                                        }}
                                        isClearable={false}
                                        isRequired={true}
                                        isDisabled={false}

                                    />
                                )}
                            />
                        </div>

                        <Controller
                            name="email_template"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={t("app.company_preference.template")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATES])}
                                    responseLabel={'templates'}
                                    label={'title'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={(r) => {
                                        onChange(r);
                                    }}
                                    isClearable={false}
                                    isRequired={true}
                                    isDisabled={false}

                                />
                            )}
                        />



                        <Controller
                            name="forwarder"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={t("app.shippings.forwarders")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, FORWARDERS])}
                                    responseLabel={'forwarders'}
                                    label={'name'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    esludeIds={responseData.negotiations.map(item => ({ id: item.forwarder.id }))}
                                    onResponse={(r) => {

                                    }}
                                    onChange={async (r) => {
                                        onChange(r);
                                        fetchData(r);
                                    }}
                                />
                            )}
                        />


                        <div className="col-span-2">
                            <Controller
                                name="primary_recipient"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <EmailSelect
                                        label={t("app.fulfillment.primary_recipient")}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        options={defaultEmails}
                                        value={value}
                                        isMulti={false}
                                        isClearable={true}
                                        isShowLabel={true}
                                        isLoading={isLoading}
                                        isDisabled={defaultEmails.length === 0}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-span-2">
                            <Controller
                                name="cc_recipient"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <EmailSelect
                                        label={t("app.fulfillment.cc_recipient")}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        regex={emailRegex}
                                        options={CcEmails}
                                        value={value}
                                        isMulti={true}
                                        isClearable={true}
                                        isShowLabel={true}
                                        isLoading={isLoading}
                                        isDisabled={CcEmails.length === 0}
                                        errors={error}
                                    />
                                )}
                            />

                        </div>

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoadingSubmit}
                        label={t("app.booking.send_request")}
                    />
                </div>
            </form>
        </FormPopup>
    );
}

// EMAIL LOG
const ViewEmailLog = ({ isOpen, setIsOpen, bookingDetail, logDetail }) => {

    const [isLoading, setLoading] = useState(false);
    const [logList, setLogList] = useState([]);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (logDetail && isOpen && bookingDetail) {
                setLoading(true);
                try {
                    const res = await getElement(getPathParam([BOOKING, bookingDetail.id, NEGOTIATION, logDetail.id, READ_EMAIL]), signal);
                    setLogList(res);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, logDetail, bookingDetail]);


    return (
        <FormPopup
            title={'Log'}
            isOpen={isOpen}
            isActiveFormPm={false}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <div className="space-y-2">
                <LoaderWrapper isLoading={isLoading}>

                    <ul className="divide-y divide-gray-100 overflow-y-scroll h-96">
                        {
                            logList.map((log) => (
                                <Disclosure key={log.id} defaultOpen={false} as={'li'} className={''}>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button as={'div'} className={classNames(
                                                open && 'bg-gray-50',
                                                'text-left  flex flex-1 flex-col  cursor-pointer hover:bg-gray-50'
                                            )}>
                                                <div className={classNames(
                                                    open && 'border-b border-gray-200',
                                                    "px-3 py-2"
                                                )}>
                                                    <span className="truncate text-sm font-medium text-gray-900">{log?.sender?.email ?? ''} - {log?.sender?.full_name ?? ''}</span>

                                                    <span className="flex mt-2 flex-col  max-w-md ">
                                                        <span className="truncate text-sm font-semibold text-gray-900">{log?.subject ?? ''}</span>
                                                        <span className="truncate overflow-hidden text-xs font-normal text-gray-700">{log?.bodyPreview ?? ''}</span>
                                                    </span>

                                                    <span className="flex items-center mt-3 font-normal text-sm text-gray-700">
                                                        <CalendarIcon className="h-4 w-4 mr-2" />
                                                        <span>{getFormattedDate(log.receivedDateTime)}</span>
                                                    </span>
                                                </div>

                                                <Disclosure.Panel as={'div'} className={' bg-gray-100'}>
                                                    <div className=" p-6  text-xs" dangerouslySetInnerHTML={{ __html: log?.body ?? '' }} />
                                                </Disclosure.Panel>
                                            </Disclosure.Button>

                                        </>
                                    )}
                                </Disclosure>
                            ))
                        }


                    </ul>
                </LoaderWrapper>
            </div>
        </FormPopup>
    );
}

const SendEmail = ({ responseData, selectedForwarders, isOpenSendMail, setOpenSendMail }) => {



    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);


    // eslint-disable-next-line
    const [isLoadingPreview, setLoadingPreview] = useState(false);
    const [emailContent, setEmailContent] = useState(false);
    const [emailContentData, setEmailContentData] = useState(false);
    const [showPreview, setShowPreview] = useState(false);



    const {
        // eslint-disable-next-line
        formState: { errors },
        handleSubmit,
        control,
        watch
    } = useForm();


    const onSubmit = async (data) => {
        if (loggedCompany) {
            let forworderData = [];
            // eslint-disable-next-line
            selectedForwarders.map(selectedForwarder => {
                forworderData.push({ id: selectedForwarder.id })
            })

            

            let dataSend = {
                negotiations: forworderData,
                company_email_template: { id: data.email_template.id }
            }

            if (emailContent !== emailContentData) {
                dataSend['custom_content'] = emailContent;
            }

            setLoading(true);
            setException(false);
            postElement(getPathParam([BOOKING, responseData.id, REPLAY_EMAIL]), dataSend)
                .then(response => {
                    console.log(response);
                    setOpenSendMail(false);
                }).catch(e => {
                    setException(e.message);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        async function fetchData() {
            if (loggedCompany && watch('email_template') && selectedForwarders.length === 1) {
                setShowPreview(true);
                
                let forwarder = selectedForwarders[0];

                let data = {
                    company_email_template: { id: watch('email_template').id },
                    type: responseData.type,
                    shipping_type: responseData.shipping_type.code,
                    forwarder: { id: forwarder.forwarder.id },
                    primary_contact: { id: forwarder.forwarder_contact.id },
                    booking_negotiation: { id: forwarder.id },
                }

                setLoading(true);
                try {
                    const res = await putElement(getPathParam([COMPANY, loggedCompany.id, EMAIL_PREVIEW]), data);
                    setEmailContent(res.content);
                    setEmailContentData(res.content);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [selectedForwarders, watch('email_template')]);




    return (
        <FormPopup
            title={t("app.forwarder.add_name")}
            defaultWith={'max-w-xl'}
            isActiveFormPm={true}
            isOpen={isOpenSendMail}
            setIsOpen={setOpenSendMail}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                    <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                        <Controller
                            name="email_template"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={t("app.company_preference.template")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, EMAIL_TEMPLATES]) + serialize({ category: 'booking' })}
                                    responseLabel={'templates'}
                                    label={'title'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={(r) => {
                                        onChange(r);
                                    }}
                                    isClearable={false}
                                    isRequired={true}
                                    isDisabled={false}

                                />
                            )}
                        />
                        <div className={"flex justify-between before:flex-1"}>
                            <a target="__blank" href={getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE])} className="mt-1 flex items-center text-xs text-blue-1000 underline cursor-pointer">
                                {t("app.settings.email_template_add")}
                            </a>
                        </div>
                    </div>


                    {
                        showPreview && (
                            <LoaderWrapper isLoading={isLoadingPreview}>
                                <ReactQuill
                                    theme="snow"
                                    value={emailContent}
                                    modules={{
                                        toolbar: [
                                            ["bold", "italic", "underline", "strike"],
                                            [{ align: [] }],
                                            [{ list: "ordered" }, { list: "bullet" }],
                                            [{ indent: "-1" }, { indent: "+1" }],
                                            [{ size: ["small", false, "large", "huge"] }],
                                            [{ color: [] }, { background: [] }]
                                        ],
                                        clipboard: {
                                            matchVisual: false
                                        }
                                    }}
                                    readOnly={false}
                                    bounds={'.app'}
                                    onChange={(content) => {
                                        setEmailContent(content);
                                    }}
                                />

                            </LoaderWrapper>
                        )
                    }






                    {/* Warning*/}
                    {exception && (
                        <Warning message={exception} />
                    )}

                    <div className="flex  items-center justify-end mt-6">
                        <InputSubmit
                            disabled={false}
                            isLoading={isLoading}
                            label={'add'}
                        />
                    </div>

                </div>
            </form>
        </FormPopup>
    );
}

