import React, {Fragment, useState} from "react";
import {Menu, Transition} from '@headlessui/react'
import {DotsVerticalIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import {getPathParam} from "../../../utils/converter";
import {useSelector} from "react-redux";
import {hasPermissionFor} from "../restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific} from "../../../constants/permissions";
import {STATUS_ARCHIVED, STATUS_CONFIRMED, STATUS_DISCONNECTED, STATUS_PENDING} from "../../../constants/config";
import {CANCEL_CONNECTION, COMPANY, CUSTOMER, RESTORE} from "../../../api/endpoints";
import RemovePopup from "../../common/popup/remove-popup";
import toast from "react-hot-toast";
import ConfirmPopup from "../../common/popup/confirm-popup";
import SendRequest from "../../../routes/customer/send-request";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ConnectionRequestNavigation = ({connection, canEdit, onUpdateData}) => {

    const { t } = useTranslation();
    const { permissions } = useSelector((s) => s.userCompany);

    const { loggedCompany } = useSelector((state) => state.user);



    // update customer
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const [isSendRequest, setSendRequest] = useState(false);






    const canRevoke = hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionSpecific.CUSTOMER_REVOKE);

    return (
        <>
        <Menu as="div" className="relative inline-block text-left ">
            <div>
                <Menu.Button>
                    <DotsVerticalIcon className="w-4 h-4 ml-4 text-gray-400 group-hover:text-gray-500" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">



                    {
                        (canEdit && connection.status === STATUS_DISCONNECTED) && (
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={(() => {
                                            setSendRequest(true);
                                        })}
                                        className={
                                            classNames(
                                                active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white',
                                                'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                            )}
                                    >
                                        {t("app.customers.send_request")}
                                    </div>
                                )}
                            </Menu.Item>
                        )
                    }

                    {
                        (canEdit && connection.status === STATUS_ARCHIVED) && (
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={(() => {
                                            const restoreDetail = {
                                                title: t("app.customers.restore_title"),
                                                message: t("app.customers.restore_description"),
                                                endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, connection.id, RESTORE])
                                            };
                                            setConfirmDetail(restoreDetail);
                                            setOpenConfirm(true);
                                        })}
                                        className={
                                            classNames(
                                                active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white',
                                                'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                            )}
                                    >
                                        {t("app.customers.restore")}
                                    </div>
                                )}
                            </Menu.Item>
                        )
                    }





                    {
                        (canRevoke && connection.status === STATUS_CONFIRMED) && (
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={(() => {
                                            const removeDetail = {
                                                title: t("app.customers.remove_title"),
                                                message: t("app.customers.remove_description"),
                                                endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, connection.id])
                                            };
                                            setRemoveDetail(removeDetail);
                                            setOpenRemove(true);
                                        })}
                                        className={
                                            classNames(
                                                active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white',
                                                'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                            )}
                                    >
                                        {t("app.customers.remove")}
                                    </div>
                                )}
                            </Menu.Item>
                        )
                    }

                    {
                        (canEdit && (connection.status === STATUS_PENDING || !connection.status)) && (
                            <>
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            onClick={(() => {
                                                setSendRequest(true)
                                            })}
                                            className={
                                                classNames(
                                                    active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                    'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white',
                                                    'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                                )}
                                        >
                                            {t("app.customers.send")}
                                        </div>
                                    )}
                                </Menu.Item>

                                {
                                    connection.status === STATUS_PENDING && (
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    onClick={(() => {
                                                        const confirmDetail = {
                                                            title: t("app.customers.cancel_request_title"),
                                                            message: t("app.customers.cancel_request_description"),
                                                            endpoint: getPathParam([COMPANY, loggedCompany.id, CUSTOMER, connection.id, CANCEL_CONNECTION])
                                                        };
                                                        setConfirmDetail(confirmDetail);
                                                        setOpenConfirm(true);

                                                    })}
                                                    className={
                                                        classNames(
                                                            active || false ? 'bg-indigo-800 text-solitude-200' : 'bg-solitude-200',
                                                            'border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white',
                                                            'group flex  items-center w-full p-3 text-sm cursor-pointer'
                                                        )}
                                                >
                                                    {t("app.customers.cancel_request")}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )
                                }
                            </>
                        )
                    }






            </Menu.Items>
        </Transition>




        </Menu>


            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(response) => {
                        toast.success(t("app.customers.update_success"));
                        onUpdateData(response);
                    }}
                />
            )}



            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={setOpenConfirm}
                    detail={confirmDetail}
                    setDetail={(response) => {
                        toast.success(t("app.customers.update_success"));
                        onUpdateData(response);
                    }}
                />
            )}



            {isSendRequest && (
                <SendRequest
                    isOpen={isSendRequest}
                    setIsOpen={setSendRequest}
                    email={connection?.notification_email ?? null}
                    isDisabled={connection.status === 'disconnected'}
                    onConfirm={(response) => {
                        onUpdateData(response);
                    }}
                />
            )}


        </>
    )
}

