import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import InputSubmit from "../../common/form/input-submit";

const EditFooter = ({ backLink, backLabel, btnLabel, isLoading , isDisabled = false }) => {
  return (
    <div className={"px-4 py-3 bg-solitude-100 text-right sm:px-6 flex items-center justify-between" + (backLink ? '' : ' before:flex-1') }>
        {
            backLink && (
                <Link
                    to={backLink}
                    className="text-sm underline inline-flex items-center"
                >
                    <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                    {backLabel}
                </Link>
            )
        }

        {
            isDisabled ? (
                <div />
            ) : (
                <InputSubmit isLoading={isLoading} label={btnLabel} />
            )
        }

    </div>
  );
};

export default EditFooter;
