import bg from '../images/20-hc.png';
import {useEffect, useState} from "react";

export default function Container20Hc({percentage}) {

    const baseWidth = 842;


    const [width, setWidth] = useState(0);
    const [color, setColor] = useState(0);

    useEffect( () => {
        setWidth(getWidth());
        setColor(getColorFromPercentage());
        // eslint-disable-next-line
    }, [percentage]);


    const getWidth = () => {
        return (baseWidth / 100) * (percentage > 100 ? 100 : percentage);
    };

    const getColorFromPercentage  = () => {
        let val = (percentage > 100 ? 100 : percentage) / 100;
        return getColor(val);
    };

    const getColor = (val) => {
        var hue = ((1 - val) * 120).toString(10);
        //console.log(hue);
        return ["hsl(", hue, ",50%,50%)"].join("");
    };

    return (

            <svg enableBackground="new 0 0 1260 582" viewBox="0 0 1260 582"  xmlns="http://www.w3.org/2000/svg">
                <image transform="translate(9.2776 14.181)" width="1260" height="582" opacity=".35" overflow="visible" href={bg}/>
                <rect x="373.3" y="71.6" style={{fill: color}} width={width} height="303"/>
                <g style={{opacity:5.000000e-02}}>
                    <pattern id="a" x="373.3" y="71.6" width="200" height="200" patternUnits="userSpaceOnUse">
                        <rect width="100" height="100"/>
                        <rect x="100" y="100" width="100" height="100"/>
                    </pattern>
                    <rect x="373.3" y="71.6" width={width} height="303" fill="url(#a)"/>
                </g>
                <text className={'fill-current text-6xl text-white uppercase font-bold'} transform="translate(690.3 240.84)">{percentage.toFixed(2)}%</text>
            </svg>
    );
}
