import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const DragDropList = ({header, groups, children, extras = {}, onUpdateGroup}) => {

    const Header = header;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        if (result.type === "droppableItem") {
            const itemsData = reorder(groups, sourceIndex, destIndex);


            onUpdateGroup(itemsData);
        } else if (result.type === "droppableSubItem") {
            const itemSubItemMap = groups.reduce((acc, item) => {
                acc[item.id] = item.container_sub_groups;
                return acc;
            }, {});


            const sourceParentId = result.source.droppableId;
            const destParentId = result.destination.droppableId;

            const sourceSubItems = itemSubItemMap[sourceParentId];
            const destSubItems = itemSubItemMap[destParentId];

            let newItems = [...groups];

            /** In this case subItems are reOrdered inside same Parent */
            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destIndex
                );
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.container_sub_groups = reorderedSubItems;
                    }
                    return item;
                });
                onUpdateGroup(newItems);
            } else {



                let newSourceSubItems = [...sourceSubItems];
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                let newDestSubItems = [...destSubItems];
                newDestSubItems.splice(destIndex, 0, draggedItem);
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.container_sub_groups = newSourceSubItems;
                    } else if (item.id === destParentId) {
                        item.container_sub_groups = newDestSubItems;
                    }
                    return item;
                });
                onUpdateGroup(newItems);
            }
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                    >
                        {groups.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                             <Header
                                                 item={item}
                                                 extras={extras}
                                                 dragHandle={provided.dragHandleProps}
                                             >
                                                {children}
                                            </Header>

                                            {/*
                                            <SubItemsDraw
                                                subItems={item.container_sub_groups}
                                                type={item.id}
                                            />
                                            */}
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
};
export default DragDropList;

// eslint-disable-next-line
{/*
const SubItemsDraw = ({type, subItems}) => {
    return (
        <Droppable droppableId={type} type={`droppableSubItem`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                >
                    {subItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div style={{ display: "flex" }}>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        {item?.group_name ?? '-'}
                                        <span
                                            {...provided.dragHandleProps}
                                        >
                                        Drag
                                      </span>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}
*/}



