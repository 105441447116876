import React, {Fragment, useEffect, useState} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {fetchAllUsers, fetchUsers} from "../../../reducers/connectionReducer";
import UserInitial from "../table/user-initial";
import SearchIcon from "../../../resources/icons/SearchIcon";



export default function InputUsersSelect({label = '', excludeId = '', isRequired = false, selectedItem = null, onSelect, onSelected, includeAll = true , setLoaded, selectedUsers = [], multiple = false}) {



    const { loggedCompany } = useSelector((state) => state.user);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const {users, isLoadingUsers} = useSelector((state) => state.connection);
    const [query, setQuery] = useState('');
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {

        if(includeAll){
            dispatch(fetchAllUsers({company_id: loggedCompany.id}));
        }else{
            dispatch(fetchUsers({company_id: loggedCompany.id}));
        }


        // eslint-disable-next-line
    }, [dispatch, loggedCompany, users]);



    useEffect(() => {
        if(!isLoadingUsers && users.length > 0 && selectedUsers.length > 0 && !selectedUsers[0].fullname){
            setLoading(true);
            // eslint-disable-next-line
            const fixedData =  selectedUsers.map(user => {
                let data = users.find(usersData => usersData.id === user.id);
                if(data){
                    return { ...user, fullname: data.fullname };
                }

            });
            onSelect(fixedData);
            setLoading(false);

        }

        // eslint-disable-next-line
    }, [isLoadingUsers, users]);





    const filteredData =
        query === ''
            ? users.filter(n => n.id !== excludeId)
            : users.filter(n => n.id !== excludeId).filter((item) =>
                item.fullname.toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    return (
        <div className="relative w-full">

            <label className="block text-sm font-medium mb-1 text-gray-700">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>


            {
                (isLoading || isLoadingUsers) ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            <Loader />
                        </div>
                    ) : (
                        <Combobox value={multiple ?  (users?.filter(n => n.id !== excludeId).filter(obj1 => selectedUsers.some(obj2 => obj1.id === obj2.id)) ?? []) : (selectedItem ? users.find(elem => elem.id === selectedItem) : null)}
                                  multiple={multiple}
                                  onChange={(r) => {
                                      onSelect(r);
                                  }}>

                            <div className="relative mt-1 ">



                                <Combobox.Button
                                    className={`form-input w-full text-left flex items-center`}
                                >
                        <span className="block truncate">{selectedUsers.length > 0  ? (selectedUsers ?? []).map(r => r.fullname).join(", ") : t("app.common.placeholder", {
                            field: label.toLowerCase(),
                        })}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                          />
                        </span>
                                </Combobox.Button>

                                {/*

                      <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm ">
                        <Combobox.Input
                            id={'test'}
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize"
                            placeholder={t("app.common.placeholder", {
                                field: label.toLowerCase(),
                            })}
                            displayValue={(item) => multiple ?   : (item?.fullname ?? '')}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>






                    </div>

                    */}



                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQuery('')}
                                >
                                    <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">





                                        {
                                            (isLoadingUsers) ? (
                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <>

                                                    <div className={"relative   w-full sm:w-auto"}>
                                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <SearchIcon className="h-5 w-5 text-blue-1000" aria-hidden="true" />
                                                        </div>
                                                        <input
                                                            type="search"
                                                            name="search"
                                                            id="search"
                                                            autoComplete={"off"}
                                                            className="form-search block w-full pl-10"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setQuery(e.target.value);
                                                            }}
                                                            defaultValue={query}
                                                        />
                                                    </div>


                                                    {filteredData.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredData.map((item) => (

                                                            <Combobox.Option
                                                                key={item.id}
                                                                className={({ selected, active }) =>
                                                                    `relative cursor-pointer select-none py-2 px-4 ${
                                                                        (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={item}
                                                            >
                                                                {({ selected, active }) => (

                                                                    <>
                                                                        <div className="flex items-center">
                                                                            <div className="flex-shrink-0">
                                                                                <UserInitial user={item} />
                                                                            </div>
                                                                            <div className="min-w-0 flex-1 ml-3">
                                                                                <div className="focus:outline-none">
                                                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                                                    <p className="text-sm font-medium text-gray-900">{item.fullname}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </>
                                            )
                                        }


                                    </Combobox.Options>
                                </Transition>
                            </div>
                        </Combobox>
                    )
            }


        </div>
    )
}
