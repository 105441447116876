import { Popover, Transition } from '@headlessui/react'
import React, {Fragment, useEffect} from 'react'
import {PlusIcon, TagIcon} from "@heroicons/react/outline";
import {Controller, useForm} from "react-hook-form";
import InputFormattedNumber from "../form/input-formatted-number";
import {ImportButton} from "../../layout/page-header";
import {useTranslation} from "react-i18next";
import {TrashIcon} from "@heroicons/react/outline/esm";

export default function DiscountEditor({discounts, element, onAddDiscount, onUpdateDiscount, label = false, boxClass = 'ml-3', btnClass = '', fixedPosition = true, hover = 'bg-green-600 hover:bg-green-700'}) {

    const {control, watch,  setValue} = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        // eslint-disable-next-line
        discounts.map((item, index) => {
            setValue("discount-" + item?.id ?? index, parseFloat(item?.price ?? item));
        })

        // eslint-disable-next-line
    }, [setValue])

    return (
        <Popover className={boxClass}>
            {({ open }) => (
                <>
                    <Popover.Button
                        className={"btn  text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none "+hover+"  "+btnClass}
                    >

                        <TagIcon
                            className="w-4 h-4 flex-shrink-0"
                            aria-hidden="true"
                        />
                        {
                            label && (
                                <div className="ml-3">{label}</div>
                            )
                        }
                    </Popover.Button>




                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className={fixedPosition ? "absolute  z-10 mt-3   transform px-4 sm:px-0" : "  z-10 mt-3    transform px-4 sm:px-0"}>
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative  bg-white p-2">

                                    {
                                        discounts.length > 0 && (
                                            <label className="block text-center text-sm font-medium mb-1 text-gray-700">
                                                {t("app.quotations.discounts")}
                                            </label>
                                        )
                                    }



                                    <div className="justify-items-center items-center text-center" >

                                    {
                                        discounts.map((data, index) => (
                                            <Fragment key={data.id}>
                                                <div className="w-full mb-2">
                                                    <div className="flex items-center ">

                                                        <Controller
                                                            name={"discount-" + data.id}
                                                            rules={{ required: true }}
                                                            control={control}
                                                            render={({ field: { onChange, value, name }, fieldState:{error}  }) => (
                                                                <InputFormattedNumber
                                                                    isFullW={true}
                                                                    label={t("app.quotations.discount")}
                                                                    isShowLabel={false}
                                                                    name={name}
                                                                    value={value}
                                                                    decimalScale={4}
                                                                    onChange={(n) => {
                                                                        //let e = n > 100 ? 100 : n;
                                                                        onChange(n);
                                                                        onUpdateDiscount(element.id,n,index);
                                                                    }}
                                                                    prefix={''}
                                                                    suffix={' %'}
                                                                    errors={error}
                                                                />
                                                            )}
                                                        />

                                                        <button
                                                            onClick={() => {
                                                                setValue("discount-" + data.id, undefined);
                                                                onUpdateDiscount(element.id,false,index);
                                                            }}
                                                            disabled={!watch("discount-" + data.id)}
                                                            className={
                                                                "ml-2 btn bg-red-500 hover:bg-red-700 disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
                                                            }
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4 text-white"
                                                                aria-hidden="true"
                                                            />
                                                        </button>

                                                    </div>
                                                </div>

                                                {
                                                    (index+1) !== discounts.length && (
                                                        <b className={'mx-4'}>{'+'}</b>
                                                    )
                                                }



                                            </Fragment>
                                        ))
                                    }


                                        <ImportButton
                                            disabled={!(discounts.length === 0 || (discounts.length > 0 && (discounts[(discounts.length-1)] !== undefined) && (discounts[(discounts.length-1)] !== null ) ))}
                                            extraClass={'w-full'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onAddDiscount(element.id);
                                            }}
                                            icon={
                                                <PlusIcon
                                                    className="w-4 h-4 fill-current flex-shrink-0"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t("app.quotations.add_discount")}
                                        />


                                    </div>




                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

