import React, { useEffect, useState } from "react";
import UnderlineTab from "../../../../components/partials/Tabs/underline-tab";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../../components/common/form/input-select";
import InputText from "../../../../components/common/form/input-text";
import InputDate from "../../../../components/common/form/input-date";
import {
  fetchDeliveryTypes,
  fetchTermTypes,
  fetchShippingLines, fetchCountries, fetchVessels, fetchPorts,
} from "../../../../reducers/configReducer";
import EditFooter from "../../../../components/partials/footer/edit-footer";
import {LOGISTICS, SHIPPINGS} from "../../../endpoints";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/common/loader/loader";
import {
  cleanData,
  getSelectParam,
  getSelectParams, getVesselParam,
} from "../../../../utils/converter";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import {putShipping, putShippingImporter} from "../../../../api/shipping";
import { Warning } from "../../../../components/common/alert/banner";
import AddForwarder from "../../../forwarder/add-forwarder";
import { getForwarders } from "../../../../api/forwarder";
import {  useOutletContext } from "react-router-dom";
import InputTextarea from "../../../../components/common/form/input-textarea";
import InputCheckbox from "../../../../components/common/form/input-checkbox";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";
import {
  getPermission,
  permissionGroup,
  permissionSpecific,
  permissionType,
  sp
} from "../../../../constants/permissions";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import InputToggle from "../../../../components/common/form/input-toggle";
import {AccordionBtnAdd} from "../../../../components/common/accordion/accordionAdd";
import SubscriptionWrapper from "../../../../components/partials/restricted/subscription-wrapper";
import moment from "moment";
import {SEA, STATUS_CONFIRMED} from "../../../../constants/config";
import InputPortSelect from "../../../../components/common/form/input-port-select";
import InputVesselSelect from "../../../../components/common/form/input-vessel-select";
import InputShippingLineSelect from "../../../../components/common/form/input-shipping-line-select";
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import CitiesSearch from "../../../../components/common/form/cities-search";

const ShippingDetail = () => {
  const { loggedCompany, isExporter } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.userCompany);

  const {
    deliveryTypesList,
    isDeliveryTypesListLoading,
    termTypesList,
    isTermTypesListLoading,
    shippingLinesList,
    isShippingLinesListLoading,
  } = useSelector((state) => state.config);
  const [activeGroup, setActiveGroup] = useState("general-information");
  const [isOpen, setIsOpen] = useState(false);
  const [newForwarder, setNewForwarder] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();
  const { t } = useTranslation();
  const { isCountriesLoading, countries, vesselList, isVesselsLoading, isPortsLoading, portList } = useSelector((state) => state.config);


  // get Shipping
  const { shippingDetail, setShippingDetail, canEdit, isSeaType, isOwner } = useOutletContext();

  const [isShippingDetailLoading] = useState(false);
  const [detail] = useState(shippingDetail);
  const [customers, setCustomers] = useState([]);
  const { permissions } = useSelector((s) => s.userCompany);

  const forwarderView = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.VIEW);
  const forwarderAdd = hasPermissionFor(permissions, permissionGroup.COMPANY_FORWARDER, permissionType.CREATE);


  // get Shipping
  const [isForwarderLoading, setForwarderLoading] = useState(true);
  const [forwarderList, setForwarderList] = useState({
    forwarders: [],
    total_rows: 0,
  });

  const tabs = [
    { name: t("app.shippings.general_information"), group: "general-information" },
    { name: t("app.shippings.delivery_information"), group: "delivery-information" },
    { name: t("app.shippings.shipping_information"), group: "shipping-information" },
  ];


  // edit shipping
  const [isEditShippingLoading, setEditShippingLoading] = useState(false);
  const [editShippingException, setEditShippingException] = useState(false);

  // Confirm Modal
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmDetail, setConfirmDetail] = useState({});
  const [tmpData, setTmpData] = useState(false);

  const updateShipping = async (data) => {
    if (loggedCompany) {
      data["id"] = id;
      setEditShippingLoading(true);
      (isExporter ? putShipping({ company_id: loggedCompany.id, shipping_id: id, data }) :
      putShippingImporter({ company_id: loggedCompany.id, shipping_id: id, data }))
        .then((response) => {
          setShippingDetail(response);
          setEditShippingLoading(false);
          toast.success(t("app.shippings.update_success"))
          setEditShippingException(false);
        })
        .catch((e) => {
          setEditShippingException(e.message);
          setEditShippingLoading(false);
        });
    }
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch(fetchDeliveryTypes());
    dispatch(fetchTermTypes());
    dispatch(fetchShippingLines());
    dispatch(fetchCountries());
    dispatch(fetchVessels());
    dispatch(fetchPorts());


    if(detail !== null){
      setCustomers([getSelectParam(detail.customer, "business_name")], {
        shouldDirty: true,
      });
      for (const k in detail) {
        if (typeof detail[k] === "object") {
          if (k === "customer") {
            setValue(k, getSelectParam(detail[k], "business_name"));
          } else if (k === "vessel" || k === "departure_port" || k === "arrival_port") {
            setValue(k, getVesselParam(detail[k]));
          } else {
            setValue(k, getSelectParam(detail[k], "name"));
          }
        } else {
          setValue(k, detail[k]);
        }
      }
    }


    function fetchForwarder() {
      if (loggedCompany && isOpen === false) {
        setForwarderLoading(true);
        getForwarders({ company_id: loggedCompany.id, params: null, signal })
          .then((response) => {
            setForwarderList(getSelectParams(response.forwarders, "name"));
            if(newForwarder){
              setValue("forwarder", newForwarder);
            }
            setForwarderLoading(false);
            setNewForwarder(false);
          })
          .catch((e) => {
            setForwarderLoading(false);
          });
      }
    }
    //fetchShipping();
    fetchForwarder();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, loggedCompany, id, setValue, isOpen, detail]);

  const onSubmit = async (data) => {
    cleanData(data);
    if((data['arrival_date'] !== shippingDetail.arrival_date) && (shippingDetail?.events?? []).find(r => r.event_type.code === 'ship_left')){
      const confirmData = {
        title: t("app.shippings.arrival_date_title"),
        message: t("app.shippings.arrival_date_description")
      };

      setTmpData(data);
      setConfirmDetail(confirmData);
      setOpenConfirm(true);
    }else {
      await updateShipping(data);
    }
  };

  return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.EDIT)]} renderBaseOnFail={true} redirectWrongCompanyType={true}>

          <UnderlineTab
            marginTop={''}
            tabs={tabs}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shadow  sm:overflow-hidden">

              {activeGroup === "general-information" && (
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                    <fieldset>
                    <div className="space-y-4">
                      {isShippingDetailLoading ? (
                        <div className="mt-6">
                          <div className="w-1/2 m-auto px-4 py-3">
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">
                            <Controller
                              name="customer"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <InputSelect
                                  label={isExporter ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                  name={name}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  options={customers}
                                  value={value}
                                  isLoading={false}
                                  isDisabled={true}
                                  errors={error}
                                />
                              )}
                            />

                            <InputText
                              id={"tracking"}
                              type={"text"}
                              errors={errors.tracking}
                              input={{
                                ...register("tracking", { required: true }),
                              }}
                              label={t("app.shippings.tracking")}
                            />

                            {
                              isExporter && (
                                    <div className="col-span-2">

                                      <InputText
                                          id={"customer_note"}
                                          type={"text"}
                                          errors={errors.customer_note}
                                          input={{ ...register("customer_note", { required: false }) }}
                                          label={t("app.quotations.customer_note")}
                                      />
                                    </div>
                                )
                            }


                            <InputText
                              name={isExporter ? "mrn_code" : "mrn_importer"}
                              type={"text"}
                              errors={errors.mrn_code}
                              input={{
                                ...register(isExporter ? "mrn_code" : "mrn_importer", { required: false }),
                              }}
                              label={t("app.shippings.mrn_code")}
                            />

                            <Controller
                              name={isExporter ? "shipping_cost" : "shipping_cost_importer"}
                              rules={{ required: false }}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                              }) => (
                                <InputFormattedNumber
                                  label={t("app.shippings.shipping_cost")}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  prefix={""}
                                  suffix={" " + (company.currency?.currency_symbol ?? '')}
                                  errors={error}
                                />
                              )}
                            />

                            <Controller
                                name={isExporter ? "turnover" : "turnover_importer"}
                                rules={{ required: false }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputFormattedNumber
                                        label={t("app.shippings.shipping_turnover")}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                        prefix={""}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name={isExporter ? "turnover_date" : "turnover_date_importer"}
                                rules={{ required: false }}
                                control={control}
                                render={({
                                           field: { onChange, value, name },
                                           fieldState: { error },
                                         }) => (
                                    <InputDate
                                        label={t("app.shippings.shipping_turnover_date")}
                                        format={"YYYY-MM-D"}
                                        placeholder={"Es. 01/12/2021"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        isShowTime={false}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                    />
                                )}
                            />

                            <InputText
                              id={"bill_of_lading"}
                              type={"text"}
                              errors={errors.bill_of_lading}
                              input={{
                                ...register("bill_of_lading", { required: false }),
                              }}
                              label={t("app.shippings.bill_of_lading")}
                            />

                            <InputText
                                name={isExporter ? "exporter_reference" : "importer_reference"}
                                type={"text"}
                                errors={isExporter ? errors.exporter_reference : errors.importer_reference}
                                input={{
                                  ...register(isExporter ? "exporter_reference" : "importer_reference", { required: false }),
                                }}
                                label={t("app.shippings.company_reference")}
                            />

                            <SubscriptionWrapper requiredPermission={sp.FORWARDER_INTERNAL_PACKAGE} renderBaseOnFail={false}>

                              {
                                    ((canEdit || forwarderView) && isOwner) && (
                                        <>
                                          <div className="sm:flex sm:w-full">
                                                <div className="w-full">
                                                  <Controller
                                                      name="forwarder"
                                                      rules={{ required: false }}
                                                      control={control}
                                                      render={({
                                                                 field: { onChange, value, name },
                                                                 fieldState: { error },
                                                               }) => (
                                                          <InputSelect
                                                              label={t("app.shippings.forwarders")}
                                                              name={name}
                                                              options={forwarderList}
                                                              value={value}
                                                              onChange={(e) => {
                                                                onChange(e);
                                                              }}
                                                              isLoading={isForwarderLoading}
                                                              isDisabled={isForwarderLoading}
                                                              errors={error}
                                                          />
                                                      )}
                                                  />
                                                </div>
                                                {
                                                  (forwarderAdd && isOwner) && (
                                                        <AccordionBtnAdd
                                                            onOpen={(r) => {
                                                              setIsOpen(true);
                                                            }}
                                                        />
                                                    )
                                                }
                                          </div>

                                          <InputText
                                              name={"forwarder_reference"}
                                              type={"text"}
                                              errors={errors.forwarder_reference}
                                              input={{
                                                ...register("forwarder_reference", { required: false }),
                                              }}
                                              label={t("app.shippings.forwarder_reference")}
                                          />
                                        </>
                                    )
                                }
                            </SubscriptionWrapper>

                          </div>



                          {
                            !isExporter  && (
                                  <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_CLOSE)]}>
                                    <div className="col-span-2 pr-6 mt-2">
                                      <Controller
                                          name={isExporter ? "shipping_closed" : "shipping_closed_importer"}
                                          rules={{ required: false }}
                                          control={control}
                                          render={({
                                                     field: { onChange, value, name },
                                                     fieldState: { error },
                                                   }) => (
                                              <InputToggle
                                                  label={t("app.shippings.confirm_title")}
                                                  description={t("app.shippings.confirm_description")}
                                                  onChange={(e) => {
                                                    onChange(e)
                                                  }}
                                                  defaultChecked={value}
                                                  errors={error}
                                              />
                                          )}
                                      />
                                    </div>
                                  </BasePermissionWrapper>
                              )
                          }




                          <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_CUSTOMER_EDIT)]}>
                            {
                              (canEdit && (detail.customer.status === STATUS_CONFIRMED)) && (
                                    <div className="col-span-2 pr-6">
                                        <Controller
                                            name="can_edit"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                      field: { onChange, value, name },
                                                      fieldState: { error },
                                                    }) => (
                                                <InputToggle
                                                    label={t("app.shippings.can_edit_title")}
                                                    description={t("app.shippings.can_edit_description", {type: isExporter ?  t("app.customers.customer") : t("app.supplier.supplier")})}
                                                    onChange={(e) => {
                                                      onChange(e)
                                                    }}
                                                    defaultChecked={value}
                                                    errors={error}
                                                />
                                            )}
                                        />
                                    </div>
                                )
                            }
                          </BasePermissionWrapper>



                        </>
                      )}

                      {/* Warning */}
                      {editShippingException && (
                        <Warning message={editShippingException} />
                      )}
                    </div>
                  </fieldset>
                </div>
              )}

              {activeGroup === "delivery-information" && (
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <fieldset>
                    <div className="space-y-4">
                      {isShippingDetailLoading ? (
                        <div className="mt-6">
                          <div className="w-1/2 m-auto px-4 py-3">
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">




                          {
                              isSeaType && (

                                  <>

                                    <InputShippingLineSelect
                                        label={t("app.shippings.shipping_line")}
                                        placeholder={t("app.shippings.shipping_line")}
                                        disabled={detail.track_shipping === true}
                                        isLoadingData={isShippingLinesListLoading}
                                        data={(watch('shipping_line') && !shippingLinesList.some(vessel => vessel.id === watch('shipping_line').id)) ? [...shippingLinesList, watch('shipping_line')] : shippingLinesList}
                                        selectedItem={watch('shipping_line')}
                                        onSelect={ (e) => {
                                          setValue('shipping_line', e)
                                        }}
                                    />



                                    <InputVesselSelect
                                        label={t("app.shippings.vessel_name")}
                                        placeholder={t("app.shippings.search_vessel")}
                                        isLoadingData={isVesselsLoading}
                                        data={(watch('vessel') && !vesselList.some(vessel => vessel.id === watch('vessel').id)) ? [...vesselList, watch('vessel')] : vesselList}
                                        selectedItem={watch('vessel')}
                                        onSelect={ (e) => {
                                          setValue('vessel', e)
                                        }}
                                    />

                                    <InputPortSelect
                                        label={t("app.shippings.departure_port")}
                                        placeholder={t("app.shippings.departure_port")}
                                        isLoadingData={isPortsLoading}
                                        data={(watch('departure_port') && !portList.some(vessel => vessel.id === watch('departure_port').id)) ? [...portList, watch('departure_port')] : portList}
                                        selectedItem={watch('departure_port')}
                                        onSelect={ (e) => {
                                          setValue('departure_port', e)
                                        }}
                                    />


                                    <InputPortSelect
                                        label={t("app.shippings.arrival_port")}
                                        placeholder={t("app.shippings.arrival_port")}
                                        isLoadingData={isPortsLoading}
                                        data={(watch('arrival_port') && !portList.some(vessel => vessel.id === watch('arrival_port').id)) ? [...portList, watch('arrival_port')] : portList}
                                        selectedItem={watch('arrival_port')}
                                        onSelect={ (e) => {
                                          setValue('arrival_port', e)
                                        }}
                                    />

                                    <Controller
                                        name="delivery_type"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({
                                                   field: { onChange, value, name },
                                                   fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={t("app.shippings.delivery_type")}
                                                name={name}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                                options={deliveryTypesList.filter(r => r.type_code === SEA)}
                                                value={value}
                                                isLoading={isDeliveryTypesListLoading}
                                                isDisabled={isDeliveryTypesListLoading}
                                                errors={error}
                                            />
                                        )}
                                    />

                                  </>
                              )
                          }

                          {
                              !isSeaType && (

                                  <>
                                    <Controller
                                        name="departure_country"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({
                                                   field: { onChange, value, name },
                                                   fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={t("app.shippings.departure_country")}
                                                name={name}
                                                options={countries}
                                                value={value}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                                isLoading={isCountriesLoading}
                                                isDisabled={isCountriesLoading}
                                                errors={error}
                                            />
                                        )}
                                    />


                                    <CitiesSearch
                                      label={t("app.shippings.departure_city")}
                                      placeholder={t("app.shippings.departure_city")}
                                      isRequired={true}
                                      selectedItem={watch('departure_city') ?? null}
                                      countryCode={watch('departure_country')?.code ?? null}
                                      isDisabled={false}
                                      onSelect={(e) => {
                                        setValue('departure_city', e)
                                      }}
                                    />


                                    <Controller
                                        name="arrival_country"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({
                                                   field: { onChange, value, name },
                                                   fieldState: { error },
                                                 }) => (
                                            <InputSelect
                                                label={t("app.shippings.arrival_country")}
                                                name={name}
                                                options={countries}
                                                value={value}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                                isLoading={isCountriesLoading}
                                                isDisabled={isCountriesLoading}
                                                errors={error}
                                            />
                                        )}
                                    />


                                    <CitiesSearch
                                      label={t("app.shippings.arrival_city")}
                                      placeholder={t("app.shippings.arrival_city")}
                                      isRequired={true}
                                      selectedItem={watch('arrival_city') ?? null}
                                      countryCode={watch('arrival_country')?.code ?? null}
                                      isDisabled={false}
                                      onSelect={(e) => {
                                        setValue('arrival_city', e)
                                      }}
                                    />

                                  </>
                              )
                          }



                          <Controller
                              name="departure_date"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.shippings.departure_date")}
                                      format={"YYYY-MM-D HH:mm:ss"}
                                      placeholder={"Es. 01/12/2021"}
                                      activeMinDate={false}
                                      startDate={value}
                                      disabled={false}
                                      isShowTime={false}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                        setValue('arrival_date', e);
                                        onChange(e);
                                      }}
                                  />
                              )}
                          />

                          <Controller
                              name="arrival_date"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                       }) => (
                                  <InputDate
                                      label={t("app.shippings.arrival_date")}
                                      format={"YYYY-MM-D"}
                                      placeholder={"Es. 01/12/2021"}
                                      activeMinDate={true}
                                      selectsEnd={true}
                                      minDate={new Date(moment(watch('departure_date')).format('MM/DD/YYYY'))}
                                      startDate={value}
                                      disabled={!watch('departure_date')}
                                      isShowTime={false}
                                      errors={error}
                                      name={name}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                  />
                              )}
                          />


                          <Controller
                              name="terms_type"
                              rules={{ required: true }}
                              control={control}
                              render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                       }) => (
                                  <InputSelect
                                      label={t("app.shippings.terms_type")}
                                      name={name}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      options={termTypesList}
                                      value={value}
                                      isLoading={isTermTypesListLoading}
                                      isDisabled={isTermTypesListLoading}
                                      errors={error}
                                  />
                              )}
                          />


                          <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionSpecific.SHIPPING_ACTIVE_TRACKING)]}>
                              {
                                isSeaType && (shippingDetail?.shipping_line?.traceable ?? false) && (
                                      <div className="col-span-2 pr-6 mt-2">
                                        <Controller
                                            name={"track_shipping"}
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                       field: { onChange, value, name },
                                                       fieldState: { error },
                                                     }) => (
                                                <InputToggle
                                                    label={t("app.shipping_tracking.tracking_title")}
                                                    description={t("app.shipping_tracking.tracking_description")}
                                                    onChange={(e) => {
                                                      onChange(e)
                                                    }}
                                                    isDisabled={detail.confirmed_containers === false || detail.track_shipping === true}
                                                    defaultChecked={value}
                                                    errors={error}
                                                />


                                            )}
                                        />


                                        {
                                          !detail.confirmed_containers && (
                                                <p className="text-sm text-red-600">{t("app.shippings.all_container_loaded")}</p>
                                            )
                                        }





                                      </div>
                                  )
                              }
                          </BasePermissionWrapper>






                        </div>
                      )}

                      {/* Warning */}
                      {editShippingException && (
                        <Warning message={editShippingException} />
                      )}
                    </div>
                  </fieldset>
                </div>
              )}

              {activeGroup === "shipping-information" && (
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <fieldset>
                    <div className="space-y-4">
                      {isShippingDetailLoading ? (
                        <div className="mt-6">
                          <div className="w-1/2 m-auto px-4 py-3">
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">
                            <InputTextarea
                              id={"shipper"}
                              errors={errors.shipper}
                              input={{
                                ...register("shipper", { required: false }),
                              }}
                              label={t("app.shippings.shipper")}
                            />

                            <InputTextarea
                              id={"consignee"}
                              errors={errors.shipper}
                              input={{
                                ...register("consignee", { required: false }),
                              }}
                              label={t("app.shippings.consignee")}
                            />

                            <InputTextarea
                              id={"notify_party"}
                              errors={errors.notify_party}
                              input={{
                                ...register("notify_party", { required: false }),
                              }}
                              label={t("app.shippings.notify_party")}
                            />

                            <InputTextarea
                              id={"description"}
                              errors={errors.description}
                              input={{
                                ...register("description", { required: false }),
                              }}
                              label={t("app.shippings.Note")}
                            />
                          </div>

                          <div className="grid grid-cols-3 gap-4">
                            <div className={"col-span-2"}>
                              <InputCheckbox
                                label={t("app.shippings.triangulation")}
                                description={
                                  t("app.shippings.triangulation_description")
                                }
                                id={"active_triangulation"}
                                input={{
                                  ...register(isExporter ? "active_triangulation" : "active_triangulation_importer", {
                                    required: false,
                                  }),
                                }}
                              />
                            </div>
                          </div>

                          <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-6 sm:grid-cols-1">
                            {watch(isExporter ? "active_triangulation" : "active_triangulation_importer") && (
                              <>

                                {
                                  isExporter && (
                                        <div className="col-span-2">
                                          <InputTextarea
                                              id={"triangulation_note"}
                                              errors={errors.triangulation_note}
                                              input={{
                                                ...register("triangulation_note", { required: false }),
                                              }}
                                              label={t("app.shippings.Note")}
                                          />
                                        </div>
                                  )
                                }




                                <InputText
                                  id={"base_company"}
                                  type={"text"}
                                  errors={errors.base_company}
                                  input={{
                                    ...register(isExporter ? "base_company" : "base_company_importer", {
                                      required: false,
                                    }),
                                  }}
                                  label={t("app.shippings.base_company")}
                                />

                                <InputText
                                  id={"foreign_company"}
                                  type={"text"}
                                  errors={errors.foreign_company}
                                  input={{
                                    ...register(isExporter ? "foreign_company" : "foreign_company_importer", {
                                      required: false,
                                    }),
                                  }}
                                  label={t("app.shippings.foreign_company")}
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {/* Warning */}
                      {editShippingException && (
                        <Warning message={editShippingException} />
                      )}
                    </div>
                  </fieldset>
                </div>
              )}

              {!isShippingDetailLoading && (
                <EditFooter
                  backLink={LOGISTICS + SHIPPINGS}
                  backLabel={t("app.shippings.back")}
                  btnLabel={t("app.shippings.save")}
                  isLoading={isEditShippingLoading}
                />
              )}
            </div>
          </form>

          {isOpen && (
            <AddForwarder
              isOpen={isOpen}
              setIsOpen={(r) => {
                setIsOpen(r);
              }}
              isNavigateActive={false}
              onAdded={(r) => {
                setNewForwarder(getSelectParam(r, "name"));
              }}
            />
          )}


          {isOpenConfirm && canEdit && (
              <ConfirmPopup
                  isOpen={isOpenConfirm}
                  setIsOpen={() => {
                    setOpenConfirm(false);
                  }}
                  detail={confirmDetail}
                  isJustConfirm={true}
                  onConfirmed={(response) => {
                    tmpData['change_date'] = true;
                    updateShipping(tmpData);
                  }}
                  onCancel={(response) => {
                    updateShipping(tmpData);
                  }}
              />
          )}

        </BasePermissionWrapper>
  );
};

export default ShippingDetail;
