import React, {useEffect} from "react";
import BaseList from "../../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../../../utils/converter";
import {
    COMPANY,
    COMPANY_SUPPLIERS,
    PRODUCT,
    SUPPLIERS,
    SUPPLIER
} from "../../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../../components/common/table/actions-menu";
import CardDetail from "../../../../../components/common/list/card-detail";
import TableDetail from "../../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../../components/common/form/input-text";
import {Warning} from "../../../../../components/common/alert/banner";
import InputSubmit from "../../../../../components/common/form/input-submit";
import {fetchCountries} from "../../../../../reducers/configReducer";
import {useOutletContext, useParams} from "react-router-dom";
import { QrcodeIcon} from "@heroicons/react/outline";
import DynamicSearch from "../../../../../components/partials/common/dynamic-search";
import BasePermissionWrapper, {hasPermissionFor} from "../../../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../../../constants/permissions";

const ProductSupplier = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { id } = useParams();
    const { setNeedRefresh } = useOutletContext();

    const { permissions } = useSelector((s) => s.userCompany);

    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.VIEW);


    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_SUPPLIER, permissionType.LIST)]} renderBaseOnFail={true}>
            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.catalog.supplier"),
                            t("app.catalog.catalog_code"),
                            "",
                        ]}
                        endpoint={getPathParam([PRODUCT, id, SUPPLIERS])}
                        storeEndpoint={getPathParam([PRODUCT, id, SUPPLIER])}
                        updateEndpoint={getPathParam([PRODUCT, id, SUPPLIER, ':id'])}
                        baseResult={{total_rows: 0, suppliers: []}}
                        resultLabel={'suppliers'}
                        title={t("app.catalog.supplier")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={QrcodeIcon}
                        addFormLabel={t("app.catalog.add", {type: t("app.catalog.supplier")})}
                        btnAddLabel={canCreate ? t("app.catalog.add", {type: t("app.catalog.supplier")}) : false}
                        addPermission={[getPermission(permissionGroup.PRODUCT_SUPPLIER, permissionType.CREATE)]}
                        editForm={EditForm}
                        editTitle={t("app.catalog.details")}
                        isActiveEdit={canView || canEdit}
                        onUpdateParent={(r) => {
                            setNeedRefresh(r);
                        }}
                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.VIEW);


    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, SUPPLIER, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.connection_supplier.name}
                    {
                        (canView || canEdit || canDelete) && (
                            <ActionsMenu>
                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onEdit(true, element);
                                        }}
                                        >
                                            {t("app.common.edit")}
                                        </button>
                                    )
                                }
                                {
                                    canDelete && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>
                                    )
                                }
                            </ActionsMenu>
                        )
                    }

                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.catalog_code")}>
                        {element?.supplier_reference ?? '-'}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    let { id } = useParams();
    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.VIEW);


    const removeDetail = {
        title: t("app.catalog.remove_title"),
        message: t("app.catalog.remove_description"),
        endpoint: getPathParam([PRODUCT, id, SUPPLIER, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.connection_supplier.name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element?.supplier_reference ?? '-'}
            </TableDetail>

            <TableDetail>
                {
                    (canView || canEdit || canDelete) && (
                        <ActionsMenu>
                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )
                            }
                            {
                                canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )
                            }
                        </ActionsMenu>
                    )
                }
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const { user } = useSelector((state) => state);
    const { loggedCompany } = user;


    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();


    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        onSubmitData(data);
    };

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);




    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <Controller
                        name="connection_supplier"
                        rules={{ required: true }}
                        control={control}
                        render={({
                                     field: { onChange, value, name },
                                     fieldState: { error },
                                 }) => (
                            <DynamicSearch
                                selectLabel={t("app.catalog.supplier")}
                                endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_SUPPLIERS])}
                                responseLabel={'company_suppliers'}
                                label={'name'}
                                errors={error}
                                value={value}
                                name={name}
                                onChange={onChange}
                            />
                        )}
                    />

                    <InputText
                        id={"supplier_reference"}
                        type={"text"}
                        errors={errors.supplier_reference}
                        input={{ ...register("supplier_reference", { required: true }) }}
                        label={t("app.catalog.catalog_code")}
                    />
                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.catalog.add")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {
    const { user } = useSelector((state) => state);
    const { loggedCompany } = user;
    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.EDIT);


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object") {
                    setValue(k, getSelectParam(data[k], "name"));
                }else{
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };


    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="space-y-4 pt-4 pb-5">

                        <Controller
                            name="connection_supplier"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <DynamicSearch
                                    selectLabel={t("app.catalog.supplier")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_SUPPLIERS])}
                                    responseLabel={'company_suppliers'}
                                    label={'name'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    isDisabled={!canEdit}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <InputText
                            id={"supplier_reference"}
                            type={"text"}
                            errors={errors.supplier_reference}
                            input={{ ...register("supplier_reference", { required: true }) }}
                            label={t("app.catalog.catalog_code")}
                            isDisabled={!canEdit}

                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    {
                        canEdit && (
                            <InputSubmit
                                isLoading={isEditLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        )
                    }
                </div>
            </form>

        </>
    );
}

export default ProductSupplier;
