// @flow
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Column from "./board-columns";
import reorder, { reorderQuoteMap } from "./reorder";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { putElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { BOARD, STATUS_POSITION, TASK_POSITION } from "../../routes/endpoints";
import LoaderWrapper from "../common/loader/loader-wrapper";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Container = React.forwardRef(({children, ...props }, ref) => (
    <div
        ref={ref}
        className="overflow-x-auto overflow-y-auto  absolute select-none whitespace-nowrap mb-2 pt-0.5 pb-2 -top-0.5 bottom-0 inset-x-0 flex flex-row px-1.5 py-0"
        {...props}
    >
        {children}
    </div>
));

const BoardList = ({
    onAddTask,
    boardData,
    statuses,
    isLoadingStatuses,
    isLoadingTaskes,
    taskes,
    onEditTask,
    isAdmin = false,
    isMember = false
}) => {


    const getStatusTasks = () =>
        statuses.reduce(
            (previous, status) => ({
                ...previous,
                [status.id]: taskes?.filter(r => r.board_status.id === status.id) ?? []
            }),
            {}
        );


    // Status Managment
    const [columns, setColumns] = useState(getStatusTasks());
    const [ordered, setOrdered] = useState(statuses ?? []);
    const { t } = useTranslation();


    const onDragEnd = (result) => {


        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;


        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // reordering column
        if (result.type === "COLUMN") {
            const reorderedOrder = reorder(ordered, source.index, destination.index);

            let data = [];
            // eslint-disable-next-line
            reorderedOrder.map(item => {
                // eslint-disable-next-line
                data.push({ id: item.id })
            })

            putElement(getPathParam([BOARD, boardData.id, STATUS_POSITION]), data)
                .then(response => {
                    toast.success(t("app.tasks.update_success"))
                }).catch(e => { });


            setOrdered(reorderedOrder);
            return;
        }

        const data = reorderQuoteMap({
            quoteMap: columns,
            source,
            destination,
        });


        let dataOrdering = [];
        // eslint-disable-next-line
        Object.keys(data.quoteMap).map((item) => {
            let tmpData = { status: { id: item }, tasks: []};
            // eslint-disable-next-line
            data.quoteMap[item].map((task) => {
                // eslint-disable-next-line
                tmpData.tasks.push({ id: task.id })
                console.log(item)
            });
            dataOrdering.push(tmpData)
        });


        
        
        putElement(getPathParam([BOARD, boardData.id, TASK_POSITION]), dataOrdering)
            .then(response => {
                toast.success(t("app.tasks.update_success"))
            }).catch(e => { });


        setColumns(data.quoteMap);
    };


    useEffect(() => {
        setOrdered(statuses ?? []);
    }, [statuses]);

    useEffect(() => {
        setColumns(getStatusTasks());
        // eslint-disable-next-line
    }, [statuses, taskes]);



    return (
        <LoaderWrapper isLoading={isLoadingStatuses}>
            {
                ordered.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="board"
                            type="COLUMN"
                            direction="horizontal"
                            ignoreContainerClipping={false}
                            isCombineEnabled={false}
                        >
                            {(provided) => (
                                <Container ref={provided.innerRef} {...provided.droppableProps}>
                                    {ordered.map((key, index) => (
                                        <Column
                                            key={key.id}
                                            index={index}
                                            columnId={key.id}
                                            title={key.title}
                                            isAdmin={isAdmin}
                                            isMember={isMember}
                                            columnData={key}
                                            taskes={columns[key.id]}
                                            isScrollable={true}
                                            isLoadingTaskes={isLoadingTaskes}
                                            isCombineEnabled={false}
                                            onAddTask={() => {
                                                onAddTask(key);
                                            }}
                                            onEditTask={(taskData) => {
                                                onEditTask(key, taskData)
                                            }}
                                        />
                                    ))}
                                   
                                    {provided.placeholder}
                                </Container>
                            )}
                        </Droppable>
                    </DragDropContext>
                )
            }
        </LoaderWrapper>
    );
};

BoardList.defaultProps = {
    isCombineEnabled: false,
};

BoardList.propTypes = {
    isCombineEnabled: PropTypes.bool,
};

export default BoardList;
