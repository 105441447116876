/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {concatenateQueryStrings, serialize} from "../../../utils/converter";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DynamicPagination({total, limit, isLoading, onChange, resetPagination = false}) {


    const checkOffset = () => {
        const pageNumber = new URLSearchParams(window.location.search).get(
            "page"
        );
        if(pageNumber){
            return parseInt(pageNumber);
        }
        return 1;
    }

    const [position, setPosition] = useState(checkOffset());

    const location = useLocation();
    useEffect(() => {
        const pageNumber = new URLSearchParams(location.search).get(
            "page"
        );
        if(pageNumber && position !== pageNumber){
            setPosition(parseInt(pageNumber));
        }


        if(resetPagination){
            navigate(location.pathname + concatenateQueryStrings(location.search, serialize({page: 1})))
        }

        // eslint-disable-next-line
    }, [location.search, resetPagination]);


    let navigate = useNavigate();

    const getPageCount = () =>{
        return  parseInt( total / limit  ) + ( 0 < ( total % limit ) ? 1 : 0 );
    };

    const getPageNumbers = () => {
        let pageCount = getPageCount();
        let pageNumbers = [];

        for ( let i = -1; i < 2 && pageCount >= 3; i++ ) {
            if ( 1 < position && position < pageCount )
                pageNumbers.push( position + i );
            if ( 1 === position )
                pageNumbers.push( position + i + 1 );
            if ( position === pageCount )
                pageNumbers.push( position + i - 1 );
        }
        for ( let i = 0; i < pageCount && pageCount < 3; i++ ) {
            pageNumbers.push( i + 1 );
        }
        return pageNumbers;
    };

    const onPageLink= ( e, index ) => {
        setPosition(index);
        onChange( ( index - 1 ) * limit );
        navigate(location.pathname + concatenateQueryStrings(location.search, serialize({page: index})))
    };

    const onPrev = () => {
        setPosition(position - 1);
        onChange( ( position - 2 ) * limit );
        navigate(location.pathname + concatenateQueryStrings(location.search, serialize({page: position - 1})))
    };

    const onNext = () => {
        setPosition(position + 1);
        onChange( position  * limit );
        navigate(location.pathname + concatenateQueryStrings(location.search, serialize({page: position + 1})))
    };

    return (
        <>
            <div className={classNames(isLoading || 0 === total || getPageNumbers().length === 1 ? 'hidden' : '', "bg-white px-4 py-3 flex items-center justify-between sm:px-6")}>

                <div className="flex-1 flex justify-center sm:hidden">
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                        <button className={ classNames('relative inline-flex items-center px-2 py-2 rounded-l-md border border-solitude-400 text-sm font-medium', position === 1 ? 'bg-solitude-400 text-gray-400' : 'bg-white text-blue-1000 hover:bg-blue-1000 hover:text-white')   } disabled={position === 1} onClick={ () => {onPrev()} }>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            Previous
                        </button>

                        {
                            getPageNumbers().map( ( item, index ) => (
                                <button className={ `relative inline-flex items-center px-4 py-2 border text-sm font-medium ${item === position ? 'z-10 border-solitude-400 bg-blue-1000  text-white' : 'bg-white border-solitude-400 text-blue-1000 hover:bg-blue-1000 hover:text-white '}` }  key={ index } onClick={ ( e ) => onPageLink( e, item ) } >{ item }</button>
                            ) )
                        }

                        <Link
                            to={location.pathname}
                            className={classNames('relative inline-flex items-center px-2 py-2 rounded-r-md border border-solitude-400 text-sm font-medium', getPageCount() === position ? 'bg-solitude-400 text-gray-400' : 'bg-white text-blue-1000 hover:bg-blue-1000 hover:text-white')   }
                            disabled={getPageCount() === position}
                            onClick={ () => {onNext()} }>
                            Next
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                    </nav>
                </div>


                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-blue-1000">
                            <span className="font-medium">{total}</span> results
                        </p>
                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <button className={ classNames('relative inline-flex items-center px-2 py-2 rounded-l-md border border-solitude-400 text-sm font-medium', position === 1 ? 'bg-solitude-400 text-gray-400' : 'bg-white text-blue-1000 hover:bg-blue-1000 hover:text-white')   } disabled={position === 1} onClick={ () => {onPrev()} }>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                Previous
                            </button>

                            {
                                getPageNumbers().map( ( item, index ) => (
                                    <button className={ `relative inline-flex items-center px-4 py-2 border text-sm font-medium ${item === position ? 'z-10 border-solitude-400 bg-blue-1000  text-white' : 'bg-white border-solitude-400 text-blue-1000 hover:bg-blue-1000 hover:text-white '}` }  key={ index } onClick={ ( e ) => onPageLink( e, item ) } >{ item }</button>
                                ) )
                            }

                            <button
                                className={classNames('relative inline-flex items-center px-2 py-2 rounded-r-md border border-solitude-400 text-sm font-medium', getPageCount() === position ? 'bg-solitude-400 text-gray-400' : 'bg-white text-blue-1000 hover:bg-blue-1000 hover:text-white')   } disabled={getPageCount() === position}
                                onClick={ () => {onNext()} }
                            >
                                Next
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </nav>
                    </div>
                </div>

            </div>


        </>
    )
}
